import React, { Component } from 'react';
import { Row, Panel, PanelGroup } from 'react-bootstrap';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '../../../styles/patient-mobile.css';
import '../styles.css';
import {
  completedExercisesSelector,
  activeExercisesSelector
} from '../selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons/index';
import V from 'voca';
import { setCurrentRx } from '../actions/patient';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import TextTranslation from '../../../I18n/TranslateText';

const checkFrequencyExercise = exercise => {
  if (exercise.frequency === undefined) {
    return false;
  }
  return !!exercise.frequency;
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleBack = () => {
    const { dispatch } = this.props;
    dispatch(setCurrentRx(null));
  };

  getActiveExercises = exercises => {
    return exercises && exercises.filter(e => !e.deleted);
  };

  getCompletedExercises = activeExercises => {
    const completedExercises = [];

    if (Array.isArray(activeExercises)) {
      for (let i = 0; i < activeExercises.length; i++) {
        const exercise = activeExercises[i];
        const { completionDates } = exercise;

        if (!Array.isArray(completionDates)) {
          return [];
        }

        const date = m().format('MM/DD/YYYY');

        for (let j = 0; j < completionDates; j++) {
          const completionDate = completionDates[j];
          if (date === completionDate) {
            completedExercises.push(exercise);
          }
        }
      }
      return completedExercises;
    } else {
      return [];
    }
  };

  rxDescription = firstName => {
    const name = V.endsWith(firstName, 's')
      ? firstName + "'"
      : firstName + "'s";

    return (
      <div className="mobile-header-container">
        <h4 style={{ textAlign: 'center', color: 'white' }}>
          {V.capitalize(name)} Home Exercise Program
        </h4>
      </div>
    );
  };

  rxDetailsDescription = currentRx => {
    const {
      visibleProfile: { Prescriptions },
      locale
    } = this.props;
    const {
      Name,
      Prescription: { exercises, frequency, perdiem, instructions }
    } = currentRx;

    const activeExercises = Array.isArray(exercises)
      ? this.getActiveExercises(exercises)
      : [];

    return (
      <div className="mobile-header-container">
        {Prescriptions.length > 1 && (
          <Button
            icon={<ArrowLeftOutlined style={{ fontSize: 24 }} />}
            size="large"
            type="link"
            shape="circle"
            onClick={this.handleBack}
          />
        )}
        <div className="home-description">
          {Array.isArray(exercises) && exercises.length > 0 && (
            <div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 plan-details">
                  {Name ? (
                    <p>
                      <strong>{V.capitalize(Name)}</strong>
                    </p>
                  ) : (
                    <br />
                  )}

                  <TextTranslation
                    currLocale={locale}
                    currText={
                      exercises.length > 1
                        ? `${exercises.length} exercises`
                        : `${exercises.length} exercise`
                    }
                  />

                  {!checkFrequencyExercise(activeExercises[0]) && (
                    <React.Fragment>
                      <span className="ptw-spacer">|</span>
                      {!perdiem ? frequency : `${frequency}x every ${perdiem}`}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          )}
          {instructions && (
            <div className={'prescription-instructions-panel-group'}>
              <PanelGroup
                accordion
                id="accordion-prescription-instructions-panel"
              >
                <Panel eventKey="1">
                  <div>
                    <Panel.Heading>
                      <Panel.Title toggle>
                        <div
                          className={
                            'accordion-prescription-instructions-panel-title'
                          }
                        >
                          <Row
                            onClick={() =>
                              this.setState({ open: !this.state.open })
                            }
                          >
                            <div>
                              <TextTranslation
                                currLocale={locale}
                                currText={'Prescription Instructions'}
                              />
                              &nbsp;&nbsp;&nbsp;
                              <FontAwesomeIcon
                                icon={!this.state.open ? faPlus : faMinus}
                              />
                            </div>
                          </Row>
                        </div>
                      </Panel.Title>
                    </Panel.Heading>
                  </div>
                  <Panel.Body collapsible>{instructions}</Panel.Body>
                </Panel>
              </PanelGroup>
            </div>
          )}
        </div>
      </div>
    );
  };

  progressInfo = progressHeader => {
    const { visibleProfile } = this.props;
    const { Prescriptions } = visibleProfile;
    const prescription = Prescriptions[0].Prescription || Prescriptions[0];

    let progressStats = {
      'Exercises Completed': prescription.completedExercises
    };

    return (
      <div className="row exercise-property">
        {progressHeader.map((property, i) => (
          <div key={i}>
            <div>
              <h2 style={{ color: '#fff' }}>{progressStats[property]}</h2>
              <h2 style={{ color: '#fff' }}>{property}</h2>
            </div>
          </div>
        ))}
      </div>
    );
  };

  achievementProgress = (totalAchievements, unlockedAchievements, locale) => (
    <div className="home-description">
      <div>
        <div className={'plan-details'}>
          <TextTranslation currLocale={locale} currText={'Achievements'} />
        </div>
      </div>
    </div>
  );

  render() {
    const {
      visibleProfile: { Prescriptions, FirstName },
      currentRx,
      locale
    } = this.props;
    const progressHeader = ['Exercises Completed'];
    let totalAchievements = 0;

    const Prescription = Prescriptions[0].Prescription || Prescriptions[0];
    const achievements = Prescription.achievements;
    if (achievements) {
      Object.keys(achievements).forEach(k => {
        totalAchievements += achievements[k].length;
      });
    }

    return (
      <div>
        <Route
          path="/exercises"
          render={() =>
            currentRx
              ? this.rxDetailsDescription(currentRx)
              : this.rxDescription(FirstName)
          }
        />
        <Route
          path="/progress"
          render={() => this.progressInfo(progressHeader)}
        />
        <Route
          path="/achievements"
          render={() => this.achievementProgress(12, totalAchievements, locale)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentLocation: state.routerReducer.location.pathname,
  visibleProfile: state.visibleProfile,
  completedExercises: completedExercisesSelector(state),
  activeExercises: activeExercisesSelector(state),
  currentRx: state.mobile.currentRx,
  locale: state.mobile.locale
});

export default withRouter(connect(mapStateToProps)(Header));
