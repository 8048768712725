import React, { Fragment } from 'react';
import { Progress } from 'antd';

const ActivityCard = ({
  title,
  count,
  unit,
  progress,
  showCompleted,
  source
}) => {
  return (
    <div className="patient-detail-tab-activity-card-container">
      {title && (
        <span className="patient-detail-tab-activity-card-title">{title}</span>
      )}
      {(count || count === 0) && (
        <div className="patient-detail-tab-activity-card-count-container">
          <h1
            className={`patient-detail-tab-activity-card-count ${
              source === 'clinical' && count !== 0 && 'card-count-green'
            }`}
          >
            {count != null && count !== 'null' ? count : 0}
          </h1>
          {unit && (
            <span className="patient-detail-tab-activity-card-unit">
              {unit}
            </span>
          )}
        </div>
      )}
      {progress && (
        <div className="patient-detail-tab-activity-card-progress">
          {!showCompleted && (
            <Progress
              strokeLinecap="square"
              type="circle"
              percent={progress !== null && progress !== 'null' ? progress : 0}
              width={50}
              format={progress =>
                `${progress != null && progress !== 'null' ? progress : 0}%`
              }
            />
          )}
          {showCompleted && (
            <Fragment>
              <Progress
                strokeLinecap="square"
                type="circle"
                percent={progress}
                width={50}
              />
              {progress === 100 && <span> Completed </span>}
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default ActivityCard;
