import { createAction } from 'redux-actions'
import { API_REQUEST } from '../../membership/middleware'

export const defaultGroupExercise = {
  active: true,
  imageUrl: ''
}

export const GET_GROUP_EXERCISES = 'GET_GROUP_EXERCISES';
export const getGroupExercises = (groupId) => ({
  [API_REQUEST]: createAction(GET_GROUP_EXERCISES)({
    resource: `groups/${groupId}/exercises?groupId=${groupId}`,
    method: 'GET'
  })
});

export const DELETE_GROUP_EXERCISE = 'DELETE_GROUP_EXERCISE';
export const deleteGroupExercise = (groupId,id) => ({
  [API_REQUEST]: createAction(DELETE_GROUP_EXERCISE)({
    resource: `groups/${groupId}/exercises`,
    method: 'DELETE',
    body:{id:id}
  })
});

export const GET_GROUP_EXERCISE = 'GET_GROUP_EXERCISE'
export const getGroupExercise = (groupId, exerciseId) => ({
  [API_REQUEST]: createAction(GET_GROUP_EXERCISE)({
    resource: `groups/${groupId}/exercises?groupId=${groupId}&exerciseName=${exerciseId.trim()}`,
    method: 'GET'
  })
})

export const SEARCH_GROUP_EXERCISES = 'SEARCH_GROUP_EXERCISES'
export const searchGroupExercises = (groupId, searchTerm) => ({
  [API_REQUEST]: createAction(SEARCH_GROUP_EXERCISES)({
    resource: `groups/${groupId}/exercises?search=${searchTerm}`,
    method: 'GET'
  })
})

export const POST_GROUP_EXERCISE = 'POST_GROUP_EXERCISE'
export const postGroupExercise = (groupId, values) => ({
  [API_REQUEST]: createAction(POST_GROUP_EXERCISE)({
    resource: `groups/${groupId}/exercises`,
    method: 'POST',
    body: {
        ...values,
        exerciseName: values.ExerciseName.trim()
    }
  })
})

export const UPDATE_GROUP_EXERCISE = 'UPDATE_GROUP_EXERCISE'
export const updateGroupExercise = (groupId, values) => ({
  [API_REQUEST]: createAction(UPDATE_GROUP_EXERCISE)({
    resource: `groups/${groupId}/exercises`,
    method: 'PUT',
    body: {
        ...values,
        ExerciseName: values.ExerciseName.trim()
    }
  })
})

export const IS_SEARCHING = 'IS_SEARCHING_GROUP_EXERCISES'
export const isSearching = createAction(IS_SEARCHING)

export const DONE_SEARCHING = 'DONE_SEARCHING_GROUP_EXERCISES'
export const doneSearching = createAction(DONE_SEARCHING)

export const OPEN_GROUP_EXERCISES_FORM_MODAL = 'OPEN_GROUP_EXERCISES_FORM_MODAL'
export const openGroupExercisesFormModal = createAction(OPEN_GROUP_EXERCISES_FORM_MODAL)()

export const CLOSE_GROUP_EXERCISES_FORM_MODAL = 'CLOSE_GROUP_EXERCISES_FORM_MODAL'
export const closeGroupExercisesFormModal = createAction(CLOSE_GROUP_EXERCISES_FORM_MODAL)()

export const UPDATE_CURRENT_GROUP_EXERCISE = 'UPDATE_CURRENT_GROUP_EXERCISE'
export const updateCurrentGroupExercise = (values) => createAction(UPDATE_CURRENT_GROUP_EXERCISE)({ values })

export const SET_GROUP_EXERCISE_FORM_TYPE = 'SET_GROUP_EXERCISE_FORM_TYPE'
export const setGroupExerciseFormType = (formType) => createAction(SET_GROUP_EXERCISE_FORM_TYPE)({ formType })

export const UPDATE_FORM_PARAMS_ERROR = 'UPDATE_EXERCISE_FORM_PARAMS_ERROR'
export const updateFormParamsError = createAction(UPDATE_FORM_PARAMS_ERROR, validParams => ({validParams}))
