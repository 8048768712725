import voca from 'voca'

export const group = {
  id: "Id",
  name: "Name",
  groupPhone: "PhoneNumber",
  address: "Address",
  city: "City",
  state: {
    key: "State",
    transform(value, forward) {
      return forward ? value : voca.upperCase(value)
    }
  },
  zip: "Zip",
  contactName: "PrimaryContactName",
  contactPhone: "PrimaryContactPhone",
  contactEmail: "PrimaryContactEmail",
  active: {
    key: 'Active',
    transform(value, forward) {
      if (forward) {
        return value === "true"
      } 
      return value && JSON.parse(value)
    }
  },
  design: 'Design',
  importDone: 'ImportDone',
  processingImport: 'ProcessingImport'
}

export const clinic = {
  id: "Id",
  clinicName: "ClinicName",
  clinicPhone: "ClinicPhoneNumber",
  address: "Address",
  city: "City",
  state: {
    key: "State",
    transform(value, forward) {
      return forward ? value : voca.upperCase(value)
    }
  },
  zip: "Zip",
  contactName: "ClinicPrimaryContactName",
  contactPhone: "ClinicPrimaryContactPhone",
  contactEmail: "ClinicPrimaryContactEmail",
  groupId: "GroupId"
}

export const exercise = {
  id: "Id",
  exerciseName: "ExerciseName",
  groupId: "GroupId",
  active: "Active",
  reps: "Reps",
  sets: "Sets",
  hold: "Hold",
  tag: {
    key: "Tag",
    transform(value) {
      return value || ''
    }
  },
  video: "Video",
  instructions: "Instructions",
  imageUrl: 'ImageUrl'
}

export const user = {
  id: "Id",
  firstName: "FirstName",
  lastName: "LastName",
  phoneNumber: "PhoneNumber",
  emailAddress: "EmailAddress",
  active: "Active",
  prescription: "Prescription",
}
