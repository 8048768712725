import { createAction } from 'redux-actions';
import { API_REQUEST } from '../../membership/middleware';

export const themes = {
  ptwired: {
    name: 'ptwired',
    title: 'PTWired',
    stylesheet: 'theme-PT-Wired-min.css',
    thumbnail: 'ptwired.png',
  },
  orange: {
    name: 'orange',
    title: 'Orange',
    stylesheet: 'theme-orange-min.css',
    thumbnail: 'orange.png',
  },
  green: {
    name: 'green',
    title: 'Green',
    stylesheet: 'theme-green-min.css',
    thumbnail: 'green.png',
  },
  purple: {
    name: 'purple',
    title: 'Purple',
    stylesheet: 'theme-purple-min.css',
    thumbnail: 'purple.png',
  },
  red: {
    name: 'red',
    title: 'Red',
    stylesheet: 'theme-red-min.css',
    thumbnail: 'red.png',
  },
  yellow: {
    name: 'yellow',
    title: 'Yellow',
    stylesheet: 'theme-yellow-min.css',
    thumbnail: 'yellow.png',
  },
  blue: {
    name: 'blue',
    title: 'Blue',
    stylesheet: 'theme-blue-min.css',
    thumbnail: 'blue.png',
  },
  darkgray: {
    name: 'darkgray',
    title: 'Dark Gray',
    stylesheet: 'theme-grey-dark-min.css',
    thumbnail: 'dark_gray.png',
  },
  lightgray: {
    name: 'lightgray',
    title: 'Light Gray',
    stylesheet: 'theme-grey-light-min.css',
    thumbnail: 'light_gray.png',
  },
  kioko: {
    name: 'kioko',
    title: 'Kioko',
    stylesheet: 'kioko.css',
    thumbnail: 'light_gray.png',
  },
  score: {
    name: 'score',
    title: 'Score Health',
    stylesheet: 'score.css',
    thumbnail: 'light_gray.png',
  },
  siento: {
    name: 'siento',
    title: 'Siento',
    stylesheet: 'siento.css',
    thumbnail: 'light_gray.png',
  },
  chicagopt: {
    name: 'chicagopt',
    title: 'Chicago PT',
    stylesheet: 'chicagopt.css',
    thumbnail: 'light_gray.png',
  },
  pickpt: {
    name: 'pickpt',
    title: 'Pick PT',
    stylesheet: 'pickpt.css',
    thumbnail: 'light_gray.png',
  },
  aokpt: {
    name: 'aokpt',
    title: 'AOK PT',
    stylesheet: 'aokpt.css',
    thumbnail: 'yellow.png',
  },
  spearpt: {
    name: 'spearpt',
    title: 'Spear PT',
    stylesheet: 'spearpt.css',
    thumbnail: 'red.png',
  },
};

//Used to bust the cache by changing the file names
export const themeMap = {
  'theme-PT-Wired-min.css': {
    stylesheet: 'theme-PT-Wired.css',
  },
  'theme-orange-min.css': {
    stylesheet: 'theme-orange.css',
  },
  'theme-green-min.css': {
    stylesheet: 'theme-green.css',
  },
  'theme-purple-min.css': {
    stylesheet: 'theme-purple.css',
  },
  'theme-red-min.css': {
    stylesheet: 'theme-red.css',
  },
  'theme-yellow-min.css': {
    stylesheet: 'theme-yellow.css',
  },
  'theme-blue-min.css': {
    stylesheet: 'theme-blue.css',
  },
  'theme-grey-dark-min.css': {
    stylesheet: 'theme-grey-dark.css',
  },
  'theme-grey-light-min.css': {
    stylesheet: 'theme-grey-light.css',
  },
  'kioko.css': {
    stylesheet: 'kioko.css',
  },
  'score.css': {
    stylesheet: 'score.css',
  },
  'siento.css': {
    stylesheet: 'siento.css',
  },
  'chicagopt.css': {
    stylesheet: 'chicagopt.css',
  },
  'pickpt.css': {
    stylesheet: 'pickpt.css',
  },
  'aokpt.css': {
    stylesheet: 'aokpt.css',
  },
  'spearpt.css': {
    stylesheet: 'spearpt.css',
  },
};

export const defaultDesign = {
  brandType: 'text',
  theme: themes.darkgray,
};

export const PREVIEW_THEME = 'PREVIEW_THEME';
export const previewTheme = createAction(PREVIEW_THEME, theme => ({ theme }));

export const UPDATE_DESIGN = 'UPDATE_GROUP_DESIGN';
export const updateDesign = (groupName, design) => ({
  [API_REQUEST]: createAction(UPDATE_DESIGN)({
    resource: `groups/${groupName}/design`,
    method: 'PUT',
    body: design,
  }),
});

export const UPLOAD_GROUP_LOGO = 'UPLOAD_GROUP_LOGO';
export const uploadLogo = (groupId, file) => {
  return {
    [API_REQUEST]: createAction(UPLOAD_GROUP_LOGO)({
      resource: `grouplogos/${groupId}.jpg`,
      method: 'POST',
      file: true,
      body: file,
    }),
  };
};
