import React, { useRef } from 'react';
import { Modal, Button, Typography, Input } from 'antd';
import setNotification from '../../../utils/setNotification.utils';

const ExportHEPGeneratedIdModal = (props) => {
  const { togglExportHEPGeneratedIdModal, generatedId } = props;
  const textAreaRef = useRef();

  const copyToClipboard = (e) => {
    try {
      navigator.clipboard.writeText(generatedId);
      setNotification('success', 'Success!', 'Copied to clipboard.');
    } catch (error) {
      const textarea = document.createElement('textarea');

      textarea.value = generatedId;
      textarea.style.position = 'fixed';
      textarea.style.left = '-9999px';

      document.body.appendChild(textarea);
      textarea.select();

      try {
        document.execCommand('copy');
        setNotification('success', 'Success!', 'Copied to clipboard.');
      } catch (err) {
        console.error('Failed to Copy: ', err);
      }

      document.body.removeChild(textarea);
    }
  };

  return (
    <Modal
      title={
        <Typography.Title level={4} style={{ marginBottom: -8 }}>
          Export HEP Generated ID
        </Typography.Title>
      }
      visible={true}
      maskClosable={false}
      onCancel={togglExportHEPGeneratedIdModal}
      footer={[
        <Button
          key="export"
          size="large"
          type="primary"
          className="ptw-btn btn-primary"
          onClick={copyToClipboard}
        >
          Copy to Clipboard
        </Button>
      ]}
    >
      <Input.TextArea ref={textAreaRef} readOnly value={generatedId} />
    </Modal>
  );
};

export default ExportHEPGeneratedIdModal;
