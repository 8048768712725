/* eslint-disable react-hooks/exhaustive-deps */
import './styles.css';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation } from '@apollo/client';

import { notification, Space, Spin, Typography } from 'antd';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons';

import { READ_MESSAGE, SEND_MESSAGE } from '../../graphql/mutations';
import {
  setCurrentMonitoredPatient,
  exitCurrentMonitoredPatient,
} from '../../features/monitor-timer/actions';

import v from 'voca';
import BeatLoader from 'react-spinners/BeatLoader';
import MessageInput from './MessageInput';
import MessageBox from './MessageBox';
import states from '../../states';
import services from '../../services';
import { clearActivity } from '../RTMTimer/mixins';

const MessageBoard = ({ id, showProfile = false, visibleProfile }) => {
  const [readMessage] = useMutation(READ_MESSAGE);
  const [sendMessage, { loading }] = useMutation(SEND_MESSAGE);

  const [board, setBoard] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [messages, setMessages] = useRecoilState(states.messages);

  const patients = useRecoilValue(states.patients);
  const prescription = useRecoilValue(states.prescription);

  const bottomRef = useRef(null);

  const disabled =
    patients.details &&
    patients.details.preferences &&
    patients.details.preferences.messaging &&
    patients.details.preferences.messaging === 'disabled';

  const recentSender = board && board.Messages[board.Messages.length - 1]?.from;

  useEffect(() => {
    if (id || messages.list) {
      const current = messages.list.find(item => item.Id === id);
      if (current) {
        setBoard(current);
      }
    }
  }, [id, messages.list]);

  useEffect(() => {
    if ((board || loading) && patients.details) {
      if (bottomRef.current) {
        bottomRef.current.scrollIntoView({
          behavior: 'auto',
        });
      }
    }
  }, [board, loading, patients.details]);

  if (!board) {
    // TODO: display placeholder if no data
    return null;
  }

  const handleAccessProfile = () => {
    if (showProfile) {
      clearActivity();
      // if (monitoringData) {
      //   if (isMonitoring) {
      //     exitMonitoring();
      //   }

      //   if (prescription.list.length > 1) {
      //     setMonitoringData(null);
      //   } else {
      //     setMonitoringData({
      //       ...monitoringData,
      //       activity: 'View Adherence Log',
      //     });
      //   }
      // }

      setRedirect(true);
    }
  };

  const handleUnreadBoard = async () => {
    try {
      const response = await services.message.unreadBoard(
        board.Id,
        board.GroupId
      );

      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Message successfully unread.',
        });

        setMessages(prevState => ({
          ...prevState,
          list: [...prevState.list].map(item => {
            if (item.Id !== board.Id) {
              return item;
            }

            return {
              ...item,
              TherapistRead: false,
              TherapistMessages: 1,
            };
          }),
        }));
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to mark this message board as unread.',
      });
    }
  };

  const handleSendMessage = async input => {
    if (input && !loading) {
      await Promise.all([
        sendMessage({
          variables: {
            messageBoardId: board.Id,
            message: input,
          },
        }),
        readMessage({
          variables: {
            messageBoardId: board.Id,
          },
        }),
      ]).then(([response]) => {
        const boardId = response.data.sendMessage.MessageBoardId;
        const newMessage = {
          ...response.data.sendMessage,
        };

        delete newMessage['MessageBoardId'];
        delete newMessage['__typename'];

        setMessages(prevState => {
          const messageList = messages.list.slice();
          const index = messageList.findIndex(m => m.Id === boardId);

          if (!messageList[index]) {
            return prevState;
          }

          const board = messageList[index];
          const updatedBoard = {
            ...board,
            Messages: [...board.Messages, newMessage],
            TherapistMessages: 0,
            TherapistRead: true,
          };

          messageList.splice(index, 1);
          messageList.unshift(updatedBoard);

          return {
            ...prevState,
            list: messageList,
          };
        });
      });
    }
  };

  return (
    <Fragment>
      <div className="board-container">
        {!patients.details ? (
          <div className="board-body">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 64 }} />} />
          </div>
        ) : (
          <>
            <div className="board-header">
              <Space align="baseline" size="middle">
                <Typography.Title
                  level={5}
                  className={showProfile && 'board-title'}
                  onClick={handleAccessProfile}
                >
                  {v.capitalize(board.FirstName.trim())}{' '}
                  {v.capitalize(board.LastName.trim())}
                </Typography.Title>

                {showProfile && (
                  <UserOutlined
                    style={{
                      fontSize: 18,
                      color: '#2192ff',
                      cursor: 'pointer',
                    }}
                    onClick={handleAccessProfile}
                  />
                )}
              </Space>

              {!disabled &&
                !!board.Messages.length &&
                recentSender &&
                recentSender !== visibleProfile.Sub && (
                  <Typography.Link
                    style={{
                      fontSize: 15,
                      marginRight: showProfile ? 0 : 16,
                    }}
                    onClick={handleUnreadBoard}
                  >
                    Mark as Unread
                  </Typography.Link>
                )}
            </div>

            {disabled ? (
              <div className="board-body board-warning-msg">
                <Typography.Title level={3}>
                  Messaging Disabled!
                </Typography.Title>

                <Typography.Text style={{ fontSize: 17 }}>
                  Messaging has been disabled for this patient. You can
                  re-enable it on the patient’s profile.
                </Typography.Text>

                <br />
                <FontAwesomeIcon
                  style={{ fontSize: '70px', marginBottom: 48 }}
                  icon={faCommentSlash}
                />
              </div>
            ) : (
              <>
                <div className="content">
                  <MessageBox board={board} />

                  <div
                    ref={bottomRef}
                    className={`receiver ${loading ? 'dialog' : ''}`}
                  >
                    {loading && (
                      <div className="beat-loader">
                        <BeatLoader
                          color="#fff"
                          size={8}
                          style={{ margin: '0 1em' }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <MessageInput sendMessage={handleSendMessage} />
              </>
            )}
          </>
        )}
      </div>

      {redirect && <Redirect to={`/patients/${board.Owner}`} />}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
  monitoringData: state.monitorTimer.currentMonitoredPatient,
  isMonitoring: state.monitorTimer.isMonitoring,
});

const mapDispatchToProps = dispatch => ({
  setMonitoringData: data => dispatch(setCurrentMonitoredPatient(data)),
  exitMonitoring: () => dispatch(exitCurrentMonitoredPatient()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageBoard);
