import {
  maxLength,
  alphaNumericAndSpecial,
  compoundValidator,
  url,
  minLength,
} from './generic';
import { getGroupExercise } from '../actions/groupExercises';
import voca from 'voca';

const minLength1 = minLength(1);
const maxLength256 = maxLength(256);
const maxLength25 = maxLength(25);
const maxLength2083 = maxLength(2083);

export const exerciseNameValidator = compoundValidator(
  [alphaNumericAndSpecial, minLength1, maxLength256],
  'Invalid exercise name'
);
export const paramValidator = compoundValidator(
  [alphaNumericAndSpecial, minLength1, maxLength25],
  'Invalid param'
);
export const instructionalVideoURLValidator = compoundValidator(
  [url, maxLength2083],
  'Invalid instructional video URL'
);
export const instructionsValidator = compoundValidator(
  [alphaNumericAndSpecial, maxLength2083],
  'Invalid instructions'
);
export const tagsValidator = compoundValidator(
  [alphaNumericAndSpecial],
  'Invalid tags'
);
export const requiredValidator = value => (value ? undefined : ' ');
export const imageValidator = file => {
  const errors = {};
  if (file.size / 1024 > 1000) errors.size = 'File too big';
  return errors;
};

export const exerciseImgValidator = file => {
  const errors = {};
  if (file.size / 1024 > 2500) errors.size = 'File too big';
  if (file.type !== 'image/jpeg' && file.type !== 'image/png')
    errors.type = 'Invalid file type';
  return errors;
};

export const asyncValidate = (values, dispatch, props) => {
  return new Promise((resolve, reject) => {
    if (
      values.exerciseName &&
      values.exerciseName.trim() !== props.initialValues.exerciseName
    ) {
      dispatch(
        getGroupExercise(props.groupId, voca.lowerCase(values.exerciseName))
      )
        .then(({ value }) => {
          if (value.message === 'Success') {
            reject({ exerciseName: 'Exercise Name must be unique' });
          } else {
            resolve();
          }
        })
        .catch(errorResponse => {
          if (errorResponse.status === 404) {
            resolve();
          } else {
            reject({ exerciseName: 'Exercise Name must be unique' });
          }
        });
    } else {
      resolve();
    }
  });
};
