import { InfoCircleFilled } from '@ant-design/icons';
import { Button, DatePicker, Popover, Space, Typography } from 'antd';
import React, { Fragment } from 'react';
import moment from 'moment';

import ActivityCard from './ActivityCard';
import ActivityLog from './ActivityLog';
import LineChart from '../../../../../components/LineChart';

export default function PatientDataLog({
  selectedPatient,
  patientDataLog,
  dataLogsDate,
  setDataLogsDate,
  getDataPoints
}) {
  if (!selectedPatient) {
    return null;
  }

  const { Sub, GroupId, BillingEnd } = selectedPatient;
  const { engagement, daysWithDataPoints, patientDataPoints, painScale } =
    patientDataLog;

  const handleClickCurrentPeriod = () => {
    const { BillingStart, BillingEnd } = selectedPatient;

    setDataLogsDate(null);
    getDataPoints({
      GroupId,
      Sub,
      BillingStart,
      BillingEnd
    });
  };

  const handleChangeDate = async (value) => {
    const BillingStart = value.startOf('month').toDate().getTime();
    const BillingEnd = value.endOf('month').toDate().getTime();

    setDataLogsDate(value);
    getDataPoints({
      GroupId,
      Sub,
      BillingStart,
      BillingEnd
    });
  };

  const isPastDate = dataLogsDate && dataLogsDate.isBefore(moment(), 'd');
  const difference = Math.ceil(
    (BillingEnd - Date.now()) / (1000 * 60 * 60 * 24)
  );

  return (
    <Fragment>
      <div
        className="patient-detail-tab-stats"
        align="middle"
        justify="center"
        gutter={[16, 8]}
      >
        <ActivityCard
          title="Engagement"
          progress={engagement >= 100 ? 100 : `${engagement}`}
        />

        <ActivityCard
          title="Days with Data Points"
          count={`${daysWithDataPoints}`}
        />

        {!isPastDate && (
          <ActivityCard
            title={
              !!selectedPatient?.RollingPeriod || difference < 0 ? (
                <Space size={4} style={{ marginLeft: 22 }}>
                  <div>Rolling Bill Period</div>
                  <Popover
                    arrowPointAtCenter
                    trigger="hover"
                    placement="topRight"
                    content={
                      <Typography.Paragraph style={{ marginBottom: 0 }}>
                        This patient currently has a rolling billing period,
                        meaning they did not meet billing requirements for 98977
                        <br />
                        in the first 30 days after they were enrolled in RTM.
                        Our system will continue to look at every preceding
                        <br />
                        30-day billing period until billing requirements are met
                        for 98977. After requirements are met, a new fixed
                        <br />
                        30-day period will be started. 'On Track for Billing,'
                        'Not On Track for Billing,' and 'No Activity Reported'
                        <br />
                        labels are based on the patient's activity over the past
                        7 days.
                      </Typography.Paragraph>
                    }
                  >
                    <InfoCircleFilled style={{ marginBottom: 20 }} />
                  </Popover>
                </Space>
              ) : (
                'Days Remaining'
              )
            }
            count={
              !!selectedPatient?.RollingPeriod || difference < 0
                ? null
                : `${Math.ceil(
                    (BillingEnd - Date.now()) / (1000 * 60 * 60 * 24)
                  )}`
            }
          />
        )}
      </div>

      <div style={{ margin: '20px 5px 10px 5px' }}>
        <DatePicker
          style={{ marginRight: 10 }}
          picker="month"
          format="MMMM-YYYY"
          allowClear={false}
          value={dataLogsDate}
          onChange={handleChangeDate}
        />

        {!!dataLogsDate && (
          <Button type="primary" onClick={handleClickCurrentPeriod}>
            Back to Current Bill Period
          </Button>
        )}
      </div>

      <div style={{ margin: 5 }}>
        {!!painScale?.length && (
          <div
            style={{
              width: 'auto',
              height: 200,
              marginBottom: 8
            }}
          >
            <Typography.Text
              style={{
                fontWeight: 'bolder',
                marginBottom: 4
              }}
            >
              Daily Outcome Score
            </Typography.Text>

            <LineChart data={painScale} />
          </div>
        )}
      </div>

      <div className="patient-detail-tab-body-container">
        {patientDataPoints &&
          patientDataPoints.map((data, i) => (
            <Fragment key={i}>
              <ActivityLog
                date={data.Date}
                activity={data.Datapoints}
                type="patient"
              />
            </Fragment>
          ))}
      </div>
      <div className="patient-detail-tab-pagination" />
    </Fragment>
  );
}
