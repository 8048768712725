import { createAction } from 'redux-actions';
import config from '../../../../../config';

export const SUBMIT_NPS_SURVEY = 'SUBMIT_NPS_SURVEY';
export const submitNPSSurvey = (code, score, comment) =>
  createAction(SUBMIT_NPS_SURVEY)(
    fetch(`${config.root}/nps-survey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code, score: Number(score), comment }),
    }).then(
      response =>
        new Promise((resolve, reject) => {
          if (response.status === 201) {
            response.json().then(json => resolve(json));
          } else {
            response.text().then(text => reject(text));
          }
        })
    )
  );
