import React from 'react';

import { Popover, Typography } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

const TabHeader = ({ title, tooltip }) => {
  return (
    <div className="tab-header">
      <Typography.Title level={2}>
        {title}

        {tooltip && (
          <Popover
            content={tooltip}
            trigger="hover"
            placement="right"
            arrowPointAtCenter
          >
            <InfoCircleFilled className="popover-icon" />
          </Popover>
        )}
      </Typography.Title>
    </div>
  );
};

export default TabHeader;
