import { Modal, Typography, Button, Row } from 'antd';
import React from 'react';

const InactivityPrompt = ({
  openInactivePrompt,
  handleResumeMonitoring,
  remainingTime,
}) => {
  const isoFormat = new Date(remainingTime * 1000).toISOString();
  const formattedStreamDuration = isoFormat
    .substring(isoFormat.indexOf('T') + 1, isoFormat.indexOf('.'))
    .substring(3);

  return (
    <Modal
      title="Inactivity Warning"
      visible={openInactivePrompt}
      closable={false}
      centered
      footer={null}
    >
      <Row justify="center">
        <div>
          <Typography.Text style={{ marginBottom: -3 }}>
            You have been inactive for a while. The RTM timer will automatically
            stop in:
          </Typography.Text>
          <Typography.Title level={3} style={{ textAlign: 'center' }}>
            {formattedStreamDuration}
          </Typography.Title>
        </div>
      </Row>
      <Row justify="center" style={{ marginTop: 24 }}>
        <Button
          className="btn-primary ptw-btn"
          type="primary"
          onClick={handleResumeMonitoring}
        >
          Resume Monitoring
        </Button>
      </Row>
    </Modal>
  );
};

export default InactivityPrompt;
