import React, { useState, useEffect, Fragment } from 'react';
import { Amplify, Predictions } from 'aws-amplify';
import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

import {
  AFRIKAANS,
  ALBANIAN,
  AMHARIC,
  ARABIC,
  ARMENIAN,
  AZERBAIJANI,
  BENGALI,
  BOSNIAN,
  BULGARIAN,
  CATALAN,
  CHINESE,
  CHINESE_TRADITIONAL,
  CROATIAN,
  CZECK,
  DANISH,
  DARI,
  DUTCH,
  ENGLISH,
  ESTONIAN,
  FARSI,
  TAGALOG,
  FINNISH,
  FRENCH,
  FRENCH_CANADA,
  GEORGIAN,
  GREEK,
  GERMAN,
  GUJATI,
  HAITIAN_CREOLE,
  HAUSA,
  HEBREW,
  HINDI,
  HUNGARIAN,
  ICELANDIC,
  INDONESIAN,
  IRISH,
  ITALIAN,
  JAPANESE,
  KANNADA,
  KAZAKH,
  KOREAN,
  LATVIAN,
  LITHUANIAN,
  LOCALE,
  MACADONIAN,
  MALAY,
  MALAYALAM,
  MALTESE,
  MARATHI,
  MONGOLIAN,
  NORWEGIAN,
  PASHTO,
  POLISH,
  PORTUGUESE,
  PUNJABI,
  ROMANIAN,
  RUSSIAN,
  SERBIAN,
  SINHALA,
  SLOVAK,
  SLOVENIAN,
  SOMALI,
  SPANISH,
  SWAHILI,
  SWEDISH,
  TAMIL,
  TELUGU,
  THAI,
  TURKISH,
  UKRAINIAN,
  URDU,
  UZBEK,
  WELSH,
  VIETNAMESE,
} from './constants';

export const languages = [
  {
    locale: AFRIKAANS,
    name: 'Afrikaans',
    sub: 'Africans',
  },
  {
    locale: ALBANIAN,
    name: 'Albanian',
    sub: 'Albanian',
  },
  {
    locale: AMHARIC,
    name: 'Amharic',
    sub: 'Amharic',
  },
  {
    locale: ARABIC,
    name: 'Arabic',
    sub: 'Arabic',
  },
  {
    locale: ARMENIAN,
    name: 'Armenian',
    sub: 'Armenian',
  },
  {
    locale: AZERBAIJANI,
    name: 'Azerbaijani',
    sub: 'Azerbaijani',
  },
  {
    locale: BENGALI,
    name: 'Bengali',
    sub: 'Bengali',
  },
  {
    locale: BOSNIAN,
    name: 'Bosnian',
    sub: 'Bosnian',
  },
  {
    locale: BULGARIAN,
    name: 'Bulgarian',
    sub: 'Bulgarian',
  },
  {
    locale: CATALAN,
    name: 'Catalan',
    sub: 'Catalan',
  },
  {
    locale: CHINESE,
    name: '简体中文',
    sub: 'Simplified Chinese',
  },
  {
    locale: CHINESE_TRADITIONAL,
    name: '繁體中文',
    sub: 'Traditional Chinese',
  },
  {
    locale: CROATIAN,
    name: 'Croatian',
    sub: 'Croatian',
  },
  {
    locale: CZECK,
    name: 'Czeck',
    sub: 'Czeck',
  },
  {
    locale: DANISH,
    name: 'Danish',
    sub: 'Danish',
  },
  {
    locale: DARI,
    name: 'Dari',
    sub: 'Dari',
  },
  {
    locale: DUTCH,
    name: 'Dutch',
    sub: 'Dutch',
  },
  {
    locale: ENGLISH,
    name: 'English',
    sub: 'English',
  },
  {
    locale: ESTONIAN,
    name: 'Estonian',
    sub: 'Estonian',
  },
  {
    locale: FARSI,
    name: 'Farsi',
    sub: 'Farsi',
  },
  {
    locale: FINNISH,
    name: 'Finnish',
    sub: 'Finnish',
  },
  {
    locale: FRENCH,
    name: 'French',
    sub: 'Français',
  },
  {
    locale: FRENCH_CANADA,
    name: 'French Canada',
    sub: 'French Canada',
  },
  {
    locale: GEORGIAN,
    name: 'Georgian',
    sub: 'Georgian',
  },
  {
    locale: GERMAN,
    name: 'Deutsche',
    sub: 'German',
  },
  {
    locale: GREEK,
    name: 'Ελληνικά',
    sub: 'Greek',
  },
  {
    locale: GUJATI,
    name: 'Gujati',
    sub: 'Gujati',
  },
  {
    locale: HAITIAN_CREOLE,
    name: 'Haitian Creole',
    sub: 'Haitian Creole',
  },
  {
    locale: HAUSA,
    name: 'Hausa',
    sub: 'Hausa',
  },
  {
    locale: HEBREW,
    name: 'Hebrew',
    sub: 'Hebrew',
  },
  {
    locale: HINDI,
    name: 'हिंदी',
    sub: 'Hindi',
  },
  {
    locale: HUNGARIAN,
    name: 'Hungarian',
    sub: 'Hungarian',
  },
  {
    locale: ICELANDIC,
    name: 'Icelandic',
    sub: 'Icelandic',
  },
  {
    locale: INDONESIAN,
    name: 'Indonesian',
    sub: 'Indonesian',
  },
  {
    locale: IRISH,
    name: 'Irish',
    sub: 'Irish',
  },
  {
    locale: ITALIAN,
    name: 'Italiano',
    sub: 'Italian',
  },
  {
    locale: JAPANESE,
    name: '日本人',
    sub: 'Japanese',
  },
  {
    locale: KANNADA,
    name: 'Kannada',
    sub: 'Kannada',
  },
  {
    locale: KAZAKH,
    name: 'Kazakh',
    sub: 'Kazakh',
  },
  {
    locale: KOREAN,
    name: 'Korean',
    sub: 'Korean',
  },
  {
    locale: LATVIAN,
    name: 'Latvian',
    sub: 'Latvian',
  },
  {
    locale: LITHUANIAN,
    name: 'Lithuanian',
    sub: 'Lithuanian',
  },
  {
    locale: MACADONIAN,
    name: 'Macadonian',
    sub: 'Macadonian',
  },
  {
    locale: MALAY,
    name: 'Malay',
    sub: 'Malay',
  },
  {
    locale: MALAYALAM,
    name: 'Malayalam',
    sub: 'Malayalam',
  },
  {
    locale: MALTESE,
    name: 'Maltese',
    sub: 'Maltese',
  },
  {
    locale: MARATHI,
    name: 'Marathi',
    sub: 'Marathi',
  },
  {
    locale: MONGOLIAN,
    name: 'Mongolian',
    sub: 'Mongolian',
  },
  {
    locale: NORWEGIAN,
    name: 'Norwegian',
    sub: 'Norwegian',
  },
  {
    locale: PASHTO,
    name: 'Pashto',
    sub: 'Pashto',
  },
  {
    locale: POLISH,
    name: 'Polish',
    sub: 'Polish',
  },
  {
    locale: PORTUGUESE,
    name: 'Português',
    sub: 'Portuguese',
  },
  {
    locale: PUNJABI,
    name: 'Punjabi',
    sub: 'Punjabi',
  },
  {
    locale: ROMANIAN,
    name: 'Romanian',
    sub: 'Romanian',
  },
  {
    locale: RUSSIAN,
    name: 'Pусский',
    sub: 'Russian',
  },
  {
    locale: SERBIAN,
    name: 'Serbian',
    sub: 'Serbian',
  },
  {
    locale: SINHALA,
    name: 'Sinhala',
    sub: 'Sinhala',
  },
  {
    locale: SLOVAK,
    name: 'Slovak',
    sub: 'Slovak',
  },
  {
    locale: SLOVENIAN,
    name: 'Slovenian',
    sub: 'Slovenian',
  },
  {
    locale: SOMALI,
    name: 'Somali',
    sub: 'Somali',
  },
  {
    locale: SPANISH,
    name: 'Español',
    sub: 'Spanish',
  },
  {
    locale: SWAHILI,
    name: 'Swahili',
    sub: 'Swahili',
  },
  {
    locale: SWEDISH,
    name: 'Swedish',
    sub: 'Swedish',
  },
  {
    locale: TAGALOG,
    name: 'Tagalog',
    sub: 'Filipino (Tagalog)',
  },
  {
    locale: TAMIL,
    name: 'Tamil',
    sub: 'Tamil',
  },
  {
    locale: TELUGU,
    name: 'Telugu',
    sub: 'Telugu',
  },
  {
    locale: THAI,
    name: 'Thai',
    sub: 'Thai',
  },
  {
    locale: TURKISH,
    name: 'Turkish',
    sub: 'Turkish',
  },
  {
    locale: UKRAINIAN,
    name: 'Ukranian',
    sub: 'Ukranian',
  },
  {
    locale: URDU,
    name: 'اردو',
    sub: 'Urdu',
  },
  {
    locale: UZBEK,
    name: 'Uzbek',
    sub: 'Uzbek',
  },
  {
    locale: VIETNAMESE,
    name: 'Tiếng Việt',
    sub: 'Vietnamese',
  },
  {
    locale: WELSH,
    name: 'Welsh',
    sub: 'Welsh',
  },
];

const dictionary = {
  [AFRIKAANS]: {},
  [ALBANIAN]: {},
  [AMHARIC]: {},
  [ARABIC]: {},
  [ARMENIAN]: {},
  [AZERBAIJANI]: {},
  [BENGALI]: {},
  [BOSNIAN]: {},
  [BULGARIAN]: {},
  [CATALAN]: {},
  [CHINESE]: {},
  [CHINESE_TRADITIONAL]: {},
  [CROATIAN]: {},
  [CZECK]: {},
  [DANISH]: {},
  [DARI]: {},
  [DUTCH]: {},
  [ENGLISH]: require('./languages/english.json'),
  [ESTONIAN]: {},
  [FARSI]: {},
  [TAGALOG]: {},
  [FINNISH]: {},
  [FRENCH]: {},
  [FRENCH_CANADA]: {},
  [GEORGIAN]: {},
  [GERMAN]: {},
  [GREEK]: {},
  [GUJATI]: {},
  [HAITIAN_CREOLE]: {},
  [HAUSA]: {},
  [HEBREW]: {},
  [HINDI]: {},
  [HUNGARIAN]: {},
  [ICELANDIC]: {},
  [INDONESIAN]: {},
  [IRISH]: {},
  [ITALIAN]: {},
  [JAPANESE]: {},
  [KANNADA]: {},
  [KAZAKH]: {},
  [KOREAN]: {},
  [LATVIAN]: {},
  [LITHUANIAN]: {},
  [MACADONIAN]: {},
  [MALAY]: {},
  [MALAYALAM]: {},
  [MALTESE]: {},
  [MARATHI]: {},
  [MONGOLIAN]: {},
  [NORWEGIAN]: {},
  [PASHTO]: {},
  [POLISH]: {},
  [PORTUGUESE]: {},
  [PUNJABI]: {},
  [ROMANIAN]: {},
  [RUSSIAN]: {},
  [SERBIAN]: {},
  [SINHALA]: {},
  [SLOVAK]: {},
  [SLOVENIAN]: {},
  [SOMALI]: {},
  [SPANISH]: require('./languages/es.json'),
  [SWAHILI]: {},
  [SWEDISH]: {},
  [TAMIL]: {},
  [TELUGU]: {},
  [THAI]: {},
  [TURKISH]: {},
  [UKRAINIAN]: {},
  [URDU]: {},
  [UZBEK]: {},
  [VIETNAMESE]: {},
  [WELSH]: {},
};

/**
 * Text Translation
 * @param props
 * @returns {*}
 * @constructor
 */
const TextTranslation = ({ currText, currLocale }) => {
  const [translatedText, setTranslatedText] = useState(currText);

  useEffect(() => {
    if (!currText) {
      return;
    }

    if (currLocale) {
      if (currLocale === ENGLISH) {
        return setTranslatedText(currText);
      }

      const mapping = dictionary[currLocale][currText];

      if (mapping) {
        return setTranslatedText(mapping);
      }

      setTranslatedText('...');

      Predictions.convert({
        translateText: {
          source: {
            text: currText,
            language: ENGLISH,
          },
          targetLanguage: currLocale,
        },
      })
        .then(result => setTranslatedText(result.text))
        .catch(err => setResponse(JSON.stringify(err, null, 2)));
    }
  }, [currText, currLocale]);

  return <Fragment>{translatedText}</Fragment>;
};
export default TextTranslation;
