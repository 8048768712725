import { defaultPTWiredExercise } from "../actions/ptwiredExercises";
import { FormTypes } from "../../groups/actions/groups";
import {
  SET_EXERCISE_FORM_TYPE,
  UPDATE_CURRENT_EXERCISE,
  IS_LOADING,
  DONE_LOADING,
  IS_SEARCHING,
  DONE_SEARCHING
} from "../actions/exercisesLibrary";

const defaultState = {
  currentExercise: defaultPTWiredExercise,
  formType: FormTypes.NEW,
  isLoading: false
}

export const exercisesLibraryReducer = (state = defaultState, {type, payload}) => {
  switch(type){
    case SET_EXERCISE_FORM_TYPE:
      return {
        ...state,
        formType: payload.formType
      }

    case UPDATE_CURRENT_EXERCISE:
      return {
        ...state,
        currentExercise: payload.values
      }

    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      }

    case DONE_LOADING:
      return {
        ...state,
        isLoading: false
      }

    case IS_SEARCHING:
      return {
        ...state,
        isSearching: true
      }

    case DONE_SEARCHING:
      return {
        ...state,
        isSearching: false
      }

    default:
      return state
  }
}