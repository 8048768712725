import React, { Fragment } from 'react';
import { Route } from 'react-router';

import AdminDashboard from '../features/patients/Dashboard/AdminDashboard';
import Announcements from '../features/announcements';
import Billing from '../features/groups/billing/Billing';
import CarePlan from '../features/care-plan';
import ChangePassword from '../features/change-password';
import Dashboard from '../features/patients/Dashboard';
import Groups from '../features/groups';
import GroupPage from '../features/groups/GroupPage';
import Help from '../features/help';
import Integration from '../features/integrations';
import Library from '../features/exercise-library';
import EducationalPDFLibrary from '../features/educational-pdf-library';
import MobileApp from '../features/mobile';
import Patients from '../pages/Patients';
import PatientPage from '../features/patients/PatientPage';
import Profile from '../features/profile';
import PushNotifications from '../features/push-notifications';
import Users from '../features/users';
import ReleaseAnnouncement from '../features/release-announcement';
import Reports from '../pages/Reports';

import ForbiddenPage from '../components/ForbiddenPage';
import NotFound from '../components/NotFound';
import MobilePrintPreview from '../features/multiple-prescriptions/components/PrintPreviewModal/MobilePrintPreview';

const routes = (role, visibleProfile) => (
  <Fragment>
    {role === 'p' ? (
      <div className="ptw-main">
        <Route exact path="/home" component={MobileApp} />
      </div>
    ) : (visibleProfile && JSON.parse(visibleProfile.GroupInfo.Active)) ||
      role === 'sa' ? (
      <div className="ptw-main">
        <Route exact path="/" component={Dashboard} />
        <Route path="/users" component={Users} />
        <Route path="/administrator" component={AdminDashboard} />
        <Route exact path="/groups" component={Groups} />
        <Route path="/groups/:id" component={GroupPage} />
        <Route exact path="/new/patients" component={Patients} />
        <Route exact path="/patients" component={Patients} />
        <Route path="/patients/:id" component={PatientPage} />
        <Route path="/print-preview" component={MobilePrintPreview} />
        <Route path="/library" component={Library} />
        <Route path="/educ-pdf-library" component={EducationalPDFLibrary} />
        <Route path="/profile" component={Profile} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/help" component={Help} />
        <Route path="/validate/:code" component={NotFound} />
        <Route path="/billing" component={Billing} />
        <Route path="/emr-integrations" component={Integration} />
        <Route path="/announcements" component={Announcements} />
        <Route path="/push-notifications" component={PushNotifications} />
        <Route path="/care-plan" component={CarePlan} />
        <Route path="/message-broadcast" component={ReleaseAnnouncement} />
        <Route path="/reports" component={Reports} />
      </div>
    ) : (
      <ForbiddenPage />
    )}
  </Fragment>
);

export default routes;
