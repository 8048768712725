import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Row,
  Col,
  Button,
  Space,
  List,
  notification,
  Typography,
  Breadcrumb
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { getIntegrationCode } from './actions';

import DropdownNavigation from '../../components/DropdownNavigation';
import DispatchLinkItem from '../../components/DispatchLinkItem';

function Integration(props) {
  const [code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [dataString, setDataString] = useState(null);

  useEffect(() => {
    if (!code) {
      return;
    }
    const subdomain = window.location.host.split('.')[1]
      ? window.location.host.split('.')[0]
      : null;

    const dataObject = {
      domain: subdomain,
      key: code
    };

    const str = JSON.stringify(dataObject);
    const enc = new Buffer(str).toString('hex');

    setDataString(enc);
  }, [code]);

  const getCode = async () => {
    const { dispatch, visibleProfile } = props;

    setIsLoading(true);
    try {
      const response = await dispatch(
        getIntegrationCode(visibleProfile.GroupId)
      );

      setCode(response.value.code);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const integrationData = [
    {
      title: 'Prompt EMR',
      siteUrl: 'https://promptemr.com/',
      imgUrl: 'https://ptw-mobile-images.s3.amazonaws.com/prompt/Logo_iso.png'
    }
    // {
    //   title: 'pVerify',
    //   siteUrl: 'https://www.pverify.com/',
    //   imgUrl:
    //     'https://www.pverify.com/wp-content/uploads/2021/09/pVerify-logo.png',
    // },
  ];

  const openNotification = () => {
    notification.open({
      message: 'Data Copied!',
      description: 'Please paste the corresponding data into Prompt EMR.'
    });
  };

  return (
    <React.Fragment>
      <DropdownNavigation />
      <div className="ptw-main-body">
        <Breadcrumb className="mb-2">
          <DispatchLinkItem title="Admin&nbsp;" url="/administrator" />
          <Breadcrumb.Item active>EMR Integrations</Breadcrumb.Item>
        </Breadcrumb>
        <Space className="mb-4" direction="vertical" size={0}>
          <Typography.Title level={4} style={{ marginTop: -10 }}>
            EMR Integrations Partners
          </Typography.Title>
          <Typography.Text type="secondary">
            Integrate with our current EMR Partners
          </Typography.Text>
        </Space>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Typography.Title level={2}>Single Sign On</Typography.Title>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <List
              className="hide-mb"
              itemLayout="horizontal"
              dataSource={integrationData}
              renderItem={(item) => (
                <List.Item actions={[,]}>
                  <List.Item.Meta
                    avatar={
                      <img
                        src={item.imgUrl}
                        alt={item.title}
                        style={{
                          height: 'auto',
                          width: 250,
                          border: '1px solid #ccc',
                          padding: 12
                        }}
                      />
                    }
                    title={item.title}
                    description={item.siteUrl}
                  />
                </List.Item>
              )}
            />
            <Space
              className="pt-2 pb-3 hide-dt show-mb"
              size={8}
              direction="vertical"
            >
              <img
                src={integrationData[0].imgUrl}
                alt={integrationData[0].title}
                style={{
                  height: 'auto',
                  width: '100%',
                  border: '1px solid #ccc',
                  padding: 12
                }}
              />
              <Space size={4} direction="vertical">
                <Typography.Text strong>
                  {integrationData[0].title}
                </Typography.Text>
                <Typography.Text type="secondary">
                  {integrationData[0].siteUrl}
                </Typography.Text>
              </Space>
            </Space>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Space align="center">
              {code ? (
                <CopyToClipboard text={dataString} onCopy={openNotification}>
                  <Button type="primary" icon={<CopyOutlined />}>
                    Copy to Clipboard
                  </Button>
                </CopyToClipboard>
              ) : (
                <Button
                  type="primary"
                  loading={isLoading}
                  onClick={getCode}
                  style={{
                    paddingLeft: 15
                  }}
                >
                  Get Integration Code
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  currentLocation: state.routerReducer.location.pathname,
  visibleProfile: state.visibleProfile
});

export default connect(mapStateToProps)(withRouter(Integration));
