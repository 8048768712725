import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Typography, Input, Form, Tooltip } from 'antd';
import { CopyOutlined, LoadingOutlined } from '@ant-design/icons';

import { exportHEP } from '../actions/prompt';
import setNotification from '../../../utils/setNotification.utils';

import ExportHepToPrompt from '../../export-hep-to-prompt';

const ExportHEPModal = props => {
  const { togglExportHEPModal, exercises, currentPatient, dispatch } = props;
  const [loading, setLoading] = useState(false);
  const [hepData, setHepData] = useState([]);
  const [clipboardString, setClipboardString] = useState(null);

  const handleSetHepData = data => {
    const finalExportData = data
      .filter(dt => dt.export === 'YES')
      .map(dt => ({
        Id: dt.id,
        CompletionDates: dt.completionDates,
        CptCode: dt.cptCode ? Number(dt.cptCode) : null,
        ExerciseName: dt.exerciseName,
        Frequency: dt.frequency,
        Hold: dt.hold,
        Instructions: dt.instructions,
        Minutes: dt.minutes || null,
        Perdiem: dt.perdiem,
        Reps: dt.reps,
        Sets: dt.sets,
        Video: dt.video,
      }));

    setHepData(finalExportData);
  };

  const handleCopyToClipboard = () => {
    const input = document.createElement('input');

    document.body.appendChild(input);
    input.value = clipboardString;
    input.focus();
    input.select();

    const result = document.execCommand('copy');

    if (result === true) {
      setNotification(
        'success',
        'Success!',
        'Export HEP ID has been copied to clipboard.'
      );
    } else {
      setNotification('error', 'Error!', 'Could not copy to clipboard.');
    }

    document.body.removeChild(input);
  };

  const handleExportHEP = async data => {
    try {
      setLoading(true);

      if (data && currentPatient?.Sub) {
        const response = await dispatch(
          exportHEP({
            Sub: currentPatient.Sub,
            HEP: data,
          })
        );

        if (response.value.message === 'Success') {
          setClipboardString(response.value.data);
        }
      }
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while exporting HEP.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Modal
        centered
        visible={!!clipboardString}
        title="Export HEP ID"
        footer={null}
        onCancel={() => setClipboardString(null)}
      >
        <Form.Item>
          <Input
            readOnly
            size="large"
            defaultValue={clipboardString}
            value={clipboardString}
            suffix={
              <Tooltip title="Copy to Clipboard">
                <Button
                  shape="circle"
                  icon={<CopyOutlined />}
                  onClick={handleCopyToClipboard}
                />
              </Tooltip>
            }
          />
        </Form.Item>
      </Modal>
      <Modal
        className="export-hep-modal"
        title={
          <Typography.Title level={4} style={{ marginBottom: -8 }}>
            <img
              src="https://ptw-mobile-images.s3.amazonaws.com/prompt/Logo_iso.png"
              alt="prompt-logo"
              style={{ height: 'auto', width: 60 }}
            />{' '}
            Export to Visit in Prompt
          </Typography.Title>
        }
        visible={true}
        onCancel={togglExportHEPModal}
        footer={[
          <Button
            key="cancel"
            size="large"
            type="default"
            className="ptw-btn"
            onClick={togglExportHEPModal}
          >
            Cancel
          </Button>,
          <Button
            key="export"
            size="large"
            type="primary"
            className="ptw-btn btn-primary"
            onClick={() => {
              if (hepData.length > 0) {
                handleExportHEP(hepData);
              }
            }}
            disabled={hepData.length === 0 || loading}
          >
            {loading && (
              <Fragment>
                <LoadingOutlined className="text-icon" />
                &nbsp;
              </Fragment>
            )}
            Export HEP
          </Button>,
        ]}
        width={1000}
      >
        <ExportHepToPrompt
          exercises={exercises}
          setHepData={handleSetHepData}
        />
      </Modal>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    currentPatient: state.patients.currentPatient,
  };
}

export default connect(mapStateToProps)(ExportHEPModal);
