import { Button, Input, Modal, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRecoilState } from 'recoil';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

import services from '../../services';
import states from '../../states';

const CaseIdModal = ({
  visibleProfile,
  component,
  prescriptionId,
  patient
}) => {
  const [showModal, setShowModal] = useState(false);
  const [valid, setValid] = useState(true);
  const [exist, setExist] = useState(false);
  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [caseId, setCaseId] = useState('');

  const [rxState, setRxState] = useRecoilState(states.prescription);

  const rxList = rxState.list;
  const rxItem = rxList.find((rx) => rx.id === prescriptionId);

  const btnDisabled = !caseId || rxItem?.caseId === caseId;
  const isCaseIdRequired =
    !!visibleProfile.CaseId?.Required &&
    !visibleProfile.CaseId?.ExcludedLocations?.includes(patient?.Clinic);

  useEffect(() => {
    if (isCaseIdRequired && !rxItem?.caseId) {
      setShowModal(true);
    }
  }, []);

  const handleShowModal = () => {
    // if (isCaseIdRequired && !rxItem.caseId) {
    //   return;
    // }

    setShowModal(!showModal);

    if (showModal) {
      setCaseId('');
    } else {
      setCaseId(rxItem?.caseId || '');
    }
  };

  const handleOnChange = (e) => {
    setValid(true);
    setExist(false);
    setCaseId(e.target.value);
  };

  const handleOnBlur = async (e) => {
    if (isCaseIdRequired) {
      const value = e.target.value;

      if (!value) {
        setValid(true);
      } else {
        const validCaseId = /^\d{8}$/.test(value);
        const groupId = visibleProfile.GroupId;

        setValid(validCaseId);

        if (validCaseId) {
          try {
            setValidating(true);
            await services.patients.getByCaseId(value, groupId).then((res) => {
              if (res.status === 200) {
                setExist(true);
              }
            });
          } finally {
            setValidating(false);
          }
        }
      }
    }
  };

  const handleSubmit = async () => {
    if (validating || !valid || exist) {
      return;
    }

    try {
      setLoading(true);

      const response = await services.prescription.assignCases({
        id: prescriptionId,
        sub: patient.Sub,
        caseId
      });

      if (response.status === 200) {
        const data = toCamelCaseObjKeys(response.data);
        const newList = rxList.map((item) => {
          if (item.id === prescriptionId) {
            return data;
          }

          return item;
        });

        setRxState((prevState) => ({
          ...prevState,
          form: data,
          list: newList
        }));

        notification.success({
          message: 'Success!',
          description: 'HEP Case Id assigned successfully.'
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'An error occurred while assigning case id.'
      });
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  return (
    <>
      <div onClick={handleShowModal}>{component}</div>

      <Modal
        destroyOnClose
        title={`Assign ${isCaseIdRequired ? 'Prescription' : ''} Case Id`}
        okText="Assign"
        open={showModal}
        onCancel={handleShowModal}
        footer={[
          <Button key="cancel" onClick={handleShowModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={btnDisabled}
            loading={loading}
            onClick={handleSubmit}
          >
            Assign
          </Button>
        ]}
      >
        <div
          style={{
            marginBottom: valid ? 30 : 8
          }}
        >
          <Typography.Text>
            {isCaseIdRequired ? 'Prescription ' : ''}Case Id:
          </Typography.Text>
          <Input
            placeholder={isCaseIdRequired ? '12345678' : 'Enter case id'}
            maxLength={isCaseIdRequired && 8}
            value={caseId}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            style={{
              borderColor: valid ? '' : 'red',
              marginTop: 2
            }}
            readOnly={validating}
          />

          {(!valid || exist) && (
            <Typography.Text
              style={{
                color: 'red'
              }}
            >
              {exist
                ? 'Case Id is already assigned to another patient.'
                : 'Case Id must be an 8-digit number.'}
            </Typography.Text>
          )}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  visibleProfile: state.visibleProfile
});

export default connect(mapStateToProps)(CaseIdModal);
