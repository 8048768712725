import { defaultPTWiredExercise } from "../actions/ptwiredExercises";
import { FormTypes } from "../../groups/actions/groups";
import {
  GET_EXERCISE,
  GET_EXERCISES,
  SEARCH_EXERCISES,
  SET_EXERCISE_FORM_TYPE,
  UPDATE_CURRENT_EXERCISE,
  IS_LOADING,
  DONE_LOADING
} from "../actions/ptwiredExercises";

import {
  PENDING,
  FULFILLED,
  REJECTED
} from 'redux-promise-middleware'

import mapModel from '../../groups/mapper'

const defaultState = {
  currentPTWiredExercise: defaultPTWiredExercise,
  formType: FormTypes.NEW,
  isFetching: false,
  fetched: false,
  isSubmitting: false,
  submitted: false,
  items: {},
  isLoading: false
}

export const ptwiredExercisesReducer = (state = defaultState, {type, payload}) => {
  let mapped
  switch(type){
    case `${GET_EXERCISE}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      }

    case `${GET_EXERCISE}_${FULFILLED}`:

      mapped = mapModel('exercise', payload.exercise, false)
      return {
        ...state,
        isFetching: false,
        fetched: true,
        items: {
          ...state.items,
          [mapped.id]: mapped
        }
      }

    case `${SEARCH_EXERCISES}_${PENDING}`:
    case `${GET_EXERCISES}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      }

    case `${SEARCH_EXERCISES}_${FULFILLED}`:
    case `${GET_EXERCISES}_${FULFILLED}`:
      const items = []
      payload.exercises.forEach(e => {
        items.push(mapModel('exercise', e, false))
      })

      return {
        ...state,
        isFetching: false,
        fetched: true,
        items:items
      }

    case `${SEARCH_EXERCISES}_${REJECTED}`:
    case `${GET_EXERCISES}_${REJECTED}`:
      return {
        ...state,
        isFetching: false,
        fetched: false
      }

    case SET_EXERCISE_FORM_TYPE:
      return {
        ...state,
        formType: payload.formType
      }

    case UPDATE_CURRENT_EXERCISE:
      return {
        ...state,
        currentPTWiredExercise: payload.values
      }

    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      }

    case DONE_LOADING:
      return {
        ...state,
        isLoading: false
      }

    default:
      return state
  }
}