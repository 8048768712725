import React, { Component } from 'react';
import { Modal, Typography, Button } from 'antd';

class DeleteModal extends Component {
  render() {
    const { showDeletePatient, deleteAction, onHide, loading } = this.props;

    return (
      <Modal
        title="Are you sure?"
        visible={showDeletePatient}
        onCancel={onHide}
        footer={[
          <Button
            key="cancel"
            size="large"
            type="default"
            className="ptw-btn"
            onClick={onHide}
          >
            Cancel
          </Button>,
          <Button
            key="delete"
            size="large"
            type="primary"
            className="ptw-btn btn-primary"
            onClick={deleteAction}
            loading={loading}
            danger
          >
            Delete
          </Button>
        ]}
      >
        <Typography.Text>
          Once a patient is deleted, they cannot be restored. Deleting a patient
          will allow you to re-use the email address they have.
        </Typography.Text>
      </Modal>
    );
  }
}

export default DeleteModal;
