import { atom } from 'recoil';

const defaultState = {
  analytics: [],
  dashboard: {
    page: 1,
    status: null,
    search: '',
    sortFilters: []
  },
  timer: {
    patient: null,
    activity: null,
    isMonitoring: false,
    streamDuration: 0,
    loadingActivityLogs: false,
    activityLogs: null
  },
  hasRemaining: false,
  stopFetching: false
};

const RTM_STATE = atom({
  key: 'RTM_STATE',
  default: defaultState
});

export default RTM_STATE;
