import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import { connect } from 'react-redux';
import { requestDeauth } from '../features/membership/actions';
import { withRouter } from 'react-router';

class NotFound extends Component{

    logout=()=>{
        const {dispatch} = this.props;
        dispatch(requestDeauth(), ()=>{
            this.props.history.push('/')
        });
    }

    render(){

        return(
            <div className="panel panel-default" style={{marginTop:'30px'}}>
                <Row>
                    <Col mdOffset={2} md={2} >
                        <a onClick={()=>{window.history.back();}}><h3>Back</h3></a>
                    </Col>
                    <Col  md={6}>
                        <h3>Please log out to use validation links</h3>
                    </Col>
                    <Col  md={2} >
                        <a onClick={this.logout}><h3>Log Out</h3></a>
                    </Col>
                </Row>

            </div>
        )
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps)(withRouter(NotFound))