import { useState, useRef, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { v4 as uuid } from 'uuid';

import { ImageSelector } from '../file-uploader';
import { uploadImageDirect } from '../file-uploader/actions';
import {
  popDescription,
  popImage,
  popImageCompatibleFormats,
  popName,
  popParameters,
  popTag,
  popVideo,
} from '../../features/groups/group-exercises/pops';
import { exerciseImgValidator } from '../../features/groups/validators/groupExercises';
import { appendTemplateToSelected } from '../../features/patients/actions/prescription';
import { toCamelCaseObjKeys } from '../../utils/object.utils';
import states from '../../states';
import services from '../../services';

import ModalForm from '../ModalForm';
import ProfileImage from '../../features/patients/Modals/ProfileImage';
import setNotification from '../../utils/setNotification.utils';

const YOUTUBE_REGEX = `(youtu.*be.*)(com)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))`;
const IMAGE_URI = 'https://d2p805pqn3eul9.cloudfront.net/';

const ExerciseFormModal = ({
  visibleProfile,
  addToTemplate,
  uploadImage,
  isOpen,
  onCancel,
  selectedExercises,
  fromTemplates = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [parametersError, setParametersError] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isClinic, setIsClinic] = useState(false);
  const [formData, setFormData] = useState({
    validImage: true,
    img: null,
    base64: null,
    file: null,
    cropping: false,
    removeFile: null,
  });

  const [exercises, setExercises] = useRecoilState(states.exercises);
  const setPrescription = useSetRecoilState(states.prescription);

  const [form] = Form.useForm();
  const imgSelectorRef = useRef();

  useEffect(() => {
    if (!isClinic && !isCustom) {
      form.setFieldsValue({
        sets: form.getFieldValue('reqSets'),
        reps: form.getFieldValue('reqReps'),
        hold: form.getFieldValue('reqHold'),
        instructions: form.getFieldValue('reqInstructions'),
      });
    }
  }, [isClinic, isCustom]);

  const setRequiredValues = () => {
    form.setFieldsValue({
      reqSets: form.getFieldValue('sets'),
      reqReps: form.getFieldValue('reps'),
      reqHold: form.getFieldValue('hold'),
      reqInstructions: form.getFieldValue('instructions'),
    });
  };

  const onFileSelected = async (file, errors) => {
    const isValid = _.isEmpty(errors);

    if (isValid) {
      setFormData({
        ...formData,
        cropping: true,
        validImage: true,
        file,
      });
    } else {
      console.log('errors', errors);
      setNotification(
        'error',
        'Upload Error!',
        'Image size must be less than 2.5MB. Use images less than 1MB for faster loading time.'
      );
    }
  };

  const onFileRemoved = () => {
    setFormData(prevState => ({
      ...prevState,
      validImage: true,
      removeFile: null,
    }));
  };

  const handleImageCrop = (blob, base64Str) => {
    setFormData(prevState => ({
      ...prevState,
      base64: base64Str,
      file: blob,
      cropping: false,
    }));
  };

  const handleExitCrop = () => {
    setFormData(prevState => ({
      ...prevState,
      img: null,
      cropping: false,
      file: null,
    }));
  };

  const validateExerciseName = str => {
    if (str.length === 0) return true;

    return exercises.list.some(
      exercise => exercise.exerciseName === str.toLowerCase()
    );
  };

  const validateVideoUrl = async str => {
    if (str.match(YOUTUBE_REGEX)) {
      return getYoutubeThumbnail(str).length === 11;
    } else if (str.startsWith('https://vimeo.com')) {
      return getVimeoThumbnail(str).then(videoId => {
        return !!videoId;
      });
    } else {
      setFormData(prevState => ({
        ...prevState,
        img: null,
      }));

      return false;
    }
  };

  const getYoutubeThumbnail = url => {
    const results = url.match('[\\?&]v=([^&#]*)');
    const videoId = results === null ? url : results[1];

    setFormData({
      ...formData,
      img: `http://img.youtube.com/vi/${videoId}/0.jpg`,
    });

    return videoId;
  };

  const getVimeoThumbnail = async url => {
    let videoId = '';
    await fetch(`https://vimeo.com/api/oembed.json?url=${url}`).then(
      async response => {
        await response.json().then(data => {
          videoId = data.video_id;

          setFormData({
            ...formData,
            img: data.thumbnail_url,
          });
        });
      }
    );

    return videoId;
  };

  const handleSelectType = e => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (!isCustom && !isClinic) {
      setRequiredValues();
    }

    switch (value) {
      case 'custom':
        setIsCustom(checked);
        setIsClinic(false);
        break;

      case 'clinic':
        setIsClinic(checked);
        setIsCustom(false);
        break;

      default:
        break;
    }
  };

  const handleAddExercise = exercise => {
    const name = exercise.exerciseName || exercise.name;
    const newExercise = {
      ...exercise,
      exerciseName: name,
    };

    delete newExercise.sub;
    delete newExercise.name;

    if (fromTemplates) {
      addToTemplate({
        Exercises: [...selectedExercises, newExercise],
      });
    } else {
      delete newExercise.active;
      delete newExercise.created;
      delete newExercise.searchTerm;
      delete newExercise.updated;

      setPrescription(state => {
        if (!state.form) {
          return {
            ...state,
          };
        }

        return {
          ...state,
          form: {
            ...state.form,
            prescription: {
              ...state.form.prescription,
              exercises: [...state.form.prescription.exercises, newExercise],
            },
          },
        };
      });
    }
  };

  const handleSubmit = async values => {
    let sets;
    let reps;
    let hold;
    let instructions;

    if (isClinic || isCustom) {
      sets = values.reqSets;
      reps = values.reqReps;
      hold = values.reqHold;
      instructions = values.reqInstructions;
    } else {
      sets = values.sets;
      reps = values.reps;
      hold = values.hold;
      instructions = values.instructions;
    }

    const exercise = {
      sub: visibleProfile.Sub,
      active: true,
      created: Date.now(),
      groupId: visibleProfile.GroupId,
      name: values.exerciseName.toLowerCase(),
      reps,
      sets,
      hold,
      instructions,
      image: formData.img,
      video: values.videoUrl,
      tag: values.tags,
    };

    try {
      setLoading(true);

      if (!isCustom && !isClinic) {
        const newExercise = {
          ...exercise,
          id: uuid(),
          image: formData.base64 || formData.img,
        };

        handleAddExercise(newExercise);
        handleCancelModal();

        return;
      }

      let newExercise = null;
      let description = '';

      if (isCustom) {
        await services.exercises.addCustomExercise(exercise).then(res => {
          if (res.status === 200) {
            let imageSrc = exercise.image;
            if (formData.base64) {
              const response = res.data;
              const type = formData.base64.split(';')[0].split('/')[1];
              const filename = `${visibleProfile.GroupId}/${response.Id}.${type}`;

              imageSrc = IMAGE_URI + filename;

              const customExercise = {
                ...toCamelCaseObjKeys(response),
                image: imageSrc,
                name: response.ExerciseName.toLowerCase(),
              };

              delete customExercise.ExerciseName;

              uploadImage(filename, formData.file);
              services.exercises.addCustomExercise(customExercise);
            }

            newExercise = {
              ...toCamelCaseObjKeys(res.data),
              image: imageSrc,
              imgBase64: formData.base64,
            };

            setExercises(prevState => {
              const index = prevState.custom.exercises.length;
              const listCopy = [...prevState.list];
              const unmappedCopy = [...prevState.unmapped];

              listCopy.splice(index, 0, newExercise);
              unmappedCopy.splice(index, 0, newExercise);

              const data = {
                ...res.data,
                Image: imageSrc,
              };

              return {
                ...prevState,
                unmapped: unmappedCopy,
                list: listCopy,
                custom: {
                  ...prevState.custom,
                  exercises: [...prevState.custom.exercises, data],
                },
              };
            });

            description = 'Custom exercise successfully added.';
          }
        });
      } else if (isClinic) {
        const params = {
          active: exercise.active,
          Active: exercise.active,
          exerciseName: exercise.name,
          ExerciseName: exercise.name,
          GroupId: exercise.groupId,
          image: exercise.image,
          imageUrl: exercise.image,
          SearchTerm: exercise.name.toLowerCase().replace(/\s/g, ''),
          Instructions: exercise.instructions,
          Reps: exercise.reps,
          Sets: exercise.sets,
          Hold: exercise.hold,
          Tag: exercise.tag,
          Video: exercise.video,
        };

        await services.exercises.addClinicExercise(params).then(res => {
          if (res.status === 200) {
            let imageSrc = exercise.image;
            if (formData.base64) {
              const id = res.data.id;
              const type = formData.base64.split(';')[0].split('/')[1];
              const filename = `${visibleProfile.GroupId}/${id}.${type}`;

              imageSrc = IMAGE_URI + filename;

              uploadImage(filename, formData.file);
              services.exercises.updateClinicExercise({
                ...params,
                Id: id,
                image: imageSrc,
                imageUrl: imageSrc,
              });
            }

            newExercise = {
              ...toCamelCaseObjKeys({
                Id: res.data.id,
                Active: params.Active,
                ExerciseName: params.ExerciseName,
                GroupId: params.GroupId,
                Image: imageSrc,
                imgBase64: formData.base64,
                SearchTerm: params.SearchTerm,
                Instructions: params.Instructions,
                Reps: params.Reps,
                Sets: params.Sets,
                Hold: params.Hold,
                Tag: params.Tag,
                Video: params.Video,
              }),
            };

            setExercises(prevState => {
              const index = prevState.custom.exercises.length;
              const listCopy = [...prevState.list];
              const unmappedCopy = [...prevState.unmapped];

              listCopy.splice(index, 0, newExercise);
              unmappedCopy.splice(index, 0, newExercise);

              return {
                ...prevState,
                unmapped: unmappedCopy,
                list: listCopy,
              };
            });

            description = 'Clinic exercise successfully added.';
          }
        });
      }

      if (newExercise) {
        handleAddExercise(newExercise);
        handleCancelModal();

        if (description) {
          notification.success({
            message: 'Success!',
            description,
          });
        }
      }
    } catch (error) {
      let description = '';

      if (isCustom) {
        description = 'An error occurred while adding custom exercise.';
      } else if (isClinic) {
        description = 'An error occurred while adding clinic exercise.';
      }

      if (description) {
        notification.error({
          message: 'Error!',
          description,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancelModal = () => {
    form.resetFields();

    setParametersError(false);
    setIsCustom(false);
    setIsClinic(false);
    setFormData(prevState => ({
      ...prevState,
      base64: null,
    }));

    handleExitCrop();
    onCancel();
  };

  const renderRequiredParameters = () => {
    return (
      <Fragment>
        <Form.Item
          style={{ marginBottom: -40, fontWeight: 'bold' }}
          label="Parameters"
          required
          tooltip={popParameters}
        />
        <Row gutter={[16, 0]}>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Form.Item
              name="reqSets"
              label="Sets"
              dependencies={['reqReps', 'reqHold']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value &&
                      !getFieldValue('reqReps') &&
                      !getFieldValue('reqHold')
                    ) {
                      setParametersError(true);
                      return Promise.reject();
                    } else {
                      setParametersError(false);
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                type="text"
                size="large"
                placeholder="Enter sets"
                name="sets"
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Form.Item
              name="reqReps"
              label="Reps"
              dependencies={['reqSets', 'reqHold']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value &&
                      !getFieldValue('reqSets') &&
                      !getFieldValue('reqHold')
                    ) {
                      setParametersError(true);
                      return Promise.reject();
                    } else {
                      setParametersError(false);
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                type="text"
                size="large"
                placeholder="Enter reps"
                name="reps"
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Form.Item
              name="reqHold"
              label="Hold"
              dependencies={['reqSets', 'reqReps']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value &&
                      !getFieldValue('reqSets') &&
                      !getFieldValue('reqReps')
                    ) {
                      setParametersError(true);
                      return Promise.reject();
                    } else {
                      setParametersError(false);
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                type="text"
                size="large"
                placeholder="Enter hold"
                name="hold"
              />
            </Form.Item>
          </Col>
        </Row>
        {parametersError && (
          <Typography.Paragraph type="danger" style={{ marginTop: -20 }}>
            Please fill in at least one parameter field.
          </Typography.Paragraph>
        )}
      </Fragment>
    );
  };

  const renderRequiredInstructions = () => {
    return (
      <Form.Item
        name="reqInstructions"
        label="Instructions"
        tooltip={popDescription}
        rules={[
          { required: true, message: 'Please input your instructions.' },
          { max: 1000, message: 'Max length is 1000 characters.' },
        ]}
      >
        <Input.TextArea
          size="large"
          placeholder="Enter instructions"
          name="instructions"
          rows={4}
        />
      </Form.Item>
    );
  };

  return (
    <Fragment>
      {formData.cropping && (
        <ProfileImage
          show={formData.cropping}
          image={formData.file}
          onSubmit={handleImageCrop}
          onExit={handleExitCrop}
          onHide={handleExitCrop}
          closeButton={handleExitCrop}
          isExerciseImage={true}
        />
      )}
      <ModalForm
        width="60%"
        className="exercise-form-modal"
        form="addCustomExercise"
        header="Add Custom Exercise"
        showModal={isOpen}
        handleClose={handleCancelModal}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <Form
          id="addCustomExercise"
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={[24, 0]}>
            <Col lg={12} md={12} sm={12}>
              <Row gutter={[16, 0]}>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name="exerciseName"
                    label="Exercise Name"
                    tooltip={popName}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your exercise name.',
                      },
                      { max: 256, message: 'Max length is 256 only.' },
                      {
                        message: 'Exercise name already exists.',
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.resolve();
                          }

                          return validateExerciseName(value)
                            ? Promise.reject()
                            : Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      size="large"
                      placeholder="Enter exercise name"
                      name="exerciseName"
                    />
                  </Form.Item>
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                  {isClinic || isCustom ? (
                    renderRequiredParameters()
                  ) : (
                    <Fragment>
                      <Form.Item
                        style={{ marginBottom: -40, fontWeight: 'bold' }}
                        label="Parameters"
                        tooltip={popParameters}
                      />
                      <Row gutter={[16, 0]}>
                        <Col lg={8} md={8} sm={24} xs={24}>
                          <Form.Item name="sets" label="Sets">
                            <Input
                              type="text"
                              size="large"
                              placeholder="Enter sets"
                              name="sets"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                          <Form.Item name="reps" label="Reps">
                            <Input
                              type="text"
                              size="large"
                              placeholder="Enter reps"
                              name="reps"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                          <Form.Item name="hold" label="Hold">
                            <Input
                              type="text"
                              size="large"
                              placeholder="Enter hold"
                              name="hold"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                </Col>

                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item name="tags" label="Tags" tooltip={popTag}>
                    <Input
                      type="text"
                      size="large"
                      placeholder="Enter tags"
                      name="tags"
                      rows={4}
                    />
                  </Form.Item>
                </Col>

                <Col lg={24} md={24} sm={24} xs={24}>
                  {isClinic || isCustom ? (
                    renderRequiredInstructions()
                  ) : (
                    <Form.Item
                      name="instructions"
                      label="Instructions"
                      tooltip={popDescription}
                      rules={[
                        {
                          max: 1000,
                          message: 'Max length is 1000 characters.',
                        },
                      ]}
                    >
                      <Input.TextArea
                        size="large"
                        placeholder="Enter instructions"
                        name="instructions"
                        rows={4}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                  name="videoUrl"
                  label="Exercise Video URL"
                  tooltip={popVideo}
                  validateTrigger="onBlur"
                  extra={
                    <Tooltip
                      overlayInnerStyle={{ width: 400 }}
                      placement="bottomRight"
                      title={popImageCompatibleFormats}
                    >
                      <Typography.Link>
                        See Compatible URL Formats
                      </Typography.Link>
                    </Tooltip>
                  }
                  rules={[
                    {
                      message: 'Please input a valid exercise video URL.',
                      validator: async (_, value) => {
                        if (!value) {
                          return Promise.resolve();
                        }

                        const valid = await validateVideoUrl(value);

                        if (valid) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject();
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    type="text"
                    size="large"
                    placeholder="Enter exercise video URL"
                    name="videoUrl"
                    rows={4}
                  />
                </Form.Item>
              </Col>
              <Col lg={24} md={24}>
                <Form.Item
                  label="Exercise Image"
                  tooltip={popImage}
                  help={
                    !formData.validImage && (
                      <Typography.Paragraph type="danger">
                        Selected image is either not in JPG, JPEG, or PNG format
                        or has a size of more than 2.5MB.
                      </Typography.Paragraph>
                    )
                  }
                >
                  <div className={!formData.validImage ? 'has-error' : ''}>
                    <ImageSelector
                      width="auto"
                      height={253}
                      ref={imgSelectorRef}
                      isUser={false}
                      defaultImageUrl={formData.base64 || formData.img}
                      src={formData.base64 || formData.img}
                      onFileSelected={onFileSelected}
                      onFileRemoved={onFileRemoved}
                      imageValidator={exerciseImgValidator}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col lg={24} md={24}>
                <Space direction="vertical">
                  <Checkbox
                    checked={isCustom}
                    onChange={handleSelectType}
                    value="custom"
                  >
                    Save to My Exercises
                  </Checkbox>

                  {visibleProfile.Role === 'ga' && (
                    <Checkbox
                      checked={isClinic}
                      onChange={handleSelectType}
                      value="clinic"
                    >
                      Save to Clinic Exercises
                    </Checkbox>
                  )}
                </Space>
              </Col>
            </Col>
          </Row>
        </Form>
      </ModalForm>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
});

const mapDispatchToProps = dispatch => ({
  addToTemplate: data => dispatch(appendTemplateToSelected(data)),
  uploadImage: (name, file) => dispatch(uploadImageDirect(name, file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseFormModal);
