import { createAction } from 'redux-actions';
import { API_REQUEST } from '../../membership/middleware';

export const EXPORT_HEP = 'EXPORT_HEP';
export const exportHEP = payload => ({
  [API_REQUEST]: createAction(EXPORT_HEP)({
    resource: `prompt/export-hep`,
    method: 'POST',
    body: payload
  })
});
