import React, { Component } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { selectedExercisesSelector } from '../selectors';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading';
import { appendTemplateToSelected } from '../actions/prescription';
import SearchBox from '../../../components/ExerciseSearchBox';
import ExerciseTemplateGrid from './ExerciseTemplateGrid';
import { success } from 'react-notification-system-redux';

class TemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      filteredTemplates: []
    };

    this.filterTemplates = this.filterTemplates.bind(this);
    this.appendTemplateToPrescription =
      this.appendTemplateToPrescription.bind(this);
  }

  showModal() {
    return true;
  }

  componentDidMount() {
    const { templates } = this.props;

    this.setState({ filteredTemplates: this.sortTemplates(templates) });
  }

  sortTemplates = templates => {
    return templates.sort(this.compare);
  };

  compare = (a, b) => {
    if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
      return -1;
    }
    if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  filterTemplates(event) {
    const searchTerm = event.target.value.toLowerCase();
    const { templates } = this.props;
    const { filteredTemplates } = this.state;
    let updatedTemplates = [];

    if (searchTerm === '') {
      return this.setState({ filteredTemplates: templates });
    }

    for (let i = 0; i < filteredTemplates.length; i++) {
      const name = filteredTemplates[i].Name.toLowerCase();
      const author = filteredTemplates[i].Author.toLowerCase();

      if (
        name.indexOf(searchTerm) !== -1 ||
        author.indexOf(searchTerm) !== -1
      ) {
        updatedTemplates.push(filteredTemplates[i]);
      }
    }
    this.setState({ filteredTemplates: updatedTemplates });
  }

  appendTemplateToPrescription(template) {
    const { templates, dispatch, closeFunction, setTemplateData } = this.props;
    setTemplateData(template);
    const templateId = template.Id;

    for (let i = 0; i < templates.length; i++) {
      if (templates[i].Id === templateId) {
        const templateCopy = Object.assign({}, templates[i]);
        dispatch(appendTemplateToSelected(templateCopy));
        closeFunction();
        return dispatch(
          success({
            title: 'Template data added to template!',
            position: 'tc'
          })
        );
      }
    }
  }

  render() {
    const { closeFunction, isSearching } = this.props;
    const { filteredTemplates } = this.state;

    return (
      <Modal
        show={true}
        keyboard={true}
        bsSize={'large'}
        onHide={closeFunction}
      >
        <Modal.Header closeButton>
          <h3 style={{ color: '#666' }}>Browse Templates</h3>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={8}>
              <h4 className="template-header" style={{ color: '#666' }}>
                Select a template to add it to the prescription.
              </h4>
            </Col>
            <Col md={4} style={{ color: '#666' }}>
              <SearchBox
                onKeyUp={this.filterTemplates}
                placeholder={'Template Name or Authors'}
                onClear={this.clearTemplates}
              />
            </Col>
          </Row>
          {this.props.templates && this.props.templates.length === 0 ? (
            <Row>
              <Col xsOffset={4} xs={4}>
                <div
                  style={{
                    textAlign: 'center',
                    color: '#666',
                    border: '1px solid #1890ff',
                    borderTop: '0px',
                    borderRight: '0px',
                    borderLeft: '0px'
                  }}
                >
                  <h4 style={{ color: '#666' }}>
                    You don't have any templates yet!
                  </h4>
                </div>
              </Col>
            </Row>
          ) : (
            <Loading isLoading={isSearching}>
              <ExerciseTemplateGrid
                templates={filteredTemplates}
                handleCardClick={this.appendTemplateToPrescription}
              />
            </Loading>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  templates: state.visibleProfile.Templates,
  selectedExercises: selectedExercisesSelector(state),
  isSearching: state.patients.isSearching
});

export default connect(mapStateToProps)(TemplateModal);
