import React, { Fragment } from 'react';
import { Col, Drawer, Form, Input, Row, Button } from 'antd';
import { ACTIONS } from './index';

export default function VideoDrawer(props) {
  const {
    state,
    carePlan,
    dispatchState,
    handleAddVideo,
    handleUpdateProgram,
    handleToggleDrawer,
  } = props;

  const onFinish = async values => {
    console.log(values)
    if (state.activeVideo.Sequence === null) {
      handleAddVideo({
        programId: carePlan.activeProgram.Id,
        drip: values.Drip,
        url: values.URL,
        title: values.Title,
        description: values.Description,
        notificationTitle: values.NotificationTitle,
        notificationDescription: values.NotificationDescription,
      });
    } else {
      const currentProgram = carePlan.activeProgram;
      const updatedProgram = {
        id: currentProgram.Id,
        programName: currentProgram.ProgramName,
        diagnosis: currentProgram.Diagnosis,
        patientTerm: currentProgram.PatientTerm,
        videos: currentProgram.Videos.map(video => {
          if (video.Sequence === state.activeVideo.Sequence) {
            return {
              ...video,
              Drip: values.Drip,
              URL: values.URL,
              Title: values.Title,
              Description: values.Description,
              NotificationTitle: values.NotificationTitle,
              NotificationDescription: values.NotificationDescription,
            };
          }
          return video;
        }),
      };
      try {
        await handleUpdateProgram(updatedProgram);
        handleToggleDrawer();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onClose = () => {
    dispatchState({ type: ACTIONS.TOGGLE_DRAWER });
  };

  return (
    <Fragment>
      <Drawer
        title={
          state.activeVideo.Title
            ? state.activeVideo.Title
            : 'Add new video sequence'
        }
        width={720}
        onClose={onClose}
        visible={state.showDrawer}>
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          initialValues={state.activeVideo}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="Drip"
                label="Drip"
                rules={[
                  {
                    required: true,
                    message: 'Please enter default drip',
                  },
                ]}>
                <Input type='number' placeholder="Please enter default drip" min={0}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="URL"
                label="URL"
                rules={[
                  {
                    required: true,
                    message: 'Please enter video url',
                  },
                ]}>
                <Input placeholder="Please enter video url" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="Title"
                label="Video Title"
                rules={[
                  {
                    required: true,
                    message: 'Please enter video title',
                  },
                ]}>
                <Input placeholder="Please enter video title" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="Description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: 'Please enter description',
                  },
                ]}>
                <Input.TextArea
                  rows={4}
                  placeholder="Please enter description"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="NotificationTitle"
                label="Push Notification Title"
                rules={[
                  {
                    required: true,
                    message: 'Please enter push notification title',
                  },
                ]}>
                <Input placeholder="Please enter push notification title" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="NotificationDescription"
                label="Push Notification Details"
                rules={[
                  {
                    required: true,
                    message: 'Please enter push notification details',
                  },
                ]}>
                <Input.TextArea
                  rows={4}
                  placeholder="Please enter push notification details"
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="care-button-container">
            <Button
              className="care-button"
              size="large"
              shape="round"
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="care-button"
              type="primary"
              size="large"
              shape="round"
              loading={carePlan.isLoading}
              htmlType="submit">
              {carePlan.isLoading ? 'Saving. Please wait!' : 'Save'}
            </Button>
          </div>
        </Form>
      </Drawer>
    </Fragment>
  );
}
