import React, { useEffect, useState } from 'react';
import { Drawer, Tabs } from 'antd';
import AdherenceLog from '../AdherenceLog';
import PrescriptionDetails from '../PrescriptionDetails';
import { useRecoilState } from 'recoil';
import states from '../../../../states';

const PrescriptionDrawer = ({ onEditClick, rxDetails, handleClose }) => {
  const [currTab, setCurrTab] = useState('prescriptionDetails');
  const [prescription, setPrescription] = useRecoilState(states.prescription);

  useEffect(() => {
    if (!!!rxDetails.prescription?.exercises?.length) {
      editRxFromDrawer();
    }
  }, []);

  const editRxFromDrawer = () => {
    // ASSIGNING ALREADY THE RX DETAILS FROM DRAWER TO STATES FOR EDITING RX
    if (rxDetails) {
      setPrescription({
        ...prescription,
        form: rxDetails,
      });

      onEditClick();
    }
  };

  return (
    <Drawer
      destroyOnClose
      title={<strong>Prescription Details</strong>}
      placement="right"
      visible={rxDetails}
      width="100%"
      onClose={handleClose}
    >
      <Tabs
        size="large"
        style={{ marginTop: -24 }}
        centered
        defaultActiveKey="prescriptionDetails"
        onChange={key => setCurrTab(key)}
      >
        <Tabs.TabPane tab="Prescription Details" key="prescriptionDetails">
          <div className="hide-dt show-lg-tablet" style={{ height: 60 }} />
          <PrescriptionDetails
            prescriptionItem={rxDetails}
            onEditClick={editRxFromDrawer}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Adherence Log" key="adherenceLog">
          <AdherenceLog currTab={currTab} />
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
};

export default PrescriptionDrawer;
