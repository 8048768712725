import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getFormValues,
  isAsyncValidating,
  isDirty,
  isPristine,
  isSubmitting,
  isValid,
  reset,
  submit
} from 'redux-form';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { Breadcrumb, Button, Col, Row, Space } from 'antd';
import _ from 'lodash';

import {
  closeModal,
  defaultPatient,
  doneLoading,
  getPatient,
  getPatientAnalyticsData,
  isLoading,
  patientSubmissionTypes,
  postPatient,
  updateCurrentUser,
  updatePatient,
  updatePatientProfile,
  updatePatientSubmissionType
} from './actions/patients';
import { currentUserGroupSelector } from '../exercise-library/selectors';
import { titleCase } from '../groups/stringHelpers';
import { updateUserProfile } from '../users/actions/users';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

import DispatchLinkItem from '../../components/DispatchLinkItem';
import DropdownNavigation from '../../components/DropdownNavigation';
import EditPatientForm from './EditPatientForm';
import LoadingPage from '../../components/LoadingPage';
import PTModal from '../groups/group-admin/components/PTModal';
import PatientForm from './PatientForm';
import PatientProfile from '../../pages/PatientProfile';
import SetLocationForm from './SetLocationForm';
import isEmail from 'validator/lib/isEmail';
import setNotification from '../../utils/setNotification.utils';
import states from '../../states';
import services from '../../services';

class PatientPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      saveEnabled: true,
      redirect: false,
      url: null,
      clickedSubmitBtn: null,
      needsClinic: false,
      locationFormValue: null,
      loading: false,
      hasPendingBill: false
    };

    this.handleEditPatient = this.handleEditPatient.bind(this);
    this.confirmationModal = this.confirmationModal.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(isLoading());

    if (match.params.id !== 'new') {
      dispatch(getPatient('sub', match.params.id)).then((res) => {
        dispatch(updateCurrentUser(res.value));

        if (res.value) {
          this.displayLocationModal(res.value);
          this.setPatientDetailsState(res.value);
        }
      });
    } else {
      dispatch(updateCurrentUser(defaultPatient));
    }

    dispatch(doneLoading());
  }

  componentWillUnmount() {
    setRecoil(states.patients, (prevState) => ({
      ...prevState,
      details: null
    }));

    setRecoil(states.prescription, (prevState) => ({
      ...prevState,
      form: null,
      action: ''
    }));
  }

  setPatientDetailsState = (value) => {
    setRecoil(states.patients, (prevState) => ({
      ...prevState,
      details: {
        emailAddress: value.EmailAddress,
        sub: value.Sub,
        role: value.Role,
        firstName: value.FirstName,
        lastName: value.LastName,
        active: value.Active,
        clinic: value.Clinic,
        discharge: value.Discharge,
        created: value.Created,
        creator: value.Creator,
        providers: value.Providers || [],
        enableRTM: !!value.EnableRTM,
        preferences: value.Preferences
          ? toCamelCaseObjKeys(value.Preferences)
          : null
      }
    }));

    const prescriptionState = getRecoil(states.prescription);
    const rxList = value.Prescriptions.map((item) => {
      if (Object.keys(item).includes('Prescription')) {
        return {
          ...toCamelCaseObjKeys(item)
        };
      } else {
        return {
          prescription: {
            ...item
          }
        };
      }
    });

    setRecoil(states.prescription, {
      ...prescriptionState,
      list: rxList,
      form: rxList.length === 1 ? rxList[0] : null,
      action: 'EDIT'
    });
  };

  displayLocationModal = async (value) => {
    const {
      visibleProfile: {
        Preferences,
        GroupInfo,
        GroupId,
        EmailAddress,
        Clinics
      },
      dispatch
    } = this.props;

    const { Clinic } = value;

    if (Clinics) {
      if (Array.isArray(Clinics) && Clinics.length > 0) {
        if (!!!Clinic) {
          let defaultLocation = Clinics[0].Id;

          let defaultRTMStatus = false;

          if (Preferences?.Location) {
            if (Clinics.some((cl) => cl.Id === Preferences.Location)) {
              defaultLocation = Location;
            }
          }

          if (Preferences?.RTMStatus) {
            defaultRTMStatus = Preferences.RTMStatus === 'enabled';
          }

          if (Clinics.length === 1) {
            if (GroupInfo) {
              if (!!GroupInfo.EnableRTM) {
                this.setState({
                  locationFormValue: {
                    ...value,
                    Clinic: defaultLocation,
                    EnableRTM: defaultRTMStatus
                  },
                  needsClinic: true
                });
              } else {
                const payload = {
                  newValues: {
                    ...value,
                    Clinic: defaultLocation
                  },
                  currentValues: value,
                  group: GroupId,
                  provider: EmailAddress
                };

                dispatch(updatePatientProfile(payload)).then(() =>
                  updateCurrentUser(payload.newValues)
                );
              }
            }
          } else {
            this.setState({
              locationFormValue: {
                ...value,
                Clinic: defaultLocation,
                EnableRTM: defaultRTMStatus
              },
              needsClinic: true
            });
          }
        }
      }
    }
  };

  getAnalyticsData = (sub, rtmEnabled) => {
    const { dispatch, visibleProfile } = this.props;
    const { GroupId, GroupInfo } = visibleProfile;

    if (!GroupInfo.EnableRTM || !rtmEnabled) {
      return;
    }

    dispatch(getPatientAnalyticsData(GroupId, sub, GroupId)).then((res) => {
      setRecoil(states.rtm, (prevState) => ({
        ...prevState,
        analytics: [
          ..._.uniqBy([...prevState.analytics, res.value], 'Sub')
        ].sort((a, b) => {
          const aFname = a.FirstName?.trim().toLowerCase() || '';
          const aLname = a.LastName?.trim().toLowerCase() || '';

          const bFname = b.FirstName?.trim().toLowerCase() || '';
          const bLname = b.LastName?.trim().toLowerCase() || '';

          if (aFname + aLname > bFname + bLname) {
            return 1;
          }

          return -1;
        })
      }));
    });
  };

  handleSubmit = (form) => {
    const {
      dispatch,
      group,
      match,
      lastPatientSubmissionType,
      clinics,
      visibleProfile
    } = this.props;

    const values = { ...form };
    if (!!visibleProfile?.GroupInfo.EnablePhoneNumber) {
      values.contact = values.contact.trim();

      if (isEmail(values.contact)) {
        values.emailAddress = values.contact;
        delete values.phoneNumber;
      } else {
        const phoneNumber = values.contact.replace(/[()\s\-]/g, '');

        let value = phoneNumber;
        if (value.length < 11 && !value.startsWith('+1')) {
          value = '+1' + phoneNumber;
        } else if (value.length < 12 && !value.startsWith('+')) {
          value = '+' + phoneNumber;
        }

        values.phoneNumber = value;
        delete values.emailAddress;
      }

      delete values.contact;
    }

    if (!values.location && clinics && clinics[0] && clinics[0].Id) {
      values.location = clinics[0].Id;
    }

    _.each(values, (val, key) => {
      if (typeof val === 'string') {
        values[key] = val.trim();
        values[key] = val.toLowerCase();
      }
    });

    const params = {
      ...values,
      creator: visibleProfile.Sub,
      active: true
    };

    let submitAction;
    if (match.params.id !== 'new') {
      updatePatient(visibleProfile.GroupId, params);
      dispatch(closeModal);
    } else {
      let messagePreference = 'enabled';
      if (
        !!visibleProfile.GroupInfo.EnableRTM &&
        !!visibleProfile.GroupInfo.EnableRTMMessaging &&
        !!!params.enableRTM
      ) {
        messagePreference = 'disabled';
      }

      submitAction = postPatient(group.name, {
        ...params,
        groupInfo: visibleProfile.GroupInfo,
        groupId: visibleProfile.GroupId,
        ptFirstName: visibleProfile.FirstName,
        ptLastName: visibleProfile.LastName,
        messaging: messagePreference
      });
    }

    return dispatch(submitAction)
      .then(({ action }) => {
        if (
          action.payload === 'Success' ||
          action.payload.message === 'Success'
        ) {
          const { data } = action.payload;
          this.getAnalyticsData(data.Sub, data.EnableRTM);

          const { Role, Sub } = visibleProfile;
          services.message.getBoards(Role, Sub).then((res) => {
            if (res && Array.isArray(res.data) && res.data.length > 0) {
              setRecoil(states.messages, {
                list: res.data
              });
            }
          });

          if (match.params.id !== 'new') {
            setNotification('success', 'Success!', 'Changes has been saved.');
          } else if (
            lastPatientSubmissionType === 'SAVE_AND_RX' ||
            lastPatientSubmissionType === 'SAVE_AND_CARE'
          ) {
            setNotification('success', 'Success!', 'Patient has been created.');
          } else {
            setNotification(
              'success',
              'Success!',
              `Search then select the patient's name to add an exercise program.`
            );
          }

          if (match.params.id === 'new') {
            services.patients.getPatientDetails('sub', data.Sub).then((res) => {
              this.setPatientDetailsState(res.data);
            });

            let url = '/patients';

            if (
              lastPatientSubmissionType ===
                patientSubmissionTypes.SAVE_AND_RX ||
              lastPatientSubmissionType === patientSubmissionTypes.SAVE_AND_CARE
            ) {
              url += `/${data.Sub}`;
            }

            this.setState({ redirect: true, url });
          }
        } else {
          setNotification(
            'error',
            'Error!',
            'An error occurred while saving patient.'
          );
        }
      })
      .catch((error) => {
        error.json().then((err) => {
          let message = 'An error occurred while saving patient.';
          if (err.code === 'UsernameExistsException') {
            message = err.message;
          }

          setNotification('error', 'Error!', message);
        });
      });
  };

  async handleEditPatient(editPatientValues) {
    const {
      dispatch,
      patients: { currentPatient },
      visibleProfile: { GroupId, EmailAddress, Sub, Preferences }
    } = this.props;

    this.setState({
      saveEnabled: false,
      loading: true
    });

    let promptLocationPopupSuccess = true;
    if (!!editPatientValues.PromptLocationPopup) {
      const preferencesObj = {
        ...Preferences,
        PromptLocationPopup: 'disabled'
      };

      try {
        await dispatch(updateUserProfile(Sub, preferencesObj)).then(() => {
          promptLocationPopupSuccess = true;
        });
      } catch (error) {
        promptLocationPopupSuccess = false;
      }

      delete editPatientValues.PromptLocationPopup;
    }

    delete editPatientValues.state;
    delete currentPatient.state;

    _.each(editPatientValues, (val, key) => {
      if (typeof val === 'string') editPatientValues[key] = val.trim();
    });

    const values = {
      ...editPatientValues
    };

    if (values.PhoneNumber) {
      const phoneNumber = values.PhoneNumber.replace(/[()\s\-]/g, '');

      let value = phoneNumber;
      if (value.length < 11 && !value.startsWith('+1')) {
        value = '+1' + phoneNumber;
      } else if (value.length < 12 && !value.startsWith('+')) {
        value = '+' + phoneNumber;
      }

      values.PhoneNumber = value;
    }

    const payload = {
      newValues: values,
      currentValues: currentPatient,
      group: GroupId,
      provider: EmailAddress
    };

    let notifType = 'success';
    let notifHead = 'Success!';
    let notifDesc = 'Changes has been saved.';

    try {
      await dispatch(updatePatientProfile(payload)).then((res) => {
        const updatedData = res.value;

        if (promptLocationPopupSuccess) {
          dispatch(closeModal);

          dispatch(
            getPatientAnalyticsData(GroupId, updatedData.Sub, GroupId)
          ).then((rtm) => {
            setRecoil(states.rtm, (prevState) => {
              const prevArr = [...prevState.analytics];
              const newData = {
                ...rtm.value
              };

              let dataArr = [];
              if (!newData.Active) {
                dataArr = prevArr.filter(
                  (item) => item.Sub !== updatedData.Sub
                );
              } else {
                dataArr = prevArr.map((item) => {
                  if (item.Sub !== updatedData.Sub) {
                    return item;
                  }

                  return newData;
                });
              }

              return {
                ...prevState,
                analytics: _.uniqBy(dataArr, 'Sub')
              };
            });
          });

          setRecoil(states.messages, (prevState) => ({
            ...prevState,
            list: [...prevState.list].map((item) => {
              const fname = updatedData.FirstName;
              const lname = updatedData.LastName;

              if (item.Owner !== Sub) {
                return item;
              }

              return {
                ...item,
                FirstName: fname,
                LastName: lname
              };
            })
          }));

          dispatch(
            updateCurrentUser({
              ...currentPatient,
              ...updatedData
            })
          );

          setRecoil(states.patients, (prevState) => ({
            ...prevState,
            details: {
              ...prevState.details,
              firstName: updatedData.FirstName,
              lastName: updatedData.LastName,
              active: updatedData.Active,
              discharge: updatedData.Discharge ?? false,
              enableRTM: !!updatedData.EnableRTM,
              preferences: updatedData.Preferences
                ? toCamelCaseObjKeys(updatedData.Preferences)
                : null
            }
          }));
        }

        if (updatedData.Role === 'p') {
          setRecoil(states.rtm, (prevState) => ({
            ...prevState,
            timer: {
              ...prevState.timer,
              patient: {
                ...prevState.timer.patient,
                EnableRTM: !!updatedData.EnableRTM
              }
            }
          }));
        }
      });
    } catch (error) {
      notifType = 'error';
      notifHead = 'Error!';
      notifDesc = 'An error occurred while saving patient.';
    } finally {
      this.setState({
        saveEnabled: true,
        needsClinic: false,
        loading: false,
        locationFormValue: null
      });

      dispatch(doneLoading());
      setNotification(notifType, notifHead, notifDesc);
    }
  }

  confirmationModal() {
    const {
      group,
      dispatch,
      patients: { currentPatient, modalOpen },
      clinics,
      visibleProfile,
      patientForm,
      isFormValid,
      isFormSubmitting,
      isFormPristine
    } = this.props;
    const { hasPendingBill } = this.state;

    const patientState = getRecoil(states.patients);
    const saveEnabled =
      !isFormValid ||
      isFormSubmitting ||
      isFormPristine ||
      patientState.loading ||
      !(patientForm.EmailAddress || patientForm.PhoneNumber);

    currentPatient &&
      Object.keys(currentPatient).forEach((val) => {
        if (
          currentPatient[val] &&
          (val === 'firstName' ||
            val === 'lastName' ||
            val === 'city' ||
            val === 'contactName' ||
            val === 'address')
        ) {
          currentPatient[val] = titleCase(currentPatient[val]);
        }
      });

    return (
      <PTModal
        header="Edit Patient Profile"
        showModal={modalOpen}
        close={() => {
          dispatch(reset('patient'));
          this.handleClose();
        }}
        save={() => dispatch(submit('patient'))}
        saveEnabled={!saveEnabled}
        width={750}
      >
        <EditPatientForm
          onSubmit={this.handleEditPatient}
          initialValues={currentPatient}
          enableReinitialize
          group={group}
          groupClinics={clinics}
          rtmEnabled={visibleProfile.GroupInfo.EnableRTM}
          hasPendingBill={hasPendingBill}
          patientFormValues={patientForm}
          visibleProfile={visibleProfile}
        />
      </PTModal>
    );
  }

  setLocationModal() {
    const { needsClinic, locationFormValue, loading } = this.state;
    const { group, clinics, visibleProfile, patientForm } = this.props;
    const { GroupInfo, Preferences } = visibleProfile;

    let showModal = needsClinic;
    let rtmEnabled = false;
    let header = 'Please Add a Location';

    if (!!GroupInfo?.EnableRTM) {
      header = 'Please Add a Location and RTM Status';
      rtmEnabled = !!GroupInfo?.EnableRTM;

      if (!clinics || (Array.isArray(clinics) && !clinics.length)) {
        header = 'Please Add RTM Status';
      }
    }

    showModal = Preferences?.PromptLocationPopup === 'enabled' || false;
    rtmEnabled = Preferences?.RTMStatus === 'enabled' || false;

    const handleSave = () => {
      this.props.dispatch(submit('patient'));
    };

    const handleClose = () => {
      this.setState({
        needsClinic: false
      });
    };

    const handleSubmitForm = async (values) => {
      await this.handleEditPatient(values).then(() => {
        this.setState({
          locationFormValue: null,
          needsClinic: false
        });
      });
    };

    return locationFormValue ? (
      <PTModal
        width={400}
        showModal={showModal}
        header={header}
        closable={false}
        maskClosable={false}
        loading={loading}
        save={handleSave}
        close={handleClose}
      >
        <SetLocationForm
          onSubmit={handleSubmitForm}
          initialValues={{
            ...locationFormValue,
            Clinic: visibleProfile.Preferences?.Location
          }}
          enableReinitialize
          group={group}
          groupClinics={clinics}
          rtmEnabled={rtmEnabled}
          patientFormValues={patientForm}
        />
      </PTModal>
    ) : null;
  }

  handleClose = () => {
    const {
      props: { dispatch }
    } = this;
    dispatch(closeModal);
  };

  newPatientFormValues = (currentPatient, clinics, visibleProfile) => {
    const initialValues = { ...currentPatient };
    const { Preferences } = visibleProfile;

    let defaultLocation;
    if (clinics) {
      if (Array.isArray(clinics) && clinics.length > 0) {
        if (clinics.length === 1) {
          defaultLocation = clinics[0].Id;
        } else {
          if (Preferences) {
            const { Location } = Preferences;
            if (Location) {
              if (clinics.some((cl) => cl.Id === Location)) {
                defaultLocation = Location;
              }
            }
          }
        }
      }
    }

    let defaultRTMStatus = false;
    const { GroupInfo } = visibleProfile;
    if (!!GroupInfo.EnableRTM) {
      if (Preferences) {
        const { RTMStatus } = Preferences;
        if (RTMStatus) {
          defaultRTMStatus = RTMStatus === 'enabled';
        }
      }
    }

    if (defaultLocation) {
      initialValues.location = defaultLocation;
    }

    return { ...initialValues, enableRTM: defaultRTMStatus };
  };

  setHasPendingBill = (value) => {
    this.setState({ hasPendingBill: value });
  };

  render() {
    const {
      isFormValid,
      isFormPristine,
      isLoading,
      isFetching,
      isFormSubmitting,
      isAsyncValidating,
      group,
      dispatch,
      match,
      location,
      patients: { currentPatient },
      visibleProfile,
      clinics
    } = this.props;

    const { redirect, url, clickedSubmitBtn, hasPendingBill } = this.state;
    const patientName = this.props.match.params.id;

    let breadcrumb;
    switch (location.pathname) {
      case `/patients/${match.params.id}`:
        breadcrumb = (
          <Breadcrumb separator="/">
            <DispatchLinkItem
              title="Patients"
              url="/patients"
              dispatch={dispatch}
            />
            <Breadcrumb.Item>
              {patientName === 'new' ? 'Add Patient' : 'Profile'}
            </Breadcrumb.Item>
          </Breadcrumb>
        );
        break;
      case `/patients/${match.params.id}/prescription`:
        breadcrumb = (
          <Breadcrumb
            separator="/"
            onClick={(e) => {
              const title = e.target.textContent;
              const route = title?.toLowerCase();

              if (route === 'profile') {
                setRecoil(states.prescription, (prevState) => {
                  const isSingleRx = prevState.list.length === 1;
                  const currRxForm = prevState.list[0];
                  const nextState = {
                    ...prevState
                  };

                  if (isSingleRx) {
                    nextState.form = currRxForm;
                  }

                  return nextState;
                });
              }
            }}
          >
            <DispatchLinkItem
              title="Patients"
              url="/patients"
              dispatch={dispatch}
            />
            <DispatchLinkItem
              title="Profile"
              url={`/patients/${match.params.id}`}
              dispatch={dispatch}
              patient={currentPatient}
              visibleProfile={{
                GroupName: visibleProfile.GroupInfo.Name,
                Provider: visibleProfile.EmailAddress
              }}
            />
            <Breadcrumb.Item>Edit Rx</Breadcrumb.Item>
          </Breadcrumb>
        );
        break;
      case `/patients/${match.params.id}/care-plan`:
        breadcrumb = (
          <Breadcrumb separator="/">
            <DispatchLinkItem
              title="Patients"
              url="/patients"
              dispatch={dispatch}
            />
            <DispatchLinkItem
              title="Profile"
              url={`/patients/${match.params.id}`}
              dispatch={dispatch}
              patient={currentPatient}
              visibleProfile={{
                GroupName: visibleProfile.GroupInfo.Name,
                Provider: visibleProfile.EmailAddress
              }}
            />
            <Breadcrumb.Item>Edit Care Plan</Breadcrumb.Item>
          </Breadcrumb>
        );
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        {redirect && <Redirect to={url} />}
        {this.confirmationModal()}
        {this.setLocationModal()}
        <DropdownNavigation />
        <div className="ptw-main-body">{breadcrumb}</div>
        {isLoading && isFetching ? (
          <div className="ptw-main-body">
            <LoadingPage
              type="list"
              content="Loading patient details, please wait..."
            />
          </div>
        ) : (
          <React.Fragment>
            {patientName === 'new' ? (
              <div className="ptw-main-body">
                <PatientForm
                  onSubmit={this.handleSubmit}
                  initialValues={this.newPatientFormValues(
                    currentPatient,
                    clinics,
                    visibleProfile
                  )}
                  enableReinitialize
                  group={group}
                  groupClinics={clinics}
                  visibleProfile={visibleProfile}
                />
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Space className="patient-form-btn-group" size={8}>
                      <Button
                        className="ptw-btn btn-primary"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={
                          clickedSubmitBtn === patientSubmissionTypes.SAVE &&
                          isFormSubmitting
                        }
                        disabled={
                          !isFormValid ||
                          isAsyncValidating ||
                          isFormSubmitting ||
                          isFormPristine ||
                          isFetching
                        }
                        onClick={async () => {
                          this.setState({
                            clickedSubmitBtn: patientSubmissionTypes.SAVE
                          });
                          await dispatch(
                            updatePatientSubmissionType(
                              patientSubmissionTypes.SAVE
                            )
                          );
                          await dispatch(submit('patient'));
                        }}
                      >
                        Save
                      </Button>
                      {/* Save and add prescription */}
                      {!visibleProfile.GroupInfo.EnablePTU && (
                        <Button
                          className="ptw-btn btn-primary"
                          type="primary"
                          htmlType="submit"
                          size="large"
                          loading={
                            clickedSubmitBtn ===
                              patientSubmissionTypes.SAVE_AND_RX &&
                            isFormSubmitting
                          }
                          disabled={
                            !isFormValid ||
                            isAsyncValidating ||
                            isFormSubmitting ||
                            isFormPristine ||
                            isFetching
                          }
                          onClick={async () => {
                            this.setState({
                              clickedSubmitBtn:
                                patientSubmissionTypes.SAVE_AND_RX
                            });
                            await dispatch(
                              updatePatientSubmissionType(
                                patientSubmissionTypes.SAVE_AND_RX
                              )
                            );
                            await dispatch(submit('patient'));
                          }}
                        >
                          Save &amp; Add Prescription
                        </Button>
                      )}
                      {/* Save and add Care Plan */}
                      {visibleProfile.GroupInfo.EnablePTU && (
                        <Button
                          className="ptw-btn btn-primary"
                          type="primary"
                          htmlType="submit"
                          size="large"
                          loading={
                            clickedSubmitBtn ===
                              patientSubmissionTypes.SAVE_AND_CARE &&
                            isFormSubmitting
                          }
                          disabled={
                            !isFormValid ||
                            isAsyncValidating ||
                            isFormSubmitting ||
                            isFormPristine ||
                            isFetching
                          }
                          onClick={async () => {
                            this.setState({
                              clickedSubmitBtn:
                                patientSubmissionTypes.SAVE_AND_CARE
                            });
                            await dispatch(
                              updatePatientSubmissionType(
                                patientSubmissionTypes.SAVE_AND_CARE
                              )
                            );
                            await dispatch(submit('patient'));
                          }}
                        >
                          Save &amp; Add Care Plan
                        </Button>
                      )}
                      <Link to="/patients">
                        <Button className="ptw-btn" type="default" size="large">
                          Cancel
                        </Button>
                      </Link>
                    </Space>
                  </Col>
                </Row>
              </div>
            ) : (
              <PatientProfile
                patientId={patientName}
                groupClinics={clinics}
                hasPendingBill={hasPendingBill}
                setHasPendingBill={this.setHasPendingBill}
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  patients: state.patients,
  group: currentUserGroupSelector(state),
  isLoading: state.patients.isLoading,
  isFetching: state.patients.isFetching,
  isFormValid: isValid('patient')(state),
  isFormDirty: isDirty('patient')(state),
  isAsyncValidating: isAsyncValidating('patient')(state),
  isFormSubmitting: isSubmitting('patient')(state),
  isFormPristine: isPristine('patient')(state),
  patient: state.form.patient,
  modalOpen: state.patients.modalOpen,
  lastPatientSubmissionType: state.patients.lastPatientSubmissionType,
  clinics: state.visibleProfile.Clinics,
  visibleProfile: state.visibleProfile,
  patientAnalytics: state.patients.patientAnalytics,
  patientForm: getFormValues('patient')(state)
});

export default withRouter(connect(mapStateToProps)(PatientPage));
