import React, { Fragment, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Table,
  Tooltip,
  Button,
  Space,
  Empty,
  Skeleton,
  Typography,
  Popconfirm,
  notification,
} from 'antd';
import {
  CheckCircleFilled,
  EditOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';

import moment from 'moment';
import services from '../../services';
import { getPlan } from './actions/patients';

const Description = ({ label, value }) => (
  <Typography.Text>
    <strong>{label}: </strong>
    {value}
  </Typography.Text>
);

const PTUAdherenceLog = props => {
  const { dispatch, currentPatient, onEditClick, openCarePlan } = props;

  const [loading, setLoading] = useState(false);
  const [carePlan, setCarePlan] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (currentPatient) {
      getCarePlan();
    }
  }, [currentPatient]);

  const getCarePlan = async () => {
    setLoading(true);

    try {
      const {
        GroupInfo: { Name },
        Sub,
      } = currentPatient;

      const plan = await dispatch(getPlan(Name, Sub));
      if (plan) {
        if (plan.value.data.length > 0) {
          setCarePlan(plan.value.data);
        } else {
          setHasError(true);
        }
      }
    } catch (err) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCareplan = async item => {
    try {
      setDeleting(true);

      const { Id, Sub } = item;
      const { EmailAddress } = currentPatient;
      await services.careplan.disablePlan({
        active: false,
        id: Id,
        sub: Sub,
        emailAddress: EmailAddress,
      });

      notification.success({
        type: 'success',
        message: 'Success!',
        description: 'Care plan deleted successfully.',
      });

      const list = carePlan.filter(plan => plan.Id !== Id);
      if (list.length) {
        setCarePlan(list);
      } else {
        setCarePlan(null);
        setHasError(true);
      }
    } catch (error) {
      notification.success({
        type: 'error',
        message: 'Error!',
        description: 'An error occurred while deleting care plan.',
      });
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className="ptw-collapse-body">
      <div className="tab-header">
        <div />

        {/* <Space direction="horizontal" size={20}>
          <Tooltip title="Edit Care Plan">
            <Button
              onClick={onEditClick}
              shape="circle"
              type="link"
              className="btn-link"
              icon={<EditOutlined style={{ fontSize: 24 }} />}
            />
          </Tooltip>
        </Space> */}
      </div>

      <Row gutter={[16, 16]}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Skeleton active size="small" loading={loading} />

          {!loading && hasError && (
            <div style={{ textAlign: 'center' }}>
              <Empty
                description="No Care Plan Found"
                style={{ marginBottom: 8 }}
              />

              <Button type="primary" onClick={openCarePlan}>
                Add Care Plan
              </Button>
            </div>
          )}

          {!loading &&
            carePlan &&
            carePlan.map((plan, index) => {
              const videoData = plan.Videos || [];
              const data = [
                {
                  video: <strong>Watched</strong>,
                  ...videoData.reduce(
                    (acc, curr, i) => (
                      (acc[`video${i}`] = curr.Watched ? (
                        <CheckCircleFilled className="table-check-icon" />
                      ) : (
                        <span>
                          <FieldTimeOutlined className="table-schedule-icon" />
                          &nbsp;
                          {moment(Number(curr.DripDate)).format('MMM DD YYYY')}
                        </span>
                      )),
                      acc
                    ),
                    {}
                  ),
                },
              ].map((item, i) => ({
                ...item,
                key: i,
              }));

              const columns = [
                {
                  title: <strong>PTU Video</strong>,
                  dataIndex: 'video',
                  key: 'video',
                  width: 125,
                  fixed: 'left',
                },
                ...videoData.map((videoItem, i) => ({
                  title: videoItem.Title,
                  dataIndex: `video${i}`,
                  key: `video${i}`,
                  width: 125,
                })),
              ];

              return (
                <Fragment key={index}>
                  <Space
                    className="hide-lg-tablet"
                    size="large"
                    style={{ marginBottom: 6 }}
                  >
                    <Description
                      label="Total Sessions per Episode"
                      value={carePlan[index]['Session']}
                    />

                    <Description
                      label="Duration (in Months)"
                      value={carePlan[index]['Duration']}
                    />

                    <Description
                      label="Re-Evaluations (in Weeks)"
                      value={carePlan[index]['Evaluation']}
                    />

                    <Popconfirm
                      title="Are you sure you want to delete this assigned care plan?"
                      okText="Delete"
                      okButtonProps={{ danger: true }}
                      onConfirm={() => handleDeleteCareplan(plan)}
                    >
                      <Button danger type="primary" loading={deleting}>
                        DELETE CAREPLAN
                      </Button>
                    </Popconfirm>
                  </Space>

                  <Row
                    gutter={[16, 16]}
                    align="center"
                    justify="start"
                    className="hide-dt flex-lg-tablet mb-4"
                  >
                    <Col lg={6} md={6} sm={12} xs={24}>
                      <Description
                        label="Total Sessions per Episode"
                        value={carePlan[index]['Session']}
                      />
                    </Col>

                    <Col lg={6} md={6} sm={12} xs={24}>
                      <Description
                        label="Duration (in Months)"
                        value={carePlan[index]['Duration']}
                      />
                    </Col>

                    <Col lg={6} md={6} sm={12} xs={24}>
                      <Description
                        label="Re-Evaluations (in Weeks)"
                        value={carePlan[index]['Evaluation']}
                      />
                    </Col>

                    <Col lg={6} md={6} sm={12} xs={24}>
                      <Popconfirm
                        title="Are you sure you want to delete this assigned care plan?"
                        okText="Delete"
                        okButtonProps={{ danger: true }}
                        onConfirm={() => handleDeleteCareplan(plan)}
                      >
                        <Button danger type="primary" loading={deleting}>
                          DELETE CAREPLAN
                        </Button>
                      </Popconfirm>
                    </Col>
                  </Row>

                  <Table
                    size="small"
                    columns={columns}
                    dataSource={data}
                    scroll={{ x: 'max-content' }}
                    sticky={true}
                    pagination={false}
                  />
                  <br />
                </Fragment>
              );
            })}
        </Col>
      </Row>
    </div>
  );
};

export default PTUAdherenceLog;
