import React from 'react';
import { Typography, Empty } from 'antd';
import '../loadingPage.css';

const NoResults = props => {
  const { content, imageStyle } = props;

  return (
    <div className="list-loading-container">
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<Typography.Text>{content}</Typography.Text>}
        imageStyle={imageStyle && imageStyle}
      />
    </div>
  );
};

export default NoResults;
