import { createSelector } from 'reselect';
import m from 'moment';

export const visibleProfileSelector = state => state.visibleProfile;

export const exercisesSelector = createSelector(
  visibleProfileSelector,
  visibleProfile =>
    visibleProfile &&
    visibleProfile.Prescriptions &&
    visibleProfile.Prescriptions[0].Prescription
      ? visibleProfile.Prescriptions[0].Prescription.exercises
      : visibleProfile.Prescriptions[0].exercises
);

export const activeExercisesSelector = createSelector(
  exercisesSelector,
  exercises => exercises && exercises.filter(e => !e.deleted)
);

export const completedExercisesSelector = createSelector(
  activeExercisesSelector,
  activeExercises => {
    const completedExercises = [];

    if (Array.isArray(activeExercises)) {
      for (let i = 0; i < activeExercises.length; i++) {
        const exercise = activeExercises[i];
        const { completionDates } = exercise;

        if (!Array.isArray(completionDates)) {
          return [];
        }

        const date = m().format('MM/DD/YYYY');

        for (let j = 0; j < completionDates; j++) {
          const completionDate = completionDates[j];
          if (date === completionDate) {
            completedExercises.push(exercise);
          }
        }
      }
      return completedExercises;
    } else {
      return [];
    }
  }
);
