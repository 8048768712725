import { atom } from 'recoil';

const defaultState = {
  list: [],
  details: null,
  user: null,
  clinics: [],
  providers: []
};

const GROUPS_STATE = atom({
  key: 'GROUPS_STATE',
  default: defaultState
});

export default GROUPS_STATE;
