import { useState } from 'react';
import { connect } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import {
  Form,
  Input,
  Select,
  Button,
  Upload,
  List,
  Tooltip,
  Modal,
  Space,
  Divider,
  notification,
} from 'antd';
import {
  DeleteOutlined,
  FileOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { categories, formatCategory } from '../../../utils/educationalPdf';
import { toCamelCaseObjKeys } from '../../../utils/object.utils';

import services from '../../../services';
import states from '../../../states';

const toBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);

    reader.readAsDataURL(file);
  });
};

const UploadPDFModal = ({ showModal, onClose, visibleProfile }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const setPdf = useSetRecoilState(states.pdf);

  const handleRemove = () => {
    setFile(null);
  };

  const handleSubmit = async values => {
    try {
      setLoading(true);

      const { Role, Sub } = visibleProfile;
      const isSuperAdmin = Role === 'sa';
      const owner = isSuperAdmin ? '2667b98c-4e98-4cdb-b03a-959ed7a7f435' : Sub;

      const base64 = await toBase64(file);
      const pdfStr = base64.replace(/^data:application\/pdf;base64,/, '');

      const payload = {
        Owner: owner,
        Title: values.title,
        Category: values.category,
      };

      const response = await services.educationalPdf.addPdf({
        ...payload,
        File: pdfStr,
      });

      if (response.status === 200) {
        notification.success({
          message: 'Upload PDF Success',
          description: 'PDF has been uploaded successfully.',
        });

        setPdf(prevState => {
          const updatedList = [...prevState.list].map(item => {
            if (item.category === values.category) {
              return {
                category: item.category,
                data: [
                  ...item.data,
                  {
                    ...toCamelCaseObjKeys(response.data),
                  },
                ],
              };
            }

            return item;
          });

          return {
            ...prevState,
            list: updatedList,
          };
        });

        form.resetFields();
        onClose();
      }
    } catch (error) {
      notification.error({
        message: 'Upload PDF Failed',
        description: 'An error occurred while uploading the PDF.',
      });
    } finally {
      setLoading(false);
    }
  };

  const validateFileSize = file => {
    return file.size <= 4500000;
  };

  const uploadProps = {
    name: 'pdfFile',
    accept: 'application/pdf',
    multiple: false,
    maxCount: 1,
    beforeUpload: file => {
      if (!validateFileSize(file)) {
        notification.error({
          message: 'File Size Error',
          description: 'File size must not exceed 4.5MB.',
        });

        return false;
      }

      setFile(file);
      return false;
    },
    itemRender: () => {
      if (!file) {
        return null;
      }

      return (
        <List>
          <List.Item>
            <List.Item.Meta
              title={file.name}
              avatar={
                <FileOutlined
                  style={{
                    fontSize: 24,
                  }}
                />
              }
            />

            <Tooltip title="Remove">
              <Button
                danger
                shape="circle"
                type="text"
                icon={<DeleteOutlined />}
                onClick={handleRemove}
              />
            </Tooltip>
          </List.Item>
        </List>
      );
    },
  };

  return (
    <Modal
      closable
      destroyOnClose
      centered
      maskClosable={false}
      open={showModal}
      onCancel={onClose}
      footer={null}
      title="Upload PDF"
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 8,
        paddingTop: 12,
      }}
    >
      <Form
        layout="vertical"
        form={form}
        onReset={onClose}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: 'Please enter PDF title.',
            },
          ]}
          style={{
            marginLeft: 24,
            marginRight: 24,
          }}
        >
          <Input type="text" placeholder="Enter your title" size="large" />
        </Form.Item>

        <Form.Item
          label="Category"
          name="category"
          rules={[
            {
              required: true,
              message: 'Please select a category.',
            },
          ]}
          style={{
            marginLeft: 24,
            marginRight: 24,
          }}
        >
          <Select placeholder="Select a category" showSearch size="large">
            {categories.map(category => (
              <Select.Option key={category} value={category}>
                {formatCategory(category)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          help="Upload PDF file with a maximum size of 4.5MB."
          style={{
            marginLeft: 24,
            marginRight: 24,
          }}
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>

        <Divider
          style={{
            marginTop: 0,
            marginBottom: 16,
          }}
        />

        <Form.Item
          style={{
            textAlign: 'right',
            marginLeft: 24,
            marginRight: 24,
            marginBottom: 12,
          }}
        >
          <Space size="middle">
            <Button htmlType="reset">Cancel</Button>

            <Button
              type="primary"
              htmlType="submit"
              disabled={!file}
              loading={loading}
            >
              Upload
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = states => ({
  visibleProfile: states.visibleProfile,
});

export default connect(mapStateToProps)(UploadPDFModal);
