import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { Redirect } from 'react-router-dom';
import {
  Modal,
  Form,
  Select,
  Button,
  Radio,
  Row,
  Col,
  Image,
  Typography,
  Card
} from 'antd';
import { isMobile } from 'react-device-detect';
import services from '../../../../services';
import { languages } from '../../../../I18n/TranslateText';
import setNotification from '../../../../utils/setNotification.utils';

import PrintPreview from './PrintPreview';
import LayoutA from '../../../../img/A.png';
import LayoutB from '../../../../img/B.png';
import LayoutC from '../../../../img/C.png';
import { useRecoilValue } from 'recoil';
import states from '../../../../states';

const PrintPreviewModal = ({ open, handleClose, printProps }) => {
  const { currentPatient } = printProps;

  const [fetching, setFetching] = useState(false);
  const [creatorEmail, setCreatorEmail] = useState(null);
  const [clinicEmail, setClinicEmail] = useState(null);
  const [clinicPhone, setClinicPhone] = useState(null);
  const [formValues, setFormValues] = useState({
    currLanguage: 'en',
    currLayout: 'A',
    currEmail: null
  });
  const [redirect, setRedirect] = useState(false);
  const [form] = Form.useForm();

  const groups = useRecoilValue(states.groups);
  const printRef = useRef(null);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (formValues.currLanguage) {
      setFetching(true);

      setTimeout(() => {
        setFetching(false);
      }, 2500);
    }
  }, [formValues.currLanguage]);

  const init = async () => {
    try {
      setFetching(true);

      await getCreatorEmail(currentPatient.Creator);
      await getClinicEmail();
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while fetching data.'
      );
    } finally {
      setFetching(false);
    }
  };

  const getCreatorEmail = async (sub) => {
    let emailAddress;
    if (sub.includes('@')) {
      emailAddress = sub;
    } else {
      const response = await services.user.getDetails('sub', sub);
      const { data } = response;

      emailAddress = data.EmailAddress;
    }

    if (groups.user.printEmail && groups.user.printEmail === 'provider') {
      setFormValues({
        ...formValues,
        currEmail: emailAddress
      });
    }

    setCreatorEmail(emailAddress);
  };

  const getClinicEmail = async () => {
    let clinicList = [];
    if (currentPatient.Clinics) {
      clinicList = [...currentPatient.Clinics];
    } else {
      const response = await services.groups.getClinics(currentPatient.GroupId);

      if (response.status === 200) {
        clinicList = [...response.data];
      }
    }

    const clinicInfo = clinicList.find(
      (item) => String(item.Id) === String(currentPatient.Clinic)
    );

    if (groups.user.printEmail && groups.user.printEmail === 'clinic') {
      setFormValues({
        ...formValues,
        currEmail: clinicInfo?.ClinicEmail
      });
    }
    setClinicPhone(
      clinicInfo?.ClinicPhoneNumber || currentPatient.GroupInfo.PhoneNumber
    );
    setClinicEmail(clinicInfo?.ClinicEmail);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const settingProps = {
    currLocale: formValues.currLanguage,
    currLayout: formValues.currLayout,
    currEmail: formValues.currEmail,
    currPhone: clinicPhone
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: '/print-preview',
          state: {
            settingProps,
            printProps
          }
        }}
      />
    );
  }

  return (
    <Modal
      title="Print Settings"
      open={open}
      onCancel={handleClose}
      maskClosable={false}
      destroyOnClose
      width={950}
      centered
      bodyStyle={{
        height: 560,
        overflow: 'hidden',
        padding: 0
      }}
      footer={[
        !isMobile || !/Mobi|Android/i.test(navigator.userAgent) ? (
          <ReactToPrint
            onBeforeGetContent={() => {
              document.title = `${currentPatient.FirstName}_${currentPatient.LastName}`;
            }}
            onAfterPrint={() => {
              document.title = 'PT Wired';
            }}
            removeAfterPrint={true}
            content={() => printRef.current}
            trigger={() => (
              <Button type="primary" className="ptw-btn" loading={fetching}>
                {fetching ? 'Loading print contents...' : 'Print'}
              </Button>
            )}
          />
        ) : (
          <Button
            type="primary"
            className="ptw-btn"
            onClick={() => setRedirect(true)}
          >
            Print Mobile
          </Button>
        ),
        <Button
          type="default"
          className="ptw-btn"
          onClick={() => {
            form.resetFields();

            setFormValues({
              currLanguage: 'en',
              currLayout: 'A',
              currEmail: creatorEmail
            });
            handleClose();
          }}
        >
          Cancel
        </Button>
      ]}
    >
      <div
        style={{
          height: 560,
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 24
        }}
      >
        <Form form={form} labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
          <Form.Item
            label="Language"
            name="currLanguage"
            help="Select the language you'd like to use for this printout."
          >
            <Select
              showSearch
              placeholder="Select a language"
              name="currLanguage"
              defaultValue={formValues.currLanguage}
              value={formValues.currLanguage}
              onChange={(val) =>
                handleChange({ target: { name: 'currLanguage', value: val } })
              }
              filterOption={(input, option) => {
                if (option && option.label) {
                  const label = String(option.label).toLowerCase();
                  if (label.includes(String(input).toLowerCase())) {
                    return option;
                  }
                }
              }}
              options={languages
                .sort((a, b) => a.name - b.name)
                .map((l) => ({
                  value: l.locale,
                  label: l.name
                }))}
            />
          </Form.Item>
          <Form.Item label="Layout" name="currLayout" style={{ marginTop: 36 }}>
            <Radio.Group name="currLayout" defaultValue={formValues.currLayout}>
              <Row gutter={[16, 16]}>
                <Col span={8} align="center">
                  <Radio value="A" onChange={handleChange}>
                    <Image
                      src={LayoutA}
                      preview={false}
                      alt="Layout A"
                      className={
                        formValues.currLayout === 'A' && 'selected-layout'
                      }
                    />
                    <Typography.Text>4 Exercises Per Page</Typography.Text>
                  </Radio>
                </Col>
                <Col span={8} align="center">
                  <Radio value="B" onChange={handleChange}>
                    <Image
                      src={LayoutB}
                      preview={false}
                      alt="Layout B"
                      className={
                        formValues.currLayout === 'B' && 'selected-layout'
                      }
                    />
                    <Typography.Text>2 Exercises Per Page</Typography.Text>
                  </Radio>
                </Col>
                <Col span={8} align="center">
                  <Radio value="C" onChange={handleChange}>
                    <Image
                      src={LayoutC}
                      preview={false}
                      alt="Layout C"
                      className={
                        formValues.currLayout === 'C' && 'selected-layout'
                      }
                    />
                    <Typography.Text>1 Exercise Per Page</Typography.Text>
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Email Settings"
            name="currEmail"
            style={{ marginTop: 36 }}
          >
            {creatorEmail || clinicEmail ? (
              <Radio.Group
                name="currEmail"
                defaultValue={formValues.currEmail}
                style={{ width: '100%' }}
              >
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Radio
                      value={creatorEmail}
                      onChange={handleChange}
                      className="radio-email-settings"
                    >
                      <Card
                        className={
                          formValues.currEmail === creatorEmail &&
                          'selected-layout'
                        }
                        style={{
                          cursor: 'pointer'
                        }}
                      >
                        <Typography.Title level={5}>Personal</Typography.Title>
                        <Typography.Text type="secondary">
                          {creatorEmail}
                        </Typography.Text>
                      </Card>
                    </Radio>
                  </Col>
                  {clinicEmail && (
                    <Col span={12}>
                      <Radio
                        value={clinicEmail}
                        onChange={handleChange}
                        className="radio-email-settings"
                      >
                        <Card
                          className={
                            formValues.currEmail === clinicEmail &&
                            'selected-layout'
                          }
                          style={{
                            cursor: 'pointer'
                          }}
                        >
                          <Typography.Title level={5}>Clinic</Typography.Title>
                          <Typography.Text type="secondary">
                            {clinicEmail}
                          </Typography.Text>
                        </Card>
                      </Radio>
                    </Col>
                  )}
                </Row>
              </Radio.Group>
            ) : (
              <Typography.Text type="danger">No email found.</Typography.Text>
            )}
          </Form.Item>
        </Form>
      </div>

      <PrintPreview
        printRef={printRef}
        settingProps={settingProps}
        printProps={printProps}
      />
    </Modal>
  );
};

export default PrintPreviewModal;
