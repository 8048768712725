import {
  ADMIN_SEARCH_USER,
  SAVE_PROFILE_CHANGES,
  CANCEL_EDIT_CHANGES,
  GET_COGNITO_USER,
  CRUD_USER_PROFILE,
  QUERY_BY_NAME,
  QUERY_BY_SUB
} from '../actions';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';

const loadingState = (state, isLoading) => ({
  ...state,
  isLoading: isLoading
});

const defaultState = {
  isLoading: false,
  userProfile: null,
  cognitoProfile: null,
  osProfile: null
};

const utilitiesReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${ADMIN_SEARCH_USER}_${PENDING}`:
      return loadingState(state, true);

    case `${ADMIN_SEARCH_USER}_${FULFILLED}`:
      return {
        ...loadingState(state, false),
        userProfile: payload.data
      };

    case `${ADMIN_SEARCH_USER}_${REJECTED}`:
      return {
        ...loadingState(state, false),
        userProfile: payload.status === 404 ? 'No Record Found' : null
      };

    case `${SAVE_PROFILE_CHANGES}_${PENDING}`:
      return loadingState(state, true);

    case `${SAVE_PROFILE_CHANGES}_${FULFILLED}`:
      return {
        ...loadingState(state, false),
        userProfile: payload.data
      };

    case `${SAVE_PROFILE_CHANGES}_${REJECTED}`:
      return loadingState(state, false);

    case `${GET_COGNITO_USER}_${PENDING}`:
      return loadingState(state, true);

    case `${GET_COGNITO_USER}_${FULFILLED}`:
      return {
        ...loadingState(state, false),
        cognitoProfile: payload.data
      };

    case `${GET_COGNITO_USER}_${REJECTED}`:
      return {
        ...loadingState(state, false),
        cognitoProfile: payload.status === 500 ? {} : null
      };

    case `${CRUD_USER_PROFILE}_${PENDING}`:
      return loadingState(state, true);

    case `${CRUD_USER_PROFILE}_${FULFILLED}`:
      return {
        ...loadingState(state, false),
        cognitoProfile: payload.data
      };

    case `${CRUD_USER_PROFILE}_${REJECTED}`:
      return {
        ...loadingState(state, false),
        cognitoProfile: payload.status === 500 ? {} : null
      };

    case CANCEL_EDIT_CHANGES:
      return defaultState;

    case `${QUERY_BY_NAME}_${PENDING}`:
      return loadingState(state, true);

    case `${QUERY_BY_NAME}_${FULFILLED}`:
      return {
        ...loadingState(state, false),
        osProfile: payload
      };

    case `${QUERY_BY_NAME}_${REJECTED}`:
      return {
        ...loadingState(state, false),
        osProfile: payload.status === 500 ? {} : null
      };

    case `${QUERY_BY_SUB}_${PENDING}`:
      return loadingState(state, true);

    case `${QUERY_BY_SUB}_${FULFILLED}`:
      return {
        ...loadingState(state, false),
        osProfile: payload
      };

    case `${QUERY_BY_SUB}_${REJECTED}`:
      return {
        ...loadingState(state, false),
        osProfile: payload.status === 500 ? {} : null
      };

    default:
      return state;
  }
};

export default utilitiesReducer;
