import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { currentUserGroupSelector } from '../exercise-library/selectors';
import UsersPage from './UsersPage';
import UserFormPage from './UserFormPage';
import DropdownNavigation from '../../components/DropdownNavigation';

class Users extends Component {
  render() {
    const {
      props: { group },
    } = this;

    return (
      <React.Fragment>
        <DropdownNavigation />
        <div className="ptw-main-body">
          <Route
            exact
            path="/users"
            render={(props) => <UsersPage group={group} {...props} />}
          />
          <Route
            exact
            path="/users/:id"
            render={(props) => <UserFormPage group={group} {...props} />}
          />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    group: currentUserGroupSelector(state),
  };
}

export default connect(mapStateToProps)(Users);
