import apiFetch from '../utils/fetch.utils';

export const getDetails = (query, input) => {
  try {
    const uri = `users?${query}=${encodeURIComponent(input)}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
