import React, { Component } from 'react';
import {
  Well,
  Panel,
  Button,
  Form,
  FormControl,
  FormGroup,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { sendComment } from '../actions/patient';
import { success } from 'react-notification-system-redux';
import TextTranslation from '../../../I18n/TranslateText';

class CommentPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textValue: '',
      isDisabled: false,
    };
  }
  handleTextChange = e => {
    this.setState({ textValue: e.target.value });
  };

  submitForm = () => {
    const {
      dispatch,
      visibleProfile: { EmailAddress },
    } = this.props;
    const { textValue } = this.state;

    dispatch(sendComment(EmailAddress, textValue)).then(() => {
      this.setState({ isDisabled: true }, () => {
        dispatch(
          success({
            title: 'Thanks!',
            message: 'Your input is appreciated.',
            position: 'tc',
          })
        );
      });
    });
  };

  render() {
    const { publicPage, locale } = this.props;
    const { textValue, isDisabled } = this.state;

    const disable = textValue.length === 0 || isDisabled ? true : false;

    return (
      <div className={'comment-div'}>
        <Well bsSize="large">
          <Panel style={{ textAlign: 'center' }}>
            <Panel.Heading style={{ minHeight: '185px' }}>
              <img
                height="185px"
                width="280px"
                src={publicPage.design.logoUrl}
                alt={''}
              />
              <br />
              <br />
              <p>
                <TextTranslation
                  currLocale={locale}
                  currText={
                    'We are always looking for ways to improve our app! Let us know any recommendations you have.'
                  }
                />
              </p>
            </Panel.Heading>
            <Panel.Body>
              <Form>
                <FormGroup controlId={'mobile-comments-form-group'}>
                  <FormControl
                    componentClass="textarea"
                    rows="10"
                    onChange={this.handleTextChange}
                  />
                </FormGroup>
              </Form>
              <Button disabled={disable} onClick={this.submitForm}>
                <TextTranslation currLocale={locale} currText={'Submit!'} />
              </Button>
            </Panel.Body>
          </Panel>
          <Button
            size="lg"
            block
            onClick={() => {
              this.props.history.push('/exercises');
            }}
          >
            <TextTranslation currLocale={locale} currText={'Back'} />
          </Button>
        </Well>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    visibleProfile: state.visibleProfile,
    publicPage: state.publicPage,
    locale: state.mobile.locale,
  };
};

export default connect(mapStateToProps)(CommentPage);
