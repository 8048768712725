/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { Well, Panel, Button } from 'react-bootstrap';

import UploadProfilePhotoModal from '../UploadProfilePhotoModal';
import MobileApp from '../../features/mobile';
import RTMTimer from '../RTMTimer';
import PlayerZero from '@goplayerzero/sdk-web';

import {
  disableProfilePhotoPopup,
  requestDeauth,
  requestProfile,
  updateVisibleProfile
} from '../../features/membership/actions';
import { toCamelCaseObjKeys } from '../../utils/object.utils';
import { clearActivity } from '../RTMTimer/mixins';

import routes from '../../routes';
import states from '../../states';
import services from '../../services';
import MessageSubscription from '../MessageSubscription';

import '../loadingPage.css';

const parseData = (data) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
};

const PrivateRoutes = ({
  dispatch,
  currentUser,
  currentLocation,
  visibleProfile,
  publicPage,
  history
}) => {
  const [fetchedUser, setFetchedUser] = useState(false);
  const [hasImgError, setHasImgError] = useState(false);

  const setExercises = useSetRecoilState(states.exercises);
  const setFavorites = useSetRecoilState(states.favorites);
  const setFilters = useSetRecoilState(states.filters);
  const setGroups = useSetRecoilState(states.groups);
  const setMessages = useSetRecoilState(states.messages);
  const setTemplates = useSetRecoilState(states.templates);
  const setUser = useSetRecoilState(states.user);

  useEffect(() => {
    const { username, role, sub } = currentUser;

    const requests = [dispatch(requestProfile(username, role))];
    if (role === 'pt' || role === 'ga') {
      requests.push(services.filters.getFilters(sub));
    }

    Promise.all(requests).then(([profile, filters]) => {
      const { value } = profile;

      PlayerZero.identify(value.Sub, {
        name: `${value.FirstName} ${value.LastName}`,
        email: value.EmailAddress,
        group: publicPage.name
      });

      setUser((prevState) => ({
        ...prevState,
        details: {
          emailAddress: value.EmailAddress,
          sub: value.Sub,
          role: value.Role,
          firstName: value.FirstName,
          lastName: value.LastName,
          active: value.Active,
          clinic: value.Clinic,
          billingRole: value.BillingRole,
          favoriteId: value.FavoriteId,
          preferences: {
            ...toCamelCaseObjKeys(value.Preferences)
          }
        }
      }));

      if (filters) {
        if (filters.status === 200) {
          setFilters(() => filters.data);
        }

        setFetchedUser(true);
        initData(value);
      } else {
        setFetchedUser(true);
      }
    });
  }, []);

  useEffect(() => {
    if (!currentLocation.includes('/patients')) {
      clearActivity();
    }
  }, [currentLocation]);

  useEffect(() => {
    if (visibleProfile && publicPage) {
      const { EmailAddress, Role } = visibleProfile;
      const { name } = publicPage;
    }
  }, [visibleProfile, publicPage]);

  const initData = (data) => {
    const { GroupId, GroupInfo, Preferences, Role, Sub } = data;
    const updatedProfile = {
      ...data
    };

    Promise.all([
      services.exercises.getCustomExercises(Sub),
      services.exercises.getCustomParameter(Sub)
    ]).then((response) => {
      const exercises = response[0];
      const parameter = response[1];

      setExercises((prevState) => ({
        ...prevState,
        custom: {
          exercises: exercises.data,
          parameter: parameter.data
        }
      }));
    });

    Promise.all([
      services.groups.getLocations(GroupId),
      services.groups.getProviders(GroupId)
    ]).then((response) => {
      const locations = response[0];
      const providers = response[1];

      setGroups((prevState) => ({
        ...prevState,
        user: {
          id: GroupId,
          ...toCamelCaseObjKeys(data.GroupInfo)
        },
        clinics: locations.data,
        providers: providers.data
      }));

      updatedProfile.Clinics = locations.data;

      dispatch(
        updateVisibleProfile({
          ...updatedProfile
        })
      );
    });

    services.exercises.getFavorites(data.FavoriteId).then((response) => {
      const favExercises = response.data.exercises;
      const hasFavorites = Array.isArray(favExercises) && !!favExercises.length;

      if (hasFavorites) {
        setFavorites((prevState) => ({
          ...prevState,
          list: favExercises.map((ex) => ({
            ...toCamelCaseObjKeys(ex)
          }))
        }));
      }
    });

    const author = data.EmailAddress;
    const filter = [];

    if (!!GroupInfo?.EnableDefaultGroupTemplates) {
      filter.push('GROUP_TEMPLATES');
    } else {
      filter.push('MY_TEMPLATES');
    }

    services.templates.getList(GroupId, filter, '', author).then((response) => {
      const templateList = response.data;
      const hasTemplates = Array.isArray(templateList) && !!templateList.length;

      if (hasTemplates) {
        setTemplates((prevState) => ({
          ...prevState,
          list: templateList
        }));

        updatedProfile.Templates = templateList;

        dispatch(
          updateVisibleProfile({
            ...updatedProfile
          })
        );
      }
    });

    const isMessagingEnabled =
      Preferences &&
      Preferences.Messaging &&
      Preferences.Messaging === 'enabled';

    if (Role === 'p' || isMessagingEnabled) {
      services.message.getBoards(Role, Sub).then((response) => {
        const messageList = response.data;
        const hasMessages = Array.isArray(messageList) && !!messageList.length;

        if (hasMessages) {
          setMessages((prevState) => ({
            ...prevState,
            list: [...messageList]
          }));
        }
      });
    }

    if (
      GroupId === 'c9d548b4-e3e8-445f-89db-ce049c1098fb' ||
      GroupId === '08d0d876-a941-4b67-82cb-c91c5c1ea91a'
    ) {
      services.groups.getNonCaseIdRequired().then((response) => {
        updatedProfile.CaseId = {
          Required: true,
          ExcludedLocations: [...response.data].map((item) => item.ClinicId)
        };

        dispatch(
          updateVisibleProfile({
            ...updatedProfile
          })
        );
      });
    }
  };

  if (!fetchedUser) {
    return (
      <div className="loading-wrapper">
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }

  if (
    visibleProfile &&
    Object.keys(visibleProfile).length > 0 &&
    !parseData(visibleProfile.Active)
  ) {
    return (
      <Well bsSize="large">
        <Panel style={{ textAlign: 'center' }}>
          <Panel.Heading>
            <img
              alt=""
              src={publicPage.design.logoUrl}
              width={250}
              onError={(e) =>
                (e.target.src = `https://mq6e62qpo5.execute-api.us-east-1.amazonaws.com/1/logo.svg`)
              }
            />

            <br />
            <br />

            <Panel.Title componentClass="h3">
              Your account has been deactivated.
            </Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <p>Please contact your account admin to activate your account.</p>
          </Panel.Body>
        </Panel>

        <Button
          block
          variant="primary"
          size="lg"
          onClick={() => dispatch(requestDeauth())}
        >
          Log Out
        </Button>
      </Well>
    );
  }

  const { Sub, GroupId, GroupInfo, imgUrl, Preferences, Role } = visibleProfile;
  const profileImg =
    imgUrl ||
    ` https://ptwired-exercise-images-prod.s3.amazonaws.com/${GroupId}/${Sub}.jpg`;
  const onPrintPreview = history.location.pathname === '/print-preview';

  return (
    <div>
      <img
        alt="empty"
        src={imgUrl ? profileImg : profileImg.replace('@', '%40')}
        style={{ display: 'none' }}
        onError={() => setHasImgError(true)}
      />

      {Role && (
        <Fragment>
          {Role !== 'p' ||
            Role !== 'sa' ||
            ((!Preferences || !Preferences.DisableProfilePhotoPopup) && (
              <UploadProfilePhotoModal
                imgError={hasImgError}
                setImgError={(hasError) => setHasImgError(hasError)}
                dispatch={dispatch}
                visibleProfile={visibleProfile}
                disableProfilePhotoPopup={disableProfilePhotoPopup}
              />
            ))}

          {Role === 'p' && <MobileApp />}

          {Role !== 'p' && (
            <Helmet>
              <script
                id="ze-snippet"
                src="https://static.zdassets.com/ekr/snippet.js?key=def14d34-c188-4965-802d-692ca269d45b"
              ></script>
            </Helmet>
          )}

          {Role === 'sa' && routes(Role, visibleProfile)}

          {(Role === 'pt' || Role === 'ga') && (
            <Fragment>
              <MessageSubscription sub={visibleProfile.Sub} />

              {routes(Role, visibleProfile)}

              {!!parseData(GroupInfo.EnableRTM) && !onPrintPreview && (
                <RTMTimer />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  currentMonitoredPatient: state.monitorTimer.currentMonitoredPatient,
  currentLocation: state.routerReducer.location.pathname,
  visibleProfile: state.visibleProfile,
  publicPage: state.publicPage
});

export default connect(mapStateToProps)(withRouter(PrivateRoutes));
