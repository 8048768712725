import { createAction } from 'redux-actions';
import { API_REQUEST } from '../../membership/middleware';
const NONE = 'NONE';

export const PrescriptionSteps = {
  SELECT_EXERCISES: 'Select Exercises',
  CUSTOMIZE_PRESCRIPTION: 'Customize Prescription'
};

export const PrescriptionStepsOrder = [
  PrescriptionSteps.SELECT_EXERCISES,
  PrescriptionSteps.CUSTOMIZE_PRESCRIPTION
];

export const SELECT_PRESCRIPTION_EXERCISE = 'SELECT_PRESCRIPTION_EXERCISE';
export const DESELECT_PRESCRIPTION_EXERCISE = 'DESELECT_PRESCRIPTION_EXERCISE';
export const REMOVE_PRESCRIPTION_EXERCISE = 'REMOVE_PRESCRIPTION_EXERCISE';
export const UNDO_REMOVE_PRESCRIPTION_EXERCISE =
  'UNDO_REMOVE_PRESCRIPTION_EXERCISE';
export const REMOVE_ALL_PRESCRIPTION_EXERCISES =
  'REMOVE_ALL_PRESCRIPTION_EXERCISES';
export const CLEAR_PRESCRIPTION_EXERCISES = 'CLEAR_PRESCRIPTION_EXERCISES';
export const CLEAR_REMOVED_PRESCRIPTION_EXERCISES =
  'CLEAR_REMOVED_PRESCRIPTION_EXERCISES';
export const MOVE_PRESCRIPTION_EXERCISE = 'MOVE_PRESCRIPTION_EXERCISE';
export const RESET_PRESCRIPTION_PROCESS = 'RESET_PRESCRIPTION_PROCESS';
export const NEXT_PRESCRIPTION_FORM_STEP = 'NEXT_PRESCRIPTION_FORM_STEP';
export const PREV_PRESCRIPTION_FORM_STEP = 'PREV_PRESCRIPTION_FORM_STEP';
export const OPEN_PRESCRIPTION_EXERCISE_MODAL =
  'OPEN_PRESCRIPTION_EXERCISE_MODAL';
export const CLOSE_PRESCRIPTION_EXERCISE_MODAL =
  'CLOSE_PRESCRIPTION_EXERCISE_MODAL';
export const POST_PRESCRIPTION = 'POST_PRESCRIPTION';
export const UPDATE_PRESCRIPTION = 'UPDATE_PRESCRIPTION';
export const SEARCH_EXERCISES = 'SEARCH_PRESCRIPTION_EXERCISES';
export const RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS';
export const FRESH_TEMPLATE_FORM = 'FRESH_TEMPLATE_FORM';
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const RETRIEVE_TEMPLATES = 'RETRIEVE_TEMPLATES';
export const APPEND_TEMPLATE_TO_PRESCRIPTION =
  'APPEND_TEMPLATE_TO_PRESCRIPTION';
export const CLEAR_TEMPLATE_DATA = 'CLEAR_TEMPLATE_DATA';
export const LOAD_SELECTED_EXERCISES = 'LOAD_SELECTED_EXERCISES';
export const EDIT_TEMPLATE_SUBMIT = 'EDIT_TEMPLATE_SUBMIT';
export const EXPORT_PRESCRIPTION_TO_TEMPLATE =
  'EXPORT_PRESCRIPTION_TO_TEMPLATE';
export const CLEAR_EXPORT_PRESCRIPTION = 'CLEAR_EXPORT_PRESCRIPTION';
export const SET_SELECTED_EXERCISES = 'SET_SELECTED_EXERCISES';

export const loadSelectedExercises = createAction(
  LOAD_SELECTED_EXERCISES,
  exercises => exercises
);

export const clearTemplateData = createAction(CLEAR_TEMPLATE_DATA);

export const setSelectedExercises = createAction(
  SET_SELECTED_EXERCISES,
  exercises => exercises
);
export const appendTemplateToSelected = createAction(
  APPEND_TEMPLATE_TO_PRESCRIPTION
);

export const freshTemplateForm = createAction(FRESH_TEMPLATE_FORM)();

export const selectPrescriptionExercise = createAction(
  SELECT_PRESCRIPTION_EXERCISE,
  exercise => ({ exercise })
);

export const exportPrescriptionToTemplate = createAction(
  EXPORT_PRESCRIPTION_TO_TEMPLATE,
  prescription => prescription
);

export const clearExportPrescription = createAction(CLEAR_EXPORT_PRESCRIPTION);

export const movePrescriptionExercise = createAction(
  MOVE_PRESCRIPTION_EXERCISE,
  result => result
);

export const deselectPrescriptionExercise = createAction(
  DESELECT_PRESCRIPTION_EXERCISE,
  index => ({ index })
);

export const removePrescriptionExercise = createAction(
  REMOVE_PRESCRIPTION_EXERCISE,
  exercise => ({ exercise })
);

export const undoRemovePrescriptionExercise = createAction(
  UNDO_REMOVE_PRESCRIPTION_EXERCISE,
  index => ({ index })
);

export const removeAllPrescriptionExercises = createAction(
  REMOVE_ALL_PRESCRIPTION_EXERCISES,
  exercises => ({ exercises })
);

export const clearPrescriptionExercises = createAction(
  CLEAR_PRESCRIPTION_EXERCISES
);

export const clearRemovedPrescriptionExercises = createAction(
  CLEAR_REMOVED_PRESCRIPTION_EXERCISES
);

export const resetPrescriptionProcess = createAction(
  RESET_PRESCRIPTION_PROCESS
);

export const nextPrescriptionFormStep = createAction(
  NEXT_PRESCRIPTION_FORM_STEP
);

export const prevPrescriptionFormStep = createAction(
  PREV_PRESCRIPTION_FORM_STEP
);

export const openPrescriptionExerciseModal = createAction(
  OPEN_PRESCRIPTION_EXERCISE_MODAL,
  exercise => ({ exercise })
);

export const closePrescriptionExerciseModal = createAction(
  CLOSE_PRESCRIPTION_EXERCISE_MODAL
);

export const resetSearchResults = createAction(RESET_SEARCH_RESULTS);

export const editTemplateSubmit = payload => ({
  [API_REQUEST]: createAction(EDIT_TEMPLATE_SUBMIT)({
    resource: 'exercises/template',
    method: 'PUT',
    body: payload
  })
});

export const createTemplate = payload => ({
  [API_REQUEST]: createAction(CREATE_TEMPLATE)({
    resource: 'exercises/template',
    method: 'POST',
    body: payload
  })
});

export const retrieveTemplates = payload => ({
  [API_REQUEST]: createAction(RETRIEVE_TEMPLATES)({
    resource: `exercises/template?groupId=${payload.GroupId}`,
    method: 'GET'
  })
});

export const searchExercises = (groupName, searchTerm, filter, groupId) => ({
  [API_REQUEST]: createAction(SEARCH_EXERCISES)({
    resource: `groups/${groupName}/exercises/query/?search=${
      searchTerm ? searchTerm : NONE
    }&filter=${filter ? filter : NONE}&groupId=${groupId}&includePTWired=true`,
    method: 'GET'
  })
});

export const createPrescription = (patientId, prescription) => ({
  [API_REQUEST]: createAction(POST_PRESCRIPTION)({
    resource: `patients/${patientId}/prescription`,
    method: 'POST',
    body: prescription
  })
});

export const updatePrescription = (
  currentPatient,
  currentPT,
  updatedPrescription,
  providers
) => ({
  [API_REQUEST]: createAction(UPDATE_PRESCRIPTION)({
    resource: `groups/${currentPatient.GroupId}/patients`,
    method: 'PUT',
    body: {
      currentPatient: currentPatient,
      updatedPrescription: updatedPrescription,
      field: 'updatedPrescription',
      therapist: currentPT,
      providers
    }
  })
});

export const UPDATE_PRESCRIPTION_EMAIL = 'UPDATE_PRESCRIPTION_EMAIL';

export const updatePrescriptionEmail = (EmailAddress, GroupId, groupName) => ({
  [API_REQUEST]: createAction(UPDATE_PRESCRIPTION_EMAIL)({
    resource: `groups/${groupName}/user-email`,
    method: 'POST',
    body: {
      EmailAddress: EmailAddress.toLowerCase(),
      GroupId: GroupId,
      EmailType: 'updatePrescription'
    }
  })
});

export const READMISSION_EMAIL = 'UPDATE_PRESCRIPTION_EMAIL';

export const readmissionEmail = (Sub, GroupId, groupName) => ({
  [API_REQUEST]: createAction(READMISSION_EMAIL)({
    resource: `groups/${groupName}/user-email`,
    method: 'POST',
    body: { Sub, GroupId, EmailType: 'readmission' }
  })
});
