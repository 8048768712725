import { createAction } from 'redux-actions'
import { API_REQUEST } from '../../membership/middleware'

export const defaultClinic = {
  state: {}
}

export const GET_CLINIC = 'GET_CLINIC'
export const getClinic = (groupId, clinicId) => ({
  [API_REQUEST]: createAction(GET_CLINIC)({
    resource: `groups/${groupId}/clinics?clinicName=${clinicId.trim()}`,
    method: 'GET'
  })
})

export const GET_CLINICS = 'GET_CLINICS'
export const getClinics = (groupName, clinicId) => ({
  [API_REQUEST]: createAction(GET_CLINICS)({
    resource: `groups/${groupName}/clinics?id=${clinicId}`,
    method: 'GET',
  })
})

export const POST_CLINIC = 'POST_CLINIC'
export const postClinic = (groupId, values) => ({
  [API_REQUEST]: createAction(POST_CLINIC)({
    resource: `groups/${groupId}/clinics`,
    method: 'POST',
    body: {
      ...values,
      clinicName: values.ClinicName.trim()
    }
  })
})

export const UPDATE_CLINIC = 'UPDATE_CLINIC'
export const updateClinic = (groupId, values) => ({
  [API_REQUEST]: createAction(UPDATE_CLINIC)({
    resource: `groups/${groupId}/clinics`,
    method: 'PUT',
    body: {
      ...values,
      clinicName: values.ClinicName.trim()
    }
  })
})

export const SEARCH_CLINICS = 'SEARCH_CLINICS'
export const searchClinics = (groupName, groupId, search) => ({
  [API_REQUEST]: createAction(SEARCH_CLINICS)({
    resource: `groups/${groupName}/clinics?id=${groupId}&clinicName=${search}`,
    method: 'GET'
  })
})

export const OPEN_CLINIC_FORM_MODAL = 'OPEN_CLINIC_FORM_MODAL'
export const openClinicFormModal = createAction(OPEN_CLINIC_FORM_MODAL)()

export const CLOSE_CLINIC_FORM_MODAL = 'CLOSE_CLINIC_FORM_MODAL'
export const closeClinicFormModal = createAction(CLOSE_CLINIC_FORM_MODAL)()

export const OPEN_CLINIC_SUCCESS_MODAL = 'OPEN_CLINIC_SUCCESS_MODAL'
export const openClinicSuccessModal = createAction(OPEN_CLINIC_SUCCESS_MODAL)()

export const CLOSE_CLINIC_SUCCESS_MODAL = 'CLOSE_CLINIC_SUCCESS_MODAL'
export const closeClinicSuccessModal = createAction(CLOSE_CLINIC_SUCCESS_MODAL)()

export const OPEN_CLINIC_CONFIRMATION_MODAL = 'OPEN_CLINIC_CONFIRMATION_MODAL'
export const openClinicConfirmationModal = createAction(OPEN_CLINIC_CONFIRMATION_MODAL)()

export const CLOSE_CLINIC_CONFIRMATION_MODAL = 'CLOSE_CLINIC_CONFIRMATION_MODAL'
export const closeClinicConfirmationModal = createAction(CLOSE_CLINIC_CONFIRMATION_MODAL)()

export const UPDATE_CURRENT_CLINIC = 'UPDATE_CURRENT_CLINIC'
export const updateCurrentClinic = (values) => createAction(UPDATE_CURRENT_CLINIC)({ values })

export const SET_CLINIC_FORM_TYPE = 'SET_CLINIC_FORM_TYPE'
export const setClinicFormType = (formType) => createAction(SET_CLINIC_FORM_TYPE)({ formType })