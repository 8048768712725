import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import v from 'voca'
import m from 'moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Calendar from '../../../components/Calendar/Calendar'
import { exersiceProperties } from '../helper'
import './styles.css'


class ExercisesProgress extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
            open: false,
            calendarInfo: undefined
        }
    }

    closeModal = () => this.setState({ open: false })

    modalInfo = () => {
        const {
            state: {
            open,
            calendarInfo,
        },
            props: {
            visibleProfile: {
                Prescription: { exercises }
            }
        },
            closeModal
         } = this

        return (calendarInfo &&
            <Modal show={open} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`${calendarInfo.monthNamesFull[calendarInfo.month]} 
                            ${calendarInfo.selectedDate}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {exercises.filter(ex => !ex.deleted).map((el, i) => {
                            let exerciseElements = ''
                            let isCompleted
                            let markedExercise = false
                            return (
                                <div key={i} className="mBottom20">
                                    {Object.keys(el.completionDates).map((dateComplete, index) => {
                                        isCompleted = m(calendarInfo.selectedDt, 'MM-DD-YYY').isSame(m(dateComplete, 'MM-DD-YYYY'))
                                        if (isCompleted) markedExercise = true
                                        return (
                                            isCompleted &&
                                            <div key={index}>
                                                <i className="fa fa-check fa-lg display-inline" />
                                                <p className='mLeft3 display-inline'>
                                                    {v.capitalize(el.exerciseName)}
                                                </p>
                                            </div>
                                        )
                                    })
                                    }
                                    {!markedExercise &&
                                        <p className='mLeft20 display-inline'>
                                            {v.capitalize(el.exerciseName)}
                                        </p>
                                    }
                                    <div className="mLeft20">
                                        {exersiceProperties.forEach((property, i) => {
                                            if (el[property])
                                                exerciseElements += `${el[property]} ${property}`

                                            if ((el[property] &&
                                                el[exersiceProperties[i + 1]]) ||
                                                el[exersiceProperties[i + 2]])
                                                exerciseElements += ' | '
                                        })}
                                        {exerciseElements && <small>{exerciseElements}</small>}
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    render() {
        return (
            <div className="mobile-body container-fluid">
            <div className="row">
                <div className="col-xs-12 col-sm-offset-3 col-sm-6 calendar-mobile-container">
                    <Calendar
                        onSelect={state => {

                            this.setState({ open: true, calendarInfo: state })
                        }}
                        disableFuture
                        exercises={this.props.visibleProfile.Prescription.exercises.filter(ex => !ex.deleted)}
                    />
                </div>
                {this.modalInfo()}
            </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    visibleProfile: state.visibleProfile
})


export default withRouter(connect(mapStateToProps)(ExercisesProgress))
