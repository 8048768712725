import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button, List } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import TextTranslation from '../../../I18n/TranslateText';
import { languages } from '../../../I18n/TranslateText';

const SelectLanguageModal = ({
  locale,
  openLanguageModal,
  handleLanguageModal,
  handleSetLocale,
}) => {
  const changeLanguage = selectedLocale => {
    localStorage.setItem('locale', selectedLocale);
    handleSetLocale(selectedLocale);
    handleLanguageModal();
  };

  return (
    <Modal
      show={openLanguageModal}
      onHide={handleLanguageModal}
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <TextTranslation currLocale={locale} currText={'Languages'} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '70vh', overflow: 'auto' }}>
        <List
          className="languages"
          itemLayout="horizontal"
          dataSource={languages}
          renderItem={(item, index) => (
            <List.Item
              onClick={() => changeLanguage(item.locale)}
              key={index}
              actions={[
                <Button
                  onClick={() => changeLanguage(item.locale)}
                  shape="circle"
                  icon={<RightOutlined />}
                />,
              ]}
            >
              <List.Item.Meta title={item.name} description={item.sub} />
            </List.Item>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SelectLanguageModal;
