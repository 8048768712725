import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Dropdown,
  Menu,
  Checkbox,
  Breadcrumb,
  Button,
  Col,
  Row,
  Input,
  Tooltip,
  Table,
  Typography,
  Empty
} from 'antd';
import {
  EditOutlined,
  FilterOutlined,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons';

import {
  isLoading,
  doneLoading,
  getAdmins,
  updateCurrentUser
} from './actions/users';
import { usersValuesSelector } from './selectors';

import Loading from '../../components/Loading';
import DispatchLinkItem from '../../components/DispatchLinkItem';

const getBillingRole = (role) => {
  if (!role) return '';

  switch (role.toLowerCase()) {
    case 'pt':
      return 'Provider';

    case 'pta':
      return 'Provider Assistant';

    case 'tech':
      return 'Technician';

    default:
      return role;
  }
};

const isPartTime = (value) => (value ? 'YES' : 'NO');

class UsersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pending: null,
      searchTerm: '',
      filterDropDownOpen: false,
      selectedRoleFilter: [],
      selectedUserStatusFilter: [],
      selectedEmplStatusFilter: []
    };

    this.fields = {
      lastName: 'Last Name',
      firstName: 'First Name',
      emailAddress: 'Email',
      Role: 'Role',
      BillingRole: 'BillingRole'
    };

    this.goToFormPage = this.goToFormPage.bind(this);
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
  }

  componentDidMount() {
    const { dispatch, visibleProfile } = this.props;
    dispatch(isLoading());

    const queries = [];

    queries.push(dispatch(getAdmins(visibleProfile.GroupId)));

    Promise.all(queries).then(() => dispatch(doneLoading()));
  }

  sortUsers = (users) => {
    return users.sort((a, b) => {
      if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
        return -1;
      } else if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  filterUsers = (users) => {
    let { searchTerm } = this.state;

    if (!searchTerm) {
      return users;
    }
    const filteredUsers = [];

    if (!searchTerm) {
      return;
    }

    searchTerm = String(searchTerm).toLowerCase();

    for (let index = 0; index < users.length; index++) {
      const item = users[index];

      if (!item.FirstName || !item.LastName) {
        continue;
      }

      const userName = item.FirstName
        ? String(
            item.FirstName.toLowerCase() + ' ' + item.LastName.toLowerCase()
          )
        : String(
            item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase()
          );

      const email = item.EmailAddress
        ? String(item.EmailAddress)
        : String(item.emailAddress);

      if (userName.includes(searchTerm) || email.includes(searchTerm)) {
        filteredUsers.push(item);
      }
    }
    return filteredUsers;
  };

  unique = (array) => {
    const users = [];
    const singles = [];

    for (let i = 0; i < array.length; i++) {
      const user = array[i].emailAddress;

      const lambda = (element) => element === user;

      if (users.findIndex(lambda) === -1) {
        singles.push(array[i]);
        users.push(user);
      }
    }

    return singles;
  };

  handleFilterDropDown = (flag) => {
    this.setState({
      filterDropDownOpen: flag
    });
  };

  handleRoleFilter = (e) => {
    const { selectedRoleFilter } = this.state;

    if (!selectedRoleFilter.includes(e.target.value)) {
      this.setState({
        selectedRoleFilter: [...selectedRoleFilter, e.target.value]
      });
    } else {
      this.setState({
        selectedRoleFilter: selectedRoleFilter.filter(
          (item) => item !== e.target.value
        )
      });
    }
  };

  handleUserStatusFilter = (e) => {
    const { selectedUserStatusFilter } = this.state;

    const boolToString = e.target.value === true ? 'true' : 'false';

    if (!selectedUserStatusFilter.includes(e.target.value)) {
      this.setState({
        selectedUserStatusFilter: [
          ...selectedUserStatusFilter,
          e.target.value,
          boolToString
        ]
      });
    } else {
      this.setState({
        selectedUserStatusFilter: selectedUserStatusFilter.filter(
          (item) => item !== e.target.value && item !== boolToString
        )
      });
    }
  };

  handleEmplStatusFilter = (e) => {
    const { selectedEmplStatusFilter } = this.state;
    if (!selectedEmplStatusFilter.includes(e.target.value)) {
      this.setState({
        selectedEmplStatusFilter: [...selectedEmplStatusFilter, e.target.value]
      });
    } else {
      this.setState({
        selectedEmplStatusFilter: selectedEmplStatusFilter.filter(
          (item) => item !== e.target.value
        )
      });
    }
  };

  filterByRole = (users) => {
    const { selectedRoleFilter } = this.state;

    if (selectedRoleFilter.length !== 0) {
      const selectedRoleFilterLowered = selectedRoleFilter.map((item) =>
        item.toLowerCase()
      );

      return users.filter(
        (user) =>
          user.BillingRole &&
          selectedRoleFilterLowered.includes(user.BillingRole.toLowerCase())
      );
    } else {
      return users;
    }
  };

  filterByUserStatus = (users) => {
    const { selectedUserStatusFilter } = this.state;

    if (selectedUserStatusFilter.length !== 0) {
      return users.filter((user) =>
        selectedUserStatusFilter.includes(user.active)
      );
    } else {
      return users;
    }
  };

  filterByEmplStatus = (users) => {
    const { selectedEmplStatusFilter } = this.state;

    if (selectedEmplStatusFilter.length) {
      return users.filter((user) => {
        if (user.partTime && selectedEmplStatusFilter.includes(user.partTime)) {
          return true;
        } else if (!user.partTime && selectedEmplStatusFilter.includes(false)) {
          return true;
        }
      });
    } else {
      return users;
    }
  };

  removeEmptyObj = (users) => {
    return users.filter((user) => Object.keys(user).length !== 0);
  };

  render() {
    const {
      goToFormPage,
      handleSearchTermChange,
      props: { admins, fetched, isLoading, isSearching },
      state: { filterDropDownOpen }
    } = this;

    let allUsers = Array.isArray(admins) ? admins : [];

    allUsers = this.sortUsers(allUsers);
    allUsers = this.filterUsers(allUsers);
    allUsers = this.filterByRole(allUsers);
    allUsers = this.filterByUserStatus(allUsers);
    allUsers = this.filterByEmplStatus(allUsers);
    allUsers = this.removeEmptyObj(allUsers);

    let content;

    if (fetched) {
      if (Array.isArray(admins) && !admins.length) {
        content = (
          <Row>
            <Col
              span={24}
              style={{
                marginTop: '4em',
                marginBottom: '2.5em'
              }}
            >
              <Empty description="No providers as of the moment." />
            </Col>
          </Row>
        );
      } else if (!allUsers.length) {
        content = (
          <Row>
            <Col
              span={24}
              style={{
                marginTop: '4em',
                marginBottom: '2.5em'
              }}
            >
              <Empty description="No results found" />
            </Col>
          </Row>
        );
      } else {
        content = (
          <Table
            scroll={{
              x: 'max-content'
            }}
            size="small"
            footer={() => (
              <Row justify="end">
                <Typography.Text strong>
                  Total Count: {allUsers.length || 0}
                </Typography.Text>
              </Row>
            )}
            pagination={false}
            rowClassName={(record) =>
              record.active === 'false' || record.active === false
                ? 'disabledEntity'
                : ''
            }
            columns={[
              {
                title: 'Full Name',
                dataIndex: 'fullName',
                key: 'fullName',
                render: (_, record) => (
                  <Typography.Text>
                    {record.firstName} {record.lastName}
                  </Typography.Text>
                )
              },
              {
                title: 'Email Address',
                dataIndex: 'emailAddress',
                key: 'emailAddress'
              },
              {
                title: 'Billing Role',
                dataIndex: 'BillingRole',
                key: 'BillingRole',
                render: (text) => getBillingRole(text)
              },
              {
                title: 'Part-Time',
                dataIndex: 'partTime',
                key: 'partTime',
                render: (text) => isPartTime(text)
              },
              {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                  <Tooltip title="Edit Provider">
                    <Button
                      onClick={() => goToFormPage(record)}
                      icon={<EditOutlined />}
                      shape="circle"
                    />
                  </Tooltip>
                )
              }
            ]}
            dataSource={allUsers}
          />
        );
      }
    }

    const menu = (
      <Menu>
        <Menu.ItemGroup title="Billing Role">
          <Menu.Item>
            <Checkbox value="PT" onChange={this.handleRoleFilter}>
              Provider
            </Checkbox>
          </Menu.Item>
          <Menu.Item>
            <Checkbox value="PTA" onChange={this.handleRoleFilter}>
              Provider Assistant
            </Checkbox>
          </Menu.Item>
          <Menu.Item>
            <Checkbox value="TECH" onChange={this.handleRoleFilter}>
              Technician
            </Checkbox>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="User Status">
          <Menu.Item>
            <Checkbox value={true} onChange={this.handleUserStatusFilter}>
              Active
            </Checkbox>
          </Menu.Item>
          <Menu.Item>
            <Checkbox value={false} onChange={this.handleUserStatusFilter}>
              Inactive
            </Checkbox>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Employment Status">
          <Menu.Item>
            <Checkbox value={false} onChange={this.handleEmplStatusFilter}>
              Full-Time
            </Checkbox>
          </Menu.Item>
          <Menu.Item>
            <Checkbox value={true} onChange={this.handleEmplStatusFilter}>
              Part-Time
            </Checkbox>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );

    return (
      <div style={{ padding: '15px' }}>
        <div className="tab-header">
          <Breadcrumb className="mb-2">
            <DispatchLinkItem title="Admin&nbsp;" url="/administrator" />
            <Breadcrumb.Item active>Providers</Breadcrumb.Item>
          </Breadcrumb>
          <div className="tab-header-controls">
            <Button
              type="primary"
              className="btn-primary ptw-btn"
              onClick={this.goToNewUserForm}
            >
              <PlusOutlined /> Add Provider
            </Button>
            <Input
              size="middle"
              placeholder="Seach provider"
              prefix={<SearchOutlined />}
              onChange={handleSearchTermChange}
            />
            <Tooltip title="Filter Providers">
              <Dropdown
                onVisibleChange={this.handleFilterDropDown}
                visible={filterDropDownOpen}
                overlay={menu}
                trigger={['click']}
                placement="bottomRight"
              >
                <Button
                  className="btn-default"
                  shape="circle"
                  icon={<FilterOutlined style={{ fontSize: 16 }} />}
                />
              </Dropdown>
            </Tooltip>
          </div>
        </div>

        <Loading isLoading={isLoading}>{content}</Loading>
      </div>
    );
  }

  goToNewUserForm = () => {
    this.props.dispatch(updateCurrentUser({}));
    this.props.history.push(`/users/new`);
  };

  goToFormPage(user) {
    user.emailAddress && this.props.dispatch(updateCurrentUser(user));
    this.props.history.push(
      `/users/${user.emailAddress ? user.emailAddress : 'new'}`
    );
  }

  handleSearchTermChange(event) {
    const searchTerm = event.target.value;

    this.setState({ searchTerm: searchTerm });
  }
}

function mapStateToProps(state) {
  return {
    visibleProfile: state.visibleProfile,
    admins: state.users.admins,
    users: usersValuesSelector(state),
    fetched: state.users.fetched,
    isLoading: state.users.isLoading,
    isSearching: state.users.isSearching
  };
}

export default connect(mapStateToProps)(withRouter(UsersPage));
