import apiFetch from '../utils/fetch.utils';

export const getList = async (
  groupId,
  filters = null,
  input = '',
  creator = ''
) => {
  try {
    const method = 'GET';
    let uri = `exercises/template?groupId=${groupId}`;

    if (filters) {
      uri += `&filters=${encodeURIComponent(JSON.stringify(filters))}`;
    }

    if (input) {
      uri += `&input=${encodeURIComponent(input)}`;
    }

    if (creator) {
      uri += `&creator=${encodeURIComponent(creator)}`;
    }

    return apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getItem = async (groupId, id) => {
  try {
    const method = 'GET';
    const uri = `exercises/template?groupId=${groupId}&id=${id}`;

    return apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
