import { WS_CONNECT, SET_NEW_MESSAGE_BADGE, UPDATE_ON_MESSAGE, VERSION_QUERY} from './WebSocketAction';

const defaultState = {
    websocket:null,
};

const socketReducer = (state = defaultState, {payload, type}) => {

    switch(type){
        case WS_CONNECT:

            if(state.browserId){
                return {
                    ...state,
                    websocket: true,
                    ready: true
                }
            }

            return {
                ...state,
                websocket: true,
                ready: true,
                browserId:payload,
            };

        case SET_NEW_MESSAGE_BADGE:

            return {
                ...state,
                badgeCount: payload
            }

        case VERSION_QUERY:

            if(!payload.value){
                return state
            }

            return{
                ...state,
                version: payload.Value
            }

        case UPDATE_ON_MESSAGE:

            return{
                ...state,
                updateOnMessage:payload
            }
        default:
            return state;
    }
};

export default socketReducer;

