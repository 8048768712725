import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router';
import { Link } from "react-router-dom";
import { isDirty, isValid, isSubmitting, isPristine, submit, change, registerField } from 'redux-form';
import { Breadcrumb, Button, Col, FormGroup, Row } from "react-bootstrap";
import { success } from 'react-notification-system-redux';
import _ from 'lodash';
import { FormTypes } from "../groups/actions/groups";
import { asyncValidate as ptwiredAsyncValidate } from './validators'
import { asyncValidate as groupAsyncValidate } from "../groups/validators/groupExercises";
import { imageValidator } from '../groups/validators/groupExercises';
import {
  isLoading,
  doneLoading,
  setExerciseFormType,
  updateCurrentExercise
} from "./actions/exercisesLibrary";

import { defaultPTWiredExercise } from './actions/ptwiredExercises'
import { uploadImageDirect, removeExerciseImage } from '../../components/file-uploader/actions'
import { actionsSelector, exerciseLibraryTypeSelector, exerciseByNameSelector } from "./selectors";
import ExerciseForm from '../groups/group-exercises/ExerciseForm';
import Loading from "../../components/Loading";
import { titleCase } from "../groups/stringHelpers";


class ExerciseFormPage extends Component {
  constructor(props) {
    super(props)

    this.leaveConfirmMessage = "You currently have unsaved changes. Are you sure you want to discard your changes?"
    this.disableConfirmMessage = "Are you sure you want to inactivate this Exercise?"

    this.state = {
      validExerciseImage: true
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.goToExercisesList = this.goToExercisesList.bind(this)
    this.showSuccessNotification = this.showSuccessNotification.bind(this)
    this.handleFileSelected = this.handleFileSelected.bind(this)
    this.handleFileRemoved = this.handleFileRemoved.bind(this)
  }

  componentDidMount() {
    const { dispatch, match } = this.props

    dispatch(isLoading());

    if (match.params.id !== 'new') {

      dispatch(setExerciseFormType(FormTypes.EDIT))
      dispatch(doneLoading());
    } else {
      dispatch(setExerciseFormType(FormTypes.NEW))
      dispatch(updateCurrentExercise(defaultPTWiredExercise))
      dispatch(doneLoading());
    }
    dispatch(registerField('exercise', 'image', 'Field'))
  }

  componentWillUnmount() {
    this.props.dispatch(updateCurrentExercise(defaultPTWiredExercise))
  }

  handleSubmit(values) {
    const { goToExercisesList, showSuccessNotification, props: {dispatch, group, formType, currentExercise, postExercise,
        updateExercise}} = this;

    _.each(values, (val, key) => {
      if (typeof (val) === 'string'){
        values[key] = val.trim();
      }  else if(val === null){
        values[key] = '';
      }
    });

    let submitAction

    const file = this.file;

    if (formType === FormTypes.NEW) {
      submitAction = postExercise({ ...values, groupId: group.id })
    } else {
      if (currentExercise.active && !values.active) {
        if (!window.confirm(this.disableConfirmMessage)) {
          dispatch(change('exercise', 'active', true))
          return;
        }
      }
      submitAction = updateExercise({ ...values, groupId: group.id, id: currentExercise.id })
    }

    return dispatch(submitAction).then(({ action }) => {
        if (action.payload.status === 200 || action.payload.message === "Success") {
            dispatch(updateCurrentExercise(values));

        const exerciseId = currentExercise.id || action.payload.Id;
        if (this.removeFile) {//remove image
          return dispatch(removeExerciseImage(group.id, exerciseId))
            .then(() => {
              goToExercisesList()
              showSuccessNotification()
            })
        } else if (file) {//begin image upload
          return dispatch(uploadImageDirect(`${group.id}/${exerciseId}.jpg`, file))
            // .then(x => dispatch(uploadImage(x.value, file)))
            .then(() => {
              goToExercisesList()
              showSuccessNotification()
            })
        } else {
          goToExercisesList()
          showSuccessNotification()
        }
      } else {
        //todo: show errors
      }
    })
  }

  handleFileSelected(x, errors) {
    const isValid = _.isEmpty(errors)
    this.setState({ validExerciseImage: isValid })
    if (isValid) {
      this.props.dispatch(change('exercise', 'image', x.name));
      this.file = x;
    }
  }

  handleFileRemoved(x) {
    this.setState({ validExerciseImage: true })
    this.props.dispatch(change('exercise', 'image', null));
    this.removeFile = x;
  }

  goToExercisesList() {
    this.props.history.push('/library')
  }

  showSuccessNotification(message = 'Successfully submitted') {
    this.props.dispatch(success({
      title: message,
      position: 'tr'
    }))
  }
  render() {
    const {
      handleSubmit,
      leaveConfirmMessage,
      handleFileSelected,
      handleFileRemoved,
      props:
          {
            visibleProfile:{GroupId},
            formType,
            currentExercise,
            isLoading,
            isFormDirty,
            isFormValid,
            isFormSubmitting,
            isFormPristine,
            dispatch,
            exerciseLibraryType,
            formParamsValid
          },
      state: {
        validExerciseImage
      }
    } = this
    Object.keys(currentExercise).forEach(val => {
      if (val === 'exerciseName' ||
          val === 'tag') {
        currentExercise[val] = titleCase(currentExercise[val])
      }
    })

    return (
        <Loading isLoading={isLoading}>
          <Breadcrumb style={{marginLeft:'28px'}}>
            <Breadcrumb.Item><Link to={'/library'}>Clinic Exercise Library</Link></Breadcrumb.Item>
            <span> &raquo; </span>
            <Breadcrumb.Item active>{formType === FormTypes.NEW ? "Add" : "Edit"}</Breadcrumb.Item>
          </Breadcrumb>

          <ExerciseForm
              onSubmit={handleSubmit}
              initialValues={currentExercise}
              groupId={GroupId}
              exercise={currentExercise}
              asyncValidate={exerciseLibraryType === 'ptwired' ? ptwiredAsyncValidate : groupAsyncValidate}
              asyncBlurFields={['exerciseName']}
              imageValidator={imageValidator}
              onFileSelected={handleFileSelected}
              onFileRemoved={handleFileRemoved}
              validImage={validExerciseImage}
              enableReinitialize />

          <Row>
            <Col md={12}>
              <FormGroup className="ptw-form-actions">
                <div className="btn-toolbar pull-right">
                  <Link to="/library"><Button disabled={isFormSubmitting} bsStyle="link">Cancel</Button></Link>
                  <Button
                      bsStyle="primary"
                      disabled={!isFormValid || !formParamsValid || isFormSubmitting || isFormPristine}
                      onClick={() => dispatch(submit('exercise'))}>Save</Button>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Prompt message={leaveConfirmMessage} when={isFormDirty} />
        </Loading>
    )
  }

}

function mapStateToProps(state, ownProps) {
  return {
    visibleProfile: state.visibleProfile,
    exerciseFromRoute: exerciseByNameSelector(ownProps.match.params.id)(state),
    currentExercise: state.exercisesLibrary.currentExercise,
    formType: state.exercisesLibrary.formType,
    isLoading: state.exercisesLibrary.isLoading,
    isFormValid: isValid('exercise')(state),
    isFormDirty: isDirty('exercise')(state),
    isFormSubmitting: isSubmitting('exercise')(state),
    isFormPristine: isPristine('exercise')(state),
    exerciseLibraryType: exerciseLibraryTypeSelector(state),
    formParamsValid: state.form.exercise && state.form.exercise.validParams,
    ...actionsSelector(state)
  }
}

export default connect(mapStateToProps)(withRouter(ExerciseFormPage))