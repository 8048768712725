import {
  GET_ALL_PROGRAMS,
  GET_PROGRAM,
  UPDATE_PROGRAM,
  ADD_VIDEO,
  ADD_PROGRAM,
  CHANGE_ACTIVE_PROGRAM,
} from '../actions';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';

const defaultState = {
  isLoading: false,
  isError: false,
  errorMessage: null,
  activeProgram: null,
  data: [],
};

const carePlanReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case `${GET_ALL_PROGRAMS}_${PENDING}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${GET_ALL_PROGRAMS}_${FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        data: payload.data,
      };

    case `${GET_ALL_PROGRAMS}_${REJECTED}`:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.data,
      };

    case `${GET_PROGRAM}_${PENDING}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${GET_PROGRAM}_${FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        activeProgram: payload.data,
      };

    case `${GET_PROGRAM}_${REJECTED}`:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.data,
      };

    case `${UPDATE_PROGRAM}_${PENDING}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${UPDATE_PROGRAM}_${FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };

    case `${UPDATE_PROGRAM}_${REJECTED}`:
      return {
        ...state,
        isLoading: false,
      };

    case `${ADD_VIDEO}_${PENDING}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${ADD_VIDEO}_${FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };

    case `${ADD_VIDEO}_${REJECTED}`:
      return {
        ...state,
        isLoading: false,
      };

    case `${ADD_PROGRAM}_${PENDING}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${ADD_PROGRAM}_${FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };

    case `${ADD_PROGRAM}_${REJECTED}`:
      return {
        ...state,
        isLoading: false,
      };

    case CHANGE_ACTIVE_PROGRAM:
      return { ...state, activeProgram: payload.activeProgram };

    default:
      return {
        ...state,
      };
  }
};

export default carePlanReducer;
