import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions as idleActions } from '../features/idle-monitor';
import { Row, Col, Button, Typography } from 'antd';
import {
  NotificationsCircle,
  Barbell,
  BagAdd,
  PhonePortrait,
  PersonCircle,
  PeopleCircle,
  Heart,
  FileTrayFull,
  Chatbubble,
} from 'react-ionicons';
import {
  ArrowLeftOutlined,
  BookFilled,
  ShareAltOutlined,
} from '@ant-design/icons';
import './styles.css';

export const isActive = (activeLink, link) => {
  return activeLink.includes(link) ? 'active' : '';
};

class Navigation extends Component {
  componentDidMount() {
    this.props.dispatch(idleActions.start());
  }

  render() {
    const {
      visibleProfile: { Role, GroupId },
    } = this.props;

    const onPTWiredGroup = GroupId === '2667b98c-4e98-4cdb-b03a-959ed7a7f435';

    return (
      <Row gutter={[24, 24]}>
        {Role === 'ga' && (
          <Link to={'/patients'}>
            <Button
              type="link"
              className="btn-link dashboard-back-btn"
              size="large"
            >
              <ArrowLeftOutlined /> Patients
            </Button>
          </Link>
        )}
        <Col span={24}>
          <Typography.Title level={2} className="dashboard-title mt-1">
            Administrator Dashboard
          </Typography.Title>
          <Row gutter={[24, 24]}>
            <Col xl={3} lg={2} md={24} sm={24} />

            <Col xl={18} lg={20} md={24} sm={24}>
              {Role === 'sa' && (
                <Row
                  gutter={[24, 24]}
                  className="dashboard-btns-container"
                  justify="center"
                >
                  <Col lg={8} md={12} sm={24}>
                    <Link to={'/groups'}>
                      <div className="dashboard-btn">
                        <PeopleCircle />
                        <Typography.Text className="dashboard-btn-text">
                          Groups
                        </Typography.Text>
                      </div>
                    </Link>
                  </Col>

                  <Col lg={8} md={12} sm={24}>
                    <Link to={'/library'}>
                      <div className="dashboard-btn">
                        <Barbell />
                        <Typography.Text className="dashboard-btn-text">
                          Clinic Exercise Library
                        </Typography.Text>
                      </div>
                    </Link>
                  </Col>

                  {onPTWiredGroup && (
                    <Col lg={8} md={12} sm={24}>
                      <Link to={'/educ-pdf-library'}>
                        <div className="dashboard-btn">
                          <BookFilled />
                          <Typography.Text className="dashboard-btn-text">
                            Educational PDF Library
                          </Typography.Text>
                        </div>
                      </Link>
                    </Col>
                  )}

                  <Col lg={8} md={12} sm={24}>
                    <Link to={'/care-plan'}>
                      <div className="dashboard-btn">
                        <Heart />
                        <Typography.Text className="dashboard-btn-text">
                          Care Plan Program
                        </Typography.Text>
                      </div>
                    </Link>
                  </Col>

                  <Col lg={8} md={12} sm={24}>
                    <Link to={'/message-broadcast'}>
                      <div className="dashboard-btn">
                        <Chatbubble />
                        <Typography.Text className="dashboard-btn-text">
                          Release Announcement
                        </Typography.Text>
                      </div>
                    </Link>
                  </Col>
                </Row>
              )}
              {Role === 'ga' && (
                <Row
                  gutter={[24, 24]}
                  className="dashboard-btns-container"
                  justify="center"
                >
                  <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                    <Link to={'/users'}>
                      <div className="dashboard-btn">
                        <BagAdd />
                        <Typography.Text className="dashboard-btn-text">
                          Providers
                        </Typography.Text>
                      </div>
                    </Link>
                  </Col>

                  <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                    <Link to={'/library'}>
                      <div className="dashboard-btn">
                        <Barbell />
                        <Typography.Text className="dashboard-btn-text">
                          Clinic Exercise Library
                        </Typography.Text>
                      </div>
                    </Link>
                  </Col>

                  <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                    <Link to={'/push-notifications'}>
                      <div className="dashboard-btn">
                        <NotificationsCircle />
                        <Typography.Text className="dashboard-btn-text">
                          iOS Push Notifications
                        </Typography.Text>
                      </div>
                    </Link>
                  </Col>

                  <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                    <Link to={'/announcements'}>
                      <div className="dashboard-btn">
                        <PhonePortrait />
                        <Typography.Text className="dashboard-btn-text">
                          In-App Customizations
                        </Typography.Text>
                      </div>
                    </Link>
                  </Col>

                  <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                    <Link to={'/reports'}>
                      <div className="dashboard-btn">
                        <FileTrayFull />
                        <Typography.Text className="dashboard-btn-text">
                          Reports
                        </Typography.Text>
                      </div>
                    </Link>
                  </Col>

                  <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                    <Link to={'/emr-integrations'}>
                      <div className="dashboard-btn">
                        <ShareAltOutlined />
                        <Typography.Text className="dashboard-btn-text">
                          EMR Integrations
                        </Typography.Text>
                      </div>
                    </Link>
                  </Col>

                  <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                    <Link to={'/profile'}>
                      <div className="dashboard-btn">
                        <PersonCircle />
                        <Typography.Text className="dashboard-btn-text">
                          My Profile
                        </Typography.Text>
                      </div>
                    </Link>
                  </Col>
                </Row>
              )}
            </Col>
            <Col xl={3} lg={2} md={24} sm={24} />
          </Row>
        </Col>
      </Row>
    );

    // return (
    //   <div>
    //     <Row>
    //       <Col md={2} mdOffset={2} xsOffset={3}>
    //         <Row>
    //           {Role === 'ga' && (
    //             <Link to={'/patients'}>
    //               <Row>
    //                 <Col md={1}>
    //                   <FontAwesomeIcon
    //                     icon={faArrowLeft}
    //                     style={{ ...usersStyle, fontSize: '20px' }}
    //                   />
    //                 </Col>
    //                 <Col md={6}>Back to Patients</Col>
    //               </Row>
    //             </Link>
    //           )}
    //         </Row>
    //       </Col>
    //       <Col md={12} style={{ textAlign: 'center', lineHeight: '3' }}>
    //         <Breadcrumb>
    //           <Breadcrumb.Item active>Administrator Dashboard</Breadcrumb.Item>
    //         </Breadcrumb>
    //       </Col>
    //     </Row>
    //     <Row className={'admin-icon-row'}>
    //       {Role === 'sa' && (
    //         <div>
    //           <Link to={'/groups'}>
    //             <Col md={2} mdOffset={3}>
    //               <Row>
    //                 <Col md={12} style={iconStyle}>
    //                   <FontAwesomeIcon icon={faUsers} style={usersStyle} />
    //                 </Col>
    //               </Row>
    //               <Row>
    //                 <Col md={12}>
    //                   <div style={linkNameStyle}>Groups</div>
    //                 </Col>
    //               </Row>
    //             </Col>
    //           </Link>
    //           <Link to={'/library'}>
    //             <Col md={2}>
    //               <Row>
    //                 <Col md={12} style={iconStyle}>
    //                   <FontAwesomeIcon icon={faDumbbell} />
    //                 </Col>
    //               </Row>
    //               <Row>
    //                 <Col md={12}>
    //                   <div style={linkNameStyle}>Clinic Exercise Library</div>
    //                 </Col>
    //               </Row>
    //             </Col>
    //           </Link>
    //           <Link to="/billing">
    //             <Col md={2}>
    //               <Row>
    //                 <Col md={12} style={iconStyle}>
    //                   <FontAwesomeIcon
    //                     icon={faMoneyBill}
    //                     style={billingStyle}
    //                   />
    //                 </Col>
    //               </Row>
    //               <Row>
    //                 <Col md={12}>
    //                   <div style={linkNameStyle}>Billing</div>
    //                 </Col>
    //               </Row>
    //             </Col>
    //           </Link>
    //         </div>
    //       )}
    //       {Role === 'ga' && (
    //         <div style={iconBuffer}>
    //           <Col md={4} style={{ marginBottom: 30 }}>
    //             <Link to={'/users'}>
    //               <Row>
    //                 <Col md={12} style={iconStyle}>
    //                   <FontAwesomeIcon icon={faUserTie} />
    //                 </Col>
    //               </Row>
    //               <Row>
    //                 <Col md={12}>
    //                   <div style={linkNameStyle}>Therapists</div>
    //                 </Col>
    //               </Row>
    //             </Link>
    //           </Col>
    //           <Col md={4} style={{ marginBottom: 30 }}>
    //             <Link to={'/library'}>
    //               <Row>
    //                 <Col md={12} style={iconStyle}>
    //                   <FontAwesomeIcon icon={faDumbbell} />
    //                 </Col>
    //               </Row>
    //               <Row>
    //                 <Col md={12}>
    //                   <div style={linkNameStyle}>Clinic Exercise Library</div>
    //                 </Col>
    //               </Row>
    //             </Link>
    //           </Col>
    //           {/* <Link to={'/announcements'}>
    //             <Col md={2}>
    //               <Row>
    //                 <Col md={12} style={iconStyle}>
    //                   <FontAwesomeIcon icon={faBullhorn} />
    //                 </Col>
    //               </Row>
    //               <Row>
    //                 <Col md={12}>
    //                   <div style={linkNameStyle}>Announcements</div>
    //                 </Col>
    //               </Row>
    //             </Col>
    //           </Link> */}
    //           <Col md={4} style={{ marginBottom: 30 }}>
    //             <Link to={'/push-notifications'}>
    //               <Row>
    //                 <Col md={12} style={iconStyle}>
    //                   <FontAwesomeIcon icon={faComment} />
    //                 </Col>
    //               </Row>
    //               <Row>
    //                 <Col md={12}>
    //                   <div style={linkNameStyle}>Push Notifications</div>
    //                 </Col>
    //               </Row>
    //             </Link>
    //           </Col>
    //           <Col md={4}>
    //             <Link to={'/emr-integrations'}>
    //               <Row>
    //                 <Col md={12} style={iconStyle}>
    //                   <FontAwesomeIcon icon={faShareAlt} />
    //                 </Col>
    //               </Row>
    //               <Row>
    //                 <Col md={12}>
    //                   <div style={linkNameStyle}>EMR Integrations</div>
    //                 </Col>
    //               </Row>
    //             </Link>
    //           </Col>
    //           <Col md={4}>
    //             <Link to={'/announcements'}>
    //               <Row>
    //                 <Col md={12} style={iconStyle}>
    //                   <FontAwesomeIcon icon={faMobileAlt} />
    //                 </Col>
    //               </Row>
    //               <Row>
    //                 <Col md={12}>
    //                     <div style={linkNameStyle}>In-App Customizations</div>
    //                 </Col>
    //               </Row>
    //             </Link>
    //           </Col>
    //           <Col md={4}>
    //             <Link to={'/profile'}>
    //               <Row>
    //                 <Col md={12} style={iconStyle}>
    //                   <FontAwesomeIcon icon={faCogs} />
    //                 </Col>
    //               </Row>
    //               <Row>
    //                 <Col md={12}>
    //                   <div style={linkNameStyle}>My Profile</div>
    //                 </Col>
    //               </Row>
    //             </Link>
    //           </Col>
    //         </div>
    //       )}
    //     </Row>
    //   </div>
    // );
  }
}

const mapStateToProps = state => {
  return {
    currentLocation: state.routerReducer.location.pathname,
    visibleProfile: state.visibleProfile,
    groupName: state.publicPage.name,
  };
};

export default connect(mapStateToProps)(Navigation);
