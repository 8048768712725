import React, { useEffect, useState } from 'react';
import { Divider, Typography } from 'antd';

import states from '../../../states';
import { useRecoilState, useRecoilValue } from 'recoil';
import CustomizeExerciseList from './CustomizeExerciseList';
import EditPrescriptionDetailsForm from './EditPrescriptionDetailsForm';

const checkExerciseFrequency = exercise => {
  if (!exercise) {
    return false;
  }
  return !!exercise.frequency;
};

const checkTemplateFrequency = (rxExercises, templates) => {
  if (!Array.isArray(rxExercises) || !Array.isArray(templates)) {
    return false;
  }

  const template = templates.find(({ Exercises }) => {
    return JSON.stringify(Exercises) === JSON.stringify(rxExercises);
  });

  if (!template) {
    return false;
  }

  return checkExerciseFrequency(template.Exercises[0]);
};

const EditPrescriptionDetails = () => {
  const [isExerciseFrequency, setIsExerciseFrequency] = useState(false);
  const [prescription, setPrescription] = useRecoilState(states.prescription);
  const templates = useRecoilValue(states.templates);

  useEffect(() => {
    const exerciseFrequency = checkExerciseFrequency(
      prescription.form &&
        prescription.form.prescription &&
        Array.isArray(prescription.form.prescription.exercises) &&
        prescription.form.prescription.exercises[0]
    );

    const templateFrequency = checkTemplateFrequency(
      prescription.form?.prescription?.exercises,
      templates.list
    );

    setIsExerciseFrequency(exerciseFrequency || templateFrequency);
  }, []);

  const handleIsExerciseFrequency = isEnabled => {
    setIsExerciseFrequency(() => {
      setPrescription(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          prescription: {
            ...prevState.form.prescription,
            exercises: prevState.form.prescription.exercises.map(exercise => ({
              ...exercise,
              frequency: isEnabled
                ? prevState.form.prescription.frequency
                : null,
              perdiem: isEnabled ? prevState.form.prescription.perdiem : null,
            })),
          },
        },
      }));

      return isEnabled;
    });
  };

  return (
    <React.Fragment>
      <Typography.Title level={4}>Prescription Details</Typography.Title>
      <Typography.Text type="secondary">
        General Instructions For Every Exercise.
      </Typography.Text>
      <EditPrescriptionDetailsForm
        isExerciseFrequency={isExerciseFrequency}
        handleIsExerciseFrequency={handleIsExerciseFrequency}
      />
      <Divider className="mt-3 mb-3" />
      <CustomizeExerciseList isExerciseFrequency={isExerciseFrequency} />
    </React.Fragment>
  );
};

export default EditPrescriptionDetails;
