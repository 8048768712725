import React from 'react';
import './loading.css';

class Loading extends React.Component {
  render() {
    let loading = (
      <div>
        <div className="loader">Loading...</div>
      </div>
    );

    return (
      <React.Fragment>
        {this.props.isLoading ? (
          <div className="ptw-main-body"> {loading} </div>
        ) : (
          this.props.children
        )}
      </React.Fragment>
    );
  }
}

export default Loading;
