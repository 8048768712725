import voca from 'voca';

export const toCamelCaseObjKeys = (obj) => {
  if (!obj) {
    return obj;
  }

  const newObj = {};
  for (let i = 0; i < Object.keys(obj).length; i++) {
    const key = Object.keys(obj)[i];
    const newKey = key[0].toLowerCase() + key.slice(1);

    newObj[newKey] = obj[key];
  }

  return newObj;
};

export const toPascalCaseObjKeys = (obj) => {
  if (!obj) {
    return obj;
  }

  const newObj = {};
  for (let i = 0; i < Object.keys(obj).length; i++) {
    const key = Object.keys(obj)[i];
    const newKey = voca.titleCase(String(key));

    newObj[newKey] = obj[key];
  }

  return newObj;
};

export const removeObjProperty = (obj, prop) => {
  const newObj = {};
  for (let i = 0; i < Object.keys(obj).length; i++) {
    const key = Object.keys(obj)[i];
    if (key !== prop) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
};
