import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { currentUserGroupSelector } from "../../exercise-library/selectors";
import {Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Loading from "../../../components/Loading";
class Dashboard extends Component {
    componentDidMount() {
        this.props.history.push('/patients')
    }

    render() {

    return (
      <div>
        <Row>
          <Col mdOffset={2} md={10}>
            <Loading isLoading={true}/>
          </Col>
        </Row>
        <Row>
          <Col mdOffset={2} md={10}>
              <Link to={'/#/patients'}>
                <Button> Back to Patients</Button>
              </Link>
          </Col>
        </Row>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.patients.isFetching,
  filteredList: state.patients.patients,
  group: currentUserGroupSelector(state)
})


export default connect(mapStateToProps)(withRouter(Dashboard))
