import { createAction } from 'redux-actions';
import { API_REQUEST } from '../../membership/middleware';
import USStates from '../USStates';

export const GET_GROUPS = 'GET_GROUPS';
export const groupsActionCreator = createAction(GET_GROUPS);

export const IS_CURRENTLY_VIEWING_GROUPS = 'IS_CURRENTLY_VIEWING_GROUPS';
export const viewingGroups = createAction(IS_CURRENTLY_VIEWING_GROUPS);
export const IS_CURRENTLY_ADDING_GROUP = 'IS_CURRENTLY_ADDING_GROUP';
export const addingGroup = createAction(IS_CURRENTLY_ADDING_GROUP);
export const IS_CURRENTLY_EDITING_GROUP = 'IS_CURRENTLY_EDITING_GROUP';
export const editingGroup = createAction(IS_CURRENTLY_EDITING_GROUP);

// Get Customizations
export const GET_CUSTOMIZATIONS = 'GET_CUSTOMIZATIONS';
export const getCustomizations = groupId => ({
  [API_REQUEST]: createAction(GET_CUSTOMIZATIONS)({
    resource: `groups/updatecustomization?groupId=${groupId}`,
    method: 'GET'
  })
});

//Edit Intro Message
export const EDIT_CUSTOMIZATIONS = 'EDIT_CUSTOMIZATIONS';
export const editCustomizations = (event, values) => ({
  [API_REQUEST]: createAction(EDIT_CUSTOMIZATIONS)({
    resource: 'groups/updatecustomization',
    method: 'POST',
    body: {
      customizationEvent: event,
      ...values
    }
  })
});

export const CHANGE_GROUP = 'CHANGE_GROUP';
export const changeGroup = values => ({
  [API_REQUEST]: createAction(CHANGE_GROUP)({
    resource: 'change-group',
    method: 'POST',
    body: {
      ...values
    }
  })
});

// Put this in a more general file
export const FormTypes = {
  NEW: 'NEW',
  EDIT: 'EDIT'
};

export const defaultGroup = {
  active: 'true',
  state: Object.keys(USStates)[0]
};

export const getGroups = () => ({
  [API_REQUEST]: groupsActionCreator({
    resource: 'groups',
    method: 'GET'
  })
});

export const ADMIN_CREATE_USER = 'ADMIN_CREATE_USER';
export const adminCreateUser = payload => ({
  [API_REQUEST]: createAction(ADMIN_CREATE_USER)({
    resource: `admin`,
    method: 'POST',
    body: payload
  })
});

export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER';
export const adminUpdateUser = payload => ({
  [API_REQUEST]: createAction(ADMIN_UPDATE_USER)({
    resource: `admin`,
    method: 'PUT',
    body: payload
  })
});

export const GET_GROUP = 'GET_GROUP';
export const getGroup = id => ({
  [API_REQUEST]: createAction(GET_GROUP)({
    resource: `groups/${id.trim()}`,
    method: 'GET'
  })
});

export const GET_BILLING = 'GET_BILLING';
export const getBilling = () => ({
  [API_REQUEST]: createAction(GET_BILLING)({
    resource: `billing`,
    method: 'GET'
  })
});

export const POST_GROUP = 'POST_GROUP';
export const postGroup = values => ({
  [API_REQUEST]: createAction(POST_GROUP)({
    resource: 'groups',
    method: 'POST',
    body: {
      ...values,
      name: values.name.trim()
    }
  })
});

export const UPDATE_GROUP = 'UPDATE_GROUP';
export const updateGroup = values => ({
  [API_REQUEST]: createAction(UPDATE_GROUP)({
    resource: `groups`,
    method: 'PUT',
    body: {
      ...values,
      name: values.name.trim()
    }
  })
});

export const SEARCH_GROUPS = 'SEARCH_GROUPS';
export const searchGroups = search => ({
  [API_REQUEST]: createAction(SEARCH_GROUPS)({
    resource: `groups?search=${search}`,
    method: 'GET'
  })
});

export const UPDATE_CURRENT_GROUP = 'UPDATE_CURRENT_GROUP';
export const updateCurrentGroup = values =>
  createAction(UPDATE_CURRENT_GROUP)({ values });

export const SET_GROUP_FORM_TYPE = 'SET_GROUP_FORM_TYPE';
export const setGroupFormType = (formType, values) =>
  createAction(SET_GROUP_FORM_TYPE)({
    formType,
    values: formType === FormTypes.NEW ? defaultGroup : values
  });

export const OPEN_MODAL = 'OPEN_MODAL';
export const openModal = createAction(OPEN_MODAL)();

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const closeModal = createAction(CLOSE_MODAL)();

export const SAVE_MODAL = 'SAVE_MODAL';
export const saveModal = createAction(SAVE_MODAL)();

export const NO_SAVE_MODAL = 'NO_SAVE_MODAL';
export const noSaveModal = createAction(NO_SAVE_MODAL)();
