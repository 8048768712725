export const WEB_SOCKET = "WEB_SOCKET";
export const WS_CONNECT = "WS_CONNECT";
export const WS_OPEN = "WS_OPEN";
export const SOCKET_MESSAGE_RECEIVED = "SOCKET_MESSAGE_RECEIVED";
export const SOCKET_MESSAGE_SEND = "SOCKET_MESSAGE_SEND";
export const SOCKET_CLOSE = "SOCKET_CLOSE";
export const SET_ON_MESSAGE = "SET_ON_MESSAGE";
export const GET_MESSAGES = "GET_MESSAGES";
export const SET_NEW_MESSAGE_BADGE = "SET_NEW_MESSAGE_BADGE";
export const UPDATE_ON_MESSAGE = "UPDATE_ON_MESSAGE";
export const GET_GROUP_DATA = "GET_GROUP_DATA";
export const VERSION_QUERY = "VERSION_QUERY";

export const wsConnect = (url, token) => (
    {
        type:WS_CONNECT,
        data: {
            url:url+`?token=${token}`
        }
    }
);

export const wsOpen = () => (
    {
        type:WS_OPEN,
    }
);

export const setNewMessageBadge = (event, isEvent = true) =>{

    const count = processEventCount(event, isEvent);

    return({
        type: SET_NEW_MESSAGE_BADGE,
        payload:count.therapistMessages
    })
};

export const versionQuery = event =>{

    return({
        type: VERSION_QUERY,
        payload:JSON.parse(event.data)
    })
}

export const setOnMessage = (onMessage) =>(
    {
        type:SET_ON_MESSAGE,
        data:onMessage
    }
)

export const UpdateOnMessage = (bool)=>(
    {
        type: UPDATE_ON_MESSAGE,
        payload: bool
    }
)


export const socketClose = ()=>(
    {
        type: SOCKET_CLOSE
    }
)

export const socketMessageSend = (message)=>(
    {
        type:SOCKET_MESSAGE_SEND,
        data: message
    }
);

export const getMessages = (payload) => (
    {
        type:GET_MESSAGES,
        data:payload,
    }
)

function processEventCount(event,isEvent){

    if(!isEvent){return{ownerMessages: 0, therapistMessages: 0}}
    try{
    const eventData = JSON.parse(event.data);

    if(!eventData.data) return {ownerMessages: 0, therapistMessages: 0};

    if(eventData.data.Id){
        return {ownerMessages: eventData.data.OwnerMessages, therapistMessages: eventData.data.TherapistMessages}
    }

    if(!eventData.data.body) return {ownerMessages: 0, therapistMessages: 0};

    if(!eventData.data.body.length === 0) return {ownerMessages: 0, therapistMessages: 0};

    const messages = eventData.data.body;

    let therapistCount = 0;

    for(let i=0; i < messages.length;i++){

        const {TherapistMessages} = eventData.data.body[i];


        therapistCount = therapistCount + Number(TherapistMessages);

    }
    return { therapistMessages: therapistCount}

    }catch(err){
        console.log('Err',err);
        return {ownerMessages: 0, therapistMessages: 0}
    }
}