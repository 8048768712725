import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Row, Col } from 'antd';
import { FormTypes } from '../actions/groups';

import ModalForm from '../../../components/ModalForm';
import USStates from '../USStates';

const ClinicForm = ({
  initialValues,
  formType,
  loadingForm,
  showModal,
  handleSubmit,
  handleClose
}) => {
  const [inputType, setInputType] = useState('tel');
  const [backspacePressed, setBackspacePressed] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues, showModal]);

  const formatPhoneInput = (value) => {
    if (inputType === 'text') {
      form.setFieldsValue({
        ClinicPhoneNumber: value
      });

      return;
    }

    const number = value.replace(/\D/g, '');
    const output = `(${number.substring(0, 3)}) ${number.substring(
      3,
      6
    )} - ${number.substring(6, 10)}`;

    form.setFieldsValue({
      ClinicPhoneNumber: number ? output : ''
    });
  };

  const validatePhoneInput = (rule, value) => {
    if (!value) {
      return Promise.reject('Please enter clinic phone number.');
    }

    if (!/^\(\d{3}\) \d{3} - \d{4}$/.test(value)) {
      return Promise.reject('Invalid phone number.');
    } else {
      return Promise.resolve();
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (value.length > 2 && inputType === 'tel') {
      formatPhoneInput(value);
    } else {
      let valStr = value;
      if (backspacePressed) {
        const string = value.replace(/\d+/g, '');
        const isFormatted =
          string.includes('(') && string.includes(')') && string.includes('-');

        if (!string || isFormatted) {
          valStr = value.replace(/\D/g, '');
        }
      }

      formatPhoneInput(valStr);
    }
  };

  const handleInputPaste = (e) => {
    e.preventDefault();
    const value = e.clipboardData.getData('Text');

    formatPhoneInput(value);
  };

  const handleKeyPress = (e) => {
    setBackspacePressed(e.key === 'Backspace');

    if (e.key === 'Backspace' || (e.ctrlKey && e.key === 'a')) {
      setInputType('text');
    } else {
      setInputType('tel');
    }
  };

  return (
    <ModalForm
      header={`${formType === FormTypes.NEW ? 'Add' : 'Edit'} Clinic`}
      width={1000}
      form="clinicForm"
      showModal={showModal}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      loading={loadingForm}
    >
      <Form
        form={form}
        id="clinicForm"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="ClinicName"
              label="Clinic Name"
              requiredMark
              rules={[
                {
                  required: true,
                  message: 'Please enter clinic name.'
                },
                {
                  max: 254,
                  message: 'Invalid clinic name.'
                },
                {
                  pattern: /^[a-zA-Z0-9\s!@#$%^&*)(+=.,;:_-]+$/,
                  message: 'Enter alphanumeric or special characters only.'
                }
              ]}
            >
              <Input type="text" size="large" placeholder="Enter clinic name" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="ClinicPhoneNumber"
              label="Clinic Phone"
              requiredMark
              rules={[
                {
                  validator: validatePhoneInput
                }
              ]}
            >
              <Input
                type="text"
                size="large"
                placeholder="(123) 456 - 7890"
                onChange={handleInputChange}
                onPaste={handleInputPaste}
                onKeyDown={handleKeyPress}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="ClinicEmail"
              label="Clinic Email"
              rules={[
                {
                  type: 'email',
                  message: 'Invalid email address.'
                }
              ]}
            >
              <Input type="text" size="large" placeholder="example@email.com" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="Address"
              label="Address"
              requiredMark
              rules={[
                {
                  required: true,
                  message: 'Please enter address.'
                },
                {
                  max: 254,
                  message: 'Invalid address.'
                },
                {
                  pattern: /^[a-zA-Z0-9\s!@#$%^&*)(+=.,;:_-]+$/,
                  message: 'Enter alphanumeric or special characters only.'
                }
              ]}
            >
              <Input type="text" size="large" placeholder="Enter address" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="City"
              label="City"
              requiredMark
              rules={[
                {
                  required: true,
                  message: 'Please enter city.'
                },
                {
                  max: 128,
                  message: 'Invalid city.'
                },
                {
                  pattern: /^[a-zA-Z0-9\s]+$/,
                  message: 'Enter alphanumeric characters only.'
                }
              ]}
            >
              <Input type="text" size="large" placeholder="Enter city" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="State"
              label="State"
              requiredMark
              rules={[
                {
                  required: true,
                  message: 'Please select state.'
                }
              ]}
            >
              <Select placeholder="Select state" size="large">
                {Object.keys(USStates).map((abbrev) => (
                  <Select.Option value={abbrev} key={abbrev}>
                    {USStates[abbrev]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="Zip"
              label="Zip"
              requiredMark
              rules={[
                {
                  required: true,
                  message: 'Please enter zip.'
                },
                {
                  min: 5,
                  message: 'Invalid zip.'
                }
              ]}
            >
              <Input type="text" size="large" placeholder="Enter zip" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="FacebookUrl"
              label="Facebook Url"
              rules={[
                {
                  pattern:
                    /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/i,
                  message: 'Invalid URL.'
                }
              ]}
            >
              <Input
                type="text"
                size="large"
                placeholder="Enter facebook URL"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="GoogleUrl"
              label="Google Url"
              rules={[
                {
                  pattern:
                    /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/i,
                  message: 'Invalid URL.'
                }
              ]}
            >
              <Input type="text" size="large" placeholder="Enter google URL" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalForm>
  );
};

export default ClinicForm;
