import { Card, Skeleton, Statistic } from 'antd';
import React from 'react';

const StatCard = ({ loading = false, title, value, icon }) => {
  return (
    <Card>
      {loading ? (
        <Skeleton active />
      ) : (
        <Statistic title={title} value={value} prefix={icon} />
      )}
    </Card>
  );
};

export default StatCard;
