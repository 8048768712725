import React, { Fragment } from 'react';
import { Form, Input, Button, Typography } from 'antd';

const CustomHomeContentForm = props => {
  const {
    customContent,
    setCustomContent,
    event,
    handleSubmitCustomization,
    isLoading,
  } = props;

  const popContent = (
    <Fragment>
      <Typography.Text strong style={{ color: '#fff' }}>
        Home Page Message
      </Typography.Text>
      <Typography.Paragraph style={{ color: '#fff' }}>
        This message is displayed at the top of the home page of your
        application. A patient will see this message any time they access the
        app and open the home page.
      </Typography.Paragraph>

      <a
        className={'pull-right'}
        href={'https://ptwired.zendesk.com/hc/en-us/articles/4411528817427'}
        target={'_blank'}
      >
        Learn More
      </a>
    </Fragment>
  );

  return (
    <Form
      name="basic"
      labelAlign="right"
      labelWrap={true}
      labelCol={{ xl: 5, lg: 6, md: 8, sm: 24 }}
      wrapperCol={{ xl: 19, lg: 18, md: 16, sm: 24 }}
      initialValues={{ customContent }}
      onFinish={() => {
        handleSubmitCustomization(event, {
          customHomeTextValue: customContent,
        });
      }}
    >
      <Form.Item
        label="Enter Home Page Announcement"
        name="customContent"
        tooltip={popContent}
        rules={[
          {
            required: true,
            message: 'Home Page Announcement is required.',
          },
        ]}
      >
        <Input.TextArea
          onChange={e => setCustomContent(e.target.value)}
          value={customContent}
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          xl: { offset: 4 },
          lg: { offset: 6 },
          md: { offset: 8 },
          sm: { offset: 0 },
        }}
      >
        <Button type="primary" htmlType="submit" isLoading={isLoading}>
          Save Changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CustomHomeContentForm;
