import React, { useState } from 'react';
import { Button, Form, Input, Select, Row, Col, Typography, Space } from 'antd';
import { CloseOutlined, DownOutlined, LeftOutlined } from '@ant-design/icons';

const CustomizeAllTemplateExDrawer = ({
  checkedExercises,
  setCheckedExercises,
  isExerciseFrequency,
  handleUpdateCheckedExercises,
}) => {
  const [isMinimized, setIsMinimized] = useState(false);

  // handle update all checked exercises after pressing enter
  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      handleUpdateCheckedExercises(e);
    }
  };

  // handle update all checked exercises
  if (checkedExercises.length === 0) return null;

  return (
    <div className="customize-all-exercises-container">
      <div className="header">
        <Space className="pull-right" direction="horizontal">
          <Button
            size="large"
            type="text"
            shape="circle"
            icon={isMinimized ? <LeftOutlined /> : <DownOutlined />}
            onClick={() => setIsMinimized(!isMinimized)}
          />
          <Button
            size="large"
            type="text"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={() => setCheckedExercises([])}
          />
        </Space>

        <Typography.Title level={5} style={{ marginTop: 6 }}>
          Editing {checkedExercises.length}{' '}
          {checkedExercises.length <= 1 ? 'item' : 'items'}
        </Typography.Title>
      </div>
      <div className={`body ${isMinimized ? 'is-minimized' : ''}`}>
        <Form layout="vertical">
          <Typography.Text>Parameters</Typography.Text>
          <Row gutter={[16, 16]} className="mt-1" style={{ marginBottom: -8 }}>
            <Col lg={8} md={8}>
              <Form.Item name="sets">
                <Input
                  size="large"
                  placeholder="Enter sets"
                  onBlur={handleUpdateCheckedExercises}
                  onKeyDown={handleKeyPress}
                  type="text"
                  name="sets"
                  suffix="sets"
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={8}>
              <Form.Item name="reps">
                <Input
                  size="large"
                  placeholder="Enter reps"
                  onBlur={handleUpdateCheckedExercises}
                  onKeyDown={handleKeyPress}
                  type="text"
                  name="reps"
                  suffix="reps"
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={8}>
              <Form.Item name="hold">
                <Input
                  size="large"
                  placeholder="Enter hold"
                  onBlur={handleUpdateCheckedExercises}
                  onKeyDown={handleKeyPress}
                  type="text"
                  name="hold"
                  suffix="hold"
                />
              </Form.Item>
            </Col>
          </Row>
          {isExerciseFrequency && (
            <React.Fragment>
              <Typography.Text>Prescription Frequency</Typography.Text>
              <div className="mt-1">
                <Select
                  size="large"
                  onChange={value =>
                    handleUpdateCheckedExercises({
                      target: {
                        name: 'frequency',
                        value,
                      },
                    })
                  }
                  virtual={false}
                  name="frequency"
                  style={{ minWidth: 120 }}
                >
                  <Select.Option value={1}>1x</Select.Option>
                  <Select.Option value={2}>2x</Select.Option>
                  <Select.Option value={3}>3x</Select.Option>
                  <Select.Option value={4}>4x</Select.Option>
                  <Select.Option value={5}>5x</Select.Option>
                  <Select.Option value={6}>6x</Select.Option>
                  <Select.Option value={7}>7x</Select.Option>
                  <Select.Option value={8}>8x</Select.Option>
                  <Select.Option value={9}>9x</Select.Option>
                  <Select.Option value={10}>10x</Select.Option>
                </Select>
                <span className="ml-2 mr-2">Every</span>
                <Select
                  size="large"
                  onChange={value =>
                    handleUpdateCheckedExercises({
                      target: {
                        name: 'perdiem',
                        value,
                      },
                    })
                  }
                  virtual={false}
                  name="perdiem"
                  style={{ minWidth: 120 }}
                >
                  <Select.Option value={'day'}>Day</Select.Option>
                  <Select.Option value={'week'}>Week</Select.Option>
                  <Select.Option value={'month'}>Month</Select.Option>
                </Select>
              </div>
            </React.Fragment>
          )}

          <Form.Item
            className="mt-2"
            name="instructions"
            label="Prescription Instructions"
            rules={[
              {
                required: true,
                message: 'Please input your prescription instructions.',
              },
              { max: 1000, message: 'Max length is 1000 characters.' },
            ]}
          >
            <Input.TextArea
              onBlur={handleUpdateCheckedExercises}
              onKeyDown={handleKeyPress}
              name="instructions"
              placeholder="Enter instructions"
              size="large"
              rows={4}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CustomizeAllTemplateExDrawer;
