import apiFetch from '../utils/fetch.utils';
import states from '../states';
import { getRecoil } from 'recoil-nexus';

export const send = async (type, sub, ptFname = '', ptLname = '') => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id, name } = groupsState.user;

    const uri = `groups/${name}/user-email`;
    const method = 'POST';
    const payload = {
      body: {
        EmailType: type,
        Sub: sub,
        GroupId: id,
        PTFirstName: ptFname,
        PTLastName: ptLname
      }
    };

    return apiFetch(uri, method, payload);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
