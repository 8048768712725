import {
  FilterOutlined,
  MessageTwoTone,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  notification,
  Pagination,
  Row,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import { ChevronForward } from 'react-ionicons';

import _ from 'lodash';
import v from 'voca';
import services from '../../services';
import states from '../../states';

import '../../features/patients/styles.css';
import '../../styles/PT-Wired.css';
import '../../styles/atomic.css';
import '../../styles/ptw-updated.css';
import '../../styles/ptw-print.css';

import DropdownNavigation from '../../components/DropdownNavigation';
import NoResults from '../../components/NoResults';
import LoadingPage from '../../components/LoadingPage';

import RTMDashboard from '../../features/patients/RTMDashboard';
import PatientExercises from '../../features/patients/PatientExercises';
import ExerciseTemplates from '../../features/patients/ExerciseTemplates';
import ReactiveModal from '../../features/patients/Modals/ReactiveModal';
import ReloadModal from '../../features/patients/Modals/ReloadModal';
import EducationalPDFs from '../EducationalPDFs';
import Messages from '../Messages';
import CaseIdModal from './CaseIdModal';

import { debounce } from 'lodash';
import { toCamelCaseObjKeys } from '../../utils/object.utils';
import { clearActivity } from '../../components/RTMTimer/mixins';

const setStringEllipsis = input => {
  if (!input) return;

  if (input.length >= 30) {
    return input.substring(0, 30) + '...';
  }

  return input;
};

const Patients = ({
  dispatch,
  history,
  visibleProfile,
  exportPrescription,
  importedTemplate,
  selectedExercises,
}) => {
  const {
    CaseId,
    Sub,
    GroupId,
    GroupInfo: { EnableRTM, EnableEducPDF },
    Preferences,
  } = visibleProfile;
  const isATIGroup = GroupId === 'c9d548b4-e3e8-445f-89db-ce049c1098fb';
  const isATIDevGroup = GroupId === '08d0d876-a941-4b67-82cb-c91c5c1ea91a';
  const showCaseId = (isATIGroup || isATIDevGroup) && !!CaseId?.Required;

  const searchHistory = history.location.search;

  const [loadingPatients, setLoadingPatients] = useState(false);
  const [loadingBoards, setLoadingBoards] = useState(false);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [showReloadModal, setShowReloadModal] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [showCaseIdModal, setShowCaseIdModal] = useState(false);
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');
  const [redirectLink, setRedirectLink] = useState('');

  const width = window.screen.width;
  const [showReactiveModal, setShowReactiveModal] = useState(width <= 812);

  const toTemplate = searchHistory === '?toTemplate=true';
  const [tab, setTab] = useState(toTemplate ? 'templates' : 'patients');

  const [pagination, setPagination] = useState({
    pageSize: 24,
    currentPage: 1,
    min: 0,
    max: 0,
  });

  const [patientState, setPatientState] = useRecoilState(states.patients);
  const [messages, setMessages] = useRecoilState(states.messages);
  const [rtm, setRTM] = useRecoilState(states.rtm);

  const user = useRecoilValue(states.user);
  const filters = useRecoilValue(states.filters);

  useEffect(() => {
    const type = 'hashchange';
    window.addEventListener(type, hashListener);

    return () => {
      debouncedSearch.cancel();
      window.removeEventListener(type, hashListener);
    };
  }, []);

  useEffect(() => {
    if (!!EnableRTM && !rtm.analytics.length) {
      getAnalyticsList();
    }
  }, []);

  useEffect(() => {
    setPatientState(prevState => ({
      ...prevState,
      redirect: '',
    }));

    if (patientState.redirect) {
      setRedirectLink(`/patients/${patientState.redirect}`);
    }
  }, [patientState.redirect]);

  useEffect(() => {
    if (tab === 'patients') {
      clearActivity();
    }
  }, [tab]);

  useEffect(() => {
    if (searchHistory && searchHistory !== '?toTemplate=true') {
      const parameter = searchHistory.split('=')[1];
      history.push('/patients/' + parameter);
    } else {
      getPatientsList('*');
      setPagination({
        ...pagination,
        min: 0,
        max: pagination.pageSize,
        currentPage: pagination.currentPage,
      });
    }
  }, [searchHistory]);

  const debouncedSearch = useRef(
    debounce(async (query, status) => {
      await getPatientsList(query, status).then(() => {
        setPagination({
          ...pagination,
          currentPage: 1,
          min: 0,
          max: pagination.pageSize,
        });
      });
    }, 500)
  ).current;

  const hashListener = () => {
    const hash = window.location.hash;
    const path = hash.substring(1);

    if (path === '/patients#patientsTab') {
      history.push('/patients');
      setTab('patients');
      getPatientsList('*').then(() => {
        setPagination({
          ...pagination,
          min: 0,
          max: pagination.pageSize,
          currentPage: pagination.currentPage,
        });
      });
    }
  };

  const getPatientsList = async (query, status = '') => {
    try {
      setLoadingPatients(true);
      const response = await services.patients.getPatients(
        GroupId,
        query,
        status
      );

      setPatientState(prevState => ({
        ...prevState,
        list: response.data.map(item => toCamelCaseObjKeys(item)),
      }));
    } catch (error) {
      notification.error({
        message: 'Search Failed',
        description: 'An error occurred while searching patients',
      });
    } finally {
      setLoadingPatients(false);
    }
  };

  const getAnalyticsList = async () => {
    let providers = [Sub];
    let total = 0;
    let initialFetched = 0;

    if (filters && Array.isArray(filters.RTM)) {
      providers = filters.RTM;
    }

    try {
      setLoadingAnalytics(true);

      await services.rtm.getAnalyticsList(GroupId, providers).then(res => {
        if (res.status === 200) {
          const totalData = res.data.total;
          const initFetch = res.data.items.length;

          setRTM(prevState => ({
            ...prevState,
            analytics: _.uniqBy([...res.data.items], 'Sub'),
            hasRemaining: totalData > initFetch,
          }));

          total = totalData;
          initialFetched = initFetch;
        }
      });
    } catch (error) {
      notification.error({
        message: 'Fetch Failed',
        description: 'An error occurred while fetching RTM patients',
      });
    } finally {
      setLoadingAnalytics(false);
    }

    if (total > initialFetched) {
      const failedQueries = [];
      const limit = 5000;
      const counter = Math.ceil(total / limit);

      for (let i = 1; i <= counter; i++) {
        const skip = i * limit;
        const done = i === counter || skip > total;

        const rtmState = getRecoil(states.rtm);
        const cancelProcess = rtmState.stopFetching;

        let msgType = done ? 'success' : 'loading';
        let msgDuration = done ? 3 : 0;
        let msgContent = done
          ? ' Fetching RTM patients completed.'
          : ' Fetching remaining RTM patients ...';

        if (cancelProcess) {
          msgType = 'warning';
          msgDuration = 3;
          msgContent = ' Fetching RTM patients cancelled.';
        }

        message.open({
          key: 'fetching-rtm-patients',
          type: msgType,
          duration: msgDuration,
          content: msgContent,
        });

        if (skip > total || cancelProcess) {
          setRTM(prevState => ({
            ...prevState,
            hasRemaining: false,
            stopFetching: false,
          }));

          break;
        }

        const query = services.rtm.getAnalyticsList(
          GroupId,
          providers,
          limit,
          skip
        );

        try {
          await query.then(res => {
            if (res.status === 200) {
              setRTM(prevState => ({
                ...prevState,
                analytics: sortAnalytics(
                  _.uniqBy([...prevState.analytics, ...res.data.items], 'Sub')
                ),
              }));
            }
          });
        } catch (error) {
          failedQueries.push(query);
          continue;
        }
      }

      if (failedQueries.length) {
        await getRemainingAnalytics(failedQueries);
      }
    }
  };

  const getRemainingAnalytics = async (queries, retry = 3) => {
    const failed = [];
    for (let i = 0; i < queries.length; i++) {
      const query = queries[i];

      try {
        await query.then(res => {
          if (res.status === 200) {
            setRTM(prevState => ({
              ...prevState,
              analytics: sortAnalytics(
                _.uniqBy([...prevState.analytics, ...res.data.items], 'Sub')
              ),
            }));
          }
        });
      } catch (error) {
        failed.push(query);
      }
    }

    if (failed.length && retry > 0) {
      await getRemainingAnalytics(failed, retry - 1);
    }
  };

  const sortAnalytics = dataArr =>
    [...dataArr].sort((a, b) => {
      const aFname = a.FirstName?.trim().toLowerCase() || '';
      const aLname = a.LastName?.trim().toLowerCase() || '';

      const bFname = b.FirstName?.trim().toLowerCase() || '';
      const bLname = b.LastName?.trim().toLowerCase() || '';

      if (aFname + aLname > bFname + bLname) {
        return 1;
      }

      return -1;
    });

  const handleInputChange = async e => {
    const value = e.target.value;
    const query = value ? encodeURIComponent(value.toLowerCase()) : '*';

    setSearch(value);
    debouncedSearch(query, filter);
  };

  const getBoardList = async () => {
    if (
      Preferences &&
      Preferences.Messaging &&
      Preferences.Messaging === 'enabled'
    ) {
      if (user.details) {
        const { role, sub } = user.details;
        if (role && sub) {
          setLoadingBoards(true);
          await services.message
            .getBoards(role, sub)
            .then(response => {
              const { data } = response;
              if (Array.isArray(data) && data.length > 0) {
                setMessages({
                  list: data,
                });
              }
            })
            .finally(() => setLoadingBoards(false));
        }
      }
    }
  };

  const handleChangeTab = key => {
    setTab(key);

    if (key === 'messages') {
      getBoardList();
    }

    clearActivity();
  };

  const handleSelectFilter = async e => {
    const name = e.target.name;
    const checked = e.target.checked;
    const key = checked ? name : '';
    const query = search ? encodeURIComponent(search) : '*';

    setFilter(key);
    debouncedSearch(query, key);
  };

  return (
    <Fragment>
      {showReactiveModal && (
        <ReactiveModal closeWindow={() => setShowReactiveModal(false)} />
      )}

      {showReloadModal && (
        <ReloadModal closeWindow={() => setShowReloadModal(false)} />
      )}

      {showCaseId && (
        <CaseIdModal
          visibleProfile={visibleProfile}
          open={showCaseIdModal}
          onClose={() => setShowCaseIdModal(false)}
          setPath={path => setRedirectLink(path)}
        />
      )}

      <DropdownNavigation />

      <Tabs activeKey={tab} onChange={handleChangeTab}>
        <Tabs.TabPane key="patients" tab="Patients">
          <div className="ptw-main-body">
            <div className="tab-header">
              <Typography.Title level={2}>Patients</Typography.Title>

              <div className="tab-header-controls">
                <Link to="/patients/new">
                  <Button type="primary" className="btn-primary ptw-btn">
                    <PlusOutlined /> Add Patient
                  </Button>
                </Link>

                {showCaseId && (
                  <Button
                    type="primary"
                    className="btn-primary ptw-btn mr-2"
                    onClick={() => {
                      setShowCaseIdModal(true);
                    }}
                    style={{
                      marginLeft: 4,
                    }}
                  >
                    <SearchOutlined /> Search by Case ID
                  </Button>
                )}

                <Input
                  size="middle"
                  placeholder="Search patient name or email"
                  prefix={<SearchOutlined />}
                  onChange={handleInputChange}
                />

                <Tooltip title="Filter Patients">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="active">
                          <Checkbox
                            name="active"
                            checked={filter === 'active'}
                            onChange={handleSelectFilter}
                          >
                            Active
                          </Checkbox>
                        </Menu.Item>
                        <Menu.Item key="discharge">
                          <Checkbox
                            name="discharge"
                            checked={filter === 'discharge'}
                            onChange={handleSelectFilter}
                          >
                            Discharged
                          </Checkbox>
                        </Menu.Item>
                      </Menu>
                    }
                    onOpenChange={e => setFilterOpen(e)}
                    open={filterOpen}
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <Button className="btn-default" shape="circle">
                      <FilterOutlined style={{ fontSize: 16 }} />
                    </Button>
                  </Dropdown>
                </Tooltip>
              </div>
            </div>

            {!loadingPatients && !patientState.list.length && (
              <NoResults content="Sorry, no results found." />
            )}

            {loadingPatients ? (
              <LoadingPage
                type="list"
                content="Loading patients, please wait..."
              />
            ) : (
              <Row gutter={[16, 16]}>
                {patientState.list
                  .slice(pagination.min, pagination.max)
                  .map((item, i) => {
                    const { firstName, lastName } = item;
                    const fullname = `${v.capitalize(firstName)} ${v.capitalize(
                      lastName
                    )}`;

                    const { GroupInfo } = visibleProfile;
                    const { emailAddress, phoneNumber } = item;
                    const contact = !!GroupInfo.EnablePhoneNumber
                      ? emailAddress || phoneNumber
                      : emailAddress || 'NOT AVAILABLE';

                    return (
                      <Fragment key={i}>
                        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                          <Link to={'/patients/' + item.sub}>
                            <Card className="ptw-card-item">
                              <Space direction="vertical">
                                <Space direction="horizontal">
                                  <Typography.Text strong>
                                    {setStringEllipsis(fullname)}
                                  </Typography.Text>

                                  {!!item.discharge && (
                                    <Tag color="volcano">Discharged</Tag>
                                  )}
                                </Space>

                                <Typography.Text type="secondary">
                                  {setStringEllipsis(contact)}
                                </Typography.Text>
                              </Space>

                              <div className="ptw-card-item-icon">
                                <ChevronForward />
                              </div>
                            </Card>
                          </Link>
                        </Col>
                      </Fragment>
                    );
                  })}
              </Row>
            )}

            <div className="pagination-container align-right">
              <Pagination
                responsive
                style={{ bottom: '0px' }}
                total={loadingPatients ? 1 : patientState.list.length}
                pageSize={pagination.pageSize}
                current={pagination.currentPage}
                showSizeChanger={false}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                onChange={page =>
                  setPagination({
                    ...pagination,
                    currentPage: page,
                    min: (page - 1) * pagination.pageSize,
                    max: page * pagination.pageSize,
                  })
                }
              />
            </div>
          </div>
        </Tabs.TabPane>

        {EnableRTM && typeof EnableRTM === 'boolean' && !!EnableRTM && (
          <Tabs.TabPane key="rtm" tab="RTM Dashboard">
            <RTMDashboard
              visibleProfile={visibleProfile}
              loadingAnalytics={loadingAnalytics}
              setLoadingAnalytics={load => setLoadingAnalytics(load)}
            />
          </Tabs.TabPane>
        )}

        <Tabs.TabPane key="exercises" tab="Exercises">
          <div className="ptw-main-body">
            <PatientExercises isTabbed={true} />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane key="templates" tab="Templates">
          <div className="ptw-main-body">
            <ExerciseTemplates
              dispatch={dispatch}
              exportPrescription={exportPrescription}
              importedTemplate={importedTemplate}
              selectedExercises={selectedExercises}
              visibleProfile={visibleProfile}
            />
          </div>
        </Tabs.TabPane>

        {EnableEducPDF &&
          typeof EnableEducPDF === 'boolean' &&
          !!EnableEducPDF && (
            <Tabs.TabPane key="educational-pdfs" tab="Educational PDFs">
              <EducationalPDFs />
            </Tabs.TabPane>
          )}

        <Tabs.TabPane
          key="messages"
          tab={
            <Badge
              offset={[20, 0]}
              count={
                messages.list.some(
                  msg => !msg.TherapistRead && msg.Messages.length > 0
                ) ? (
                  <MessageTwoTone twoToneColor="red" />
                ) : null
              }
            >
              <span>Messages</span>
            </Badge>
          }
        >
          <div className="ptw-main-body">
            <Messages
              loadingBoards={loadingBoards}
              setLoadingBoards={load => setLoadingBoards(load)}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>

      {redirectLink && <Redirect to={redirectLink} />}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
  exportPrescription: state.patients.exportPrescription,
  importedTemplate: state.patients.templateData,
  selectedExercises: state.patients.selectedExercises,
});

export default connect(mapStateToProps)(withRouter(Patients));
