import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Input, Table, Button, Space, Popconfirm } from 'antd';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

export default function ProgramDetails(props) {
  const { carePlan, handleUpdateProgram, handleToggleDrawer } = props;
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: '5%',
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Sequence',
      dataIndex: 'Sequence',
      key: 'Sequence',
      className: 'care-plan-program-table_row',
      width: '5%',
    },
    {
      title: 'Drip',
      dataIndex: 'Drip',
      key: 'Drip',
      className: 'care-plan-program-table_row',
      width: '5%',
    },
    {
      title: 'URL',
      dataIndex: 'URL',
      key: 'URL',
      className: 'care-plan-program-table_row',
      width: '10%',
    },
    {
      title: 'Video Title',
      dataIndex: 'Title',
      key: 'Title',
      className: 'care-plan-program-table_row',
      width: '12%',
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      className: 'care-plan-program-table_row',
      width: '30%',
    },
    {
      title: 'Notification Title',
      dataIndex: 'NotificationTitle',
      key: 'NotificationTitle',
      className: 'care-plan-program-table_row',
    },
    {
      title: 'Notification Details',
      dataIndex: 'NotificationDescription',
      key: 'NotificationDescription',
      className: 'care-plan-program-table_row',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space
          size="middle"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              onDelete(record);
            }}
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = [...dataSource];
      const movedItem = newData.splice(oldIndex, 1);
      newData.splice(newIndex, 0, movedItem[0]).filter(el => !!el);

      const filteredData = newData.filter((el, index) => {
        return (el.Sequence = index + 1);
      });
      // console.log('Filtered items: ', filteredData);
      setDataSource(filteredData);
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.Id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const onFinish = values => {
    handleUpdateProgram({
      id: carePlan.activeProgram.Id,
      programName: values.ProgramName,
      diagnosis: values.Diagnosis,
      patientTerm: values.PatientTerm,
      videos: [...dataSource],
    });
  };

  const onDelete = async values => {
    const currentProgram = carePlan.activeProgram;
    const filterProgram = {
      id: currentProgram.Id,
      programName: currentProgram.ProgramName,
      diagnosis: currentProgram.Diagnosis,
      patientTerm: currentProgram.PatientTerm,
      videos: currentProgram.Videos.filter(video => {
        if (video.Sequence !== values.Sequence) {
          return video;
        }
        return [];
      }),
    };
    const updatedProgram = {
      ...filterProgram,
      videos: filterProgram.videos.map((video, index) => {
        return {
          ...video,
          Sequence: index + 1,
        };
      }),
    };
    try {
      await handleUpdateProgram(updatedProgram);
    } catch (error) {
      console.log(error);
    }
  };

  // Update the dataSource array when the carePlan.activeProgram.Videos array changes
  useEffect(() => {
    if (carePlan && carePlan.activeProgram && carePlan.activeProgram.Videos) {
      setDataSource(carePlan.activeProgram.Videos);
    }
    form.setFieldsValue(carePlan.activeProgram);
  }, [carePlan.activeProgram]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={carePlan.activeProgram}
      onFinish={onFinish}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="ProgramName"
            label="Program name"
            rules={[
              {
                required: true,
                message: 'Please enter program name',
              },
            ]}
          >
            <Input placeholder="Please enter program name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="Diagnosis"
            label="Diagnosis"
            rules={[
              {
                required: true,
                message: 'Please enter diagnosis',
              },
            ]}
          >
            <Input placeholder="Please enter diagnosis" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="PatientTerm"
            label="Patient Term"
            rules={[
              {
                required: true,
                message: 'Please enter patient term',
              },
            ]}
          >
            <Input placeholder="Please enter patient term" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey="Id"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            onRow={record => ({
              onClick: () => {
                handleToggleDrawer(record);
              },
            })}
          />
        </Col>
      </Row>
      <div className="care-button-container">
        <Button
          className="care-button"
          type="primary"
          size="large"
          shape="round"
          loading={carePlan.isLoading}
          htmlType="submit"
        >
          {carePlan.isLoading ? 'Saving. Please wait!' : 'Save'}
        </Button>
      </div>
    </Form>
  );
}
