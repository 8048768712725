import React, { Component } from 'react';
import { Button, Row, Col, Breadcrumb, Input, Table, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getGroups, viewingGroups, searchGroups } from './actions/groups';
import {
  isLoading,
  doneLoading,
  isSearching,
  doneSearching
} from './actions/groupsLoadingState';
import { Link } from 'react-router-dom';
import PTWTable from './PTWTable';
import SearchBox from '../../components/SearchBox';
import Loading from '../../components/Loading';
import { ClickableRow } from './ClickableRow';
import DropdownNavigation from '../../components/DropdownNavigation';
import DispatchLinkItem from '../../components/DispatchLinkItem';

class Groups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pending: null
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const searchTerm = event.target.value;
    const { dispatch } = this.props;

    if (this.state.pending) {
      clearTimeout(this.state.pending);
    }

    this.setState({
      pending: setTimeout(() => {
        if (searchTerm) {
          dispatch(isSearching());
          dispatch(isLoading());
          dispatch(searchGroups(searchTerm)).then(
            () => dispatch(doneLoading()),
            () => dispatch(doneLoading())
          );
        } else {
          dispatch(doneSearching());
          dispatch(isLoading());
          dispatch(getGroups()).then(
            () => dispatch(doneLoading()),
            () => dispatch(doneLoading())
          );
        }
      }, 200)
    });
  }

  componentDidMount() {
    this.props.dispatch(isLoading());
    this.props.dispatch(viewingGroups());
    this.props
      .dispatch(getGroups())
      .then((x) => {
        setTimeout(() => {
          this.initializing = false;
          this.props.dispatch(doneLoading());
        }, 500);
      })
      .catch((x) => {
        this.props.dispatch(doneLoading());
      });
  }

  sortGroup(sortBy, ascending) {
    return (item1, item2) => {
      if (item1[sortBy] < item2[sortBy]) {
        return ascending ? -1 : 1;
      } else if (item1[sortBy] > item2[sortBy]) {
        return ascending ? 1 : -1;
      }
      return 0;
    };
  }

  render() {
    const groupFields = [
      {
        title: 'Group Name',
        dataIndex: 'name',
        key: 'name',
        showSorterTooltip: { target: 'full-header' },
        sorter: (a, b) => a.name.localeCompare(b),
        sortDirections: ['descend']
      },
      {
        title: 'Group Phone',
        dataIndex: 'groupPhone',
        key: 'groupPhone'
      },
      {
        title: 'Contact Name',
        dataIndex: 'contactName',
        key: 'contactName'
      },
      {
        title: 'Contact Phone',
        dataIndex: 'contactPhone',
        key: 'contactPhone'
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Tooltip title="Edit Group">
            <Button
              onClick={() => {
                this.props.history.push(`/groups/${record.name}`);
              }}
              icon={<EditOutlined />}
              shape="circle"
            />
          </Tooltip>
        )
      }
    ];

    let content;
    const { fetched, groups, isLoadingGrid, isSearching } = this.props;

    if (fetched) {
      if (groups.length === 0) {
        if (isSearching) {
          content = (
            <Row>
              <Col md={4} mdOffset={4} className="ptw-action-block">
                No results found
              </Col>
            </Row>
          );
        }
      } else {
        content = (
          <PTWTable items={groups} sortByDefault="name" fields={groupFields} />
        );
      }
    }

    return (
      <React.Fragment>
        <DropdownNavigation />

        <div className="ptw-main-body">
          <div className="tab-header">
            <Breadcrumb className="care-breadcrumb mb-1">
              <Breadcrumb>
                <DispatchLinkItem title="Admin&nbsp;" url="/administrator" />
                <Breadcrumb.Item active>Groups</Breadcrumb.Item>
              </Breadcrumb>
            </Breadcrumb>
            <div className="tab-header-controls">
              <Link to="/groups/new">
                <Button type="primary" className="btn-primary ptw-btn">
                  <PlusOutlined /> Add Group
                </Button>
              </Link>
              <Input
                size="middle"
                placeholder="Search group name"
                prefix={<SearchOutlined />}
                onChange={this.onChange}
              />
            </div>
          </div>
          <Loading isLoading={isLoadingGrid}>{content}</Loading>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    groups: Object.values(state.groups.items),
    isLoadingGrid: state.groups.isLoading,
    isSearching: state.groups.isSearching,
    fetched: state.groups.fetched
  };
}

export default connect(mapStateToProps)(withRouter(Groups));
