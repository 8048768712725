import React, { Component, Fragment } from 'react';
import { Modal, Button, Row, Col, Typography } from 'antd';
import { connect } from 'react-redux';
import { currentUserGroupSelector } from '../../exercise-library/selectors';
import ReactCrop from 'react-image-crop';
import html2canvas from 'html2canvas';
import 'react-image-crop/dist/ReactCrop.css';
import './modals.css';

const cropStyle = {};

class ProfileImage extends Component {
  constructor(props) {
    super(props);

    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        this.setState({ image64: reader.result });
      },
      false
    );

    reader.readAsDataURL(props.image);

    this.state = {
      image64: null,
      crop: {
        unit: '%',
        width: 30,
        aspect:
          props.isExerciseImage && props.isExerciseImage === true
            ? 260 / 160
            : 5 / 5,
      },
      blob: null,
    };
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'profile-pic.png'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          this.setState({ blob });

          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        'image/png',
        1
      );
    });
  }

  submit = () => {
    const { blob } = this.state;
    const { onSubmit } = this.props;

    html2canvas(document.querySelector('#preview')).then(canvas => {
      const base64Image = canvas.toDataURL('image/png', 0.3);

      onSubmit(blob, base64Image);
    });
  };

  render() {
    const {
      submit,
      onCropComplete,
      onImageLoaded,
      onCropChange,
      props: { show, onHide, onExit, uploadLoading, isExerciseImage },
      state: { image64, crop, croppedImageUrl },
    } = this;

    return (
      <Modal
        title="Crop Image"
        visible={show}
        width={'60%'}
        bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
        destroyOnClose
        maskClosable={false}
        onCancel={onHide}
        footer={[
          <Button
            className="ptw-btn btn-primary"
            type="primary"
            size="large"
            loading={uploadLoading}
            onClick={() => {
              onExit();
              submit();
            }}
          >
            Save Changes
          </Button>,
          <Button
            className="ptw-btn"
            type="default"
            htmlType="submit"
            size="large"
            onClick={onHide}
          >
            Cancel
          </Button>,
        ]}
      >
        {!image64 ? (
          <div className="loader">Loading...</div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Row gutter={[16, 16]}>
                  <Col span={10}>
                    <Typography.Title level={4}>
                      Instructions for Image Crop
                    </Typography.Title>
                    {isExerciseImage ? (
                      <Fragment>
                        <Typography.Paragraph style={{ textAlign: 'left' }}>
                          &bull; For best results, use an image with a 260x160
                          aspect ratio. Images that are below 260x160px will
                          appear pixelated.
                        </Typography.Paragraph>
                        <Typography.Paragraph style={{ textAlign: 'left' }}>
                          &bull; We support image uploads of up to 2.5MB.
                          However, we recommend to upload image with less than
                          1MB for optimal search speed result.
                        </Typography.Paragraph>
                      </Fragment>
                    ) : (
                      <Typography.Paragraph style={{ textAlign: 'left' }}>
                        For best results, use an image with a 160x160 aspect
                        ratio. Images that are below 160x160px will appear
                        pixelated.
                      </Typography.Paragraph>
                    )}
                    <Typography.Paragraph style={{ textAlign: 'left' }}>
                      Drag crop square until the image appears great in the
                      preview!
                    </Typography.Paragraph>
                  </Col>
                  <Col span={14}>
                    <div style={cropStyle} ref={'cropRef'}>
                      <ReactCrop
                        src={image64}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Typography.Title level={4}>Preview</Typography.Title>
                <div className="preview-container">
                  {croppedImageUrl && (
                    <div id="preview">
                      <img
                        alt="Crop"
                        className="ReactCrop__image"
                        style={{ maxWidth: '100%' }}
                        src={croppedImageUrl}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  group: currentUserGroupSelector(state),
  filteredList: state.patients.patients,
});

export default connect(mapStateToProps)(ProfileImage);
