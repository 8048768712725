import React, { useEffect, useState, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { debounce } from 'lodash';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Menu,
  Popover,
  Space,
  Tooltip,
  Typography,
  notification
} from 'antd';
import {
  FilterOutlined,
  InfoCircleFilled,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons';

import {
  clearExportPrescription,
  clearTemplateData,
  setSelectedExercises
} from '../actions/prescription';

import services from '../../../services';
import states from '../../../states';

import ExerciseTemplateCreation from './ExerciseTemplateCreation';
import ExerciseTemplateGrid from './ExerciseTemplateGrid';
import LoadingPage from '../../../components/LoadingPage';
import NoResults from '../../../components/NoResults';

const popoverContent = (
  <Space direction="vertical">
    <div className="popover-title-container">
      {' '}
      <Typography.Text strong>Templates</Typography.Text>
      <a
        className={'pull-right btn-link'}
        href={'https://vimeo.com/363131034/0276db8d8c'}
        target={'_blank'}
      >
        See Tutorial Video
      </a>
    </div>
    <Typography.Text>
      Templates allow you to save exercise programs for quick and easy reuse in
      <br />
      the future. After importing a Template into a patient's prescription, you
      <br />
      are still able to make custom changes. Templates are shared between all
      <br />
      providers in your group.
    </Typography.Text>
  </Space>
);

const filterTypeToText = (type) => {
  switch (type) {
    case 'GROUP_TEMPLATES':
      return 'Group Templates';
    case 'MY_TEMPLATES':
      return 'My Templates';
    default:
      return '';
  }
};

function ExerciseTemplates(props) {
  const {
    dispatch,
    visibleProfile,
    exportPrescription,
    selectedExercises,
    importedTemplate
  } = props;

  const [templateState, setTemplateState] = useRecoilState(states.templates);

  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [listView, setListView] = useState(!!!exportPrescription);
  const [isEditingTemplate, setIsEditingTemplate] = useState(false);
  const [search, setSearch] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState([
    !!visibleProfile.GroupInfo?.EnableDefaultGroupTemplates
      ? 'GROUP_TEMPLATES'
      : 'MY_TEMPLATES'
  ]);
  const [templateData, setTemplateData] = useState(
    exportPrescription || importedTemplate
  );

  useEffect(() => {
    if (!!exportPrescription) {
      dispatch(setSelectedExercises(exportPrescription.exercises));
      dispatch(clearExportPrescription());
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  useEffect(() => {
    setTemplates(sortList(templateState.list));
  }, [templateState.list]);

  const debouncedSearch = useRef(
    debounce(async (query, input, creator) => {
      const filter = query.length ? query : null;
      const search = input;
      const author = creator;

      await fetchTemplates(filter, search, author);
    }, 500)
  ).current;

  const fetchTemplates = async (query, input, author) => {
    try {
      setLoading(true);

      const response = await services.templates.getList(
        visibleProfile.GroupId,
        query,
        input,
        author
      );

      if (response.status === 200) {
        setTemplateState((prevState) => ({
          ...prevState,
          list: response.data
        }));
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while fetching templates.'
      });
    } finally {
      setLoading(false);
    }
  };

  const getTemplates = async () => {
    const query = filter.length ? filter : null;
    const author = filter.includes('MY_TEMPLATES')
      ? visibleProfile.EmailAddress
      : null;

    await fetchTemplates(query, search, author);
  };

  const sortList = (list) => {
    return [...list].sort((a, b) => {
      const nameA = a.Name?.toLowerCase() || '';
      const nameB = b.Name?.toLowerCase() || '';

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  };

  const handleSelectFilter = (e) => {
    const type = e.target.name;
    const filterArr = filter.includes(type)
      ? filter.filter((item) => item !== type)
      : [...filter, type];
    const author = filterArr.includes('MY_TEMPLATES')
      ? visibleProfile.EmailAddress
      : null;

    setFilter(filterArr);
    fetchTemplates(filterArr, search, author);
  };

  const handleInputSearch = (e) => {
    const value = e.target.value;
    const author = filter.includes('MY_TEMPLATES')
      ? visibleProfile.EmailAddress
      : null;

    setSearch(value);
    debouncedSearch(filter, value, author);
  };

  const handleSetListView = () => {
    setListView(true);
  };

  const handleClickItem = async (item) => {
    if (item?.Exercises?.length) {
      setTemplateData(item);
      setListView(false);
      setIsEditingTemplate(true);
      dispatch(setSelectedExercises(item.Exercises));

      return;
    }

    try {
      notification.info({
        message: 'Loading...',
        description: 'Fetching template data. Please wait.'
      });

      const { GroupId, Id } = item;
      const response = await services.templates.getItem(GroupId, Id);

      if (response.status === 200) {
        notification.destroy();

        setTemplateData(response.data);
        setListView(false);
        setIsEditingTemplate(true);
        dispatch(setSelectedExercises(response.data.Exercises));
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while fetching template data.'
      });
    }
  };

  if (listView) {
    return (
      <React.Fragment>
        <div className="tab-header">
          <Typography.Title level={2}>
            Templates{' '}
            <Popover
              content={popoverContent}
              trigger="hover"
              placement="right"
              arrowPointAtCenter
            >
              <InfoCircleFilled className="popover-icon" />
            </Popover>
          </Typography.Title>
          <div className="tab-header-controls">
            <Button
              type="primary"
              className="btn-primary ptw-btn"
              onClick={() => {
                dispatch(clearTemplateData());
                setTemplateData([]);
                setListView(false);
                setIsEditingTemplate(false);
              }}
            >
              <PlusOutlined /> Add Template
            </Button>
            <Input
              autoFocus
              size="middle"
              placeholder="Search template or author"
              value={search}
              prefix={<SearchOutlined />}
              onChange={handleInputSearch}
            />
            <Tooltip title="Filter Templates">
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="group-templates" onClick={() => null}>
                      <Checkbox
                        name="GROUP_TEMPLATES"
                        checked={filter.includes('GROUP_TEMPLATES')}
                        onChange={handleSelectFilter}
                      >
                        Group Templates
                      </Checkbox>
                    </Menu.Item>
                    <Menu.Item key="my-templates" onClick={() => null}>
                      <Checkbox
                        name="MY_TEMPLATES"
                        checked={filter.includes('MY_TEMPLATES')}
                        onChange={handleSelectFilter}
                      >
                        My Templates
                      </Checkbox>
                    </Menu.Item>
                  </Menu>
                }
                onOpenChange={(e) => setFilterOpen(e)}
                open={filterOpen}
                trigger={['click']}
                placement="bottomRight"
              >
                <Button className="btn-default" shape="circle">
                  <FilterOutlined style={{ fontSize: 16 }} />
                </Button>
              </Dropdown>
            </Tooltip>
          </div>
        </div>
        {!!filter.length && (
          <div style={{ marginBottom: 20 }}>
            <Typography.Text style={{ fontSize: 16 }} level={5}>
              Filtered by -{' '}
              <Typography.Link onClick={() => setFilterOpen(!filterOpen)}>
                {filter.map((item) => filterTypeToText(item)).join(', ')}{' '}
              </Typography.Link>
            </Typography.Text>
          </div>
        )}
        {search && (
          <div style={{ marginBottom: 20 }}>
            <Typography.Text>
              Search results for:{' '}
              <Typography.Text strong>{search}</Typography.Text>
            </Typography.Text>
          </div>
        )}
        {templates.length === 0 && search.length !== 0 && (
          <NoResults content="Sorry, no results found." />
        )}
        {loading ? (
          <LoadingPage
            type="list"
            content="Loading templates, please wait..."
          />
        ) : (
          <ExerciseTemplateGrid
            templates={templates}
            handleCardClick={handleClickItem}
          />
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Breadcrumb separator="/">
        <Breadcrumb.Item
          href=""
          onClick={(e) => {
            e.preventDefault();
            handleSetListView();
          }}
        >
          Templates
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          {isEditingTemplate ? 'Edit Template' : 'Add Template'}
        </Breadcrumb.Item>
      </Breadcrumb>

      <ExerciseTemplateCreation
        dispatch={dispatch}
        visibleProfile={visibleProfile}
        isEditingTemplate={isEditingTemplate}
        selectedExercises={selectedExercises}
        templateData={templateData}
        setTemplateData={setTemplateData}
        refetchList={getTemplates}
        backToIndex={handleSetListView}
      />
    </React.Fragment>
  );
}

export default ExerciseTemplates;
