import { Button, Modal, Typography } from 'antd';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import {
  toCamelCaseObjKeys,
  toPascalCaseObjKeys
} from '../../../utils/object.utils';
import { delay } from '../RTMDashboard/components/PatientDetailDrawer/mixins';

import services from '../../../services';
import setNotification from '../../../utils/setNotification.utils';
import states from '../../../states';

const ToggleMessagingModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useRecoilState(states.patients);

  const { details } = patients;
  const toggle =
    details &&
    details.preferences &&
    details.preferences.messaging &&
    details.preferences.messaging === 'disabled'
      ? 'Enable'
      : 'Disable';

  const handleToggleMessaging = async () => {
    const value = toggle.toLowerCase() + 'd';

    let notifType = 'success';
    let notifHead = 'Success!';
    let notifDesc = `Messaging has been ${value} sucessfully.`;

    try {
      setLoading(true);

      const response = await services.preferences.updatePreferences({
        sub: details.sub,
        preferences: {
          ...toPascalCaseObjKeys(details.preferences),
          Messaging: value
        }
      });

      if (value === 'enabled') {
        await delay(1500);
      }

      setPatients((prevState) => ({
        ...prevState,
        details: {
          ...prevState.details,
          preferences: toCamelCaseObjKeys(response.data.Preferences)
        }
      }));
    } catch (error) {
      notifType = 'error';
      notifHead = 'Error!';
      notifDesc = 'An error occurred while updating messaging preference.';
    } finally {
      setLoading(false);
      setNotification(notifType, notifHead, notifDesc);

      onClose();
    }
  };

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title={toggle + ' Messaging?'}
      footer={[
        <Button
          key="cancel"
          type="default"
          size="large"
          className="ptw-btn"
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          key="toggle"
          type="primary"
          size="large"
          className="ptw-btn btn-primary"
          danger={toggle === 'Disable'}
          loading={loading}
          onClick={handleToggleMessaging}
        >
          {toggle}
        </Button>
      ]}
    >
      <Typography.Text>
        {toggle} Messaging will {toggle === 'Disable' ? 'remove' : 'restore'}{' '}
        the ability for this patient to communicate with their assigned
        providers. This can be{' '}
        {toggle === 'Disable' ? 're-enabled' : 'disabled'} in the future.
      </Typography.Text>
    </Modal>
  );
};

export default ToggleMessagingModal;
