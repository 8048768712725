import apiFetch from '../utils/fetch.utils';

export const getProfile = async email => {
  try {
    const uri = `cognito?emailAddress=${encodeURIComponent(email.trim())}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateProfile = async (action, payload) => {
  try {
    const uri = 'cognito';
    const method = 'POST';

    return apiFetch(uri, method, {
      body: {
        action,
        ...payload
      }
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};
