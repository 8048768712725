import { Typography } from 'antd';
import React, { Fragment } from 'react';

export const popName = (
  <Fragment>
    <Typography.Paragraph style={{ color: '#fff' }}>
      The name of the exercise. This can be updated through the clinic exercise
      library, but cannot be changed on a case-by-case basis when prescribing
      this exercise to a patient.
    </Typography.Paragraph>
    <a
      href={'https://ptwired.zendesk.com/hc/en-us/articles/360044190434'}
      target={'_blank'}
    >
      See Tutorial Video
    </a>
  </Fragment>
);

export const popParameters = (
  <Fragment>
    <Typography.Paragraph style={{ color: '#fff' }}>
      The default values for Sets, Reps, and Hold when prescribing this
      exercise. At least one field is required. This can be changed on a
      case-by-case basis when prescribing this exercise to a patient.
    </Typography.Paragraph>
    <a
      href={'https://ptwired.zendesk.com/hc/en-us/articles/360044190434'}
      target={'_blank'}
    >
      See Tutorial Video
    </a>
  </Fragment>
);

export const popVideo = (
  <Fragment>
    <Typography.Paragraph style={{ color: '#fff' }}>
      The web URL for the video that you are adding to the system. This could be
      a YouTube link, Vimeo link, or any other video hosting platform.
    </Typography.Paragraph>

    <a
      href={'https://ptwired.zendesk.com/hc/en-us/articles/360044190434'}
      target={'_blank'}
    >
      See Tutorial Video
    </a>
  </Fragment>
);
export const popTag = (
  <Fragment>
    <Typography.Paragraph style={{ color: '#fff' }}>
      The key words you want associated with this exercise for easier search. If
      you want this exercise to be listed when certain filters are selected, add
      those filter names to the tags. For example, add 'adduction' as a tag if
      you want this exercise to be listed when the 'adduction' filter is
      selected'. Tags do not need to be filters - you can add any word as a tag
      to have this exercise show up if that word is used in the search box. You
      can list multiple tags, separated by either spaces or commas.
    </Typography.Paragraph>
    <a
      href={'https://ptwired.zendesk.com/hc/en-us/articles/360044190434'}
      target={'_blank'}
    >
      See Tutorial Video
    </a>
  </Fragment>
);

export const popImage = (
  <Fragment>
    <Typography.Paragraph style={{ color: '#fff' }}>
      The exercise image for this exercise. This will be displayed to patients
      on the mobile app, displayed to providers in the search results, and will
      also be used if a patient's prescription is exported as a PDF. To ensure
      the best results on all devices, please use a 260x160 aspect ratio. Any
      image uploaded will automatically be adjusted to this aspect ratio.
    </Typography.Paragraph>
    <a
      href={'https://ptwired.zendesk.com/hc/en-us/articles/360044190434'}
      target={'_blank'}
    >
      See Tutorial Video
    </a>
  </Fragment>
);

export const popImageCompatibleFormats = (
  <Fragment>
    <Typography.Paragraph style={{ color: '#fff' }}>
      Video URL Formats If you are uploading a YouTube link, you must use the
      following format for your video:
    </Typography.Paragraph>
    <Typography.Paragraph strong style={{ color: '#fff' }}>
      https://www.youtube.com/watch?v=VIDEO_ID
    </Typography.Paragraph>
    <Typography.Paragraph style={{ color: '#fff' }}>
      Any other format will result in an error. If you've copied the URL in a
      different format, paste it into your web browser and it should load into
      the correct format. From there you can copy and paste it into PT Wired.
    </Typography.Paragraph>
    <Typography.Paragraph style={{ color: '#fff' }}>
      If you have any issues, contact us with the 'Chat' feature and we can
      help!
    </Typography.Paragraph>
  </Fragment>
);

export const popDescription = (
  <Fragment>
    <Typography.Paragraph style={{ color: '#fff' }}>
      The text instructions for this exercise. This can be changed on a
      case-by-case basis when prescribing this exercise to a patient.
    </Typography.Paragraph>
    <a
      href={'https://ptwired.zendesk.com/hc/en-us/articles/360044190434'}
      target={'_blank'}
    >
      See Tutorial Video
    </a>
  </Fragment>
);

export const popExerciseLevelFrequency = (
  <Fragment>
    <Typography.Paragraph style={{ color: '#fff' }}>
      Rather than choosing a single frequency for all exercises in the
      prescription, use Exercise Level Frequency to choose a specific frequency
      for each exercise.
    </Typography.Paragraph>
    <a
      href={'https://ptwired.zendesk.com/hc/en-us/articles/360044190434'}
      target={'_blank'}
    >
      Help Center
    </a>
  </Fragment>
);
