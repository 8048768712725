import {
  BarbellOutline,
  MedkitOutline,
  PeopleCircleOutline,
  PersonOutline,
  PinOutline,
} from 'react-ionicons';

const iconStyle = {
  height: 32,
  width: 32,
  verticalAlign: 'middle',
  marginTop: -5,
  marginRight: 8,
};

export const stats = [
  {
    title: 'Patients Added',
    value: 0,
    icon: <PeopleCircleOutline style={iconStyle} />,
  },
  {
    title: 'HEPs Assigned',
    value: 0,
    icon: <BarbellOutline style={iconStyle} />,
  },
  {
    title: 'Care Plans Assigned',
    value: 0,
    icon: <MedkitOutline style={iconStyle} />,
  },
  {
    title: 'Providers',
    value: 0,
    icon: <PersonOutline style={iconStyle} />,
  },
  {
    title: 'Locations',
    value: 0,
    icon: <PinOutline style={iconStyle} />,
  },
];

export const colors = [
  {
    border: 'rgb(53, 162, 235)',
    background: 'rgba(53, 162, 235, 0.5)',
  },
  {
    border: 'rgb(42, 56, 69)',
    background: 'rgba(42, 56, 69, 0.5)',
  },
  {
    border: 'rgb(245, 203, 66)',
    background: 'rgba(245, 203, 66, 0.5)',
  },
  {
    border: 'rgb(173, 56, 45)',
    background: 'rgba(173, 56, 45, 0.5)',
  },
  {
    border: 'rgb(69, 82, 26)',
    background: 'rgba(69, 82, 26, 0.5)',
  },
  {
    border: 'rgb(237, 119, 160)',
    background: 'rgba(237, 119, 160, 0.5)',
  },
  {
    border: 'rgb(70, 105, 99)',
    background: 'rgba(70, 105, 99, 0.5)',
  },
  {
    border: 'rgb(39, 94, 143)',
    background: 'rgba(39, 94, 143, 0.5)',
  },

  {
    border: 'rgb(39, 171, 219)',
    background: 'rgba(39, 171, 219, 0.5)',
  },
  {
    border: 'rgb(237, 227, 81)',
    background: 'rgba(237, 227, 81, 0.5)',
  },
  {
    border: 'rgb(255, 145, 0)',
    background: 'rgba(255, 145, 0, 0.5)',
  },
  {
    border: 'rgb(135, 46, 83)',
    background: 'rgba(135, 46, 83, 0.5)',
  },
  {
    border: 'rgb(54, 156, 68)',
    background: 'rgba(54, 156, 68, 0.5)',
  },
];
