/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useRecoilValue } from 'recoil';
import { Button, Input, List, Modal, Typography } from 'antd';
import {
  LoadingOutlined,
  SearchOutlined,
  SendOutlined
} from '@ant-design/icons';
import { toCamelCaseObjKeys } from '../../../utils/object.utils';

import _ from 'lodash';
import v from 'voca';

import states from '../../../states';
import services from '../../../services';

const NewBoardModal = ({
  visibleProfile,
  open,
  loading,
  sub,
  onClose,
  onAddNewBoard
}) => {
  const messages = useRecoilValue(states.messages);
  const patients = useRecoilValue(states.patients);

  const [filteredList, setFilteredList] = useState([...patients.list]);
  const [filter, setFilter] = useState('');
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (!open) {
      setFilter('');
      setFilteredList([...patients.list]);
    }
  }, [open]);

  const debouncedSearch = useRef(
    _.debounce(async (input) => {
      try {
        setFetching(true);

        const { GroupId } = visibleProfile;
        await services.patients.getPatients(GroupId, input).then((res) => {
          if (res.status === 200) {
            setFilteredList(res.data);
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        setFetching(false);
      }
    }, 500)
  ).current;

  const handleClose = () => {
    setFilter('');
    setFilteredList([]);

    onClose();
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    const input = value.toLowerCase().trim();

    setFilter(value);
    if (input) {
      debouncedSearch(input);
    } else {
      setFilteredList([...patients.list]);
    }
  };

  return (
    <Modal
      closable
      footer={null}
      visible={open}
      bodyStyle={{ height: 512, padding: 0 }}
      title={
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          Add Converstation
        </Typography.Title>
      }
      onCancel={handleClose}
    >
      <div className="new-msg-modal-input">
        <Input
          placeholder="Search patients.."
          value={filter}
          onChange={handleSearch}
          prefix={<SearchOutlined />}
        />
      </div>

      <div className="new-msg-modal-content">
        {fetching ? (
          <div className="search-loading">
            <LoadingOutlined
              style={{
                fontSize: '4em'
              }}
            />
          </div>
        ) : (
          <List
            dataSource={filteredList}
            renderItem={(item, i) => {
              const fname = item.firstName || item.FirstName;
              const lname = item.lastName || item.LastName;
              const email = item.emailAddress || item.EmailAddress;
              const phone = item.phoneNumber;
              const uname = item.sub || item.Sub;
              const included = messages.list.some((p) => p.Owner === uname);

              return (
                <List.Item key={i}>
                  <List.Item.Meta
                    title={`${v.capitalize(fname.trim())} ${v.capitalize(
                      lname.trim()
                    )}`}
                    description={email || phone}
                  />

                  <Button
                    type="link"
                    size="large"
                    icon={<SendOutlined />}
                    loading={loading && sub === uname}
                    disabled={(loading && sub !== uname) || included}
                    onClick={() => {
                      onAddNewBoard({
                        ...toCamelCaseObjKeys(item)
                      });
                    }}
                  />
                </List.Item>
              );
            }}
          />
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  visibleProfile: state.visibleProfile
});

export default withRouter(connect(mapStateToProps)(NewBoardModal));
