import React, { useEffect, useState, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRecoil } from 'recoil-nexus';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
  Upload,
  message,
  notification,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import isEmail from 'validator/lib/isEmail';
import config from '../../config';
import services from '../../services';
import states from '../../states';
import ProfileImage from '../patients/Modals/ProfileImage';
import moment from 'moment';

const { Option } = Select;

const PT = 'pt';
const GA = 'ga';

const getBillingRole = role => {
  if (!role) return '';

  switch (role.toLowerCase()) {
    case 'pt':
      return 'Provider';

    case 'pta':
      return 'Provider Assistant';

    case 'tech':
      return 'Technician';

    default:
      return role;
  }
};

const isActive = value => {
  if (value === true || value === 'true') {
    return true;
  } else if (value === false || value === 'false') {
    return false;
  } else return true;
};

const checkAdmin = value => {
  if (value === GA) {
    return true;
  } else if (value === PT) {
    return false;
  } else return false;
};

function UserForm(props) {
  const {
    currentUser,
    onSubmit,
    uploadImage,
    submitting,
    initialValues: {
      Active,
      BillingRole,
      EmailAddress,
      FirstName,
      LastName,
      Role,
      PartTime,
    },
  } = props;

  // get 3 days from now using new Date()

  const [cropping, setCropping] = useState(false);
  const [file, setFile] = useState(null);
  const [imgUrl, setImgUrl] = useState('');

  const formRef = useRef(null);
  const groups = getRecoil(states.groups);

  const [messaging, setMessaging] = useState('enabled');
  const [location, setLocation] = useState(null);
  const [rtmStatus, setRtmStatus] = useState('disabled');
  const [notifyBillableCodes, setNotifyBillableCodes] = useState('enabled');
  const [promptLocationPopup, setPromptLocationPopup] = useState('disabled');
  const [painScaleThreshold, setPainScaleThreshold] = useState(null);
  const [painScaleNotification, setPainScaleNotification] = useState('enabled');
  const [cancelDate, setCancelDate] = useState(null);
  const [editCancelDate, setEditCancelDate] = useState(false);

  const [firstName, setFirstName] = useState(FirstName);
  const [lastName, setLastName] = useState(LastName);
  const [emailAddress, setEmailAddress] = useState(EmailAddress);
  const [billingRole, setBillingRole] = useState(BillingRole);
  const [role, setRole] = useState(Role ? Role : PT);
  const [active, setActive] = useState(Active ? isActive(Active) : true);
  const [partTime, setPartTime] = useState(PartTime || false);
  const [emailError, setEmailError] = useState(false);
  const [uniqueEmail, setUniqueEmail] = useState(true);
  const [validating, setValidating] = useState(false);

  const incomplete = !firstName || !lastName || !emailAddress || !billingRole;
  const notEdited =
    FirstName === firstName &&
    LastName === lastName &&
    EmailAddress === emailAddress &&
    BillingRole === billingRole &&
    Role === role &&
    isActive(Active) === active &&
    PartTime === partTime;

  const preferences = currentUser.Preferences;
  const notEditedPreferences =
    (preferences?.Messaging || 'enabled') === messaging &&
    (preferences?.Location || null) === location &&
    (preferences?.RTMStatus || 'disabled') === rtmStatus &&
    (preferences?.NotifyBillableCodes || 'enabled') === notifyBillableCodes &&
    (preferences?.PromptLocationPopup || 'disabled') === promptLocationPopup &&
    (preferences?.PainScaleThreshold || null) === painScaleThreshold &&
    (preferences?.PainScaleNotification || 'enabled') === painScaleNotification;

  const notEditedCancelDate =
    moment(currentUser.CancelDate).format('MMMM DD, YYYY') ===
    moment(cancelDate).format('MMMM DD, YYYY');

  /**
   * Create Default Image
   * */
  useEffect(() => {
    const { GroupId, Sub, Preferences, CancelDate } = currentUser;

    if (GroupId || Sub) {
      setImgUrl(
        `https://ptwired-exercise-images-prod.s3.amazonaws.com/${GroupId}/${Sub}.jpg`
      );
    }

    if (Preferences) {
      setMessaging(Preferences.Messaging || 'enabled');
      setLocation(Preferences.Location || null);
      setRtmStatus(Preferences.RTMStatus || 'disabled');
      setNotifyBillableCodes(Preferences.NotifyBillableCodes || 'enabled');
      setPromptLocationPopup(Preferences.PromptLocationPopup || 'disabled');
      setPainScaleThreshold(Preferences.PainScaleThreshold || null);
      setPainScaleNotification(Preferences.PainScaleNotification || 'enabled');
    }

    // check if CancelDate is in the past
    if (active && CancelDate && moment(CancelDate).isAfter(moment())) {
      setCancelDate(moment(CancelDate));
    }
  }, []);

  useEffect(() => {
    if (!active) {
      setEditCancelDate(true);
    } else {
      setEditCancelDate(false);
    }
  }, [active]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  }

  const [imageLoading, setImageLoading] = useState(false);

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setImageLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file, imageUrl => {
        setImgUrl(imageUrl);
        setImageLoading(false);
      });
    }
  };

  const handleImage = params => {
    setFile(params.file);
    setCropping(true);
  };

  const updateImage = async blob => {
    try {
      const { GroupId, Sub } = currentUser;
      const imageSrc = `https://ptwired-exercise-images-prod.s3.amazonaws.com/${GroupId}/${Sub}.jpg`;

      await uploadImage(blob).then(() => {
        setImgUrl(imageSrc);

        notification.success({
          message: 'Success',
          description: 'Image uploaded successfully!',
        });
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description:
          'There was an error uploading the image. Please try again.',
      });
    } finally {
      setImageLoading(false);
    }
  };

  const submitEmail = e => {
    const value = e.target.value.trim().toLowerCase();

    setEmailAddress(value);
    setEmailError(!isEmail(value));
  };

  const validateEmail = async e => {
    const value = e.target.value.trim().toLowerCase();
    const input = encodeURIComponent(value);
    const url = `${config.root}/users/external?input=${input}`;

    if (!value || emailError) {
      setUniqueEmail(true);
      return;
    }

    try {
      setValidating(true);

      const response = await fetch(url);
      const data = await response.json();

      let unique = data.sub === value;
      if (data.sub === currentUser.Sub) {
        unique = true;
      }

      setUniqueEmail(unique);
    } catch (error) {
      setUniqueEmail(false);
    } finally {
      setValidating(false);
    }
  };

  const disablePastDate = current => {
    return current && current.valueOf() < new Date();
  };

  const submit = async () => {
    const { initialValues } = props;
    const { GroupId } = props.visibleProfile;
    const cancelDateUTC = moment(cancelDate).startOf('day').valueOf();

    const form = {
      Sub: initialValues.Sub,
      FirstName: firstName,
      LastName: lastName,
      EmailAddress: emailAddress,
      BillingRole: billingRole,
      Role: role,
      Active: active,
      CancelDate: cancelDate
        ? moment(cancelDate).startOf('day').valueOf()
        : null,
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      billingRole: billingRole,
      role: role,
      active: active,
      groupId: GroupId,
      GroupId: GroupId,
      PartTime: partTime,
      isEmailChanged: initialValues.EmailAddress !== emailAddress,
    };

    const editedCancelDate = !notEditedCancelDate;

    await onSubmit(form, editedCancelDate).then(async () => {
      if (!notEditedPreferences) {
        let userSub = currentUser?.Sub;
        if (!currentUser?.Sub) {
          const input = encodeURIComponent(emailAddress);
          await services.user.getDetails('search', input).then(res => {
            if (res.status === 200) {
              userSub = res.data.Sub;
            }
          });
        }

        await services.preferences.updatePreferences({
          sub: userSub,
          preferences: {
            Messaging: messaging,
            Location: location,
            RTMStatus: rtmStatus,
            NotifyBillableCodes: notifyBillableCodes,
            PromptLocationPopup: promptLocationPopup,
            PainScaleThreshold: painScaleThreshold,
            PainScaleNotification: painScaleNotification,
          },
        });
      }
    });
  };

  const renderSchedDeactivation = () => {
    return (
      <Col>
        {editCancelDate ? (
          <Form.Item
            style={{ marginTop: 5 }}
            label="Deactivation Date"
            tooltip="Select the date you want to deactivate this provider."
            help={
              !active &&
              'Disregard if you want to deactivate this provider immediately.'
            }
          >
            <Space size={12}>
              <DatePicker
                placeholder="Select deactivation date"
                disabledDate={disablePastDate}
                allowClear={false}
                onChange={date => {
                  setCancelDate(date);
                }}
                value={cancelDate}
                style={{ width: 250 }}
              />
              <Space size={6}>
                {!cancelDate && currentUser.CancelDate && (
                  <Typography.Link
                    underline
                    onClick={() => {
                      setCancelDate(moment(currentUser.CancelDate));
                      setActive(Active ? isActive(Active) : true);
                    }}
                  >
                    Revert Changes
                  </Typography.Link>
                )}
                {active && (
                  <Typography.Link
                    underline
                    onClick={() => {
                      setEditCancelDate(false);
                    }}
                  >
                    Cancel
                  </Typography.Link>
                )}
              </Space>
            </Space>
          </Form.Item>
        ) : (
          currentUser.CancelDate && (
            <Space style={{ marginTop: 5 }} size={12}>
              {cancelDate ? (
                <Fragment>
                  <Typography.Text type="secondary">
                    Provider is scheduled to deactivate on{' '}
                    <Typography.Text strong>
                      {moment(cancelDate).format('MMMM DD, YYYY')}
                    </Typography.Text>
                    .
                  </Typography.Text>
                  <Space size={6}>
                    <Typography.Link
                      underline
                      onClick={() => setEditCancelDate(true)}
                    >
                      Edit
                    </Typography.Link>
                    |
                    <Typography.Link
                      underline
                      onClick={() => setCancelDate(null)}
                    >
                      Remove
                    </Typography.Link>
                  </Space>
                </Fragment>
              ) : (
                <Typography.Link
                  underline
                  onClick={() => {
                    setCancelDate(moment(currentUser.CancelDate));
                  }}
                >
                  Revert Changes
                </Typography.Link>
              )}
            </Space>
          )
        )}
      </Col>
    );
  };

  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}

      <div className="ant-upload-text">Upload Profile Image</div>
    </div>
  );

  return (
    <Fragment>
      <Row className="mt-4" gutter={[24, 24]}>
        <Col md={4} sm={0} xs={0} />
        <Col md={16} sm={24} xs={24}>
          <Form name="user-form" layout="vertical">
            {cropping && (
              <ProfileImage
                show={cropping}
                image={file}
                onHide={() => {
                  setCropping(false);
                }}
                onSubmit={val => {
                  updateImage(val);
                }}
                closeButton={() => {
                  setCropping(false);
                }}
                onExit={() => {
                  setCropping(false);
                }}
              />
            )}

            {currentUser && currentUser.EmailAddress && (
              <Form.Item
                name="imageSelector"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  customRequest={params => {
                    handleImage(params);
                  }}
                >
                  {imgUrl ? (
                    <img
                      src={imgUrl}
                      alt="avatar"
                      style={{
                        width: '100%',
                      }}
                      onError={() => {
                        setImgUrl(null);
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            )}

            <Row gutter={[24, 0]}>
              <Col lg={12} md={24} sm={24} xs={24}>
                <Form.Item name="firstName" label="First Name">
                  <Input
                    placeholder="First Name"
                    defaultValue={FirstName}
                    value={firstName}
                    onChange={e => {
                      setFirstName(e.target.value.trim());
                    }}
                  />
                </Form.Item>
              </Col>

              <Col lg={12} md={24} sm={24} xs={24}>
                <Form.Item name="lastName" label="Last Name">
                  <Input
                    placeholder="Last Name"
                    defaultValue={LastName}
                    value={lastName}
                    onChange={e => {
                      setLastName(e.target.value.trim());
                    }}
                  />
                </Form.Item>
              </Col>

              <Col lg={12} md={24} sm={24} xs={24}>
                <Form.Item
                  name="emailAddress"
                  label="Email Address"
                  validateStatus={
                    emailError || !uniqueEmail
                      ? 'error'
                      : emailAddress
                      ? 'success'
                      : null
                  }
                  help={!uniqueEmail ? 'Email must be unique' : null}
                >
                  <Input
                    placeholder="Email Address"
                    defaultValue={EmailAddress}
                    value={emailAddress}
                    onChange={submitEmail}
                    onBlur={validateEmail}
                  />
                </Form.Item>
              </Col>

              <Col lg={12} md={24} sm={24} xs={24}>
                <Form.Item name="billingRole" label="Billing Role">
                  <Select
                    placeholder="Billing Role"
                    value={getBillingRole(billingRole)}
                    defaultValue={getBillingRole(billingRole)}
                    onChange={value => {
                      setBillingRole(value);
                    }}
                  >
                    <Option value={'PT'}>Provider</Option>
                    <Option value={'PTA'}>Provider Assistant</Option>
                    <Option value={'TECH'}>Technician</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col>
                <Form.Item name="parttime">
                  <Space>
                    <Switch
                      checked={partTime}
                      onChange={val => {
                        setPartTime(val);
                      }}
                    />

                    <Typography.Text>Part-Time</Typography.Text>
                  </Space>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item name="administrator" valuePropName="checked">
                  <Space>
                    <Switch
                      defaultChecked={checkAdmin(role)}
                      checked={checkAdmin(role)}
                      onClick={val => {
                        setRole(val ? 'ga' : 'pt');
                      }}
                    />

                    <Typography.Text>Administrator</Typography.Text>
                  </Space>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item name="active" valuePropName="checked">
                  <Space size={14}>
                    <Space>
                      <Switch
                        defaultChecked={isActive(active)}
                        checked={isActive(active)}
                        onClick={() => setActive(!active)}
                        disabled={cancelDate}
                      />

                      <Typography.Text>Active</Typography.Text>
                    </Space>
                  </Space>
                </Form.Item>
              </Col>

              {/* {renderSchedDeactivation()} */}

              {/* {!active && (
                <Col>
                  <Form.Item
                    style={{ marginTop: 5 }}
                    label="Select Deactivation Date"
                    help={
                      inactiveDate && (
                        <Typography.Text>
                          Note: This provider is scheduled to deactivate on{' '}
                          <Typography.Text strong>
                            {inactiveDate.format('MMMM DD, YYYY')}
                          </Typography.Text>
                          .
                        </Typography.Text>
                      )
                    }
                  >
                    <DatePicker
                      allowClear
                      disabledDate={disablePastDate}
                      onChange={date => {
                        setCancelDate(date);
                      }}
                      value={inactiveDate}
                      style={{ width: 250 }}
                    />
                  </Form.Item>
                </Col>
              )} */}
            </Row>

            <Divider
              plain
              orientation="left"
              orientationMargin={0}
              className="mt-1"
            >
              Preferences
            </Divider>

            <Row
              gutter={[24, 24]}
              style={{
                marginBottom: 60,
              }}
            >
              <Col lg={12} md={24} sm={24} xs={24}>
                <Space size="middle" direction="vertical">
                  <Space>
                    <Switch
                      checked={messaging === 'enabled'}
                      onChange={checked => {
                        setMessaging(checked ? 'enabled' : 'disabled');
                      }}
                    />

                    <Typography.Text>Enable Messaging</Typography.Text>
                  </Space>

                  <Space>
                    <Switch
                      checked={rtmStatus === 'enabled'}
                      onChange={checked => {
                        setRtmStatus(checked ? 'enabled' : 'disabled');
                      }}
                    />

                    <Typography.Text>Default RTM Status</Typography.Text>
                  </Space>

                  <Space>
                    <Switch
                      checked={notifyBillableCodes === 'enabled'}
                      onChange={checked => {
                        setNotifyBillableCodes(
                          checked ? 'enabled' : 'disabled'
                        );
                      }}
                    />

                    <Typography.Text>
                      Enable Billable Codes Notification
                    </Typography.Text>
                  </Space>

                  <Space>
                    <Switch
                      checked={promptLocationPopup === 'enabled'}
                      onChange={checked => {
                        setPromptLocationPopup(
                          checked ? 'enabled' : 'disabled'
                        );
                      }}
                    />

                    <Typography.Text>
                      Display Location & RTM Pop-Up
                    </Typography.Text>
                  </Space>
                </Space>
              </Col>

              <Col lg={12} md={24} sm={24} xs={24}>
                <Space
                  direction="vertical"
                  size="middle"
                  style={{
                    width: '100%',
                  }}
                >
                  <Space
                    size={0}
                    direction="vertical"
                    style={{
                      width: '100%',
                    }}
                  >
                    <Typography.Text>Default Location</Typography.Text>
                    <Select
                      showSearch
                      style={{
                        width: '100%',
                      }}
                      value={location || ''}
                      onChange={value => {
                        setLocation(value);
                      }}
                    >
                      {[...groups.clinics]
                        .sort((a, b) => a.ClinicName - b.ClinicName)
                        .map((item, i) => (
                          <Fragment key={i}>
                            <Select.Option value={item.Id}>
                              {item.ClinicName}
                            </Select.Option>
                          </Fragment>
                        ))}
                    </Select>
                  </Space>

                  <Space size={2} direction="vertical">
                    <Typography.Text>
                      Daily Outcome Score Threshold
                    </Typography.Text>

                    <Space size="large">
                      <Space>
                        <input
                          type="number"
                          min="1"
                          max="5"
                          style={{
                            width: 42,
                            borderRadius: 4,
                            border: '1px solid #d9d9d9',
                          }}
                          value={painScaleThreshold || ''}
                          onChange={e => {
                            let value = e.target.value;
                            if (value > 5) {
                              value = 5;
                            } else if (value <= 0) {
                              value = '';
                            }

                            setPainScaleThreshold(value || null);
                          }}
                        />

                        <Typography.Text>Threshold</Typography.Text>
                      </Space>

                      <Space>
                        <Switch
                          checked={painScaleNotification === 'enabled'}
                          onChange={checked => {
                            setPainScaleNotification(
                              checked ? 'enabled' : 'disabled'
                            );
                          }}
                        />

                        <Typography.Text>Notification</Typography.Text>
                      </Space>
                    </Space>
                  </Space>
                </Space>
              </Col>
            </Row>

            <Row justify="end">
              <Space>
                <Form.Item name="submit">
                  {!active ? (
                    <Popconfirm
                      title={
                        <Typography.Text>
                          <strong>Deactivate Account?</strong>
                          <br />
                          Deactivating this provider will archive all
                          conversations this provider has had with any patients.
                          You will be able to re-assign any of this providers’
                          patients to a new provider.
                          <br />
                          This cannot be undone. Do you want to continue?
                        </Typography.Text>
                      }
                      placement="topRight"
                      okText="Deactivate Account"
                      cancelText="Cancel"
                      onConfirm={() => {
                        submit();
                      }}
                      okButtonProps={{
                        danger: true,
                      }}
                    >
                      <Button
                        size="large"
                        className="ptw-btn"
                        type="primary"
                        disabled={
                          incomplete ||
                          validating ||
                          submitting ||
                          emailError ||
                          !uniqueEmail ||
                          (notEdited &&
                            notEditedPreferences &&
                            notEditedCancelDate)
                        }
                      >
                        {!submitting ? 'Save Changes' : <Spin />}
                      </Button>
                    </Popconfirm>
                  ) : (
                    <Button
                      size="large"
                      className="ptw-btn"
                      type="primary"
                      disabled={
                        incomplete ||
                        validating ||
                        submitting ||
                        emailError ||
                        !uniqueEmail ||
                        (notEdited &&
                          notEditedPreferences &&
                          notEditedCancelDate)
                      }
                      onClick={() => {
                        submit();
                      }}
                    >
                      {!submitting ? 'Save Changes' : <Spin />}
                    </Button>
                  )}
                </Form.Item>

                <Form.Item name="cancel">
                  <Link to="/users">
                    <Button className="ptw-btn" size="large" type="default">
                      Cancel
                    </Button>
                  </Link>
                </Form.Item>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={4} sm={0} xs={0} />
      </Row>

      <div
        ref={formRef}
        style={{
          display: 'none',
        }}
      />
    </Fragment>
  );
}
function mapStateToProps(state) {
  return {
    currentUser: state.users.currentUser,
    visibleProfile: state.visibleProfile,
  };
}

export default connect(mapStateToProps)(UserForm);
