import { createAction } from 'redux-actions';
import { API_REQUEST } from '../../../membership/middleware';
import { submit, reset } from 'redux-form';

//include grid state
export * from './gridState';

export const GET_GROUPADMINS = 'GET_GROUPADMINS';
export const GET_GROUPADMIN = 'GET_GROUPADMIN';
export const SEARCH_GROUPADMIN = 'SEARCH_GROUPADMIN';
export const ADD_GROUPADMIN = 'ADD_GROUPADMIN';
export const TOGGLE_ADD_GROUP_ADMIN = 'TOGGLE_ADD_GROUP_ADMIN';
export const EDIT_GROUPADMIN = 'EDIT_GROUPADMIN';
export const TOGGLE_EDIT_GROUP_ADMIN = 'TOGGLE_EDIT_GROUP_ADMIN';
export const SELECT_GROUP_ADMIN = 'SELECT_GROUP_ADMIN';
export const CHECK_GROUP_ADMIN_NAME = 'CHECK_GROUP_ADMIN_NAME';
export const MANUAL_PASSWORD_RESET = 'MANUAL_PASSWORD_RESET';

export const IS_CURRENTLY_VIEWING_GROUP_ADMINS =
  'IS_CURRENTLY_VIEWING_GROUP_ADMINS';
export const viewingGroupAdmins = createAction(
  IS_CURRENTLY_VIEWING_GROUP_ADMINS
);

export const IS_CURRENTLY_ADDING_GROUP_ADMIN =
  'IS_CURRENTLY_ADDING_GROUP_ADMIN';
export const addingGroupAdmin = createAction(IS_CURRENTLY_ADDING_GROUP_ADMIN);

export const IS_CURRENTLY_EDITING_GROUP_ADMIN =
  'IS_CURRENTLY_EDITING_GROUP_ADMIN';
export const editingGroupAdmin = createAction(IS_CURRENTLY_EDITING_GROUP_ADMIN);

export const checkGroupAdminName = (groupName, emailAddress) => ({
  [API_REQUEST]: createAction(CHECK_GROUP_ADMIN_NAME)({
    resource: `/users/exists?email=${encodeURIComponent(
      emailAddress.trim().toLowerCase()
    )}`,
    method: 'GET'
  })
});

export const getGroupAdmins = groupName => ({
  [API_REQUEST]: createAction(GET_GROUPADMINS)({
    resource: `groups/${groupName}/users?role=ga`, //todo: the querystring 'role' will be needed once we have a user other than group admin to lookup
    method: 'GET'
  })
});
export const getGroupAdmin = (groupName, emailAddress) => ({
  [API_REQUEST]: createAction(GET_GROUPADMINS)({
    resource: `groups/${groupName}/users?search=${encodeURIComponent(
      emailAddress
    )}&role=ga`, //todo: the querystring 'role' will be needed once we have a user other than group admin to lookup
    method: 'GET'
  })
});
export const searchGroupAdmin = (groupName, searchTerm) => ({
  [API_REQUEST]: createAction(SEARCH_GROUPADMIN)({
    resource: `groups/${groupName}/users?search=${searchTerm}&role=ga`,
    method: 'GET'
  })
});
//add
export const toggleAddGroupAdmin = () => ({
  ...createAction(TOGGLE_ADD_GROUP_ADMIN)()
});
export const submitAddGroupAdmin = () => ({
  ...submit('AddGroupAdmin')
});
export const resetAddGroupAdmin = () => ({
  ...reset('AddGroupAdmin')
});
export const addGroupAdmin = (groupName, admin) => ({
  [API_REQUEST]: createAction(ADD_GROUPADMIN)({
    resource: `groups/${groupName}/users`,
    method: 'POST',
    body: { ...admin, role: 'ga' }
  })
});
//edit
export const toggleEditGroupAdmin = () => ({
  ...createAction(TOGGLE_EDIT_GROUP_ADMIN)()
});
export const submitEditGroupAdmin = () => ({
  ...submit('EditGroupAdmin')
});
export const resetEditGroupAdmin = () => ({
  ...reset('EditGroupAdmin')
});
export const updateGroupAdmin = (groupName, admin) => ({
  [API_REQUEST]: createAction(EDIT_GROUPADMIN)({
    resource: `groups/${groupName}/users`,
    method: 'PUT',
    body: { ...admin, role: 'ga' }
  })
});
export const selectGroupAdmin = admin => ({
  ...createAction(SELECT_GROUP_ADMIN)({
    firstName: admin.FirstName,
    lastName: admin.LastName,
    phoneNumber: admin.PhoneNumber,
    emailAddress: admin.EmailAddress,
    active: admin.Active
  })
});
export const manualPasswordReset = emailAddress => ({
  [API_REQUEST]: createAction(MANUAL_PASSWORD_RESET)({
    resource: `users/password-reset/${emailAddress}`,
    method: 'POST',
    body: {}
  })
});
