import { createAction } from 'redux-actions'

export const IS_LOADING = 'GROUPS_IS_LOADING';
export const isLoading = createAction(IS_LOADING);

export const DONE_LOADING = 'GROUPS_DONE_LOADING';
export const doneLoading = createAction(DONE_LOADING);

export const RESET_GROUPS = 'GROUPS_RESET';
export const resetGroups = createAction(RESET_GROUPS);

export const IS_SEARCHING = 'IS_SEARCHING_GROUPS';
export const isSearching = createAction(IS_SEARCHING);

export const DONE_SEARCHING = 'DONE_SEARCHING_GROUPS';
export const doneSearching = createAction(DONE_SEARCHING);