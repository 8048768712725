import apiFetch from '../utils/fetch.utils';
import states from '../states';
import { getRecoil } from 'recoil-nexus';

export const sendSurvey = async payload => {
  try {
    const uri = 'nps-survey';
    const method = 'PUT';

    return apiFetch(uri, method, { body: payload });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getDistroList = async () => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id } = groupsState.user;

    const uri = `nps-alerts?groupId=${id}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateDistroList = async payload => {
  try {
    const uri = 'nps-alerts';
    const method = 'POST';

    return apiFetch(uri, method, { body: payload });
  } catch (err) {
    console.log(err);
    throw err;
  }
};
