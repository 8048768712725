import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Input } from 'antd';
import './input.css';
const {TextArea} = Input;

const searchBarStyling = {padding:'15px'};

class MessageInput extends Component {

    handleMessage = (e)=>{
        const {setText} = this.props;
        setText(e.target.value);
    };

    handleSubmit = (e)=>{
        const key = e.key;
        if(key === 'Enter'){
            e.preventDefault();
            const { props:{sendMessage, setText, message}} = this;

            if(message){
                sendMessage(message);
                setText(null)
            }
        }
    };

    render() {

        const {handleMessage, handleSubmit, props:{multiLine, onFocus, onBlur, message}} = this;

        let size;
        if(multiLine){size = {minRows:4, maxRows:4}}


        return (
            <div style={searchBarStyling}>

                    <TextArea placeholder="New Message..." value={message} onChange={e => handleMessage(e)}
                              onKeyPress={handleSubmit} onFocus={onFocus} onBlur={onBlur}
                              autosize={size}/>

            </div>)
    }
}

const mapStateToProps = state => ({
    visibleProfile: state.visibleProfile,
});


export default connect(mapStateToProps)(withRouter(MessageInput))
