import { atom } from 'recoil';

const defaultState = {
  announcement: null,
};

const ADMIN_STATE = atom({
  key: 'ADMIN_STATE',
  default: defaultState,
});

export default ADMIN_STATE;
