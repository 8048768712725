import { UPDATE_FORM_PARAMS_ERROR } from '../actions/groupExercises';

const reducer = (state, action) => {
    switch(action.type){
        case UPDATE_FORM_PARAMS_ERROR:
            return {
                ...state,
                validParams: action.payload.validParams
            };
        default: 
            return state;
    }
}

export default reducer;