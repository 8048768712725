export const DIETS = 'DIETS';
export const DISEASE_MANAGEMENT = 'DISEASE_MANAGEMENT';
export const EXERCISE_TIPS = 'EXERCISE_TIPS';
export const MENTAL_HEALTH = 'MENTAL_HEALTH';
export const PHYSICAL_THERAPY = 'PHYSICAL_THERAPY';
export const POST_OPERATIVE_EDUCATION = 'POST_OPERATIVE_EDUCATION';
export const PAIN_EDUCATION = 'PAIN_EDUCATION';
export const SLEEPING_HABITS = 'SLEEPING_HABITS';
export const ACTIVITIES_OF_DAILY_LIVING_MODIFICATIONS =
  'ACTIVITIES_OF_DAILY_LIVING_MODIFICATIONS';
export const SELF_MOBILIZATION_TECHNIQUES = 'SELF_MOBILIZATION_TECHNIQUES';
export const EATING_HABITS = 'EATING_HABITS';
export const OTHER = 'OTHER';

export const categories = [
  DIETS,
  DISEASE_MANAGEMENT,
  EXERCISE_TIPS,
  MENTAL_HEALTH,
  PHYSICAL_THERAPY,
  POST_OPERATIVE_EDUCATION,
  PAIN_EDUCATION,
  SLEEPING_HABITS,
  ACTIVITIES_OF_DAILY_LIVING_MODIFICATIONS,
  SELF_MOBILIZATION_TECHNIQUES,
  EATING_HABITS,
  OTHER,
].sort();

export function formatCategory(category) {
  switch (category) {
    case DIETS:
      return 'Diets';
    case DISEASE_MANAGEMENT:
      return 'Disease Management';
    case EXERCISE_TIPS:
      return 'Exercise Tips';
    case MENTAL_HEALTH:
      return 'Mental Health';
    case PHYSICAL_THERAPY:
      return 'Physical Therapy';
    case POST_OPERATIVE_EDUCATION:
      return 'Post-Operative Education';
    case PAIN_EDUCATION:
      return 'Pain Education';
    case SLEEPING_HABITS:
      return 'Sleeping Habits';
    case ACTIVITIES_OF_DAILY_LIVING_MODIFICATIONS:
      return 'Activities of Daily Living Modifications';
    case SELF_MOBILIZATION_TECHNIQUES:
      return 'Self-Mobilization Techniques';
    case EATING_HABITS:
      return 'Eating Habits';
    case OTHER:
      return 'Other';
    default:
      return category;
  }
}

export function filterData(data, filter) {
  if (data.length === 0) {
    return [];
  }

  if (filter.length === 0) {
    return data;
  }

  return [...data].filter(item => filter.includes(item.category));
}

export function searchData(data, search) {
  if (data.length === 0) {
    return [];
  }

  if (search.length === 0) {
    return data;
  }

  return [...data].map(item => ({
    category: item.category,
    data: item.data.filter(item =>
      item.title.toLowerCase().includes(search.toLowerCase())
    ),
  }));
}

export const sortData = data => {
  if (data.length === 0) {
    return [];
  }

  return data.map(item => ({
    category: item.category,
    data: [...item.data].sort((a, b) =>
      a.title.toLowerCase().localeCompare(b.title.toLowerCase())
    ),
  }));
};

export function paginateData(data, page) {
  const start = (page - 1) * 4;
  const end = page * 4;

  return data.slice(start, end);
}

export function getNumOfPages(data) {
  return Math.ceil(data.length / 4) || 1;
}
