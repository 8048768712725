import React from 'react';
import { Well, Popover, OverlayTrigger } from 'react-bootstrap';
import { Row, Col, Typography } from 'antd';
import { AddOutline } from 'react-ionicons';
import ExerciseCard from '../../components/ExerciseItem';

const popoverHoverFocus = fromTemplate => {
  const target = fromTemplate ? 'template.' : "patient's prescription.";

  return (
    <Popover id="popover-trigger-hover-focus" title="Write-In">
      A Write-In Exercise will only apply to this {target} The Exercise will{' '}
      <strong>not</strong> be added to the Exercise Library.
    </Popover>
  );
};

const PatientExercisesGrid = ({
  exercises,
  onExerciseTitleClick,
  onExerciseCardClick,
  onAddCustomExerciseClick,
  displayAddCustomButton,
  isTabbed,
  fromPatientPrescription = false,
  fromTemplate,
}) => {
  return (
    <div>
      <Row gutter={[16, 16]}>
        {Array.isArray(exercises) &&
          exercises.map((e, i) => {
            return (
              <Col key={i} lg={6} md={12} sm={12} xs={24}>
                <ExerciseCard
                  exercise={e}
                  fromPatientPrescription={fromPatientPrescription}
                  handleExerciseDetailsModal={onExerciseTitleClick}
                  handleSelectExercise={onExerciseCardClick}
                />
              </Col>
            );
          })}
        {displayAddCustomButton && !isTabbed && (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={popoverHoverFocus(fromTemplate)}
          >
            <Col lg={6} md={12} sm={12} xs={24}>
              <div
                className="ptw-card-image-outer group add-custom-exercise clickable"
                onClick={onAddCustomExerciseClick}
              >
                <AddOutline height="48px" width="48px" />
                <Typography.Text>Write-In Exercise</Typography.Text>
              </div>
            </Col>
          </OverlayTrigger>
        )}
        {displayAddCustomButton && exercises && exercises.length === 0 && (
          <Col lg={6} md={12} sm={12} xs={24}>
            <div>
              <Well bsSize="large">
                <p>No exercises or tags found.</p>
                <p>
                  <a
                    href="https://goo.gl/forms/WIes4AstvA5PeauE2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here to request exercise!
                  </a>
                </p>
              </Well>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default PatientExercisesGrid;
