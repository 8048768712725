import React, { useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { parseFavorites } from '../../utils/exercises.utils';

import ExerciseCardItem from './ExerciseCardItem';
import ExerciseListItem from './ExerciseListItem';

import services from '../../services';
import setNotification from '../../utils/setNotification.utils';
import states from '../../states';

const ExerciseCard = ({
  currentView = 'card',
  exercise,
  fromPatientPrescription,
  handleExerciseDetailsModal,
  handleSelectExercise
}) => {
  const [tagLoading, setTagLoading] = useState(false);
  const [faveLoading, setFaveLoading] = useState(false);

  const [favorites, setFavorites] = useRecoilState(states.favorites);

  const user = useRecoilValue(states.user);
  const group = useRecoilValue(states.groups);

  const handleAddFavorite = async (id, groupId) => {
    try {
      setFaveLoading(true);

      let response = await services.exercises.addFavorite(
        user.details.favoriteId,
        [{ Id: id, GroupId: groupId }]
      );

      if (response.status === 200) {
        setFavorites({
          ...favorites,
          list: parseFavorites(response.data.exercises)
        });
        setNotification(
          'success',
          'Success!',
          'Exercise has been added to favorites.'
        );
      }
    } catch (err) {
      console.log(err);
      setNotification(
        'error',
        'Error!',
        'An error occurred while fetching favorites list.'
      );
    } finally {
      setFaveLoading(false);
    }
  };

  const handleRemoveFavorite = async id => {
    try {
      setFaveLoading(true);

      let response = await services.exercises.deleteFavorite(
        user.details.favoriteId,
        id
      );

      if (response.status === 200) {
        setFavorites({
          ...favorites,
          list: parseFavorites(response.data.exercises)
        });
        setNotification(
          'success',
          'Success!',
          'Exercise has been removed from favorites.'
        );
      }
    } catch (err) {
      console.log(err);
      setNotification(
        'error',
        'Error!',
        'An error occurred while fetching favorites list.'
      );
    } finally {
      setFaveLoading(false);
    }
  };

  const handleAddTag = async (id, name, customName, tag) => {
    try {
      setTagLoading(true);

      const response = await services.exercises.addTag(
        group.user.id,
        user.details.sub,
        id,
        name,
        customName,
        tag
      );

      if (response.status === 200) {
        setNotification(
          'success',
          'Success!',
          'Tag has been requested for this exercise.'
        );
      }
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while requesting tag for the exercise.'
      );
    } finally {
      setTagLoading(false);
    }
  };

  // card view
  if (currentView === 'card') {
    return (
      <ExerciseCardItem
        exercise={exercise}
        handleExerciseDetailsModal={handleExerciseDetailsModal}
        handleSelectExercise={handleSelectExercise}
        faveLoading={faveLoading}
        tagLoading={tagLoading}
        fromPatientPrescription={fromPatientPrescription}
        handleAddFavorite={handleAddFavorite}
        handleRemoveFavorite={handleRemoveFavorite}
        handleAddTag={handleAddTag}
      />
    );
  }

  // list view
  return (
    <ExerciseListItem
      exercise={exercise}
      handleExerciseDetailsModal={handleExerciseDetailsModal}
      handleSelectExercise={handleSelectExercise}
      faveLoading={faveLoading}
      tagLoading={tagLoading}
      fromPatientPrescription={fromPatientPrescription}
      handleAddFavorite={handleAddFavorite}
      handleRemoveFavorite={handleRemoveFavorite}
      handleAddTag={handleAddTag}
    />
  );
};

export default ExerciseCard;
