import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import { GET_PROFILE } from '../../membership/actions';
import { SIDEBAR_OPEN, SIDEBAR_CLOSE } from '../actions/sidebarActions';
import {
  SET_MESSAGE_BADGE,
  GET_CREATOR_PREFERENCES,
  HIDE_NAV_BAR,
  SET_CURRENT_RX,
  SET_LOCALE,
  SET_RX_LIST,
  UPDATE_RX_LIST,
} from '../actions/patient';
import { ENGLISH } from '../../../I18n/constants';

const defaultState = {
  fetchingProfile: false,
  currentRx: null,
  rxList: [],
  locale: ENGLISH,
  sidebarState: false,
  hideNavBar: false,
};

const mobileReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case HIDE_NAV_BAR:
      return { ...state, hideNavBar: payload };

    case `${SET_MESSAGE_BADGE}`:
      return { ...state, badgeCount: payload.count };

    case `${GET_PROFILE}_${PENDING}`:
      return {
        ...state,
        fetchingProfile: true,
      };

    case `${GET_PROFILE}_${FULFILLED}`:
    case `${GET_PROFILE}_${REJECTED}`:
      return {
        ...state,
        fetchingProfile: false,
      };

    case `${GET_CREATOR_PREFERENCES}_${REJECTED}`:
      return {
        ...state,
        fetchingProfile: false,
      };

    case `${GET_CREATOR_PREFERENCES}_${FULFILLED}`:
      let status;
      let PT;
      const data = payload.data;
      try {
        status = data.Item.Preferences
          ? data.Item.Preferences.Messaging
          : 'enabled';
        PT = data.Item.FirstName
          ? data.Item.FirstName + ' ' + data.Item.LastName
          : null;
      } catch (err) {
        status = 'enabled';
      }
      return {
        ...state,
        fetchingProfile: false,
        Messaging: status,
        PT: PT,
      };

    case SET_CURRENT_RX:
      return {
        ...state,
        currentRx: payload,
      };

    case SET_RX_LIST:
      return {
        ...state,
        rxList: payload,
      };

    case UPDATE_RX_LIST:
      return {
        ...state,
        rxList: state.rxList.map(rx =>
          rx.Id === payload.rxId
            ? {
                ...rx,
                Prescription: {
                  ...rx.Prescription,
                  ...payload.rxDetails,
                },
              }
            : { ...rx }
        ),
      };

    case SET_LOCALE:
      return {
        ...state,
        locale: payload || ENGLISH,
      };

    case SIDEBAR_OPEN:
      return {
        ...state,
        sidebarState: true,
      };

    case SIDEBAR_CLOSE:
      return {
        ...state,
        sidebarState: false,
      };

    default:
      return state;
  }
};

export default mobileReducer;
