import React, { Fragment, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Card,
  Space,
  Button,
  Form,
  DatePicker,
  Select,
  Row,
  Col,
  Typography,
  Skeleton,
  Table,
  Tag,
} from 'antd';

import moment from 'moment';
import services from '../../../services';
import states from '../../../states';
import * as reportsUtils from '../../../utils/reports.utils';

const status = [
  {
    label: 'Ready for Billing',
    value: 'GREEN',
  },
  {
    label: 'High Engagement',
    value: 'BLUE',
  },
  {
    label: 'Low Engagement',
    value: 'YELLOW',
  },
  {
    label: 'No Engagement',
    value: 'RED',
  },
];

const RtmPatients = ({
  title,
  showFilter,
  onSetLoading,
  onSetError,
  onSetDateRange,
  onSetReport,
}) => {
  const [filter, setFilter] = useState({
    dateRange: null,
    queryRange: null,
    rtmStatus: [],
    providers: [],
  });
  const [disableSelect, setDisableSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [chartTitle, setChartTitle] = useState('');
  const [report, setReport] = useState(null);

  const groups = useRecoilValue(states.groups);
  const [form] = Form.useForm();

  useEffect(() => {
    initChart();
  }, [filter]);

  const initChart = async () => {
    try {
      if (error) {
        onSetError(false);
        setError(false);
      }

      onSetLoading(true);
      setLoading(true);

      const reportResponse = await fetchReports();
      onSetReport(reportResponse);
      setReport(reportResponse);

      let startDate;
      let endDate;

      if (filter.dateRange) {
        startDate = moment(filter.dateRange[0]).format('MMM DD, YYYY');
        endDate = moment(filter.dateRange[1]).format('MMM DD, YYYY');
      }

      if (filter.queryRange && !filter.dateRange) {
        startDate = moment(filter.queryRange[0]).format('MMM DD, YYYY');
        endDate = moment(filter.queryRange[1]).format('MMM DD, YYYY');
      }

      setChartTitle(
        `${title} Report ${
          startDate && endDate ? `for ${startDate} - ${endDate}` : ''
        }`
      );
    } catch (error) {
      console.log('initChart', error);

      onSetError(true);
      setError(true);
    } finally {
      onSetLoading(false);
      setLoading(false);
    }
  };

  const fetchReports = async () => {
    try {
      let query = reportsUtils.generateDateQuery();

      if (filter.dateRange) {
        query = reportsUtils.generateDateQuery(
          moment(filter.dateRange[0]).format() || null,
          moment(filter.dateRange[1]).format() || null
        );
      }

      let queryTypes = [];
      let queryRange = null;

      if (filter.queryRange) {
        if (!filter.dateRange) {
          queryTypes = ['enrollment'];
          query = reportsUtils.generateDateQuery(
            moment(filter.queryRange[0]).format() || null,
            moment(filter.queryRange[1]).format() || null
          );
        } else {
          queryTypes = ['enrollment', 'billing'];
          queryRange = reportsUtils.generateDateQuery(
            moment(filter.queryRange[0]).format() || null,
            moment(filter.queryRange[1]).format() || null
          );
        }
      }

      const providers = filter.providers.length > 0 ? filter.providers : [];
      const response = await services.reports.getRtmPatients(
        query,
        providers,
        filter.rtmStatus.length > 0 ? filter.rtmStatus : null,
        queryTypes.length ? queryTypes : null,
        queryRange
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log('fetchReports', error);
      throw error;
    }
  };

  const handleFilter = values => {
    let range = null;

    if (values.dateRange) {
      range = [values.dateRange[0].format(), values.dateRange[1].format()];
    }

    if (values.queryRange && !values.dateRange) {
      range = [values.queryRange[0].format(), values.queryRange[1].format()];
    }

    onSetDateRange(range);
    setFilter({
      dateRange: values.dateRange
        ? [values.dateRange[0].format(), values.dateRange[1].format()]
        : null,
      queryRange: values.queryRange
        ? [values.queryRange[0].format(), values.queryRange[1].format()]
        : null,
      rtmStatus: Array.isArray(values.rtmStatus) ? [...values.rtmStatus] : [],
      providers: Array.isArray(values.providers) ? [...values.providers] : [],
    });
  };

  const handleClear = () => {
    form.resetFields();

    onSetDateRange(null);
    setFilter({
      dateRange: null,
      queryRange: null,
      rtmStatus: [],
      providers: [],
    });
  };

  const generateDataSource = reportData => {
    return Array.isArray(reportData) && reportData.length > 0
      ? reportData.map(item => {
          const providers = reportsUtils.formatRtmProviders(item.Providers);

          return {
            ...item,
            EnrollStart: moment(item.EnrollStart).format('MMMM DD, YYYY'),
            BillingDates: `${moment(item.BillingStart).format(
              'MMM DD, YYYY'
            )} - ${moment(item.BillingEnd).format('MMM DD, YYYY')}`,
            Providers: providers
              .map(provider => `${provider.FirstName} ${provider.LastName}`)
              .join(', '),
          };
        })
      : [];
  };

  const renderTable = () => {
    if (!report) return null;

    const dataSource = generateDataSource(report);

    return (
      <Fragment>
        <Typography.Title level={5}>{chartTitle}</Typography.Title>
        <Table
          size="small"
          scroll={{
            x: 'max-content',
          }}
          columns={[
            {
              title: 'RTM Status',
              dataIndex: 'Status',
              key: 'Status',
              render: value => (
                <Tag
                  className="rtm-status-tag"
                  color={typeof value === 'string' && value.toLowerCase()}
                >
                  {reportsUtils.getEngagementStatus(value)}
                </Tag>
              ),
            },
            {
              title: 'Patient',
              dataIndex: 'PatientName',
              key: 'PatientName',
              render: value => (
                <Typography.Text style={{ textTransform: 'capitalize' }}>
                  {value}
                </Typography.Text>
              ),
            },
            {
              title: 'Email Address',
              dataIndex: 'EmailAddress',
              key: 'EmailAddress',
            },
            {
              title: 'Phone Number',
              dataIndex: 'PhoneNumber',
              key: 'PhoneNumber',
            },
            {
              title: 'Enrollment Date',
              dataIndex: 'EnrollStart',
              key: 'EnrollStart',
            },
            {
              title: 'Billing Period',
              dataIndex: 'BillingDates',
              key: 'BillingDates',
            },
            {
              title: 'Assigned Providers',
              dataIndex: 'Providers',
              key: 'Providers',
            },
          ]}
          dataSource={dataSource}
          pagination={false}
        />
      </Fragment>
    );
  };

  const validateSelectProviders = () => {
    setDisableSelect(form.getFieldValue('providers').length === 10);
  };

  return (
    <Fragment>
      {showFilter && (
        <Card style={{ background: '#f7f7f7', marginBottom: 20 }}>
          <Form
            className="filter-chart-form"
            form={form}
            onFinish={handleFilter}
          >
            <Row gutter={[16, 16]} style={{ flex: 1 }}>
              <Col xl={5} lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="RTM Status" name="rtmStatus">
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    placeholder="Select RTM status"
                    maxTagCount={5}
                    filterOption={(input, option) => {
                      if (option && option.label) {
                        const label = String(option.label).toLowerCase();
                        if (label.includes(String(input).toLowerCase())) {
                          return option;
                        }
                      }
                    }}
                    options={status}
                  />
                </Form.Item>
              </Col>
              <Col xl={5} lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Enrollment Date" name="queryRange">
                  <DatePicker.RangePicker
                    disabledDate={current =>
                      (current && current < moment().subtract(1, 'year')) ||
                      current > moment().endOf('day')
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xl={5} lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Billing Period" name="dateRange">
                  <DatePicker.RangePicker
                    disabledDate={current =>
                      (current && current < moment().subtract(1, 'year')) ||
                      current > moment().endOf('day')
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xl={5} lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Assigned Provider/s"
                  name="providers"
                  rules={[
                    {
                      type: 'array',
                      max: 10,
                      message:
                        'You can only select up to 10 assigned providers',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    placeholder="Select provider/s"
                    maxTagCount={5}
                    maxTagTextLength={15}
                    onChange={validateSelectProviders}
                    filterOption={(input, option) => {
                      const searchInput = input.toLowerCase();
                      const isString = typeof option.children === 'string';
                      const isObject = typeof option.children === 'object';

                      if (isString) {
                        return option.children
                          .toLowerCase()
                          .includes(searchInput);
                      }

                      if (isObject) {
                        return option.children.props.children
                          .toLowerCase()
                          .includes(searchInput);
                      }
                    }}
                  >
                    {reportsUtils
                      .sortProvidersByStatus(groups.providers)
                      .map(item => {
                        const selected = form.getFieldValue('providers');
                        const included = Array.isArray(selected)
                          ? !selected.includes(item.Sub)
                          : false;
                        const disabled = disableSelect && included;

                        return (
                          <Option value={item.Sub} disabled={disabled}>
                            {!item.Active ? (
                              <em
                                style={{
                                  color: disabled ? '#bfbfbf' : 'grey',
                                }}
                              >{`${item.FirstName} ${item.LastName}`}</em>
                            ) : (
                              `${item.FirstName} ${item.LastName}`
                            )}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={4} lg={8} md={12} sm={24} xs={24}>
                <Form.Item>
                  <Space>
                    <Button htmlType="submit" type="primary">
                      Apply Filter
                    </Button>
                    <Button
                      htmlType="button"
                      type="default"
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
      {loading ? <Skeleton active /> : renderTable()}
    </Fragment>
  );
};

export default RtmPatients;
