import React, { Fragment, useState } from 'react';
import {
  Button,
  Select,
  DatePicker,
  Input,
  Popconfirm,
  Typography,
  Row,
  Col,
  notification
} from 'antd';
import moment from 'moment';
import ActivityCard from './ActivityCard';
import ActivityLog from './ActivityLog';
import NoResults from '../../../../../components/NoResults';
import { millisToMinutesAndSeconds, formatDateTime } from './mixins';

const { Option } = Select;

const activity_list = [
  { event: 'View Patient RTM Details' },
  { event: 'View Adherence Log' },
  { event: 'Edit Exercise Program' },
  { event: 'Messaging Patient' },
  { event: 'Live Call' },
  { event: 'Communication With Qualified Health Professional (QHP)' }
];

const toDate = (date, time) =>
  new Date(`${formatDateTime(date).date} ${formatDateTime(time).time}`);

export default function ClinicalMonitoringLog({
  clinicalMonitoringLog,
  saveManualTimer,
  filteredDate,
  changeFilteredDate,
  handleDeleteCLinicMonitorLogs,
  selectedPatient,
  getClinicLogs,
  visibleProfile
}) {
  const { totalClinicalMonitoringTime, clinicalMonitoringLogs, totalCallLogs } =
    clinicalMonitoringLog;
  const [showForm, setShowForm] = useState(false);
  const [saving, setSaving] = useState(false);
  const [timerActivity, setTimerActivity] = useState({
    activity: '',
    date: moment().format('LL'),
    start: '',
    end: '',
    notes: ''
  });

  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const daysRemaining = Math.ceil(
    (lastDayOfMonth.setHours(23, 59, 59) - today.getTime()) /
      (1000 * 60 * 60 * 24)
  );

  const validateForm = () => {
    if (timerActivity.activity === '') return true;
    if (timerActivity.start === '') return true;
    if (timerActivity.end === '') return true;
    return false;
  };

  const validateOverlap = () => {
    const { date, start, end } = timerActivity;
    const inputStart = new Date(`${date} ${start}`).getTime();
    const inputEnd = new Date(`${date} ${end}`).getTime();

    let hasOverlap = false;
    for (let i = 0; i < clinicalMonitoringLogs.length; i++) {
      const item = clinicalMonitoringLogs[i];
      const { Date, Start, End } = item;
      const logStart = toDate(Date, Start).getTime();
      const logEnd = toDate(Date, End).getTime();

      if (
        (logStart >= inputStart && logStart < inputEnd) ||
        (inputStart >= logStart && inputStart < logEnd) ||
        (logStart === inputStart && logEnd === inputEnd)
      ) {
        hasOverlap = true;
      }
    }

    return hasOverlap;
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  const handleSave = async () => {
    const { date, start, end } = timerActivity;

    const timerStart = date ? `${date} ${start}` : start;
    const timerEnd = date ? `${date} ${end}` : end;

    if (moment(timerStart).valueOf() >= moment(timerEnd).valueOf()) {
      let desc = 'earlier than';
      if (moment(timerStart).valueOf() === moment(timerEnd).valueOf()) {
        desc = 'equal to';
      }

      notification.warning({
        message: 'Invalid Time Range',
        description: `You have entered an end time that is ${desc} the start time. Please enter an end time later than the start time and try again.`,
        duration: 4
      });

      return;
    }

    setSaving(true);
    await saveManualTimer(timerActivity)
      .then(() => {
        setTimerActivity({
          activity: '',
          date: moment().format('LL'),
          start: '',
          end: '',
          notes: ''
        });
      })
      .finally(() => setSaving(false));
  };

  const isPastDate = filteredDate.isBefore(moment(), 'd');
  const discharged = !!selectedPatient?.Discharge;

  return (
    <Fragment>
      <div className="patient-detail-tab-stats">
        <ActivityCard
          title="Live Interactions"
          count={`${totalCallLogs}`}
          source="clinical"
        />
        <ActivityCard
          title="Total Clinical Monitoring Time"
          count={millisToMinutesAndSeconds(totalClinicalMonitoringTime)}
          unit="Minutes"
        />
        <ActivityCard
          title="Days Remaining"
          count={isPastDate ? 'N/A' : daysRemaining}
        />
      </div>
      <div className="patient-detail-tab-rtm-container">
        <div className="patient-detail-tab-rtm-control-container">
          {visibleProfile?.BillingRole !== 'TECH' && (
            <Button
              className="patient-detail-tab-rtm-control-button"
              type="primary"
              disabled={showForm || discharged}
              onClick={handleShowForm}
            >
              {' '}
              Add RTM Time{' '}
            </Button>
          )}
          <DatePicker
            allowClear={false}
            picker="month"
            format="MMMM-YYYY"
            value={filteredDate}
            onChange={(date) => changeFilteredDate(date)}
          />
        </div>
        {showForm && (
          <div className="patient-detail-tab-rtm-form">
            {
              <Fragment>
                <div className="patient-detail-tab-rtm-form-activity">
                  <span className="patient-detail-tab-rtm-form-label">
                    {' '}
                    Activity{' '}
                  </span>
                  <Select
                    placeholder="Select activity"
                    onChange={(value) => {
                      setTimerActivity({
                        ...timerActivity,
                        activity: value
                      });
                    }}
                  >
                    {activity_list.map((activity, i) => {
                      return (
                        <Option value={activity.event} key={i}>
                          {activity.event === 'Live Call'
                            ? 'Live Interaction'
                            : activity.event}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="patient-detail-tab-rtm-form-date-time">
                  <div className="patient-detail-tab-rtm-form-date">
                    <span className="patient-detail-tab-rtm-form-label">
                      {' '}
                      Date{' '}
                    </span>
                    <DatePicker
                      allowClear={false}
                      format="MM/DD/YYYY"
                      defaultValue={moment()}
                      onChange={(value) => {
                        if (value) {
                          setTimerActivity({
                            ...timerActivity,
                            date: value.format('LL')
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="patient-detail-tab-rtm-form-time">
                    <span className="patient-detail-tab-rtm-form-label">
                      {' '}
                      Start/End Time{' '}
                    </span>
                    <Row gutter={[8, 8]}>
                      <Col md={12} xs={24}>
                        <input
                          type="time"
                          className="patient-detail-tab-rtm-form-input-time"
                          onChange={(e) => {
                            const format = timerActivity.date ? 'LT' : 'LLL';

                            setTimerActivity({
                              ...timerActivity,
                              start: moment(e.target.value, 'HH:mm a').format(
                                format
                              )
                            });
                          }}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <input
                          type="time"
                          className="patient-detail-tab-rtm-form-input-time"
                          onChange={(e) => {
                            const format = timerActivity.date ? 'LT' : 'LLL';

                            setTimerActivity({
                              ...timerActivity,
                              end: moment(e.target.value, 'HH:mm a').format(
                                format
                              )
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
                <>
                  <span className="patient-detail-tab-rtm-form-label">
                    {' '}
                    Notes{' '}
                  </span>
                  <Input.TextArea
                    style={{ resize: 'none' }}
                    value={timerActivity.notes}
                    onChange={(e) =>
                      setTimerActivity({
                        ...timerActivity,
                        notes: e.target.value
                      })
                    }
                  />
                </>

                <div className="patient-detail-tab-rtm-form-button">
                  <Button
                    className="patient-detail-tab-rtm-form-button-cancel"
                    onClick={() => {
                      setShowForm(false);
                      setTimerActivity({
                        activity: '',
                        date: moment().format('LL'),
                        start: '',
                        end: '',
                        notes: ''
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  {clinicalMonitoringLogs && validateOverlap() ? (
                    <Popconfirm
                      placement="topRight"
                      title={
                        <Typography.Text>
                          Your RTM time input overlaps with an existing
                          monitoring log activity.
                          <br />
                          Do you want to continue?
                        </Typography.Text>
                      }
                      onConfirm={handleSave}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="primary"
                        className="patient-detail-tab-rtm-form-button-save"
                        loading={saving}
                        disabled={validateForm()}
                      >
                        Save Changes
                      </Button>
                    </Popconfirm>
                  ) : (
                    <Button
                      type="primary"
                      className="patient-detail-tab-rtm-form-button-save"
                      loading={saving}
                      disabled={validateForm()}
                      onClick={handleSave}
                    >
                      Save Changes
                    </Button>
                  )}
                </div>
              </Fragment>
            }
          </div>
        )}
      </div>
      <div className="patient-detail-tab-body-container">
        {clinicalMonitoringLogs &&
          clinicalMonitoringLogs.map((data, i) => (
            <Fragment key={i}>
              <ActivityLog
                type="clinical"
                handleDeleteCLinicMonitorLogs={handleDeleteCLinicMonitorLogs}
                date={data.Date}
                activity={{
                  Id: data.Id,
                  Name: data.Name,
                  Event:
                    data.Event === 'Live Call'
                      ? 'Live Interaction'
                      : data.Event,
                  Start: data.Start,
                  End: data.End,
                  Duration: millisToMinutesAndSeconds(data.Duration),
                  Notes: data.Notes
                }}
                selectedPatient={selectedPatient}
                getClinicLogs={getClinicLogs}
              />
            </Fragment>
          ))}
        {clinicalMonitoringLogs.length === 0 && (
          <>
            {moment().isSame(filteredDate, 'd') ? (
              <NoResults content="No logs recorded today." />
            ) : (
              <NoResults
                content={
                  <Fragment>
                    No logs recorded during{' '}
                    <strong>{filteredDate.format('LL')}</strong>.
                  </Fragment>
                }
              />
            )}
          </>
        )}
      </div>
      <div className="patient-detail-tab-pagination" />
    </Fragment>
  );
}
