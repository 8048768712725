import { atom } from 'recoil';

const defaultState = {
  details: null
};

const USER_STATE = atom({
  key: 'USER_STATE',
  default: defaultState
});

export default USER_STATE;
