import React from 'react';
import { Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const AutoLoginModal = ({ autoLoginOpen }) => {
  return (
    <Modal visible={autoLoginOpen} footer={null} closable={false}>
      <p style={{ textAlign: 'center' }}>
        <LoadingOutlined style={{ fontSize: 24 }} spin color='primary' />
      </p>
      <p style={{ textAlign: 'center' }}>Automatically logging in...</p>
    </Modal>
  );
};

export default AutoLoginModal;
