import { atom } from 'recoil';

const defaultState = {
  list: [],
  history: [],
  form: null,
  action: '',
  rxDrawer: null,
};

const PRESCRIPTION_STATE = atom({
  key: 'PRESCRIPTION_STATE',
  default: defaultState,
});

export default PRESCRIPTION_STATE;
