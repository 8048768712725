import React, { Component } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { isDirty, isPristine, isSubmitting, isValid, reset } from 'redux-form';
import { Button, Input, Upload, message, notification } from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
  UploadOutlined
} from '@ant-design/icons';

import * as XLSX from 'xlsx';

import { FormTypes } from '../actions/groups';
import {
  getClinics,
  postClinic,
  updateClinic,
  defaultClinic,
  updateCurrentClinic,
  setClinicFormType as setFormType,
  openClinicFormModal as openFormModal,
  closeClinicFormModal as closeFormModal
} from '../actions/clinics';
import { isLoading, doneLoading } from '../actions/clinicsLoadingState';
import { titleCase } from '../stringHelpers';

import Loading from '../../../components/Loading';
import ClinicForm from './ClinicForm';

const loadingMS = 500;

class ClinicsPage extends Component {
  constructor(props) {
    super(props);

    this.confirmMessage =
      'You currently have unsaved changes. Are you sure you want to discard your changes?';

    this.handleSubmit = this.handleSubmit.bind(this);
    this.openNewFormModal = this.openNewFormModal.bind(this);
    this.openEditFormModal = this.openEditFormModal.bind(this);
    this.handleCloseForm = this.handleCloseForm.bind(this);
    this.validateFileType = this.validateFileType.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      search: '',
      loadingForm: false,
      clinicsArr: []
    };
  }

  componentDidMount() {
    const { dispatch, group } = this.props;
    dispatch(isLoading());
    dispatch(getClinics(group.name, group.id)).then(
      (x) => {
        setTimeout(() => {
          dispatch(doneLoading());
        }, loadingMS);
      },
      (x) => {
        setTimeout(() => {
          dispatch(doneLoading());
        }, loadingMS);
      }
    );
  }

  sortClinics = (a, b) => {
    if (a.ClinicName.trim() < b.ClinicName.trim()) {
      return -1;
    }
    if (a.ClinicName.trim() > b.ClinicName.trim()) {
      return 1;
    }
    return 0;
  };

  onChange(event) {
    const { clinics } = this.props;
    const search = event.target.value;

    this.setState({ search });

    if (search) {
      const clinicsArr = clinics.filter(
        (item) =>
          item.ClinicName &&
          item.ClinicName.toLowerCase().startsWith(search.toLowerCase())
      );

      this.setState({ clinicsArr });
    }
  }

  handleCloseForm() {
    const {
      props: { dispatch }
    } = this;

    dispatch(closeFormModal);
  }

  validateFileType(file) {
    const validTypes = [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    const isValidType = validTypes.includes(file.type);
    const hasValidExtension = ['.csv', '.xls', '.xlsx'].includes(
      file.name.split('.').pop().toLowerCase()
    );

    if (isValidType || hasValidExtension) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {
          type: 'array'
        });

        const names = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[names];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1
        });

        if (!jsonData.length) {
          return;
        }

        const dataArr = jsonData.slice(1).map((row) => {
          const obj = {};
          jsonData[0].forEach((key, index) => {
            obj[key] = row[index];
          });

          return obj;
        });

        const items = dataArr.filter((item) => !!item['Clinic ID']);
        for (let i = 0; i < items.length; i++) {
          notification.info({
            key: 'import-clinics',
            closeIcon: <></>,
            description: `Processing ${i + 1} of ${items.length} clinics ...`,
            icon: <LoadingOutlined />,
            duration: i === items.length - 1 ? 2 : 0
          });

          const item = items[i];
          const clinics = this.props.clinics;
          const group = this.props.group;

          if (clinics.some((c) => c.ClinicName === item['Practice Name'])) {
            continue;
          }

          try {
            await this.props.dispatch(
              postClinic(group.name, {
                groupId: group.id,
                Address: item['Address 1'],
                BlogUrl: group.BlogUrl ?? null,
                City: item['City'],
                ClinicEmail: null,
                ClinicName: item['Practice Name'],
                ClinicPhoneNumber: '(855) 692 - 8480',
                FacebookUrl: null,
                GoogleUrl: null,
                IsPracticePromotions: group.IsPracticePromotions,
                State: item['State/Territory/Province'],
                Zip: item['PostalCode']?.toString()
              })
            );
          } catch (error) {
            console.log('[import clinics]', error);
            continue;
          }
        }

        this.props.dispatch(
          getClinics(this.props.group.name, this.props.group.id)
        );
      };

      reader.readAsArrayBuffer(file);

      return true;
    }

    message.error('Invalid file type. Please upload an Excel or CSV only.');
    return false;
  }

  render() {
    const {
      openEditFormModal,
      openNewFormModal,
      handleCloseForm,
      handleSubmit,
      validateFileType,
      props: { clinics, fetched, formType, formModalOpen, currentClinic },
      state: { search, loadingForm, clinicsArr }
    } = this;

    let content;
    Object.keys(currentClinic).forEach((val) => {
      if (
        val === 'clinicName' ||
        val === 'address' ||
        val === 'city' ||
        val === 'contactName'
      ) {
        currentClinic[val] = titleCase(currentClinic[val]);
      }
    });

    const noClinics =
      !clinics || (fetched && clinics.length === 0 && !this.props.isSearching);
    const list = search ? clinicsArr : clinics;

    if (noClinics) {
      content = (
        <Row>
          <Col md={4} mdOffset={4} className="ptw-action-block">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Button
                type="primary"
                className="btn-primary ptw-btn"
                onClick={openNewFormModal}
              >
                Add Clinic
              </Button>

              <Upload
                accept=".csv,.xls,.xlsx"
                maxCount={1}
                showUploadList={false}
                beforeUpload={validateFileType}
              >
                <Button
                  type="primary"
                  className="btn-primary ptw-btn"
                  style={{
                    marginLeft: '1em'
                  }}
                >
                  Upload File
                </Button>
              </Upload>
            </div>
          </Col>
        </Row>
      );
    } else {
      content = (
        <>
          <div className="tab-header">
            <div />
            <div className="tab-header-controls">
              <Button
                type="primary"
                className="btn-primary ptw-btn"
                onClick={openNewFormModal}
              >
                <PlusOutlined /> Add Clinic
              </Button>

              <Upload
                accept=".csv,.xls,.xlsx"
                maxCount={1}
                showUploadList={false}
                beforeUpload={validateFileType}
              >
                <Button
                  type="primary"
                  className="btn-primary ptw-btn"
                  style={{
                    marginRight: '1em'
                  }}
                >
                  <UploadOutlined /> Upload File
                </Button>
              </Upload>

              <Input
                size="middle"
                placeholder="Clinic Name"
                prefix={<SearchOutlined />}
                onChange={this.onChange}
              />
            </div>
          </div>

          <Table responsive>
            <thead>
              <tr>
                <th>Clinic Name</th>
                <th>Address</th>
                <th>State</th>
                <th>Zip</th>
              </tr>
            </thead>

            <tbody>
              {Array.isArray(list) &&
                [...list]
                  .sort((a, b) => this.sortClinics(a, b))
                  .map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr
                          id={'row-' + index}
                          onClick={openEditFormModal && openEditFormModal(item)}
                        >
                          <td>{item.ClinicName}</td>
                          <td>{item.Address}</td>
                          <td>{item.State}</td>
                          <td>{item.Zip}</td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
            </tbody>
          </Table>
        </>
      );
    }

    return (
      <div>
        <Loading isLoading={this.props.isLoading}>
          {content}

          <ClinicForm
            initialValues={currentClinic}
            formType={formType}
            showModal={formModalOpen}
            loadingForm={loadingForm}
            handleClose={handleCloseForm}
            handleSubmit={handleSubmit}
          />
        </Loading>
      </div>
    );
  }

  async handleSubmit(values) {
    const { dispatch, group, formType, currentClinic } = this.props;

    this.setState({
      loadingForm: true
    });

    values.IsPracticePromotions = group.IsPracticePromotions;
    values.BlogUrl = group.BlogUrl;

    let submitAction;
    if (formType === FormTypes.NEW) {
      submitAction = postClinic(group.name, {
        ...values,
        groupId: group.id
      });
    } else {
      submitAction = updateClinic(group.name, {
        ...values,
        GroupId: group.id,
        Id: currentClinic.Id
      });
    }

    return await dispatch(submitAction)
      .then(() => {
        this.setState({
          loadingForm: false
        });

        notification.success({
          message: 'Success!',
          description: 'Clinic has been saved.'
        });

        dispatch(closeFormModal);
        dispatch(getClinics(group.name, group.id));
      })
      .catch(() => {
        this.setState({
          loadingForm: false
        });

        notification.error({
          message: 'Error!',
          description: 'An error occurred while saving clinic.'
        });

        dispatch(closeFormModal);
      });
  }

  openNewFormModal() {
    const { dispatch } = this.props;
    dispatch(setFormType(FormTypes.NEW));
    dispatch(updateCurrentClinic(defaultClinic));
    dispatch(openFormModal);
    dispatch(reset('clinic'));
  }

  openEditFormModal(clinic) {
    const { dispatch } = this.props;
    return () => {
      dispatch(setFormType(FormTypes.EDIT));
      dispatch(updateCurrentClinic(clinic));
      dispatch(openFormModal);
    };
  }
}

function mapStateToProps(state) {
  return {
    clinics: state.clinics.items,
    isFetching: state.clinics.isFetching,
    fetched: state.clinics.fetched,
    formModalOpen: state.clinics.formModalOpen,
    formType: state.clinics.formType,
    currentClinic: state.clinics.currentClinic,
    isLoading: state.clinics.isLoading,
    isSearching: state.clinics.isSearching,
    isFormValid: isValid('clinic')(state),
    isFormDirty: isDirty('clinic')(state),
    isFormSubmitting: isSubmitting('clinic')(state),
    isFormPristine: isPristine('clinic')(state)
  };
}

export default connect(mapStateToProps)(ClinicsPage);
