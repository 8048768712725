import React, { useState } from 'react';
import { Button, Form, Input, Space, Typography } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

const LoginForm = ({
  onSubmit,
  onChange,
  handleCapsLockOn,
  submitting,
  capsLockOn,
}) => {
  const [inputType, setInputType] = useState('text');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showUnameError, setShowUnameError] = useState(false);
  const [showPwordError, setShowPwordError] = useState(false);

  const formatInput = (type, value) => {
    if (type === 'tel') {
      const number = value.replace(/\D/g, '');
      const output = `(${number.substring(0, 3)}) ${number.substring(
        3,
        6
      )} - ${number.substring(6, 10)}`;

      setShowUnameError(false);
      setUsername(output);
      onChange('username', '+1' + output.replace(/[()\s\-]/g, ''));
    } else {
      setShowUnameError(false);
      setUsername(value);
      onChange('username', value);
    }
  };

  const handleUsernameInput = e => {
    const value = e.target.value;

    if (value.length > 2 && inputType === 'tel') {
      formatInput('tel', value);
    } else {
      formatInput('text', value);
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Backspace' || (e.ctrlKey && e.key === 'a')) {
      setInputType('text');
      return;
    }

    const value = e.target.value;
    const areNumbers =
      value.length > 0
        ? value
            .trim()
            .replace(/[()\s\-]/g, '')
            .split('')
            .every(c => !isNaN(c))
        : false;

    setUsername(value);

    if (areNumbers) {
      setInputType('tel');
    } else {
      setInputType('text');
    }
  };

  const handleInputPaste = e => {
    e.preventDefault();

    if (e.clipboardData) {
      const value = e.clipboardData.getData('Text');

      if (!/\s/g.test(value) && /^\d+$/.test(value)) {
        formatInput('tel', value);
      } else {
        formatInput('text', value);
      }
    }
  };

  const handlePasswordInput = e => {
    const value = e.target.value;

    setShowPwordError(false);
    setPassword(value);
    onChange('password', value);
  };

  const handleSubmitForm = () => {
    if (username && password) {
      onSubmit();
      return;
    }

    if (!username) setShowUnameError(true);
    if (!password) setShowPwordError(true);
  };

  const renderHelper = (show, message) =>
    show ? <Typography.Text type="danger">{message}</Typography.Text> : null;

  return (
    <Form
      layout="vertical"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={handleSubmitForm}
    >
      <Space
        direction="vertical"
        size="1em"
        style={{
          display: 'flex',
        }}
      >
        <Form.Item
          required
          label="Email or Phone Number"
          help={renderHelper(
            showUnameError,
            'Email or phone mumber is required.'
          )}
        >
          <Input
            size="large"
            placeholder="Enter your email or phone number"
            value={username}
            onChange={handleUsernameInput}
            onKeyDown={handleKeyPress}
            onPaste={handleInputPaste}
          />
        </Form.Item>

        <>
          {capsLockOn && (
            <Typography.Text type="warning" className="caps-lock-warning">
              <WarningFilled /> CAPS lock is on
            </Typography.Text>
          )}

          <Form.Item
            required
            label="Password"
            help={renderHelper(showPwordError, 'Password is required.')}
          >
            <Input.Password
              size="large"
              placeholder="Enter your password"
              value={password}
              onChange={handlePasswordInput}
              onKeyUp={handleCapsLockOn}
            />
          </Form.Item>
        </>

        <Form.Item style={{ marginTop: 8 }}>
          <Button
            block
            className="ptw-btn btn-primary"
            type="primary"
            size="large"
            htmlType="submit"
            loading={submitting}
          >
            {submitting ? ' ' : 'Login'}
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  submitEnabled: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export default LoginForm;
