import { getExercise } from "./actions/ptwiredExercises";
import voca from 'voca'

export const asyncValidate = (values, dispatch, props) =>
  new Promise((resolve, reject) => {
    if(values.exerciseName && values.exerciseName.trim() !== props.initialValues.exerciseName){
      dispatch(getExercise(voca.lowerCase(values.exerciseName))).then(({value}) => {
        if(value.message === "Success"){
          reject({exerciseName: "Exercise Name already exists for this Group"})
        }else{
          resolve()
        }
      }).catch(errorResponse => {
        if(errorResponse.status === 404){
          resolve()
        }else{
          reject({exerciseName: "Exercise Name already exists for this Group"})
        }
      })
    }else{
      resolve()
    }
  })