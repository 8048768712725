import React, { Fragment, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Card, Space, Button, Typography, Tooltip } from 'antd';
import {
  ArrowLeftOutlined,
  ExportOutlined,
  FilterOutlined
} from '@ant-design/icons';
import { downloadFile } from '../../../utils/csv.utils';

import moment from 'moment';
import v from 'voca';
import RtmBilled from './RtmBilled';
import RtmBillers from './RtmBillers';
import RtmCodes from './RtmCodes';
import RtmPatients from './RtmPatients';
import RtmServiceLogs from './RtmServiceLogs';
import states from '../../../states';
import setNotification from '../../../utils/setNotification.utils';
import * as reportsUtils from '../../../utils/reports.utils';

const formatDate = (date) => moment(date).format('DD-MMM-YY');

const ExpandedCard = ({ type, handleClose }) => {
  const title =
    type === 'BILLED'
      ? 'RTM Billed Codes'
      : type === 'BILLERS'
      ? 'RTM Codes Billed by Providers'
      : type === 'CODES'
      ? 'RTM Codes Billed per Patient'
      : type === 'PATIENTS'
      ? 'RTM Patients'
      : 'RTM Service Time by Provider';

  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [report, setReport] = useState(null);

  const groups = useRecoilValue(states.groups);

  const handleExport = () => {
    try {
      const reports = [];
      if (type === 'BILLED' || type === 'BILLERS') {
        for (let i = 0; i < report.labels.length; i++) {
          const values = {};

          if (type === 'BILLERS') {
            const provider = groups.providers.find((p) => {
              const fname = `${p.FirstName} ${p.LastName}`.toLowerCase();
              const label = report.labels[i].toLowerCase();

              return fname === label;
            });

            values['Provider'] = report.labels[i];
            values['Email Address'] = provider?.EmailAddress || '';
          } else {
            values[''] = report.labels[i];
          }

          for (let j = 0; j < report.datasets.length; j++) {
            const dataset = report.datasets[j];
            values[dataset.label] = dataset.data[i];
          }

          reports.push(values);
        }
      } else {
        for (let i = 0; i < report.length; i++) {
          const item = report[i];
          const { clinics, providers } = groups;

          if (type === 'CODES') {
            let billDate = '';
            if (item.BillDate === 'unbilled') {
              billDate = 'unbilled';
            } else {
              billDate = moment(item.BillDate).format('MMMM DD, YYYY');
            }

            let billedBy = '';
            if (item.BilledBy === 'unbilled') {
              billedBy = 'unbilled';
            } else {
              const profile = providers.find((p) => p.Sub === item.BilledBy);
              if (profile) {
                billedBy = `${profile.FirstName} ${profile.LastName}`;
              }
            }

            reports.push({
              'RTM Code': item.Code,
              Patient: v.titleCase(item.PatientName),
              'Email Address': item.EmailAddress || '',
              'Phone Number': item.PhoneNumber || '',
              'Ready for Billing Date': moment(item.Date).format(
                'MMMM DD, YYYY'
              ),
              'Billed Date': billDate,
              'Billed By': billedBy
            });
          } else if (type === 'PATIENTS') {
            const assignedProviders = [];
            if (item.Providers) {
              const isArray =
                item.Providers.startsWith('[') && item.Providers.endsWith(']');
              const assigned = [];

              if (isArray) {
                const parsed = JSON.parse(item.Providers);
                assigned.push(...parsed);
              } else {
                assigned.push(item.Providers);
              }

              assignedProviders.push(...assigned);
            }

            reports.push({
              'RTM Status': reportsUtils.getEngagementStatus(item.Status),
              Patient: v.titleCase(item.PatientName),
              'Email Address': item.EmailAddress || '',
              'Phone Number': item.PhoneNumber || '',
              'Enrollment Date': moment(item.EnrollStart).format(
                'MMMM DD, YYYY'
              ),
              'Billing Period':
                moment(item.BillingStart).format('MMM DD, YYYY') +
                ' - ' +
                moment(item.BillingEnd).format('MMM DD, YYYY'),
              'Assigned Providers': assignedProviders
                .map((ap) => {
                  const profile = providers.find((p) => p.Sub === ap);
                  if (!profile) {
                    return '';
                  }

                  return `${profile.FirstName} ${profile.LastName}`;
                })
                .join(', ')
            });
          } else if (type === 'SERVICE_LOGS') {
            const provider = groups.providers.find((p) => {
              const fname = `${p.FirstName} ${p.LastName}`.toLowerCase();
              const label = report[i]['ProviderName'].toLowerCase();

              return fname === label;
            });

            reports.push({
              Provider: item.ProviderName,
              'Email Address': provider?.EmailAddress || '',
              'Total RTM Time': item.TotalDuration,
              'Interactive Communication': item.TotalLiveCall,
              Location:
                clinics.find((c) => c.Id === item.Location)?.ClinicName ||
                'None'
            });
          }
        }
      }

      const query = reportsUtils.generateDateQuery(
        dateRange ? dateRange[0] : null,
        dateRange ? dateRange[1] : null
      );
      const filename = `${title}_${formatDate(query.start)}_TO_${formatDate(
        query.end
      )}`;

      if (!reports.length) {
        return;
      }

      downloadFile(reports, filename);
    } catch (error) {
      console.log(error);
      setNotification(
        'error',
        'Error!',
        'An error occurred while trying to download file.'
      );
    }
  };

  return (
    <Fragment>
      <Card
        title={
          <Space>
            <Button
              shape="circle"
              type="text"
              icon={<ArrowLeftOutlined />}
              onClick={handleClose}
            />
            <Typography.Text>{title}</Typography.Text>
          </Space>
        }
        extra={
          <Fragment>
            <Space className="hide-sm-tablet">
              <Button
                type="default"
                icon={<FilterOutlined />}
                onClick={() => setShowFilter(!showFilter)}
              >
                Filter
              </Button>
              <Button
                type="primary"
                icon={<ExportOutlined />}
                disabled={loading || error || !report}
                onClick={handleExport}
              >
                Export
              </Button>
            </Space>
            <Space className="hide-dt flex-sm-tablet">
              <Tooltip title="Filter">
                <Button
                  shape="circle"
                  type="default"
                  icon={<FilterOutlined />}
                  onClick={() => setShowFilter(!showFilter)}
                />
              </Tooltip>
              <Tooltip title="Export">
                <Button
                  shape="circle"
                  type="primary"
                  icon={<ExportOutlined />}
                  disabled={loading || error || !report}
                  onClick={handleExport}
                />
              </Tooltip>
            </Space>
          </Fragment>
        }
      >
        {type === 'BILLED' && (
          <RtmBilled
            title={title}
            showFilter={showFilter}
            onSetLoading={setLoading}
            onSetError={setError}
            onSetDateRange={setDateRange}
            onSetReport={setReport}
          />
        )}
        {type === 'BILLERS' && (
          <RtmBillers
            title={title}
            showFilter={showFilter}
            onSetLoading={setLoading}
            onSetError={setError}
            onSetDateRange={setDateRange}
            onSetReport={setReport}
          />
        )}
        {type === 'CODES' && (
          <RtmCodes
            title={title}
            showFilter={showFilter}
            onSetLoading={setLoading}
            onSetError={setError}
            onSetDateRange={setDateRange}
            onSetReport={setReport}
          />
        )}
        {type === 'PATIENTS' && (
          <RtmPatients
            title={title}
            showFilter={showFilter}
            onSetLoading={setLoading}
            onSetError={setError}
            onSetDateRange={setDateRange}
            onSetReport={setReport}
          />
        )}
        {type === 'SERVICE_LOGS' && (
          <RtmServiceLogs
            title={title}
            showFilter={showFilter}
            onSetLoading={setLoading}
            onSetError={setError}
            onSetDateRange={setDateRange}
            onSetReport={setReport}
          />
        )}
      </Card>
    </Fragment>
  );
};

export default ExpandedCard;
