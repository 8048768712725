import React, { Fragment } from 'react';
import { Table, Tag } from 'antd';

const columns = [
  {
    title: 'Program',
    dataIndex: 'ProgramName',
    key: 'ProgramName',
    width: '15%',
    className: 'care-plan-program-table_row',
  },
  {
    title: 'Diagnosis',
    dataIndex: 'Diagnosis',
    key: 'Diagnosis',
    width: '15%',
    className: 'care-plan-program-table_row',
  },
  {
    title: 'Patient Term',
    dataIndex: 'PatientTerm',
    key: 'PatientTerm',
    width: '15%',
    className: 'care-plan-program-table_row',
  },
  {
    title: 'Videos',
    dataIndex: 'Videos',
    key: 'Videos',
    className: 'care-plan-program-table_row',
    render: (_, Program) => (
      <Fragment>
        {Program &&
          Program.Videos &&
          Program.Videos.map(video => {
            return (
              <Tag color="geekblue" key={video.Id}>
                {String(video.Title).toUpperCase()}
              </Tag>
            );
          })}
      </Fragment>
    ),
  },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   render: (_, record) => (
  //     <Space size="middle">
  //       <a>Delete</a>
  //     </Space>
  //   ),
  // },
];

export default function Program(props) {
  const { data, state, handleChangeScreen } = props;
  return (
    <Table
      scroll={{
        x: 'calc(100vh - 500px)',
      }}
      size="small"
      columns={columns}
      dataSource={data}
      onRow={record => ({
        onClick: () => {
          handleChangeScreen({
            activeProgram: record,
            screen: 'details',
          });
        },
      })}
    />
  );
}
