import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { changeWeek } from './actions/patients'


const ArrowDirection = props => {
  const { className, onClick, direction } = props

  return (
    <div
      className={className}
      onClick={() => {
        onClick()
        if (direction === 'prev') {
          props.dispatch(changeWeek(props.week - 1))
        } else {
          props.dispatch(changeWeek(props.week + 1))
        }
      }}
    ></div>
  );
}

const mapStateToProps = state => ({
  week: state.patients.weekChange,
})

export default connect(mapStateToProps)(withRouter(ArrowDirection))
