import React from 'react';
import { Typography } from 'antd';
import './loadingPage.css';

const LoadingPage = ({ content, type }) => {
  if (type === 'list') {
    return (
      <div className="list-loading-container">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <Typography.Text>{content}</Typography.Text>
      </div>
    );
  }

  return (
    <div className="page-loading-container">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <Typography.Text>{content}</Typography.Text>
    </div>
  );
};

export default LoadingPage;
