import React from 'react';
import { Modal, Image, Grid, Row, Col } from 'react-bootstrap';
import { ios, android } from './AppUrls.js';
import { replace, words, pull } from 'lodash/';
import { getAppstoreLinks } from './actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import services from '../../services/index.js';

class AppstoreLinks extends React.Component {
  constructor(props, context) {
    super(props, context);

    let show;
    let role;
    if (this.props.location.search === '?from=validate') {
      show = true;
      role = 'p';
    } else if (this.props.location.search === '?role=pt') {
      show = true;
      role = 'pt';
    } else {
      show = false;
    }

    let delimeters = [
      'http:',
      'https:',
      'www',
      'com',
      'com/#/',
      'com/#/',
      '.com/#/?from=validate',
      '.com/#/?role=pt',
      'ptwired'
    ];

    let location = window.location.href;
    // eslint-disable-next-line
    let domain = pull(words(location, /[^\/$.]+/g), ...delimeters)[0];

    if (domain === 'localhost:3000') {
      domain = 'ptw';
    }

    let url;
    if (this.props.location.search === '?from=validate') {
      url = replace(location, '?from=validate', '');
    }

    if (this.props.location.search === '?role=pt') {
      url = replace(location, '?role=pt', '');
    }

    this.ios = ios;
    this.android = android;

    services.links.getStores(domain).then((response) => {
      if (response && response.data) {
        this.setState({
          iOSUrl: response.data.iOSUrl,
          AndroidUrl: response.data.AndroidUrl
        });
      }
    });

    this.state = {
      show: show,
      domain: domain,
      url: url,
      role: role
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  checkUrl(url) {
    const index = url.indexOf('//');
    const newUrl = url.substring(index > -1 ? index + 2 : 0, url.length);

    return `https://${newUrl}`;
  }

  render() {
    const { domain, url, show, AndroidUrl, iOSUrl, role } = this.state;

    // console.log('url', url);

    return (
      <div className="static-modal store-badge-modal">
        <Modal
          show={show}
          keyboard={true}
          onHide={this.handleClose}
          bsSize="large"
          aria-labelledby="contained-modal-title-lg"
        >
          {role === 'pt' && (
            <div>
              <Modal.Header
                className="store-badge-modal-header"
                closeButton={true}
              >
                <p>Your account is now activated!</p>
              </Modal.Header>
              <Modal.Body className="store-badge-modal-body">
                <Grid fluid>
                  <Row>
                    <Col sm={12} style={{ fontSize: '75px', color: '#59b664' }}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <p>
                        <text style={{ fontSize: '40px' }}>
                          &nbsp; Bookmark this login page so you don't forget
                          it!
                        </text>
                      </p>
                      <br />
                      <p>
                        <a
                          style={{ fontSize: '40px' }}
                          href={url}
                          target="_blank"
                        >
                          {domain}.ptwired.com
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Grid>
              </Modal.Body>
              <Modal.Footer className="store-badge-modal-footer">
                <p>
                  <a href="mailto:admin@ptwired.com">Need Help?</a>
                </p>
              </Modal.Footer>
            </div>
          )}
          {role === 'p' && (
            <div>
              <Modal.Header
                className="store-badge-modal-header"
                closeButton={true}
              >
                <p>Congratulations!</p>
              </Modal.Header>
              <Modal.Body className="store-badge-modal-body">
                <Grid fluid>
                  <Row>
                    <Col sm={12}>
                      <p>Your account is now activated.</p>
                      <br />
                      <p>To login, download the app on your phone!</p>
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="store-badge-col"
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xsPush={2}
                      smPush={1}
                      mdPush={2}
                      lgPush={2}
                    >
                      <div className="store-badge badge-ios">
                        {iOSUrl ? (
                          <a
                            href={`${this.checkUrl(iOSUrl)}`}
                            target={'_blank'}
                          >
                            <Image
                              src="/App_Store_Badge.png"
                              responsive={true}
                              rounded={true}
                            />
                          </a>
                        ) : (
                          <Image
                            src="/App_Store_Badge.png"
                            responsive={true}
                            rounded={true}
                            style={{
                              opacity: 0.4,
                              cursor: 'not-allowed'
                            }}
                          />
                        )}
                      </div>
                    </Col>
                    <Col
                      className="store-badge-col"
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xsPush={2}
                      smPush={1}
                      mdPush={2}
                      lgPush={2}
                    >
                      <div className="store-badge badge-android">
                        {AndroidUrl ? (
                          <a
                            href={`${this.checkUrl(AndroidUrl)}`}
                            target={'_blank'}
                          >
                            <Image
                              src="/google-play-badge-1.png"
                              responsive={true}
                              rounded={true}
                            />
                          </a>
                        ) : (
                          <Image
                            src="/google-play-badge-1.png"
                            responsive={true}
                            rounded={true}
                            style={{
                              opacity: 0.4,
                              cursor: 'not-allowed'
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </Modal.Body>
              <Modal.Footer className="store-badge-modal-footer">
                <p onClick={this.handleClose}>
                  Rather access online? Go to{' '}
                  <a href={url}>{domain}.ptwired.com</a>.
                  <br />
                </p>
                <p>
                  <a href="mailto:admin@ptwired.com">Help?</a>
                </p>
              </Modal.Footer>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default AppstoreLinks;
