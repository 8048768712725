import exercises_1 from '../../../img/achievements/exercises/1.png';
import exercises_10 from '../../../img/achievements/exercises/10.png';
import exercises_25 from '../../../img/achievements/exercises/25.png';
import exercises_50 from '../../../img/achievements/exercises/50.png';

import routines_1 from '../../../img/achievements/routines/1.png';
import routines_5 from '../../../img/achievements/routines/5.png';
import routines_15 from '../../../img/achievements/routines/15.png';
import routines_30 from '../../../img/achievements/routines/30.png';

// import streaks_3 from '../../../img/achievements/streaks/3.png';
// import streaks_7 from '../../../img/achievements/streaks/7.png';
// import streaks_14 from '../../../img/achievements/streaks/14.png';
// import streaks_21 from '../../../img/achievements/streaks/21.png';

const achievementsImages = {
    exercises: {
        1: {
            image_name: exercises_1,
            title: 'First Exercise',
            message: "Congratulations, you've completed your first exercise!"
        },
        10: {
            image_name: exercises_10,
            title: '10 Exercises',
            message: "Congratulations, you've completed your 10th exercise!"
        },
        25: {
            image_name: exercises_25,
            title: '25 Exercises',
            message: "Congratulations, you've completed your 25th exercise!"
        },
        50: {
            image_name: exercises_50,
            title: '50 Exercises',
            message: "Congratulations, you've completed your 50th exercise!"
        }
    },

    routines: {
        1: {
            image_name: routines_1,
            title: 'First Routine',
            message: "Congratulations, you've completed your first routine!"
        },
        5: {
            image_name: routines_5,
            title: '5 Routines',
            message: "Congratulations, you've completed your 5th routine!"
        },
        15: {
            image_name: routines_15,
            title: '15 Routines',
            message: "Congratulations, you've completed your 15th routine!"
        },
        30: {
            image_name: routines_30,
            title: '30 Routines',
            message: "Congratulations, you've completed your 30th routine!"
        }
    },
    //
    // streaks: {
    //     3: {
    //         image_name: streaks_3,
    //         title: '3 Day Streak',
    //         message: "Congratulations, you've completed your 3 Day Streak!"
    //     },
    //     7: {
    //         image_name: streaks_7,
    //         title: '7 Day Streak',
    //         message: "Congratulations, you've completed your 7 Day Streak!"
    //     },
    //     14: {
    //         image_name: streaks_14,
    //         title: '14 Day Streak',
    //         message: "Congratulations, you've completed your 14 Day Streak!"
    //     },
    //     21: {
    //         image_name: streaks_21,
    //         title: '21 Day Streak',
    //         message: "Congratulations, you've completed your 21 Day Streak!"
    //     }
    // }
};

export default achievementsImages;