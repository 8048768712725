import apiFetch from '../utils/fetch.utils';

export const getFilters = async (sub) => {
  try {
    const uri = `filters?sub=${sub}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addFilters = async (payload) => {
  try {
    const uri = 'filters';
    const method = 'POST';

    return apiFetch(uri, method, {
      body: {
        ...payload
      }
    });
  } catch (error) {
    console.log(err);
    throw err;
  }
};
