import { createAction } from 'redux-actions';
import { API_REQUEST } from '../../membership/middleware';

export const GET_ALL_PROGRAMS = 'GET_ALL_PROGRAMS';
export const getAllPrograms = () => ({
  [API_REQUEST]: createAction(GET_ALL_PROGRAMS)({
    resource: `admin/care-plan?action=GET_PROGRAMS`,
    method: 'GET',
  }),
});

export const GET_PROGRAM = 'GET_PROGRAM';
export const getProgram = ({ id }) => ({
  [API_REQUEST]: createAction(GET_PROGRAM)({
    resource: `admin/care-plan?action=GET_PROGRAM&id=${id}`,
    method: 'GET',
  }),
});

export const UPDATE_PROGRAM = 'UPDATE_PROGRAM';
export const updateProgram = payload => ({
  [API_REQUEST]: createAction(UPDATE_PROGRAM)({
    resource: `admin/care-plan`,
    method: 'PUT',
    body: { ...payload },
  }),
});

export const ADD_VIDEO = 'ADD_VIDEO';
export const addVideo = payload => ({
  [API_REQUEST]: createAction(ADD_VIDEO)({
    resource: `admin/care-plan`,
    method: 'POST',
    body: {
      action: 'ADD_VIDEO',
      ...payload,
    },
  }),
});

export const ADD_PROGRAM = 'ADD_PROGRAM';
export const addProgram = payload => ({
  [API_REQUEST]: createAction(ADD_PROGRAM)({
    resource: `admin/care-plan`,
    method: 'POST',
    body: {
      action: 'ADD_PROGRAM',
      programName: payload.programName,
      diagnosis: payload.diagnosis,
      patientTerm: payload.patientTerm,
    },
  }),
});

export const CHANGE_ACTIVE_PROGRAM = 'CHANGE_ACTIVE_PROGRAM';
export const changeActiveProgram = createAction(
  CHANGE_ACTIVE_PROGRAM,
  activeProgram => ({
    activeProgram,
  }),
);
