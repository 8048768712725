import React, { useEffect } from 'react';
import { Typography, List, Checkbox, Tree } from 'antd';
import { MedicineBoxFilled, StarFilled } from '@ant-design/icons';
import _ from 'lodash';

import {
  calculateHalfChecked,
  createTag,
  getParents
} from '../../../utils/exercises.utils';

const filterListTop = [
  {
    icon: <StarFilled />,
    name: 'Only Show Favorites'
  },
  {
    icon: <MedicineBoxFilled />,
    name: 'Only Clinic Exercises'
  },
  {
    icon: <MedicineBoxFilled />,
    name: 'Only My Exercises'
  }
];

export const keyValueMap = {
  '0-1-0-0-0': 'Cervical Spine Flexors',
  '0-1-0-0-1': 'Cervical Spine Extensors',
  '0-1-0-0-2': 'Cervical Spine Rotators',
  '0-1-1-0-0': 'Ankle Dorsiflexors',
  '0-1-1-0-1': 'Ankle Plantarflexors',
  '0-1-1-0-2': 'Ankle Invertors',
  '0-1-1-0-3': 'Ankle Evertors',
  '0-1-1-1-0': 'Foot Intrinsics',
  '0-1-1-1-1': 'Toe Extensors',
  '0-1-1-1-2': 'Toe Flexors',
  '0-1-1-2-0': 'Hip Flexors',
  '0-1-1-2-1': 'Hip Extensors',
  '0-1-1-2-2': 'Hip Internal Rotators',
  '0-1-1-2-3': 'Hip External Rotators',
  '0-1-1-2-4': 'Hip Abductors',
  '0-1-1-2-5': 'Hip Adductors',
  '0-1-1-3-0': 'Knee Extensors',
  '0-1-1-3-1': 'Knee Flexors',
  '0-1-1-3-2': 'Patella',
  '0-1-3-1-4': 'Hand Thumb',
  '0-1-3-3-3': 'Shoulder Flexors',
  '0-1-3-3-4': 'Shoulder Extensors',
  '0-1-3-3-5': 'Shoulder Abductors',
  '0-1-3-3-6': 'Shoulder Internal Rotators',
  '0-1-3-3-7': 'Shoulder External Rotators',
  '0-1-3-4-0': 'Wrist Extensors',
  '0-1-3-4-1': 'Wrist Flexors',
  '0-1-3-4-2': 'Wrist Pronators',
  '0-1-3-4-3': 'Wrist Supinators',
  '0-1-3-4-4': 'Wrist Radial Deviation',
  '0-1-3-4-5': 'Wrist Ulnar Deviation'
};

export const ignoredKeys = ['0-0', '0-1', '0-2', '0-3', '0-4', '0-5', '0-6'];

const ExerciseFilter = ({
  type = 'dt',
  setFavoritesSelected,
  setClinicExercisesSelected,
  setMyExercisesSelected,
  checkedKeys,
  setCheckedKeys,
  expandedKeys,
  setExpandedKeys,
  tags,
  setTags,
  removeTag
}) => {
  useEffect(() => {
    window.addEventListener('scroll', isSticky);

    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = () => {
    const sidebar = document.querySelector('.filter-exercises-container');
    const scrollTop = window.scrollY;

    if (sidebar) {
      if (scrollTop >= 300) {
        sidebar.classList.add('is-sticky');
      } else {
        sidebar.classList.remove('is-sticky');
      }
    }
  };

  const handleFilterTop = (item) => {
    switch (item.name) {
      case 'Only Show Favorites':
        setFavoritesSelected((prevBool) => !prevBool);
        break;

      case 'Only Clinic Exercises':
        setClinicExercisesSelected((prevBool) => !prevBool);
        break;

      case 'Only My Exercises':
        setMyExercisesSelected((prevBool) => !prevBool);
        break;

      default:
        break;
    }
  };

  const handleFilter = (selectedKeys, e) => {
    if (e.checkedNodes.length === 0) {
      setTags([]);
      setCheckedKeys({
        checked: [],
        halfChecked: []
      });

      return;
    }

    const nodes = e.checkedNodes;

    addTags(nodes, e);
    checkKeys(nodes, e);
  };

  const handleSelectFilter = (selectedKeys, e) => {
    const selected = e.selected;

    if (selected) {
      e.checked = true;

      addTags(e.selectedNodes, e);
      expandKeys(e.selectedNodes, e);
      checkKeys(e.selectedNodes, e);
    } else {
      e.checked = false;
      e.node.key = e.node.eventKey;

      const uncheckedNode = createTag(e.node);

      uncheckedNode.key = e.node.props.eventKey;
      removeTag(uncheckedNode);
    }
  };

  const addTags = (nodes, e) => {
    if (e.checked) {
      let newTags = _.concat([], tags);

      nodes.map((node) => {
        if (ignoredKeys.some((key) => node.key === key)) {
          return node;
        }

        newTags.push(createTag(node));
        return createTag(node);
      });

      newTags = _.uniqBy(newTags, 'key');
      setTags(newTags);
    } else {
      const item = e.node.props.eventKey;
      const filteredTags = _.filter(tags, (object) => {
        return object.key !== item;
      });

      setTags(filteredTags);
    }
  };

  const checkKeys = (nodes, e) => {
    if (e.checked) {
      let newCheckedKeys = _.concat([], checkedKeys.checked);

      nodes.map((node) => {
        return newCheckedKeys.push(node.key);
      });

      const halfChecked = calculateHalfChecked(newCheckedKeys);
      newCheckedKeys = _.uniq(newCheckedKeys);

      setCheckedKeys({
        checked: newCheckedKeys,
        halfChecked: halfChecked
      });

      return;
    }

    const item = e.node.props.eventKey;
    const removed = _.without(checkedKeys.checked, item);
    const halfChecked = calculateHalfChecked(removed);

    setCheckedKeys({
      checked: removed,
      halfChecked: halfChecked
    });
  };

  const handleExpand = (index, item) => {
    const key = item.node.props.eventKey;
    const parents = getParents(key);

    let keys = _.concat(parents, key);
    if (item.expanded) {
      setExpandedKeys(_.uniq(keys));
    } else {
      setExpandedKeys(index);
    }
  };

  const expandKeys = (nodes) => {
    let keys = [];

    nodes.map((node) => {
      keys.push(node.key);
      const parents = getParents(node.key);
      keys = _.concat(parents, keys);
      return node;
    });

    setExpandedKeys(_.uniq(keys));
  };

  return (
    <div
      className={`filter-exercises-container ${
        type === 'dt' ? 'hide-sm-tablet' : 'hide-dt show-sm-tablet'
      } pt-1 pr-1`}
    >
      <Typography.Title level={4}>Filters</Typography.Title>
      <List
        size="large"
        dataSource={filterListTop}
        renderItem={(item) => (
          <List.Item className="pt-1 pb-2">
            <Typography.Text>
              {item.icon} {item.name}
            </Typography.Text>
            <Checkbox
              className="pull-right"
              onChange={() => handleFilterTop(item)}
            />
          </List.Item>
        )}
      />
      <Tree
        checkable
        checkStrictly
        onCheck={handleFilter}
        onExpand={handleExpand}
        onSelect={handleSelectFilter}
        checkedKeys={checkedKeys}
        expandedKeys={expandedKeys}
      >
        <Tree.TreeNode title="Position" key="0-3" disableCheckbox>
          <Tree.TreeNode title="Dynamic" key="0-3-0" />
          <Tree.TreeNode title="Kneeling" key="0-3-1" />
          <Tree.TreeNode title="Prone" key="0-3-2" />
          <Tree.TreeNode title="Quadruped" key="0-3-3" />
          <Tree.TreeNode title="Side Lying" key="0-3-4" />
          <Tree.TreeNode title="Sitting" key="0-3-5" />
          <Tree.TreeNode title="Standing" key="0-3-6" />
          <Tree.TreeNode title="Supine" key="0-3-7" />
        </Tree.TreeNode>
        <Tree.TreeNode title="Body Parts" key="0-1" disableCheckbox>
          <Tree.TreeNode title="Head and Neck" key="0-1-0">
            <Tree.TreeNode title="Cervical Spine" key="0-1-0-0">
              <Tree.TreeNode
                title="Flexors"
                value="Cervical Spine Flexors"
                key="0-1-0-0-0"
              />
              <Tree.TreeNode
                title="Extensors"
                value="Cervical Spine Extensors"
                key="0-1-0-0-1"
              />
              <Tree.TreeNode
                title="Rotators"
                value="Cervical Spine Rotators"
                key="0-1-0-0-2"
              />
            </Tree.TreeNode>
            <Tree.TreeNode title="Face" key="0-1-0-1" />
            <Tree.TreeNode title="Jaw" key="0-1-0-2" />
            <Tree.TreeNode title="Vestibular" key="0-1-0-3" />
          </Tree.TreeNode>
          <Tree.TreeNode title="Lower Extremity" key="0-1-1">
            <Tree.TreeNode title="Ankle" key="0-1-1-0">
              <Tree.TreeNode
                title="Dorsiflexors"
                value="Ankle Dorsiflexors"
                key="0-1-1-0-0"
              />
              <Tree.TreeNode
                title="Plantarflexors"
                value="Ankle Plantarflexors"
                key="0-1-1-0-1"
              />
              <Tree.TreeNode
                title="Invertors"
                value="Ankle Invertors"
                key="0-1-1-0-2"
              />
              <Tree.TreeNode
                title="Evertors"
                value="Ankle Evertors"
                key="0-1-1-0-3"
              />
            </Tree.TreeNode>
            <Tree.TreeNode title="Foot" key="0-1-1-1">
              <Tree.TreeNode
                title="Intrinsics"
                value="Foot Intrinsics"
                key="0-1-1-1-0"
              />
              <Tree.TreeNode
                title="Extensors"
                value="Toe Extensors"
                key="0-1-1-1-1"
              />
              <Tree.TreeNode
                title="Flexors"
                value="Toe Flexors"
                key="0-1-1-1-2"
              />
            </Tree.TreeNode>
            <Tree.TreeNode title="Hip" key="0-1-1-2">
              <Tree.TreeNode
                title="Flexors"
                value="Hip Flexors"
                key="0-1-1-2-0"
              />
              <Tree.TreeNode
                title="Extensors"
                value="Hip Extensors"
                key="0-1-1-2-1"
              />
              <Tree.TreeNode
                title="Internal Rotators"
                value="Hip Internal Rotators"
                key="0-1-1-2-2"
              />
              <Tree.TreeNode
                title="External Rotators"
                value="Hip External Rotators"
                key="0-1-1-2-3"
              />
              <Tree.TreeNode
                title="Abductors"
                value="Hip Abductors"
                key="0-1-1-2-4"
              />
              <Tree.TreeNode
                title="Adductors"
                value="Hip Adductors"
                key="0-1-1-2-5"
              />
            </Tree.TreeNode>
            <Tree.TreeNode title="Knee" key="0-1-1-3">
              <Tree.TreeNode
                title="Extensors"
                value="Knee Extensors"
                key="0-1-1-3-0"
              />
              <Tree.TreeNode
                title="Flexors"
                value="Knee Flexors"
                key="0-1-1-3-1"
              />
              <Tree.TreeNode title="Patella" key="0-1-1-3-2" />
            </Tree.TreeNode>
          </Tree.TreeNode>
          <Tree.TreeNode title="Trunk" key="0-1-2">
            <Tree.TreeNode title="Abdominals" key="0-1-2-0" />
            <Tree.TreeNode title="Chest" key="0-1-2-1" />
            <Tree.TreeNode title="Costovertebral" key="0-1-2-2" />
            <Tree.TreeNode title="Lumbar Spine" key="0-1-2-3" />
            <Tree.TreeNode title="Pelvis" key="0-1-2-4" />
            <Tree.TreeNode title="Thoracic Spine" key="0-1-2-5" />
          </Tree.TreeNode>
          <Tree.TreeNode title="Upper Extremity" key="0-1-3">
            <Tree.TreeNode title="Elbow" key="0-1-3-0">
              <Tree.TreeNode title="Elbow Flexors" key="0-1-3-0-0" />
              <Tree.TreeNode title="Elbow Extensors" key="0-1-3-0-1" />
            </Tree.TreeNode>
            <Tree.TreeNode title="Hand" key="0-1-3-1">
              <Tree.TreeNode title="Finger Flexors" key="0-1-3-1-0" />
              <Tree.TreeNode title="Finger Extensors" key="0-1-3-1-1" />
              <Tree.TreeNode title="Finger Adductors" key="0-1-3-1-2" />
              <Tree.TreeNode title="Finger Abductors" key="0-1-3-1-3" />
              <Tree.TreeNode title="Thumb" value="Hand Thumb" key="0-1-3-1-4" />
            </Tree.TreeNode>
            <Tree.TreeNode
              title="Scapulothoracic"
              key="0-1-3-2"
            ></Tree.TreeNode>
            <Tree.TreeNode title="Shoulder" key="0-1-3-3">
              <Tree.TreeNode title="Lower Trap" key="0-1-3-3-0" />
              <Tree.TreeNode title="Middle Trap" key="0-1-3-3-1" />
              <Tree.TreeNode title="Serratus Anterior" key="0-1-3-3-2" />
              <Tree.TreeNode
                title="Flexors"
                value="Shoulder Flexors"
                key="0-1-3-3-3"
              />
              <Tree.TreeNode
                title="Extensors"
                value="Shoulder Extensors"
                key="0-1-3-3-4"
              />
              <Tree.TreeNode
                title="Abductors"
                value="Shoulder Abductors"
                key="0-1-3-3-5"
              />
              <Tree.TreeNode
                title="Internal Rotators"
                value="Shoulder Internal Rotators"
                key="0-1-3-3-6"
              />
              <Tree.TreeNode
                title="External Rotators"
                value="Shoulder External Rotators"
                key="0-1-3-3-7"
              />
            </Tree.TreeNode>
            <Tree.TreeNode title="Wrist" key="0-1-3-4">
              <Tree.TreeNode
                title="Extensors"
                value="Wrist Extensors"
                key="0-1-3-4-0"
              />
              <Tree.TreeNode
                title="Flexors"
                value="Wrist Flexors"
                key="0-1-3-4-1"
              />
              <Tree.TreeNode
                title="Pronators"
                value="Wrist Pronators"
                key="0-1-3-4-2"
              />
              <Tree.TreeNode
                title="Supinators"
                value="Wrist Supinators"
                key="0-1-3-4-3"
              />
              <Tree.TreeNode
                title="Radial Deviation"
                value="Wrist Radial Deviation"
                key="0-1-3-4-4"
              />
              <Tree.TreeNode
                title="Ulnar Deviation"
                value="Wrist Ulnar Deviation"
                key="0-1-3-4-5"
              />
            </Tree.TreeNode>
          </Tree.TreeNode>
        </Tree.TreeNode>
        <Tree.TreeNode title="Movement/Direction" key="0-2" disableCheckbox>
          <Tree.TreeNode title="Flexion" key="0-2-0" />
          <Tree.TreeNode title="Extension" key="0-2-1" />
          <Tree.TreeNode title="Abduction" key="0-2-2" />
          <Tree.TreeNode title="Adduction" key="0-2-3" />
          <Tree.TreeNode title="External Rotation" key="0-2-4" />
          <Tree.TreeNode title="Internal Rotation" key="0-2-5" />
          <Tree.TreeNode title="Circumduction" key="0-2-6" />
          <Tree.TreeNode title="Rotation" key="0-2-7" />
        </Tree.TreeNode>
        <Tree.TreeNode title="Type" key="0-0" disableCheckbox>
          <Tree.TreeNode title="Aquatic" key="0-0-0" />
          <Tree.TreeNode title="Range of Motion" key="0-0-1">
            <Tree.TreeNode title="AAROM" key="0-0-1-1" />
            <Tree.TreeNode title="AROM" key="0-0-1-2" />
            <Tree.TreeNode title="PROM" key="0-0-1-3" />
          </Tree.TreeNode>
          <Tree.TreeNode title="Mobilization" key="0-0-2">
            <Tree.TreeNode title="Self Mobilization" key="0-0-2-0" />
            <Tree.TreeNode title="Static Hold Stretching" key="0-0-2-1" />
            <Tree.TreeNode title="Dynamic Stretching" key="0-0-2-2" />
          </Tree.TreeNode>
          <Tree.TreeNode title="Strengthening" key="0-0-3">
            <Tree.TreeNode title="Isometrics" key="0-0-3-0" />
            <Tree.TreeNode title="Concentrics" key="0-0-3-1" />
            <Tree.TreeNode title="Eccentrics" key="0-0-3-2" />
          </Tree.TreeNode>
          <Tree.TreeNode title="Functional" key="0-0-4">
            <Tree.TreeNode title="Agility" key="0-0-4-0" />
            <Tree.TreeNode title="Plyometrics" key="0-0-4-1" />
            <Tree.TreeNode title="Gait Training" key="0-0-4-2" />
          </Tree.TreeNode>
          <Tree.TreeNode title="Proprioception" key="0-0-5">
            <Tree.TreeNode title="Balance" key="0-0-5-0" />
            <Tree.TreeNode title="Vestibular" key="0-0-5-1" />
          </Tree.TreeNode>
          <Tree.TreeNode title="Pediatric" key="0-0-6" />
        </Tree.TreeNode>
        <Tree.TreeNode title="Equipment" key="0-4" disableCheckbox>
          <Tree.TreeNode title="Ankle Weight" key="0-4-0" />
          <Tree.TreeNode title="Barbell" key="0-4-1" />
          <Tree.TreeNode title="BOSU Ball" key="0-4-2" />
          <Tree.TreeNode title="Cane" key="0-4-3" />
          <Tree.TreeNode title="Dowel" key="0-4-4" />
          <Tree.TreeNode title="Dumbbell" key="0-4-5" />
          <Tree.TreeNode title="Exercise Band" key="0-4-6" />
          <Tree.TreeNode title="Exercise Ball" key="0-4-7" />
          <Tree.TreeNode title="Foam Pad" key="0-4-8" />
          <Tree.TreeNode title="Foam Roller" key="0-4-9" />
          <Tree.TreeNode title="Kettlebell" key="0-4-10" />
          <Tree.TreeNode title="Pulley" key="0-4-11" />
          <Tree.TreeNode title="Step" key="0-4-12" />
          <Tree.TreeNode title="Strap" key="0-4-13" />
          <Tree.TreeNode title="TRX" key="0-4-14" />
          <Tree.TreeNode title="Wobble Board" key="0-4-15" />
          <Tree.TreeNode title="Yoga Block" key="0-4-16" />
        </Tree.TreeNode>
      </Tree>
      <div className="mt-4" />
    </div>
  );
};

export default ExerciseFilter;
