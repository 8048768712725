import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Space, Button, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import v from 'voca';

import CarePlanForm from '../../../components/CarePlanForm';
import { setCarePlanProperties } from '../actions/patients';
import { useSetRecoilState } from 'recoil';
import states from '../../../states';

const CarePlan = props => {
  const { dispatch, goToRxPage, patient } = props;

  const [carePlanData, setCarePlanData] = useState(null);
  const [validated, setValidated] = useState(false);

  const setPrescription = useSetRecoilState(states.prescription);

  useEffect(() => {
    setPrescription(prevState => ({
      ...prevState,
      action: 'EDIT'
    }));
  }, []);

  useEffect(() => {
    if (carePlanData) {
      const { program_name } = carePlanData;

      const notNull = program_name !== null;
      const notEmpty = program_name !== '';
      const notOther = program_name !== 'Other';

      setValidated(notNull && notEmpty && notOther);
    }
  }, [carePlanData]);

  const handleSubmit = () => {
    dispatch(setCarePlanProperties(carePlanData));
    goToRxPage();
  };

  return (
    <div className="ptw-main-body">
      <Typography.Title level={3}>Care Plan</Typography.Title>

      <Typography.Text>
        Finalize your treatment plan for{' '}
        <strong>
          {v.capitalize(patient.FirstName)} {v.capitalize(patient.LastName)}.
        </strong>
      </Typography.Text>

      <CarePlanForm
        dispatch={dispatch}
        sendData={data => setCarePlanData(data)}
      />

      <Space direction="horizontal" className="stepper-container pull-right">
        <Button
          size="large"
          type="primary"
          className="ptw-btn btn-primary"
          disabled={!validated}
          onClick={handleSubmit}
        >
          Next <RightOutlined />
        </Button>
      </Space>
    </div>
  );
};

const mapStateToProps = state => ({
  patient: state.patients.currentPatient
});

export default withRouter(connect(mapStateToProps)(CarePlan));
