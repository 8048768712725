import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { Form, Select, Checkbox } from 'antd';
import FormInput from '../groups/Input';

import { requiredValidator } from './validators/patients.js';

const FormCheckbox = props => {
  const { input, label } = props;
  return (
    <Form.Item name={input.name}>
      <Checkbox onChange={input.onChange}>{label}</Checkbox>
    </Form.Item>
  );
};

const SetLocationForm = props => {
  const {
    initialValues,
    groupClinics: dropDownClinics,
    handleSubmit,
    rtmEnabled,
    visibleProfile,
  } = props;
  const [enableRTM, setEnableRTM] = useState(false);
  const [promptLocationPopup, setPromptLocationPopup] = useState(false);

  console.log('visibleProfile', visibleProfile);

  const handleRTM = () => {
    setEnableRTM(!enableRTM);
  };

  const handlePromptLocationPopup = () => {
    setPromptLocationPopup(!promptLocationPopup);
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      {Array.isArray(dropDownClinics) && dropDownClinics.length > 0 && (
        <Field
          name="Clinic"
          label="Location"
          placeholder="Select location"
          component={FormInput}
          type="select"
          isRequired
          validate={[requiredValidator]}
        >
          {dropDownClinics
            .filter(clinic => clinic.ClinicName !== 'null')
            .map(clinic => {
              return (
                <Select.Option value={clinic.Id} key={clinic.ClinicName}>
                  {clinic.ClinicName}
                </Select.Option>
              );
            })}
        </Field>
      )}

      {!!rtmEnabled && (
        <Field
          label="Enable RTM"
          name="EnableRTM"
          type="switch"
          checkedChildren="Yes"
          unCheckedChildren="No"
          component={FormInput}
          defaultChecked={initialValues.EnableRTM}
          onChange={handleRTM}
        />
      )}

      {(visibleProfile?.GroupInfo?.EnableLocationPopup === undefined ||
        !!visibleProfile?.GroupInfo?.EnableLocationPopup) && (
        <Field
          name="PromptLocationPopup"
          label="Use these defaults, don't show again."
          component={FormCheckbox}
          value={promptLocationPopup}
          onChange={handlePromptLocationPopup}
        />
      )}
    </Form>
  );
};

const mapsStateToProps = state => ({
  visibleProfile: state.visibleProfile,
});

export default connect(mapsStateToProps)(
  reduxForm({
    form: 'patient',
  })(withRouter(SetLocationForm))
);
