/* eslint-disable react-hooks/exhaustive-deps */
import './styles.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Modal, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons';

import MessageBoard from '../MessageBoard';
import states from '../../states';
import services from '../../services';

import { setActivity } from '../RTMTimer/mixins';

const MessageModal = ({
  open,
  onClose,
  sub,
  fromPatientProfile = false,
  history = null,
  rtmPatient = null,
}) => {
  const [board, setBoard] = useState(null);
  const [messages, setMessages] = useRecoilState(states.messages);
  const rx = useRecoilValue(states.prescription);

  const patient = useRecoilValue(states.patients);
  const user = useRecoilValue(states.user);
  const isMessagingEnabled =
    user.details.preferences &&
    user.details.preferences.messaging &&
    user.details.preferences.messaging === 'enabled';

  useEffect(() => {
    const list = messages.list;
    const current = list.find(item => item.Owner === sub);

    if (current) {
      setBoard(current);
    } else {
      addNewBoard();
    }

    return () => {
      setBoard(null);
      getBoards();
    };
  }, []);

  const addNewBoard = async () => {
    try {
      const owner = {
        sub: patient.details.sub,
        fname: patient.details.firstName,
        lname: patient.details.lastName,
      };

      const therapist = {
        sub: user.details.sub,
        fname: user.details.firstName,
        lname: user.details.lastName,
      };

      const response = await services.message.addNewBoard(owner, therapist);
      if (response.status === 201) {
        const { data } = response.data;

        await getBoards();
        setBoard(data.Attributes || data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBoards = async () => {
    try {
      const { role, sub } = user.details;
      await services.message.getBoards(role, sub).then(res => {
        if (res && Array.isArray(res.data) && res.data.length > 0) {
          setMessages({
            list: res.data,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = async () => {
    if (fromPatientProfile) {
      const isMultipleRx =
        !!rx.list.length &&
        rx.list.length > 1 &&
        history &&
        history.location.pathname.includes('/patients');

      if (isMultipleRx) {
        setActivity(rtmPatient, null);
      } else {
        setActivity(rtmPatient, 'View Adherence Log');
      }
    }

    onClose();
  };

  return (
    <Modal
      closable
      footer={null}
      visible={open}
      width={850}
      bodyStyle={{ height: 600 }}
      onCancel={handleClose}
    >
      {isMessagingEnabled ? (
        board ? (
          <div style={{ height: 460 }}>
            <MessageBoard id={board.Id} />
          </div>
        ) : (
          <div className="modal-warning-msg">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} />} />
          </div>
        )
      ) : (
        <div className="modal-warning-msg ">
          <Typography.Title level={3}>Messaging Disabled!</Typography.Title>

          <Typography.Text style={{ fontSize: 17 }}>
            Enable messaging in your <Link to={'/profile'}>Profile</Link> in
            order to send messages.
          </Typography.Text>

          <br />
          <FontAwesomeIcon
            style={{ fontSize: '70px', marginBottom: 48 }}
            icon={faCommentSlash}
          />
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = state => ({
  currentMonitoredPatient: state.monitorTimer.currentMonitoredPatient,
  isMonitoring: state.monitorTimer.isMonitoring,
  visibleProfile: state.visibleProfile,
});

export default connect(mapStateToProps)(MessageModal);
