import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const ProgramMenu = ({ formData, programs, otherProgram, onChange }) => {
  const renderPrograms = () => (
    <Menu>
      {Array.isArray(programs) &&
        programs.map(item => (
          <Menu.SubMenu key={item.Program} title={item.Program}>
            {item &&
              item.Diagnosis &&
              Array.isArray(item.Diagnosis) &&
              item.Diagnosis.map(subItem => (
                <Menu.Item
                  key={subItem.Id}
                  onClick={() =>
                    onChange({
                      target: {
                        action: 'CHANGE_PROGRAM',
                        value: {
                          id: subItem.Id,
                          diagnosis: subItem.Diagnosis
                        }
                      }
                    })
                  }
                >
                  {subItem.Diagnosis}
                </Menu.Item>
              ))}
          </Menu.SubMenu>
        ))}
      <Menu.Item
        key="other"
        onClick={() =>
          onChange({
            target: {
              action: 'CHANGE_PROGRAM',
              value: {
                id: null,
                diagnosis: 'Other'
              }
            }
          })
        }
      >
        Other
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown trigger={['click']} overlay={renderPrograms()}>
      <Button block>
        {formData.program_name !== null
          ? formData.program_name
          : otherProgram
          ? ''
          : 'Select Program'}{' '}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ProgramMenu;
