import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { Form, Input, Select, Button, Modal, Space, notification } from 'antd';

import { categories, formatCategory } from '../../../utils/educationalPdf';
import { toCamelCaseObjKeys } from '../../../utils/object.utils';

import services from '../../../services';
import states from '../../../states';

const EditPDFModal = ({ initialData, showModal, onClose, visibleProfile }) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const setPdf = useSetRecoilState(states.pdf);

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(toCamelCaseObjKeys(initialData));
    }
  }, [initialData]);

  const handleSubmit = async values => {
    try {
      setLoading(true);

      const { Role, Sub } = visibleProfile;
      const isSuperAdmin = Role === 'sa';
      const owner = isSuperAdmin ? '2667b98c-4e98-4cdb-b03a-959ed7a7f435' : Sub;

      const response = await services.educationalPdf.editPdf({
        Id: initialData.id,
        Owner: owner,
        Title: values.title,
        Category: values.category,
      });

      if (response.status === 200) {
        notification.success({
          message: 'Edit PDF Success',
          description: 'PDF details successfully updated.',
        });

        setPdf(prevState => ({
          ...prevState,
          list: [...prevState.list].map(item => {
            if (!item.data.some(pdf => pdf.id === initialData.id)) {
              return item;
            }

            return {
              category: item.category,
              data: [...item.data].map(pdf => {
                if (pdf.id !== initialData.id) {
                  return pdf;
                }

                return toCamelCaseObjKeys(response.data);
              }),
            };
          }),
        }));

        onClose();
      }
    } catch (error) {
      notification.error({
        message: 'Edit PDF Error',
        description: 'An error occurred while updating the PDF details.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      closable
      destroyOnClose
      centered
      maskClosable={false}
      open={showModal}
      onCancel={onClose}
      footer={null}
      title="Edit PDF"
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 8,
        paddingTop: 12,
      }}
    >
      <Form
        layout="vertical"
        form={form}
        onReset={onClose}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: 'Please enter PDF title.',
            },
          ]}
          style={{
            marginLeft: 24,
            marginRight: 24,
          }}
        >
          <Input type="text" placeholder="Enter your title" size="large" />
        </Form.Item>

        <Form.Item
          label="Category"
          name="category"
          rules={[
            {
              required: true,
              message: 'Please select a category.',
            },
          ]}
          style={{
            marginLeft: 24,
            marginRight: 24,
          }}
        >
          <Select placeholder="Select a category" showSearch size="large">
            {categories.map(category => (
              <Select.Option key={category} value={category}>
                {formatCategory(category)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          style={{
            textAlign: 'right',
            marginLeft: 24,
            marginRight: 24,
            marginBottom: 12,
          }}
        >
          <Space size="middle">
            <Button htmlType="reset">Cancel</Button>

            <Button type="primary" htmlType="submit" loading={loading}>
              Save Changes
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = states => ({
  visibleProfile: states.visibleProfile,
});

export default connect(mapStateToProps)(EditPDFModal);
