import { useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { GET_NEW_ANNOUNCEMENT } from '../graphql/subscrptions';

import states from '../states';

const AnnouncementSubscription = () => {
  const [admin, setAdmin] = useRecoilState(states.admin);

  const time = 24 * 60 * 60 * 1000;
  const date = Math.floor(Date.now() / time) * time;

  const { loading, data } = useSubscription(GET_NEW_ANNOUNCEMENT, {
    variables: {
      DateSent: date,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      const announcement = {
        ...data.onSendAnnouncement,
      };

      delete announcement['__typename'];

      console.log('announcement', announcement);
      setAdmin({
        ...admin,
        announcement,
      });
    }
  }, [loading, data]);

  return null;
};

export default AnnouncementSubscription;
