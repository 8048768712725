import { useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { GET_NEW_MESSAGE } from '../graphql/subscrptions';

import states from '../states';

const MessageSubscription = ({ sub }) => {
  const [messages, setMessages] = useRecoilState(states.messages);
  const { loading, data } = useSubscription(GET_NEW_MESSAGE, {
    variables: {
      to: sub
    }
  });

  useEffect(() => {
    if (!loading && data) {
      const boardId = data.onNewMessage.MessageBoardId;
      const newMessage = {
        ...data.onNewMessage
      };

      delete newMessage['MessageBoardId'];
      delete newMessage['__typename'];

      setMessages((prevState) => {
        const messageList = messages.list.slice();
        const index = messageList.findIndex((m) => m.Id === boardId);

        if (!messageList[index]) {
          return prevState;
        }

        const board = messageList[index];
        const updatedBoard = {
          ...board,
          Messages: [...board.Messages, newMessage],
          TherapistMessages: Number(board.TherapistMessages) + 1,
          TherapistRead: false
        };

        messageList.splice(index, 1);
        messageList.unshift(updatedBoard);

        return {
          ...prevState,
          list: messageList
        };
      });
    }
  }, [loading, data]);

  return null;
};

export default MessageSubscription;
