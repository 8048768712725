import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Popover, Table, Typography } from 'antd';
import {
  DislikeFilled,
  InfoCircleOutlined,
  LikeFilled,
  LoadingOutlined,
} from '@ant-design/icons';

import { EditableRow, EditableCell } from './EditableRow';

import cptCodes from './CptCodes.json';
import EditMultipleForm from './EditMultipleForm';
import states from '../../states';
import services from '../../services';

const defaultColumns = [
  {
    title: 'Exercise Name',
    dataIndex: 'exerciseName',
    key: 'exerciseName',
    width: '30%',
    render: val => (
      <Typography.Text style={{ textTransform: 'capitalize' }}>
        {val}
      </Typography.Text>
    ),
  },
  {
    title: 'Sets',
    dataIndex: 'sets',
    key: 'sets',
    editable: true,
  },
  {
    title: 'Reps',
    dataIndex: 'reps',
    key: 'reps',
    editable: true,
  },
  {
    title: 'Hold',
    dataIndex: 'hold',
    key: 'hold',
    editable: true,
    width: '12%',
  },
  {
    title: 'CPT Code',
    dataIndex: 'cptCode',
    key: 'cptCode',
    editable: true,
    width: '15%',
  },
  {
    title: 'Minutes',
    dataIndex: 'minutes',
    key: 'minutes',
    editable: true,
  },
  {
    title: (
      <React.Fragment>
        <LikeFilled style={{ fontSize: 16 }} />
        <DislikeFilled style={{ fontSize: 16, transform: 'scaleX(-1)' }} />
        <Popover
          title="Export/Not-export"
          content={
            <Typography.Text>
              <LikeFilled style={{ color: '#59c2ae' }} /> - Exercise will be
              included in export
              <br />
              <DislikeFilled style={{ color: '#f47373' }} /> - Exercise will{' '}
              <i>not</i> be included in export
            </Typography.Text>
          }
          placement="rightTop"
        >
          <InfoCircleOutlined className="export-info-icon" />
        </Popover>
      </React.Fragment>
    ),
    dataIndex: 'export',
    key: 'export',
    editable: true,
    width: '12%',
    render: val => {
      switch (val) {
        case 'YES':
          return <LikeFilled className="like-icon" />;
        case 'NO':
          return <DislikeFilled className="dislike-icon" />;
        default:
          break;
      }
    },
  },
];

const ExportHepToPrompt = props => {
  const { exercises, setHepData } = props;
  const exercisesState = useRecoilValue(states.exercises);

  const [initialDataSource, setIinitialDataSource] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [cptCodeList, setCptCodeList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getExercisesDetails().then(res => {
      const modifiedExercise = [];
      for (let i = 0; i < exercises.length; i++) {
        const item = exercises[i];
        const details = res.find(ex => ex?.Id === item.id);
        const parameter = exercisesState.custom.parameter;
        const params = parameter.find(p => p.ExerciseId === item.id);
        const custom = exercisesState.custom.exercises.find(
          ex => ex.Id === item.id
        );

        modifiedExercise.push({
          ...item,
          key: item.id,
          cptCode:
            params?.CPTCode || custom?.CPTCode || details?.CPTCode || null,
          export: item.export || 'YES',
        });
      }

      setIinitialDataSource([...modifiedExercise]);
      setDataSource([...modifiedExercise]);
    });
  }, []);

  useEffect(() => {
    setCptCodeList([...cptCodes]);
  }, []);

  useEffect(() => {
    setHepData(dataSource);
  }, [dataSource]);

  const getExercisesDetails = async () => {
    try {
      setLoading(true);
      const response = await Promise.all(
        exercises.map(ex => services.exercises.getExerciseDetails(ex.id))
      );

      return response.map(res => res.data);
    } catch (error) {
      return [];
    } finally {
      setLoading(false);
    }
  };

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleUpdateSelectedItems = e => {
    if (e.target.value === '') {
      return;
    }

    setDataSource(prevState => {
      return prevState.map(row => {
        const parameter = exercisesState.custom.parameter;
        const params = parameter.find(p => p.ExerciseId === row.id);
        const custom = exercisesState.custom.exercises.find(
          ex => ex.Id === row.id
        );

        const data = {
          ...row,
          cptCode: params?.CPTCode || custom?.CPTCode || null,
        };

        if (selectedRowKeys.includes(data.key)) {
          if (e.target.name === 'export' && e.target.value === 'NO') {
            return {
              ...data,
              cptCode: null,
              minutes: null,
              [e.target.name]: e.target.value,
            };
          }

          return {
            ...data,
            [e.target.name]: e.target.value,
          };
        }

        return data;
      });
    });
  };

  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      handleUpdateSelectedItems(e);
    }
  };

  const handleSave = row => {
    const updatedDataSource = [...dataSource];
    const rowIndex = updatedDataSource.findIndex(item => row.key === item.key);
    const item = updatedDataSource[rowIndex];
    updatedDataSource.splice(rowIndex, 1, { ...item, ...row });
    setDataSource(updatedDataSource);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        cptCodeList,
        handleSave,
      }),
    };
  });

  return (
    <div
      style={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 300px)',
      }}
    >
      {selectedRowKeys.length !== 0 && (
        <EditMultipleForm
          selectedRowKeys={selectedRowKeys}
          cptCodeList={cptCodeList}
          handleUpdateSelectedItems={handleUpdateSelectedItems}
          handleKeyPress={handleKeyPress}
        />
      )}
      <div className="export-hep-table">
        <Table
          size="small"
          loading={{
            size: 'large',
            spinning: loading,
            indicator: (
              <LoadingOutlined
                style={{
                  fontSize: 40,
                }}
              />
            ),
          }}
          components={components}
          rowSelection={rowSelection}
          rowClassName={() => 'editable-row'}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{
            x: 'max-content',
          }}
        />
      </div>
    </div>
  );
};

export default ExportHepToPrompt;
