import React, { Fragment, useState } from 'react';
import { Button, Space } from 'antd';
import { delay } from './mixins';

import moment from 'moment';
import BillingCard from './BillingCard';
import LoadingPage from '../../../../../components/LoadingPage';
import NoResults from '../../../../../components/NoResults';

export default function BillingLog({
  rtmBillingData,
  getBillingDocument,
  billingDocument,
  refreshBillingList,
  patient
}) {
  const [loading, setLoading] = useState(false);
  const [showPrevCodes, setShowPrevCodes] = useState(false);

  const handleShowPrevCodes = async () => {
    setLoading(true);
    await delay(1000).then(() => {
      setLoading(false);
      setShowPrevCodes(!showPrevCodes);
    });
  };

  const handleRefreshBillingList = (updated) => {
    refreshBillingList(updated);
  };

  const currCodes = rtmBillingData?.currCodes || [];
  const prevCodes = rtmBillingData?.prevCodes || [];
  const billCodes = showPrevCodes ? prevCodes : currCodes;

  if (loading) {
    return <LoadingPage type="list" />;
  }

  return (
    <div
      style={{
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5
      }}
    >
      {prevCodes && prevCodes.length > 0 && (
        <Space
          style={{
            marginBottom: '1rem'
          }}
        >
          <Button type="primary" onClick={handleShowPrevCodes}>
            {showPrevCodes
              ? 'Back to Current Plan of Care'
              : 'Show Past Plans of Care'}
          </Button>
        </Space>
      )}

      {!billCodes.length ? (
        <NoResults content="Billable codes will show up here once requirements are met!" />
      ) : (
        <div className="patient-detail-tab-billing-container">
          {billCodes.map((data, i) => {
            const hours = 23 * 60 * 60 * 1000;
            const minutes = 59 * 60 * 1000;
            const seconds = 59 * 1000;

            let startQuery = data.Start;
            let endQuery = data.End + hours + minutes + seconds;

            if (data.Code === 98980 || data.Code === 98981) {
              const offset = new Date().getTimezoneOffset();

              startQuery = startQuery + offset * 60000;
              endQuery = endQuery + offset * 60000;
            }

            const otherCodes = billCodes.filter((c) => c.Id !== data.Id);
            const hasPending = otherCodes.some((c) => c.Status === 'marked');

            return (
              <Fragment key={i}>
                <BillingCard
                  date={`${moment(data.Start).format('MMMM D')} - ${moment(
                    data.End
                  ).format('MMMM D, YYYY')}`}
                  bill_id={data.Id}
                  bill_number={data.Code}
                  bill_date={data.BillDate}
                  billed_by={data.BilledBy}
                  status={data.Status}
                  currentPeriod={data.CurrentPeriod}
                  getBillingDocument={() =>
                    getBillingDocument(
                      data.Id,
                      data.Code,
                      data.Status,
                      data.Start,
                      data.End,
                      hasPending
                    )
                  }
                  billingDocument={billingDocument}
                  refreshBillingList={handleRefreshBillingList}
                  patient={patient}
                  isPastCode={showPrevCodes}
                />
              </Fragment>
            );
          })}
        </div>
      )}

      <div className="patient-detail-tab-pagination" />
    </div>
  );
}
