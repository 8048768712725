import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, ButtonGroup } from 'react-bootstrap';
import { connect, createLocalTracks } from 'twilio-video';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophoneSlash,
  faCompressArrowsAlt,
  faPhoneSlash,
  faVideo,
  faExpand
} from '@fortawesome/free-solid-svg-icons';
import { getVideoRoom, notificationVideoRoom } from './actions';
import('./videoPlayer.css');
const GET_VIDEO_ROOM_FULFILLED = 'GET_VIDEO_ROOM_FULFILLED';
import TranslateText from '../../I18n/TranslateText';

import services from '../../services';
import isEmail from 'validator/lib/isEmail';

const minimizeMedia = 'minimize-media';
const mainMedia = 'main-media';
const remoteMedia = 'remote-media';
const voiceMedia = 'voice-media';

export default function (props) {
  const {
    patient,
    dispatch,
    groupId,
    locale,
    onHide,
    patientContact,
    ptFirstName,
    ptLastName,
    role,
    showVideo,
    therapist
  } = props;

  const localResources = useRef(null);
  const [minimize, setMinimize] = useState(false);
  const [videoOn, setVideoOn] = useState(false);
  const [localAudio, setLocalAudio] = useState(null);
  const [localVideo, setLocalVideo] = useState(null);
  const [remoteAudio, setRemoteAudio] = useState(null);
  const [remoteVideo, setRemoteVideo] = useState(null);
  const [callStarted, setCallStarted] = useState(false);
  const [mute, setMute] = useState(false);
  const [blind, setBlind] = useState(false);
  const [invalidBrowser, setInvalidBrowser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [provider, setProvider] = useState(therapist);

  useEffect(() => {
    if (therapist && !isEmail(therapist)) {
      getProviderEmail();
    }
  }, [therapist]);

  const getProviderEmail = async () => {
    try {
      setFetching(true);

      const response = await services.user.getDetails('sub', therapist);
      if (response.status === 200) {
        setProvider(response.data.EmailAddress);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  /**
   * Disconnect from room
   */
  const disconnect = () => {
    if (localResources.current) {
      console.log('disconnect on dismount', localResources);
      localResources.current.disconnect();
      localResources.current = null;
    }

    if (localVideo) {
      localVideo.stop();
      removeTrackFromDom(localVideo);
    }

    if (localAudio) {
      localAudio.stop();
      removeTrackFromDom(localAudio);
    }

    if (remoteVideo) {
      removeTrackFromDom(remoteVideo);
    }

    if (remoteAudio) {
      removeTrackFromDom(remoteAudio);
    }

    removeVideoContainerChildren(mainMedia);

    removeVideoContainerChildren(remoteMedia);

    setLocalVideo(null);
    setLocalAudio(null);

    setVideoOn(false);
    setCallStarted(false);
    setMinimize(false);
  };
  /**
   * Disconnect on cleanup
   */

  useEffect(() => {
    return () => {
      if (localResources.current) {
        localResources.current.disconnect();
        localResources.current = null;
      } else {
        disconnect();
      }
    };
  }, []);

  /**
   * Begin Call sequence
   * Request Token
   * Connect To room
   * Handle users
   */
  const startCall = async tracks => {
    setCallStarted(true);
    dispatch(getVideoRoom(patient, provider, role))
      .then(response => {
        const {
          action: { type },
          value: {
            token,
            room: { RoomName }
          }
        } = response;
        console.log('vid', response);
        if (type === GET_VIDEO_ROOM_FULFILLED) {
          connectToRoom(token, RoomName, tracks);
        } else {
          console.log('something went wrong');
          console.log(response);
        }
      })
      .catch(err => {
        console.log(err);
        alert(err);
        onHide();
      });
  };

  /**
   * Connect to Room and handle people joining
   * @param accessToken
   */
  const connectToRoom = (accessToken, roomName, tracks) => {
    connect(accessToken, {
      name: roomName,
      tracks: tracks
    }).then(room => {
      console.log(`Connected to Room`);

      setLoading(false);

      localResources.current = room;

      const { participants } = room;

      participants.forEach(participant => {
        participant.on('trackStarted', track => {
          if (track.kind === 'video') {
            removeVideoContainerChildren(mainMedia);
            removeVideoContainerChildren(minimizeMedia);

            attachVideoToDom(minimizeMedia, track);
            attachVideoToDom(mainMedia, track);
            setRemoteVideo(track);
          } else {
            setRemoteAudio(track);
            attachVoiceToDom(track);
          }
        });
      });

      room.on('reconnected', something => {
        console.log('reconnected', something);
      });

      /**
       * Disconnect
       */
      // Log Participants as they disconnect from the Room
      room.once('participantDisconnected', participant => {
        console.log(
          `Participant "${participant.identity}" has disconnected from the Room`
        );

        // tracks.forEach(track=>removeTrackFromDom(track.track))
        removeVideoContainerChildren(mainMedia);
        removeVideoContainerChildren(minimizeMedia);
        removeVideoContainerChildren(voiceMedia);
        setRemoteVideo(null);
      });

      /**
       * Participant Connect
       */
      room.on('participantConnected', participant => {
        console.log(`Participant "${participant.identity}" connected`);

        /**
         * Add Current Members
         */
        participant.tracks.forEach(publication => {
          if (publication.isSubscribed) {
            const track = publication.track;
            if (track.kind === 'video') {
              removeVideoContainerChildren(mainMedia);
              removeVideoContainerChildren(minimizeMedia);

              setRemoteVideo(track);

              attachVideoToDom(mainMedia, track);
              attachVideoToDom(minimizeMedia, track);
            } else {
              setRemoteAudio(track);
              attachVoiceToDom(track);
            }
          }
        });

        /**
         * Add New Members
         */
        participant.on('trackSubscribed', track => {
          if (track.kind === 'video') {
            removeVideoContainerChildren(mainMedia);
            removeVideoContainerChildren(minimizeMedia);
            setRemoteVideo(track);
            attachVideoToDom(mainMedia, track);
            attachVideoToDom(minimizeMedia, track);
          } else {
            setRemoteAudio(track);
            attachVoiceToDom(track);
          }
        });
      });
    });
  };

  /**
   * Retrieve Tracks
   * @param content
   * @param width
   * @returns {Promise}
   */
  const createVideoStream = () => {
    return new Promise((resolve, reject) => {
      createLocalTracks({
        audio: true,
        video: {}
      })
        .then(localTracks => {
          const [audioTrack, videoTrack] = localTracks;

          setLocalAudio(audioTrack);
          setLocalVideo(videoTrack);

          attachVideoToDom(remoteMedia, videoTrack);

          resolve(localTracks);
        })
        .catch(err => reject(err));
    });
  };

  /**
   * Init video preview
   */
  const initStreams = () => {
    return createVideoStream();
  };
  /**
   * Start Video
   */
  const startVideo = () => {
    if (groupId) {
      dispatch(
        notificationVideoRoom(patientContact, groupId, ptFirstName, ptLastName)
      );
    }

    setVideoOn(true);
    initStreams()
      .then(response => {
        startCall(response);
      })
      .catch(err => {
        console.log(err);
      });
  };
  /**
   * Mute user voice
   */
  const handleMute = () => {
    if (mute) {
      localAudio.enable();
      setMute(false);
    } else {
      localAudio.disable();
      setMute(true);
    }
  };
  /**
   * Disable  user video
   */
  const handleBlind = () => {
    if (blind) {
      localVideo.enable();
      setBlind(false);
    } else {
      localVideo.disable();
      setBlind(true);
    }
  };
  /**
   * Handler for minimize functionality
   */
  const handleMinimize = () => {
    setMinimize(!minimize);
  };
  const attachVoiceToDom = track => {
    const element = document.getElementById(voiceMedia);
    element.appendChild(track.attach());
  };
  /**
   * Atttach Video to DOM
   * @param selector
   * @param track
   */
  const attachVideoToDom = (selector, track) => {
    if (!track) {
      return;
    }
    const element = document.getElementById(selector);
    element.appendChild(track.attach());
  };
  /**
   * Remove Voice or Video Binding
   * NOTE: Does not remove video player html
   * @param track
   */
  const removeTrackFromDom = track => {
    const trackItem = track.track ? track.track : track;
    const attachedElements = trackItem.detach();
    attachedElements.forEach(element => element.remove());
  };
  /**
   * Removes Video HTML from dom for given selector
   * @param selector
   */
  const removeVideoContainerChildren = selector => {
    const element = document.getElementById(selector);
    if (element) {
      element.innerHTML = '';
    } else {
      return;
    }

    const children = element.children;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      console.log(children[i]);
      element.removeChild(child);
    }
  };

  /**
   * Checks for IE or Edge
   */
  useEffect(() => {
    const ua = window.navigator.userAgent;
    const chrome = ua.indexOf('Chrome');
    const safari = ua.indexOf('Safari');
    const firefox = ua.indexOf('Fire');
    if (chrome === -1 && safari === -1 && firefox === -1) {
      setInvalidBrowser(false);
    }
  }, []);

  // return (
  //   <Drawer
  //     title="Video Call"
  //     placement="bottom"
  //     onClose={onHide}
  //     visible={showVideo}
  //     height="100%"
  //   >
  //     </Drawer>
  // );

  return (
    <div
      style={
        minimize
          ? {
              width: '25%',
              position: 'fixed',
              zIndex: 999999,
              left: 0,
              bottom: 0,
              display: showVideo ? 'block' : 'none',
              backgroundColor: 'black'
            }
          : {
              width: '99%',
              top: 15,
              left: 7,
              display: showVideo ? 'block' : 'none',
              position: 'fixed',
              zIndex: 999999,
              backgroundColor: 'white',
              borderWidth: '2px',
              borderColor: 'lightgrey',
              borderStyle: 'solid',
              borderRadius: 15,
              paddingBottom: 15
            }
      }
    >
      {callStarted && (
        <div>
          <ButtonGroup
            style={{ float: 'right', marginRight: 15, marignTop: 5 }}
          >
            <Button onClick={handleMute}>
              {!mute ? (
                <FontAwesomeIcon icon={faMicrophoneSlash} />
              ) : (
                <FontAwesomeIcon
                  style={{ color: 'red' }}
                  icon={faMicrophoneSlash}
                />
              )}
              {!minimize && (
                <text style={mute ? { color: 'red' } : {}}>&nbsp;Mute</text>
              )}
            </Button>

            <Button onClick={handleBlind}>
              {!blind ? (
                <FontAwesomeIcon icon={faVideo} />
              ) : (
                <FontAwesomeIcon style={{ color: 'red' }} icon={faVideo} />
              )}
              {!minimize && (
                <text style={blind ? { color: 'red' } : {}}>
                  &nbsp;Hide Video
                </text>
              )}
            </Button>

            <Button onClick={handleMinimize}>
              {!minimize ? (
                <FontAwesomeIcon icon={faCompressArrowsAlt} />
              ) : (
                <FontAwesomeIcon icon={faExpand} />
              )}
              {!minimize && <text>&nbsp;Minimize</text>}
            </Button>

            <Button
              onClick={() => {
                onHide();
                disconnect();
              }}
              style={{ color: 'red', borderColor: 'red' }}
            >
              <FontAwesomeIcon icon={faPhoneSlash} />
              {!minimize && (
                <text style={{ color: 'red' }}>&nbsp;End Call</text>
              )}
            </Button>
          </ButtonGroup>
        </div>
      )}
      {invalidBrowser && (
        <div>
          <Col xs={12} style={{ padding: '15px' }}>
            <Button onClick={() => onHide()} style={{ float: 'right' }}>
              <text>Close</text>
            </Button>
          </Col>
          <Col xs={8} xsOffset={4} style={{ padding: '15px', float: 'left' }}>
            <h3 style={{ margin: 'auto' }}>Your browser is not supported</h3>
          </Col>
          <Col xs={6} xsOffset={4} style={{ padding: '15px', float: 'left' }}>
            <p>Please Use Chrome, Firefox, or Safari to join a Video Call</p>
          </Col>
          <Col xs={6} xsOffset={4} style={{ padding: '15px', float: 'left' }}>
            <a href={'https://www.google.com/chrome/'} target={'_blank'}>
              Chrome Download Page
            </a>
          </Col>
          <Col xs={6} xsOffset={4} style={{ padding: '15px', float: 'left' }}>
            <a
              href={'https://www.mozilla.org/en-US/firefox/new/'}
              target={'_blank'}
            >
              Firefox Download Page
            </a>
          </Col>
          <Col xs={6} xsOffset={4} style={{ padding: '15px', float: 'left' }}>
            <a
              href={'https://support.apple.com/downloads/safari'}
              target={'_blank'}
            >
              Safari Download Page
            </a>
          </Col>
        </div>
      )}
      {!videoOn && !invalidBrowser && (
        <div>
          <div>
            <Col xs={12} style={{ padding: '15px' }}>
              <Button onClick={() => onHide()} style={{ float: 'right' }}>
                <text>
                  <TranslateText
                    currLocale={role === 'p' ? locale : 'en'}
                    currText={'Close'}
                  />
                </text>
              </Button>
            </Col>
            <Col xs={4} xsOffset={5} style={{ padding: '15px', float: 'left' }}>
              <h3 style={{ margin: 'auto' }}>
                {' '}
                <FontAwesomeIcon icon={faVideo} /> &nbsp;
                <TranslateText
                  currLocale={role === 'p' ? locale : 'en'}
                  currText={'Video Call'}
                />
              </h3>
            </Col>
            <Col xs={6} xsOffset={3} style={{ padding: '15px', float: 'left' }}>
              {role === 'p' ? (
                <h4 style={{ textAlign: 'center' }}>
                  <TranslateText
                    currLocale={locale}
                    currText={
                      'Click Below To Join A Video Call With Your Provider!'
                    }
                  />
                </h4>
              ) : (
                <h4 style={{ textAlign: 'center' }}>
                  Click below to begin a video call with this patient!
                </h4>
              )}
              {role === 'p' ? (
                <TranslateText
                  currLocale={locale}
                  currText={
                    'If you have recently received an email notification that your provider has invited you to a video call, click below to join.'
                  }
                />
              ) : (
                <p>
                  After selecting 'Start Video Call', the patient will be sent
                  and email notifying them that you will have initiated a video
                  call. They will be given a link to login to the web
                  application. Upon logging in, they will be able to select
                  'Video Call' under the 'More' tab to join the call.
                </p>
              )}
              <p>
                <em>NOTE:</em>
              </p>
              {role === 'p' ? (
                <div>
                  <p>
                    <TranslateText
                      currLocale={locale}
                      currText={
                        '- This is not a communication channel to initiate a video call with your provider. You should only access this page if your provider has already initiated a video call with and you have been notified via email.'
                      }
                    />
                  </p>
                  <p>
                    <TranslateText
                      currLocale={locale}
                      currText={
                        '- During the call, do not use any of the buttons below (Exercises, Awards, etc.). Clicking one of these buttons will end the call with your provider.'
                      }
                    />
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    - Patients will only be able to join calls through the web
                    app. They will not be able to join the call on the mobile
                    app.
                  </p>
                  <p>
                    - There is currently no way to schedule calls, so please use
                    the Messaging system or other platforms to organize call
                    sessions.
                  </p>
                </div>
              )}
            </Col>
            <Col xs={3} xsOffset={5} style={{ padding: '15px' }}>
              <Button
                disabled={fetching}
                onClick={() => startVideo()}
                style={{ float: 'left', margin: 'auto', lineHeight: 1 }}
              >
                <FontAwesomeIcon style={{ fontSize: 20 }} icon={faVideo} />
                &nbsp; &nbsp;
                <text style={{ fontSize: 20 }}>
                  <TranslateText
                    currLocale={role === 'p' ? locale : 'en'}
                    currText={
                      fetching ? 'Initializing Call..' : 'Start Video Call'
                    }
                  />
                </text>
              </Button>
            </Col>
            <Col xs={6} xsOffset={3} style={{ padding: '15px' }}>
              <h4 style={{ margin: 'auto' }}>
                <TranslateText
                  currLocale={role === 'p' ? locale : 'en'}
                  currText={'Disclaimer'}
                />
              </h4>
              <p>
                <TranslateText
                  currLocale={role === 'p' ? locale : 'en'}
                  currText={
                    'By using the Video Call feature, you acknowledge the potentialrisks associated with telehealth services. These include, butare not limited to, the following: information transmitted maynot be sufficient to allow appropriate medical or health caredecision making by the Provider (for example, poor resolution ofimages), delays in evaluation or treatment could occur due to failures of electronic equipment, a lack of access to complete medical records may result in judgement errors; although the electronic systems we use incorporate network and software security protocols to protect the privacy and security of health information, those protocols could fail causing a breach of privacy of your health information.'
                  }
                />
              </p>
            </Col>
          </div>
        </div>
      )}
      <div
        style={
          videoOn
            ? { display: 'block', alignContent: 'center' }
            : { display: 'none' }
        }
      >
        <div
          style={{ display: minimize ? 'block' : 'none' }}
          id={'minimize-media'}
        ></div>

        <Col xs={9}>
          {!remoteVideo && !minimize && (
            <div>
              {!loading ? (
                <div>
                  <p style={{ textAlign: 'center' }}>
                    You are the only one in the room.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    Make sure your appointment time is correct and the other
                    party is aware of your Video Call.
                  </p>
                </div>
              ) : (
                <i className="fa fa-spinner fa-pulse fa-fw text-icon" />
              )}
            </div>
          )}
          <div id={'voice-media'}></div>
          <div
            style={{ display: minimize ? 'none' : 'block' }}
            id={'main-media'}
          ></div>
        </Col>
        <Col xs={3}>
          <div
            style={{ display: minimize ? 'none' : 'block' }}
            id={'remote-media'}
          ></div>
        </Col>
      </div>
    </div>
  );
}
