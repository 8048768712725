import {
  SEARCH_CLINICS,
  GET_CLINICS,
  GET_CLINIC,
  POST_CLINIC,
  UPDATE_CLINIC,
  OPEN_CLINIC_FORM_MODAL,
  CLOSE_CLINIC_FORM_MODAL,
  OPEN_CLINIC_SUCCESS_MODAL,
  CLOSE_CLINIC_SUCCESS_MODAL,
  OPEN_CLINIC_CONFIRMATION_MODAL,
  CLOSE_CLINIC_CONFIRMATION_MODAL,
  UPDATE_CURRENT_CLINIC,
  SET_CLINIC_FORM_TYPE,
  defaultClinic
} from '../actions/clinics'
import { FormTypes } from "../actions/groups";
import { FULFILLED, PENDING, REJECTED } from 'redux-promise-middleware'
import { RESET_CLINICS, DONE_LOADING, IS_LOADING } from '../actions/clinicsLoadingState'

import mapModel from '../mapper'
import {GET_CLINIC_DETAILS} from "../../mobile/actions/patient";

const defaultState = {
  currentClinic: defaultClinic,
  formType: FormTypes.NEW,
  formModalOpen: false,
  successModalOpen: false,
  confirmationModalOpen: false,
  isFetching: false,
  fetched: false,
  isSubmitting: false,
  submitted: false,
  isSearching: false,
  items: {},
}

export const clinicsReducer = (state = defaultState, action) => {
  const { payload } = action
  let mapped

  switch (action.type) {

    case `${GET_CLINIC_DETAILS}_${FULFILLED}`:

      return {
        ...state,
        currentClinic:payload,
      };

    case RESET_CLINICS:
      return {
        ...defaultState
      }
    case DONE_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      }

    case `${SEARCH_CLINICS}_${PENDING}`:
      return {
        ...state,
        isFetching: false,
        isSearching: true,
        fetched: true,
      }
    case `${SEARCH_CLINICS}_${FULFILLED}`:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        isSearching: true,
        items: payload
      }

    case `${GET_CLINICS}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      }

    case `${GET_CLINICS}_${FULFILLED}`:

      return {
        ...state,
        isFetching: false,
        fetched: true,
        isSearching: false,
        items: payload
      }

    case `${GET_CLINIC}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      }

    case `${GET_CLINIC}_${FULFILLED}`:
      mapped = mapModel('clinic', payload.clinic, false)
      return {
        ...state,
        isFetching: false,
        fetched: true,
        items: {
          ...state.items,
          [mapped.clinicName]: mapped
        }
      }

    case `${GET_CLINIC}_${REJECTED}`:
      return {
        ...state,
        isFetching: false
      }

    case `${POST_CLINIC}_${PENDING}`:
      return {
        ...state,
        isSubmitting: true,
        submitted: false
      }

    case `${POST_CLINIC}_${FULFILLED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: true
      }

    case `${POST_CLINIC}_${REJECTED}`:
      return {
        ...state,
        isSubmitting: false
      }

    case `${UPDATE_CLINIC}_${PENDING}`:
      return {
        ...state,
        isSubmitting: true,
        submitted: false
      }

    case `${UPDATE_CLINIC}_${FULFILLED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: true
      }

    case `${UPDATE_CLINIC}_${REJECTED}`:
      return {
        ...state,
        isSubmitting: false
      }
    case OPEN_CLINIC_FORM_MODAL:
      return {
        ...state,
        formModalOpen: true
      }

    case CLOSE_CLINIC_FORM_MODAL:
      return {
        ...state,
        formModalOpen: false
      }

    case OPEN_CLINIC_SUCCESS_MODAL:
      return {
        ...state,
        successModalOpen: true
      }

    case CLOSE_CLINIC_SUCCESS_MODAL:
      return {
        ...state,
        successModalOpen: false
      }

    case OPEN_CLINIC_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModalOpen: true
      }

    case CLOSE_CLINIC_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModalOpen: false
      }

    case SET_CLINIC_FORM_TYPE:
      return {
        ...state,
        formType: payload.formType
      }

    case UPDATE_CURRENT_CLINIC:
      return {
        ...state,
        currentClinic: payload.values
      }

    default:
      return state
  }
}