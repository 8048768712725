import React, { useState } from 'react';
import { Form, Input, Typography } from 'antd';
import { WarningFilled } from '@ant-design/icons';

const TextInput = props => {
  const {
    onChange,
    type,
    name,
    value,
    label,
    placeholder,
    isRequired = false,
    validateEmail = false,
    capsLockOn = false,
    ...rest
  } = props;

  const [inputType, setInputType] = useState('text');

  const rules = [
    {
      required: isRequired,
      message: `${label} is required.`
    }
  ];

  if (validateEmail) {
    rules.push({
      type,
      message: `${label} is not valid.`
    });
  }

  const formatInput = (type, value) => {
    if (type === 'tel') {
      const number = value.replace(/\D/g, '');
      const output = `(${number.substring(0, 3)}) ${number.substring(
        3,
        6
      )} - ${number.substring(6, 10)}`;

      onChange(output);
    } else {
      onChange(value.trim());
    }
  };

  const handleInputChange = e => {
    const value = e.target.value;

    if (value.length > 2 && inputType === 'tel') {
      formatInput('tel', value);
    } else {
      formatInput('text', value);
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Backspace' || (e.ctrlKey && e.key === 'a')) {
      setInputType('text');
      return;
    }

    const value = e.target.value;
    const areNumbers =
      value.length > 0
        ? value
            .trim()
            .replace(/[()\s\-]/g, '')
            .split('')
            .every(c => !isNaN(c))
        : false;

    onChange(value);

    if (areNumbers) {
      setInputType('tel');
    } else {
      setInputType('text');
    }
  };

  const handleInputPaste = e => {
    e.preventDefault();
    const value = e.clipboardData.getData('Text');

    if (!/\s/g.test(value) && /^\d+$/.test(value)) {
      formatInput('tel', value);
    } else {
      formatInput('text', value);
    }
  };

  return (
    <React.Fragment>
      {capsLockOn && (
        <Typography.Text className="caps-lock-warning" type="warning">
          <WarningFilled /> CAPS lock is on
        </Typography.Text>
      )}
      <Form.Item label={label} name={name} rules={rules}>
        {type !== 'password' ? (
          <Input
            size="large"
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            onPaste={handleInputPaste}
            {...rest}
          />
        ) : (
          <Input.Password
            size="large"
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e)}
            {...rest}
          />
        )}
      </Form.Item>
    </React.Fragment>
  );
};

export default TextInput;
