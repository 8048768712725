import React, { Fragment } from 'react';
import { Row, Col, Button, Tooltip } from 'antd';
import {
  AppstoreOutlined,
  BarsOutlined,
  FilterOutlined,
  PicCenterOutlined,
} from '@ant-design/icons';
import SearchField from '../../SearchField';

const ExerciseAction = ({
  isEditing,
  handleSearch,
  searchTerm,
  displayFilter,
  handleDisplayFilter,
  currentView,
  setCurrentView,
  handleSelectionPanel,
}) => {
  return (
    <Fragment>
      <div className="exercise-header-controls-mb hide-dt flex-lg-tablet mb-2">
        <SearchField
          placeholder="Search exercise..."
          handleSearch={handleSearch}
          value={searchTerm}
        />
        <Tooltip title="Filters">
          <Button
            shape="circle"
            className="btn-default"
            icon={<FilterOutlined style={{ fontSize: 18 }} />}
            onClick={() => handleDisplayFilter(!displayFilter)}
          />
        </Tooltip>
        <Tooltip title="Exercises View">
          <Button
            className="btn-default pull-right"
            shape="circle"
            icon={
              currentView === 'card' ? (
                <AppstoreOutlined style={{ fontSize: 18 }} />
              ) : (
                <BarsOutlined style={{ fontSize: 18 }} />
              )
            }
            onClick={() =>
              setCurrentView(prevView =>
                prevView === 'card' ? 'list' : 'card'
              )
            }
          />
        </Tooltip>
        {isEditing && (
          <Tooltip title="Selected Exercises">
            <Button
              className="hide-dt show-sm-tablet btn-default pull-right ml-1"
              shape="circle"
              icon={<PicCenterOutlined style={{ fontSize: 18 }} />}
              onClick={handleSelectionPanel}
            />
          </Tooltip>
        )}
      </div>
      <Row
        gutter={[16, 16]}
        className="exercise-header-controls-dt flex-dt hide-lg-tablet mb-3"
      >
        <Col lg={16} md={16} sm={24} xs={24}>
          <SearchField
            placeholder="Search exercise..."
            handleSearch={handleSearch}
            value={searchTerm}
          />
        </Col>
        <Col lg={8} md={8} sm={24} xs={24}>
          <Tooltip title="Filters">
            <Button
              shape="circle"
              className="btn-default"
              icon={<FilterOutlined style={{ fontSize: 18 }} />}
              onClick={() => handleDisplayFilter(!displayFilter)}
            />
          </Tooltip>
          <Tooltip title="Exercises View">
            <Button
              className="btn-default pull-right"
              shape="circle"
              icon={
                currentView === 'card' ? (
                  <AppstoreOutlined style={{ fontSize: 18 }} />
                ) : (
                  <BarsOutlined style={{ fontSize: 18 }} />
                )
              }
              onClick={() =>
                setCurrentView(prevView =>
                  prevView === 'card' ? 'list' : 'card'
                )
              }
            />
          </Tooltip>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ExerciseAction;
