import React,{Component} from "react";
import {List, Avatar} from "antd";
import MessageInput from ".//MessageInput";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {MessageList, ChatItem} from "react-chat-elements";
import {trim} from 'lodash';
import './messaging.css';
import {Row, Col, Button} from 'react-bootstrap';
import {
    BOARD_READ,
    SEND_MESSAGE,
    SOCKET_BOARDS_RECEIVE,
    SOCKET_MESSAGE_RECEIVE
} from "../../../DispatchSocket/socketEvents";
import {
    GET_MESSAGES,
    setOnMessage,
    socketMessageSend,
    setNewMessageBadge,
} from "../../../DispatchSocket/WebSocketAction";

import { format} from 'timeago.js';
import {hideNavBar} from "../actions/patient";
//css
const listStyle={height:"50vh", overflow: "scroll"};
const listStyleFocus={height:"50vh", overflow: "scroll"};
const inputStyle={marginTop:"5px"};
const containerStyle={backgroundColor:'white', borderRadius:'30px', paddingBottom:'45px' };
const containerFocus={backgroundColor:'white'};
const avatarStyle={height:"90px", width:"90px", fontSize:'50px', padding:'20px'};
const headerStyle={backgroundColor:"whitesmoke"};
const buttonStyle={borderRadius:'0px', border:'1px solid #808080', color:'#808080', backgroundColor:'whitesmoke'};
const headingStyle={padding:'15px', textAlign:'center'};
const colStyle={};
const nameStyle={overflow:'hidden', verticalAlign:'middle', lineHeight:'2.5'};
//css
class Messaging extends Component{

    constructor(props){
        super(props);
        this.state={
            messages:[],
            focus: false,
            board: null,
            boards:[],
            hasInitiated:false,
            isMounted: false,
            loading:true,
            text:'',
            message:'',
            notSelected:true,
        }
        this.onMessage = this.onMessage.bind(this);
    }

    componentWillUnmount() {
        const {props:{dispatch}} = this;
        dispatch(setOnMessage(null));
    }

    componentDidMount() {
        const {props:{dispatch}} = this;
        try {
            dispatch(socketMessageSend(this.generateGetMessagePayload()), this.setState({isMounted: true}));
        }catch(err){
            setTimeout(()=>{
                this.setState({loading:true});
                dispatch(socketMessageSend(this.generateGetMessagePayload()), this.setState({isMounted: true, loading:false}));
            }, 5000);
        }
    }

    componentDidUpdate(prevProps,prevState,snapshot) {
        const {props:{dispatch, socket:{websocket}}, state:{hasInitiated}, onMessage, generateGetMessagePayload} = this;

        if(!hasInitiated && websocket){
            this.setState({hasInitiated: true});
            dispatch(setOnMessage(onMessage));
            dispatch(socketMessageSend(generateGetMessagePayload()));
        }
    }

    generateGetMessagePayload = ()=>{
        const {visibleProfile:{EmailAddress, GroupId, Role}} = this.props;
        return {User: EmailAddress, Role: Role, Action: GET_MESSAGES, GroupId: GroupId}
    };

    replaceBoards=(newBoard)=>{
        const {boards} = this.state;

        for(let i=0;i<boards.length;i++) {

            const oldId =String(boards[i].Id);
            const newId = String(newBoard.Id);

            if(oldId === newId) {
                boards[i] = newBoard;
            }
        }
        return boards;
    }

    onMessage = function(incoming){
        const eventData = JSON.parse(incoming.data)
        const {event, message} = eventData;

        if(message === "Internal server error"){return}

        switch(event) {
            case  SOCKET_MESSAGE_RECEIVE :

                const {data,data:{Messages}}=eventData;

                const updatedBoards = this.replaceBoards(data);

                this.setState({loading: false, board: data,boards:updatedBoards, messages:this.formatIncoming(Messages)}, this.scrollToBottom)
                break;

            case SOCKET_BOARDS_RECEIVE:

                const{ data:{body}} = eventData;

                if(body.length === 0){return this.setState({loading: false})}

                this.setState({loading: false, board: body[0],boards:body, messages:this.formatIncoming(body[0].Messages)},
                    this.scrollToBottom)
                break;

            default:
                console.log('[EVENT NOT FOUND] ',event)
        }
    }

    scrollToTop = ()=>{
        this.top && this.top.scrollIntoView({ behavior: "auto"});
    }

    scrollToBottom = ()=>{
        this.el && this.el.scrollIntoView({ behavior: "auto", });
    };

    generateBoardReadPayload = (board)=>{
        const {visibleProfile} = this.props;
        return {User: visibleProfile.EmailAddress, Board:board, Role: 'p', Action: BOARD_READ}

    };

    registerBoardOpen=(board)=>{

        const boardPayload = this.generateBoardReadPayload(board);

        this.props.dispatch(socketMessageSend(boardPayload))
    }

    formatMessage=(message)=>{
        const {props:{visibleProfile:{EmailAddress,Creator, FirstName,LastName, GroupId, Role}}, state:{board}} = this;

        let TO ='';
        if(!board){ TO = Creator}
        else if (board.Therapist){ TO = board.Therapist}
        else{TO = Creator}

        let ptFirstName, ptLastName;

        if(board && board.PTFirstName && board.PTLastName){
            ptFirstName = board.PTFirstName;
            ptLastName = board.PTLastName;
        }else{
            ptFirstName ='';
            ptLastName = '';
        }

        const formattedMessage = {
            Action: SEND_MESSAGE,
            titleColor: 'white',
            from: EmailAddress,
            to: TO,
            text: message,
            firstName:FirstName,
            lastName: LastName,
            GroupId: GroupId,
            Role: Role,
            date: Date.now(),
            notch:false,
            position: 'right',
            ptFirstName: ptFirstName,
            ptLastName: ptLastName,
        };

        return formattedMessage
    }

    sendMessage=(message)=>{
        const {dispatch}=this.props;

        if(trim(message).length ===0){return}
        const {state:{messages}, formatMessage, scroll} = this;
        const formattedMessage = formatMessage(trim(message));
        this.setState({messages:messages}, scroll)

        dispatch(socketMessageSend(formattedMessage))
        dispatch(setNewMessageBadge(null,false))
    }

    submitMessage=()=>{
        const {text} = this.state;
        if(text && text !== ''){
            this.sendMessage(text)
            this.setState({text: null})
        }
    }

    onFocus=()=>{
        const {props:{dispatch}, state:{board}} = this;
        this.setState({focus:true})
        this.registerBoardOpen(board)
        dispatch(hideNavBar(true))
    }

    onBlur=()=>{
        const {props:{dispatch}, scrollToBottom} = this;
        this.setState({focus: false}, scrollToBottom)
        dispatch(hideNavBar(false))
    }

    formatIncoming = (messages)=>{
        const {visibleProfile:{EmailAddress}} = this.props;
        const incoming = [];
        for(let i=0; i<messages.length;i++){
            const obj = messages[i];
            const {date, from, message} = obj

            const isFromSelf = (from === EmailAddress) ? true : false;

            incoming.push({titleColor:'black',text:message, dateString: format(`${date}`), position: isFromSelf ?'right':'left', notch: false})
        }

        return incoming;
    }

    setText=(text)=>{
        this.setState({text:text})
    }

    avatarLink(userEmail, GroupId){
        const url =`https://d2p805pqn3eul9.cloudfront.net/${GroupId}/${userEmail}.jpg`;
        return url
    }

    handleItemClick=(board)=>{
        this.registerBoardOpen(board);
        this.setState({notSelected:false,board:board,messages:this.formatIncoming(board.Messages)}, this.scrollToBottom)
    }

    backButton=()=>{
        this.setState({notSelected:true,board: null, messages: []})
    }

    render(){
        const {onFocus, onBlur, sendMessage,submitMessage, avatarLink,handleItemClick, backButton,
            state:{messages, focus,loading, text, boards, notSelected, board},
            props:{visibleProfile:{GroupId,Creator}},PT} = this;

        const nameString = board && board.PTFirstName ? `${board.PTFirstName} ${board.PTLastName}` : PT;

        return(
                <div style={!focus ? {...containerStyle, backgroundColor:loading?'transparent':'#fff'} : containerFocus } ref={(top)=>{this.top = top}}>

                    { loading ? <div style={{color:'#fff'}} className="loader">Loading...</div> :

                        boards.length > 1 && notSelected ?
                        <div>
                            <h1 style={headingStyle}>Care Providers</h1>
                            <Row>
                                {boards.map( (item,i) =>{

                                    const {Therapist, PTFirstName, PTLastName, OwnerMessages, LastMessageDate, GroupId} = item;

                                return (<Col style={colStyle} xs={12} key={i} className={'care-provider-list'}>
                                            <ChatItem avatar={avatarLink(Therapist, GroupId)} title={`${PTFirstName} ${PTLastName}`}
                                            unread={OwnerMessages} dateString={format(LastMessageDate)}
                                            onClick={()=>{handleItemClick(item)}}/>
                                        </Col>)
                                    })
                                }

                            </Row>
                        </div>
                        : <div>
                            <List bordered style={headerStyle}>
                                <Row>
                                    <Col xs={4}>
                                        {(board && board.Therapist) &&
                                            <Avatar style={avatarStyle} size="large" icon="user"
                                                    src={avatarLink(board.Therapist, GroupId)}/>
                                        }
                                        {(!board && Creator) &&
                                        <Avatar style={avatarStyle} size="large" icon="user"
                                                src={avatarLink(Creator, GroupId)}/>
                                        }
                                    </Col>
                                    <Col xs={4}>
                                        <p style={nameStyle}>{nameString}</p>
                                    </Col>
                                    <Col xs={4}>
                                        {boards.length >1 &&
                                            <Button style={{...buttonStyle, marginBottom: '2px'}} onClick={backButton}>
                                                Back
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </List>
                            <List style={focus ? listStyleFocus : listStyle}>

                                <MessageList className='message-list' dataSource={messages}/>
                                <div style={{float: "left", clear: "both"}}
                                     ref={(el) => {
                                         this.el = el;
                                     }}>
                                </div>
                            </List>
                            <List bordered style={inputStyle}>

                                <MessageInput sendMessage={sendMessage} onFocus={onFocus} onBlur={onBlur}
                                              setText={this.setText}
                                              message={text}/>
                                <Row>
                                    <Col sm={2} smOffset={10}>
                                        <Button style={buttonStyle} bsStyle={'default'} block
                                                onClick={submitMessage}>Send</Button>
                                    </Col>
                                </Row>

                            </List>
                        </div>

                    }
                </div>
        )
    }
}

const mapStateToProps = state => ({
    visibleProfile: state.visibleProfile,
    socket: state.socket,
    PT: state.mobile.PT,
});

export default connect(mapStateToProps)(withRouter(Messaging));
