import { getRecoil } from 'recoil-nexus';
import states from '../states';

export const getDiagnosisName = id => {
  const careplan = getRecoil(states.careplan);

  if (id === 'other') {
    return 'Other';
  }

  let name = '';
  careplan.programs.forEach(({ Diagnosis }) => {
    if (Array.isArray(Diagnosis)) {
      const item = Diagnosis.find(({ Id }) => Id === id);
      if (item) {
        name = item.Diagnosis;
      }
    }
  });

  return name;
};
