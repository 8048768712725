import { createSelector } from 'reselect';
import {
  getFormMeta,
  getFormSyncErrors,
  getFormError,
  getFormValues,
  isValid,
  isSubmitting,
} from 'redux-form';
const PRESCRIPTION_FORM_NAME = 'form';

export const searchResultsSelector = (state) => state.patients.searchResults;
export const selectedExercisesSelector = (state) =>
  state.patients.selectedExercises;
export const visibleProfileSelector = (state) => state.visibleProfile;

export const cookedSearchResultsSelector = createSelector(
  searchResultsSelector,
  visibleProfileSelector,
  selectedExercisesSelector,
  (searchResults, visibleProfile, selectedExercises) =>
    searchResults
      ? searchResults.map((e) => {
          return {
            ...e,
            isGroupExercise: e.groupId === visibleProfile.GroupId,
            isSelected: !!selectedExercises.find((ex) => ex && ex.id === e.id),
          };
        })
      : undefined
);

export const formInitialValuesSelector = createSelector(
  selectedExercisesSelector,
  (selectedExercises) => ({
    exercises: selectedExercises ? selectedExercises : undefined,
  })
);

export const exercisesGenericErrors = createSelector(
  getFormError(PRESCRIPTION_FORM_NAME),
  getFormValues(PRESCRIPTION_FORM_NAME),
  (error, values) =>
    error && error.exercises ? error.exercises : Array(values.length).fill({})
);

export const showParamsErrors = createSelector(
  getFormMeta(PRESCRIPTION_FORM_NAME),
  getFormValues(PRESCRIPTION_FORM_NAME),
  (fields, values) => {
    if (fields && fields.exercises) {
      return values.exercises.map((e, i) => {
        let exerciseFields = fields.exercises[i];
        return (
          !!exerciseFields &&
          ((exerciseFields.reps && exerciseFields.reps.touched) ||
            (exerciseFields.sets && exerciseFields.sets.touched) ||
            (exerciseFields.hold && exerciseFields.hold.touched))
        );
      });
    } else {
      return Array(values.exercises.length).fill(false);
    }
  }
);

export const anyExerciseGenericError = createSelector(
  showParamsErrors,
  exercisesGenericErrors,
  (showParamsErrors, genericErrors) => {
    for (let i = 0; i < showParamsErrors.length; i++) {
      if (
        genericErrors[i] &&
        genericErrors[i].parameters &&
        showParamsErrors[i]
      ) {
        return true;
      }
    }
    return false;
  }
);

export const anyInvalidTouchedField = (formName) =>
  createSelector(
    getFormMeta(formName),
    getFormSyncErrors(formName),
    anyInvalidTouchedFieldHelper
  );

export const formError = createSelector(
  anyExerciseGenericError,
  anyInvalidTouchedField(PRESCRIPTION_FORM_NAME),
  (exerciseGenericError, invalidTouchedField) =>
    exerciseGenericError || invalidTouchedField
);

function anyInvalidTouchedFieldHelper(fields, syncErrors) {
  if (!fields || !syncErrors) return false;

  let fieldKeys = Object.keys(fields);

  for (let i = 0; i < fieldKeys.length; i++) {
    let key = fieldKeys[i];
    if (Array.isArray(fields[key])) {
      for (let j = 0; j < fields[key].length; j++) {
        if (
          syncErrors[key] &&
          anyInvalidTouchedFieldHelper(fields[key][j], syncErrors[key][j])
        ) {
          return true;
        }
      }
    } else {
      if (syncErrors[key] && fields[key].touched) return true;
    }
  }
  return false;
}

export const formSaveEnabled = (formName) =>
  createSelector(
    isValid(formName),
    isSubmitting(formName),
    // isPristine(formName),
    (valid, submitting, pristine) => valid && !submitting && !pristine
  );
