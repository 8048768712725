import V from 'voca';

import {
  GET_USER,
  GET_USERS,
  POST_USER,
  UPDATE_USER,
  SET_USER_FORM_TYPE,
  UPDATE_CURRENT_USER,
  IS_LOADING,
  DONE_LOADING,
  defaultUser,
  SEARCH_USER,
  IS_SEARCHING,
  DONE_SEARCHING,
  CLOSE_MODAL,
  OPEN_MODAL,
  PT_NEW_USER_EMAIL,
  GET_ADMINS,
} from "../actions/users";

import {
  PENDING,
  FULFILLED,
  REJECTED
} from 'redux-promise-middleware';

import mapModel from '../../groups/mapper';
import { FormTypes } from "../../groups/actions/groups";

const defaultState = {
  modalOpen: false,
  formType: FormTypes.NEW,
  currentUser: defaultUser,
  isFetching: false,
  fetched: false,
  isSubmitting: false,
  submitted: false,
  items: {},
  isLoading: false,
  isSearching: false
};

export const usersReducer = (state = defaultState, { type, payload }) => {
  let mapped;

  switch (type) {

      case `${GET_ADMINS}_${PENDING}`:
          return {
              ...state,
              isFetching: true,
              fetched: false
          }
      case `${GET_ADMINS}_${FULFILLED}`:
          let transform = {};
          const admins = [];

          payload.map((current)=>{
            const keys = Object.keys(current);
            const camelKeys = {};

            for(let i=0; i<keys.length; i++){
                const key = keys[i]
                camelKeys[V.camelCase(key)] = current[key]
            }

            const replace = Object.assign(current, camelKeys);


            admins.push(replace)
            return current;
          });

          let items = Object.assign(transform, state.items);

          return {
              ...state,
              admins:admins,
              isFetching: false,
              fetched: true
          }

    case `${PT_NEW_USER_EMAIL}_${PENDING}`:
        return {
            ...state,
            isFetching: true,
            fetched: false
        }
    case `${PT_NEW_USER_EMAIL}_${FULFILLED}`:
        items = {}
        return {
            ...state,
            isFetching: false,
            fetched: true
        }

    case `${GET_USERS}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      }

    case `${GET_USERS}_${FULFILLED}`:
      items = {}

      payload.profiles.forEach(u => {
        mapped = mapModel('user', u, false)
        items[mapped.emailAddress] = mapped
      })

      return {
        ...state,
        isFetching: false,
        fetched: true,
        items
      }

    case `${SEARCH_USER}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      }

    case `${SEARCH_USER}_${FULFILLED}`:
      items = {}
      payload.profiles.forEach(u => {
        mapped = mapModel('user', u, false)
        items[mapped.emailAddress] = mapped
      })

      return {
        ...state,
        isFetching: false,
        fetched: true,
        items
      }

    case `${GET_USER}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      }

    case `${GET_USER}_${FULFILLED}`:
      mapped = mapModel('user', payload.profile, false)
      return {
        ...state,
        isFetching: false,
        fetched: true,
        items: {
          ...state.items,
          [mapped.emailAddress]: mapped
        }
      }

    case `${POST_USER}_${PENDING}`:
      return {
        ...state,
        isSubmitting: true,
        submitted: false
      }

    case `${POST_USER}_${FULFILLED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: true
      }

    case `${POST_USER}_${REJECTED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: false
      }

    case `${UPDATE_USER}_${PENDING}`:
      return {
        ...state,
        isSubmitting: true,
        submitted: false
      }

    case `${UPDATE_USER}_${FULFILLED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: true
      }

    case `${UPDATE_USER}_${REJECTED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: false
      }

    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      }

    case DONE_LOADING:
      return {
        ...state,
        isLoading: false
      }

    case SET_USER_FORM_TYPE:
      return {
        ...state,
        formType: payload.formType
      }

    case UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: payload.values
      }

    case IS_SEARCHING:
      return {
        ...state,
        isSearching: true
      }

    case DONE_SEARCHING:
      return {
        ...state,
        isSearching: false
      }

    case OPEN_MODAL:
      return {
        ...state,
        modalOpen: true
      }

    case CLOSE_MODAL:
      return {
        ...state,
        modalOpen: false
      }

    default:
      return state
  }
}