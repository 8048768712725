import { createSelector } from 'reselect'

export const usersItemsSelector = state => state.users.items

export const usersValuesSelector = createSelector(
  usersItemsSelector,
  usersItems => Object.values(usersItems)
)


