import React, { useEffect, useState, useRef, useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { Form, Input, Select, Button, Tooltip } from 'antd';
import { LikeFilled, DislikeFilled } from '@ant-design/icons';

import states from '../../states';

const EditableContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={{ ...form, isHovering }}>
        <tr
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          {...props}
        />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  cptCodeList,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);

  const exercises = useRecoilValue(states.exercises);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      if (dataIndex !== 'export') {
        inputRef.current.focus();
      }
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    });
  };

  const handleSaveCell = async (exportVal) => {
    try {
      let values;

      if (exportVal.export) {
        if (exportVal.export === 'NO') {
          record.minutes = null;
        }

        values = exportVal;
      } else {
        values = await form.validateFields();
      }

      if (dataIndex !== 'export') {
        toggleEdit();
      }

      const parameter = exercises.custom.parameter;
      const params = parameter.find((p) => p.ExerciseId === record?.id);
      const custom = exercises.custom.exercises.find(
        (ex) => ex.Id === record?.id
      );

      record.cptCode =
        record?.cptCode || params?.CPTCode || custom?.CPTCode || null;

      handleSave({
        ...record,
        ...values
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const setEditableClassName = () => {
    if (dataIndex !== 'export' && form.isHovering) {
      if (record.export && record.export === 'NO') {
        if (dataIndex === 'cptCode' || dataIndex === 'minutes') {
          return '';
        } else {
          return 'editable-cell-value-wrap';
        }
      } else {
        return 'editable-cell-value-wrap';
      }
    } else {
      return '';
    }
  };

  const setOnClickFunc = () => {
    if (dataIndex !== 'export') {
      if (dataIndex === 'cptCode' || dataIndex === 'minutes') {
        if (record.export === 'YES') {
          return toggleEdit;
        } else {
          return null;
        }
      } else {
        return toggleEdit;
      }
    } else {
      return null;
    }
  };

  const renderExportToggle = () => {
    return (
      <Form.Item>
        <Button
          className={`btn-like ${record.export === 'YES' ? 'active' : ''}`}
          icon={<LikeFilled />}
          onClick={() => handleSaveCell({ export: 'YES' })}
        />{' '}
        <Button
          className={`btn-dislike ${record.export === 'NO' ? 'active' : ''}`}
          icon={<DislikeFilled />}
          onClick={() => handleSaveCell({ export: 'NO' })}
        />
      </Form.Item>
    );
  };

  let childNode = children;

  if (editable) {
    childNode =
      editing && dataIndex !== 'export' ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
        >
          {dataIndex === 'cptCode' ? (
            <Select
              ref={inputRef}
              showSearch
              placeholder="CPT Code"
              onChange={handleSaveCell}
              onBlur={() => setEditing(false)}
            >
              {cptCodeList.map((item, i) => (
                <Select.Option key={i} value={item.CPT_Code}>
                  <Tooltip title={item.Description} placement="right">
                    {item.CPT_Code}
                  </Tooltip>
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input
              ref={inputRef}
              onPressEnter={handleSaveCell}
              onBlur={handleSaveCell}
            />
          )}
        </Form.Item>
      ) : (
        <div className={setEditableClassName()} onClick={setOnClickFunc()}>
          {dataIndex === 'export' && form.isHovering
            ? renderExportToggle()
            : children}
        </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};
