import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, notification } from 'antd';
import services from '../../../services';

const RtmConsentForm = ({ group }) => {
  const [fetching, setFetching] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (group) {
      getGroupCustoms();
    }
  }, [group]);

  const getGroupCustoms = async () => {
    try {
      setFetching(true);

      const field = 'RTMConsent';
      const response = await services.groups.getCustoms(group.id, field);

      if (response.status === 200) {
        if (response.data && response.data[field]) {
          form.setFieldsValue({
            consent: response.data[field]
          });
        }
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to fetch RTM Consent.'
      });
    } finally {
      setFetching(false);
    }
  };

  const handleSetInput = (e) => {
    form.setFieldsValue({
      consent: e.target.value
    });
  };

  const handleSubmit = async (values) => {
    try {
      if (fetching) {
        return;
      }

      setUpdating(true);

      const response = await services.groups.updateCustoms({
        GroupId: group.id,
        RTMConsent: values.consent
      });

      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'RTM Consent updated successfully.'
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to update RTM Consent.'
      });
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Form
      id="rtm-consent-form"
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="consent"
        label="Consent Text"
        rules={[
          {
            required: true,
            message: 'Consent Text is required.'
          }
        ]}
      >
        <Input.TextArea
          rows={6}
          placeholder="Enter Consent Text"
          readOnly={fetching}
          onChange={handleSetInput}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={updating}>
          Save Changes
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  group: state.groups.currentGroup
});

export default connect(mapStateToProps)(RtmConsentForm);
