import { createAction } from 'redux-actions';
import { API_REQUEST } from '../../../membership/middleware';

export const ADMIN_SEARCH_USER = 'ADMIN_SEARCH_USER';
export const adminSearchUser = emailAddress => ({
  [API_REQUEST]: createAction(ADMIN_SEARCH_USER)({
    resource: `admin/users?search=${encodeURIComponent(emailAddress.trim())}`,
    method: 'GET'
  })
});

export const SAVE_PROFILE_CHANGES = 'SAVE_PROFILE_CHANGES';
export const saveProfileChanges = userProfile => ({
  [API_REQUEST]: createAction(SAVE_PROFILE_CHANGES)({
    resource: 'admin/users',
    method: 'PUT',
    body: {
      ...userProfile
    }
  })
});

export const CANCEL_EDIT_CHANGES = 'CANCEL_EDIT_CHANGES';
export const cancelEditChanges = createAction(CANCEL_EDIT_CHANGES);

export const GET_COGNITO_USER = 'GET_COGNITO_USER';
export const getCognitoUser = emailAddress => ({
  [API_REQUEST]: createAction(GET_COGNITO_USER)({
    resource: `cognito?emailAddress=${encodeURIComponent(emailAddress.trim())}`,
    method: 'GET'
  })
});

export const CRUD_USER_PROFILE = 'CRUD_USER_PROFILE';
export const crudUserProfile = (action, payload) => ({
  [API_REQUEST]: createAction(CRUD_USER_PROFILE)({
    resource: 'cognito',
    method: 'POST',
    body: {
      action,
      ...payload
    }
  })
});

export const QUERY_BY_NAME = 'QUERY_BY_NAME';
export const osQueryByName = (groupName, groupId, query) => ({
  [API_REQUEST]: createAction(QUERY_BY_NAME)({
    resource: `groups/${groupName}/patients?action=${QUERY_BY_NAME}&groupId=${groupId}&query=${query}`,
    method: 'GET'
  })
});

export const QUERY_BY_SUB = 'QUERY_BY_SUB';
export const osQueryBySub = (groupName, sub) => ({
  [API_REQUEST]: createAction(QUERY_BY_SUB)({
    resource: `groups/${groupName}/patients?action=${QUERY_BY_SUB}&sub=${sub}&index=patients`,
    method: 'GET'
  })
});
