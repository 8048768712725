import React, { Fragment, useState } from 'react';
import { Button, Modal, Form, Col, Row, Input } from 'antd';

export default function AddProgramModal(props) {
  const {
    carePlan,
    showAddProgramModal,
    setshowAddProgramModal,
    handleAddProgram,
  } = props;

  function handleCancel() {
    setshowAddProgramModal(false);
  }

  const onFinish = values => {
    handleAddProgram({
      programName: values.ProgramName,
      diagnosis: values.Diagnosis,
      patientTerm: values.PatientTerm,
    });
  };

  return (
    <Fragment>
      <Modal
        visible={showAddProgramModal}
        title="Add Program"
        onCancel={handleCancel}
        footer={null}>
        <Form layout="vertical" hideRequiredMark onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="ProgramName"
                label="Program name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter program name',
                  },
                ]}>
                <Input placeholder="Please enter program name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="Diagnosis"
                label="Diagnosis"
                rules={[
                  {
                    required: true,
                    message: 'Please enter diagnosis',
                  },
                ]}>
                <Input placeholder="Please enter diagnosis" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="PatientTerm"
                label="Patient term"
                rules={[
                  {
                    required: true,
                    message: 'Please enter patient term',
                  },
                ]}>
                <Input placeholder="Please enter patient term" />
              </Form.Item>
            </Col>
          </Row>
          <div className="care-button-container">
            <Button
              className="care-button"
              size="large"
              shape="round"
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="care-button"
              type="primary"
              size="large"
              shape="round"
              loading={carePlan.isLoading}
              htmlType="submit">
              {carePlan.isLoading ? 'Saving Program...' : 'Save Program'}
            </Button>
          </div>
        </Form>
      </Modal>
    </Fragment>
  );
}
