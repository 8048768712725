import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SearchField = ({ value, handleSearch, placeholder = 'Search...' }) => {
  return (
    <Input
      placeholder={placeholder}
      onChange={e => handleSearch(e.target.value)}
      value={value}
      allowClear
      prefix={<SearchOutlined />}
    />
  );
};

export default SearchField;
