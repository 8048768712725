import { routerReducer } from 'react-router-redux';
import { currentUser } from '../features/membership/reducers/currentUser';
import visibleProfile from '../features/profile/reducers/visibleProfile';
import { reducer as formReducer } from 'redux-form';
import help from '../features/help/reducers/helpReducer';
import { groupsReducer } from '../features/groups/reducers/groups';
import groupAdmins from '../features/groups/group-admin/reducers';
import { clinicsReducer } from '../features/groups/reducers/clinics';
import { groupExercisesReducer } from '../features/groups/reducers/groupExercises';
import groupPatients from '../features/groups/group-patients/reducers';
import { reducer as alerts } from 'react-notification-system-redux';
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { ptwiredExercisesReducer } from '../features/exercise-library/reducers/ptwiredExercises';
import { reducer as idle } from '../features/idle-monitor';
import { exercisesLibraryReducer } from '../features/exercise-library/reducers/exercisesLibrary';
import { usersReducer } from '../features/users/reducers/users';
import { patientsReducer } from '../features/patients/reducers/patients';
import groupExercisesPlugin from '../features/groups/reducers/groupExercisesReduxFormPlugin';
import publicPageReducer from '../reducer';
import mobileReducer from '../features/mobile/reducers';
import socketReducer from '../DispatchSocket/socketReducer';
import utilitiesReducer from '../features/groups/utilities/reducers';
import monitorTimerReducer from '../features/monitor-timer/reducers';
import carePlanReducer from '../features/care-plan/reducers';

const ptwiredApp = persistCombineReducers(
  {
    key: 'root',
    stateReconciler: inbound => {
      if (inbound && inbound.currentUser) {
        inbound.currentUser = {
          ...inbound.currentUser,
          isLocked: false,
          hasError: false,
        };
      }
      return inbound;
    },
    debug: true,
    storage,
    blacklist: ['currentUser'],
  },
  {
    form: formReducer.plugin({
      exercise: groupExercisesPlugin,
    }),
    visibleProfile,
    routerReducer,
    currentUser,
    help,
    groups: groupsReducer,
    groupAdmins,
    clinics: clinicsReducer,
    groupExercises: groupExercisesReducer,
    groupPatients,
    ptwiredExercises: ptwiredExercisesReducer,
    alerts,
    idle,
    exercisesLibrary: exercisesLibraryReducer,
    users: usersReducer,
    patients: patientsReducer,
    publicPage: publicPageReducer,
    mobile: mobileReducer,
    socket: socketReducer,
    utilities: utilitiesReducer,
    monitorTimer: monitorTimerReducer,
    carePlan: carePlanReducer,
  },
);

export default ptwiredApp;
