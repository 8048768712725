import { atom } from 'recoil';

const defaultState = {
  reports: [],
  distroList: [],
  survey: {
    refetch: false
  }
};

const NPS_STATE = atom({
  key: 'NPS_STATE',
  default: defaultState
});

export default NPS_STATE;
