import { atom } from 'recoil';

const defaultState = {
  caseId: ''
};

const PARAMS_STATE = atom({
  key: 'PARAMS_STATE',
  default: defaultState
});

export default PARAMS_STATE;
