import apiFetch from '../utils/fetch.utils';

export const getLearnWorlds = async (email, groupId) => {
  try {
    const uri = `links?type=learnworlds&email=${encodeURIComponent(
      email
    )}&groupId=${encodeURIComponent(groupId)}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getStores = async (subdomain) => {
  try {
    const uri = `links?type=stores&subdomain=${subdomain}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
