import Typography from 'antd/lib/typography/Typography';
import {
  PeopleCircleOutline,
  PinOutline,
  ReceiptOutline,
  TimeOutline,
} from 'react-ionicons';

const iconStyle = {
  height: 32,
  width: 32,
  verticalAlign: 'middle',
  marginTop: -5,
  marginRight: 8,
};

export const stats = [
  {
    title: 'Total RTM Billed Codes',
    value: 0,
    icon: <ReceiptOutline style={iconStyle} />,
  },
  {
    title: 'Active RTM Patients',
    value: 0,
    icon: <PeopleCircleOutline style={iconStyle} />,
  },
  {
    title: 'Total RTM Service Time',
    value: 0,
    icon: <TimeOutline style={iconStyle} />,
  },
  {
    title: 'Locations',
    value: 0,
    icon: <PinOutline style={iconStyle} />,
  },
];

export const codes = [
  {
    label: '98981',
    value: '98981',
  },
  {
    label: '98980',
    value: '98980',
  },
  {
    label: '98975',
    value: '98975',
  },
  {
    label: '98977',
    value: '98977',
  },
];

export const statuses = [
  {
    label: 'Ready for Billing',
    value: 'Ready for Billing',
  },
  {
    label: 'On Track',
    value: 'On Track',
  },
  {
    label: 'Not on Track',
    value: 'Not on Track',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];

export const columns = {
  CODES: [
    {
      title: 'RTM Code Billed',
      dataIndex: 'Code',
      key: 'Code',
    },
    {
      title: 'Patient',
      dataIndex: 'PatientName',
      key: 'PatientName',
      render: value => (
        <Typography.Text style={{ textTransform: 'capitalize' }}>
          {value}
        </Typography.Text>
      ),
    },
    {
      title: 'Date Billed',
      dataIndex: 'Date',
      key: 'Date',
    },

    {
      title: 'Marked as Billed By',
      dataIndex: 'Providers',
      key: 'Providers',
    },
  ],
  SERVICE_LOGS: [
    {
      title: 'Patient',
      dataIndex: 'PatientName',
      key: 'PatientName',
      render: value => (
        <Typography.Text style={{ textTransform: 'capitalize' }}>
          {value}
        </Typography.Text>
      ),
    },
    {
      title: 'Enrollment Dates',
      dataIndex: 'EnrollStart',
      key: 'EnrollStart',
    },
    {
      title: 'Total RTM Time',
      dataIndex: 'TotalDurations',
      key: 'TotalDurations',
    },
    {
      title: 'Interactive Communication',
      dataIndex: 'TotalLiveCall',
      key: 'TotalLiveCall',
    },
  ],
};

export const colors = [
  {
    border: 'rgb(53, 162, 235)',
    background: 'rgba(53, 162, 235, 0.5)',
  },
  {
    border: 'rgb(42, 56, 69)',
    background: 'rgba(42, 56, 69, 0.5)',
  },
  {
    border: 'rgb(245, 203, 66)',
    background: 'rgba(245, 203, 66, 0.5)',
  },
  {
    border: 'rgb(173, 56, 45)',
    background: 'rgba(173, 56, 45, 0.5)',
  },
  {
    border: 'rgb(69, 82, 26)',
    background: 'rgba(69, 82, 26, 0.5)',
  },
  {
    border: 'rgb(237, 119, 160)',
    background: 'rgba(237, 119, 160, 0.5)',
  },
  {
    border: 'rgb(70, 105, 99)',
    background: 'rgba(70, 105, 99, 0.5)',
  },
  {
    border: 'rgb(39, 94, 143)',
    background: 'rgba(39, 94, 143, 0.5)',
  },

  {
    border: 'rgb(39, 171, 219)',
    background: 'rgba(39, 171, 219, 0.5)',
  },
  {
    border: 'rgb(237, 227, 81)',
    background: 'rgba(237, 227, 81, 0.5)',
  },
  {
    border: 'rgb(255, 145, 0)',
    background: 'rgba(255, 145, 0, 0.5)',
  },
  {
    border: 'rgb(135, 46, 83)',
    background: 'rgba(135, 46, 83, 0.5)',
  },
  {
    border: 'rgb(54, 156, 68)',
    background: 'rgba(54, 156, 68, 0.5)',
  },
];
