import apiFetch from '../utils/fetch.utils';
import states from '../states';
import { getRecoil } from 'recoil-nexus';

export const getPrograms = async () => {
  try {
    const groupsState = getRecoil(states.groups);
    const { name } = groupsState.user;

    const uri = `groups/${name}/care-plan?action=GET_PROGRAMS`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getPlan = async (action, sub) => {
  try {
    const groupsState = getRecoil(states.groups);
    const { name } = groupsState.user;

    const uri = `groups/${name}/care-plan?action=${action}&sub=${sub}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const disablePlan = async payload => {
  try {
    const groupsState = getRecoil(states.groups);
    const { name } = groupsState.user;

    const uri = `groups/${name}/care-plan`;
    const method = 'PUT';

    return apiFetch(uri, method, {
      body: {
        action: 'DISABLE_PLAN',
        ...payload
      }
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};
