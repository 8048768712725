import React, { Fragment, useEffect, useState } from 'react';
import { Button, Popover, Space, Tag, Typography } from 'antd';
import {
  FieldTimeOutlined,
  InfoCircleFilled,
  MinusOutlined,
} from '@ant-design/icons';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  checkTechRole,
  checkDischarged,
  checkRTMEnabled,
  saveAndFetchActivityLogs,
  fetchActivityLogs,
} from './mixins';

import useRtmIdleTimer from './hooks/useRtmIdleTimer';
import states from '../../states';

import TimerBody from './TimerBody';
import TimerFooter from './TimerFooter';
import InactivityPrompt from '../../features/monitor-timer/components/InactivityPrompt';

const RTMTimer = () => {
  const user = useRecoilValue(states.user);
  const [rtm, setRTM] = useRecoilState(states.rtm);

  const {
    patient,
    isMonitoring,
    activity,
    streamDuration: monitoredStreamDuration,
  } = rtm.timer;

  const [openTimer, setOpenTimer] = useState(false);
  const [duration, setDuration] = useState(0);
  const [renderStreamDuration, setRenderStreamDuration] = useState('00:00:00');

  const isTechRole = checkTechRole(user.details);
  const isDischarged = checkDischarged(patient);
  const isRTMEnabled = checkRTMEnabled(patient);

  const {
    handleResumeMonitoring,
    isInactive,
    openInactivePrompt,
    remainingTime,
  } = useRtmIdleTimer();

  useEffect(() => {
    if (!isRTMEnabled) {
      setDuration(0);
      return;
    }

    let interval;
    if (isMonitoring) {
      if (!patient || !activity) {
        setDuration(0);
        return;
      }

      interval = setInterval(() => {
        setDuration(value => value + 1);
      }, 1000);
    } else {
      setDuration(0);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isMonitoring, patient, activity]);

  useEffect(() => {
    if (!patient) {
      return;
    }

    setDuration(0);
    setRTM(prev => ({
      ...prev,
      timer: {
        ...prev.timer,
        loadingActivityLogs: true,
      },
    }));

    fetchActivityLogs(patient.Sub).then(() => {
      setRTM(prev => ({
        ...prev,
        timer: {
          ...prev.timer,
          loadingActivityLogs: false,
        },
      }));
    });
  }, [patient, activity]);

  useEffect(() => {
    if (isDischarged) {
      setDuration(0);
    }
  }, [isDischarged]);

  useEffect(() => {
    if (duration > 0) {
      const format = val => val.toString().padStart(2, '0');
      const hours = format(Math.floor(duration / 3600));
      const minutes = format(Math.floor((duration % 3600) / 60));
      const seconds = format(duration % 60);

      setRenderStreamDuration(`${hours}:${minutes}:${seconds}`);
      setRTM(prev => ({
        ...prev,
        timer: {
          ...prev.timer,
          streamDuration: duration,
        },
      }));
    } else {
      setRenderStreamDuration('00:00:00');
      setRTM(prev => ({
        ...prev,
        timer: {
          ...prev.timer,
          streamDuration: 0,
        },
      }));
    }
  }, [duration]);

  useEffect(() => {
    const isMonitoring = rtm.timer.isMonitoring;
    const patient = rtm.timer.patient;
    const hasTimerData = isMonitoring && !!patient;

    if (isInactive && !openInactivePrompt && hasTimerData) {
      setRTM(prev => ({
        ...prev,
        timer: {
          ...prev.timer,
          isMonitoring: false,
        },
      }));
      (async () => {
        await saveAndFetchActivityLogs(
          patient,
          activity,
          monitoredStreamDuration
        );
      })();
    }
  }, [isInactive, openInactivePrompt]);

  const handleStartMonitoring = async value => {
    setRTM(prev => ({
      ...prev,
      timer: {
        ...prev.timer,
        isMonitoring: value,
      },
    }));

    if (patient && activity && monitoredStreamDuration > 0) {
      await saveAndFetchActivityLogs(
        patient,
        activity,
        monitoredStreamDuration
      );
    } else {
      setDuration(0);
    }
  };

  return (
    <Fragment>
      <InactivityPrompt
        remainingTime={remainingTime}
        openInactivePrompt={openInactivePrompt}
        handleResumeMonitoring={handleResumeMonitoring}
      />
      <div className="timer-container">
        <div className={`timer-modal ${openTimer ? 'open' : 'close'}`}>
          <div className="timer-modal-header">
            <Typography.Title level={3}>
              RTM Timer{' '}
              <Popover
                content={popoverContent()}
                trigger="hover"
                placement="right"
                arrowPointAtCenter
                zIndex={9999}
              >
                <InfoCircleFilled className="popover-icon" />
              </Popover>
            </Typography.Title>
          </div>
          <div className="timer-modal-body">
            <TimerBody
              isDischarged={isDischarged}
              isTechRole={isTechRole}
              isRTMEnabled={isRTMEnabled}
              streamDuration={duration}
              renderStreamDuration={renderStreamDuration}
            />
          </div>
          <div className="timer-modal-footer">
            <TimerFooter
              isDischarged={isDischarged}
              isTechRole={isTechRole}
              handleStartMonitoring={handleStartMonitoring}
            />
          </div>
        </div>
        <Button
          shape="circle"
          type={openTimer ? 'default' : 'primary'}
          size="large"
          className={`timer-btn ${!openTimer && 'btn-primary'}`}
          onClick={() => setOpenTimer(!openTimer)}
          icon={openTimer ? <MinusOutlined /> : <FieldTimeOutlined />}
        />
        {/* display when modal is closed and monitoring started */}
        {!openTimer && isMonitoring && (
          <Tag color="#f47373" className="timer-indicator">
            ● Monitoring
          </Tag>
        )}
      </div>
    </Fragment>
  );
};

const popoverContent = () => (
  <Space direction="vertical">
    <div className="popover-title-container">
      <Space direction="horizontal" size={24}>
        <Typography.Text strong>RTM Timer</Typography.Text>
        <Typography.Link
          className={'pull-right btn-link'}
          href={'https://ptwired.zendesk.com/hc/en-us/articles/8352988938259'}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          Help Center
        </Typography.Link>
      </Space>
    </div>
  </Space>
);

export default RTMTimer;
