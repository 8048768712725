import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  notification,
  Radio,
  Space
} from 'antd';

import { submitNPSSurvey } from './actions';
import defaultLogo from '../../../../img/default-logo.png';
import ThankYouPage from './ThankYouPage';

const NPSSurveyForm = ({ match, history, design, dispatch }) => {
  const search = history.location.search;
  const code = match.params.code;
  const score = new URLSearchParams(search).get('value');

  const [loadingForm, setLoadingForm] = useState(false);
  const [manualScore, setManualScore] = useState(0);
  const [comment, setComment] = useState('');
  const [requestResult, setRequestResult] = useState({
    success: false,
    data: null
  });

  const handleSelectScore = e => {
    const value = e.target.value;
    setManualScore(value);
  };

  const handleSubmitNPSSurvey = async () => {
    if (!score && !manualScore) {
      setShowRequired(true);
    } else {
      try {
        setLoadingForm(true);

        const value = score || manualScore;
        const response = await dispatch(submitNPSSurvey(code, value, comment));

        if (response && response.value) {
          setRequestResult({
            success: true,
            data: response.value
          });
        }
      } catch (err) {
        notification['error']({
          message: 'Submission Failed.',
          description: err.message
        });

        setRequestResult({
          success: false,
          data: err
        });
      } finally {
        setLoadingForm(false);
      }
    }
  };

  return (
    <div className="survey-container">
      <div className="brand-logo">
        {design.brandType === 'logo' ? (
          <img
            src={design.logoUrl}
            alt="logo"
            className="img-responsive center-block"
          />
        ) : (
          <img
            src={defaultLogo}
            alt="logo"
            className="img-responsive center-block"
          />
        )}
      </div>

      <Row gutter={0} className="survey-form">
        <Col lg={7} md={6} xs={1} />
        <Col lg={10} md={12} xs={22}>
          {requestResult.success ? (
            <ThankYouPage
              score={Number(score || manualScore)}
              requestResult={requestResult}
            />
          ) : (
            <Form layout="vertical" onSubmitCapture={handleSubmitNPSSurvey}>
              {!score && (
                <>
                  <Space
                    direction="vertical"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Typography.Title level={5} style={{ marginBottom: 0 }}>
                      Rate your experience with us!
                    </Typography.Title>

                    <Radio.Group
                      buttonStyle="solid"
                      style={{ marginBottom: 24 }}
                      disabled={loadingForm}
                      onChange={handleSelectScore}
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num, i) => (
                        <Radio.Button key={i} value={num} style={{ margin: 8 }}>
                          <strong>{num}</strong>
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Space>
                </>
              )}

              <Form.Item
                label={
                  <Typography.Title level={5}>
                    What is the most important reason for your score?
                  </Typography.Title>
                }
              >
                <Input.TextArea
                  className="survey-textarea"
                  placeholder="Compose your comment..."
                  name="comment"
                  size="large"
                  cols={4}
                  showCount
                  maxLength={250}
                  disabled={loadingForm}
                  onChange={e => setComment(e.target.value)}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  block
                  size="large"
                  className="ptw-btn btn-primary"
                  type="primary"
                  htmlType="submit"
                  loading={loadingForm}
                  disabled={!score && !manualScore}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
        <Col lg={7} md={6} xs={1} />
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  design: state.publicPage.design
});

export default connect(mapStateToProps)(NPSSurveyForm);
