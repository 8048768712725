export const getEmbeddedUrl = url => {
  if (!url) return undefined;

  const youtubeUrlId = getYoutubeUrlId(url);
  const vimeoUrlId = getVimeoUrlId(url);

  if (youtubeUrlId) {
    return `https://www.youtube.com/embed/${youtubeUrlId}`;
  } else if (vimeoUrlId) {
    return `https://player.vimeo.com/video/${vimeoUrlId}`;
  }
};

export const getYoutubeUrlId = url => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2];
};

export const getVimeoUrlId = url => {
  const regExp =
    /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
  const match = url.match(regExp);
  return match && match[3];
};
