import { gql } from '@apollo/client';

export const READ_MESSAGE = gql`
  mutation readMessage($messageBoardId: String!) {
    readMessage(messageBoardId: $messageBoardId) {
      OwnerMessages
      TherapistMessages
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessageMutation($message: String!, $messageBoardId: String!) {
    sendMessage(input: { message: $message, messageBoardId: $messageBoardId }) {
      to
      message
      from
      date
      MessageBoardId
    }
  }
`;
