import React, { Fragment, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Card,
  Space,
  Button,
  Form,
  DatePicker,
  Select,
  Row,
  Col,
  Typography,
  Skeleton,
  Table,
} from 'antd';

import moment from 'moment';
import services from '../../../services';
import states from '../../../states';
import * as reportsUtils from '../../../utils/reports.utils';

const RtmServiceLogs = ({
  title,
  showFilter,
  onSetLoading,
  onSetError,
  onSetDateRange,
  onSetReport,
}) => {
  const [filter, setFilter] = useState({
    dateRange: null,
    clinics: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [chartTitle, setChartTitle] = useState('');
  const [report, setReport] = useState(null);

  const groups = useRecoilValue(states.groups);
  const [form] = Form.useForm();

  useEffect(() => {
    initChart();
  }, [filter]);

  const initChart = async () => {
    try {
      if (error) {
        onSetError(false);
        setError(false);
      }

      onSetLoading(true);
      setLoading(true);

      const reportResponse = await fetchReports();
      onSetReport(reportResponse);
      setReport(reportResponse);

      let startDate;
      let endDate;

      if (filter.dateRange !== null) {
        startDate = moment(filter.dateRange[0]).format('MMM DD, YYYY');
        endDate = moment(filter.dateRange[1]).format('MMM DD, YYYY');
      }

      setChartTitle(
        `${title} ${!filter.dateRange ? '(Last 30 days)' : ''} Report ${
          filter.dateRange !== null ? `for ${startDate} - ${endDate}` : ''
        }`
      );
    } catch (error) {
      console.log('initChart', error);

      onSetError(true);
      setError(true);
    } finally {
      onSetLoading(false);
      setLoading(false);
    }
  };

  const fetchReports = async () => {
    try {
      let query = reportsUtils.generateDateQuery();

      if (filter.dateRange) {
        query = reportsUtils.generateDateQuery(
          moment(filter.dateRange[0]).format() || null,
          moment(filter.dateRange[1]).format() || null
        );
      }

      const response = await services.reports.getRtmServiceLogs(
        query,
        filter.clinics.length > 0 ? filter.clinics : null
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log('fetchReports', error);
      throw error;
    }
  };

  const handleFilter = values => {
    onSetDateRange(
      values.dateRange
        ? [values.dateRange[0].format(), values.dateRange[1].format()]
        : null
    );

    setFilter({
      dateRange: values.dateRange
        ? [values.dateRange[0].format(), values.dateRange[1].format()]
        : null,
      clinics: Array.isArray(values.clinics) ? [...values.clinics] : [],
    });
  };

  const handleClear = () => {
    form.resetFields();

    onSetDateRange(null);
    setFilter({
      dateRange: null,
      clinics: [],
    });
  };

  const generateDataSource = reportData => {
    return Array.isArray(reportData) && reportData.length > 0
      ? reportData
          .slice(0, 20)
          .sort((a, b) => b.TotalDuration - a.TotalDuration)
          .map(log => ({
            ...log,
            TotalDuration: `${log.TotalDuration} hours`,
          }))
      : [];
  };

  const renderTable = () => {
    if (!report) return null;

    const dataSource = generateDataSource(report);

    return (
      <Fragment>
        <Typography.Title level={5}>{chartTitle}</Typography.Title>
        <Table
          size="small"
          scroll={{
            x: 'max-content',
          }}
          columns={[
            {
              title: 'Provider',
              dataIndex: 'ProviderName',
              key: 'ProviderName',
              render: value => (
                <Typography.Text style={{ textTransform: 'capitalize' }}>
                  {value}
                </Typography.Text>
              ),
            },
            {
              title: 'Total RTM Time',
              dataIndex: 'TotalDuration',
              key: 'TotalDuration',
            },
            {
              title: 'Interactive Communication',
              dataIndex: 'TotalLiveCall',
              key: 'TotalLiveCall',
            },
            {
              title: 'Location',
              dataIndex: 'Location',
              key: 'Location',
              render: value =>
                groups.clinics.find(c => c.Id === value)?.ClinicName || value,
            },
          ]}
          dataSource={dataSource}
          pagination={false}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      {showFilter && (
        <Card style={{ background: '#f7f7f7', marginBottom: 20 }}>
          <Form
            className="filter-chart-form"
            form={form}
            onFinish={handleFilter}
          >
            <Row gutter={[16, 16]} style={{ flex: 1 }}>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Date Range" name="dateRange">
                  <DatePicker.RangePicker
                    disabledDate={current =>
                      (current && current < moment().subtract(1, 'year')) ||
                      current > moment().endOf('day')
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Location/s"
                  name="clinics"
                  rules={[
                    {
                      type: 'array',
                      max: 20,
                      message: 'You can only select up to 20 locations',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    placeholder="Select location/s"
                    maxTagCount={5}
                    maxTagTextLength={15}
                    filterOption={(input, option) => {
                      if (option && option.label) {
                        const label = String(option.label).toLowerCase();
                        if (label.includes(String(input).toLowerCase())) {
                          return option;
                        }
                      }
                    }}
                    options={groups.clinics.map(c => ({
                      label: c.ClinicName,
                      value: c.Id,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item>
                  <Space>
                    <Button htmlType="submit" type="primary">
                      Apply Filter
                    </Button>
                    <Button
                      htmlType="button"
                      type="default"
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
      {loading ? <Skeleton active /> : renderTable()}
    </Fragment>
  );
};

export default RtmServiceLogs;
