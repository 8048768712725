import React, { Component } from 'react';

class ReRoute extends Component {
    componentDidUpdate(){
        if(this.props.history.location.pathname === '/'){
            this.props.history.push('/exercises')
        }
    }
    render(){

        return(<div>Rerouting...</div>)
    }
}

export default ReRoute;