import React, { useState, useEffect } from 'react';
import { Checkbox, Col, Row, Typography } from 'antd';
import TemplateFieldData from './TemplateFieldData';
import TemplateHeader from './TemplateHeader';
import CustomizeAllTemplateExDrawer from './CustomizeAllTemplateExDrawer';

const checkExerciseFrequency = exercise => {
  if (!exercise) {
    return false;
  }

  return !!exercise.frequency;
};

const TemplateForm = props => {
  const {
    initialValues: { Frequency, Perdiem, Instructions, Exercises },
    setCurrentForm,
    selectedExercises,
    importedTemplate,
  } = props;

  const [exerciseFrequency, setExerciseFrequency] = useState(
    checkExerciseFrequency(Exercises ? Exercises[0] : undefined)
  );
  const [checkedExercises, setCheckedExercises] = useState([]);

  if (!Array.isArray(selectedExercises)) {
    return <div>Client Error: Missing selectedExercises</div>;
  }

  const [form, setForm] = useState({
    Exercises: [...selectedExercises],
    Frequency: Frequency ? Frequency : 1,
    Perdiem: Perdiem ? Perdiem : 'day',
    Instructions: Instructions,
  });

  const editHeader = (name, value) => {
    const updateObj = { ...form };
    updateObj[name] = value;
    setForm(updateObj);
    setCurrentForm(updateObj);
  };

  const editExercise = (exerciseId, name, value) => {
    const updateExercises = form.Exercises.map(ex =>
      ex.id === exerciseId ? { ...ex, [name]: value } : ex
    );
    setForm({
      ...form,
      Exercises: updateExercises,
    });
    setCurrentForm({ ...form, Exercises: updateExercises });
  };
  /**
   * Initialize form
   */
  useEffect(() => setCurrentForm(form));

  const handleCheckExerciseItem = id => {
    setCheckedExercises(prevState => {
      const isChecked = prevState.includes(id);

      if (!isChecked) {
        return [...prevState, id];
      } else {
        return prevState.filter(exerciseId => exerciseId !== id);
      }
    });
  };

  const handleCheckAllExercise = isChecked => {
    setCheckedExercises(() => {
      if (!isChecked) {
        return [];
      } else {
        return selectedExercises.map(exercise => exercise['id']);
      }
    });
  };

  const handleUpdateCheckedExercises = e => {
    console.log('e', e.target.name);
    if (e.target.value === '') return;

    const updatedExercises = form.Exercises.map(exercise => {
      if (checkedExercises.includes(exercise.id)) {
        return { ...exercise, [e.target.name]: e.target.value };
      } else {
        return { ...exercise };
      }
    });

    setForm({
      ...form,
      Exercises: updatedExercises,
    });
    setCurrentForm({
      ...form,
      Exercises: updatedExercises,
    });
  };

  const isCheckedAll = selectedExercises.length === checkedExercises.length;

  return (
    <form>
      <CustomizeAllTemplateExDrawer
        isExerciseFrequency={exerciseFrequency}
        checkedExercises={checkedExercises}
        setCheckedExercises={setCheckedExercises}
        handleUpdateCheckedExercises={handleUpdateCheckedExercises}
      />
      <div className="prescription-details-section">
        <h3 style={{ marginBottom: '20px !important' }}>
          TEMPLATE&nbsp;&nbsp;DETAILS
        </h3>
        <TemplateHeader
          initialValues={form}
          editHeader={editHeader}
          importedTemplate={importedTemplate}
          exerciseFrequency={exerciseFrequency}
          setExerciseFrequency={setExerciseFrequency}
        />
        <div className="mt-2 mb-5">
          <Typography.Title level={4}>Customize Exercises</Typography.Title>
          <Typography.Text type="secondary">
            Specific Instructions For Individual Exercises.
          </Typography.Text>
          <div className="mt-3">
            <div className="customize-exercise-list-header">
              <Row gutter={[16, 16]}>
                <Col lg={1} md={1} className="center-content">
                  <Checkbox
                    onChange={e => handleCheckAllExercise(e.target.checked)}
                    checked={isCheckedAll}
                  />
                </Col>
                <Col lg={23} md={23}>
                  <Typography.Text strong>
                    Customize Exercise Details
                  </Typography.Text>
                </Col>
              </Row>
            </div>
            {form.Exercises.map((exercise, i) => {
              return (
                <TemplateFieldData
                  key={`field-data-${i}`}
                  exercise={exercise}
                  onChange={editExercise}
                  initialValues={form}
                  isExerciseFrequency={exerciseFrequency}
                  checkedExercises={checkedExercises}
                  handleCheckExerciseItem={handleCheckExerciseItem}
                />
              );
            })}
          </div>
        </div>
      </div>
    </form>
  );
};

export default TemplateForm;
