import { createAction } from 'redux-actions'
import { API_REQUEST } from '../../features/membership/middleware'

export const GET_IMAGE_UPLOAD_POLICY = 'GET_IMAGE_UPLOAD_POLICY';
export const getImageUploadPolicy = (fileName) => ({
    [API_REQUEST]: createAction(GET_IMAGE_UPLOAD_POLICY)({
        resource: `s3-policy-document?filename=${fileName}`,
        method: 'GET'
    })
});

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const uploadImage = (policyDoc, file) => {
    let fd = new FormData();
    fd.append('key', policyDoc.key);
    fd.append('acl', 'private');
    fd.append('Content-Type', file.type);
    fd.append('AWSAccessKeyId', policyDoc.access_key);
    fd.append('policy', policyDoc.encoded_policy);
    fd.append('signature', policyDoc.signature);
    fd.append('file', file, file.name);

    return {
        [API_REQUEST]: createAction(UPLOAD_IMAGE)({
            resource: policyDoc.upload_url,
            method: 'POST',
            file: true,
            body: fd
        })
    }
};

export const UPLOAD_IMAGE_DIRECT = 'UPLOAD_IMAGE_DIRECT';
export const uploadImageDirect = (fileName, file) => {
    return {
        [API_REQUEST]: createAction(UPLOAD_IMAGE_DIRECT)({
            resource: `images/${fileName}`,
            method: 'POST',
            file: true,
            body: file
        })
    }
};

export const REMOVE_EXERCISE_IMAGE = 'REMOVE_EXERCISE_IMAGE';
export const removeExerciseImage = (groupId, exerciseId) => {
    return {
        [API_REQUEST]: createAction(REMOVE_EXERCISE_IMAGE)({
            method: 'DELETE',
            resource: `images/${groupId}/${exerciseId}.jpg`
        })
    }
}
