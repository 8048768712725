import React from 'react';
import { connect } from 'react-redux';
import editImage from '../../img/edit-overlay.png';
import placeholder from '../../img/upload_360.png';
import user from '../../img/user.png';
import _ from 'lodash';
import ProfilePhotoSelector from './ProfilePhotoSelector';

class Uploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      hasImage: true,
      currentSrc: this.props.src ? this.props.src : null,
    };

    this.onChange = this.onChange.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
    this.uploadClicked = this.uploadClicked.bind(this);
    this.removeClicked = this.removeClicked.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
  }

  componentDidUpdate() {
    const { src } = this.props;
    const { currentSrc } = this.state;

    if (currentSrc !== src) {
      this.setState({
        currentSrc: src,
      });
    }
  }

  handleImageError(defaultImageUrl) {
    const { onLoadError } = this.props;
    if (!defaultImageUrl) {
      this.setState({ currentSrc: placeholder });
    }

    this.setState({ hasImage: false });
    if (onLoadError) onLoadError();
  }

  componentWillUnmount() {
    // window.URL.revokeObjectURL(this.img.src);
    window.URL.revokeObjectURL(this.state.currentSrc);
  }

  onChange(event) {
    const file = event.target.files[0];
    const { imageValidator, onFileSelected } = this.props;

    if (file) {
      const errors = imageValidator(file);

      if (_.isEmpty(errors)) {
        this.setState({
          hasImage: true,
          currentSrc: window.URL.createObjectURL(file),
        });
      }

      return onFileSelected(file, errors);
    }
  }

  removeClicked(e) {
    this.props.onFileRemoved(true);
    // this.img.src = placeholder;
    this.setState({ hasImage: false, currentSrc: null });
    e.preventDefault();
  }

  uploadClicked(e) {
    this.props.onFileRemoved(false);
    this.input.click();
    e.preventDefault();
  }

  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }

  //use doc to upload
  render() {
    const {
      handleImageError,
      onChange,
      uploadClicked,
      props: {
        width,
        height,
        notCentered,
        isUser,
        selectorType,
        defaultImageUrl,
      },
      state: { hasImage, currentSrc, hover },
    } = this;

    let imageOverlay = hasImage
      ? {
          width: width,
          height: height,
          borderRadius: isUser ? '60px' : 'none',
          backgroundColor: 'grey',
          backgroundImage: `url(${editImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          cursor: 'pointer',
        }
      : {
          width: width,
          height: height,
          borderRadius: isUser ? '60px' : 'none',
          backgroundColor: isUser ? 'lightgrey' : 'transparent',
          opacity: hover ? '0.2' : 1,
          backgroundImage: isUser ? user : 'none',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          cursor: 'pointer',
        };

    let imageOpacity = hasImage
      ? {
          opacity: hover ? '0.2' : '1',
          borderRadius: isUser ? '60px' : 'none',
          background: 'center',
          cursor: 'pointer',
        }
      : {
          background: 'center',
          backgroundColor: 'grey',
          opacity: this.state.hover ? '0.2' : '1',
          borderRadius: isUser ? '60px' : 'none',
          backgroundImage: `url(${editImage})`,
          backgroundRepeat: 'no-repeat',
          cursor: 'pointer',
        };

    if (
      selectorType === 'PROFILE_PHOTO' ||
      selectorType === 'NAV_PROFILE_PHOTO'
    ) {
      return (
        <React.Fragment>
          <ProfilePhotoSelector
            onChange={onChange}
            uploadClicked={uploadClicked}
            currentSrc={currentSrc}
            notCentered={notCentered}
            height={height}
            width={width}
            selectorType={selectorType}
            inputRef={input => (this.input = input)}
            imgRef={img => (this.img = img)}
          />
        </React.Fragment>
      );
    }

    return (
      <form
        ref={form => {
          this.form = form;
        }}
      >
        <input
          type="file"
          ref={input => {
            this.input = input;
          }}
          accept="image/png, image/gif, image/jpeg, image/jpg"
          onChange={onChange}
          name="filename"
          style={{ display: 'none' }}
        />

        <div
          style={{
            ...imageOverlay,
            borderRadius: 12,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
          className={`${notCentered ? '' : 'center-block'}`}
          onClick={this.uploadClicked}
          width={width}
          height={height}
        >
          {currentSrc && (
            <img
              alt="photo"
              width="auto"
              height="100%"
              src={currentSrc}
              onError={() => {
                handleImageError(defaultImageUrl);
              }}
              style={{ ...imageOpacity }}
              ref={img => (this.img = img)}
              className={`${notCentered ? '' : 'center-block'}`}
            />
          )}
        </div>
      </form>
    );
  }
}

const ImageSelector = connect()(Uploader);

export { ImageSelector };
