import { Col, Form, Input, message, Radio, Row, Select, Space } from 'antd';
import { useEffect, useState } from 'react';

import config from '../../../config';
import ModalForm from '../../../components/ModalForm';

const AdminForm = ({
  showModal,
  formType,
  initialValues,
  loadingForm,
  handleClose,
  handleSubmit
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [isEmailExists, setEmailExists] = useState(false);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues, showModal]);

  const handleCheckEmail = async (e) => {
    setEmailExists(false);

    const value = e.target.value.trim();
    const input = encodeURIComponent(value.toLowerCase());

    if (!value) {
      return;
    }

    try {
      setLoading(true);

      const host = config.root;
      const response = await fetch(`${host}/users/external?input=${input}`);
      const data = await response.json();
      const exists = data.sub !== value.toLowerCase();

      setEmailExists(exists);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitForm = (values) => {
    const initEmail = initialValues.EmailAddress?.toLowerCase();
    const currEmail = values.EmailAddress?.toLowerCase();

    if (loading || (isEmailExists && initEmail !== currEmail)) {
      if (isEmailExists) {
        message.warning('Email address already exists.');
      }

      return;
    }

    handleSubmit(values);
  };

  return (
    <ModalForm
      form="adminForm"
      width={1000}
      header={`${formType === 'edit' ? 'Edit' : 'Add'} Admin`}
      showModal={showModal}
      loading={loadingForm}
      handleSubmit={handleSubmitForm}
      handleClose={handleClose}
    >
      <Form
        form={form}
        id="adminForm"
        layout="vertical"
        onFinish={handleSubmitForm}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="FirstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                }
              ]}
            >
              <Input type="text" size="large" placeholder="Enter first name" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="LastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                }
              ]}
            >
              <Input type="text" size="large" placeholder="Enter last name" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="EmailAddress"
              label="Email Address"
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                },
                {
                  type: 'email',
                  message: 'Invalid email address.'
                }
              ]}
            >
              <Input
                type="text"
                size="large"
                placeholder="example@email.com"
                onBlur={handleCheckEmail}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="BillingRole"
              label="Billing Role"
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                }
              ]}
            >
              <Select
                size="large"
                placeholder="Select billing role"
                options={[
                  { label: 'Provider', value: 'PT' },
                  { label: 'Provider Assistant', value: 'PTA' },
                  { label: 'Technician', value: 'TECH' }
                ]}
              />
            </Form.Item>
          </Col>

          <Col>
            <Space size={42}>
              <Form.Item name="Active" label="Account Status">
                <Radio.Group>
                  <Radio value={true}>Active</Radio>
                  <Radio value={false}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item name="PartTime" label="Employment Status">
                <Radio.Group>
                  <Radio value={false}>Full Time</Radio>
                  <Radio value={true}>Part Time</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="isAdmin" label="User Role">
                <Radio.Group>
                  <Radio value={false}>Provider</Radio>
                  <Radio value={true}>Group Admin</Radio>
                </Radio.Group>
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Form>
    </ModalForm>
  );
};

export default AdminForm;
