import React, { Component } from 'react';
import { Nav, NavItem, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisH,
  faRunning,
  faTrophy,
  faComments,
} from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Badge } from 'antd';
import {
  GET_MESSAGES,
  socketMessageSend,
} from '../../DispatchSocket/WebSocketAction';
import { Link } from 'react-router-dom';

const hashes = {
  '#/exercises': 1,
  '#/messaging': 2,
  '#/achievements': 3,
  '#/blog': 4,
  '#/more': 5,
  '#/progress': 6,
};

class BottomNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      current: null,
      isPracticePromotions: false,
      clinicObj: null,
      initialBadgeCount: 0,
      checkedMessages: false,
      ppSet: false,
    };
  }

  componentDidMount() {
    this.setState({
      activeTab: hashes[window.location.hash],
      start: window.location.hash,
    });
  }

  componentDidUpdate() {
    const {
      state: { start, isPracticePromotions, ppSet, clinicObj, checkedMessages },
      props: {
        clinic,
        socket: { websocket },
        dispatch,
      },
    } = this;

    if (!checkedMessages && websocket) {
      dispatch(
        socketMessageSend(this.generateGetMessagePayload()),
        this.setState({ checkedMessages: true })
      );
    }

    if (window.location.hash !== start) {
      this.setState({
        activeTab: hashes[window.location.hash],
        start: window.location.hash,
      });
    }

    if (clinic === clinicObj) {
      return;
    } else {
      this.setState({ clinicObj: clinic });
    }

    if (clinic && !ppSet) {
      if (isPracticePromotions === false) {
        this.setState({
          isPracticePromotions:
            clinic.IsPracticePromotions === 'true' ||
            clinic.IsPracticePromotions === true
              ? true
              : false,
          ppSet: true,
        });
      }
      this.setState({ ppSet: true });
    }
  }

  generateGetMessagePayload = () => {
    const {
      visibleProfile: { EmailAddress, GroupId, Role },
    } = this.props;
    return {
      User: EmailAddress,
      Role: Role,
      Action: GET_MESSAGES,
      GroupId: GroupId,
    };
  };

  checkCreator = creator => {
    if (!creator) {
      return false;
    }
    if (creator === 'null' || creator === '') {
      return false;
    }
    return true;
  };

  render() {
    const { activeTab } = this.state;
    const {
      badgeCount,
      Messaging,
      visibleProfile: { Creator },
      hideNavBar,
      clinic,
    } = this.props;
    const { checkCreator } = this;
    const ownerMessages = badgeCount ? badgeCount.ownerMessages : 0;

    let xsSize = 3;

    const isPracticePromotions =
      clinic.IsPracticePromotions === true ||
      clinic.IsPracticePromotions === 'true';

    const hasMessaging = checkCreator(Creator) && Messaging !== 'disabled';
    const displayBottomNav = hideNavBar ? { display: 'none' } : {};

    const showMessaging = !!hasMessaging;

    if (showMessaging || isPracticePromotions) {
      xsSize = 3;
    }

    return (
      <div className="bottom-nav" style={displayBottomNav}>
        <Nav bsStyle="tabs">
          <Row>
            <Col xsOffset={xsSize === 3 ? 0 : 1} xs={xsSize}>
              <NavItem
                eventKey={1}
                className="bottom-nav-item"
                id="bottom-nav-item-1"
              >
                <Link to={'/exercises'}>
                  <Row
                    className={
                      activeTab === 1 ? 'icon-row icon-selected' : 'icon-row'
                    }
                  >
                    <FontAwesomeIcon icon={faRunning} />
                  </Row>
                  <Row
                    className={
                      activeTab === 1
                        ? 'icon-text-row icon-selected'
                        : 'icon-text-row'
                    }
                  >
                    Exercises
                  </Row>
                </Link>
              </NavItem>
            </Col>
            {/*{showMessaging &&*/}
            {false && (
              <Col xs={xsSize}>
                <NavItem
                  eventKey={2}
                  className="bottom-nav-item"
                  id="bottom-nav-item-2"
                >
                  <Link to={'/messaging'}>
                    <Row
                      className={
                        activeTab === 2 ? 'icon-row icon-selected' : 'icon-row'
                      }
                    >
                      <FontAwesomeIcon icon={faComments} />
                    </Row>
                    <Row
                      className={
                        activeTab === 2
                          ? 'icon-text-row icon-selected'
                          : 'icon-text-row'
                      }
                    >
                      Messaging
                      <Badge offset={[0, -40]} count={ownerMessages}></Badge>
                    </Row>
                  </Link>
                </NavItem>
              </Col>
            )}

            <Col xs={xsSize}>
              <NavItem
                eventKey={3}
                className="bottom-nav-item"
                id="bottom-nav-item-3"
              >
                <Link to={'/achievements'}>
                  <Row
                    className={
                      activeTab === 3 ? 'icon-row icon-selected' : 'icon-row'
                    }
                  >
                    <FontAwesomeIcon icon={faTrophy} />
                  </Row>
                  <Row
                    className={
                      activeTab === 3
                        ? 'icon-text-row icon-selected'
                        : 'icon-text-row'
                    }
                  >
                    Awards
                  </Row>
                </Link>
              </NavItem>
            </Col>

            {/*{isPracticePromotions &&*/}
            {/*<Col xs={xsSize}>*/}
            {/*<NavItem eventKey={4} className="bottom-nav-item" id="bottom-nav-item-4">*/}
            {/*<Link to={'/blog'}>*/}
            {/*<Row className={activeTab === 4 ? "icon-row icon-selected" : "icon-row"}>*/}
            {/*<FontAwesomeIcon icon={faNewspaper}/>*/}
            {/*</Row>*/}
            {/*<Row className={activeTab === 4 ? "icon-text-row icon-selected" : "icon-text-row"}>*/}
            {/*Blog*/}
            {/*</Row>*/}
            {/*</Link>*/}
            {/*</NavItem>*/}
            {/*</Col>*/}
            {/*}*/}
            {/*{ (!isPracticePromotions) &&*/}
            {/*<Col xs={xsSize}>*/}
            {/*<NavItem eventKey={6} className="bottom-nav-item" id="bottom-nav-item-6">*/}
            {/*<Link to={'/progress'}>*/}
            {/*<Row className={activeTab === 6 ? "icon-row icon-selected" : "icon-row"}>*/}
            {/*<FontAwesomeIcon icon={faChartLine}/>*/}
            {/*</Row>*/}
            {/*<Row className={activeTab === 6 ? "icon-text-row icon-selected" : "icon-text-row"}>*/}
            {/*Progress*/}
            {/*</Row>*/}
            {/*</Link>*/}
            {/*</NavItem>*/}
            {/*</Col>*/}
            {/*}*/}
            <Col xs={xsSize}>
              <NavItem
                eventKey={5}
                className="bottom-nav-item"
                id="bottom-nav-item-5"
              >
                <Link to={'/more'}>
                  <Row
                    className={
                      activeTab === 5 ? 'icon-row icon-selected' : 'icon-row'
                    }
                  >
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </Row>
                  <Row
                    className={
                      activeTab === 5
                        ? 'icon-text-row icon-selected'
                        : 'icon-text-row'
                    }
                  >
                    More
                  </Row>
                </Link>
              </NavItem>
            </Col>
          </Row>
        </Nav>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state,
    clinic: state.clinics.currentClinic,
    visibleProfile: state.visibleProfile,
    badgeCount: state.socket.badgeCount,
    Messaging: state.mobile.Messaging,
    hideNavBar: state.mobile.hideNavBar,
  };
};

export default connect(mapStateToProps)(withRouter(BottomNav));
