import { atom } from 'recoil';

const defaultState = {
  list: []
};

const TEMPLATES_STATE = atom({
  key: 'TEMPLATES_STATE',
  default: defaultState
});

export default TEMPLATES_STATE;
