import { createAction } from 'redux-actions'
import { API_REQUEST } from "../../membership/middleware";

export const defaultPTWiredExercise = {
  active: true,
  imageUrl: ''
}

export const GET_EXERCISE = 'GET_PTWIRED_EXERCISE'
export const getExercise = (exerciseId, groupId) => ({
  [API_REQUEST]: createAction(GET_EXERCISE)({
    resource: `exercises?exerciseName=${exerciseId.trim()}&groupId=${groupId}`,
    method: 'GET'
  })
})

export const GET_EXERCISES = 'GET_PTWIRED_EXERCISES';

export const getExercises = groupId =>({
  [API_REQUEST]: createAction(GET_EXERCISES)({
    resource: `exercises?groupId=${groupId}`,
    method: 'GET'
  })
})

export const SEARCH_EXERCISES = 'SEARCH_PTWIRED_EXERCISES'
export const searchExercises = (searchTerm) => ({
  [API_REQUEST]: createAction(SEARCH_EXERCISES)({
    resource: `exercises?search=${searchTerm}`,
    method: 'GET'
  })
})

export const POST_EXERCISE = 'POST_PTWIRED_EXERCISE'
export const postExercise = values => ({
  [API_REQUEST]: createAction(POST_EXERCISE)({
    resource: 'exercises',
    method: 'POST',
    body: {
      ...values,
      exerciseName: values.exerciseName.trim()
    }
  })
})

export const UPDATE_EXERCISE = 'UPDATE_PTWIRED_EXERCISE'
export const updateExercise = values => ({
  [API_REQUEST]: createAction(UPDATE_EXERCISE)({
    resource: 'exercises',
    method: 'PUT',
    body: {
      ...values,
      exerciseName: values.exerciseName.trim()
    }
  })
})


export const UPDATE_CURRENT_EXERCISE = 'UPDATE_CURRENT_PTWIRED_EXERCISE'
export const updateCurrentExercise = createAction(UPDATE_CURRENT_EXERCISE, values => ({ values }))

export const SET_EXERCISE_FORM_TYPE = 'SET_PTWIRED_EXERCISE_FORM_TYPE'
export const setExerciseFormType = createAction(SET_EXERCISE_FORM_TYPE, formType => ({ formType }))

export const IS_LOADING = 'PTWIRED_EXERCISES_IS_LOADING';
export const isLoading = createAction(IS_LOADING);

export const DONE_LOADING = 'PTWIRED_EXERCISES_DONE_LOADING';
export const doneLoading = createAction(DONE_LOADING);
