import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Form, Typography, Row, Col } from 'antd';
import { paramValidator } from '../groups/validators/groupExercises';
import ParamInput from '../groups/group-exercises/ExerciseParamInput';
import FormInput from '../groups/Input';
import * as CustomExerciseValidators from './validators/customExercise';
import {
  anyInvalidTouchedField,
  showParamsError,
} from '../exercise-library/selectors';

const CustomExerciseForm = ({
  handleSubmit,
  error,
  showParamsError,
  anyInvalidTouchedField,
}) => {
  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Row gutter={[16, 0]}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Field
            name="exerciseName"
            label="Exercise Name"
            placeholder="Enter exercise name"
            component={FormInput}
            type="text"
            validate={CustomExerciseValidators.exerciseName}
            isRequired
          />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24}>
          <div
            className={
              error &&
              showParamsError &&
              error.parameters &&
              'ant-form-item-has-error'
            }
          >
            <Typography.Text strong>Parameters</Typography.Text>
            <Row gutter={[16, 0]}>
              <Col lg={8} md={8} sm={24} xs={24}>
                <Field
                  name="sets"
                  label="Sets"
                  placeholder="Enter sets"
                  component={ParamInput}
                  type="text"
                  validate={paramValidator}
                />
              </Col>
              <Col lg={8} md={8} sm={24} xs={24}>
                <Field
                  name="reps"
                  label="Reps"
                  placeholder="Enter reps"
                  component={ParamInput}
                  type="text"
                  validate={paramValidator}
                />
              </Col>
              <Col lg={8} md={8} sm={24} xs={24}>
                <Field
                  name="hold"
                  label="Hold"
                  placeholder="Enter hold"
                  component={ParamInput}
                  type="text"
                  validate={paramValidator}
                />
              </Col>
              {error && showParamsError && error.parameters && (
                <Typography.Text
                  type="danger"
                  style={{ marginTop: -24, marginBottom: 20, marginLeft: 8 }}
                >
                  Parameters are required.
                </Typography.Text>
              )}
            </Row>
          </div>
        </Col>

        <Col lg={24} md={24} sm={24} xs={24}>
          <Field
            name="instructions"
            label="Instructions"
            placeholder="Enter instructions"
            component={FormInput}
            type="textarea"
            validate={CustomExerciseValidators.instructions}
            hideErrorMessage
          />
        </Col>
      </Row>
    </Form>
  );
};

function mapStateToProps(state) {
  return {
    showParamsError: showParamsError('prescriptionCustomExercise')(state),
    anyInvalidTouchedField: anyInvalidTouchedField(
      'prescriptionCustomExercise'
    )(state),
  };
}

export default reduxForm({
  form: 'prescriptionCustomExercise',
  validate: CustomExerciseValidators.generic,
})(connect(mapStateToProps)(CustomExerciseForm));
