import apiFetch from '../utils/fetch.utils';

export const getPreferences = async sub => {
  try {
    const uri = `preferences?sub=${encodeURIComponent(sub)}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updatePreferences = async payload => {
  try {
    const uri = 'preferences';
    const method = 'PUT';

    return apiFetch(uri, method, { body: payload });
  } catch (error) {
    console.log(error);
    throw error;
  }
};
