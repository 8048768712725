import React from 'react';
import { Modal, Button } from 'antd';

const ModalForm = ({
  header,
  form,
  showModal,
  handleClose,
  handleSubmit,
  saveEnabled = true,
  closable = true,
  loading,
  width,
  className,
  children,
}) => {
  return (
    <Modal
      title={header}
      width={width}
      visible={showModal}
      onCancel={handleClose}
      closable={closable}
      destroyOnClose
      centered
      className={className}
      footer={[
        <Button
          key={0}
          form={form}
          className="ptw-btn btn-primary"
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading}
          disabled={!saveEnabled}
          onSubmit={handleSubmit}
        >
          Save Changes
        </Button>,
        closable && (
          <Button
            key={1}
            className="ptw-btn"
            type="default"
            htmlType="submit"
            size="large"
            onClick={handleClose}
            disabled={loading}
          >
            Cancel
          </Button>
        ),
      ]}
    >
      {children}
    </Modal>
  );
};

export default ModalForm;
