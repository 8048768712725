import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import {
  Modal,
  Select,
  Typography,
  Button,
  Divider,
  Spin,
  notification
} from 'antd';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';

import { getAdmins } from '../../users/actions/users';
import {
  getMessageBoardProviders,
  setAssignedProviders,
  setMessageBoardProviders
} from '../actions/patients';
import { MANAGE_BOARDS } from '../../../DispatchSocket/socketEvents';

import camelCaseObjectKeys from '../lib/camelCaseObjectKeys';
import states from '../../../states';

const AssignProviderModal = props => {
  const {
    showAssignProvider,
    handleShowAssignProvider,
    patient,
    admins,
    visibleProfile: { GroupId },
    dispatch
  } = props;

  const [providers, setProviders] = useState([]);
  const [selectedProviderEmails, setSelectedProviderEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const setNpsState = useSetRecoilState(states.nps);

  useEffect(() => {
    if (showAssignProvider) {
      getAssignedProviders();
      fetchAdmins();
    }
  }, [showAssignProvider]);

  const fetchAdmins = async () => {
    try {
      setLoading(true);
      const queries = [];

      queries.push(dispatch(getAdmins(GroupId)));

      await Promise.all(queries);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const getAssignedProviders = async () => {
    /**
     * Get Provider information for message boards
     * */
    const response = dispatch(getMessageBoardProviders(patient.Sub));

    await response
      .then(data => {
        const { providers } = data.value;
        const parsedProviders = camelCaseObjectKeys(providers);

        if (!parsedProviders) {
          return;
        }

        setProviders(parsedProviders);
        setSelectedProviderEmails(getProviders(parsedProviders));
        dispatch(setAssignedProviders(parsedProviders));
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleSelectProviders = value => {
    const parsedAdmins = camelCaseObjectKeys(admins);
    const activeAdmins = getActiveProviders(parsedAdmins);

    const provider = activeAdmins.filter(admin => admin.sub === value);
    let allProviders = providers.concat(provider);

    setProviders(allProviders);
    setSelectedProviderEmails(getProviders(allProviders));
  };

  const handleRemoveProvider = async sub => {
    if (providers.length === 1) {
      return notification.warning({
        message: 'Sorry',
        description: 'Patient must have at least one provider.'
      });
    }

    let updatedProviders = providers.filter(provider => provider.sub !== sub);

    setProviders(updatedProviders);
    setSelectedProviderEmails(getProviders(updatedProviders));
  };

  const updateAssignedProvider = async () => {
    const format = formatMessage(providers);
    try {
      setFormLoading(true);
      await dispatch(setMessageBoardProviders(format));

      setNpsState(prevState => ({
        ...prevState,
        survey: {
          refetch: true
        }
      }));

      notification.success({
        message: 'Success!',
        description: 'Assigned providers has been updated.'
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong while assigning provider.'
      });
    } finally {
      setFormLoading(false);
      handleShowAssignProvider();
    }
  };

  const renderProviderOptions = () => {
    const parsedAdmins = camelCaseObjectKeys(admins);
    const activeAdmins = getActiveProviders(parsedAdmins);

    return activeAdmins
      .filter(admin => !selectedProviderEmails.includes(admin.sub))
      .sort((a, b) => (a.firstName > b.firstName) - (a.firstName < b.firstName))
      .map((admin, i) => {
        return (
          <Select.Option key={i} value={admin.sub}>
            {admin.firstName} {admin.lastName} (
            {admin.phoneNumber || admin.emailAddress})
          </Select.Option>
        );
      });
  };

  const renderProviderItems = providers => {
    if (!providers) {
      return null;
    }

    const parsedAdmins = camelCaseObjectKeys(admins);
    const activeAdmins = getActiveProviders(parsedAdmins);
    const mappedAdmins = [
      ...providers.map(item => {
        const profile = {
          ...item
        };

        if (!item.firstName || !item.lastName) {
          const admin = activeAdmins.find(a => a.sub === item.sub);

          if (admin) {
            profile.firstName = admin.firstName;
            profile.lastName = admin.lastName;
          }
        }

        return profile;
      })
    ].sort((a, b) => {
      const aFname = a.firstName.trim().toLowerCase() || '';
      const aLname = a.lastName.trim().toLowerCase() || '';

      const bFname = b.firstName.trim().toLowerCase() || '';
      const bLname = b.lastName.trim().toLowerCase() || '';

      return aFname + aLname > bFname + bLname ? 1 : -1;
    });

    return mappedAdmins.map(provider => (
      <div className="provider-tag" key={provider.sub}>
        <Typography.Text>
          {provider.firstName} {provider.lastName}
          <span
            className="provider-tag-remove-btn"
            onClick={() => handleRemoveProvider(provider.sub)}
          >
            <CloseOutlined />
          </span>
        </Typography.Text>
      </div>
    ));
  };

  const formatMessage = therapistsList => {
    const formattedMessage = {
      Action: MANAGE_BOARDS,
      therapists: therapistsList,
      boardData: {
        to: patient.Sub,
        firstName: patient.FirstName,
        lastName: patient.LastName,
        GroupId: GroupId
      }
    };
    return formattedMessage;
  };

  const getProviders = providers => {
    return providers && providers.map(provider => provider.sub);
  };

  const getActiveProviders = admins => {
    if (Array.isArray(admins)) {
      return admins.filter(
        admin => admin.active === true || admin.active === 'true'
      );
    }
    return [];
  };

  if (!showAssignProvider) return null;

  return (
    <Modal
      title="Assign Providers"
      visible={showAssignProvider}
      onCancel={handleShowAssignProvider}
      maskClosable={false}
      footer={[
        <Button
          key="cancel"
          size="large"
          type="default"
          className="ptw-btn"
          onClick={handleShowAssignProvider}
          disabled={formLoading}
        >
          Cancel
        </Button>,
        <Button
          key="assign"
          size="large"
          type="primary"
          className="ptw-btn btn-primary"
          onClick={updateAssignedProvider}
          loading={formLoading}
        >
          Save Changes
        </Button>
      ]}
      width={700}
    >
      {!loading || (admins && providers) ? (
        <React.Fragment>
          <Select
            size="large"
            placeholder="Assign providers to this patient..."
            showArrow
            showSearch
            style={{ width: '100%' }}
            value={null}
            onChange={handleSelectProviders}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children[0]
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option.props.children[2]
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option.props.children[4]
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {renderProviderOptions()}
          </Select>
          <Divider />
          <Typography.Title level={4}>
            Current Selected Providers
          </Typography.Title>
          <div className="providers-tags-container">
            {renderProviderItems(providers)}
          </div>
        </React.Fragment>
      ) : (
        <Typography.Text>
          <Spin indicator={<LoadingOutlined spin />} /> Loading assign
          providers...
        </Typography.Text>
      )}
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    socket: state.socket,
    admins: state.users.admins,
    patient: state.patients.currentPatient,
    visibleProfile: state.visibleProfile
  };
};

export default connect(mapStateToProps)(AssignProviderModal);
