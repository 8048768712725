import React, { Component } from 'react'
import { connect } from 'react-redux';
import 'isomorphic-fetch';
import {Button, Modal} from "react-bootstrap";
import Celebrate from "../../../img/celebrate.png";

class Notifications extends Component{

    constructor(props){
        super(props)
        this.state = {
            hideImage: false,
        }
    }

    handleImageError = ()=>{
        this.setState({hideImage: true})
    };

    render(){
        const {onClose, profile} = this.props;
        const {hideImage} = this.state;
        const style = {
            maxHeight: "90px",
            maxWidth: "90px",
            borderRadius: "60px"
        };

        return (

                <Modal.Dialog style={{textAlign:"center"}}>

                    <Modal.Header style={{backgroundImage:`url(${Celebrate})`,height:"110px", backgroundPositionY:"-21px",backgroundRepeatY:"no-repeat"}}>
                        <Modal.Title ><h1 style={{backgroundColor:"white", width:"40%", marginLeft:"30%"}}>Nice Job!</h1></Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        { !hideImage && <img style={style} onError={this.handleImageError} alt={""}
                         src={`https://d2p805pqn3eul9.cloudfront.net/${profile.GroupId}/${profile.Creator}.jpg`}/>
                        }
                        &nbsp;
                        &nbsp;
                        <p>Your Therapist has been notified that you've completed your exercises for the day!</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle={'primary'} onClick={()=>{onClose()}}>Close</Button>
                    </Modal.Footer>
                </Modal.Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile: state.visibleProfile
    }
}

export default connect(mapStateToProps)(Notifications);