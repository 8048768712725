import React from 'react'
import Notifications from 'react-notification-system-redux'
import { connect } from 'react-redux'

class Alerts extends React.Component {
    render() {
        return (
            <div>
                <Notifications notifications={this.props.alerts} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        alerts: [...state.alerts]
    }
};
export default connect(mapStateToProps)(Alerts);