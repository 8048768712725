import React, { Component } from 'react';
import { Table } from 'antd';
import { titleCase } from './stringHelpers';
import './styles.css';

class PTWTable extends Component {
  constructor(props) {
    super(props);

    const sortBy = props.sortByDefault;
    const ascending = true;
    this.state = {
      sortBy,
      ascending,
      items: this.sort(props.items, sortBy, ascending),
    };

    this.toggleSort = this.toggleSort.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { sortBy, ascending } = this.state;
    this.setState({
      items: this.sort(nextProps.items, sortBy, ascending),
    });
  }

  toggleSort(field) {
    return () => {
      this.setState(prevState => {
        const ascending =
          prevState.sortBy === field ? !prevState.ascending : true;
        return {
          ascending,
          items: this.sort(prevState.items, field, ascending),
          sortBy: field,
        };
      });
    };
  }

  compareFunction(sortBy, ascending) {
    return (item1, item2) => {
      if (item1[sortBy] < item2[sortBy]) {
        return ascending ? -1 : 1;
      } else if (item1[sortBy] > item2[sortBy]) {
        return ascending ? 1 : -1;
      }
      return 0;
    };
  }

  sort(array, sortBy, ascending) {
    return (array || []).sort(this.compareFunction(sortBy, ascending));
  }

  render() {
    const {
      toggleSort,
      state: { ascending, items, sortBy },
      props: { fields, ...otherProps },
    } = this;

    return (
      <Table
        scroll={{
          x: 'max-content',
        }}
        pagination={false}
        rowClassName={record =>
          record.active === 'false' || record.active === false
            ? 'disabledEntity'
            : ''
        }
        size="small"
        columns={fields}
        dataSource={items}
        sortDirections={['descend', 'ascend']}
        showSorterTooltip={{ target: 'sorter-icon' }}
      />
    );

    // return (
    //   <Table responsive className="table-hover">
    //     <thead>
    //       <tr>
    //         {Object.keys(fields).map((field, i) => (
    //           <th
    //             key={i}
    //             role="button"
    //             onClick={toggleSort(field)}
    //             className={`sort-${sortBy === field ? (ascending ? 'down' : 'up') : 'blank'}`}>
    //             {fields[field]}
    //           </th>
    //         ))}
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {items.map((item, i) => (
    //         <RowComponent item={item} key={i} {...otherProps}>
    //           {Object.keys(fields).map((field, j) => (
    //             <td
    //               key={j}
    //               className={
    //                 item.active === 'false' ||
    //                   item.active === false
    //                   ?
    //                   'disabledEntity'
    //                   :
    //                   ''
    //               }>{(
    //                 typeof (item[field]) === "string"
    //                 && field !== 'EmailAddress'
    //               )
    //                 ? titleCase(item[field])
    //                 : item[field]}</td>
    //           )
    //           )}
    //         </RowComponent>
    //       )
    //       )}
    //     </tbody>
    //   </Table>
    // )
  }
}

export default PTWTable;
