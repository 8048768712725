import { getRecoil } from 'recoil-nexus';
import { toPascalCaseObjKeys } from '../utils/object.utils';
import apiFetch from '../utils/fetch.utils';
import states from '../states';

export const assignCases = async (payload) => {
  try {
    const uri = `groups/${payload.groupId}/patients`;
    const method = 'PUT';

    return apiFetch(uri, method, {
      body: payload
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const update = async (groupId, isNewPatient, sub) => {
  try {
    const patientsState = getRecoil(states.patients);
    const prescriptionState = getRecoil(states.prescription);
    const paramsState = getRecoil(states.params);

    const { form } = prescriptionState;
    const newExerciseList = [...form.prescription.exercises].map((item) => {
      const exercise = {
        ...item
      };

      delete exercise.active;
      delete exercise.created;
      delete exercise.foundational;
      delete exercise.imgBase64;
      delete exercise.isGroupExercise;
      delete exercise.isSelected;
      delete exercise.star;
      delete exercise.searchTerm;
      delete exercise.tag;
      delete exercise.updated;
      delete exercise.imageMirror;
      delete exercise.videoMirror;

      if (!Array.isArray(exercise.completionDates)) {
        return {
          ...exercise,
          completionDates: []
        };
      }

      return exercise;
    });

    const uri = `groups/${groupId}/patients`;
    const method = 'PUT';
    const payload = {
      body: {
        action: prescriptionState.action,
        currentPatient: {
          ...toPascalCaseObjKeys(patientsState.details),
          Sub: sub,
          GroupId: groupId
        },
        field: 'updatedPrescription',
        updatedPrescription: {
          ...form,
          active: true,
          prescription: {
            ...form.prescription,
            exercises: newExerciseList
          }
        },
        isNewPatient
      }
    };

    if (paramsState.caseId) {
      payload.body.updatedPrescription.caseId = paramsState.caseId;
    }

    return apiFetch(uri, method, payload);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const toggle = async (rxId, sub, active) => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id } = groupsState.user;

    const uri = `groups/${id}/patients`;
    const method = 'PUT';
    const payload = {
      body: {
        toggle: true,
        id: rxId,
        sub,
        active
      }
    };

    return apiFetch(uri, method, payload);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getHistory = async (id) => {
  try {
    const uri = `prescription-history?id=${id}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
