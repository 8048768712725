import React, { Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Space, Typography } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import states from '../../../states';

const TimerFooter = ({ isTechRole, isDischarged, handleStartMonitoring }) => {
  const rtm = useRecoilValue(states.rtm);
  const { isMonitoring } = rtm.timer;

  return (
    <Space direction="horizontal" size={7}>
      <Fragment>
        <Button
          danger={isMonitoring}
          shape="circle"
          type="primary"
          className="btn-primary"
          icon={
            isMonitoring ? (
              <Typography.Text style={{ color: '#fff' }}>●</Typography.Text>
            ) : (
              <CaretRightOutlined />
            )
          }
          disabled={isTechRole || isDischarged}
          onClick={() => handleStartMonitoring(!isMonitoring)}
        />
        <Typography>{isMonitoring ? 'Stop' : 'Start'} Monitoring</Typography>
      </Fragment>
    </Space>
  );
};

export default TimerFooter;
