import { createAction } from 'redux-actions';
import { API_REQUEST } from '../../membership/middleware';

const date = new Date();
const completionDate =
  date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();

export const HIDE_NAV_BAR = 'HIDE_NAV_BAR';
export const hideNavBar = bool => createAction(HIDE_NAV_BAR)(bool);

export const SET_RX_LIST = 'SET_RX_LIST';
export const setRxList = rxList => createAction(SET_RX_LIST)(rxList);

export const UPDATE_RX_LIST = 'UPDATE_RX_LIST';
export const updateRxList = (rxId, rxDetails) =>
  createAction(UPDATE_RX_LIST)({ rxId, rxDetails });

export const SET_CURRENT_RX = 'SET_CURRENT_RX';
export const setCurrentRx = rxDetails =>
  createAction(SET_CURRENT_RX)(rxDetails);

export const SET_LOCALE = 'SET_LOCALE';
export const setLocale = locale => createAction(SET_LOCALE)(locale);

export const GET_CLINIC_DETAILS = 'GET_CLINIC_DETAILS';
export const getClinicDetails = clinicId => {
  if (!clinicId) {
    return;
  }
  const clinic = clinicId + '';
  const id = clinic.replace(' ', '%20');
  return {
    [API_REQUEST]: createAction(GET_CLINIC_DETAILS)({
      resource: `get-clinic-details/?id=${id}`,
      method: 'GET'
    })
  };
};

export const GET_CREATOR_PREFERENCES = 'GET_CREATOR_PREFERENCES';
export const getCreatorPreferences = (sub, groupId) => ({
  [API_REQUEST]: createAction(GET_CREATOR_PREFERENCES)({
    resource: `preferences?sub=${encodeURIComponent(sub)}&groupId=${groupId}`,
    method: 'GET'
  })
});

export const COMPLETE_EXERCISE = 'COMPLETE_EXERCISE';
export const completeExercise = (groupName, sub, exerciseId, prescription) => ({
  [API_REQUEST]: createAction(COMPLETE_EXERCISE)({
    resource: `groups/${groupName}/patients/${encodeURIComponent(
      sub
    )}/completeexercise/${exerciseId}?date=${completionDate}${prescription}`,
    method: 'PUT'
  })
});

export const UPDATE_PATIENT_PROFILE = 'UPDATE_PATIENT_PROFILE';
export const updatePatientProfile = profileId => {
  return {
    [API_REQUEST]: createAction(UPDATE_PATIENT_PROFILE)({
      resource: 'users',
      method: 'GET',
      query: `email=${encodeURIComponent(profileId)}`
    })
  };
};

export const SEND_COMMENT = 'SEND_COMMENT';
export const sendComment = (emailAddress, message) => {
  return {
    [API_REQUEST]: createAction(SEND_COMMENT)({
      resource: '/comment',
      method: 'post',
      body: { emailAddress: emailAddress, message: message }
    })
  };
};

export const CHECK_COMPLETE_EXERCISE = 'CHECK_COMPLETE_EXERCISE';
export const checkCompleteExercise = createAction(
  CHECK_COMPLETE_EXERCISE,
  index => ({ index })
);

export const SET_MESSAGE_BADGE = 'SET_MESSAGE_BADGE';
export const setMessageBadge = createAction(SET_MESSAGE_BADGE, count => ({
  count
}));

export const SEND_LOGIN_EVENT = 'SEND_LOGIN_EVENT';
export const sendLoginEvent = (groupName, sub, groupId, emailAddress) => ({
  [API_REQUEST]: createAction(SEND_LOGIN_EVENT)({
    resource: `groups/${groupName}/rtm/datapoints`,
    method: 'POST',
    body: {
      sub: sub,
      groupId: groupId,
      action: 'LOGIN',
      emailAddress: emailAddress
    }
  })
});

export const SEND_EXERCSE_COMPLETE_EVENT = 'SEND_EXERCSE_COMPLETE_EVENT';
export const sendExerciseCompleteEvent = (
  groupName,
  sub,
  groupId,
  emailAddress,
  exerciseId
) => ({
  [API_REQUEST]: createAction(SEND_EXERCSE_COMPLETE_EVENT)({
    resource: `groups/${groupName}/rtm/datapoints`,
    method: 'POST',
    body: {
      sub: sub,
      groupId: groupId,
      action: 'COMPLETE_EXERCISE',
      emailAddress: emailAddress,
      exerciseId: exerciseId
    }
  })
});
