import { GET_PUBLIC_SITE_INFO } from './actions';
import { FULFILLED, PENDING, REJECTED } from 'redux-promise-middleware';
import { themes } from './features/groups/group-design/actions';
import config from './config';
import version from './components/version';
const defaultState = {
  design: {
    theme: themes.darkgray,
    brandType: 'logo',
    logoUrl: `${process.env.REACT_APP_ROOT}/logo.svg`,
    loading: false
  },
  version: version
};

const publicPageReducer = (state = defaultState, { payload, type }) => {
  switch (type) {
    case `${GET_PUBLIC_SITE_INFO}_${PENDING}`:
      return {
        ...state,
        design: {
          ...state.design,
          loading: true
        }
      };

    case `${GET_PUBLIC_SITE_INFO}_${FULFILLED}`:
      let design;

      if (!!payload.design) {
        const { theme, brandType, brandText } = payload.design;

        design = {
          ...state.design,
          brandType,
          theme,
          logoUrl:
            brandType === 'logo'
              ? `${config.root}/grouplogos/${payload.id}.jpg`
              : '',
          brandText: brandType === 'text' ? brandText : '',
          loading: false
        };
      } else {
        design = {
          ...state.design,
          loading: false
        };
      }

      const { name, email, phone } = payload;

      if (name) {
        return {
          ...state,
          name,
          email,
          phone,
          design
        };
      } else {
        return {
          ...state,
          design
        };
      }

    case `${GET_PUBLIC_SITE_INFO}_${REJECTED}`:
      return {
        ...state,
        design: {
          ...state.design,
          loading: false
        }
      };

    default:
      return state;
  }
};

export default publicPageReducer;
