import { Input, Modal, Typography, notification } from 'antd';
import { useState } from 'react';
import services from '../../../services';

const CaseIdModal = ({ visibleProfile, open, onClose, setPath }) => {
  const [valid, setValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');

  const handleChange = (e) => {
    setValid(true);
    setInput(e.target.value);
  };

  const handleBlur = (e) => {
    const value = e.target.value;
    const isValid = /^\d{8}$/.test(value);

    if (!value) {
      setValid(true);
    } else {
      setValid(isValid);
    }
  };

  const handleCancel = () => {
    setInput('');
    setValid(true);
    onClose();
  };

  const handleSubmit = async () => {
    if (!valid || !input) {
      return;
    }

    try {
      setLoading(true);

      const response = await services.patients.getByCaseId(
        input,
        visibleProfile.GroupId
      );

      if (response.status === 200) {
        setPath(`/patients/${response.data.Sub}`);
        handleCancel();
      } else if (response.status === 404) {
        notification.warn({
          message: 'Patient Not Found!',
          description: 'No patient was found with the given case id.'
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'An error occurred while searching for case id.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      destroyOnClose
      title="Search by Prescription Case Id"
      okText="Search"
      open={open}
      confirmLoading={loading}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <div
        style={{
          marginBottom: valid ? 30 : 8
        }}
      >
        <Typography.Text>Prescription Case Id:</Typography.Text>
        <Input
          placeholder="12345678"
          maxLength={8}
          value={input}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{
            borderColor: valid ? '' : 'red',
            marginTop: 2
          }}
        />

        {!valid && (
          <Typography.Text
            style={{
              color: 'red'
            }}
          >
            Case Id must be an 8-digit number.
          </Typography.Text>
        )}
      </div>
    </Modal>
  );
};

export default CaseIdModal;
