import {
  GET_GROUP_EXERCISE,
  GET_GROUP_EXERCISES,
  SEARCH_GROUP_EXERCISES,
  POST_GROUP_EXERCISE,
  UPDATE_GROUP_EXERCISE,
  OPEN_GROUP_EXERCISES_FORM_MODAL,
  CLOSE_GROUP_EXERCISES_FORM_MODAL,
  SET_GROUP_EXERCISE_FORM_TYPE,
  UPDATE_CURRENT_GROUP_EXERCISE,
  IS_SEARCHING,
  DONE_SEARCHING,
    DELETE_GROUP_EXERCISE
} from '../actions/groupExercises'
import { IS_LOADING, DONE_LOADING } from '../actions/groupExercisesLoadingState'
import { FULFILLED, PENDING, REJECTED } from 'redux-promise-middleware'
import { defaultGroupExercise } from '../actions/groupExercises'
import { FormTypes } from "../actions/groups";
import mapModel from '../mapper'

const defaultState = {
  currentGroupExercise: defaultGroupExercise,
  formType: FormTypes.NEW,
  formModalOpen: false,
  successModalOpen: false,
  confirmationModalOpen: false,
  isFetching: false,
  fetched: false,
  isSubmitting: false,
  submitted: false,
  items: {},
  isLoading: false,
  isSearching: false
}

export const groupExercisesReducer = (state = defaultState, action) => {
  const { payload } = action
  let mapped

  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      }

    case `${DELETE_GROUP_EXERCISE}_${FULFILLED}`:

      return {
        ...state,
        isFetching: false,
        fetched: true,
      }

    case DONE_LOADING:
      return {
        ...state,
        isLoading: false
      }

    case `${SEARCH_GROUP_EXERCISES}_${PENDING}`:
    case `${GET_GROUP_EXERCISES}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      }

    case `${SEARCH_GROUP_EXERCISES}_${FULFILLED}`:
    case `${GET_GROUP_EXERCISES}_${FULFILLED}`:

      return {
        ...state,
        isFetching: false,
        fetched: true,
        items:payload.exercises
      }

    case `${GET_GROUP_EXERCISE}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      }

    case `${GET_GROUP_EXERCISE}_${FULFILLED}`:
      mapped = mapModel('exercise', payload.exercise, false)
      return {
        ...state,
        isFetching: false,
        fetched: true,
        items: {
          ...state.items,
          [mapped.id]: mapped
        }
      }

    case `${POST_GROUP_EXERCISE}_${PENDING}`:
      return {
        ...state,
        isSubmitting: true,
        submitted: false
      }

    case `${POST_GROUP_EXERCISE}_${FULFILLED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: true
      }

    case `${POST_GROUP_EXERCISE}_${REJECTED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: false
      }

    case `${UPDATE_GROUP_EXERCISE}_${PENDING}`:
      return {
        ...state,
        isSubmitting: true,
        submitted: false
      }

    case `${UPDATE_GROUP_EXERCISE}_${FULFILLED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: true
      }

    case `${UPDATE_GROUP_EXERCISE}_${REJECTED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: false
      }


    case OPEN_GROUP_EXERCISES_FORM_MODAL:
      return {
        ...state,
        formModalOpen: true
      }

    case CLOSE_GROUP_EXERCISES_FORM_MODAL:
      return {
        ...state,
        formModalOpen: false
      }

    case SET_GROUP_EXERCISE_FORM_TYPE:
      return {
        ...state,
        formType: payload.formType
      }

    case UPDATE_CURRENT_GROUP_EXERCISE:
      return {
        ...state,
        currentGroupExercise: payload.values
      }

    case IS_SEARCHING:
      return {
        ...state,
        isSearching: true
      }

    case DONE_SEARCHING:
      return {
        ...state,
        isSearching: false
      }
      
    default:
      return state
  }
}