import React from 'react';
import { useRecoilValue } from 'recoil';
import { Select } from 'antd';

import states from '../../../states';
import * as reportsUtils from '../../../utils/reports.utils';

const { Option } = Select;

const ProvidersSelect = ({ loading, value, onChange, multiple = false }) => {
  const groups = useRecoilValue(states.groups);

  return (
    <Select
      showSearch
      className="nps-reports-select"
      placeholder="Select Provider"
      optionFilterProp="children"
      {...(multiple && { mode: 'tags' })}
      loading={loading}
      disabled={loading}
      value={value}
      onChange={value => onChange(value)}
      filterOption={(input, option) => {
        const searchInput = input.toLowerCase();
        const isString = typeof option.children === 'string';
        const isObject = typeof option.children === 'object';

        if (isString) {
          return option.children.toLowerCase().includes(searchInput);
        }

        if (isObject) {
          return option.children.props.children
            .toLowerCase()
            .includes(searchInput);
        }
      }}
    >
      {reportsUtils.sortProvidersByStatus(groups.providers).map((item, i) => (
        <Option key={i} value={item.EmailAddress}>
          {!item.Active ? (
            <em
              style={{ color: 'grey' }}
            >{`${item.FirstName} ${item.LastName}`}</em>
          ) : (
            `${item.FirstName} ${item.LastName}`
          )}
        </Option>
      ))}
    </Select>
  );
};

export default ProvidersSelect;
