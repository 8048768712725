import React, { useState } from 'react';
import { Modal, Button, Typography, Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const UploadProfilePhotoModal = (props) => {
  const {
    imgError,
    setImgError,
    dispatch,
    disableProfilePhotoPopup,
    visibleProfile,
  } = props;
  const [checked, setChecked] = useState(false);

  const { EmailAddress, Preferences } = visibleProfile;

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <Modal
      visible={imgError}
      footer={[
        <Link
          to="/profile"
          onClick={() => {
            if (checked) {
              dispatch(disableProfilePhotoPopup(EmailAddress, Preferences));
            }
            setImgError(!imgError);
          }}
        >
          <Button type="primary" size="large">
            Upload Profile Photo{' '}
            <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: 8 }} />
          </Button>
        </Link>,
      ]}
      onCancel={() => {
        if (checked) {
          dispatch(disableProfilePhotoPopup(EmailAddress, Preferences));
        }
        setImgError(!imgError);
      }}
    >
      <Typography.Text style={{ marginBottom: 16 }}>
        Hey! Looks like you didn't set your profile photo yet.
      </Typography.Text>
      <div style={{ marginTop: 16, marginBottom: -16 }}>
        {' '}
        <Checkbox onChange={handleChange}>
          Don't show this message again
        </Checkbox>
      </div>
    </Modal>
  );
};

export default UploadProfilePhotoModal;
