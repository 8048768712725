import React, { useState } from 'react';
import { Card, Space, Tooltip, Typography } from 'antd';
import { connect } from 'react-redux';
import { useRecoilState } from 'recoil';

import { CheckCircleOutlined, RightOutlined } from '@ant-design/icons';

import { formatCategory } from '../../../utils/educationalPdf';

import states from '../../../states';

const AssignPDFCard = ({ item, onPreview, onSelect }) => {
  const [isHovering, setIsHovering] = useState(false);

  const [pdf, setPdf] = useRecoilState(states.pdf);

  const subTitle = formatCategory(item.category);
  const isSelected = pdf.currSelected.find(
    selected => selected.title === item.title
  );

  let hoverTimer;

  const handleMouseOver = hovering => {
    if (hovering) {
      clearTimeout(hoverTimer); // Prevent flickering when moving to overlay
      setIsHovering(true);
    } else {
      hoverTimer = setTimeout(() => {
        setIsHovering(false);
      }, 100); // Slight delay to prevent flicker
    }
  };

  return (
    <Tooltip title={item.title}>
      <Card
        className={`pdf-cat-card ${isSelected ? 'selected' : ''}`}
        hoverable
        onMouseOver={() => handleMouseOver(true)}
        onMouseOut={() => handleMouseOver(false)}
      >
        <span className="pdf-select-overlay" onClick={() => onSelect(item)} />
        {isSelected && <CheckCircleOutlined className="pdf-cat-card-icon" />}
        <Space direction="vertical" size={0} className={isSelected && 'mr-4'}>
          <div className="pdf-cat-card-title">
            <Typography.Title level={5}>{item.title}</Typography.Title>
          </div>
          <Typography.Text
            type="secondary"
            style={{ textTransform: 'uppercase', fontSize: 12 }}
          >
            {subTitle}
          </Typography.Text>
        </Space>
        {isHovering && (
          <div className="pdf-preview-overlay" onClick={() => onPreview(item)}>
            <Typography.Text>
              Preview <RightOutlined />
            </Typography.Text>
          </div>
        )}
      </Card>
    </Tooltip>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
});

export default connect(mapStateToProps)(AssignPDFCard);
