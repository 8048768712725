import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { currentUserGroupSelector } from '../exercise-library/selectors';
import { closeModal, deletePatient } from './actions/patients';
import { success, error } from 'react-notification-system-redux';
import DeleteModal from './Modals/DeleteModal';
import { Redirect } from 'react-router-dom';
class DeletePatientWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      loading: false
    };

    this.onHide = this.onHide.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
  }

  onHide() {
    const { handleShowDeletePatient, dispatch } = this.props;
    handleShowDeletePatient();
    dispatch(closeModal);
  }

  async deleteAction() {
    const { dispatch, groupName, currentPatient } = this.props;

    try {
      this.setState({ loading: true });

      await dispatch(deletePatient(groupName, currentPatient));

      dispatch(
        success({
          title: 'User successfully deleted',
          position: 'tr'
        })
      );

      this.setState({ redirect: true });
    } catch (e) {
      dispatch(
        error({
          title: 'User failed to delete',
          position: 'tr'
        })
      );
    } finally {
      this.setState({ loading: false });
      this.onHide();
    }
  }

  render() {
    const { redirect, loading } = this.state;
    const { showDeletePatient } = this.props;

    return (
      <React.Fragment>
        {redirect && <Redirect to={'/patients'} />}
        <DeleteModal
          showDeletePatient={showDeletePatient}
          loading={loading}
          deleteAction={this.deleteAction}
          onHide={this.onHide}
          onExited={this.onExited}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  group: currentUserGroupSelector(state),
  currentPatient: state.patients.currentPatient
});

export default withRouter(connect(mapStateToProps)(DeletePatientWindow));
