import {
  CLOSE_MODAL,
  SEARCH_GROUPS,
  GET_GROUP,
  GET_GROUPS,
  OPEN_MODAL,
  POST_GROUP,
  SET_GROUP_FORM_TYPE,
  UPDATE_CURRENT_GROUP,
  FormTypes,
  defaultGroup,
  SAVE_MODAL,
  NO_SAVE_MODAL,
  GET_BILLING,
  CHANGE_GROUP,
} from '../actions/groups'
import { FULFILLED, PENDING, REJECTED } from 'redux-promise-middleware'
import { RESET_GROUPS, DONE_LOADING, IS_LOADING, IS_SEARCHING, DONE_SEARCHING } from '../actions/groupsLoadingState'
import mapModel from '../mapper'

const defaultState = {
  modalOpen: false,
  currentGroup: defaultGroup,
  formType: FormTypes.NEW,
  isFetching: false,
  fetched: false,
  isSubmitting: false,
  submitted: false,
  isSearching: false,
  saveModal: false,
  items: {}
}

export const groupsReducer = (state = defaultState, action) => {
  const { payload } = action
  let mapped
  let items

  switch (action.type) {

    case RESET_GROUPS:
      return {
        ...defaultState
      }
    case DONE_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      }

    case `${SEARCH_GROUPS}_${PENDING}`:
      return {
        ...state,
        isFetching: false,
        isSearching: true,
        fetched: true,
      }
    case `${SEARCH_GROUPS}_${FULFILLED}`:
      items = {}
      payload.groups.forEach(g => {
          mapped = mapModel('group', g, false)
          items[mapped.name] = { ...mapped, persisted: true }
      })
      return {
        ...state,
        isFetching: false,
        fetched: true,
        isSearching: true,
        items
      }
    case `${CHANGE_GROUP}_${FULFILLED}`:

      return {
        ...state
      }
    case `${GET_GROUP}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      }

    case `${GET_GROUP}_${FULFILLED}`:
      mapped = mapModel('group', payload.data, false)
      return {
        ...state,
        isFetching: false,
        fetched: true,
        items: {
          ...state.items,
          [mapped.name]: { ...mapped, persisted: true }

        }
      }

    case `${GET_GROUPS}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      }

    case `${GET_GROUPS}_${FULFILLED}`:
      items = {}
      payload.groups.forEach(g => {
        mapped = mapModel('group', g, false)
        items[mapped.name] = { ...mapped, persisted: true }
      })

      return {
        ...state,
        isFetching: false,
        fetched: true,
        items
      }


    case `${GET_BILLING}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      }

    case `${GET_BILLING}_${FULFILLED}`:

      return {
        ...state,
        isFetching: false,
        fetched: true,
        billingGroups:payload
      }

    case `${POST_GROUP}_${PENDING}`:
      return {
        ...state,
        isSubmitting: true,
        submitted: false
      }

    case `${POST_GROUP}_${FULFILLED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: true
      }

    case `${POST_GROUP}_${REJECTED}`:
      return {
        ...state,
        isSubmitting: false
      }

    case OPEN_MODAL:
      return {
        ...state,
        modalOpen: true
      }

    case CLOSE_MODAL:
      return {
        ...state,
        modalOpen: false
      }

    case SET_GROUP_FORM_TYPE:
      return {
        ...state,
        formType: payload.formType,
        currentGroup: payload.values
      }

    case UPDATE_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: payload.values
      }

    case IS_SEARCHING:
      return {
        ...state,
        isSearching: true
      }

    case DONE_SEARCHING:
      return {
        ...state,
        isSearching: false
      }

    case SAVE_MODAL:
      return {
        ...state,
        saveModal: true
      }

    case NO_SAVE_MODAL:
      return {
        ...state,
        saveModal: false
      }

    default:
      return state
  }
}
