import React from 'react';
import { Image } from 'react-bootstrap';
import achievementsImages from './AchievementsImages';
import achievementPlaceholder from '../../../img/achievements/lock-achievement.png';
import TextTranslation from '../../../I18n/TranslateText';
import './styles.css';

const ImageComponent = ({ achievements, category, valueToCheck, locale }) => {
  if (
    achievements &&
    achievements[category] &&
    achievements[category].includes(valueToCheck)
  ) {
    return (
      <div className="ptw-card-achievement">
        <div className="image">
          <Image src={achievementsImages[category][valueToCheck].image_name} />
        </div>
        <div className="data">
          <TextTranslation
            currLocale={locale}
            currText={achievementsImages[category][valueToCheck].title}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="ptw-card-achievement">
        <div className="image">
          <Image src={achievementPlaceholder} />
        </div>
        <div className="data">
          <TextTranslation
            currLocale={locale}
            currText={achievementsImages[category][valueToCheck].title}
          />
        </div>
      </div>
    );
  }
};

export default ImageComponent;
