import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Space,
  Tooltip,
  Button,
  Typography,
  Switch,
  Modal,
  Spin,
  Menu,
  Dropdown,
  Row,
} from 'antd';
import v from 'voca';
import {
  PrinterOutlined,
  EditOutlined,
  FileOutlined,
  BookOutlined,
  LoadingOutlined,
  FileAddOutlined,
  MoreOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import '../../../../styles/atomic.css';
import { exportPrescriptionToTemplate } from '../../../patients/actions/prescription';
import { currentUserGroupSelector } from '../../../exercise-library/selectors';
import DocumentNowModal from '../../../patients/Modals/DocumentNowModal';
import { exportHEP } from '../../../patients/actions/prompt';

import setNotification from '../../../../utils/setNotification.utils';
import ExportHEPModal from '../../../patients/Modals/ExportHEPModal';
import ExportHEPGeneratedIdModal from '../../../patients/Modals/ExportHEPGeneratedIdModal';
import PopupBlockerModal from '../../../patients/Modals/PopupBlockerModal';
import PatientExercisesGrid from '../../../patients/PatientExercisesGrid';
import ExerciseDetailModal from '../../../patients/Modals/ExerciseDetailModal';
import PrintPreviewModal from '../PrintPreviewModal';
import CaseIdModal from '../../../../components/CaseIdModal';
import PrescriptionHistoryDrawer from '../../PrescriptionHistoryDrawer';

import { getRecoil, setRecoil } from 'recoil-nexus';
import { toCamelCaseObjKeys } from '../../../../utils/object.utils';
import states from '../../../../states';
import services from '../../../../services';

const loadingToggleRx = () =>
  Modal.info({
    title: (
      <Typography.Title level={1} className="text-center">
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 48 }} />}
          size="large"
        />
      </Typography.Title>
    ),
    content: (
      <Typography.Paragraph className="text-center">
        Updating prescription, please wait...
      </Typography.Paragraph>
    ),
    centered: true,
    okButtonProps: {
      style: {
        display: 'none',
      },
    },
    icon: null,
  });

class PrescriptionDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      base64images: [],
      page: 1,
      generatingPDF: false,
      showPopupBlockerModal: false,
      showDocumentNowModal: false,
      showExportHEPModal: false,
      showExportHEPGeneratedIdModal: false,
      generatedId: null,
      videoPreview: false,
      printPreview: false,
      previewEx: null,
      loadingExport: false,
      rxHistoryModal: false,
    };

    this.printRef = React.createRef();
  }

  componentDidMount() {
    this.getPatientRxHistory();
  }

  getPatientRxHistory = async () => {
    const { prescriptionItem, isHistory } = this.props;

    if (isHistory) return;

    try {
      const response = await services.prescription.getHistory(
        prescriptionItem.id
      );

      const prescription = getRecoil(states.prescription);

      setRecoil(states.prescription, {
        ...prescription,
        history: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  hidePopupBlockerModal = () => {
    this.setState({ showPopupBlockerModal: false });
  };

  hideDocumentNow = () => {
    this.setState({ showDocumentNowModal: false });
  };

  createTemplate = prescription => {
    const { history, dispatch, group } = this.props;
    dispatch(exportPrescriptionToTemplate(prescription));

    history.push('/patients?toTemplate=true');
  };

  documentNow = () => {
    const { group } = this.props;

    this.setState({ showDocumentNowModal: true });
  };

  previewVideo = ex => {
    this.setState({ videoPreview: true, previewEx: ex });
  };

  closePreviewVideo = () => {
    this.setState({ videoPreview: false, previewEx: null });
  };

  togglExportHEPModal = () => {
    const { showExportHEPModal } = this.state;
    this.setState({
      showExportHEPModal: !showExportHEPModal,
    });
  };

  togglExportHEPGeneratedIdModal = () => {
    const { showExportHEPGeneratedIdModal } = this.state;
    this.setState({
      showExportHEPGeneratedIdModal: !showExportHEPGeneratedIdModal,
    });
  };

  handleSetExportData = async data => {
    const { dispatch, currentPatient } = this.props;

    if (data && currentPatient?.Sub) {
      try {
        this.setState({
          loadingExport: true,
        });

        const response = await dispatch(
          exportHEP({
            Sub: currentPatient.Sub,
            HEP: data,
          })
        );

        if (response.value.message === 'Success') {
          try {
            navigator.clipboard.writeText(response.value.data);
            setNotification(
              'success',
              'Success!',
              'Export HEP Id has been generated and copied to clipboard.'
            );
          } catch (error) {
            const textarea = document.createElement('textarea');

            textarea.value = response.value.data;
            textarea.style.position = 'fixed';
            textarea.style.left = '-9999px';

            document.body.appendChild(textarea);
            textarea.select();

            try {
              document.execCommand('copy');
              setNotification(
                'success',
                'Success!',
                'Export HEP Id has been generated and copied to clipboard.'
              );
            } catch (err) {
              console.error('Failed to Copy: ', err);
            }

            document.body.removeChild(textarea);
          }
        }
      } catch (error) {
        setNotification(
          'error',
          'Error!',
          'An error occurred while exporting HEP.'
        );
      } finally {
        this.setState({
          loadingExport: false,
          showExportHEPModal: false,
        });
      }
    }
  };

  handleTogglePrescription = async checked => {
    const { prescriptionItem, currentPatient } = this.props;
    if (prescriptionItem) {
      const loadingToggle = loadingToggleRx();

      try {
        const { id } = prescriptionItem;
        const response = await services.prescription.toggle(
          id,
          currentPatient.Sub,
          checked
        );

        const prescription = getRecoil(states.prescription);
        const rxListCopy = prescription.list.slice();
        const updatedRxList = [...rxListCopy];
        const data = toCamelCaseObjKeys(response.data);

        if (!id) {
          updatedRxList.shift();
          updatedRxList.push(data);
        } else {
          const index = rxListCopy.findIndex(rx => rx.id === data.id);
          updatedRxList[index] = data;
        }

        setRecoil(states.prescription, {
          ...prescription,
          form: data,
          list: updatedRxList,
        });

        setNotification(
          'success',
          'Success!',
          checked
            ? 'Prescription has been enabled.'
            : 'Prescription has been disabled.'
        );
      } catch (error) {
        setNotification(
          'error',
          'Error!',
          'An error occurred while updating prescription.'
        );
      } finally {
        loadingToggle.destroy();
      }
    }
  };

  render() {
    const {
      siteDesign,
      currentPatient,
      visibleProfile,
      visibleProfile: { GroupInfo },
      dispatch,
      onEditClick,
      prescriptionItem,
      isHistory = false,
    } = this.props;
    const {
      showPopupBlockerModal,
      showDocumentNowModal,
      showExportHEPModal,
      showExportHEPGeneratedIdModal,
      videoPreview,
      printPreview,
      previewEx,
      generatedId,
      rxHistoryModal,
    } = this.state;
    const prescription = getRecoil(states.prescription);
    const isRxActive =
      prescriptionItem && prescriptionItem.hasOwnProperty('active')
        ? prescriptionItem.active
        : true;

    const printProps = {
      currentPatient: {
        ...currentPatient,
        Creator: visibleProfile.EmailAddress,
        GroupInfo: {
          ...currentPatient.GroupInfo,
          PrintEmail: currentPatient.GroupInfo?.PrintEmail || 'provider',
        },
      },
      prescription: prescriptionItem.prescription,
      visibleProfile,
      siteDesign,
    };

    const rxId = prescriptionItem.id;
    const rxItem = prescription.list.find(rx => rx.id === rxId);
    const rxCaseId = rxItem?.caseId;
    const isATIGroup =
      visibleProfile.GroupId === 'c9d548b4-e3e8-445f-89db-ce049c1098fb';
    const showCaseId =
      !!GroupInfo.EnableRaintree ||
      (isATIGroup &&
        !!visibleProfile.CaseId?.Required &&
        !visibleProfile.CaseId?.ExcludedLocations?.includes(
          currentPatient?.Clinic
        ));

    if (isHistory) {
      return (
        <React.Fragment>
          {videoPreview && (
            <ExerciseDetailModal
              currentExercise={previewEx}
              closeModal={this.closePreviewVideo}
              favorites={null}
            />
          )}
          <Space direction="vertical" size={16} className="full-width">
            {prescriptionItem.name && (
              <div>
                <Typography.Text type="secondary">Name</Typography.Text>
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                  {prescriptionItem.name}
                </Typography.Title>
              </div>
            )}

            {prescriptionItem &&
              prescriptionItem.prescription.exercises &&
              prescriptionItem.prescription.exercises[0] &&
              !prescriptionItem.prescription.exercises[0]['frequency'] && (
                <div>
                  <Typography.Text type="secondary">Frequency</Typography.Text>
                  <Typography.Paragraph>
                    {prescriptionItem.prescription.perdiem
                      ? `${prescriptionItem.prescription.frequency}x per ${prescriptionItem.prescription.perdiem}`
                      : v.capitalize(prescriptionItem.frequency)}
                  </Typography.Paragraph>
                </div>
              )}

            {prescriptionItem && prescriptionItem.prescription.instructions && (
              <div>
                <Typography.Text type="secondary">Instructions</Typography.Text>
                <Typography.Paragraph>
                  {v.capitalize(prescriptionItem.prescription.instructions)}
                </Typography.Paragraph>
              </div>
            )}

            {(!!GroupInfo.EnablePrompt || !!GroupInfo.EnableRaintree) &&
              !!rxCaseId && (
                <div>
                  <Typography.Text type="secondary">Case Id</Typography.Text>
                  <Typography.Paragraph>{rxCaseId}</Typography.Paragraph>
                </div>
              )}

            {prescriptionItem && prescriptionItem.prescription.exercises && (
              <div style={{ marginTop: 24 }}>
                <PatientExercisesGrid
                  notClickable={true}
                  exercises={prescriptionItem.prescription.exercises}
                  displayAddCustomButton={false}
                  groupId={currentPatient.GroupId}
                  fromPatientPrescription={true}
                  onExerciseCardClick={this.previewVideo}
                  onExerciseTitleClick={this.previewVideo}
                />
              </div>
            )}
          </Space>
        </React.Fragment>
      );
    }

    const rxDropdown = (
      <Menu className="patient-profile-dropdown">
        <Menu.Item
          key="edit-rx"
          onClick={onEditClick}
          disabled={!isRxActive}
          icon={<EditOutlined style={{ fontSize: 18 }} />}
        >
          Edit Prescription
        </Menu.Item>

        <Menu.Item
          key="print-rx"
          onClick={() => this.setState({ printPreview: true })}
          icon={<PrinterOutlined style={{ fontSize: 18 }} />}
        >
          Print Prescription
        </Menu.Item>

        <Menu.Item
          key="document"
          onClick={() => {
            this.documentNow(prescriptionItem.prescription);
          }}
          icon={<FileOutlined style={{ fontSize: 18 }} />}
        >
          Document
        </Menu.Item>

        <Menu.Item
          key="save-as-template"
          onClick={() => {
            this.createTemplate(prescriptionItem.prescription);
          }}
          icon={<BookOutlined style={{ fontSize: 18 }} />}
        >
          Save as Template
        </Menu.Item>

        {showCaseId && (
          <CaseIdModal
            component={
              <Menu.Item
                key="assign-case-id"
                icon={
                  <FileAddOutlined
                    style={{
                      fontSize: 18,
                    }}
                  />
                }
              >
                Assign Case Id
              </Menu.Item>
            }
            prescriptionId={prescriptionItem.id}
            patient={currentPatient}
          />
        )}

        {!!prescription.history.length && (
          <Menu.Item
            key={'view-rx-history'}
            icon={<HistoryOutlined style={{ fontSize: 18 }} />}
            onClick={() => this.setState({ rxHistoryModal: true })}
          >
            View Prescription History
          </Menu.Item>
        )}

        {!!GroupInfo.EnablePrompt && (
          <Menu.Item
            key="export-to-visit-in-prompt"
            onClick={this.togglExportHEPModal}
            icon={
              <img
                src="https://ptw-mobile-images.s3.amazonaws.com/prompt/Brand+Mark+Red.png"
                alt="prompt-logo"
                style={{ height: 18, width: 'auto' }}
              />
            }
          >
            Export to Visit in Prompt
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <React.Fragment>
        <PrintPreviewModal
          currentPatient={currentPatient}
          open={printPreview}
          handleClose={() => this.setState({ printPreview: false })}
          prescription={prescriptionItem.prescription}
          printProps={printProps}
        />
        {videoPreview && (
          <ExerciseDetailModal
            currentExercise={previewEx}
            closeModal={this.closePreviewVideo}
            favorites={null}
          />
        )}

        {showPopupBlockerModal && (
          <PopupBlockerModal closeWindow={this.hidePopupBlockerModal} />
        )}

        {showDocumentNowModal && (
          <DocumentNowModal
            dispatch={dispatch}
            prescription={prescriptionItem.prescription}
            closeWindow={this.hideDocumentNow}
          />
        )}

        {showExportHEPModal && (
          <ExportHEPModal
            togglExportHEPModal={this.togglExportHEPModal}
            exercises={prescriptionItem.prescription.exercises}
          />
        )}

        {showExportHEPGeneratedIdModal && (
          <ExportHEPGeneratedIdModal
            showExportHEPGeneratedIdModal={showExportHEPGeneratedIdModal}
            togglExportHEPGeneratedIdModal={this.togglExportHEPGeneratedIdModal}
            generatedId={generatedId}
          />
        )}
        <PrescriptionHistoryDrawer
          currentRx={rxItem}
          isOpen={rxHistoryModal}
          handleClose={() => this.setState({ rxHistoryModal: false })}
        />
        <Row
          className="patient-rx-action-buttons hide-dt flex-mb"
          align="middle"
          justify="end"
          style={{ marginBottom: 16 }}
        >
          <Space direction="horizontal" size={20}>
            {prescription.list.length > 1 && (
              <Tooltip title="Enable/Disable Prescription">
                <Switch
                  defaultChecked={isRxActive}
                  onChange={this.handleTogglePrescription}
                />
              </Tooltip>
            )}

            <Dropdown
              overlay={rxDropdown}
              placement="bottomRight"
              trigger={['click']}
            >
              <Button
                shape="circle"
                type="link"
                className="btn-link"
                icon={<MoreOutlined style={{ fontSize: 24 }} />}
              />
            </Dropdown>
          </Space>
        </Row>

        <Row
          className="patient-rx-action-buttons hide-mb"
          align="middle"
          justify="end"
          style={{ marginBottom: 12 }}
        >
          <Space direction="horizontal" size={20}>
            {prescription.list.length > 1 && (
              <Tooltip title="Enable/Disable Prescription">
                <Switch
                  defaultChecked={isRxActive}
                  onChange={this.handleTogglePrescription}
                />
              </Tooltip>
            )}

            <Tooltip title="Edit Prescription">
              <Button
                onClick={onEditClick}
                disabled={!isRxActive}
                shape="circle"
                type="link"
                className="btn-link"
                icon={<EditOutlined style={{ fontSize: 24 }} />}
              />
            </Tooltip>

            <Tooltip title="Print Prescription">
              <Button
                shape="circle"
                type="link"
                className="btn-link"
                onClick={() => this.setState({ printPreview: true })}
                icon={<PrinterOutlined style={{ fontSize: 24 }} />}
              />
            </Tooltip>

            <Tooltip title="Document">
              <Button
                onClick={() => {
                  this.documentNow(prescriptionItem.prescription);
                }}
                shape="circle"
                type="link"
                className="btn-link"
                icon={<FileOutlined style={{ fontSize: 24 }} />}
              />
            </Tooltip>

            <Tooltip title="Save as Template">
              <Button
                onClick={() => {
                  this.createTemplate(prescriptionItem.prescription);
                }}
                shape="circle"
                type="link"
                className="btn-link"
                icon={<BookOutlined style={{ fontSize: 24 }} />}
              />
            </Tooltip>

            {showCaseId && (
              <CaseIdModal
                component={
                  <Tooltip title="Assign Case Id">
                    <Button
                      shape="circle"
                      type="link"
                      className="btn-link"
                      icon={
                        <FileAddOutlined
                          style={{
                            fontSize: 24,
                          }}
                        />
                      }
                    />
                  </Tooltip>
                }
                prescriptionId={prescriptionItem.id}
                patient={currentPatient}
              />
            )}

            {!!prescription.history.length && (
              <Tooltip title="View Prescription History">
                <Button
                  onClick={() => this.setState({ rxHistoryModal: true })}
                  shape="circle"
                  type="link"
                  className="btn-link"
                  icon={<HistoryOutlined style={{ fontSize: 24 }} />}
                />
              </Tooltip>
            )}

            {!!GroupInfo.EnablePrompt && (
              <Tooltip title="Export to Visit in Prompt">
                <Button
                  onClick={this.togglExportHEPModal}
                  shape="circle"
                  type="link"
                  className="btn-link"
                  style={{ marginTop: -12 }}
                  icon={
                    <img
                      src="https://ptw-mobile-images.s3.amazonaws.com/prompt/Brand+Mark+Red.png"
                      alt="prompt-logo"
                      style={{ height: 30, width: 'auto' }}
                    />
                  }
                />
              </Tooltip>
            )}
          </Space>
        </Row>
        <Space direction="vertical" size={16} className="full-width">
          {prescriptionItem.name && (
            <div>
              <Typography.Text type="secondary">Name</Typography.Text>
              <Typography.Title level={5} style={{ marginTop: 0 }}>
                {prescriptionItem.name}
              </Typography.Title>
            </div>
          )}

          {prescriptionItem &&
            prescriptionItem.prescription.exercises &&
            prescriptionItem.prescription.exercises[0] &&
            !prescriptionItem.prescription.exercises[0]['frequency'] && (
              <div>
                <Typography.Text type="secondary">Frequency</Typography.Text>
                <Typography.Paragraph>
                  {prescriptionItem.prescription.perdiem
                    ? `${prescriptionItem.prescription.frequency}x per ${prescriptionItem.prescription.perdiem}`
                    : v.capitalize(prescriptionItem.frequency)}
                </Typography.Paragraph>
              </div>
            )}

          {prescriptionItem && prescriptionItem.prescription.instructions && (
            <div>
              <Typography.Text type="secondary">Instructions</Typography.Text>
              <Typography.Paragraph>
                {v.capitalize(prescriptionItem.prescription.instructions)}
              </Typography.Paragraph>
            </div>
          )}

          {(!!GroupInfo.EnablePrompt || !!GroupInfo.EnableRaintree) &&
            !!rxCaseId && (
              <div>
                <Typography.Text type="secondary">Case Id</Typography.Text>
                <Typography.Paragraph>{rxCaseId}</Typography.Paragraph>
              </div>
            )}

          {prescriptionItem && prescriptionItem.prescription.exercises && (
            <div style={{ marginTop: 24 }}>
              <PatientExercisesGrid
                notClickable={true}
                exercises={prescriptionItem.prescription.exercises}
                displayAddCustomButton={false}
                groupId={currentPatient.GroupId}
                fromPatientPrescription={true}
                onExerciseCardClick={this.previewVideo}
                onExerciseTitleClick={this.previewVideo}
              />
            </div>
          )}
        </Space>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    visibleProfile: state.visibleProfile,
    currentPatient: state.patients.currentPatient,
    printModalOpen: state.patients.printModalOpen,
    siteDesign: state.publicPage.design,
    group: currentUserGroupSelector(state),
  };
}

export default withRouter(connect(mapStateToProps)(PrescriptionDetails));
