import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, Col, Row, Table } from 'react-bootstrap';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import SearchBox from '../../../components/SearchBox';
import { getBilling } from '../actions/groups';
import Loading from '../../../components/Loading';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import DropdownNavigation from '../../../components/DropdownNavigation';

class Billing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      searchTerm: null,
    };

    this.props.dispatch(getBilling());
  }

  componentDidUpdate() {
    const { isFetching } = this.state;
    const { billingGroups } = this.props;

    if (!isFetching) {
      return;
    }

    if (isFetching && billingGroups) {
      this.setState({ isFetching: false });
    }
  }

  filterUsers = (groups) => {
    if (!groups) {
      return [];
    }

    const mappedGroups = groups.map((group) => {
      this.countUsers(group);
      return group;
    });

    return mappedGroups;
  };

  countUsers = (group) => {
    const combined = _.concat(group.GAS, group.PTS);

    let pts = 0,
      ptas = 0,
      techs = 0,
      iPts = 0,
      iPtas = 0,
      iTechs = 0;

    _.forEach(combined, (user) => {
      if (!user) return;

      const { BillingRole, Active } = user;

      if (BillingRole === 'PT') {
        if (Active === true || Active === 'true') pts = pts + 1;
        else iPts = iPts + 1;
      } else if (BillingRole === 'PTA') {
        if (Active === true || Active === 'true') ptas = ptas + 1;
        else iPtas = iPtas + 1;
      } else if (BillingRole === 'TECH') {
        if (Active === true || Active === 'true') techs = techs + 1;
        else iTechs = iTechs + 1;
      } else {
        if (Active === true || Active === 'true') pts = pts + 1;
        else iPts = iPts + 1;
      }
    });

    group.pts = pts;
    group.ptas = ptas;
    group.techs = techs;
    group.iPts = iPts;
    group.iPtas = iPtas;
    group.iTechs = iTechs;

    return group;
  };

  searchFilter = (groups, searchTerm) => {
    const filteredGroups = [];
    for (let index in groups) {
      const item = groups[index];
      const groupName = item.name;
      if (groupName.includes(searchTerm)) {
        filteredGroups.push(item);
      }
    }
    return filteredGroups;
  };

  handleSearchTermChange = (event) => {
    const searchTerm = event.target.value;

    this.setState({ searchTerm: searchTerm });
  };

  render() {
    const { isFetching } = this.state;
    const { billingGroups } = this.props;
    const groups = this.filterUsers(billingGroups);

    return (
      <React.Fragment>
        <DropdownNavigation />
        <div className="ptw-main-body">
          {' '}
          <Link to="/administrator">
            <Button
              type="link"
              className="btn-link dashboard-back-btn"
              size="large"
            >
              <ArrowLeftOutlined /> Back
            </Button>
          </Link>
          <Row>
            <Col mdOffset={4} md={2}>
              <Breadcrumb>
                <Breadcrumb.Item active>Billing</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col mdOffset={2} md={4}>
              <SearchBox
                onChange={this.handleSearchTermChange}
                placeholder="Groups"
              />
            </Col>
          </Row>
          <Row>
            <div style={{ padding: '30px' }}>
              {isFetching ? (
                <Loading isLoading={isFetching} />
              ) : (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Group Name</th>
                      <th>Group Id</th>
                      <th>Number of PTs</th>
                      <th>Number of PTAS</th>
                      <th>Number of Techs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups.map((group, index) => {
                      const {
                        Name,
                        Id,
                        pts,
                        ptas,
                        techs,
                        iPts,
                        iPtas,
                        iTechs,
                      } = group;

                      return (
                        <tr key={index} style={{ cursor: 'pointer' }}>
                          <td>{index + 1}</td>
                          <td>{Name}</td>
                          <td>{Id}</td>
                          <td>
                            {pts ? pts : 0}&nbsp; {`(${iPts})`}
                          </td>
                          <td>
                            {ptas ? ptas : 0}&nbsp; {`(${iPtas})`}
                          </td>
                          <td>
                            {techs ? techs : 0}&nbsp; {`(${iTechs})`}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </div>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { billingGroups: state.groups.billingGroups };
};

export default connect(mapStateToProps)(Billing);
