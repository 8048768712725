import React, { Component, useMemo } from 'react';
import { connect } from 'react-redux';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { Row, Col, Button, Space } from 'antd';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import _ from 'lodash';
import { Prompt } from 'react-router';
import { Link } from 'react-router-dom';

import { ImageSelector } from '../../../components/file-uploader';
import * as validators from './validators';
import { themes } from './actions';
import { Input } from 'antd';

class GroupDesignForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presentLogo: false,
      validLogo: false,
      deletedLogo: false,
    };

    this.leaveConfirmMessage =
      'You currently have unsaved changes. Are you sure you want to discard your changes?';
    this.themeArray = this.themeArray.bind(this);
  }

  handleLogoSelectionDecorator = (file, errors) => {
    this.props.handleLogoSelection(file, errors);
    this.setState({
      presentLogo: true,
      validLogo: _.isEmpty(errors),
      deletedLogo: false,
    });
  };

  handleLogoRemovalDecorator = real => {
    this.props.handleLogoRemoval(real);
    this.setState({
      presentLogo: false,
      validLogo: false,
      deletedLogo: true,
    });
  };

  handleLogoLoadError = () => {
    this.setState({
      validLogo: false,
    });
  };

  themeArray = () => {
    const arr = [];
    const keys = Object.keys(themes);
    for (let i = 0; i < keys.length; i++) {
      arr.push(themes[keys[i]]);
    }
    return arr;
  };

  render() {
    const {
      valid,
      submitting,
      pristine,
      handleSubmit,
      initialValues,
      selectedBrandType,
    } = this.props;

    const { validLogo, presentLogo, deletedLogo } = this.state;
    const isBrandTypeLogo = selectedBrandType === 'logo';
    const validForm =
      valid &&
      (isBrandTypeLogo
        ? presentLogo
          ? validLogo
          : !deletedLogo && initialValues.logoUrl
        : true);
    const dirtyForm = isBrandTypeLogo
      ? !pristine || presentLogo || deletedLogo
      : !pristine;
    const formSaveEnabled = validForm && !submitting && dirtyForm;
    const themeArray = this.themeArray();

    const presentLogoError = presentLogo && !validLogo;

    return (
      <form
        onSubmit={handleSubmit(data => {
          this.props.onSubmit(data);
          this.setState({ presentLogo: false, validLogo: false });
        })}
      >
        <div>
          <ControlLabel className="required">Brand</ControlLabel>
          <Row gutter={[24, 0]}>
            <Col md={16} sm={16} xs={24}>
              <Field
                name="brandText"
                component={Input}
                type="text"
                // style={{ display: 'block', width: '100%' }}
              />
              <label>
                <Field
                  name="brandType"
                  component="input"
                  type="radio"
                  value="text"
                />
                &nbsp;Text
              </label>
            </Col>

            <Col md={8} sm={8} xs={24}>
              <div className={presentLogoError && 'has-error'}>
                <ImageSelector
                  defaultImageUrl={initialValues.logoUrl || ''}
                  imageValidator={validators.logo}
                  onFileSelected={this.handleLogoSelectionDecorator}
                  onFileRemoved={this.handleLogoRemovalDecorator}
                  onLoadError={this.handleLogoLoadError}
                  height={110}
                  width={110}
                  notCentered
                />
                {presentLogoError && (
                  <span className="help-block">
                    Please select a .jpg or .png smaller than 5MB.
                  </span>
                )}
                <label>
                  <Field
                    name="brandType"
                    component="input"
                    type="radio"
                    value="logo"
                  />
                  &nbsp;Logo
                </label>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <ControlLabel className="required">Theme</ControlLabel>
          <Row gutter={[24, 24]}>
            {themeArray.map((theme, i) => (
              <Col md={4} sm={8} xs={12} key={i}>
                <Space direction="vertical">
                  <img
                    src={`${process.env.PUBLIC_URL}/themes/thumbnails/${theme.thumbnail}`}
                    style={{ width: 110, height: 110 }}
                    alt="theme thumbnail"
                  />
                  <label>
                    <Field
                      name="theme"
                      component="input"
                      type="radio"
                      value={theme.name}
                    />
                    &nbsp;{theme.title}
                  </label>
                </Space>
              </Col>
            ))}
          </Row>
        </div>
        <Row>
          <Col md={24} sm={24} xs={24}>
            <FormGroup className="ptw-form-actions">
              <Space size={4} className="btn-toolbar pull-right">
                <Link to="/groups">
                  <Button
                    disabled={submitting}
                    className="ptw-btn"
                    type="default"
                    size="large"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="ptw-btn"
                  size="large"
                  disabled={!formSaveEnabled}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Space>
            </FormGroup>
          </Col>
        </Row>

        <Prompt message={this.leaveConfirmMessage} when={dirtyForm} />
      </form>
    );
  }
}

export const GROUP_DESIGN_FORM_NAME = 'groupDesign';

const mapStateToProps = state => ({
  selectedBrandType: formValueSelector(GROUP_DESIGN_FORM_NAME)(
    state,
    'brandType'
  ),
});

export default reduxForm({
  form: GROUP_DESIGN_FORM_NAME,
  validate: validators.generic,
})(connect(mapStateToProps)(GroupDesignForm));
