import React, { Fragment } from 'react';
import {
  Button,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Select,
  Tooltip
} from 'antd';
import { LikeFilled, DislikeFilled } from '@ant-design/icons';

const EditMultipleForm = (props) => {
  const {
    selectedRowKeys,
    handleUpdateSelectedItems,
    handleKeyPress,
    cptCodeList
  } = props;

  return (
    <div className="selected-details-container">
      <Row gutter={16}>
        <Col md={8}>
          <Typography.Text>
            Editing <strong>{selectedRowKeys.length}</strong> items.
          </Typography.Text>
        </Col>
        <Col md={16}>
          <Row gutter={16}>
            <Col md={3}>
              <Form.Item>
                <Input
                  type="text"
                  placeholder="Sets"
                  name="sets"
                  onBlur={handleUpdateSelectedItems}
                  onKeyDown={handleKeyPress}
                />
              </Form.Item>
            </Col>
            <Col md={3}>
              <Form.Item>
                <Input
                  type="text"
                  placeholder="Reps"
                  name="reps"
                  onBlur={handleUpdateSelectedItems}
                  onKeyDown={handleKeyPress}
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item>
                <Input
                  type="text"
                  placeholder="Hold"
                  name="hold"
                  onBlur={handleUpdateSelectedItems}
                  onKeyDown={handleKeyPress}
                />
              </Form.Item>
            </Col>
            <Col md={5}>
              <Form.Item>
                <Select
                  showSearch
                  placeholder="CPT Code"
                  onChange={(value) =>
                    handleUpdateSelectedItems({
                      target: { name: 'cptCode', value }
                    })
                  }
                >
                  {cptCodeList.map((item, i) => (
                    <Fragment key={i}>
                      <Select.Option value={item.CPT_Code}>
                        <Tooltip title={item.Description} placement="right">
                          {item.CPT_Code}
                        </Tooltip>
                      </Select.Option>
                    </Fragment>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={5}>
              <Form.Item>
                <Input
                  type="text"
                  placeholder="Minutes"
                  name="minutes"
                  onBlur={handleUpdateSelectedItems}
                  onKeyDown={handleKeyPress}
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item>
                <Button
                  className="btn-like"
                  icon={<LikeFilled />}
                  onClick={() =>
                    handleUpdateSelectedItems({
                      target: { name: 'export', value: 'YES' }
                    })
                  }
                />{' '}
                <Button
                  className="btn-dislike"
                  icon={<DislikeFilled />}
                  onClick={() =>
                    handleUpdateSelectedItems({
                      target: { name: 'export', value: 'NO' }
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default EditMultipleForm;
