import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import ExerciseFormPage from './ExerciseFormPage';
import { currentUserGroupSelector } from './selectors';
import GroupExercisesPage from '../groups/group-exercises/GroupExercisesPage';
import DropdownNavigation from '../../components/DropdownNavigation';

class Library extends Component {
  render() {
    const {
      props: { group },
    } = this;

    return (
      <React.Fragment>
        <DropdownNavigation />
        <div className="ptw-main-body">
          <Route
            exact
            path="/library"
            render={(props) => <GroupExercisesPage group={group} {...props} />}
          />
          <Route
            exact
            path="/library/:id+"
            render={(props) => <ExerciseFormPage group={group} {...props} />}
          />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    group: currentUserGroupSelector(state),
  };
}

export default connect(mapStateToProps)(Library);
