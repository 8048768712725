import { createAction } from 'redux-actions'
import { API_REQUEST } from '../../../membership/middleware'

//include grid state
export * from './gridState'

export const GET_GROUPPATIENTS = 'GET_GROUPPATIENTS';
export const SELECT_GROUP_PATIENT = 'SELECT_GROUP_PATIENT';
export const CHECK_GROUP_PATIENT_NAME = 'CHECK_GROUP_PATIENT_NAME';
export const DELETE_PATIENT = 'DELETE_PATIENT';

export const checkGroupPatientName = (groupName, emailAddress) => ({
    [API_REQUEST]: createAction(CHECK_GROUP_PATIENT_NAME)({
        resource: `/users/exists?email=${encodeURIComponent(emailAddress)}`,
        method: 'GET'
    })
});
export const getGroupPatients = groupName => ({
    [API_REQUEST]: createAction(GET_GROUPPATIENTS)({
        resource: `groups/${groupName}/users?role=p`,//todo: the querystring 'role' will be needed once we have a user other than group patient to lookup
        method: 'GET'
    })
});

export const deletePatient = (groupName, patientId) => ({
    [API_REQUEST]: createAction(DELETE_PATIENT)({
        resource: `groups/${groupName}/patients/${patientId}`,
        method: 'DELETE'
    })
});

export const selectCurrentGroupPatient = patient => ({
    ...createAction(SELECT_GROUP_PATIENT)({
        firstName: patient.firstName,
        lastName: patient.lastName,
        dateBirth: patient.dateBirth,
        gender: patient.gender,
        address: patient.address,
        city: patient.city,
        state: patient.state,
        zip: patient.zip,
        phoneNumber: patient.phoneNumber,
        emailAddress: patient.emailAddress,
        referring: patient.referring,
        role: "p"
    }),
});

export const OPEN_PATIENT_FORM_MODAL = 'OPEN_PATIENT_FORM_MODAL'
export const openPatientFormModal = createAction(OPEN_PATIENT_FORM_MODAL)

export const CLOSE_PATIENT_FORM_MODAL = 'CLOSE_PATIENT_FORM_MODAL'
export const closePatientFormModal = createAction(CLOSE_PATIENT_FORM_MODAL)

export const UPDATE_CURRENT_PATIENT = 'UPDATE_CURRENT_PATIENT'
export const updateCurrentPatient = (values) => createAction(UPDATE_CURRENT_PATIENT)({ values })

export const SET_PATIENT_FORM_TYPE = 'SET_PATIENT_FORM_TYPE'
export const setPatientFormType = createAction(SET_PATIENT_FORM_TYPE)

export const UPLOAD_IMPORT_PATIENTS_FILE = 'UPLOAD_IMPORT_PATIENTS_FILE';
export const uploadImportPatientsFile = (groupId, file) => {
    return {
        [API_REQUEST]: createAction(UPLOAD_IMPORT_PATIENTS_FILE)({
            resource: `import-patients-files/${groupId}.csv`,
            method: 'POST',
            file: true,
            body: file
        })
    }
};
