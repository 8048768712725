import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { currentUserGroupSelector } from '../../exercise-library/selectors';
import Navigation from '../../../components/Navigation';
import DropdownNavigation from '../../../components/DropdownNavigation';

class AdminDashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <DropdownNavigation />
        <div className="ptw-main-body">
          <Navigation />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.patients.isFetching,
  filteredList: state.patients.patients,
  group: currentUserGroupSelector(state),
});

export default connect(mapStateToProps)(withRouter(AdminDashboard));
