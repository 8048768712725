import React, { Fragment } from 'react';
import { Skeleton, Col, Row, Empty } from 'antd';

import NoFavorites from './../NoFavorites';
import ExerciseItem from '../../../components/ExerciseItem';

const ExerciseListDisplay = ({
  loading,
  currentView,
  favoritesSelected,
  favorites,
  exercisesList,
  handleSelectExercise,
  handleExerciseDetailsModal,
}) => {
  if (loading) {
    return (
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Skeleton
            loading={loading}
            shape="square"
            active
            className="full-width"
          />
        </Col>
      </Row>
    );
  }

  return (
    <React.Fragment>
      {favoritesSelected &&
      (!favorites.list ||
        (Array.isArray(favorites.list) && favorites.list.length === 0)) ? (
        <NoFavorites />
      ) : (
        <Fragment>
          {Array.isArray(exercisesList) && exercisesList.length > 0 ? (
            <Row gutter={[16, 16]}>
              {exercisesList.map((exercise, i) => (
                <Col
                  style={{ width: '100%' }}
                  xl={currentView === 'card' ? 6 : 12}
                  lg={currentView === 'card' ? 6 : 12}
                  md={currentView === 'card' ? 12 : 24}
                  sm={currentView === 'card' ? 12 : 24}
                  key={i}
                >
                  <ExerciseItem
                    currentView={currentView}
                    exercise={exercise}
                    handleExerciseDetailsModal={handleExerciseDetailsModal}
                    handleSelectExercise={handleSelectExercise}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <Empty description="No exercises found." />
          )}
        </Fragment>
      )}
    </React.Fragment>
  );
};

export default ExerciseListDisplay;
