import './styles.css';
import { Drawer, Typography, Empty } from 'antd';

const PDFViewerDrawer = ({ isOpen, pdfData, handleClose }) => {
  return (
    <Drawer
      destroyOnClose
      width="100%"
      placement="left"
      title={pdfData?.title}
      open={isOpen}
      onClose={handleClose}
    >
      {pdfData?.url ? (
        <div className="pdf-container">
          <embed
            type="application/pdf"
            className="pdf-renderer"
            src={pdfData?.url}
          />
        </div>
      ) : (
        <div className="empty-container">
          <Empty
            description={
              <Typography.Text
                style={{
                  fontSize: '1.4em'
                }}
              >
                No PDF Available
              </Typography.Text>
            }
          />
        </div>
      )}
    </Drawer>
  );
};

export default PDFViewerDrawer;
