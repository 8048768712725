import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { UploadOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

class ProfilePhotoSelector extends React.Component {
  constructor() {
    super();
    this.state = {
      imageError: false,
    };

    this.handleProfilePhotoError = this.handleProfilePhotoError.bind(this);
    this.handleProfilePhotoLoad = this.handleProfilePhotoLoad.bind(this);
  }

  componentWillReceiveProps() {
    this.handleProfilePhotoLoad();
  }

  handleProfilePhotoLoad() {
    this.setState({
      imageError: false,
    });
  }

  handleProfilePhotoError() {
    this.setState({
      imageError: true,
    });
  }

  render() {
    const {
      onChange,
      uploadClicked,
      currentSrc,
      width,
      height,
      inputRef,
      imgRef,
      selectorType,
    } = this.props;
    const { imageError } = this.state;

    return (
      <form
        className="profile-img-form"
        ref={form => {
          this.form = form;
        }}
      >
        <input
          type="file"
          ref={inputRef}
          accept="image/jpg, image/png"
          onChange={onChange}
          name="filename"
          style={{ display: 'none' }}
        />
        {currentSrc !== null && !imageError ? (
          <React.Fragment>
            {selectorType === 'NAV_PROFILE_PHOTO' ? (
              <div className={'center-block profile-image-container'}>
                {/*eslint-disable-next-line*/}
                <img
                  src={currentSrc}
                  width={width}
                  height={height}
                  ref={imgRef}
                  className="profile-img"
                  alt="profile-photo"
                  onLoad={this.handleProfilePhotoLoad}
                  onError={this.handleProfilePhotoError}
                />
              </div>
            ) : (
              <div className={'center-block profile-image-container'}>
                <div
                  className={'profile-image-overlay'}
                  onClick={uploadClicked}
                >
                  {/*eslint-disable-next-line*/}
                  <img
                    src={currentSrc}
                    width={width}
                    height={height}
                    ref={imgRef}
                    className={'center-block profile-image'}
                    alt="profile-photo"
                    onLoad={this.handleProfilePhotoLoad}
                    onError={this.handleProfilePhotoError}
                  />
                  <div className="profile-image-edit">
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className={'profile-photo-edit-icon'}
                    />
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ) : (
          <div
            className="profile-img"
            style={{ height, width }}
            onClick={uploadClicked}
          >
            <UploadOutlined />
            <Typography.Text>Upload</Typography.Text>
          </div>
        )}
      </form>
    );
  }
}

export default ProfilePhotoSelector;
