import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import _ from 'lodash';
import { success } from 'react-notification-system-redux'

import GroupDesignForm, { GROUP_DESIGN_FORM_NAME } from './GroupDesignForm';
import { defaultDesign, updateDesign, uploadLogo } from './actions';
import { getGroup } from '../actions/groups';
import config from '../../../config';

class GroupDesignPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            logo: null,
        };
    }

    submitDesign = formValues => {
        const { dispatch, group } = this.props;
        const { logo } = this.state;
        const payload = formValues.brandType === 'logo' ? {
            brandType: formValues.brandType,
            theme: formValues.theme
        } : {
            brandType: formValues.brandType,
            theme: formValues.theme,
            brandText: formValues.brandText
        };

        return dispatch(updateDesign(group.name, payload))
        .then(({action}) => {
            if (action.payload.message === 'Success') {
                if(formValues.brandType === 'logo' && logo){
                    return dispatch(uploadLogo(group.id, logo))
                            .then(() => dispatch(getGroup(group.name)));
                }else{
                    return dispatch(getGroup(group.name));
                }
            }
        }).then(() => this.props.dispatch(success({ title: 'Design successfully updated', position: 'tr' })));
    }

    handleLogoSelection = (file, errors) => {
        this.props.dispatch(change(GROUP_DESIGN_FORM_NAME, 'brandType', 'logo'));
        if(_.isEmpty(errors)){
            this.setState({
                logo: file
            });
        }
    }

    handleLogoRemoval = () => {
        this.setState({
            logo: null,
        });
    }

    render(){
        const { 
            group, 
        } = this.props;

        const initialValues = group.design ? {
            ...group.design,
            logoUrl: group.design.brandType === 'logo' ? `https://d25e7ve96uj01v.cloudfront.net/${group.id}.jpg` : '',
        } : defaultDesign;

        return (
            <div>
                <GroupDesignForm 
                    onSubmit={this.submitDesign}
                    initialValues={initialValues}
                    handleLogoRemoval={this.handleLogoRemoval}
                    handleLogoSelection={this.handleLogoSelection}
                    enableReinitialize/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(GroupDesignPage);