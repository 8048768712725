import ADMIN_STATE from './admin';
import AUTH_STATE from './auth';
import CAREPLAN_STATE from './careplan';
import EXERCISES_STATE from './exercises';
import FAVORITES_STATE from './favorites';
import FILTERS_STATE from './filters';
import GROUPS_STATE from './groups';
import MESSAGES_STATE from './messages';
import MODAL_STATE from './modal';
import NPS_STATE from './nps';
import PARAMS_STATE from './params';
import PATIENTS_STATE from './patients';
import PRESCRIPTION_STATE from './prescription';
import REPORTS_STATE from './reports';
import RTM_STATE from './rtm';
import TEMPLATES_STATE from './templates';
import USER_STATE from './user';
import PDF_STATE from './pdf';

const states = {
  authentication: AUTH_STATE,
  careplan: CAREPLAN_STATE,
  exercises: EXERCISES_STATE,
  favorites: FAVORITES_STATE,
  filters: FILTERS_STATE,
  groups: GROUPS_STATE,
  messages: MESSAGES_STATE,
  modal: MODAL_STATE,
  nps: NPS_STATE,
  params: PARAMS_STATE,
  patients: PATIENTS_STATE,
  prescription: PRESCRIPTION_STATE,
  reports: REPORTS_STATE,
  rtm: RTM_STATE,
  templates: TEMPLATES_STATE,
  user: USER_STATE,
  admin: ADMIN_STATE,
  pdf: PDF_STATE,
};

export default states;
