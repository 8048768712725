import React, { Fragment, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';

import moment from 'moment';
import StackedBarChart from '../../../components/StackedBarChart';
import services from '../../../services';
import states from '../../../states';
import * as reportsUtils from '../../../utils/reports.utils';

const { Option } = Select;
const codes = [98980, 98981, 98977, 98975];

const RtmBillers = ({
  title,
  showFilter,
  onSetLoading,
  onSetError,
  onSetDateRange,
  onSetReport,
}) => {
  const [filter, setFilter] = useState({
    dateRange: null,
    rtmCodes: [],
    providers: [],
  });
  const [disableSelect, setDisableSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [chartTitle, setChartTitle] = useState('');
  const [report, setReport] = useState(null);
  const [total, setTotal] = useState(0);

  const groups = useRecoilValue(states.groups);
  const [form] = Form.useForm();

  useEffect(() => {
    initChart();
  }, [filter]);

  const initChart = async () => {
    try {
      if (error) {
        onSetError(false);
        setError(false);
      }

      setTotal(0);
      onSetLoading(true);
      setLoading(true);

      const response = await fetchReports();
      const generatedReport = reportsUtils.generateRtmBillers(response);
      const totalCount = response.reduce((acc, curr) => {
        return acc + curr.result.reduce((sum, item) => sum + item.count, 0);
      }, 0);

      setTotal(totalCount);
      onSetReport(generatedReport);
      setReport(generatedReport);

      let startDate;
      let endDate;

      if (filter.dateRange !== null) {
        startDate = moment(filter.dateRange[0]).format('MMM DD, YYYY');
        endDate = moment(filter.dateRange[1]).format('MMM DD, YYYY');
      }

      setChartTitle(
        `${title} ${!filter.dateRange ? '(Last 30 days)' : ''} Report ${
          filter.dateRange !== null ? `for ${startDate} - ${endDate}` : ''
        }`
      );
    } catch (error) {
      console.log('initChart', error);

      onSetError(true);
      setError(true);
    } finally {
      onSetLoading(false);
      setLoading(false);
    }
  };

  const fetchReports = async () => {
    try {
      let query = reportsUtils.generateDateQuery();
      const providers = filter.providers.length > 0 ? filter.providers : [];

      if (filter.dateRange) {
        query = reportsUtils.generateDateQuery(
          moment(filter.dateRange[0]).format() || null,
          moment(filter.dateRange[1]).format() || null
        );
      }

      const response = await services.reports.getRtmBillers(
        query,
        providers,
        filter.rtmCodes.length > 0 ? filter.rtmCodes : null
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log('fetchReports', error);
      throw error;
    }
  };

  const handleFilter = values => {
    onSetDateRange(
      values.dateRange
        ? [values.dateRange[0].format(), values.dateRange[1].format()]
        : null
    );

    setFilter({
      dateRange: values.dateRange
        ? [values.dateRange[0].format(), values.dateRange[1].format()]
        : null,
      rtmCodes: Array.isArray(values.rtmCodes) ? [...values.rtmCodes] : [],
      providers: Array.isArray(values.providers) ? [...values.providers] : [],
    });
  };

  const handleClear = () => {
    form.resetFields();

    onSetDateRange(null);
    setFilter({
      dateRange: null,
      rtmCodes: [],
      providers: [],
    });
  };

  const validateSelectProviders = () => {
    setDisableSelect(form.getFieldValue('providers').length === 10);
  };

  return (
    <Fragment>
      {showFilter && (
        <Card style={{ background: '#f7f7f7', marginBottom: 20 }}>
          <Form
            className="filter-chart-form"
            form={form}
            onFinish={handleFilter}
          >
            <Row gutter={[16, 16]} style={{ flex: 1 }}>
              <Col lg={6} md={12} sm={24} xs={24}>
                <Form.Item label="Date Range" name="dateRange">
                  <DatePicker.RangePicker
                    disabledDate={current =>
                      (current && current < moment().subtract(1, 'year')) ||
                      current > moment().endOf('day')
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={12} sm={24} xs={24}>
                <Form.Item label="RTM Code/s" name="rtmCodes">
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    placeholder="Select RTM code/s"
                    maxTagCount={5}
                    filterOption={(input, option) => {
                      if (option && option.label) {
                        const label = String(option.label).toLowerCase();
                        if (label.includes(String(input).toLowerCase())) {
                          return option;
                        }
                      }
                    }}
                    options={codes.map(code => ({
                      label: code.toString(),
                      value: code,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Provider/s"
                  name="providers"
                  rules={[
                    {
                      type: 'array',
                      max: 10,
                      message: 'You can only select up to 10 providers',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    placeholder="Select provider/s"
                    maxTagCount={5}
                    maxTagTextLength={15}
                    onChange={validateSelectProviders}
                    filterOption={(input, option) => {
                      const searchInput = input.toLowerCase();
                      const isString = typeof option.children === 'string';
                      const isObject = typeof option.children === 'object';

                      if (isString) {
                        return option.children
                          .toLowerCase()
                          .includes(searchInput);
                      }

                      if (isObject) {
                        return option.children.props.children
                          .toLowerCase()
                          .includes(searchInput);
                      }
                    }}
                  >
                    {reportsUtils
                      .sortProvidersByStatus(groups.providers)
                      .map(item => {
                        const selected = form.getFieldValue('providers');
                        const included = Array.isArray(selected)
                          ? !selected.includes(item.Sub)
                          : false;
                        const disabled = disableSelect && included;

                        return (
                          <Option value={item.Sub} disabled={disabled}>
                            {!item.Active ? (
                              <em
                                style={{
                                  color: disabled ? '#bfbfbf' : 'grey',
                                }}
                              >{`${item.FirstName} ${item.LastName}`}</em>
                            ) : (
                              `${item.FirstName} ${item.LastName}`
                            )}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6} md={12} sm={24} xs={24}>
                <Form.Item>
                  <Space>
                    <Button htmlType="submit" type="primary">
                      Apply Filter
                    </Button>
                    <Button
                      htmlType="button"
                      type="default"
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      )}

      <StackedBarChart
        loading={loading}
        chartTitle={chartTitle}
        data={report}
      />

      {!!total && (
        <Space
          direction="horizontal"
          style={{
            width: '100%',
            justifyContent: 'center',
            marginTop: 16,
          }}
        >
          <Typography.Text>Total Billed Codes: {total}</Typography.Text>
        </Space>
      )}
    </Fragment>
  );
};

export default RtmBillers;
