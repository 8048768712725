import { useState, useRef, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRecoilState } from 'recoil';
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { titleCase } from 'voca';

import { ImageSelector } from '../../../components/file-uploader';
import { uploadImageDirect } from '../../../components/file-uploader/actions';
import { getGroupExercises } from '../actions/groupExercises';
import {
  popDescription,
  popImage,
  popImageCompatibleFormats,
  popName,
  popParameters,
  popTag,
  popVideo,
} from './pops';
import { exerciseImgValidator } from '../validators/groupExercises';
import { toCamelCaseObjKeys } from '../../../utils/object.utils';

import cptCodes from '../../../features/export-hep-to-prompt/CptCodes.json';
import states from '../../../states';
import services from '../../../services';

import ModalForm from '../../../components/ModalForm';
import ProfileImage from '../../patients/Modals/ProfileImage';
import setNotification from '../../../utils/setNotification.utils';

const YOUTUBE_REGEX = `(youtu.*be.*)(com)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))`;
const IMAGE_URI = 'https://d2p805pqn3eul9.cloudfront.net/';

const ClinicExerciseForm = ({
  visibleProfile,
  getGroupExercises,
  uploadImage,
  values,
  isOpen,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const [parametersError, setParametersError] = useState(false);
  const [showMirrored, setShowMirrored] = useState(false);

  const [formData, setFormData] = useState({
    base64: null,
    cropping: false,
    file: null,
    img: null,
    removeFile: null,
    validImage: true,
  });

  const [mirroredImage, setMirroredImage] = useState({
    base64: null,
    cropping: false,
    file: null,
    img: null,
    removeFile: null,
    validImage: true,
  });

  const [exercises, setExercises] = useRecoilState(states.exercises);
  const [form] = Form.useForm();

  const imgSelectorRef = useRef();
  const mirrorImgSelectorRef = useRef();

  const promptIntegrated = visibleProfile?.GroupInfo?.EnablePrompt;
  const isSuperAdmin = visibleProfile.Role === 'sa';
  const onPTWiredGroup =
    visibleProfile.GroupId === '2667b98c-4e98-4cdb-b03a-959ed7a7f435';

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        exerciseName: titleCase(values.ExerciseName || ''),
        sets: values.Sets || '',
        reps: values.Reps || '',
        hold: values.Hold || '',
        tags: values.Tag || '',
        instructions: values.Instructions || '',
        videoUrl: values.Video || '',
        cptCode: values.CPTCode || '',
        mirrorVideoUrl: values.VideoMirror || '',
      });

      setShowMirrored(!!values.ImageMirror || !!values.VideoMirror);

      setFormData(prevState => ({
        ...prevState,
        img: values.image || values.imageUrl || null,
      }));

      setMirroredImage(prevState => ({
        ...prevState,
        img: values.ImageMirror || null,
      }));
    }
  }, [isOpen]);

  const onFileSelected = async (file, errors) => {
    const isValid = _.isEmpty(errors);

    if (isValid) {
      setFormData(prevState => ({
        ...prevState,
        cropping: true,
        file,
        validImage: true,
      }));
    } else {
      console.log('errors', errors);
      setNotification(
        'error',
        'Upload Error!',
        'Image size must be less than 2.5MB. Use images less than 1MB for faster loading time.'
      );
    }
  };

  const onMirrorFileSelected = async (file, errors) => {
    const isValid = _.isEmpty(errors);

    if (isValid) {
      setMirroredImage(prevState => ({
        ...prevState,
        cropping: true,
        file,
        validImage: true,
      }));
    } else {
      console.log('errors', errors);
      setNotification(
        'error',
        'Upload Error!',
        'Image size must be less than 2.5MB. Use images less than 1MB for faster loading time.'
      );
    }
  };

  const onFileRemoved = () => {
    setFormData(prevState => ({
      ...prevState,
      removeFile: null,
      validImage: true,
    }));
  };

  const onMirrorFileRemoved = () => {
    setMirroredImage(prevState => ({
      ...prevState,
      removeFile: null,
      validImage: true,
    }));
  };

  const handleImageCrop = (blob, base64Str) => {
    setFormData(prevState => ({
      ...prevState,
      base64: base64Str,
      file: blob,
      cropping: false,
    }));
  };

  const handleMirrorImageCrop = (blob, base64Str) => {
    setMirroredImage(prevState => ({
      ...prevState,
      base64: base64Str,
      file: blob,
      cropping: false,
    }));
  };

  const handleExitCrop = () => {
    setFormData(prevState => ({
      ...prevState,
      img: null,
      cropping: false,
      file: null,
    }));
  };

  const handleExitMirrorCrop = () => {
    setMirroredImage(prevState => ({
      ...prevState,
      img: null,
      cropping: false,
      file: null,
    }));
  };

  const validateExerciseName = str => {
    if (str.length === 0) {
      return true;
    } else if (!!values?.Id) {
      return false;
    } else {
      return exercises.list.some(ex => ex.exerciseName === str.toLowerCase());
    }
  };

  const validateVideoUrl = async (str, isMirror = false) => {
    if (str.match(YOUTUBE_REGEX)) {
      return getYoutubeThumbnail(str, isMirror).length === 11;
    } else if (str.startsWith('https://vimeo.com')) {
      return getVimeoThumbnail(str, isMirror).then(videoId => {
        return !!videoId;
      });
    } else {
      if (isMirror) {
        setMirroredImage(prevState => ({
          ...prevState,
          img: null,
        }));
      } else {
        setFormData(prevState => ({
          ...prevState,
          img: null,
        }));
      }

      return false;
    }
  };

  const getYoutubeThumbnail = (url, isMirror = false) => {
    const results = url.match('[\\?&]v=([^&#]*)');
    const videoId = results === null ? url : results[1];

    if (isMirror) {
      setMirroredImage(prevState => ({
        ...prevState,
        img: `http://img.youtube.com/vi/${videoId}/0.jpg`,
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        img: `http://img.youtube.com/vi/${videoId}/0.jpg`,
      }));
    }

    return videoId;
  };

  const getVimeoThumbnail = async (url, isMirror = false) => {
    let videoId = '';
    await fetch(`https://vimeo.com/api/oembed.json?url=${url}`).then(
      async response => {
        await response.json().then(data => {
          videoId = data.video_id;

          if (isMirror) {
            setMirroredImage(prevState => ({
              ...prevState,
              img: data.thumbnail_url,
            }));
          } else {
            setFormData(prevState => ({
              ...prevState,
              img: data.thumbnail_url,
            }));
          }
        });
      }
    );

    return videoId;
  };

  const handleSubmit = async form => {
    try {
      setLoading(true);

      const name = form.exerciseName.trim().toLowerCase();
      const params = {
        active: true,
        Active: true,
        exerciseName: name,
        ExerciseName: name,
        GroupId: visibleProfile.GroupId,
        SearchTerm: name.replace(/\s/g, ''),
        Instructions: form.instructions,
        Reps: form.reps || '',
        Sets: form.sets || '',
        Hold: form.hold || '',
        Tag: form.tags || '',
        Video: form.videoUrl || '',
      };

      if (form.cptCode) {
        params.CPTCode = form.cptCode;
      }

      if (form.mirrorVideoUrl) {
        params.VideoMirror = form.mirrorVideoUrl;
      }

      let response = null;
      if (!!values?.Id) {
        let image = params.Video === values.Video ? values.image : formData.img;
        let imageMirror =
          params.VideoMirror === values.VideoMirror
            ? values.ImageMirror
            : mirroredImage.img;

        if (formData.base64) {
          const type = formData.base64.split(';')[0].split('/')[1];
          const filename = `${visibleProfile.GroupId}/${values.Id}.${type}`;

          await uploadImage(filename, formData.file).then(() => {
            image = IMAGE_URI + filename;
          });
        }

        if (mirroredImage.base64) {
          const type = mirroredImage.base64.split(';')[0].split('/')[1];
          const filename = `${visibleProfile.GroupId}/${values.Id}-mirror.${type}`;

          await uploadImage(filename, mirroredImage.file).then(() => {
            imageMirror = IMAGE_URI + filename;
          });
        }

        params.image = image;
        params.ImageMirror = imageMirror;

        response = await services.exercises.updateClinicExercise({
          ...params,
          Id: values.Id,
          Created: values.Created,
        });
      } else {
        params.image = formData.img;
        params.ImageMirror = mirroredImage.img;

        response = await services.exercises.addClinicExercise(params);

        if (!!formData.base64 || !!mirroredImage.base64) {
          const id = response.data.id;
          const newParams = {
            ...params,
          };
          const requests = [];

          if (formData.base64) {
            const type = formData.base64.split(';')[0].split('/')[1];
            const filename = `${visibleProfile.GroupId}/${id}.${type}`;
            const imageUri = IMAGE_URI + filename;

            newParams.image = imageUri;
            params.image = imageUri;
            requests.push(uploadImage(filename, formData.file));
          }

          if (mirroredImage.base64) {
            const type = mirroredImage.base64.split(';')[0].split('/')[1];
            const filename = `${visibleProfile.GroupId}/${id}-mirror.${type}`;
            const mirroredImageUri = IMAGE_URI + filename;

            newParams.ImageMirror = mirroredImageUri;
            params.ImageMirror = mirroredImageUri;
            requests.push(uploadImage(filename, mirroredImage.file));
          }

          requests.push(
            services.exercises.updateClinicExercise({
              ...newParams,
              Id: id,
            })
          );

          await Promise.all(requests);
        }
      }

      if (response.status === 200) {
        const newExercise = {
          ...toCamelCaseObjKeys({
            Id: response.data.id,
            Active: params.Active,
            ExerciseName: params.ExerciseName,
            GroupId: params.GroupId,
            Image: params.image,
            ImageMirror: params.ImageMirror,
            SearchTerm: params.SearchTerm,
            Instructions: params.Instructions,
            Reps: params.Reps,
            Sets: params.Sets,
            Tag: params.Tag,
            Video: params.Video,
            VideoMirror: params.VideoMirror,
          }),
        };

        setExercises(prevState => {
          const index = prevState.custom.exercises.length;
          const listCopy = [...prevState.list];
          const unmappedCopy = [...prevState.unmapped];

          listCopy.splice(index, 0, newExercise);
          unmappedCopy.splice(index, 0, newExercise);

          return {
            ...prevState,
            unmapped: unmappedCopy,
            list: listCopy,
          };
        });

        notification.success({
          message: 'Success!',
          description: `Clinic exercise successfully ${
            !!values?.Id ? 'updated' : 'added'
          }.`,
        });

        handleCancelModal();
        getGroupExercises(params.GroupId);
      } else {
        throw response;
      }
    } catch (error) {
      console.log('[clinic exercise]', error);

      notification.error({
        message: 'Error!',
        description: `An error occurred while ${
          !!values?.Id ? 'updating' : 'adding'
        } clinic exercise.`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelModal = () => {
    form.resetFields();

    setParametersError(false);
    setFormData(prevState => ({
      ...prevState,
      base64: null,
    }));

    handleExitCrop();
    onCancel();
  };

  const infoCol = {
    lg: showMirrored ? 10 : 14,
    md: showMirrored ? 24 : 24,
    sm: showMirrored ? 24 : 24,
  };

  const defaultUploadCol = {
    lg: showMirrored ? 7 : 10,
    md: showMirrored ? 12 : 24,
    sm: showMirrored ? 24 : 24,
  };

  return (
    <Fragment>
      {formData.cropping && (
        <ProfileImage
          show={formData.cropping}
          image={formData.file}
          onSubmit={handleImageCrop}
          onExit={handleExitCrop}
          onHide={handleExitCrop}
          closeButton={handleExitCrop}
          isExerciseImage={true}
        />
      )}

      {mirroredImage.cropping && (
        <ProfileImage
          show={mirroredImage.cropping}
          image={mirroredImage.file}
          onSubmit={handleMirrorImageCrop}
          onExit={handleExitMirrorCrop}
          onHide={handleExitMirrorCrop}
          closeButton={handleExitMirrorCrop}
          isExerciseImage={true}
        />
      )}

      <ModalForm
        form="clinicExercise"
        header={(!!values?.Id ? 'Edit' : 'Add') + ' Exercise'}
        showModal={isOpen}
        handleClose={handleCancelModal}
        handleSubmit={handleSubmit}
        loading={loading}
        className={`exercise-form-modal ${showMirrored ? 'mirrored' : ''}`}
      >
        <Form
          layout="vertical"
          id="clinicExercise"
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={[24, 0]}>
            <Col {...infoCol}>
              <Row gutter={[16, 0]}>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name="exerciseName"
                    label="Exercise Name"
                    tooltip={popName}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your exercise name.',
                      },
                      { max: 256, message: 'Max length is 256 only.' },
                      {
                        message: 'Exercise name already exists.',
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.resolve();
                          }

                          return validateExerciseName(value)
                            ? Promise.reject()
                            : Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      size="large"
                      placeholder="Enter exercise name"
                      name="exerciseName"
                    />
                  </Form.Item>
                </Col>

                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    style={{ marginBottom: -40, fontWeight: 'bold' }}
                    label="Parameters"
                    required
                    tooltip={popParameters}
                  />

                  <Row gutter={[16, 0]}>
                    <Col lg={8} md={8} sm={8} xs={24}>
                      <Form.Item
                        name="sets"
                        label="Sets"
                        dependencies={['reps', 'hold']}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value &&
                                !getFieldValue('reps') &&
                                !getFieldValue('hold')
                              ) {
                                setParametersError(true);
                                return Promise.reject();
                              } else {
                                setParametersError(false);
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="text"
                          size="large"
                          placeholder="Enter sets"
                          name="sets"
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={8} md={8} sm={8} xs={24}>
                      <Form.Item
                        name="reps"
                        label="Reps"
                        dependencies={['sets', 'hold']}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value &&
                                !getFieldValue('sets') &&
                                !getFieldValue('hold')
                              ) {
                                setParametersError(true);
                                return Promise.reject();
                              } else {
                                setParametersError(false);
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="text"
                          size="large"
                          placeholder="Enter reps"
                          name="reps"
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={8} md={8} sm={8} xs={24}>
                      <Form.Item
                        name="hold"
                        label="Hold"
                        dependencies={['sets', 'reps']}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value &&
                                !getFieldValue('sets') &&
                                !getFieldValue('reps')
                              ) {
                                setParametersError(true);
                                return Promise.reject();
                              } else {
                                setParametersError(false);
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="text"
                          size="large"
                          placeholder="Enter hold"
                          name="hold"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {parametersError && (
                    <Typography.Paragraph
                      type="danger"
                      style={{ marginTop: -20 }}
                    >
                      Please fill in at least one parameter field.
                    </Typography.Paragraph>
                  )}
                </Col>

                <Col lg={24} md={24} sm={24} xs={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={promptIntegrated ? 12 : 24}>
                      <Form.Item name="tags" label="Tags" tooltip={popTag}>
                        <Input
                          type="text"
                          size="large"
                          placeholder="Enter tags"
                          name="tags"
                        />
                      </Form.Item>
                    </Col>

                    {promptIntegrated && (
                      <Col span={12}>
                        <Form.Item
                          name="cptCode"
                          label="CPT Code"
                          tooltip="Assign a default CPT Code to this exercise. When using the Export HEP to Visit in Prompt feature, this CPT Code will automatically be assigned. You can still update it later if needed."
                        >
                          <Select
                            showSearch
                            size="large"
                            placeholder="CPT Code"
                          >
                            {cptCodes.map((item, i) => (
                              <Fragment key={i}>
                                <Select.Option value={item.CPT_Code}>
                                  <Tooltip
                                    placement="right"
                                    title={item.Description}
                                  >
                                    {item.CPT_Code}
                                  </Tooltip>
                                </Select.Option>
                              </Fragment>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Col>

                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name="instructions"
                    label="Instructions"
                    tooltip={popDescription}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your instructions.',
                      },
                      { max: 1000, message: 'Max length is 1000 characters.' },
                    ]}
                  >
                    <Input.TextArea
                      size="large"
                      placeholder="Enter instructions"
                      name="instructions"
                      rows={4}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col {...defaultUploadCol}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                  name="videoUrl"
                  label="Exercise Video URL"
                  tooltip={popVideo}
                  extra={
                    <Tooltip
                      placement="bottomRight"
                      title={popImageCompatibleFormats}
                      overlayInnerStyle={{
                        width: 400,
                      }}
                    >
                      <Typography.Link>
                        See Compatible URL Formats
                      </Typography.Link>
                    </Tooltip>
                  }
                  validateTrigger="onBlur"
                  rules={[
                    {
                      message: 'Please input a valid exercise video URL.',
                      validator: async (_, value) => {
                        if (!value || value === values.Video) {
                          return Promise.resolve();
                        }

                        const valid = await validateVideoUrl(value);
                        if (valid) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject();
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    type="text"
                    size="large"
                    placeholder="Enter exercise video URL"
                    name="videoUrl"
                    rows={4}
                  />
                </Form.Item>
              </Col>

              <Col lg={24} md={24}>
                <Form.Item
                  label="Exercise Image"
                  tooltip={popImage}
                  help={
                    !formData.validImage && (
                      <Typography.Paragraph type="danger">
                        Selected image is either not in JPG, JPEG, or PNG format
                        or has a size of more than 2.5MB.
                      </Typography.Paragraph>
                    )
                  }
                >
                  <div className={!formData.validImage ? 'has-error' : ''}>
                    <ImageSelector
                      width="auto"
                      height={300}
                      ref={imgSelectorRef}
                      isUser={false}
                      defaultImageUrl={formData.base64 || formData.img}
                      src={formData.base64 || formData.img}
                      onFileSelected={onFileSelected}
                      onFileRemoved={onFileRemoved}
                      imageValidator={exerciseImgValidator}
                    />
                  </div>
                </Form.Item>

                {isSuperAdmin && onPTWiredGroup && (
                  <Space direction="horizontal">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={showMirrored}
                      onChange={setShowMirrored}
                    />
                    <Typography.Text>Mirror Exercise</Typography.Text>
                  </Space>
                )}
              </Col>
            </Col>
            {showMirrored && (
              <Col lg={7} md={12} sm={24} xs={24}>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name="mirrorVideoUrl"
                    label="Mirrored Exercise Video URL"
                    tooltip={popVideo}
                    validateTrigger="onBlur"
                    extra={
                      <Tooltip
                        placement="bottomRight"
                        title={popImageCompatibleFormats}
                        overlayInnerStyle={{
                          width: 400,
                        }}
                      >
                        <Typography.Link>
                          See Compatible URL Formats
                        </Typography.Link>
                      </Tooltip>
                    }
                    rules={[
                      {
                        message: 'Please input a valid exercise video URL.',
                        validator: async (_, value) => {
                          if (!value || value === values.Video) {
                            return Promise.resolve();
                          }

                          const valid = await validateVideoUrl(value, true);
                          if (valid) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject();
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      size="large"
                      placeholder="Enter mirrored exercise video URL"
                      name="videoUrl"
                      rows={4}
                    />
                  </Form.Item>
                </Col>

                <Col lg={24} md={24}>
                  <Form.Item
                    label="Mirrored Exercise Image"
                    tooltip={popImage}
                    help={
                      !mirroredImage.validImage && (
                        <Typography.Paragraph type="danger">
                          Selected image is either not in JPG, JPEG, or PNG
                          format or has a size of more than 2.5MB.
                        </Typography.Paragraph>
                      )
                    }
                  >
                    <div
                      className={!mirroredImage.validImage ? 'has-error' : ''}
                    >
                      <ImageSelector
                        width="auto"
                        height={300}
                        ref={mirrorImgSelectorRef}
                        isUser={false}
                        defaultImageUrl={
                          mirroredImage.base64 || mirroredImage.img
                        }
                        src={mirroredImage.base64 || mirroredImage.img}
                        onFileSelected={onMirrorFileSelected}
                        onFileRemoved={onMirrorFileRemoved}
                        imageValidator={exerciseImgValidator}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Col>
            )}
          </Row>
        </Form>
      </ModalForm>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
});

const mapDispatchToProps = dispatch => ({
  getGroupExercises: id => dispatch(getGroupExercises(id)),
  uploadImage: (name, file) => dispatch(uploadImageDirect(name, file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClinicExerciseForm);
