import 'isomorphic-fetch';
import Auth from '@aws-amplify/auth';
import decode from 'jwt-decode';
import { getRecoil, setRecoil } from 'recoil-nexus';

import config from '../config';
import states from '../states';

const getToken = async () => {
  let token = null;
  const auth = getRecoil(states.authentication);

  try {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();

    token = idToken.getJwtToken();
    setRecoil(states.authentication, {
      ...auth,
      token: idToken.getJwtToken()
    });
  } catch (error) {
    if (error === 'No current user') {
      if (auth.token) {
        const decoded = decode(auth.token);
        if (decoded.exp * 1000 > Date.now()) {
          token = auth.token;
        } else {
          if (auth.promptCode) {
            await fetch(`${config.root}/integration`, {
              method: 'POST',
              mode: 'cors',
              cache: 'no-cache',
              credentials: 'same-origin',
              headers: {
                'Content-Type': 'application/json'
              },
              redirect: 'follow',
              referrerPolicy: 'no-referrer',
              body: JSON.stringify({
                code: auth.promptCode
              })
            }).then(response =>
              response.json().then(data => {
                const { AuthenticationResult } = data;
                if (AuthenticationResult) {
                  const { IdToken } = AuthenticationResult;
                  if (IdToken) {
                    token = IdToken;
                    setRecoil(states.authentication, {
                      ...auth,
                      token: IdToken
                    });
                  }
                }
              })
            );
          }
        }
      }
    }
  }

  return token;
};

const apiFetch = async (uri, method, payload, abortOptions) => {
  try {
    const url = `${config.root}/${uri}`;
    const token = await getToken();
    let headers = {
      Authorization: token,
      'Content-Type': 'application/json'
    };
    let body = payload && JSON.stringify(payload.body);

    if (payload && payload.file) {
      const fileHeaders = {
        ...headers,
        Accept: 'image/*'
      };
      delete fileHeaders['Content-Type'];

      headers = fileHeaders;
      body = payload.file;
    }

    const response = await fetch(url, {
      headers,
      method,
      body,
      signal: abortOptions?.abortSignal
    });

    let message = null;
    let data = null;

    const { status } = response;
    if (status >= 200 && status < 300) {
      if (status !== 204) {
        message = 'Success';

        try {
          data = await response.json();
        } catch (err) {
          data = null;
        }
      }
    }

    if (!message) {
      message = await response.text();
    }

    return { status, message, data };
  } catch (err) {
    if (err.name === 'AbortError') {
      if (abortOptions.abortCallback) {
        return abortOptions.abortCallback();
      }

      return;
    }

    throw err;
  }
};

export default apiFetch;
