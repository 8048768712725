import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  PointElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';

import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  Filler
);

const LineChart = ({ data }) => (
  <Line
    width={'auto'}
    data={{
      labels: data.map(item => moment(item.Date).format('MMM-DD')),
      datasets: [
        {
          label: 'Daily Outcome Score',
          data: data.map(item => item.Value),
          datalabels: {
            display: false,
          },
          fill: true,
          pointHoverRadius: 4,
          pointRadius: 4,
        },
      ],
    }}
    options={{
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            stepSize: 1,
          },
          beginAtZero: true,
          min: 1,
          max: 5,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
        },
      },
    }}
  />
);

export default LineChart;
