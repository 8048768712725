import moment from 'moment';
import voca from 'voca';

export function formatDateTime(date) {
  return {
    date: moment(date).format('LL'),
    time: moment(date).format('LT')
  };
}

export function titleCase(str) {
  if (typeof str !== 'string') {
    return str;
  }

  return voca.titleCase(str);
}

export function millisToMinutesAndSeconds(millis) {
  const minutes = Number(millis / 60000).toFixed(1);
  const parts = minutes.split('.');
  const decimal = parts[1];

  if (Number(decimal) === 0) {
    return parts[0];
  }

  return minutes;
}

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function withinMonth(date) {
  const monthStart = moment().startOf('month').valueOf();
  const monthEnd = moment().endOf('month').valueOf();

  return date >= monthStart && date <= monthEnd;
}

export function getSortFunction(item) {
  switch (item.key) {
    case '20_MIN_THRESHOLD':
      return (a, b) => {
        let prev = 0;
        if (withinMonth(a.TimerDate)) {
          const duration = Math.floor(a.TimerDuration / 60000);
          const progress = duration / 0.2;

          prev = progress;
          if (progress > 100) {
            prev = progress % 100 || 100;
          }
        }

        let next = 0;
        if (withinMonth(b.TimerDate)) {
          const duration = Math.floor(b.TimerDuration / 60000);
          const progress = duration / 0.2;

          next = progress;
          if (progress > 100) {
            next = progress % 100 || 100;
          }
        }

        if (item.sortOrder === 'ASC') {
          return prev - next;
        } else {
          return next - prev;
        }
      };

    case 'CALL_STATUS':
      return (a, b) => {
        if (item.sortOrder === 'ASC') {
          return a.LiveCallDate - b.LiveCallDate;
        } else {
          return b.LiveCallDate - a.LiveCallDate;
        }
      };

    case 'ENROLLMENT_DATE':
      return (a, b) => {
        if (item.sortOrder === 'ASC') {
          return a.EnrollStart - b.EnrollStart;
        } else {
          return b.EnrollStart - a.EnrollStart;
        }
      };

    case '30_DAYS_BILLING':
      return (a, b) => {
        const now = Date.now();
        const prev = b.BillingEnd - now;
        const next = a.BillingEnd - now;

        if (item.sortOrder === 'ASC') {
          return prev - next;
        } else {
          return next - prev;
        }
      };

    default:
      return () => null;
  }
}

export function checkDischarged(currentMonitoredPatient) {
  let discharged = false;

  if (currentMonitoredPatient && currentMonitoredPatient?.patient) {
    const { patient } = currentMonitoredPatient;

    // if no 'Discharge' property, should be false
    if (patient.hasOwnProperty('Discharge')) {
      // if has 'Discharge' property, discharged should be the value of patient.Discharge
      discharged = patient.Discharge;
    }
  }

  return discharged;
}

export const toUTCDate = (date) => {
  if (!date) {
    return '';
  }

  const utcDate = new Date(date).toUTCString();
  const dateOnly = utcDate.substring(
    utcDate.indexOf(',') + 2,
    utcDate.indexOf(':') - 3
  );

  const dateArr = dateOnly.split(' ');
  const day = dateArr[0];
  const month = dateArr[1];
  const year = dateArr[2];

  return `${month} ${day}, ${year}`;
};
