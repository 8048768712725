import React, { Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { Col, Divider, Row, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import v from 'voca';

import states from '../../../states';

import ActivityLogs from './ActivityLogs';
import TotalTime from './TotalTime';

const TimerBody = ({
  isTechRole,
  isDischarged,
  isRTMEnabled,
  streamDuration,
  renderStreamDuration,
}) => {
  const rtm = useRecoilValue(states.rtm);
  const { loadingActivityLogs, patient, activity, isMonitoring, activityLogs } =
    rtm.timer;

  const renderPatientActivityLogs = () => {
    return (
      <Fragment>
        {activity ? (
          <Fragment>
            <Typography.Text className="timer-patient-name">
              Current Patient:{' '}
              {patient && (
                <strong>
                  {v.capitalize(patient.FirstName)}{' '}
                  {v.capitalize(patient.LastName)}
                </strong>
              )}
            </Typography.Text>
            <Row justify="space-between">
              <Col span={17}>
                <Typography.Text strong>Current Activity</Typography.Text>
              </Col>
              <Col span={7}>
                <Typography.Text strong>Duration</Typography.Text>
              </Col>
              <Col span={17}>
                <Typography.Text>{activity}</Typography.Text>
              </Col>
              <Col span={7}>
                <Typography.Text type={isMonitoring && 'success'}>
                  {isMonitoring ? renderStreamDuration : '00:00:00'}
                </Typography.Text>
              </Col>
              <Divider />
              <TotalTime
                loadingActivityLogs={loadingActivityLogs}
                activityLogs={activityLogs}
                streamDuration={streamDuration}
                renderStreamDuration={renderStreamDuration}
                isMonitoring={isMonitoring}
              />
              <Divider />

              {loadingActivityLogs ? (
                <Col span={24}>
                  <Typography.Text
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '50px',
                    }}
                  >
                    <Spin
                      indicator={
                        <LoadingOutlined spin style={{ fontSize: '36px' }} />
                      }
                    />
                  </Typography.Text>
                </Col>
              ) : (
                <ActivityLogs activityLogs={activityLogs} />
              )}
            </Row>
          </Fragment>
        ) : (
          <Typography.Text>
            There is no RTM eligible activity at the moment.
          </Typography.Text>
        )}
      </Fragment>
    );
  };

  const renderDischarged = () => {
    return (
      <Typography.Text>
        This patient has been discharged. To track RTM time please re-admit the
        patient.
      </Typography.Text>
    );
  };

  if (isTechRole) {
    return (
      <Typography.Text>
        Only <Typography.Text strong>therapists</Typography.Text> and{' '}
        <Typography.Text strong>therapist assistants</Typography.Text> are able
        to log billable RTM time.
      </Typography.Text>
    );
  }

  return (
    <Fragment>
      {patient && isRTMEnabled ? (
        <Fragment>
          {isDischarged ? renderDischarged() : renderPatientActivityLogs()}
        </Fragment>
      ) : (
        <Typography.Text>
          No RTM patient selected at the moment.
        </Typography.Text>
      )}
    </Fragment>
  );
};

export default TimerBody;
