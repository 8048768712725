import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Row, Slider, Switch, Tooltip, Input, Form } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { getAllPrograms } from '../../features/patients/actions/patients';
import ProgramMenu from './ProgramMenu';

const duration = {
  0: '0',
  3: '3',
  6: '6',
  9: '9',
  12: '12',
};

const evaluation = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
};

const help_tooltip = (
  <span>
    Provide an educational video series to your patients receiving treatment for
    a given diagnosis. Videos support your patient education and communication
    throughout care. For a detailed breakdown of content, go{' '}
    <a
      href="https://ptwired.zendesk.com/hc/en-us/articles/8905509724563"
      target={'_blank'}
      rel="noopener noreferrer"
    >
      here
    </a>
    .
  </span>
);

const CarePlanForm = ({ dispatch, patient, programs, sendData }) => {
  const [formData, setFormData] = useState({
    sub: patient.Sub,
    duration: 3,
    session: 14,
    evaluation: 2,
    program: null,
    program_name: null,
    nps: true,
    psfs: true,
  });
  const [otherProgram, setOtherProgram] = useState(false);

  const session = {};
  for (let i = 1; i <= 20; i++) {
    session[i] = i;
  }

  useEffect(() => {
    if (patient) {
      const {
        GroupInfo: { Name },
      } = patient;

      dispatch(getAllPrograms(Name));
    }
  }, []);

  useEffect(() => {
    if (formData) {
      sendData(formData);
    }
  }, [formData]);

  const handleChange = async e => {
    if (e.target.action === 'CHANGE_PROGRAM') {
      if (e.target.value.diagnosis === 'Other') {
        setOtherProgram(true);
      } else {
        setOtherProgram(false);
      }

      setFormData({
        ...formData,
        program: e.target.value.id,
        program_name: e.target.value.diagnosis,
      });

      return;
    }

    if (e.target.action === 'CHANGE_TO_OTHER_PROGRAM') {
      setFormData({
        ...formData,
        program: e.target.value.id,
        program_name: e.target.value.diagnosis,
      });

      return;
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form layout="vertical">
      <Row gutter={16} style={{ paddingTop: '32px' }}>
        <Col span={12}>
          <Form.Item
            label={<span> Duration &#40;in Months&#41; </span>}
            name="duration"
          >
            <Slider
              defaultValue={formData.duration}
              marks={duration}
              max={12}
              tooltipVisible
              onChange={e => {
                handleChange({ target: { name: 'duration', value: e } });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Total Sessions per Episode" name="session">
            <Slider
              defaultValue={formData.session}
              marks={session}
              min={1}
              max={20}
              tooltipVisible
              onChange={e => {
                handleChange({ target: { name: 'session', value: e } });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ paddingTop: '22px' }}>
        <Col span={12}>
          <Form.Item
            label={<span> Re-Evaluations &#40;in Weeks&#41; </span>}
            name="evaluation"
          >
            <Slider
              defaultValue={formData.evaluation}
              marks={evaluation}
              min={1}
              max={4}
              tooltipVisible
              onChange={e => {
                handleChange({ target: { name: 'evaluation', value: e } });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Program" name="Program">
                <ProgramMenu
                  formData={formData}
                  programs={programs}
                  otherProgram={otherProgram}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>

            <Col span={2}>
              <Tooltip placement="bottomRight" title={help_tooltip}>
                <QuestionCircleOutlined
                  style={{
                    marginTop: 34,
                    color: 'gray',
                    fontSize: '20px',
                    padding: '0px 10px',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </Col>
          </Row>

          {otherProgram && (
            <Form.Item label="Request New Program" name="program">
              <Input
                size="large"
                placeholder="Enter program name"
                onChange={e => {
                  handleChange({
                    target: {
                      action: 'CHANGE_TO_OTHER_PROGRAM',
                      value: {
                        id: e.target.value,
                        diagnosis: e.target.value,
                      },
                    },
                  });
                }}
              />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <span> NPS </span>

          <div style={{ paddingTop: '10px' }}>
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              defaultChecked={formData.nps}
              onChange={e => {
                handleChange({ target: { name: 'nps', value: e } });
              }}
            />
          </div>
        </Col>
      </Row>

      {/* <Row gutter={16} style={{ paddingTop: '22px' }}>
          <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
            <span> PSFS </span>
            
            <div style={{ paddingTop: '10px' }}>
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                defaultChecked={data.psfs}
                onChange={e => {
                  handleChange({ target: { name: 'psfs', value: e } });
                }}
              />
            </div>
          </Col>
        </Row> */}
    </Form>
  );
};

const mapStateToProps = state => ({
  patient: state.patients.currentPatient,
  programs: state.patients.carePlanPrograms,
});

export default withRouter(connect(mapStateToProps)(CarePlanForm));
