import React, { Component } from 'react';
import { Modal, Button } from 'antd';

class GroupExercisesPage extends Component {
  render() {
    const {
      header,
      showModal,
      close,
      save,
      saveEnabled = true,
      closable = true,
      loading,
      children,
    } = this.props;

    return (
      <Modal
        title={header}
        visible={showModal}
        onCancel={close}
        closable={closable}
        footer={[
          <Button
            key={0}
            className="ptw-btn btn-primary"
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            disabled={!saveEnabled}
            onClick={save}
          >
            Save Changes
          </Button>,
          closable && (
            <Button
              key={1}
              className="ptw-btn"
              type="default"
              htmlType="submit"
              size="large"
              onClick={close}
            >
              Cancel
            </Button>
          ),
        ]}
        {...this.props}
      >
        {children}
      </Modal>
    );
  }
}

export default GroupExercisesPage;
