import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Modal, notification, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  getMessageBoardProviders,
  postCarePlan,
  setCarePlanProperties,
  setMessageBoardProviders,
} from '../../features/patients/actions/patients';
import { toCamelCaseObjKeys } from '../../utils/object.utils';
import { setActivity, setHEPActivity } from '../../components/RTMTimer/mixins';

import PrintPreview from '../../features/multiple-prescriptions/components/PrintPreviewModal/PrintPreview';
import Stepper from './Stepper';
import SelectExercises from './SelectExercises';
import EditPrescriptionDetails from './EditPrescriptionDetails';

import html2pdf from 'html2pdf.js';
import states from '../../states';
import services from '../../services';

const getLoadingModal = () => {
  return Modal.info({
    title: (
      <Typography.Title level={1} className="text-center">
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 48 }} />}
          size="large"
        />
      </Typography.Title>
    ),
    content: (
      <Typography.Paragraph className="text-center">
        Updating prescription, please wait...
      </Typography.Paragraph>
    ),
    centered: true,
    okButtonProps: {
      style: {
        display: 'none',
      },
    },
    icon: null,
  });
};

const EditPrescription = ({
  match,
  dispatch,
  visibleProfile,
  currentPatient,
  carePlanProperties,
  fromCarePlan,
  siteDesign,
}) => {
  const [redirect, setRedirect] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const [prescription, setPrescription] = useRecoilState(states.prescription);
  const [params, setParams] = useRecoilState(states.params);

  const user = useRecoilValue(states.user);
  const patients = useRecoilValue(states.patients);
  const groups = useRecoilValue(states.groups);

  const pdfPrintRef = useRef(null);

  // assign the current prescription to edited prescription state
  useEffect(() => {
    window.scrollTo(0, 0);

    initRTMActivity('Edit Exercise Program');
  }, []);

  const initRTMActivity = async activity => {
    setActivity(currentPatient, activity);
  };

  const handleAssignPrescription = async () => {
    const rxForm = prescription.form;
    const rxList = prescription.list;

    if (!rxForm || !rxForm?.prescription?.exercises?.length) {
      return;
    }

    const hasFormError = rxForm.prescription.formError;
    const hasExerciseError = rxForm.prescription.exercises.some(
      item => item?.formError === true
    );

    if (hasFormError || hasExerciseError) {
      return;
    }

    if (
      !!visibleProfile.CaseId?.Required &&
      !visibleProfile.CaseId?.ExcludedLocations?.includes(
        currentPatient?.Clinic
      )
    ) {
      let message = '';
      let description = '';

      if (!rxForm.caseId) {
        message = 'Case Id Required!';
        description = 'Please enter a prescription case id.';
      } else {
        const { caseId } = rxForm;
        const { GroupId } = visibleProfile;
        const { Sub } = currentPatient;

        if (!/^\d{8}$/.test(caseId)) {
          message = 'Invalid Case Id!';
          description = 'Prescription case id must be an 8-digit number.';
        } else {
          await services.patients.getByCaseId(caseId, GroupId).then(res => {
            if (res.status === 200) {
              const rxSub = res.data.Sub;
              const rxPrescriptionId = res.data.PrescriptionId;

              if (rxSub === Sub) {
                if (rxPrescriptionId !== rxForm.id) {
                  message = 'Case Id Exists!';
                  description =
                    "Case Id is already assigned to this patient's another prescription.";
                }
              } else {
                message = 'Case Id Exists!';
                description = 'Case Id is already assigned to another patient.';
              }
            }
          });
        }
      }

      if (message && description) {
        notification.warning({
          message,
          description,
        });

        return;
      }
    }

    let notifType = 'updatePrescription';
    if (rxList.length === 1 && !rxList[0].prescription.exercises.length) {
      notifType = 'newPatient';
    }

    const loading = getLoadingModal();
    const isNewPatient = notifType === 'newPatient';
    const raintreeIntegrated = !!visibleProfile.GroupInfo.EnableRaintree;

    let update = null;
    try {
      const paths = match.path.split('/');
      const sub = paths[paths.length - 2];

      const [response] = await Promise.all([
        services.prescription.update(visibleProfile.GroupId, isNewPatient, sub),
        saveCarePlan(),
      ]);

      if (response.status !== 200) {
        throw new Error(JSON.stringify(response));
      }

      update = response;
    } catch (error) {
      console.log(error);

      loading.destroy();
      notification.error({
        message: 'Error!',
        description: 'An error occurred while updating prescription.',
      });

      return;
    }

    const requests = [assignProvider()];
    const sortedList = [...rxList].sort((a, b) => {
      return (b.updated || b.created) - (a.updated || a.created);
    });

    if (sortedList[0]) {
      const lastDate = sortedList[0].updated || sortedList[0].created;
      const difference = Date.now() - lastDate;
      const interval = difference / 60000;

      if (interval >= 30 || isNewPatient) {
        const sub = patients.details.sub;
        const ptFname = user.details.firstName;
        const ptLname = user.details.lastName;

        requests.push(services.email.send(notifType, sub, ptFname, ptLname));
      }
    }

    try {
      await Promise.all(requests);
    } catch (error) {
      console.log(error);
    }

    if (raintreeIntegrated) {
      const element = document.getElementById('hep-pdf');
      const child = element.firstChild;
      const options = {
        html2canvas: {
          useCORS: true,
        },
        pageBreakBefore: 'always',
      };

      const pdf = await html2pdf().set(options).from(child).outputPdf();
      const pdfBase64 = window.btoa(pdf);

      if (currentPatient.SystemId) {
        services.documents.sendPdf({
          groupId: visibleProfile.GroupId,
          systemId: currentPatient.SystemId,
          caseId: rxForm.caseId || params.caseId || 'PT001',
          file: pdfBase64,
        });
      }
    }

    const updatedList = [...rxList];
    const updatedItem = {
      ...toCamelCaseObjKeys(update.data),
    };

    if (prescription.action === 'ADD') {
      updatedList.push(updatedItem);
    } else if (prescription.action === 'EDIT') {
      if (rxList.length > 1) {
        if (rxList.some(rx => rx.id === updatedItem.id)) {
          const index = rxList.findIndex(rx => rx.id === updatedItem.id);

          updatedList[index] = updatedItem;
        } else {
          const index = rxList.findIndex(rx => !rx.id);

          updatedList.splice(index, 1);
          updatedList.unshift(updatedItem);
        }
      } else {
        updatedList[0] = updatedItem;
      }
    }

    setPrescription(prevState => ({
      ...prevState,
      list: updatedList,
      form: updatedItem,
      rxDrawer: prevState.rxDrawer ? updatedItem : null,
      action: '',
    }));

    if (params.caseId) {
      setParams(prevState => ({
        ...prevState,
        caseId: '',
      }));
    }

    let notifMessage = 'Prescription successfully updated.';
    if (raintreeIntegrated) {
      notifMessage =
        "Prescription has been successfully updated and saved to this patient's chart in Raintree.";
    }

    loading.destroy();
    notification.success({
      message: 'Success!',
      description: notifMessage,
      duration: raintreeIntegrated ? 6 : 3,
    });

    if (updatedList.length > 1) {
      initRTMActivity(null);
    } else {
      initRTMActivity('View Adherence Log');
    }
    
    setRedirect(true);
  };

  const saveCarePlan = async () => {
    if (!!visibleProfile.GroupInfo?.EnablePTU) {
      if (carePlanProperties) {
        await dispatch(
          postCarePlan(visibleProfile.GroupId, carePlanProperties)
        ).then(() => {
          dispatch(setCarePlanProperties(null));
        });
      }
    }
  };

  const assignProvider = async () => {
    const sub = patients.details.sub;
    await dispatch(getMessageBoardProviders(sub)).then(async res => {
      if (Array.isArray(res.value.providers)) {
        const mappedResults = res.value.providers.map(p => p.Sub);
        const isNewProvider = !mappedResults.includes(user.details.sub);

        if (!isNewProvider) {
          return;
        }

        const fname = patients.details.firstName;
        const lname = patients.details.lastName;
        const group = groups.user.id;

        const ptSub = user.details.sub;
        const ptFname = user.details.firstName;
        const ptLname = user.details.lastName;

        await dispatch(
          setMessageBoardProviders({
            Action: 'MANAGE_BOARDS',
            boardData: {
              to: sub,
              GroupId: group,
              firstName: fname,
              lastName: lname,
            },
            therapists: [
              ...res.value.providers.map(p => ({
                ...toCamelCaseObjKeys(p),
              })),
              {
                sub: ptSub,
                firstName: ptFname,
                lastName: ptLname,
              },
            ],
          })
        );
      }
    });
  };

  const renderCurrentStepComponent = () => {
    switch (currentStep) {
      case 0:
        return <p>Setup Care Plan</p>;
      case 1:
        return <SelectExercises />;
      case 2:
        return <EditPrescriptionDetails />;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {redirect && <Redirect to={`/patients/${patients.details.sub}`} />}

      <div className="ptw-collapse-body">
        {renderCurrentStepComponent()}
        <Stepper
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleAssignPrescription={handleAssignPrescription}
        />
      </div>

      {!!visibleProfile.GroupInfo.EnableRaintree &&
        !!prescription.form?.prescription && (
          <div id="hep-pdf" style={{ display: 'none' }}>
            <PrintPreview
              printRef={pdfPrintRef}
              printProps={{
                currentPatient: {
                  ...currentPatient,
                  Creator: visibleProfile.EmailAddress,
                  GroupInfo: {
                    ...currentPatient.GroupInfo,
                    PrintEmail:
                      currentPatient.GroupInfo.PrintEmail || 'provider',
                  },
                },
                prescription: prescription.form.prescription,
                visibleProfile,
                siteDesign,
              }}
              raintreePDF={true}
              settingProps={{
                currLocale: 'en',
                currLayout: 'A',
                currEmail: null,
                currPhone: null,
              }}
            />
          </div>
        )}
    </React.Fragment>
  );
};

const mapStateToProps = states => ({
  visibleProfile: states.visibleProfile,
  currentPatient: states.patients.currentPatient,
  currentMonitoredPatient: states.monitorTimer.currentMonitoredPatient,
  carePlanProperties: states.patients.carePlanProperties,
  siteDesign: states.publicPage.design,
});

export default connect(mapStateToProps)(EditPrescription);
