import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Select, Typography } from 'antd';

import VideoModal from '../../video/VideoModal';
import services from '../../../services';

function MobileVideo(props) {
  const {
    dispatch,
    visibleProfile: {
      EmailAddress,
      PhoneNumber,
      Creator,
      Providers,
      Role,
      FirstName,
      LastName
    },
    locale,
    showVideo,
    onHide
  } = props;

  const [loading, setLoading] = useState(false);
  const [therapistList, setTherapistList] = useState([]);
  const [therapist, setTherapist] = useState('');

  useEffect(() => {
    const therapists = Providers.length ? Providers : [Creator];
    if (therapists.length > 1) {
      getTherapistNames(therapists).then(res => {
        setTherapistList(res);
      });
    } else {
      setTherapist(therapists[0]);
    }
  }, []);

  const getTherapistNames = async therapistArr => {
    try {
      setLoading(true);

      const names = [];
      for (let i = 0; i < therapistArr.length; i++) {
        const sub = therapistArr[i];
        const response = await services.user.getDetails('sub', sub);

        if (response.status === 200) {
          const { data } = response;
          const { FirstName, LastName, EmailAddress } = data;
          names.push({
            sub: sub,
            label: `${FirstName} ${LastName}`,
            value: EmailAddress
          });
        }
      }

      return names;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!therapist ? (
        <Modal footer={null} open={showVideo} onCancel={onHide}>
          <Typography.Title level={5}>
            Multiple Providers Assigned
          </Typography.Title>

          <Typography.Text>
            Your account has multiple providers assigned. Please select the
            provider you would like to connect with.
          </Typography.Text>

          <Select
            placeholder="Select Therapist"
            loading={loading}
            disabled={loading}
            options={therapistList}
            value={therapist}
            onChange={val => {
              setTherapist(val);
            }}
            style={{
              width: '100%',
              marginBottom: '2em',
              marginTop: '2em'
            }}
          />
        </Modal>
      ) : (
        therapist && (
          <VideoModal
            dispatch={dispatch}
            locale={locale}
            therapist={therapist}
            patientContact={EmailAddress || PhoneNumber}
            patient={EmailAddress}
            ptFirstName={FirstName}
            ptLastName={LastName}
            role={Role}
            showVideo={showVideo}
            onHide={() => onHide()}
          />
        )
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    visibleProfile: state.visibleProfile,
    locale: state.mobile.locale
  };
};
export default connect(mapStateToProps)(MobileVideo);
