import { Tag, Typography } from 'antd';
import React from 'react';

const ExerciseFilterTags = ({ tags, removeTag, removeAllTags }) => {
  if (tags.length === 0) {
    return null;
  }

  return (
    <div className="mb-3">
      <Typography.Text strong className="mr-1">
        Filter:
      </Typography.Text>
      {tags.map((tag, i) => (
        <Tag
          key={i}
          closable
          name={tag.title}
          visible
          onClose={() => {
            removeTag(tag);
          }}
        >
          {tag.value ? tag.value : tag.title}
        </Tag>
      ))}
      {tags.length > 1 && (
        <Tag
          color="red"
          closable
          visible
          onClick={removeAllTags}
          onClose={removeAllTags}
        >
          Clear All
        </Tag>
      )}
    </div>
  );
};

export default ExerciseFilterTags;
