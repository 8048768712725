import { notification } from 'antd';

const setNotification = (type = 'info', message, description, duration = 5) => {
  notification[type]({
    message,
    description,
    duration,
  });
};

export default setNotification;
