import _ from 'lodash';
import {
    maxLength, 
    alphaNumericAndSpecial, 
    compoundValidator, 
    required
} from '../../groups/validators/generic';

const maxLength25 = maxLength(25);
const maxLength256 = maxLength(256);
const maxLength750 = maxLength(750);

export const sets = compoundValidator([alphaNumericAndSpecial, maxLength25], 'Invalid sets param');
export const reps = compoundValidator([alphaNumericAndSpecial, maxLength25], 'Invalid reps param');
export const hold = compoundValidator([alphaNumericAndSpecial, maxLength25], 'Invalid hold param');
export const instructions = compoundValidator([alphaNumericAndSpecial, maxLength750], 'Invalid instructions');
export const exerciseName = compoundValidator([required, alphaNumericAndSpecial, maxLength256], 'Invalid exercise name');

export const generic = values => {
    const errors = {}
    const genericErrors = {}

    if(!values.sets && !values.reps && !values.hold){
        genericErrors.parameters = 'At least one field should be filled'
    }

    if(!_.isEmpty(genericErrors)) errors._error = genericErrors

    return errors
}