const ios = {
  aimsportsmedicine:"https://itunes.apple.com/app/id1375813681",
  bodygears:"https://itunes.apple.com/app/id1225037099",
  capitolpt:"https://itunes.apple.com/app/id1385186175",
  centerforoptimalmovement:"https://itunes.apple.com/app/id1388067516",
  chaineffect:"https://itunes.apple.com/app/id1222418179",
  ptwired:"https://itunes.apple.com/app/id1175316335",
  risingsunphysicaltherapy:"https://itunes.apple.com/app/id1379426433",
  thediamondmethod:"https://itunes.apple.com/app/id1391113285",
  themanualtouch:"https://itunes.apple.com/app/id1379850738",

}

const android = {
    aimsportsmedicine:
      "https://play.google.com/store/apps/details?id=com.ptwired.ptwiredapp.aimsportsmedicine",
    bodygears:
      "https://play.google.com/store/apps/details?id=com.ptwired.ptwiredapp.bodygears",
    capitolpt:
        "https://play.google.com/store/apps/details?id=com.ptwired.capitolpt",
    centerforoptimalmovement:
        "https://play.google.com/store/apps/details?id=com.ptwired.centerforoptimalmovement",
    chaineffect:
      "https://play.google.com/store/apps/details?id=com.ptwired.ptwiredapp.chaineffect",
    ptwired:
      "https://play.google.com/store/apps/details?id=com.ptwired.ptwiredapp",
    risingsunphysicaltherapy:
      "https://play.google.com/store/apps/details?id=com.ptwired.risingsunphysicaltherapy",
    thediamondmethod:
        "https://play.google.com/store/apps/details?id=com.ptwired.thediamondmethod",
    themanualtouch:
      "https://play.google.com/store/apps/details?id=com.ptwired.themanualtouch",
}

export {ios, android};
