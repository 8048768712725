import React, { useEffect, useState, useMemo } from 'react';
import { Amplify } from 'aws-amplify';

import Predictions, {
  AmazonAIPredictionsProvider
} from '@aws-amplify/predictions';
import { Helmet } from 'react-helmet';
import { Typography, notification } from 'antd';
import { WarningOutline } from 'react-ionicons';
import PrivateRoutes from './PrivateRoutes';
import Login from '../features/membership';
import ForgotPassword from '../features/membership/ForgotPassword';
import ValidateAccount from '../features/membership/ValidateAccount';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import Alerts from './Alerts';
import { getPublicSiteInfo } from '../actions';
import { themes, themeMap } from '../features/groups/group-design/actions';
import { decodedCurrentUserSelector } from '../features/exercise-library/selectors';
import { requestProfile } from '../features/membership/actions';
import version from './version';
import LoadingPage from './LoadingPage';
import NPSSurveyForm from '../features/patients/NetPromoterScore/SurveyForm';
import AnnouncementPopup from './AnnouncementPopup';
import AnnouncementSubscription from './AnnouncementSubscription';
import NetworkConnectionPopup from './NetworkConnectionPopup';

const stylesheet = (design) => {
  try {
    return themes[design];
  } catch (err) {
    return themes.darkgray;
  }
};

Amplify.addPluggable(new AmazonAIPredictionsProvider());

const App = function (props) {
  const {
    dispatch,
    IsAuthenticated,
    cssURL,
    decodedCurrentUser,
    loading,
    theme
  } = props;
  const file = useMemo(() => {
    if (!theme) {
      return null;
    }
    try {
      const sheet = stylesheet(theme).stylesheet;
      return themeMap[sheet].stylesheet;
    } catch (err) {
      return null;
    }
  }, [theme]);

  useEffect(() => {
    let subdomain = window.location.host.split('.')[0];
    const queryString = window.location.hash.split('?');

    if (queryString.length > 1) {
      let checkGroupName = null;
      const queryStrings = queryString[1].split('&');

      for (let i = 0; i < queryStrings.length; i++) {
        const query = queryStrings[i].split('=');
        if (query[0] === 'groupName') {
          checkGroupName = query[1];
        }
      }
      if (checkGroupName === null) {
        if (subdomain === 'localhost:3000') {
          subdomain = 'ptw';
        }
      } else {
        subdomain = checkGroupName;
      }
    } else {
      if (subdomain === 'localhost:3000') {
        subdomain = 'ptw';
      }
    }

    console.log('Subdomain is', subdomain);
    console.log('Version:', version);

    if (IsAuthenticated && decodedCurrentUser.role === 'p') {
      dispatch(requestProfile(decodedCurrentUser.email.toLowerCase()), 'p');
    }

    dispatch(getPublicSiteInfo(subdomain));
  }, []);

  return (
    <div>
      <Helmet>
        <title>PT Wired</title>
        <link rel="stylesheet" type={cssURL} />
        <link
          rel="stylesheet"
          type="text/css"
          href={
            file
              ? `${process.env.PUBLIC_URL}/themes/stylesheets/${file}`
              : `${process.env.PUBLIC_URL}/themes/stylesheets/theme-grey-dark.css`
          }
        />
        <meta
          name="viewport"
          id="viewport"
          content="width=device-width,minimum-scale=1.0,maximum-scale=1.0,initial-scale=1.0"
        />
      </Helmet>

      <Alerts />
      <AnnouncementSubscription />
      <AnnouncementPopup />
      <NetworkConnectionPopup />

      {loading ? (
        <LoadingPage content={'Loading, please wait...'} />
      ) : (
        <React.Fragment>
          {IsAuthenticated ? (
            <PrivateRoutes />
          ) : (
            <React.Fragment>
              <Switch>
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/validate/:code" component={ValidateAccount} />
                <Route path="/nps/:code" component={NPSSurveyForm} />
                <Route component={Login} />
              </Switch>
              <div className="version-container">
                <Typography.Text style={{ color: '#fff' }}>
                  Version: {version}
                </Typography.Text>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    IsAuthenticated: state.currentUser.IsAuthenticated,
    Token: state.currentUser.token,
    theme: state.publicPage.design.theme,
    loading: state.publicPage.design.loading,
    decodedCurrentUser: decodedCurrentUserSelector(state),
    currentPatient: state.patients.currentPatient
  };
};

export default connect(
  //todo: look into wrapRouter
  //pure:false needed because connect wraps a component that uses <Link></Link>, Navigation.
  //https://github.com/ReactTraining/react-router/issues/3536
  mapStateToProps,
  null,
  null,
  { pure: false }
)(App);
