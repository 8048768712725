import apiFetch from '../utils/fetch.utils';
import states from '../states';
import { getRecoil } from 'recoil-nexus';

export const getNPS = async (query, diagnosis, clinic, provider) => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id } = groupsState.user;

    const method = 'POST';
    const body = {
      type: 'NPS',
      groupId: id,
      query: query,
      offset: new Date().getTimezoneOffset()
    };

    if (diagnosis) {
      body['diagnosis'] = diagnosis;
    }

    if (clinic) {
      body['clinic'] = clinic;
    }

    if (provider) {
      body['provider'] = provider;
    }

    const uri = `reports`;

    return apiFetch(uri, method, { body });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getUsageReports = async (subtype, query, providers, clinics) => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id } = groupsState.user;

    const method = 'POST';
    const body = {
      type: 'USAGE',
      subtype,
      groupId: id,
      query,
      providers,
      clinics,
      offset: new Date().getTimezoneOffset()
    };

    return apiFetch('reports', method, { body });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getRtmTotals = async subtype => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id } = groupsState.user;

    const method = 'POST';
    const body = {
      type: 'RTM',
      subtype,
      groupId: id,
      offset: new Date().getTimezoneOffset()
    };

    return apiFetch('reports', method, { body });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getRtmBilled = async (query, codes) => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id } = groupsState.user;

    const method = 'POST';
    let body = {
      type: 'RTM',
      subtype: 'BILLED',
      groupId: id,
      query: query,
      offset: new Date().getTimezoneOffset()
    };

    if (codes) {
      body['codes'] = codes;
    }

    return apiFetch('reports', method, { body });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getRtmBillers = async (query, providers, codes) => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id } = groupsState.user;

    const method = 'POST';
    const body = {
      type: 'RTM',
      subtype: 'BILLERS',
      groupId: id,
      query: query,
      providers: providers,
      offset: new Date().getTimezoneOffset()
    };

    if (codes) {
      body['codes'] = codes;
    }

    return apiFetch('reports', method, { body });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getRtmCodes = async (query, providers, codes) => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id } = groupsState.user;

    const method = 'POST';
    const body = {
      type: 'RTM',
      subtype: 'CODES',
      groupId: id,
      query: query,
      offset: new Date().getTimezoneOffset()
    };

    if (providers) {
      body['providers'] = providers;
    }

    if (codes) {
      body['codes'] = codes;
    }

    return apiFetch('reports', method, { body });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getRtmPatients = async (
  query,
  providers,
  status,
  queryTypes,
  queryRange
) => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id } = groupsState.user;

    const method = 'POST';
    const body = {
      type: 'RTM',
      subtype: 'PATIENTS',
      groupId: id,
      query: query,
      offset: new Date().getTimezoneOffset(),
      queryTypes,
      queryRange
    };

    if (providers) {
      body['providers'] = providers;
    }

    if (status) {
      body['status'] = status;
    }

    return apiFetch('reports', method, { body });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getRtmServiceLogs = async (query, clinics) => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id } = groupsState.user;

    const method = 'POST';
    const body = {
      type: 'RTM',
      subtype: 'SERVICE_LOGS',
      groupId: id,
      query: query,
      offset: new Date().getTimezoneOffset()
    };

    if (clinics) {
      body['clinics'] = clinics;
    }

    return apiFetch('reports', method, { body });
  } catch (err) {
    console.log(err);
    throw err;
  }
};
