import {
  minLength,
  maxLength,
  email,
  alphaNumericAndSpecial,
  alphaNumeric,
  compoundValidator,
  phone,
  required
} from '../../groups/validators/generic';
import moment from 'moment';

const dateMoment = date => moment(date, 'MM-DD-YYYY');

const date = value =>
  value &&
  (dateMoment(value).isAfter(moment.utc()) || !dateMoment(value).isValid())
    ? 'Invalid date.'
    : undefined;

const minLength1 = minLength(1);
const maxLength30 = maxLength(30);
const maxLength50 = maxLength(50);
const maxLength128 = maxLength(128);
const maxLength254 = maxLength(254);

export const firstNameValidator = compoundValidator(
  [maxLength30, minLength1, alphaNumericAndSpecial],
  'Invalid firstname.'
);
export const lastNameValidator = compoundValidator(
  [maxLength50, minLength1, alphaNumericAndSpecial],
  'Invalid lastname.'
);
export const phoneValidator = compoundValidator(
  [phone],
  'Invalid phone number.'
);
export const addressValidator = compoundValidator(
  [maxLength128, alphaNumeric],
  'Invalid address'
);
export const emailValidator = compoundValidator(
  [email, maxLength254],
  'Invalid email.'
);
export const referingMDValidator = compoundValidator(
  [maxLength128, alphaNumericAndSpecial],
  'Invalid referring MD.'
);
export const dateValidator = compoundValidator([date], 'Invalid date.');

export const requiredValidator = compoundValidator(
  [required],
  'This field is required.'
);

export const phoneNumberValidator = compoundValidator(
  [
    value =>
      value &&
      !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
        value
      )
        ? 'Invalid phone number'
        : undefined
  ],
  'Invalid phone number.'
);
