import React, { useEffect, useState } from 'react';
import {
  FacebookOutlined,
  GooglePlusOutlined,
  SmileOutlined
} from '@ant-design/icons';
import { Button, Result, Typography } from 'antd';
import v from 'voca';

import ProviderAvatar from '../ProviderAvatar';

const message = (
  providers
) => `thank you for taking the time to answer the survey.

Our team reviews your feedback and works to improve your experience.

If you could, please consider leaving a review at any of the following options. Many people go online before starting therapy, and ${
  providers && providers.length > 1 ? "we'd" : "I'd"
}  love to help them!`;

const ThankYouPage = ({ score, requestResult: { data } }) => {
  if (score <= 8) {
    return (
      <Result
        icon={<SmileOutlined />}
        title="Thank you!"
        subTitle="Your feedback is valuable and appreciated."
      />
    );
  }

  const [patientName, setPatientName] = useState('Greetings');

  useEffect(() => {
    if (data) {
      setPatientName(data.Patient.FirstName);
    }
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {data &&
          data.Providers &&
          data.Providers.map((item) => (
            <ProviderAvatar url={item.ImageUrl} name={item.FullName} />
          ))}
      </div>

      <Typography.Title level={4}>Thank you!</Typography.Title>

      <div className="thank-you-description-container">
        {data && (
          <Typography.Paragraph>
            {v.capitalize(patientName)}, {message(data.Providers)}
          </Typography.Paragraph>
        )}
      </div>

      <div className="thank-you-social-btn-container">
        {data && data.FacebookUrl && (
          <Button
            href={data.FacebookUrl}
            target="_blank"
            icon={<FacebookOutlined style={{ color: '#fff' }} />}
            shape="circle"
            size="large"
            className="fb-btn"
          />
        )}

        {data && data.GoogleUrl && (
          <Button
            href={data.GoogleUrl}
            target="_blank"
            icon={<GooglePlusOutlined style={{ color: '#fff' }} />}
            shape="circle"
            size="large"
            className="google-btn"
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ThankYouPage;
