import { atom } from 'recoil';

const defaultState = {
  usage: {
    patients: null,
    heps: null,
    careplans: null,
    totalPatients: 0,
    totalHeps: 0,
    totalCareplans: 0,
  },
  rtm: {
    totalCodes: 0,
    totalPatients: 0,
    totalDuration: 0,
    billed: null,
    billers: null,
    codes: null,
    patients: null,
    serviceLogs: null,
  },
};

const REPORTS_STATE = atom({
  key: 'REPORTS_STATE',
  default: defaultState,
});

export default REPORTS_STATE;
