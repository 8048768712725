import React from 'react';
import { Form, Input, Button } from 'antd';

const IntroVideoForm = props => {
  const {
    introVideoUrl,
    setIntroVideoUrl,
    event,
    handleSubmitCustomization,
    isLoading,
  } = props;

  console.log(introVideoUrl);

  return (
    <Form
      name="basic"
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ introVideoUrl }}
      onFinish={() => {
        handleSubmitCustomization(event, {
          introVideoUrl,
        });
      }}
    >
      <Form.Item
        label="Enter Video URL"
        name="introVideoUrl"
        rules={[{ required: true, message: 'Video URL is required.' }]}
      >
        <Input
          type="url"
          onChange={e => setIntroVideoUrl(e.target.value)}
          value={introVideoUrl}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3, span: 12 }}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save Changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default IntroVideoForm;
