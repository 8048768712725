import React, { useState } from 'react';
import { Button, Input, Space, Tooltip } from 'antd';
import { SendOutlined } from '@ant-design/icons';

const MessageInput = ({ sendMessage }) => {
  const [input, setInput] = useState('');

  const handleSendMessage = () => {
    const value = input.trim();

    setInput('');
    sendMessage(value);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Space.Compact
      style={{
        width: '100%'
      }}
    >
      <Input.TextArea
        size="large"
        autoSize={{
          maxRows: 2
        }}
        placeholder="Aa"
        value={input}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        style={{
          marginRight: 12
        }}
      />

      <Tooltip title="Send Message">
        <Button
          htmlType="submit"
          type="link"
          size="large"
          disabled={!input.trim()}
          onClick={handleSendMessage}
          icon={
            <SendOutlined
              style={{
                fontSize: 32
              }}
            />
          }
        />
      </Tooltip>
    </Space.Compact>
  );
};

export default React.memo(MessageInput);
