import { Tabs, Breadcrumb } from 'antd';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import DispatchLinkItem from '../../components/DispatchLinkItem';
import DropdownNavigation from '../../components/DropdownNavigation';
import UsageReports from '../UsageReports';
import RTMReports from '../RTMReports';
import NPSReports from '../NPSReports';

const Reports = ({ visibleProfile }) => {
  const [active, setActive] = useState('usage');

  const rtmEnabled = !!visibleProfile?.GroupInfo?.EnableRTM;
  const ptuEnabled = !!visibleProfile?.GroupInfo?.EnablePTU;

  return (
    <>
      <DropdownNavigation />

      <div className="ptw-main-body">
        <Breadcrumb>
          <DispatchLinkItem url="/administrator/" title="Admin" />
          <Breadcrumb.Item active>Reports</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Tabs
        defaultActiveKey="usage"
        activeKey={active}
        onChange={key => setActive(key)}
      >
        <Tabs.TabPane key="usage" tab="Usage Reports">
          <UsageReports />
        </Tabs.TabPane>

        {rtmEnabled && (
          <Tabs.TabPane key="rtm" tab="RTM Reports">
            <RTMReports />
          </Tabs.TabPane>
        )}

        {ptuEnabled && (
          <Tabs.TabPane key="nps" tab="NPS Reports">
            <NPSReports />
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
});

export default connect(mapStateToProps)(withRouter(Reports));
