import { getRecoil, setRecoil } from 'recoil-nexus';

import states from '../../states';
import { getActivityLogs, saveActivityLog } from '../../services/rtm';

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function checkTechRole(user) {
  return user && user.billingRole === 'Tech';
}

export function checkDischarged(patient) {
  let discharged = false;

  if (patient) {
    if (patient.hasOwnProperty('Discharge')) {
      discharged = patient.Discharge;
    }
  }

  return discharged;
}

export function checkRTMEnabled(patient) {
  return patient && patient.EnableRTM;
}

export async function setActivity(patient, activity) {
  let oldActivity = null;
  let oldStreamDuration = 0;

  setRecoil(states.rtm, prevState => {
    oldActivity = prevState.timer.activity;
    oldStreamDuration = prevState.timer.streamDuration;

    return {
      ...prevState,
      timer: {
        ...prevState.timer,
        activity,
        patient,
      },
    };
  });

  if (oldActivity && oldStreamDuration > 2) {
    await saveAndFetchActivityLogs(patient, oldActivity, oldStreamDuration);
  }
}

export function setHEPActivity(patient, activity) {
  setRecoil(states.rtm, prevState => {
    return {
      ...prevState,
      timer: {
        ...prevState.timer,
        activity,
        patient,
      },
    };
  });
}

export async function setMessageActivity(patient) {
  let oldPatient = null;
  let oldActivity = null;
  let oldStreamDuration = 0;

  setRecoil(states.rtm, prevState => {
    oldPatient = prevState.timer.patient;
    oldActivity = prevState.timer.activity;
    oldStreamDuration = prevState.timer.streamDuration;

    return {
      ...prevState,
      timer: {
        ...prevState.timer,
        activity: 'Message Patient',
        patient,
      },
    };
  });

  // when patient changes
  if (oldPatient?.Sub !== patient?.Sub) {
    if (oldActivity && oldStreamDuration > 0) {
      await saveActivityLog(oldPatient, oldActivity, oldStreamDuration);
      await fetchActivityLogs(patient.Sub);
    } else {
      await fetchActivityLogs(patient.Sub);
    }
  } else {
    await fetchActivityLogs(patient.Sub);
  }
}

export async function clearActivity() {
  const rtmState = getRecoil(states.rtm);
  const { patient, isMonitoring } = rtmState.timer;

  if (patient) {
    let oldPatient = null;
    let oldActivity = null;
    let oldStreamDuration = 0;

    setRecoil(states.rtm, prevState => {
      oldPatient = prevState.timer.patient;
      oldActivity = prevState.timer.activity;
      oldStreamDuration = prevState.timer.streamDuration;

      return {
        ...prevState,
        timer: {
          ...prevState.timer,
          patient: null,
          activity: null,
          streamDuration: 0,
        },
      };
    });

    if (isMonitoring && oldActivity && oldStreamDuration > 2) {
      await saveActivityLog(oldPatient, oldActivity, oldStreamDuration);
    }
  }
}

export async function saveAndFetchActivityLogs(
  patient,
  activity,
  streamDuration
) {
  try {
    setRecoil(states.rtm, prevState => ({
      ...prevState,
      timer: {
        ...prevState.timer,
        loadingActivityLogs: true,
      },
    }));

    // blocks saving when patient's enable rtm is false
    if (patient.EnableRTM) {
      await saveActivityLog(patient, activity, streamDuration);
      await fetchActivityLogs(patient.Sub);
    }
  } catch (error) {
    console.log('[saveAndFetchActivityLogs] error', error);
  } finally {
    setRecoil(states.rtm, prevState => ({
      ...prevState,
      timer: {
        ...prevState.timer,
        loadingActivityLogs: false,
      },
    }));
  }
}

export async function fetchActivityLogs(sub) {
  try {
    const response = await getActivityLogs(sub);

    if (response.status === 200) {
      setRecoil(states.rtm, prevState => ({
        ...prevState,
        timer: {
          ...prevState.timer,
          activityLogs: response.data,
        },
      }));
    }
  } catch (error) {
    console.log('Error fetching activity logs:', error);
  }
}
