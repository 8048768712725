import React, { Component } from 'react';
import Slider from 'react-slick';
import moment from 'moment';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ArrowDirection from './ArrowDirection';
import { getCurrentWeekDates, setCurrentWeek } from './actions/patients';
import { Typography } from 'antd';

class SimpleSlider extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.week !== this.props.week) {
      const weekArray = getCurrentWeekDates(this.props.week);
      this.props.dispatch(setCurrentWeek(weekArray));
    }
  }

  render() {
    const { week, currentWeek } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      prevArrow: <ArrowDirection direction="prev" />,
      nextArrow: <ArrowDirection direction="next" />,
    };

    window.moment = moment;
    return (
      <div>
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div>
            <Typography.Title level={4}>
              {currentWeek[0].month === currentWeek[6].month
                ? `${currentWeek[0].month} ${currentWeek[0].dayN} - ${currentWeek[6].dayN}`
                : `${currentWeek[0].month} ${currentWeek[0].dayN} - ${currentWeek[6].month} ${currentWeek[6].dayN}`}
              {week === 0 && ' (This week)'}
            </Typography.Title>
          </div>
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  week: state.patients.weekChange,
  currentWeek: state.patients.currentWeek,
});

export default connect(mapStateToProps)(withRouter(SimpleSlider));
