import React, { useState } from 'react';
import { Row, Col, Typography, Checkbox } from 'antd';

import states from '../../../states';
import { useRecoilValue } from 'recoil';
import CustomizeExerciseItem from './CustomizeExerciseItem';
import CustomizeAllExerciseDrawer from './CustomizeAllExerciseDrawer';

const CustomizeExerciseList = ({ isExerciseFrequency }) => {
  const [checkedExercises, setCheckedExercises] = useState([]);
  const prescription = useRecoilValue(states.prescription);
  const { form } = prescription;

  const handleCheckExerciseItem = id => {
    setCheckedExercises(prevState => {
      const isChecked = prevState.includes(id);

      if (!isChecked) {
        return [...prevState, id];
      } else {
        return prevState.filter(exerciseId => exerciseId !== id);
      }
    });
  };

  const handleCheckAllExercise = isChecked => {
    setCheckedExercises(() => {
      if (!isChecked) {
        return [];
      } else {
        return form.prescription.exercises.map(exercise => exercise['id']);
      }
    });
  };

  if (!form) return null;

  const isCheckedAll =
    form.prescription.exercises.length === checkedExercises.length;

  return (
    <React.Fragment>
      <CustomizeAllExerciseDrawer
        isExerciseFrequency={isExerciseFrequency}
        checkedExercises={checkedExercises}
        setCheckedExercises={setCheckedExercises}
      />
      <div className="mt-2 mb-5">
        <Typography.Title level={4}>Customize Exercises</Typography.Title>
        <Typography.Text type="secondary">
          Specific Instructions For Individual Exercises.
        </Typography.Text>
        <div className="mt-3">
          <div className="customize-exercise-list-header">
            <Row gutter={[16, 16]}>
              <Col lg={1} md={1} className="center-content">
                <Checkbox
                  onChange={e => handleCheckAllExercise(e.target.checked)}
                  checked={isCheckedAll}
                />
              </Col>
              <Col lg={23} md={23}>
                <Typography.Text strong>
                  Customize Exercise Details
                </Typography.Text>
              </Col>
            </Row>
          </div>
          {Array.isArray(form.prescription.exercises) &&
            form.prescription.exercises.map((exercise, i) => (
              <CustomizeExerciseItem
                key={i}
                exercise={exercise}
                isExerciseFrequency={isExerciseFrequency}
                checkedExercises={checkedExercises}
                handleCheckExerciseItem={handleCheckExerciseItem}
              />
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomizeExerciseList;
