import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { notification } from 'antd';

import ExerciseList from '../../../components/ExerciseList';
import setNotification from '../../../utils/setNotification.utils';
import states from '../../../states';
import services from '../../../services';

const createExercises = (prevState, isSelected, hepName, hepItem) => {
  const prescriptionState = {
    ...prevState
  };

  if (prescriptionState.form) {
    const { form } = prescriptionState;
    const { prescription } = form;

    prescriptionState.form = {
      ...form,
      prescription: {
        ...prescription,
        exercises: isSelected
          ? prescription.exercises.filter(
              (item) => item.exerciseName !== hepName
            )
          : [...prescription.exercises, hepItem]
      }
    };
  }

  return prescriptionState;
};

const SelectExercises = () => {
  const templates = useRecoilValue(states.templates);

  const [favorites, setFavorites] = useRecoilState(states.favorites);
  const [modals, setModals] = useRecoilState(states.modal);
  const [prescription, setPrescription] = useRecoilState(states.prescription);

  const { form } = prescription;
  const rxExercises = form ? form.prescription.exercises : [];

  const addFavorite = (id) => {
    const updatedList = favorites.list.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isSelected: !item.isSelected
        };
      }

      return item;
    });

    setFavorites({ list: updatedList });
  };

  const handleSelectExercise = (exercise) => {
    const { star, id, isSelected, exerciseName } = exercise;

    if (star) {
      addFavorite(id);
    }

    setPrescription((prevPrescription) => ({
      ...createExercises(prevPrescription, isSelected, exerciseName, exercise)
    }));
  };

  const handleAddTemplateModal = () => {
    setModals({
      ...modals,
      addTemplate: !modals.addTemplate
    });
  };

  const handleRemoveExerciseItem = (exerciseName) => {
    setPrescription((prevPrescription) => ({
      ...prevPrescription,
      form: {
        ...prevPrescription.form,
        prescription: {
          ...prevPrescription.form.prescription,
          exercises: prevPrescription.form.prescription.exercises.filter(
            (item) => item.exerciseName !== exerciseName
          )
        }
      }
    }));
  };

  const handleRemoveAllExerciseItem = () => {
    setPrescription((prevPrescription) => ({
      ...prevPrescription,
      form: {
        ...prevPrescription.form,
        prescription: {
          ...prevPrescription.form.prescription,
          exercises: []
        }
      }
    }));
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { form } = prescription;
    const updatedItems = [...form.prescription.exercises];
    const item = updatedItems.splice(result.source.index, 1)[0];
    updatedItems.splice(result.destination.index, 0, item);

    setPrescription({
      ...prescription,
      form: {
        ...form,
        prescription: {
          ...form.prescription,
          exercises: updatedItems
        }
      }
    });
  };

  const addTemplateToSelectionPanel = async (template) => {
    if (template.Exercises?.length) {
      addTemplate({
        ...template
      });

      return;
    }

    try {
      notification.info({
        message: 'Loading...',
        description: 'Fetching template data. Please wait.'
      });

      const { Id, GroupId } = template;
      await services.templates.getItem(GroupId, Id).then((res) => {
        if (res.status === 200) {
          value = res.data;
          notification.destroy();

          addTemplate({
            ...res.data
          });
        }
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while fetching template data.'
      });
    }
  };

  const addTemplate = (payload) => {
    try {
      const exList = payload.Exercises || payload.exercises;
      const templateInstruction =
        payload.Instructions || payload.instructions || '';
      const templateFrequency = payload.Frequency || payload.frequency || '';
      const templatePerdiem = payload.Perdiem || payload.perdiem || '';

      const templateIds = exList.map(({ id }) => id);
      const updatedList = [];

      const { form } = prescription;
      for (let i = 0; i < form.prescription.exercises.length; i++) {
        const ex = form.prescription.exercises[i];

        let sentinel = false;
        for (let j = 0; j < templateIds.length; j++) {
          const id = templateIds[j];
          if (id === ex.id) {
            sentinel = true;
          }
        }

        if (sentinel === false) {
          updatedList.push(ex);
        }
      }

      updatedList.push(...exList);

      setPrescription((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          prescription: {
            ...prevState.form.prescription,
            frequency: templateFrequency,
            perdiem: templatePerdiem,
            instructions: templateInstruction,
            exercises: updatedList
          }
        }
      }));

      setNotification('success', 'Success!', 'Template has been added.');
      setModals((prevState) => ({
        ...prevState,
        addTemplate: !prevState.addTemplate
      }));
    } catch (err) {
      console.log(err);
      setNotification(
        'error',
        'Error!',
        'An error occurred while adding template.'
      );
    }
  };

  return (
    <ExerciseList
      isEditing
      selectedExercises={rxExercises}
      handleAddTemplateModal={handleAddTemplateModal}
      handleSelectExercise={handleSelectExercise}
      handleRemoveExerciseItem={handleRemoveExerciseItem}
      handleRemoveAllExerciseItem={handleRemoveAllExerciseItem}
      onDragEnd={onDragEnd}
      addTemplateToSelectionPanel={addTemplateToSelectionPanel}
    />
  );
};

export default SelectExercises;
