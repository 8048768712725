// import update from 'immutability-helper';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';

import {
  START_LOADING_MONITORED_PATIENT,
  END_LOADING_MONITORED_PATIENT,
  SET_CURRENT_MONITORED_PATIENT,
  CLEAR_CURRENT_MONITORED_PATIENT,
  GET_RECENT_MONITORED_LOGS,
  CLEAR_RECENT_MONITORED_LOGS,
  EXIT_CURRENT_MONITORED_PATIENT,
  SET_IS_MONITORING
} from './actions';

const defaultState = {
  isMonitoring: false,
  currentMonitoredPatient: null,
  currentMonitoredPatientTotal: '00:00:00',
  loadingMonitoredPatient: false,
  loadingLogs: false,
  recentMonitoredLogs: null,
  exitActivity: false
};

const monitorTimerReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case START_LOADING_MONITORED_PATIENT:
      return {
        ...state,
        loadingMonitoredPatient: true
      };

    case END_LOADING_MONITORED_PATIENT:
      return {
        ...state,
        loadingMonitoredPatient: false
      };

    case SET_IS_MONITORING:
      return {
        ...state,
        isMonitoring: payload
      };

    case SET_CURRENT_MONITORED_PATIENT:
      return {
        ...state,
        loadingMonitoredPatient: false,
        currentMonitoredPatient: payload
      };

    case CLEAR_CURRENT_MONITORED_PATIENT:
      return {
        ...state,
        currentMonitoredPatient: null,
        loadingMonitoredPatient: false,
        exitActivity: false
      };

    case EXIT_CURRENT_MONITORED_PATIENT:
      return {
        ...state,
        exitActivity: true,
        loadingMonitoredPatient: false
      };

    case `${GET_RECENT_MONITORED_LOGS}_${PENDING}`:
      return {
        ...state,
        loadingLogs: true
      };

    case `${GET_RECENT_MONITORED_LOGS}_${FULFILLED}`:
      return {
        ...state,
        loadingLogs: false,
        recentMonitoredLogs: payload
      };

    case `${GET_RECENT_MONITORED_LOGS}_${REJECTED}`:
      return {
        ...state,
        loadingLogs: false,
        recentMonitoredLogs: payload
      };

    case CLEAR_RECENT_MONITORED_LOGS:
      return {
        ...state,
        recentMonitoredLogs: null
      };

    default:
      return {
        ...state
      };
  }
};

export default monitorTimerReducer;
