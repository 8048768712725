import React, { Component, Fragment } from 'react';
import V from 'voca';
import { Typography, Modal, Button } from 'antd';
import setNotification from '../../../utils/setNotification.utils';

const checkExerciseFrequency = exercise => {
  if (exercise === undefined) {
    return false;
  }

  return !!exercise.frequency;
};

class DocumentNowModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showId: false
    };
  }

  formatExerciseText(ex) {
    const { showId } = this.state;
    const { exerciseName, groupId, id, frequency, perdiem, reps, sets, hold } =
      ex;

    return (
      <li>
        <span>{V.titleCase(exerciseName)} </span>
        {showId && (
          <span style={{ display: 'block' }}>
            {groupId}-{id}
          </span>
        )}
        <ul>
          <li>
            {reps && `${reps} reps`}
            {sets && `, ${sets} sets`}
            {hold && `, ${hold}`}
          </li>
        </ul>
        {frequency && perdiem && (
          <ul>
            <li>
              {frequency}x per {perdiem}
            </li>
          </ul>
        )}
      </li>
    );
  }

  createClipboardString = () => {
    const {
      prescription: { exercises, instructions, frequency, perdiem }
    } = this.props;
    const { showId } = this.state;

    let clipBoardString = 'Exercises Prescribed:\n';

    for (let i = 0; i < exercises.length; i++) {
      if (showId) {
        clipBoardString =
          clipBoardString +
          '    •' +
          V.titleCase(exercises[i].exerciseName) +
          '\n' +
          ' Id: ' +
          exercises[i].groupId +
          '-' +
          exercises[i].id +
          '\n';
      } else {
        clipBoardString =
          clipBoardString +
          '    •' +
          V.titleCase(exercises[i].exerciseName) +
          '\n';
      }

      clipBoardString =
        clipBoardString +
        `       • ${exercises[i].reps ? `${exercises[i].reps} reps` : ''}${
          exercises[i].sets ? `, ${exercises[i].sets} sets` : ''
        }${exercises[i].hold ? `, ${exercises[i].hold}` : ''}` +
        '\n';

      if (exercises[i].frequency && exercises[i].perdiem) {
        clipBoardString =
          clipBoardString +
          '       •' +
          exercises[i].frequency +
          'x per ' +
          exercises[i].perdiem +
          '\n';
      }
    }
    if (!checkExerciseFrequency(exercises[0])) {
      clipBoardString =
        clipBoardString +
        '\nPrescription Frequency:\n' +
        frequency +
        ' per ' +
        perdiem +
        '\n';
    }

    if (instructions) {
      return clipBoardString + '\nAdditional Instructions:\n' + instructions;
    } else return clipBoardString;
  };

  copyToClipboard = () => {
    const { closeWindow } = this.props;
    try {
      const clipboardString = this.createClipboardString();

      const input = document.createElement('textarea');

      document.body.appendChild(input);
      input.value = clipboardString;
      input.select();

      const result = document.execCommand('copy');

      if (result === true) {
        setNotification(
          'success',
          'Success!',
          'Item has been copied to clipboard.'
        );
      } else {
        console.log(result);
        setNotification('error', 'Error!', 'Could not copy to clipboard.');
      }

      document.body.removeChild(input);
    } catch (err) {
      console.log(err);
      setNotification('error', 'Error!', 'Could not copy to clipboard.');
    }
    closeWindow();
  };

  render() {
    const {
      prescription: { exercises, instructions, frequency, perdiem }
    } = this.props;
    const { showId } = this.state;

    return (
      <div className="reactive-modal">
        <Modal
          title="Home Exercise Program"
          visible={true}
          onCancel={this.props.closeWindow}
          footer={[
            <Button
              key="copy"
              size="large"
              type="primary"
              className="ptw-btn btn-primary"
              onClick={this.copyToClipboard}
            >
              Copy to Clipboard
            </Button>,
            <Button
              key="cancel"
              size="large"
              type="default"
              className="ptw-btn"
              onClick={this.props.closeWindow}
            >
              Cancel
            </Button>
          ]}
          width={900}
        >
          <div className="tab-header">
            <Typography.Title level={4}>Exercises Prescribed</Typography.Title>
            <Button size="large" className="ptw-btn">
              {showId ? 'Hide Video Identifiers' : 'Show Video Identifiers'}
            </Button>
          </div>

          <div ref={text => (this.text = text)}>
            <ul>
              {exercises.map((exercise, i) => (
                <Fragment key={i}>{this.formatExerciseText(exercise)}</Fragment>
              ))}
            </ul>

            {!checkExerciseFrequency(exercises[0]) && (
              <React.Fragment>
                <Typography.Title level={4}>
                  Prescription Frequency:
                </Typography.Title>
                <Typography.Paragraph>
                  {frequency}x per {perdiem}
                </Typography.Paragraph>
              </React.Fragment>
            )}

            {instructions && (
              <>
                <Typography.Title level={4}>
                  Additional Instructions
                </Typography.Title>
                <Typography.Paragraph>
                  <pre>{instructions}</pre>
                </Typography.Paragraph>
              </>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default DocumentNowModal;
