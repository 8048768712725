import React from 'react';
import { useRecoilValue } from 'recoil';
import { Select } from 'antd';

import states from '../../../states';

const LocationSelect = ({ loading, value, onChange, multiple = false }) => {
  const groups = useRecoilValue(states.groups);

  return (
    <Select
      showSearch
      className="nps-reports-select"
      placeholder="Select Location"
      optionFilterProp="children"
      {...(multiple && { mode: 'tags' })}
      loading={loading}
      disabled={loading}
      value={value}
      onChange={value => onChange(value)}
      options={groups.clinics.map(item => ({
        value: item.Id,
        label: item.ClinicName
      }))}
      filterOption={(input, option) => {
          if (option && option.label) {
              const label = String(option.label).toLowerCase();
              if (label.includes(String(input).toLowerCase())) {
            return option;
          }
        }
      }}
    />
  );
};

export default LocationSelect;
