import { useState, useEffect } from 'react';
import { Amplify, Predictions } from 'aws-amplify';
import { ENGLISH } from './constants';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

const useTranslate = (currText, currLocale, translate = true) => {
  if (!translate) {
    return currText;
  }

  const [translation, setTranslation] = useState('');

  useEffect(() => {
    translateText();
  }, [currText, currLocale]);

  const translateText = async () => {
    if (!currText || currLocale === ENGLISH) {
      setTranslation(currText);
      return;
    }

    try {
      Predictions.convert({
        translateText: {
          source: {
            text: currText
          },
          targetLanguage: currLocale
        }
      }).then((res) => setTranslation(res.text));
    } catch (error) {
      setTranslation(currText);
    }
  };

  return translation;
};

export default useTranslate;
