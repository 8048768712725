export const downloadFile = (data, filename) => {
  if (!Array.isArray(data)) {
    throw new Error('Invalid Data');
  }

  const file = [];
  const headers = Object.keys(data[0]);
  const values = data.map(item => {
    const row = [];
    Object.keys(item).forEach(key => {
      let rowItem = item[key];
      if (typeof rowItem === 'string') {
        if (rowItem.endsWith('\n')) {
          rowItem = rowItem.slice(0, -1);
        }

        if (rowItem.includes(',')) {
          rowItem = `"${rowItem}"`;
        }
      }

      row.push(rowItem);
    });

    return row.join(',');
  });

  file.push(headers.join(','));
  file.push(values.join('\n'));

  const a = document.createElement('a');
  const blob = new Blob([file.join('\n')], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  a.href = url;
  a.setAttribute('download', filename + '.csv');
  a.click();
};
