import { createSelector } from 'reselect'
import jwt_decode from 'jwt-decode'

import * as ptwired from './actions/ptwiredExercises'
import * as group from '../groups/actions/groupExercises'
import {getFormMeta, getFormSyncErrors} from "redux-form";

export const currentUserSelector = state => state.currentUser

export const decodedCurrentUserSelector = createSelector(
  currentUserSelector,
  currentUser => currentUser.token ? jwt_decode(currentUser.token) : currentUser
)

export const currentUserGroupSelector = createSelector(
  decodedCurrentUserSelector,
  ({groupId, groupName}) => ({id: groupId, name: groupName})
)

export const exerciseLibraryTypeSelector = createSelector(
  decodedCurrentUserSelector,
  ({role}) => role === 'sa' ? 'ptwired' : 'group'
)

export const exercisesStoreSelector = createSelector(
  state => state,
  decodedCurrentUserSelector,
  (state, {role}) => state[role === 'sa' ? 'ptwiredExercises' : 'groupExercises']
)

export const exercisesItemsSelector = createSelector(
  exercisesStoreSelector,
  exercises => exercises.items
)

export const exercisesValuesSelector = createSelector(
  exercisesItemsSelector,
  exercisesItems => Object.values(exercisesItems)
)

export const exerciseByNameSelector = name =>
  createSelector(
    exercisesValuesSelector,
    exercisesValues => exercisesValues.find(e => e.exerciseName === name)
  )

//maybe these could be passed to a mapDispatchToProps func
export const actionsSelector = createSelector(
  decodedCurrentUserSelector,
  ({role, groupName}) => {
    if(role === 'sa'){
      return {
        getExercise: ptwired.getExercise,
        getExercises: ptwired.getExercises,
        postExercise: ptwired.postExercise,
        updateExercise: ptwired.updateExercise, 
        searchExercises: ptwired.searchExercises
      }
    }else{
      return {
        getExercise: group.getGroupExercise.bind(null, groupName),
        getExercises: group.getGroupExercises(groupName),
        postExercise: group.postGroupExercise.bind(null, groupName),
        updateExercise: group.updateGroupExercise.bind(null, groupName),
        searchExercises: group.searchGroupExercises.bind(null, groupName)
      }
    }
  }
)

export const anyInvalidTouchedField = formName => createSelector(
  getFormMeta(formName),
  getFormSyncErrors(formName),
  (fields, syncErrors) => {
    if(!fields || !syncErrors) return false
    let fieldKeys = Object.keys(fields)
    for(let i=0; i<fieldKeys.length; i++){
      let key = fieldKeys[i]
      if(fields[key].touched && syncErrors[key]) return true
    }
    return false
  }
)

export const showParamsError = formName => createSelector(
    getFormMeta(formName),
    (fields) => fields && ((fields.reps && fields.reps.touched) || (fields.sets && fields.sets.touched) || (fields.hold && fields.hold.touched))
)