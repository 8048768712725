import React, { Component } from 'react';
import { Button, Modal, Row, Col } from "react-bootstrap";


class ReloadModal extends Component {
    render(){

        return (
            <div className="reactive-modal" style={{textAlign:'center'}}>
                <Modal.Dialog >
                    <Modal.Header>
                        <Modal.Title style={{fontSize:'30px'}}>NEW UPDATE TO PT Wired!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Click below to access the new version of PT Wired.</Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col md={8}>
                                <Button onClick={this.props.closeWindow} bsStyle="primary">Take me to the new version!</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
        )

    }
}

export default ReloadModal;