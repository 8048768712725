import '../styles.css';
import {
  Button,
  Checkbox,
  Input,
  notification,
  Select,
  Space,
  Switch,
  Typography,
  Row,
  Col,
} from 'antd';
import { Fragment, useEffect, useState } from 'react';

import services from '../../../../../services';

const FormInput = ({ label, value, onChange, helper = undefined }) => (
  <Fragment>
    <Typography.Text>{label}</Typography.Text>

    <Input
      readOnly={onChange === null}
      value={value}
      onChange={e => onChange(e.target.value)}
    />

    {helper}
  </Fragment>
);

const FormSelect = ({ options, label, value, onChange }) => (
  <Fragment>
    <Typography.Text>{label}</Typography.Text>

    <Select className="dropdown" value={value} onChange={val => onChange(val)}>
      {options.map((item, i) => (
        <Fragment key={i}>
          <Select.Option value={item.key}>{item.value}</Select.Option>
        </Fragment>
      ))}
    </Select>
  </Fragment>
);

const FormSwitch = ({ label, value, onChange }) => (
  <Fragment>
    <Space size="small">
      <Switch checked={value} onChange={val => onChange(val)} />

      <Typography.Text>{label}</Typography.Text>
    </Space>
  </Fragment>
);

const UserForm = ({ profile, group, clinics, getProfile }) => {
  const [profileCopy, setProfileCopy] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (profile) {
      setProfileCopy(profile);
    }
  }, [profile]);

  const handleUpdateProfile = async () => {
    try {
      setSaving(true);

      const profilePayload = { ...profile };
      delete profilePayload.GroupName;

      const profileCopyPayload = { ...profileCopy };
      delete profileCopyPayload.GroupName;

      await services.admin.updateProfile({
        oldProfile: profilePayload,
        newProfile: profileCopyPayload,
      });

      notification.success({
        message: 'Update Success',
        description: 'User profile updated successfully',
        duration: 3,
      });
    } catch (error) {
      notification.error({
        message: 'Analytics Failed',
        description: 'An error occurred while updating user profile',
        duration: 3,
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    profileCopy && (
      <div className="su-utilities">
        <Row gutter={[24, 24]}>
          <Col md={12} sm={24} xs={24}>
            <FormInput
              label="GROUP ID"
              value={profileCopy.GroupId}
              onChange={null}
              helper={
                <Typography.Text>
                  CURRENT GROUP:{' '}
                  <strong>{profileCopy.GroupName?.toUpperCase()}</strong>
                </Typography.Text>
              }
            />
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Checkbox
              className="checkbox"
              style={{ marginTop: '1.1em' }}
              disabled={profile.GroupId === group.id}
              checked={profileCopy.GroupId === group.id}
              onChange={e =>
                setProfileCopy({
                  ...profileCopy,
                  GroupId: e.target.checked ? group.id : profile.GroupId,
                })
              }
            >
              ASSIGN TO GROUP
            </Checkbox>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <FormInput
              label="EMAIL ADDRESS"
              value={profileCopy.EmailAddress}
              onChange={val =>
                setProfileCopy({
                  ...profileCopy,
                  EmailAddress: val,
                })
              }
            />
          </Col>
          <Col md={12} sm={24} xs={24}>
            <FormInput
              label="PHONE NUMBER"
              value={profileCopy.PhoneNumber}
              onChange={val =>
                setProfileCopy({
                  ...profileCopy,
                  PhoneNumber: val,
                })
              }
            />
          </Col>
          <Col md={12} sm={24} xs={24}>
            <FormSelect
              options={[
                { key: 'sa', value: 'Super Admin' },
                { key: 'ga', value: 'Group Admin' },
                { key: 'pt', value: 'Physical Therapist' },
                { key: 'p', value: 'Patient' },
              ]}
              label="ROLE"
              value={profileCopy.Role}
              onChange={val =>
                setProfileCopy({
                  ...profileCopy,
                  Role: val,
                })
              }
            />
          </Col>
          <Col md={12} sm={24} xs={24}>
            <FormSelect
              options={clinics.map(item => ({
                key: item.Id,
                value: item.ClinicName,
              }))}
              label="CLINICS"
              value={profileCopy.Clinic}
              onChange={val =>
                setProfileCopy({
                  ...profileCopy,
                  Clinic: val,
                })
              }
            />
          </Col>
          <Col md={12} sm={24} xs={24}>
            <FormInput
              label="FIRST NAME"
              value={profileCopy.FirstName}
              onChange={val =>
                setProfileCopy({
                  ...profileCopy,
                  FirstName: val,
                })
              }
            />
          </Col>
          <Col md={12} sm={24} xs={24}>
            <FormInput
              label="LAST NAME"
              value={profileCopy.LastName}
              onChange={val =>
                setProfileCopy({
                  ...profileCopy,
                  LastName: val,
                })
              }
            />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <Space size={12}>
              <FormSwitch
                label="ACTIVE"
                value={!!profileCopy.Active}
                onChange={val =>
                  setProfileCopy({
                    ...profileCopy,
                    Active: val,
                  })
                }
              />

              <FormSwitch
                label="DISCHARGE"
                value={!!profileCopy.Discharge}
                onChange={val =>
                  setProfileCopy({
                    ...profileCopy,
                    Discharge: val,
                  })
                }
              />
            </Space>
          </Col>
        </Row>

        <div className="buttons mt-3">
          <div className="button">
            <Button size="large" onClick={() => setProfileCopy(profile)}>
              CANCEL
            </Button>
          </div>

          <div className="button">
            <Button
              type="primary"
              className="ptw-btn"
              size="large"
              loading={saving}
              disabled={JSON.stringify(profile) === JSON.stringify(profileCopy)}
              onClick={async () => {
                await handleUpdateProfile();
                await getProfile(profileCopy.EmailAddress);
              }}
            >
              SAVE CHANGES
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

export default UserForm;
