import {
  FilterOutlined,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
  UploadOutlined
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  Menu,
  message,
  notification,
  Tooltip,
  Typography,
  Upload
} from 'antd';
import { isEqual } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { capitalize } from 'voca';
import { getLookupRole } from './utils/roles';

import * as XLSX from 'xlsx';

import AdminForm from './AdminForm';
import Loading from '../../../components/Loading';
import services from '../../../services';

const GroupAdminsPage = ({ currentGroup }) => {
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState([]);
  const [groupAdmins, setGroupAdmins] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [search, setSearch] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [formType, setFormType] = useState('add');
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [saving, setSaving] = useState(false);

  const atiGroup =
    currentGroup.id === 'c9d548b4-e3e8-445f-89db-ce049c1098fb' ||
    currentGroup.id === '08d0d876-a941-4b67-82cb-c91c5c1ea91a';
  const providersList = search || filters.length ? filteredList : groupAdmins;

  useEffect(() => {
    initList();
  }, []);

  const initList = async () => {
    try {
      setLoading(true);
      await services.groups.getProviders(currentGroup.id).then((res) => {
        if (res.status === 200) {
          const activeLists = res.data.filter((item) => !!item.Active);
          const sortedActiveLists = [...activeLists].sort((a, b) =>
            a.FirstName.localeCompare(b.FirstName)
          );

          const deactivated = res.data.filter((item) => !!!item.Active);
          const sortedDeactivated = [...deactivated].sort((a, b) =>
            a.FirstName.localeCompare(b.FirstName)
          );

          setGroupAdmins([...sortedActiveLists, ...sortedDeactivated]);
        }
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'An error occurred while fetching providers.'
      });
    } finally {
      setLoading(false);
    }
  };

  const validateFileType = (file) => {
    const validTypes = [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    const isValidType = validTypes.includes(file.type);
    const hasValidExtension = ['.csv', '.xls', '.xlsx'].includes(
      file.name.split('.').pop().toLowerCase()
    );

    if (isValidType || hasValidExtension) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {
          type: 'array'
        });

        const names = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[names];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1
        });

        if (!jsonData.length) {
          return;
        }

        const dataArr = jsonData.slice(1).map((row) => {
          const obj = {};
          jsonData[0].forEach((key, index) => {
            obj[key] = row[index];
          });

          return obj;
        });

        const items = dataArr.filter((item) => !!item['External ID']);
        const nonAdmins = groupAdmins.filter((item) => item.Role === 'pt');

        const insertArr = items.filter((item) => {
          const email = item['Employee Business Email']?.toLowerCase();
          const included = nonAdmins.some(
            (admin) => admin.EmailAddress.toLowerCase() === email
          );

          return !included;
        });

        const deleteArr = nonAdmins.filter((admin) => {
          const email = admin.EmailAddress.toLowerCase();
          const included = items.some(
            (item) => item['Employee Business Email']?.toLowerCase() === email
          );

          return !included && !!admin.Active;
        });

        const toProcessArr = [
          ...insertArr.map((item) => ({
            action: 'insert',
            data: item
          })),
          ...deleteArr.map((item) => ({
            action: 'delete',
            data: item
          }))
        ];

        for (let i = 0; i < toProcessArr.length; i++) {
          const item = toProcessArr[i];

          notification.info({
            key: 'import-providers',
            closeIcon: <></>,
            description: `Processing ${i + 1} of ${
              toProcessArr.length
            } providers ...`,
            icon: <LoadingOutlined />,
            duration: i === toProcessArr.length - 1 ? 2 : 0
          });

          try {
            if (item.action === 'insert') {
              await services.providers.addProfile({
                GroupId: currentGroup.id,
                EmailAddress:
                  item.data['Employee Business Email'].toLowerCase(),
                Active: true,
                FirstName: item.data['First Name'],
                LastName: item.data['Last Name'],
                BillingRole: getLookupRole(item.data['Job Title']),
                Role: 'pt',
                PartTime: item.data['Employment Status'] !== 'Full Time'
              });
            } else if (item.action === 'delete') {
              await services.providers.updateProfile({
                ...item.data,
                GroupId: currentGroup.id,
                Active: false,
                active: false,
                billingRole: item.data.BillingRole,
                emailAddress: item.data.EmailAddress,
                firstName: item.data.FirstName,
                lastName: item.data.LastName,
                groupId: currentGroup.id,
                role: item.data.Role,
                isEmailChanged: false,
                sendEmail: false
              });
            }
          } catch (error) {
            console.log('[import providers]', error);
            continue;
          }
        }

        initList();
      };

      reader.readAsArrayBuffer(file);

      return true;
    }

    message.error('Invalid file type. Please upload an Excel or CSV only.');
    return false;
  };

  const billingRole = (role) => {
    if (!role) {
      return '';
    }

    switch (role.toLowerCase()) {
      case 'pt':
        return 'Provider';

      case 'pta':
        return 'Provider Assistant';

      case 'tech':
        return 'Technician';

      default:
        return role;
    }
  };

  const filterList = (filterArr) => {
    return groupAdmins.filter((item) => {
      const hasProvider = filterArr.includes('PT');
      const hasAssistant = filterArr.includes('PTA');
      const hasTech = filterArr.includes('TECH');

      let role = true;
      if (hasProvider && hasAssistant && hasTech) {
        role = true;
      } else if (hasProvider && hasAssistant) {
        role =
          item.BillingRole?.toLowerCase() === 'pt' ||
          item.BillingRole?.toLowerCase() === 'pta';
      } else if (hasProvider && hasTech) {
        role =
          item.BillingRole?.toLowerCase() === 'pt' ||
          item.BillingRole?.toLowerCase() === 'tech';
      } else if (hasAssistant && hasTech) {
        role =
          item.BillingRole?.toLowerCase() === 'pta' ||
          item.BillingRole?.toLowerCase() === 'tech';
      } else if (hasProvider) {
        role = item.BillingRole?.toLowerCase() === 'pt';
      } else if (hasAssistant) {
        role = item.BillingRole?.toLowerCase() === 'pta';
      } else if (hasTech) {
        role = item.BillingRole?.toLowerCase() === 'tech';
      }

      const hasActive = filterArr.includes('ACTIVE');
      const hasInactive = filterArr.includes('INACTIVE');

      let status = true;
      if (hasActive && hasInactive) {
        status = true;
      } else if (hasActive) {
        status = !!item.Active;
      } else if (hasInactive) {
        status = !!!item.Active;
      }

      const hasFullTime = filterArr.includes('FULLTIME');
      const hasPartTime = filterArr.includes('PARTTIME');

      let empStatus = true;
      if (hasFullTime && hasPartTime) {
        empStatus = true;
      } else if (hasFullTime) {
        empStatus = !!!item.PartTime;
      } else if (hasPartTime) {
        empStatus = !!item.PartTime;
      }

      return role && status && empStatus;
    });
  };

  const handleInputSearch = (e) => {
    const value = e.target.value;
    const query = value?.toLowerCase();

    if (!value) {
      if (filters.length) {
        setFilteredList(filterList(filters));
      }
    } else {
      const filteredArr = providersList.filter((item) => {
        const fname = item.FirstName?.toLowerCase();
        const lname = item.LastName?.toLowerCase();

        return fname.startsWith(query) || lname.startsWith(query);
      });

      setFilteredList(filteredArr);
    }

    setSearch(value);
  };

  const handleShowFilters = (value) => {
    setShowFilters(value);
  };

  const handleSelectFilters = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    let filterArr = [...filters];

    const add = (key) => filterArr.push(key);
    const remove = (key) => {
      filterArr = filters.filter((item) => item !== key);
    };

    switch (value) {
      case 'PT':
        checked ? add('PT') : remove('PT');
        break;

      case 'PTA':
        checked ? add('PTA') : remove('PTA');
        break;

      case 'TECH':
        checked ? add('TECH') : remove('TECH');
        break;

      case 'ACTIVE':
        checked ? add('ACTIVE') : remove('ACTIVE');
        break;

      case 'INACTIVE':
        checked ? add('INACTIVE') : remove('INACTIVE');
        break;

      case 'FULLTIME':
        checked ? add('FULLTIME') : remove('FULLTIME');
        break;

      case 'PARTTIME':
        checked ? add('PARTTIME') : remove('PARTTIME');
        break;

      default:
        break;
    }

    setFilters(filterArr);
    setFilteredList(filterList(filterArr));
  };

  const handleShowModal = (type, data) => {
    setShowModal(true);
    setFormType(type);
    setSelectedAdmin(data);
  };

  const handleHideModal = () => {
    setShowModal(false);
    setSelectedAdmin(null);
  };

  const handleSubmitForm = async (values) => {
    if (isEqual(values, selectedAdmin)) {
      handleHideModal();
      notification.info({
        message: 'No changes applied!'
      });

      return;
    }

    try {
      setSaving(true);
      const payload = {
        ...values,
        EmailAddress: values.EmailAddress.toLowerCase(),
        GroupId: currentGroup.id,
        Role: values.isAdmin ? 'ga' : 'pt'
      };

      delete payload.isAdmin;

      if (formType === 'add') {
        await services.providers.addProfile(payload).then((res) => {
          if (res.status === 200) {
            notification.success({
              message: 'User Added!',
              description: 'User profile has been successfully added.'
            });
          }
        });
      } else if (formType === 'edit') {
        const ptEmail = selectedAdmin.EmailAddress.toLowerCase();
        const profile = groupAdmins.find(
          (item) => item.EmailAddress.toLowerCase() === ptEmail
        );

        payload.Sub = profile.Sub;
        payload.active = payload.Active;
        payload.billingRole = payload.BillingRole;
        payload.emailAddress = payload.EmailAddress;
        payload.firstName = payload.FirstName;
        payload.lastName = payload.LastName;
        payload.groupId = currentGroup.id;
        payload.role = payload.isAdmin ? 'ga' : 'pt';
        payload.isEmailChanged = payload.EmailAddress !== ptEmail;
        payload.sendEmail = false;

        await services.providers.updateProfile(payload).then((res) => {
          if (res.status === 200) {
            notification.success({
              message: 'Changes Saved!',
              description: 'User profile has been successfully updated.'
            });
          }
        });
      }

      handleHideModal();
      initList();
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: `An error occurred while ${
          formType === 'new' ? 'adding an admin' : 'saving changes'
        }.`
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      <Loading isLoading={loading}>
        {!groupAdmins.length ? (
          <Row>
            <Col md={4} mdOffset={4} className="ptw-action-block">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Button
                  type="primary"
                  className="btn-primary ptw-btn"
                  onClick={() => {
                    handleShowModal('add', {
                      Active: true,
                      PartTime: false,
                      isAdmin: false
                    });
                  }}
                >
                  Add Admin
                </Button>

                <Upload
                  accept=".csv,.xls,.xlsx"
                  maxCount={1}
                  showUploadList={false}
                  beforeUpload={validateFileType}
                >
                  <Button
                    type="primary"
                    className="btn-primary ptw-btn"
                    style={{
                      marginLeft: '1em'
                    }}
                  >
                    Upload File
                  </Button>
                </Upload>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <div className="tab-header">
              <div
                style={{
                  paddingTop: 8,
                  paddingLeft: 7
                }}
              >
                <Typography.Text
                  strong
                  style={{
                    fontSize: 16
                  }}
                >
                  Total Count: {providersList.length}
                </Typography.Text>
              </div>

              <div className="tab-header-controls">
                <Button
                  type="primary"
                  className="btn-primary ptw-btn"
                  onClick={() => {
                    handleShowModal('add', {
                      Active: true,
                      PartTime: false,
                      isAdmin: false
                    });
                  }}
                >
                  <PlusOutlined /> Add Admin
                </Button>

                <Upload
                  accept=".csv,.xls,.xlsx"
                  maxCount={1}
                  showUploadList={false}
                  beforeUpload={validateFileType}
                >
                  <Button
                    type="primary"
                    className="btn-primary ptw-btn"
                    style={{
                      marginRight: '1em'
                    }}
                  >
                    <UploadOutlined /> Upload File
                  </Button>
                </Upload>

                <Input
                  size="middle"
                  placeholder="Name or Email Address"
                  prefix={<SearchOutlined />}
                  value={search}
                  onChange={handleInputSearch}
                />

                <Tooltip title="Filter Admins">
                  <Dropdown
                    placement="bottomRight"
                    trigger={['click']}
                    overlay={
                      <Menu>
                        <Menu.ItemGroup title="Billing Role">
                          <Menu.Item>
                            <Checkbox value="PT" onChange={handleSelectFilters}>
                              Provider
                            </Checkbox>
                          </Menu.Item>

                          <Menu.Item>
                            <Checkbox
                              value="PTA"
                              onChange={handleSelectFilters}
                            >
                              Provider Assistant
                            </Checkbox>
                          </Menu.Item>

                          <Menu.Item>
                            <Checkbox
                              value="TECH"
                              onChange={handleSelectFilters}
                            >
                              Technician
                            </Checkbox>
                          </Menu.Item>
                        </Menu.ItemGroup>

                        <Menu.ItemGroup title="User Status">
                          <Menu.Item>
                            <Checkbox
                              value="ACTIVE"
                              onChange={handleSelectFilters}
                            >
                              Active
                            </Checkbox>
                          </Menu.Item>

                          <Menu.Item>
                            <Checkbox
                              value="INACTIVE"
                              onChange={handleSelectFilters}
                            >
                              Inactive
                            </Checkbox>
                          </Menu.Item>
                        </Menu.ItemGroup>

                        <Menu.ItemGroup title="Employment Status">
                          <Menu.Item>
                            <Checkbox
                              value="FULLTIME"
                              onChange={handleSelectFilters}
                            >
                              Full Time
                            </Checkbox>
                          </Menu.Item>

                          <Menu.Item>
                            <Checkbox
                              value="PARTTIME"
                              onChange={handleSelectFilters}
                            >
                              Part Time
                            </Checkbox>
                          </Menu.Item>
                        </Menu.ItemGroup>
                      </Menu>
                    }
                    open={showFilters}
                    onOpenChange={handleShowFilters}
                  >
                    <Button
                      shape="circle"
                      className="btn-default"
                      type={filters.length ? 'primary' : 'default'}
                      icon={
                        <FilterOutlined
                          style={{
                            fontSize: 16
                          }}
                        />
                      }
                    />
                  </Dropdown>
                </Tooltip>
              </div>
            </div>

            <Table responsive>
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email Address</th>
                  <th>Billing Role</th>
                  <th>Employment Status</th>
                </tr>
              </thead>

              <tbody>
                {providersList.map((item, i) => {
                  const fullname = `${capitalize(item.FirstName)} ${capitalize(
                    item.LastName
                  )}`;
                  const empStatus = `${!item.PartTime ? 'Full' : 'Part'} Time`;

                  return (
                    <Fragment key={i}>
                      <tr
                        className={!!item.Active ? '' : 'disabledEntity'}
                        onClick={() => {
                          handleShowModal('edit', {
                            FirstName: item.FirstName,
                            LastName: item.LastName,
                            EmailAddress: item.EmailAddress,
                            BillingRole: item.BillingRole,
                            Active: !!item.Active,
                            PartTime: !!item.PartTime,
                            isAdmin: item.Role === 'ga'
                          });
                        }}
                      >
                        <td>{fullname}</td>
                        <td>{item.EmailAddress}</td>
                        <td>{billingRole(item.BillingRole)}</td>
                        <td>{empStatus}</td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </Loading>

      <AdminForm
        showModal={showModal}
        formType={formType}
        initialValues={selectedAdmin}
        loadingForm={saving}
        handleClose={handleHideModal}
        handleSubmit={handleSubmitForm}
      />
    </div>
  );
};

export default GroupAdminsPage;
