import React, { Fragment, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Skeleton } from 'antd';
import { useSetRecoilState } from 'recoil';

import { toCamelCaseObjKeys } from '../../utils/object.utils';

import PDFCategoryList from '../../components/PDFCategoryList';
import AssignEducationalPDFDrawer from './AssignEducationalPDFDrawer';

import states from '../../states';
import services from '../../services';

const EducationalPDFs = ({ patient }) => {
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [data, setData] = useState(null);

  const setPdfState = useSetRecoilState(states.pdf);

  useEffect(() => {
    if (patient) {
      getAssignedPDFs();
    }
  }, []);

  useEffect(() => {
    if (showDrawer) {
      const mappedData = data?.PDFs?.map(pdf => ({
        ...toCamelCaseObjKeys(pdf),
      }));

      setPdfState(prevState => ({
        ...prevState,
        currSelected: [...(mappedData || [])],
      }));
    } else {
      setPdfState(prevState => ({
        ...prevState,
        currSelected: [],
      }));
    }
  }, [showDrawer]);

  const getAssignedPDFs = async () => {
    try {
      setLoading(true);
      const response = await services.educationalPdf.getByPatient(patient.Sub);

      if (response.status === 200 && response.data) {
        setData({
          ...response.data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <Fragment>
      <AssignEducationalPDFDrawer
        isOpen={showDrawer}
        handleClose={handleShowDrawer}
        data={data}
        patient={patient}
        successCallback={setData}
      />

      <div className="ptw-main-body" style={{ paddingTop: 22 }}>
        {loading ? (
          <Skeleton active size="small" />
        ) : (
          <Fragment>
            <div className="tab-header">
              <div />

              <Button
                type="primary"
                className="btn-primary ptw-btn"
                size="large"
                onClick={handleShowDrawer}
              >
                <PlusOutlined /> Assign Educational PDF
              </Button>
            </div>

            {data?.PDFs?.length === 0 ? (
              <Empty description="No assigned educational PDFs as of the moment." />
            ) : (
              <PDFCategoryList
                type="PATIENT_PROFILE"
                data={
                  data?.PDFs?.map(pdf => ({
                    ...toCamelCaseObjKeys(pdf),
                  })) || []
                }
              />
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default EducationalPDFs;
