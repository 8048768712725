import {
    GET_GROUPADMINS,
    GET_GROUPADMIN,
    SEARCH_GROUPADMIN,
    TOGGLE_ADD_GROUP_ADMIN,
    TOGGLE_EDIT_GROUP_ADMIN,
    SELECT_GROUP_ADMIN
} from '../actions'
import {
    RESET_GRID,
    DONE_LOADING,
    IS_LOADING
} from '../actions/gridState'
import { FULFILLED, PENDING, REJECTED } from 'redux-promise-middleware'
import _ from 'lodash'

const defaultState = {
    groupAdmins: [],
    isFetching: false,
    isSearching: false,
    isSubmitting: false,
    isLoadingGrid: true,
    showAddGroupAdmin: false,//toggle on first so that default toggles off
    showEditGroupAdmin: false,
    selectedGroupAdmin: {},
    hasZeroGroupAdmins: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case RESET_GRID:
            return {
                ...defaultState
            }
        case DONE_LOADING:        
            return {
                ...state,
                isLoadingGrid: false
            }
        case IS_LOADING:
            return {
                ...state,
                isLoadingGrid: true
            }
        case '@@router/LOCATION_CHANGE':
            //are we navigating away from /groups/{anything}
            //if so cleanup state after prompting 'are you sure' dialog
            //todo: reevaluate the need for this
            if (!/\/groups\/\w+/g.test(action.payload.pathname)) {
                return {
                    ...state,
                    showEditGroupAdmin: false,
                    showAddGroupAdmin: false,
                    selectedGroupAdmin: {}
                }
            } else {
                return {
                    ...state
                }
            }

        case SELECT_GROUP_ADMIN:
            return {
                ...state,
                selectedGroupAdmin: action.payload
            }

        case TOGGLE_EDIT_GROUP_ADMIN:
            return {
                ...state,
                showEditGroupAdmin: !state.showEditGroupAdmin
            }

        //add group admin modal
        case TOGGLE_ADD_GROUP_ADMIN:
            return {
                ...state,
                showAddGroupAdmin: !state.showAddGroupAdmin
            }

        //group admins
        case `${GET_GROUPADMINS}_${PENDING}`:
            return {
                ...state,
                isFetching: true
            }
        case `${GET_GROUPADMINS}_${FULFILLED}`:
            return {
                ...state,
                hasZeroGroupAdmins: action.payload.profiles.length === 0,
                isFetching: false,
                groupAdmins: _.sortBy(action.payload.profiles, ['lastName']),
                isSearching: false
                //todo: this should probably return a root node of 'users' instead of 'groups' for correctness
            }
        case `${GET_GROUPADMINS}_${REJECTED}`:
        return {
            ...state,
            isFetching: false
        }

        //search group admins
        case `${SEARCH_GROUPADMIN}_${PENDING}`:
            return {
                ...state,
                isFetching: true,
                isSearching: true
            }
        case `${SEARCH_GROUPADMIN}_${FULFILLED}`:
            const admins = {
                ...state,
                isFetching: false,
                isSearching: true
            };

            return action.payload.profiles ? {
                ...admins,
                groupAdmins: _.sortBy(action.payload.profiles, ['lastName'])
            } : admins;

        //group admin
        case `${GET_GROUPADMIN}_${PENDING}`:
            return {
                ...state,
                isFetching: true,
            }
        case `${GET_GROUPADMIN}_${FULFILLED}`:
            return {
                ...state,
                isFetching: false,
                selectedGroupAdmin: action.payload.profile
            }
        default:
            return state;
    }
}