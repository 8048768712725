import React from 'react';
import { Typography } from 'antd';
import { StarFilled } from '@ant-design/icons';

const NoFavorites = () => {
  return (
    <div className="mt-5">
      <Typography.Title level={2} className="text-center">
        Get Started With Favorites! <StarFilled />
      </Typography.Title>
      <Typography.Paragraph
        className="mt-3 text-center"
        style={{ maxWidth: 550, margin: 'auto' }}
      >
        Get easy access to your favorite exercises by adding them to your
        Favorites list. To add an exercise to your Favorites, scroll over the
        name of the exercise you want to favorite, select 'Preview' then select
        'Add to Favorites'!{' '}
        <Typography.Link
          target="_blank"
          href={'https://ptwired.zendesk.com/hc/en-us/articles/360049585654'}
        >
          See Tutorial Video.
        </Typography.Link>
      </Typography.Paragraph>
    </div>
  );
};

export default NoFavorites;
