import { atom } from 'recoil';

const defaultState = {
  list: [],
  currSelected: [],
};

const PDF_STATE = atom({
  key: 'PDF_STATE',
  default: defaultState,
});

export default PDF_STATE;
