import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRecoilState } from 'recoil';
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Input,
  Menu,
  notification,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import {
  FilterOutlined,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons';

import {
  filterData,
  searchData,
  formatCategory,
  sortData,
  categories
} from '../../utils/educationalPdf';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

import UploadPDFModal from './UploadPDFModal';
import PDFCategoryList from '../../components/PDFCategoryList';
import LoadingPage from '../../components/LoadingPage';
import NoResults from '../../components/NoResults';

import states from '../../states';
import services from '../../services';

const EducationalPDFs = ({ visibleProfile }) => {
  const [loading, setLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState([]);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [pdf, setPdf] = useRecoilState(states.pdf);

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    try {
      setLoading(true);

      const ptw = '2667b98c-4e98-4cdb-b03a-959ed7a7f435';
      const sub = visibleProfile.Sub;

      const [fromPTW, fromSub] = await Promise.all([
        services.educationalPdf.getByOwner(ptw),
        services.educationalPdf.getByOwner(sub)
      ]);

      if (fromPTW.status === 200 && fromSub.status === 200) {
        setPdf((prevState) => ({
          ...prevState,
          list: categories.map((cat) => ({
            category: cat,
            data: [...fromPTW.data, ...fromSub.data]
              .filter((item) => item.Category === cat)
              .map((item) => ({
                ...toCamelCaseObjKeys(item)
              }))
          }))
        }));
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while fetching Educational PDFs.'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSelectFilter = (e) => {
    setFilter((prevState) => {
      if (prevState.includes(e.target.name)) {
        return prevState.filter((item) => item !== e.target.name);
      }

      return [...prevState, e.target.name];
    });
  };

  const handleCloseFilter = (removedTag) => {
    setFilter(filter.filter((tag) => tag !== removedTag));
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  let filteredList = [];

  filteredList = filterData(pdf.list, filter);
  filteredList = searchData(filteredList, search);
  filteredList = sortData(filteredList);

  return (
    <Fragment>
      <UploadPDFModal showModal={showModal} onClose={handleShowModal} />

      <div className="ptw-main-body">
        <div className="tab-header">
          <Typography.Title level={2}>Educational PDFs</Typography.Title>

          <div className="tab-header-controls">
            <Button
              type="primary"
              className="btn-primary ptw-btn"
              onClick={handleShowModal}
            >
              <PlusOutlined /> Upload PDF
            </Button>

            <Input
              size="middle"
              placeholder="Search educational PDFs"
              prefix={<SearchOutlined />}
              value={search}
              onChange={handleSearch}
            />
            <Tooltip title="Filter Educational PDFs">
              <Dropdown
                overlay={
                  <Menu>
                    {categories.map((item, i) => (
                      <Menu.Item key={item}>
                        <Checkbox
                          name={item}
                          checked={filter.includes(item)}
                          onChange={handleSelectFilter}
                        >
                          {formatCategory(item)}
                        </Checkbox>
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                onOpenChange={(e) => setFilterOpen(e)}
                open={filterOpen}
                trigger={['click']}
                placement="bottomRight"
              >
                <Button className="btn-default" shape="circle">
                  <FilterOutlined style={{ fontSize: 16 }} />
                </Button>
              </Dropdown>
            </Tooltip>
          </div>
        </div>

        <Space direction="vertical" size={8} style={{ width: '100%' }}>
          {!!filter.length && (
            <div>
              <Space size={6}>
                <Typography.Text>Filtered by:</Typography.Text>
                <Space size={0}>
                  {filter.map((item) => (
                    <Tag
                      color="blue"
                      key={item}
                      closable
                      onClose={() => handleCloseFilter(item)}
                    >
                      {formatCategory(item)}
                    </Tag>
                  ))}
                </Space>
              </Space>
            </div>
          )}

          {!!search.length && (
            <Typography.Text>
              Results found for:{' '}
              <Typography.Text strong>{search}</Typography.Text>
            </Typography.Text>
          )}

          {loading ? (
            <LoadingPage type="list" content="Loading PDFs, please wait..." />
          ) : (
            <>
              {!!pdf.list.length ? (
                <Row gutter={[24, 24]}>
                  {filteredList &&
                    filteredList.map((item, i) => {
                      if (!item.data.length) {
                        return null;
                      }

                      return (
                        <Col key={i} span={24}>
                          <PDFCategoryList
                            category={item.category}
                            data={item.data}
                          />
                        </Col>
                      );
                    })}
                </Row>
              ) : (
                <NoResults content="Sorry, no results found." />
              )}
            </>
          )}
        </Space>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (states) => ({
  visibleProfile: states.visibleProfile
});

export default connect(mapStateToProps)(EducationalPDFs);
