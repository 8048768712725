import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { RightOutlined } from '@ant-design/icons';
import { setCurrentRx } from '../actions/patient';
import { countExercises } from './exerciseHelpers';

import ExercisesList from './ExercisesList';
import MobileVideo from '../../../features/mobile/Video';
import TextTranslation from '../../../I18n/TranslateText';

class RxList extends Component {
  constructor(props) {
    super();

    const videoOpen = props.location.search === '?video=true';

    this.state = {
      showVideo: videoOpen
    };
  }
  handleOpenRx = rxDetails => {
    const { dispatch } = this.props;
    dispatch(setCurrentRx(rxDetails));
  };

  rxPercentage = (completedExercises, exercises) => {
    const denominator = exercises.length;
    const percentage = (completedExercises / denominator) * 10000;

    return Math.ceil(Math.round(percentage || 0) / 100);
  };

  formatRxItem = (rxItem, i) => {
    return !rxItem.hasOwnProperty('Prescription')
      ? {
          Name: `Prescription No. ${i + 1}`,
          Prescription: { ...rxItem }
        }
      : rxItem;
  };

  render() {
    const {
      locale,
      currentRx,
      visibleProfile: {
        Sub,
        EmailAddress,
        PhoneNumber,
        Prescriptions,
        Role,
        Creator,
        FirstName,
        LastName
      },
      dispatch
    } = this.props;
    const { showVideo } = this.state;

    return (
      <Fragment>
        <MobileVideo
          dispatch={dispatch}
          locale={locale}
          patientContact={EmailAddress || PhoneNumber}
          patient={EmailAddress || PhoneNumber}
          ptFirstName={FirstName}
          ptLastName={LastName}
          role={Role}
          showVideo={showVideo}
          therapist={Creator}
          onHide={() => {
            this.setState({
              showVideo: false
            });
          }}
        />
        {currentRx ? (
          <ExercisesList currentRx={currentRx} />
        ) : (
          <Fragment>
            {Prescriptions &&
              Array.isArray(Prescriptions) &&
              Prescriptions.map((rxItem, i) =>
                this.formatRxItem(rxItem, i)
              ).map((rxItem, i) => {
                const {
                  Name,
                  Prescription: { exercises }
                } = rxItem;

                let completedExercise = countExercises(exercises);

                const percentage = this.rxPercentage(
                  completedExercise,
                  exercises
                );

                return (
                  <div
                    key={i}
                    className="rx-card"
                    onClick={() => this.handleOpenRx(rxItem)}
                  >
                    <div>
                      <h4>{Name}</h4>
                      <p>
                        {exercises.length}{' '}
                        <TextTranslation
                          currLocale={locale}
                          currText={'Exercises'}
                        />{' '}
                        &bull; {percentage}%{' '}
                        <TextTranslation
                          currLocale={locale}
                          currText={'Finished Today'}
                        />
                      </p>
                    </div>

                    <RightOutlined className="right-icon" />
                  </div>
                );
              })}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentLocation: state.routerReducer.location.pathname,
  visibleProfile: state.visibleProfile,
  currentRx: state.mobile.currentRx,
  locale: state.mobile.locale
});
export default withRouter(connect(mapStateToProps)(RxList));
