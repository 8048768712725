import React, { Fragment } from 'react';
import { Col, Row, Space, Typography } from 'antd';

const Tooltip = ({ type }) => {
  switch (type) {
    case 'title':
      return (
        <Space direction="vertical">
          <div className="header-tooltip">
            <Typography.Text strong>Messaging</Typography.Text>

            <a
              className={'pull-right btn-link'}
              href={'https://vimeo.com/363335876/d05c66414bc'}
              target={'_blank'}
              rel="noreferrer"
            >
              See Tutorial Video
            </a>
          </div>

          <Typography.Text>
            Send your patients secure messages through the Messages Dashboard.
            Start <br />a conversation with an existing Patient by selecting the
            + button and
            <br />
            adding the Patient to the Dashboard. We'll send you an email each
            time <br />
            you have a new message from a Patient. To disable Messages, go to
            the My <br />
            Profile page.
          </Typography.Text>
        </Space>
      );

    case 'add':
      return (
        <Fragment>
          <Row>
            <Col sm={24}>
              <strong>Create New Conversation</strong>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              Browse patients and start a new conversation with one!
            </Col>
          </Row>
        </Fragment>
      );

    case 'reload':
      return (
        <Fragment>
          <Row>
            <Col sm={24}>
              <strong>Refresh Conversation List</strong>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              Refresh to get your latest conversations with patients.
            </Col>
          </Row>
        </Fragment>
      );

    default:
      return null;
  }
};

export default Tooltip;
