import { atom } from 'recoil';

const defaultState = {
  exerciseDetails: false,
  writeInExercise: false,
  addTemplate: false,
};

const MODAL_STATE = atom({
  key: 'MODAL_STATE',
  default: defaultState,
});

export default MODAL_STATE;
