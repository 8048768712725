import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

const headingContainer = {
  borderBottom: '.2rem solid #ececec',
  marginBottom: '15px',
};

const Profile = ({ profile }) => (
  <div className="ptw-panel-margin">
    <Row>
      <Col xs={12} md={10}>
        <dl>
          <div style={headingContainer}>
            <h4>Full Name</h4>
            <dd>
              {profile.FirstName} {profile.LastName}
            </dd>
          </div>
          <div style={headingContainer}>
            <h4>Email</h4>
            <dd>{profile.EmailAddress}</dd>
          </div>
        </dl>

        {/*<Link to="/change-password">Change Password</Link>*/}
      </Col>
    </Row>
  </div>
);

Profile.propTypes = {
  profile: PropTypes.shape({
    FirstName: PropTypes.string.isRequired,
    LastName: PropTypes.string.isRequired,
  }),
};

export default Profile;
