import {
  REQUEST_AUTHENTICATION,
  REQUEST_DEAUTHENTICATION,
  GET_PROFILE,
  GET_ROLE,
  GET_APPSTORE_LINKS,
  LOGIN_SUCCESS,
  CLEAR_DATA
} from '../actions';

import {
  IS_CURRENTLY_VIEWING_GROUPS,
  IS_CURRENTLY_ADDING_GROUP,
  IS_CURRENTLY_EDITING_GROUP
} from '../../groups/actions/groups';

import {
  IS_CURRENTLY_VIEWING_GROUP_ADMINS,
  IS_CURRENTLY_ADDING_GROUP_ADMIN,
  IS_CURRENTLY_EDITING_GROUP_ADMIN
} from '../../groups/group-admin/actions';

import { FULFILLED, REJECTED, PENDING } from 'redux-promise-middleware';

const handleLoginFulfilled = ({
  accessToken,
  refreshToken,
  token,
  username,
  role,
  sub
}) => {
  return {
    IsAuthenticated: true,
    token: token,
    isCurrently: 'authenticated',
    username: username,
    role: role,
    accessToken: accessToken,
    refreshToken: refreshToken,
    sub: sub
  };
};

const handleLoginRejected = (state, { payload }) => {
  return {
    hasSubmitted: true,
    hasError: true,
    isLocked: payload === 'Locked',
    notAuthorized: payload === 'Not Authorized'
  };
};

const handleUserCurrentActivity = (state, action) => {
  let activity = state.isCurrently.activity;
  let isNewGroup = state.isCurrently.isNewGroup;

  switch (action.type) {
    case IS_CURRENTLY_EDITING_GROUP_ADMIN:
      activity = `editing_group_admin`;
      break;
    case IS_CURRENTLY_ADDING_GROUP_ADMIN:
      activity = 'adding_group_admin';
      break;
    case IS_CURRENTLY_VIEWING_GROUP_ADMINS:
      activity = 'viewing_group_admins';
      break;
    case IS_CURRENTLY_VIEWING_GROUPS:
      activity = 'viewing_groups';
      isNewGroup = false;
      break;
    case IS_CURRENTLY_ADDING_GROUP:
      activity = 'adding_group';
      isNewGroup = true;
      break;
    case IS_CURRENTLY_EDITING_GROUP:
      activity = 'editing_group';
      isNewGroup = false;
      break;
    default:
  }

  return {
    ...state,
    isCurrently: { activity, isNewGroup }
  };
};

//pull startup state out of local storage and test expiration
export const currentUser = (state = {}, action, ...props) => {
  switch (action.type) {
    case IS_CURRENTLY_EDITING_GROUP_ADMIN:
    case IS_CURRENTLY_ADDING_GROUP_ADMIN:
    case IS_CURRENTLY_VIEWING_GROUP_ADMINS:
    case IS_CURRENTLY_VIEWING_GROUPS:
    case IS_CURRENTLY_ADDING_GROUP:
    case IS_CURRENTLY_EDITING_GROUP:
      return {
        ...handleUserCurrentActivity(state, action)
      };

    case `${REQUEST_AUTHENTICATION}_${PENDING}`:
      return {
        ...state,
        submitting: true
      };

    case `${REQUEST_AUTHENTICATION}_${FULFILLED}`:
      return {
        ...handleLoginFulfilled(action.payload),
        submitting: false
      };
    case `${LOGIN_SUCCESS}`:
      return {
        ...handleLoginFulfilled(action.payload),
        submitting: false
      };
    case `${REQUEST_AUTHENTICATION}_${REJECTED}`:
      return {
        ...handleLoginRejected(state, action),
        submitting: false
      };
    case REQUEST_DEAUTHENTICATION:
      localStorage.clear();
      return {
        IsAuthenticated: false,
        token: null,
        hasLoggedOut: true
      };
    case `${GET_PROFILE}_${FULFILLED}`:
      return {
        ...state,
        ...action.payload.profile
      };
    case `${GET_ROLE}_${FULFILLED}`:
      return {
        ...state
      };

    case `${GET_APPSTORE_LINKS}_${FULFILLED}`:
      return {
        ...state
      };
    case `${CLEAR_DATA}`:
      return {};
    default:
      return state;
  }
};
