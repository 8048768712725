import React, { useState } from 'react';
import { Avatar, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const ProviderAvatar = ({ url, name }) => {
  const [imgError, setImgError] = useState(false);

  return (
    <div style={{ width: 210 }}>
      <div className="brand-logo">
        {imgError ? (
          <Avatar size={80} icon={<UserOutlined />} />
        ) : (
          <img
            src={url}
            height={200}
            alt="therapist"
            className="img-responsive center-block"
            style={{ borderRadius: 100 }}
            onError={() => setImgError(true)}
          />
        )}
      </div>

      <Typography.Paragraph
        type="secondary"
        style={{ textAlign: 'center', marginTop: -8 }}
      >
        {name}
      </Typography.Paragraph>
    </div>
  );
};

export default ProviderAvatar;
