import { atom } from 'recoil';

const defaultState = {
  custom: {
    exercises: [],
    parameter: []
  },
  unmapped: [],
  list: [],
  details: null
};

const EXERCISES_STATE = atom({
  key: 'EXERCISES_STATE',
  default: defaultState
});

export default EXERCISES_STATE;
