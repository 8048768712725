import {
  minLength,
  maxLength,
  email,
  alphaNumeric,
  alphaNumericAndSpecial,
  compoundValidator,
  phone
} from './generic'
import { getGroup } from "../actions/groups";
import voca from 'voca'

const minLength1 = minLength(1)
const maxLength254 = maxLength(254)
const maxLength128 = maxLength(128)
const minLength5 = minLength(5)

export const groupNameValidator = compoundValidator([maxLength254, alphaNumericAndSpecial], 'Invalid group name')
export const addressValidator = compoundValidator([maxLength254, minLength1, alphaNumericAndSpecial], 'Invalid address')
export const cityValidator = compoundValidator([maxLength128, minLength1, alphaNumeric], 'Invalid city')
export const contactNameValidator = compoundValidator([maxLength254, minLength1, alphaNumericAndSpecial], 'Invalid contact name')
export const contactEmailValidator = compoundValidator([email], 'Invalid contact email')
export const zipValidator = compoundValidator([minLength5], 'Invalid zip')
export const phoneValidator = compoundValidator([phone], 'Invalid phone number')

export const asyncValidate = (values, dispatch, props) => {
  return new Promise((resolve, reject) => {
    if(values.name && values.name.trim() !== props.initialValues.name){
      dispatch(getGroup(voca.lowerCase(values.name))).then(({value}) => {
        if(value.message === "Success"){
          reject({name: "Group name must be unique"})
        }else{
          resolve()
        }
      }).catch(errorResponse => {
        if (errorResponse.status === 404) {
          resolve()
        } else {
          reject({ name: "Group name must be unique" })
        }
      })
    } else {
      resolve()
    }
  })
}
