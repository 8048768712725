import { createAction } from "redux-actions";

export const SIDEBAR_OPEN = 'SIDEBAR_OPEN';
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE';

export const setSidebar = createAction((bool)=>{
    if(bool)
      return SIDEBAR_OPEN;
    else
    return SIDEBAR_CLOSE;
  }
);


export default setSidebar;
