import React, { Component } from 'react'
import {
    FormGroup,
    FormControl
} from 'react-bootstrap'
import * as ReactDOM from "react-dom";

const inputTypes = [
    window.HTMLInputElement,
    window.HTMLSelectElement,
    window.HTMLTextAreaElement,
];

const triggerInputChange = (node, value = '') => {
    if ( inputTypes.indexOf(node.__proto__.constructor) >-1 ) {
        const setValue = Object.getOwnPropertyDescriptor(node.__proto__, 'value').set;
        const event = new Event('input', { bubbles: true });

        setValue.call(node, value);
        return node.dispatchEvent(event);
    }
    return null;
};

class SearchBox extends Component {
    constructor(props){
        super(props)
        this.handleInputClear = this.handleInputClear.bind(this)
    }

    handleInputClear(){
        const response = triggerInputChange(ReactDOM.findDOMNode(this.input), '');

        if(!!response && !!this.props.onClear){
            this.props.onClear()
        }
    }

    render(){
        const { onKeyUp, placeholder} = this.props
        return (
            <FormGroup className="input-group ptw-search">
                <span className="input-group-addon"><i className="fa fa-search"/></span>
                <FormControl
                    type="text"
                    placeholder={placeholder}
                    onKeyUp={onKeyUp}
                    inputRef={ref => this.input = ref}
                />

                {this.input && this.input.value &&
                <span className="input-group-btn">
          <button className="btn btn-default" type="button" onClick={this.handleInputClear}><i className="fa fa-times-circle fa-lg"/></button>
        </span>
                }
            </FormGroup>
        );
    }
}

export default SearchBox;

