import React from 'react';
import DropdownNavigation from '../../components/DropdownNavigation';
import EducationalPDFLibraryPage from './EducationalPDFLibraryPage';

const EducationalPDFLibrary = () => {
  return (
    <React.Fragment>
      <DropdownNavigation />
      <div className="ptw-main-body">
        <EducationalPDFLibraryPage />
      </div>
    </React.Fragment>
  );
};

export default EducationalPDFLibrary;
