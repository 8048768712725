import React from 'react';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { requestDeauth } from '../features/membership/actions';
import { bindActionCreators } from 'redux';
import { actions as idleActions } from '../features/idle-monitor';
import { Typography } from 'antd';
import setNotification from '../utils/setNotification.utils';

import { useResetRecoilState } from 'recoil';
import states from '../states';

export const logoff = ({ visit, requestDeauth, stopIdleTimer }) => {
  visit('/');
  requestDeauth();
  stopIdleTimer();
};

const DispatchLogoutItem = connect(null, (dispatch) =>
  bindActionCreators(
    {
      visit: push,
      requestDeauth,
      stopIdleTimer: idleActions.stop
    },
    dispatch
  )
)((config) => {
  const resetAdmin = useResetRecoilState(states.admin);
  const resetAuth = useResetRecoilState(states.authentication);
  const resetCarePlan = useResetRecoilState(states.careplan);
  const resetExercises = useResetRecoilState(states.exercises);
  const resetFavorites = useResetRecoilState(states.favorites);
  const resetFilters = useResetRecoilState(states.filters);
  const resetGroups = useResetRecoilState(states.groups);
  const resetMsgs = useResetRecoilState(states.messages);
  const resetModal = useResetRecoilState(states.modal);
  const resetNPS = useResetRecoilState(states.nps);
  const resetParams = useResetRecoilState(states.params);
  const resetPatients = useResetRecoilState(states.patients);
  const resetPdf = useResetRecoilState(states.pdf);
  const resetReports = useResetRecoilState(states.reports);
  const resetRx = useResetRecoilState(states.prescription);
  const resetRTM = useResetRecoilState(states.rtm);
  const resetTemplates = useResetRecoilState(states.templates);
  const resetUser = useResetRecoilState(states.user);

  return (
    <React.Fragment>
      <Link
        to="/#"
        onClick={(e) => {
          e.preventDefault();

          resetAdmin();
          resetAuth();
          resetCarePlan();
          resetExercises();
          resetFavorites();
          resetFilters();
          resetGroups();
          resetMsgs();
          resetNPS();
          resetModal();
          resetParams();
          resetPatients();
          resetPdf();
          resetReports();
          resetRx();
          resetRTM();
          resetTemplates();
          resetUser();

          logoff(config);
          setNotification(
            'success',
            'Success!',
            'You have been logged out.',
            2
          );
        }}
      >
        <Typography.Text>Log Out</Typography.Text>
      </Link>
    </React.Fragment>
  );
});

export default DispatchLogoutItem;
