import React, { Fragment, useState } from 'react';
import { Button, Col, Row, Space, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import {
  formatCategory,
  getNumOfPages,
  paginateData,
} from '../../utils/educationalPdf';

import PDFViewerDrawer from './PDFViewerDrawer';
import PDFCard from './PDFCard';
import AssignPDFCard from './AssignPDFCard';

const PDFCategoryList = ({
  type = 'DEFAULT',
  handleSelectPDF,
  category,
  data,
}) => {
  const [page, setPage] = useState(1);
  const [currPDF, setCurrPDF] = useState({
    isOpen: false,
    pdfData: null,
  });

  const handleNextPage = () => {
    if (page === getNumOfPages(data)) {
      setPage(1);
    } else {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page === 1) {
      setPage(getNumOfPages(data));
    } else {
      setPage(page - 1);
    }
  };

  const handlePreviewPDF = item => {
    setCurrPDF({ isOpen: true, pdfData: item });
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <PDFViewerDrawer
        pdfData={currPDF.pdfData}
        isOpen={currPDF.isOpen}
        handleClose={() =>
          setCurrPDF({
            isOpen: false,
            pdfData: null,
          })
        }
      />
      <div className="pdf-cat-container">
        <div className="pdf-cat-header">
          <Typography.Title level={4}>
            {type === 'PATIENT_PROFILE'
              ? 'Assigned PDFs'
              : formatCategory(category)}{' '}
            ({data.length})
          </Typography.Title>

          <Space size={4}>
            <Button
              icon={<LeftOutlined />}
              shape="circle"
              className="btn-default"
              disabled={getNumOfPages(data) === 1}
              onClick={handlePrevPage}
            />
            <Button
              icon={<RightOutlined />}
              shape="circle"
              className="btn-default"
              disabled={getNumOfPages(data) === 1}
              onClick={handleNextPage}
            />
          </Space>
        </div>

        <Row gutter={[16, 16]} className="pdf-cat-list">
          {data &&
            paginateData(data, page).map((item, i) => {
              return (
                <Col
                  xl={6}
                  lg={6}
                  md={12}
                  sm={24}
                  xs={24}
                  key={i}
                  style={{ display: 'flex' }}
                >
                  {type === 'ASSIGN_PDF' ? (
                    <AssignPDFCard
                      item={item}
                      onPreview={handlePreviewPDF}
                      onSelect={handleSelectPDF}
                    />
                  ) : (
                    <PDFCard item={item} onPreview={handlePreviewPDF} />
                  )}
                </Col>
              );
            })}
        </Row>

        <Typography.Text
          type="secondary"
          className="pdf-cat-page-text pull-right"
        >
          {page}/{getNumOfPages(data)}
        </Typography.Text>
      </div>
    </Fragment>
  );
};

export default PDFCategoryList;
