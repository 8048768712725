import React, { Component } from 'react';
import { Form, Field, reduxForm } from 'redux-form';
import { Row, Col, FormControl, Button } from 'react-bootstrap';
import FormInput from '../../groups/Input';
import {
  setOnMessage,
  socketMessageSend,
} from '../../../DispatchSocket/WebSocketAction';
import {
  GET_GROUP_DATA,
  REQUEST_APPOINTMENT,
} from '../../../DispatchSocket/socketEvents';
import { connect } from 'react-redux';
import v from 'voca';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const { titleCase } = v;
const ERROR = 'Internal server error';

const dropDownStyle = {
  backgroundColor: 'white',
  color: '#666',
  width: '100%',
  padding: 8,
  border: '1px solid #ccc',
  borderRadius: 6,
};

class RequestAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasInitiated: false,
      pts: [],
      groupClinics: [],
      times: ['Morning', 'Afternoon', 'Evening'],
      requested: false,
      loading: true,
      error: true,
    };
    this.onMessage = this.onMessage.bind(this);
  }

  componentWillUnmount() {
    const {
      props: { dispatch },
    } = this;
    dispatch(setOnMessage(null));
  }

  componentDidMount() {
    const {
      props: {
        dispatch,
        socket: { websocket },
      },
      state: { hasInitiated },
      onMessage,
      generateGetProviderInfoPayload,
    } = this;

    if (!hasInitiated && websocket) {
      this.setState({ hasInitiated: true, loading: false });
      try {
        dispatch(setOnMessage(onMessage));
        dispatch(socketMessageSend(generateGetProviderInfoPayload()));
      } catch (err) {
        console.log('Err setting on message');
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      props: {
        dispatch,
        socket: { websocket },
      },
      state: { hasInitiated },
      onMessage,
      generateGetProviderInfoPayload,
    } = this;

    if (!hasInitiated && websocket) {
      this.setState({ hasInitiated: true, loading: false });
      dispatch(setOnMessage(onMessage));
      dispatch(socketMessageSend(generateGetProviderInfoPayload()));
    }
  }
  generateGetProviderInfoPayload = () => {
    const {
      props: { visibleProfile },
    } = this;
    return {
      User: visibleProfile.EmailAddress,
      Role: 'p',
      Action: GET_GROUP_DATA,
      GroupId: visibleProfile.GroupId,
    };
  };

  generateRequestAppointmentPayload = (input) => {
    const {
      props: { visibleProfile },
    } = this;
    return {
      User: visibleProfile.EmailAddress,
      Role: 'p',
      Action: REQUEST_APPOINTMENT,
      GroupId: visibleProfile.GroupId,
      AppointmentObject: input,
    };
  };

  onMessage = function (incoming) {
    const eventData = JSON.parse(incoming.data);
    const {
      event,
      message,
      data: {
        PTs,
        Clinics: { Items: clinics },
      },
    } = eventData;

    if (message === ERROR) {
      console.log(ERROR, eventData);
      this.setState({ error: true, loading: false });
      return;
    }

    if (event === GET_GROUP_DATA) {
      let pts = [];
      for (let i = 0; i < PTs.length; i++) {
        pts = pts.concat(PTs[i].Items);
      }

      this.setState({ pts: pts, groupClinics: clinics, loading: false });
    }
  };

  submitAppointmentRequest = (e) => {
    e.preventDefault();
    const {
      props: {
        dispatch,
        visibleProfile: { FirstName, LastName },
      },
      generateRequestAppointmentPayload,
    } = this;

    const ptInput = document.getElementById('pt-input');
    const locationInput = document.getElementById('location-input');
    const timeInput = document.getElementById('time-input');
    const dateInput = document.getElementById('date-input');
    const commentsInput = document.getElementById('comments-input');

    const clinic =
      locationInput && locationInput.value
        ? locationInput.value.split(',')
        : 'Any';

    const inputObj = {
      PhysicalTherapist: titleCase(ptInput.value),
      FirstName: titleCase(FirstName),
      LastName: titleCase(LastName),
      Clinic: clinic[0],
      ClinicName: clinic[1],
      Time: timeInput.value,
      Date: dateInput.value,
      Comments: commentsInput.value,
    };

    dispatch(socketMessageSend(generateRequestAppointmentPayload(inputObj)));
    this.setState({ requested: true });
  };

  filterPts = (pts) => {
    for (let i = 0; i < pts.length; i++) {
      if (!pts[i]) {
        continue;
      }

      if (pts[i].Active === 'false' || pts[i].Active === false) {
        pts[i] = null;
      }

      if (pts[i] && pts[i].BillingRole !== 'PT') {
        pts[i] = null;
      }
    }

    return pts;
  };

  render() {
    const {
      state: { groupClinics, times, pts, requested, loading },
      filterPts,
    } = this;

    const filteredPts = filterPts(pts);

    return (
      <div
        className="panel-body ptw-panel"
        style={{
          backgroundColor: 'white',
          padding: '10px',
          marginBottom: '120px',
          borderRadius: '30px',
        }}
      >
        {loading ? (
          <div className="loader">Loading...</div>
        ) : requested ? (
          <div className="ptw-main-body" style={{ textAlign: 'center' }}>
            <FontAwesomeIcon
              icon={faCalendarCheck}
              style={{ color: '#5cb85c', fontSize: '120px' }}
            />
            <h1>Appointment Requested</h1>
            <p>
              Someone from our team will reach out to you soon to confirm your
              appointment.
            </p>
            <p>We'll see you soon!</p>
          </div>
        ) : (
          <div className="ptw-main-body">
            <h1>Request an Appointment</h1>
            <Form onSubmit={this.submitAppointmentRequest}>
              <Row>
                <Col xs={12} md={6}>
                  <Field
                    name="pt"
                    label="Preferred PT"
                    component={FormInput}
                    type="select"
                    id={'pt-input'}
                  >
                    <option value={'Any'}>Any</option>
                    {filteredPts.map((pt) => {
                      if (pt === null) {
                        return null;
                      }
                      return (
                        <option
                          value={`${pt.FirstName} ${pt.LastName}`}
                          key={pt.EmailAddress}
                        >
                          {titleCase(pt.FirstName)} {titleCase(pt.LastName)}
                        </option>
                      );
                    })}
                  </Field>
                </Col>
              </Row>
              {groupClinics.length > 0 && (
                <Row>
                  <Col xs={12} md={6}>
                    <Field
                      name="location"
                      label="Preferred Location"
                      component={FormInput}
                      type="select"
                      id={'location-input'}
                    >
                      {groupClinics.map((clinic) => {
                        return (
                          <option
                            value={[clinic.Id, clinic.ClinicName]}
                            key={clinic.ClinicName}
                          >
                            {clinic.ClinicName}
                          </option>
                        );
                      })}
                    </Field>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12} md={6}>
                  <Field
                    name="time"
                    label="Preferred Time"
                    component={FormInput}
                    type="select"
                    id={'time-input'}
                  >
                    <option value={'Any'}>Any</option>
                    {times.map((time) => {
                      return (
                        <option value={time} key={time}>
                          {time}
                        </option>
                      );
                    })}
                  </Field>
                </Col>
              </Row>
              <Row>
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Choose a Date</label>
              </Row>
              <Row>
                <Col xs={12}>
                  <input type="date" style={dropDownStyle} id={'date-input'} />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  Additional Comments
                  <FormControl
                    componentClass="textarea"
                    rows="10"
                    onChange={this.handleTextChange}
                    id={'comments-input'}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12}>
                  <Button block type={'submit'}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    visibleProfile: state.visibleProfile,
    publicPage: state.publicPage,
  };
};

export default reduxForm({
  form: 'request_appointment',
})(connect(mapStateToProps)(RequestAppointment));
