import React, { Fragment } from 'react';
import { Form, Input, Button, Row, Col, Modal } from 'antd';
import { LoadingOutlined, QuestionCircleFilled } from '@ant-design/icons';

const { confirm } = Modal;

const PushNotificationsForm = props => {
  const {
    pushNotifAnnouncement,
    setPushNotifAnnouncement,
    handleSubmitCustomization,
    isLoading,
  } = props;

  const showConfirm = () => {
    confirm({
      title: 'Are you sure?',
      icon: <QuestionCircleFilled />,
      content: 'All patients will receive this push notification after this.',
      okText: 'Send Now',
      onOk: () => {
        handleSubmitCustomization();
      },
    });
  };

  const resetForm = () => {
    setPushNotifAnnouncement({
      title: '',
      message: '',
      silent: false,
    });
  };

  return (
    <Row gutter={[24, 24]}>
      <Col xl={4} lg={3} md={0} sm={0} xs={0} />
      <Col xl={16} lg={18} md={24} sm={24} xs={24}>
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={showConfirm}
          onReset={resetForm}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Title is required.' }]}
          >
            <Input
              defaultValue={pushNotifAnnouncement.title}
              onChange={e =>
                setPushNotifAnnouncement({
                  ...pushNotifAnnouncement,
                  title: e.target.value,
                })
              }
              value={pushNotifAnnouncement.title}
            />
          </Form.Item>
          <Form.Item
            label="Message"
            name="message"
            rules={[{ required: true, message: 'Message is required.' }]}
          >
            <Input.TextArea
              defaultValue={pushNotifAnnouncement.message}
              rows={3}
              onChange={e =>
                setPushNotifAnnouncement({
                  ...pushNotifAnnouncement,
                  message: e.target.value,
                })
              }
              showCount
              maxLength={200}
              value={pushNotifAnnouncement.message}
            />
          </Form.Item>
          {/* <Form.Item label="Silent" name="silent">
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={checked =>
                setPushNotifAnnouncement({
                  ...pushNotifAnnouncement,
                  silent: checked
                })
              }
            />
          </Form.Item> */}
          <Form.Item>
            <div style={{ marginTop: 12 }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isLoading}
                size="large"
              >
                {isLoading && (
                  <Fragment>
                    <LoadingOutlined className="text-icon" />
                    &nbsp;
                  </Fragment>
                )}
                Send Push Notification
              </Button>
              &emsp;
              <Button htmlType="reset" size="large">
                Clear
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Col>
      <Col xl={4} lg={3} md={0} sm={0} xs={0} />
    </Row>
  );
};

export default PushNotificationsForm;
