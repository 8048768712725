import React from 'react';
import { connect } from 'react-redux';
import AnnouncementsPage from './AnnouncementsPage';
import DropdownNavigation from '../../components/DropdownNavigation';

const Announcements = (props) => {
  return (
    <React.Fragment>
      <DropdownNavigation />
      <div className="ptw-main-body">
        <AnnouncementsPage {...props} />
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    groupId: state.visibleProfile.GroupId,
    emailAddress: state.visibleProfile.EmailAddress,
  };
}

export default connect(mapStateToProps)(Announcements);
