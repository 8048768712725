import m from 'moment';

export const exersiceProperties = ['sets', 'reps', 'hold', 'frequency'];

export const sidebarOptions = [
  {
    title: 'Exercises',
    icon: 'fa-check-square-o fa-lg',
    url: 'exercises',
  },
  {
    title: 'Progress',
    icon: 'fa-pie-chart fa-lg',
    url: 'progress',
  },
  {
    title: 'Achievements',
    icon: 'fa-trophy fa-lg',
    url: 'achievements',
  },
  {
    title: 'Logout',
    icon: 'fa-power-off fa-lg',
  },
];

export const greeting = () =>
  m().format('HH:mm') <= '11:59' ? 'morning' : 'afternoon';
