import * as mappers from "./mappers"

Object.keys(mappers).forEach(k1 => {
  const backwardMapper = {}
  const mapper = mappers[k1]

//  Object.keys(mapper).forEach(k2 => backwardMapper[mapper[k2]] = k2)
  Object.keys(mapper).forEach(k2 => {
    if(typeof mapper[k2] === "object"){
      backwardMapper[mapper[k2].key] = {
        key: k2,
        transform: mapper[k2].transform
      }
    }else{
      backwardMapper[mapper[k2]] = k2
    }
  })
  mappers[`${k1}Backward`] = backwardMapper
})

//todo: if some fields are not defined in the map object, then include them however they are
export default function (modelName, object, forward = true){
  const obj = {...object};
  const result = {}
  const mapper = forward ? mappers[modelName] : mappers[`${modelName}Backward`]
  Object.keys(mapper).forEach(k => {
    if(typeof(mapper[k]) === "object"){
      result[mapper[k].key] = mapper[k].transform(obj[k], forward)
    }else{
      result[mapper[k]] = obj[k]
    }
    delete obj[k]
  })
  return {...obj, ...result}
}