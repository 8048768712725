import { createAction } from 'redux-actions';
import {API_REQUEST} from "../membership/middleware";

export const GET_INTEGRATION_CODE = 'GET_INTEGRATION_CODE';

export const getIntegrationCode = (groupId)=>({
    [API_REQUEST]: createAction(GET_INTEGRATION_CODE)({
        resource: `integration`,
        method: 'GET',
        query: `groupId=${groupId}`
    })
});
