import apiFetch from '../utils/fetch.utils';

export const addProfile = async (payload) => {
  try {
    const uri = `groups/${payload.GroupId}/therapists`;
    const method = 'POST';

    return apiFetch(uri, method, {
      body: payload
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateProfile = async (payload) => {
  try {
    const uri = `groups/${payload.GroupId}/therapists`;
    const method = 'PUT';

    return apiFetch(uri, method, {
      body: payload
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};
