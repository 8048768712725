import { createAction } from 'redux-actions';
import { API_REQUEST } from '../membership/middleware';

export const START_LOADING_MONITORED_PATIENT =
  'START_LOADING_MONITORED_PATIENT';
export const startLoadingMonitoredPatient = createAction(
  START_LOADING_MONITORED_PATIENT
);

export const END_LOADING_MONITORED_PATIENT = 'END_LOADING_MONITORED_PATIENT';
export const endLoadingMonitoredPatient = createAction(
  END_LOADING_MONITORED_PATIENT
);

export const SET_IS_MONITORING = 'SET_IS_MONITORING';
export const setIsMonitoring = createAction(
  SET_IS_MONITORING,
  payload => payload
);

export const SET_CURRENT_MONITORED_PATIENT = 'SET_CURRENT_MONITORED_PATIENT';
export const setCurrentMonitoredPatient = createAction(
  SET_CURRENT_MONITORED_PATIENT,
  payload => payload
);

export const EXIT_CURRENT_MONITORED_PATIENT = 'EXIT_CURRENT_MONITORED_PATIENT';
export const exitCurrentMonitoredPatient = createAction(
  EXIT_CURRENT_MONITORED_PATIENT
);

export const CLEAR_CURRENT_MONITORED_PATIENT =
  'CLEAR_CURRENT_MONITORED_PATIENT';
export const clearCurrentMonitoredPatient = createAction(
  CLEAR_CURRENT_MONITORED_PATIENT
);

export const SET_TIMER_EVENT = 'SET_TIMER_EVENT';
export const setTimerEvent = (
  groupName,
  emailAddress,
  groupId,
  sub,
  duration,
  activity,
  provider
) => ({
  [API_REQUEST]: createAction(SET_TIMER_EVENT)({
    resource: `groups/${groupName}/rtm/timer`,
    method: 'POST',
    body: {
      emailAddress,
      groupId,
      sub,
      duration,
      activity,
      provider
    }
  })
});

export const GET_RECENT_MONITORED_LOGS = 'GET_RECENT_MONITORED_LOGS';
export const getRecentMonitoredLogs = (groupName, sub, groupId, query) => ({
  [API_REQUEST]: createAction(GET_RECENT_MONITORED_LOGS)({
    resource: `groups/${groupName}/rtm/cliniclogs?sub=${sub}&groupId=${groupId}&query=${encodeURIComponent(
      JSON.stringify(query)
    )}&offset=${new Date().getTimezoneOffset()}`,
    method: 'GET'
  })
});

export const CLEAR_RECENT_MONITORED_LOGS = 'CLEAR_RECENT_MONITORED_LOGS';
export const clearRecentMonitoredLogs = createAction(
  CLEAR_RECENT_MONITORED_LOGS
);
