const lookupList = [
  {
    JobTitle: 'AWS Massage Therapist',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'AWS Physical Therapist',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'AWS Training and Development Specialist',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Clinic Director',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Clinic Director - MSA',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Clinic Director - Multisite',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Clinic Director - Telerehab',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Clinic Lead I - AWS',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Clinic Lead II - AWS',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Clinic Lead III - AWS',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Clinic Provider I - AWS',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Clinic Provider II - AWS',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Clinic Provider III - AWS',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Clinical Case Mgr',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Dir Health Services',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Dir Organizational Development AWS',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Dir Sports Medicine',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'District Director',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'District Director II',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Early Intervention Specialist',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Ergonomic Consultant',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Ergonomic Specialist',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'FIRST Program Director',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Head Athletic Trainer',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Head Athletic Trainer - 10 Month',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Health and Wellness Specialist',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Industry Dir ATI Worksite Solutions',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Industry Mgr ATI Worksite Solutions',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Lead Clinician',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Lead Operations Support Specialist',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Medical Assistant',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'MSA Authorization Specialist',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'MSA Office Coordinator',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Nurse Practitioner',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Occupational Therapist',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Occupational Therapist - Peds',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Occupational Therapist Floater',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Occupational Therapy Asst',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Office Coordinator',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Office Specialist',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Operations Coordinator',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Operations Mgr - Health Services',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Operations Support Specialist I',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Operations Support Specialist II',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Operations Support Specialist III',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Operations Support Specialist Pending License',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Operations Support Specialist Pending License - Explore ATI',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'OSS I - PT Tuition Support Program',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'OSS II - PTA Tuition Support Program',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'OSS III - Clinical',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'OSS III - PTA Tuition Support Program',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Outreach ATC - 10 Month',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Outreach Athletic Trainer',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Physical Therapist',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Physical Therapist - Explore ATI',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Physical Therapist - Floater',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Physical Therapist - Passport',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Physical Therapist - Residency Site Coordinator',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Physical Therapist - Resident',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Physical Therapist Assistant',
    BillingRole: 'Provider Assistant'
  },
  {
    JobTitle: 'Physical Therapist Asst Pending License',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Physical Therapist Ast - Float',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Physical Therapist Pending License',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Physical Therapist Pending License - Passport',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Physician Assistant',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Program Services Manager - AWS',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Regional Hand Director',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Regional Vice President',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Registered Dietitian/Nutrition',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Sports Medicine Mgr',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Sr Analyst Health Services',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Student Physical Therapist',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'SVP Health Services',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Telerehab Physical Therapist',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Telerehab Physical Therapist Assistant',
    BillingRole: 'Provider Assistant'
  },
  {
    JobTitle: 'Virtual Care Navigator',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'Virtual Operations Director',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Virtual Patient Coordinator',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'VP Product Operations',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Work Capacity Coordinator',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Work Comp Specialist',
    BillingRole: 'Technician'
  },
  {
    JobTitle: 'WWE Medical Affairs Manager',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'WWE Medical Liaison',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'WWE Physical Therapist',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'WWE Strength and Conditioning Coach',
    BillingRole: 'Provider'
  },
  {
    JobTitle: 'Xray Technician',
    BillingRole: 'Technician'
  }
];

export const getLookupRole = (title) => {
  const role = lookupList.find((item) => item.JobTitle === title);
  const value = role?.BillingRole || 'Provider';

  if (!role) {
    return 'PT';
  }

  switch (value) {
    case 'Provider':
      return 'PT';
    case 'Provider Assistant':
      return 'PTA';
    case 'Technician':
      return 'TECH';
    default:
      return 'PT';
  }
};
