import React, { Fragment, useState, useEffect } from 'react';
import { Typography, Col } from 'antd';

const formatNumToTime = num => (num.toString().length > 1 ? num : `0${num}`);

const TotalTime = ({
  loadingActivityLogs,
  renderStreamDuration,
  activityLogs,
  isMonitoring,
}) => {
  const [totalTime, setTotalTime] = useState(0);

  useEffect(() => {
    let recentTotalTime = 0;

    if (activityLogs) {
      recentTotalTime = activityLogs.totalClinicalMonitoringTime;
    }

    setTotalTime(recentTotalTime);
  }, [activityLogs]);

  const getTotalStreamDuration = () => {
    const [hours, minutes, seconds] = renderStreamDuration.split(':');
    const hoursToMs = parseInt(hours) * 60 * 60 * 1000;
    const minutesToMs = parseInt(minutes) * 60 * 1000;
    const secondsToMs = parseInt(seconds) * 1000;
    const streamDurationMs = hoursToMs + minutesToMs + secondsToMs;

    const totalTimeMs = (isMonitoring ? streamDurationMs : 0) + totalTime;
    const totalHours = formatNumToTime(
      Math.floor(totalTimeMs / (1000 * 60 * 60))
    );
    const totalMinutes = formatNumToTime(
      Math.floor(totalTimeMs / (1000 * 60)) % 60
    );
    const totalSeconds = formatNumToTime(Math.floor(totalTimeMs / 1000) % 60);

    return `${totalHours}:${totalMinutes}:${totalSeconds}`;
  };

  const totalStreamDuration = getTotalStreamDuration();

  return (
    <Fragment>
      <Col span={17}>
        <Typography.Text strong>Total Time</Typography.Text>
      </Col>
      <Col span={7}>
        <Typography.Text strong>
          {loadingActivityLogs ? 'Loading...' : totalStreamDuration}
        </Typography.Text>
      </Col>
    </Fragment>
  );
};

// const TotalTime = ({
//   loadingActivityLogs,
//   renderStreamDuration,
//   streamDuration,
//   activityLogs,
// }) => {
//   const [totalTime, setTotalTime] = useState(0);

//   useEffect(() => {
//     let recentTotalTime = 0;

//     if (activityLogs) {
//       recentTotalTime = activityLogs.totalClinicalMonitoringTime;
//     }

//     setTotalTime(recentTotalTime + streamDuration);
//   }, [activityLogs, streamDuration]);

//   useEffect(() => {
//     if (streamDuration !== 0) {
//       setTotalTime(prevTime => prevTime + 1000);
//     }
//   }, [streamDuration]);

//   const convertTotalTime = () => {
//     const hours = formatNumToTime(Math.floor(totalTime / (1000 * 60 * 60)));
//     const minutes = formatNumToTime(Math.floor(totalTime / (1000 * 60)) % 60);
//     const seconds = formatNumToTime(Math.floor(totalTime / 1000) % 60);

//     return `${hours}:${minutes}:${seconds}`;
//   };

//   return (
//     <Fragment>
//       <Col span={17}>
//         <Typography.Text strong>Total Time</Typography.Text>
//       </Col>
//       <Col span={7}>
//         <Typography.Text strong>{convertTotalTime()}</Typography.Text>
//       </Col>
//     </Fragment>
//   );
// };

export default TotalTime;
