import { atom } from 'recoil';

const defaultState = {
  list: []
};

const MESSAGES_STATE = atom({
  key: 'MESSAGES_STATE',
  default: defaultState
});

export default MESSAGES_STATE;
