import React, { useState, useEffect } from 'react';
import { Well } from 'react-bootstrap';
import { success } from 'react-notification-system-redux';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Input, Switch, Col, Row, Button, Form, Typography } from 'antd';
import { Divider, Alert } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import {
  createTemplate,
  clearTemplateData,
  editTemplateSubmit,
  deselectPrescriptionExercise,
  selectPrescriptionExercise,
  clearPrescriptionExercises,
  movePrescriptionExercise,
  appendTemplateToSelected
} from '../actions/prescription';
import { delay } from '../RTMDashboard/components/PatientDetailDrawer/mixins';

import Loading from '../../../components/Loading';
import ExerciseList from '../../../components/ExerciseList';
import TemplateForm from './TemplateForm';
import states from '../../../states';

const ExerciseTemplateCreation = ({
  dispatch,
  isEditingTemplate,
  selectedExercises,
  visibleProfile,
  backToIndex,
  templateData,
  setTemplateData,
  refetchList
}) => {
  const { Name, GroupTemplate } = templateData;

  const [favorites, setFavorites] = useRecoilState(states.favorites);

  const user = useRecoilValue(states.user);
  const setModals = useSetRecoilState(states.modal);

  const [permission, setPermission] = useState(true);
  const [form, setForm] = useState();
  const [disabled, setDisabled] = useState(true);
  const [templateName, setTemplateName] = useState(Name || 'Template Name');
  const [isGroupTemplate, setIsGroupTemplates] = useState(!!GroupTemplate);
  const [currentStep, setCurrentStep] = useState(-1);

  const addFavorite = (id) => {
    const updatedList = favorites.list.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isSelected: !item.isSelected
        };
      }

      return item;
    });

    setFavorites({ list: updatedList });
  };

  const handleWriteInExercise = (newExercise) => {
    dispatch(selectPrescriptionExercise(newExercise));
  };

  const handleSelectExercise = (exercise) => {
    const { star, id, isSelected, exerciseName } = exercise;

    if (star) {
      addFavorite(id);
    }

    if (isSelected) {
      const index = selectedExercises.findIndex(
        (item) => item.exerciseName === exerciseName
      );
      dispatch(deselectPrescriptionExercise(index));
    } else {
      dispatch(selectPrescriptionExercise(exercise));
    }
  };

  const handleRemoveExerciseItem = (name) => {
    const { Author } = templateData;
    const owner = Author === user.details.emailAddress;

    if (isEditingTemplate && !owner) {
      return;
    }

    const selected = selectedExercises;
    const exercise = selected.find((item) => item.exerciseName === name);

    if (exercise && exercise.star) {
      const favoritesList = [...favorites.list];

      favoritesList.map((item) => {
        const isFavorite = item.id === exercise.id;
        if (!isFavorite) {
          return item;
        }

        return {
          ...item,
          isSelected: false
        };
      });

      setFavorites({
        list: favoritesList
      });
    }

    const index = selected.findIndex((item) => item.exerciseName === name);
    dispatch(deselectPrescriptionExercise(index));
  };

  const handleRemoveAllExerciseItem = () => {
    dispatch(clearPrescriptionExercises());
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    dispatch(movePrescriptionExercise(result));
  };

  const handleAddTemplateModal = () => {
    setModals((prevState) => ({
      ...prevState,
      addTemplate: !prevState.addTemplate
    }));
  };

  const addTemplateToSelectionPanel = (template) => {
    const { Author } = templateData;
    const owner = Author === user.details.emailAddress;

    if (isEditingTemplate && !owner) {
      setPermission(false);
      handleAddTemplateModal();

      return;
    }

    const originalCopy = {
      ...templateData
    };

    if (Array.isArray(template.Exercises)) {
      const updatedList = [...template.Exercises];

      for (let i = 0; i < template.Exercises.length; i++) {
        const item = template.Exercises[i];
        if (!updatedList.some((t) => t.id === item.id)) {
          updatedList.push(item);
        }
      }

      originalCopy.Exercises = updatedList;
    } else {
      originalCopy.Exercises = [...template.Exercises];
    }

    setTemplateData(originalCopy);
    dispatch(appendTemplateToSelected(originalCopy));
    handleAddTemplateModal();

    return dispatch(
      success({
        title: 'Template data added to template!',
        position: 'tc'
      })
    );
  };

  const submitTemplate = () => {
    const { EmailAddress, GroupId, GroupInfo } = visibleProfile;
    const { Exercises, Instructions, Frequency, Perdiem } = form;

    const dateNow = Date.now();
    const payload = {
      Id: templateData.Id,
      dateCreated: dateNow,
      groupId: GroupId,
      author: EmailAddress,
      group: {
        id: GroupId,
        name: GroupInfo.Name
      },
      exercises: [...Exercises].map((item) => {
        const exercise = {
          ...item
        };

        delete exercise.completionDates;
        return exercise;
      }),
      name: templateName,
      isGroupTemplate: isGroupTemplate,
      active: true,
      isEditable: true,
      modifiedBy: [
        {
          user: EmailAddress,
          date: dateNow
        }
      ],
      instructions: Instructions,
      frequency: Frequency ?? 1,
      perdiem: Perdiem ?? 'day'
    };

    let response;
    if (isEditingTemplate) {
      response = dispatch(editTemplateSubmit(payload));
    } else {
      response = dispatch(createTemplate(payload));
    }

    response.then(async (status, err) => {
      if (status.value === 'Success') {
        dispatch(clearTemplateData());
        dispatch(
          success({
            title: 'Template Saved.',
            position: 'tr'
          })
        );

        await delay(1000).then(() => {
          refetchList();
          backToIndex();
        });
      } else {
        console.log('Error: ', err);
      }
    });
  };

  /**
   * Init
   */
  useEffect(() => {
    if (currentStep === -1) {
      setCurrentStep(0);
    }
  }, []);

  /**
   * Update buttons if selected exercises changes
   */
  useEffect(() => {
    if (Array.isArray(selectedExercises)) {
      if (!selectedExercises.length) {
        setDisabled(true);
      } else {
        const author = templateData.Author;
        const currentUser = user.details.emailAddress;
        const isOwner = !author ? true : author === currentUser;

        if (isOwner) {
          setDisabled(false);
        } else {
          setPermission(false);
        }
      }
    }
  }, [selectedExercises]);

  const stepUp = () => {
    if (currentStep === 0) {
      setCurrentStep(1);
    } else {
      if (form.formError || form.Exercises.some((e) => e?.formError === true)) {
        return;
      }

      setDisabled(true);
      setCurrentStep(-1);
      submitTemplate();
    }
  };

  /**
   * Change UI on state of the form
   * Step -1: De-initialized
   * Step 0: Select exercises
   * Step 1: Review information and submit
   */
  return (
    <div>
      <Row gutter={[16, 0]}>
        <Col xl={16} lg={16} md={24} sm={24} xs={24}>
          {!permission && (
            <Alert
              message="Note: You can view this template, but cannot save any edits as you are not the creator. To create an edited version, create a new Template, import this Template and save any edits."
              type="info"
              showIcon
            />
          )}
          {currentStep !== -1 && (
            <Form.Item
              label="Template Name"
              rules={[
                { required: true, message: 'Please input your template name!' }
              ]}
              style={{ marginTop: 16, marginBottom: -12 }}
            >
              <Input
                type="text"
                placeholder="Enter template name"
                name="templateName"
                value={templateName}
                onChange={({ target: { value } }) => setTemplateName(value)}
              />
            </Form.Item>
          )}
        </Col>
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
          {user?.details?.role === 'ga' && (
            <Form.Item
              label="Group Template"
              tooltip={
                <Typography.Text
                  style={{
                    textAlign: 'justify',
                    whiteSpace: 'break-spaces',
                    color: 'white'
                  }}
                >
                  Group Templates are labeled with a special icon on the
                  Templates page to make it easier for all providers to access.
                  In addition, providers can filter to only view Group
                  Templates. Only Administrators can create Group Templates.
                </Typography.Text>
              }
              style={{
                margin: '16px 0px -12px 0px'
              }}
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={isGroupTemplate}
                onChange={(checked) => setIsGroupTemplates(checked)}
              />
            </Form.Item>
          )}
        </Col>
        <Col span={24}>
          <Divider />
          {currentStep === -1 && <Loading isLoading={true} />}
          {currentStep === 0 && (
            <ExerciseList
              isEditing
              selectedExercises={selectedExercises}
              handleSelectExercise={handleSelectExercise}
              handleAddTemplateModal={handleAddTemplateModal}
              handleRemoveExerciseItem={handleRemoveExerciseItem}
              handleRemoveAllExerciseItem={handleRemoveAllExerciseItem}
              onDragEnd={onDragEnd}
              addTemplateToSelectionPanel={addTemplateToSelectionPanel}
              handleWriteInExercise={handleWriteInExercise}
              fromTemplates={true}
            />
          )}
          {currentStep === 1 && (
            <TemplateForm
              onSubmit={(data) => {
                console.log('Submt', data);
              }}
              initialValues={templateData}
              selectedExercises={selectedExercises}
              setCurrentForm={setForm}
              importedTemplate={templateData}
            />
          )}
        </Col>
      </Row>
      {currentStep !== -1 && (
        <Well className="prescription-form-page-footer">
          <Row gutter={[12, 12]}>
            <Col xs={12} className="centered-text">
              {currentStep === 1 && (
                <Button
                  disabled={false}
                  type="default"
                  onClick={() => {
                    setCurrentStep(0);
                    window.scrollTo(0, 0);
                  }}
                >
                  Back
                </Button>
              )}
            </Col>
            <Col xs={currentStep === 1 ? 12 : 24} className="centered-text">
              <Button
                type="primary"
                onClick={() => {
                  stepUp();
                }}
                disabled={disabled}
              >
                {currentStep === 0 ? 'Next' : 'Save'}
              </Button>
            </Col>
          </Row>
        </Well>
      )}
    </div>
  );
};

export default ExerciseTemplateCreation;
