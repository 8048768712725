import { atom } from 'recoil';

const defaultState = {
  token: null,
  promptCode: null
};

const AUTH_STATE = atom({
  key: 'AUTH_STATE',
  default: defaultState
});

export default AUTH_STATE;
