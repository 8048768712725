import _ from 'lodash';
import moment from 'moment';

export const simpleDate = function () {
  const date = new Date();
  return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
};

export const countExercises = (exercises) => {
  let count = 0;
  for (let i = 0; i < exercises.length; i++) {
    const ex = exercises[i];
    // const { completionDates } = ex;
    if (isChecked(ex)) {
      count = count + 1;
    }
  }
  return count;
};

export const isChecked = ({ completionDates }) => {
  if (!completionDates) {
    return false;
  }

  const date1 = moment().format('l');
  const date2 = moment().format('L');
  const date3 = moment().format('YYYY-MM-DD');

  const found = completionDates.find((date) => {
    return date === date1 || date === date2 || date === date3;
  });

  return !!found;
};

// export const countExercises = exercises => {
//   if (exercises.length === 0) {
//     return 0;
//   }
//   const completed = exercises.reduce((acc, ex, idx) => {
//     console.log('acc', acc);
//     console.log('idx', idx);
//     if (idx === 1) {
//       const firstChecked = isChecked(acc);

//       let count = firstChecked ? 1 : 0;

//       if (isChecked(ex)) {
//         return 1 + count;
//       } else {
//         return count;
//       }
//     } else {
//       if (isChecked(ex)) {
//         return acc + 1;
//       } else {
//         return acc;
//       }
//     }
//   });

//   return completed;
// };

// export const isChecked = ({ completionDates }) => {
//   let sentinel = false;
//   const today = simpleDate().split('/');

//   if (!Array.isArray(today)) {
//     console.log(1);
//     return sentinel;
//   }
//   /*eslint-disable-next-line*/
//   const todayNumbers = today.map(str => parseInt(str));

//   if (completionDates) {
//     console.log(2);
//     for (let i = 0; i < completionDates.length; i++) {
//       const date = completionDates[i].split('/');
//       /*eslint-disable-next-line*/
//       const dateNumbers = date.map(str => parseInt(str));
//       for (let i = 0; i < dateNumbers.length; i++) {
//         if (todayNumbers[i] === dateNumbers[i]) {
//           sentinel = true;
//         }
//       }
//     }
//   }

//   return sentinel;
// };

export const formatRxItem = (rxItem) => {
  return !rxItem.hasOwnProperty('Prescription')
    ? { Prescription: rxItem }
    : rxItem;
};

export const calculateAchievements = (rxList) => {
  if (!rxList) return;

  let exerciseAchievements = [];
  let routineAchievments = [];

  const formattedList = rxList.map((item) => formatRxItem(item));

  const exerciseList = [];

  formattedList.map(({ Prescription }) =>
    exerciseList.push(...Prescription.exercises)
  );

  const completionDatesList = exerciseList.map(
    ({ completionDates }) => completionDates
  );

  const totalExercises = completionDatesList
    .map((item) => item && item.length)
    .reduce((acc, cv) => acc + cv);

  if (totalExercises >= 1) exerciseAchievements.push(1);

  if (totalExercises >= 10) exerciseAchievements.push(10);

  if (totalExercises >= 25) exerciseAchievements.push(25);

  if (totalExercises >= 50) exerciseAchievements.push(50);

  const availableDates = completionDatesList[0];

  if (!Array.isArray(availableDates)) {
    return [];
  }

  // const datesMap = availableDates.map(date =>
  //   _.findIndex(completionDatesList, date) === -1 ? false : true
  // );

  let numberOfCompletedRoutines = 0;

  formattedList.map(({ Prescription }) => {
    const { completedRoutines } = Prescription;
    numberOfCompletedRoutines += completedRoutines;
  });

  if (numberOfCompletedRoutines >= 1) routineAchievments.push(1);

  if (numberOfCompletedRoutines >= 5) routineAchievments.push(5);

  if (numberOfCompletedRoutines >= 15) routineAchievments.push(15);

  if (numberOfCompletedRoutines >= 30) routineAchievments.push(30);

  return { exercises: exerciseAchievements, routines: routineAchievments };
};
