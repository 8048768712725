import React, { Component } from 'react';
import {Row, Col, Table, Thumbnail} from 'react-bootstrap';

class Feed extends Component {

    render() {
        const {isLoading, unavailable, blogData, thumbClick} = this.props;


        let name;
        if(blogData && blogData[0]){
            name = "Health and Wellness Tips"
        }
        return(
            <div>
                {isLoading && <h1>LOADING...</h1>}
                {unavailable && <h1> Blog Not Available</h1>}
                {blogData && blogData[0] &&
                <div>
                    <Table bordered={true} style={{position:'absolute', top:0,left:0,maxWidth:'none'}}>
                        <tbody>
                        <tr>
                            <th>
                                <Row>
                                    <Col xs={12}>
                                        <h1 className={'feed-title'}>{name}</h1>
                                    </Col>
                                </Row>
                            </th>
                        </tr>
                        {
                            blogData && Array.isArray(blogData)  && blogData.map((item, index)=>{

                                return(
                                    <tr key={'article-'+index} style={{paddingTop:'20px'}}>
                                        <Row className={"feed-row"} onClick={()=>{thumbClick(item)}}
                                             style={index%2 ===0 ? {backgroundColor:"#ffffff"} :
                                                 {backgroundColor:"#f5f5f5"} }>

                                            {item.content.src && <Col xs={6}>
                                                <Thumbnail src={item.content.src} alt="242x200"/>
                                            </Col>
                                            }
                                            <Col xs={item.content.src ? 6: 12} >
                                                <h3 style={{textTransform:"none"}}>{item.title}</h3>
                                                <p>
                                                {Array.isArray(item.categories) &&item.categories.map((item, i)=>{
                                                    return (<small> {item}&nbsp;|&nbsp;</small>)
                                                })}
                                                </p>
                                            </Col>
                                        </Row>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
                }
            </div>)
    }
}

export default Feed;