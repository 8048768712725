import React, { Fragment, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { connect } from 'react-redux';
import { Space, Button, Tooltip, Typography, Input, Card } from 'antd';
import { PlusOutlined, SearchOutlined, RightOutlined } from '@ant-design/icons';
import v from 'voca';
import '../../styles/atomic.css';
import states from '../../states';
import PrescriptionDrawer from '../multiple-prescriptions/components/PrescriptionDrawer';

const PrescriptionList = ({ onAddClick, onEditClick, currentPatient }) => {
  const [filter, setFilter] = useState('');

  const [params, setParams] = useRecoilState(states.params);
  const [prescription, setPrescription] = useRecoilState(states.prescription);
  const { list, rxDrawer } = prescription;

  useEffect(() => {
    if (params.caseId) {
      const item = list.find(item => item.caseId === params.caseId);
      if (item) {
        handleSetRxDetails(item);
        setParams({
          ...params,
          caseId: '',
        });
      }
    }
  }, []);

  const handleSetRxDetails = item => {
    setPrescription(prevState => ({
      ...prevState,
      form: item,
      rxDrawer: item,
    }));
  };

  const handleFilter = rxList => {
    if (filter) {
      return rxList.filter(
        item =>
          (item.name &&
            item.name.toLowerCase().includes(filter.toLowerCase())) ||
          item.prescription.instructions
            .toLowerCase()
            .includes(filter.toLowerCase())
      );
    }
    return rxList;
  };

  const filteredList = handleFilter(list);

  return (
    <React.Fragment>
      {rxDrawer && (
        <PrescriptionDrawer
          rxDetails={rxDrawer}
          onEditClick={onEditClick}
          handleClose={() => handleSetRxDetails(null)}
        />
      )}
      <div className="ptw-collapse-body">
        <div className="tab-header">
          <div />
          <div className="tab-header-controls">
            <Button
              type="primary"
              className="btn-primary ptw-btn"
              size="large"
              onClick={onAddClick}
            >
              <PlusOutlined />
              Add Prescription
            </Button>
            <Input
              size="large"
              placeholder="Search prescription..."
              onChange={e => setFilter(e.target.value)}
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
        {filteredList &&
          filteredList
            .map(item => {
              const { FirstName, LastName } = currentPatient;
              const patientName = `${v.capitalize(FirstName)} ${v.capitalize(
                LastName
              )}`;
              const prescriptionName = `${patientName}'s Home Exercise Program`;

              return {
                ...item,
                name: item.name || prescriptionName,
              };
            })
            .map((item, i) => (
              <Card
                hoverable
                onClick={() => handleSetRxDetails(item)}
                key={i}
                style={{ marginBottom: 12, borderRadius: 6 }}
              >
                <div className="prescription-item">
                  <div>
                    <Typography.Title level={5}>{item.name}</Typography.Title>
                    <Typography.Text type="secondary">
                      {item.prescription.exercises.length} Exercises{' '}
                    </Typography.Text>
                    {item.hasOwnProperty('active') && !item.active && (
                      <Fragment>
                        &bull;{' '}
                        <Typography.Text type="danger">
                          Disabled
                        </Typography.Text>
                      </Fragment>
                    )}
                  </div>
                  <Space direction="horizontal" size={16}>
                    <Tooltip title="View Prescription Details">
                      <Button
                        shape="circle"
                        type="text"
                        icon={<RightOutlined style={{ fontSize: 24 }} />}
                        onClick={() => handleSetRxDetails(item)}
                      />
                    </Tooltip>
                  </Space>
                </div>
              </Card>
            ))}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  currentPatient: state.patients.currentPatient,
});

export default connect(mapStateToProps)(PrescriptionList);
