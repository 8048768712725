import { atom } from 'recoil';

const defaultState = null;

const FILTERS_STATE = atom({
  key: 'FILTERS_STATE',
  default: defaultState
});

export default FILTERS_STATE;
