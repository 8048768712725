import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Breadcrumb, Button, Col, FormGroup, Row } from 'react-bootstrap'
import { updatePasswordByToken } from '../membership/actions';
import TextInput from '../membership/TextInput';
import { passwordValidator } from '../../validators';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      passwordConfirmation: '',
      hasError: false,
      errorMessage: '',
      isSubmitting: false,
      emailAddress: props.profile.EmailAddress,
    };

    this.submitPasswordUpdate = this.submitPasswordUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  submitPasswordUpdate(e) {
    const { history } = this.props;
    const { oldPassword, newPassword, emailAddress } = this.state;
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props.dispatch(updatePasswordByToken({ oldPassword, newPassword, emailAddress }))
    .then(() => history.push('/profile?success=true'), err =>
      err.text().then(errorMessage =>
        this.setState({
          isLoading: false,
          errorMessage,
        })
    ));
  }

  handleChange({ target }) {
    this.setState({
      [target.name]: target.value,
    });
  }

  render() {
    let passwordsDoNotMatch = this.state.newPassword !== this.state.passwordConfirmation;
    let readyToSubmit = passwordValidator(this.state.newPassword) && !passwordsDoNotMatch;

    return (
      <div className="ptw-panel-margin">
        <Row>
          <Col xs={12} md={6}>
            <Breadcrumb>
              <Breadcrumb.Item href={"/#/profile"}>Profile</Breadcrumb.Item>
              <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col xs={6} xsOffset={3}>
            {this.state.errorMessage &&
                <p className="text-danger">{this.state.errorMessage}</p>}

            <form onSubmit={this.submitPasswordUpdate}>
              <TextInput
                name="oldPassword"
                type="password"
                label="Old Password"
                onChange={this.handleChange}
                hasError={this.state.hasError}
              />

              <TextInput
                name="newPassword"
                type="password"
                label="New Password"
                onChange={this.handleChange}
                hasError={this.state.hasError}
              />

              <TextInput
                name="passwordConfirmation"
                type="password"
                label="Confirm Password"
                onChange={this.handleChange}
                hasError={this.state.hasError}
              />

              <div className='password-requirements'>
                Your password must adhere to the following:
                <ul>
                  <li>1 upper case character</li>
                  <li>1 lower case character</li>
                  <li>1 number</li>
                </ul>
                Password must be at least 8 characters.
              </div>

              <FormGroup>
                <Button
                  type="submit"
                  bsStyle="primary"
                  className="btn-block"
                  disabled={!readyToSubmit || this.state.isSubmitting}>
                  Submit
                  {this.state.isSubmitting && <i className="fa fa-spinner fa-pulse fa-fw text-icon"/>}
                </Button>
              </FormGroup>
            </form>
          </Col>
        </Row>
      </div>
    )
  }
}

ChangePassword.propTypes = {
    profile: PropTypes.shape({
        FirstName: PropTypes.string.isRequired,
        LastName: PropTypes.string.isRequired,
        EmailAddress: PropTypes.string.isRequired,
    })
}

export default connect(state => ({profile: state.visibleProfile}))(ChangePassword);
