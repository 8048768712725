import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { useRecoilState } from 'recoil';
import {
  Modal,
  Dropdown,
  Menu,
  Checkbox,
  Tooltip,
  Button,
  Input,
  Typography,
  notification
} from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';

import LoadingPage from '../../../components/LoadingPage';
import ExerciseTemplateGrid from '../../../features/patients/ExerciseTemplates/ExerciseTemplateGrid';
import states from '../../../states';
import services from '../../../services';

const filterTypeToText = (type) => {
  switch (type) {
    case 'GROUP_TEMPLATES':
      return 'Group Templates';
    case 'MY_TEMPLATES':
      return 'My Templates';
    default:
      return '';
  }
};

const AddTemplateModal = ({ addTemplateToSelectionPanel, visibleProfile }) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [filter, setFilter] = useState([
    !!visibleProfile.GroupInfo?.EnableDefaultGroupTemplates
      ? 'GROUP_TEMPLATES'
      : 'MY_TEMPLATES'
  ]);

  const [modals, setModals] = useRecoilState(states.modal);

  useEffect(() => {
    const query = filter.length ? filter : null;
    const author = filter.includes('MY_TEMPLATES')
      ? visibleProfile.EmailAddress
      : null;

    fetchTemplates(query, search, author);

    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const debouncedSearch = useRef(
    debounce(async (query, input, creator) => {
      const filter = query.length ? query : null;
      const search = input;
      const author = creator;

      await fetchTemplates(filter, search, author);
    }, 500)
  ).current;

  const fetchTemplates = async (query, input, author) => {
    try {
      setLoading(true);

      const { GroupId } = visibleProfile;
      const response = await services.templates.getList(
        GroupId,
        query,
        input,
        author
      );

      if (response.status === 200) {
        setTemplates(
          [...response.data].sort((a, b) => {
            const nameA = a.Name?.toLowerCase() || '';
            const nameB = b.Name?.toLowerCase() || '';

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
        );
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while fetching templates.'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputSearch = (e) => {
    const value = e.target.value;
    const author = filter.includes('MY_TEMPLATES')
      ? visibleProfile.EmailAddress
      : null;

    setSearch(value);
    debouncedSearch(filter, value, author);
  };

  const handleSelectFilter = (e) => {
    const type = e.target.name;
    const filterArr = filter.includes(type)
      ? filter.filter((item) => item !== type)
      : [...filter, type];
    const author = filterArr.includes('MY_TEMPLATES')
      ? visibleProfile.EmailAddress
      : null;

    setFilter(filterArr);
    fetchTemplates(filterArr, search, author);
  };

  const handleAddTemplateModal = () => {
    setModals((prevState) => ({
      ...prevState,
      addTemplate: !prevState.addTemplate
    }));
  };

  return (
    <Modal
      width={1200}
      footer={null}
      title="Add Template to Prescription"
      open={modals.addTemplate}
      onCancel={handleAddTemplateModal}
    >
      <div className="tab-header">
        <div />

        <div className="tab-header-controls">
          <Input
            autoFocus
            size="middle"
            placeholder="Search template or author"
            value={search}
            onChange={handleInputSearch}
            prefix={<SearchOutlined />}
          />

          <Tooltip title="Filter Templates">
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => null}>
                    <Checkbox
                      name="GROUP_TEMPLATES"
                      checked={filter.includes('GROUP_TEMPLATES')}
                      onChange={handleSelectFilter}
                    >
                      Group Templates
                    </Checkbox>
                  </Menu.Item>

                  <Menu.Item onClick={() => null}>
                    <Checkbox
                      name="MY_TEMPLATES"
                      checked={filter.includes('MY_TEMPLATES')}
                      onChange={handleSelectFilter}
                    >
                      My Templates
                    </Checkbox>
                  </Menu.Item>
                </Menu>
              }
              onOpenChange={(e) => setFilterOpen(e)}
              open={filterOpen}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                type={filter.length < 1 ? 'default' : 'primary'}
                shape="circle"
              >
                <FilterOutlined style={{ fontSize: 16 }} />
              </Button>
            </Dropdown>
          </Tooltip>
        </div>
      </div>

      {!!filter.length && (
        <div style={{ marginBottom: 20 }}>
          <Typography.Text style={{ fontSize: 16 }} level={5}>
            Filtered by -{' '}
            <Typography.Link onClick={() => setFilterOpen(!filterOpen)}>
              {filter.map((item) => filterTypeToText(item)).join(', ')}{' '}
            </Typography.Link>
          </Typography.Text>
        </div>
      )}

      {loading ? (
        <LoadingPage type="list" content="Loading templates, please wait..." />
      ) : (
        <ExerciseTemplateGrid
          templates={templates}
          handleCardClick={addTemplateToSelectionPanel}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  visibleProfile: state.visibleProfile
});

export default connect(mapStateToProps)(AddTemplateModal);
