import React from 'react'
import { connect } from 'react-redux'
import Help from '../Help'
import { requestHelp } from '../actions/helpActions'
import { bindActionCreators } from 'redux'

const mapStateToProps = state => {
    return {
        help: state.help
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ requestHelp }, dispatch)

class helpContainer extends React.Component {
    componentDidMount() {
        this.props.requestHelp();        
    }

    render() {
        return (<Help/>);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(helpContainer);