import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Typography,
  message,
  notification
} from 'antd';
import {
  AppstoreOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  BarsOutlined,
  CaretDownFilled,
  CloseOutlined,
  FileDoneOutlined,
  FilterOutlined,
  InfoCircleFilled,
  PicCenterOutlined,
  SearchOutlined,
  UsergroupDeleteOutlined
} from '@ant-design/icons';
import {
  ChevronForward,
  ChevronDown,
  SwapVerticalOutline
} from 'react-ionicons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import { firstBy } from 'thenby';
import _ from 'lodash';

import LoadingPage from '../../../components/LoadingPage';
import FilterPatientsModal from './components/FilterPatientsModal';
import SortPatientsContainer from './components/SortPatientsContainer';
import PatientDetailDrawer from './components/PatientDetailDrawer/index';
import PatientsTable from './components/PatientsTable';

import { getAdmins } from '../../users/actions/users';
import { getSortFunction } from './components/PatientDetailDrawer/mixins';

import states from '../../../states';
import services from '../../../services';

const popoverContent = (content) => (
  <Space direction="vertical">
    {content ? (
      <div className="popover-title-container">
        <Space direction="vertical" style={{ width: 350 }}>
          <Typography.Text strong>{content.title}</Typography.Text>
          <Typography.Text>{content.description}</Typography.Text>
        </Space>
      </div>
    ) : (
      <div className="popover-title-container">
        <Space direction="vertical">
          <Typography.Text strong>RTM Dashboard</Typography.Text>
          <Typography.Paragraph>
            Easily track your patients' RTM activity, progress, and billing
            <br />
            requirements on the RTM Dashboard. See our Help Center for tutorial
            <br />
            videos and more information.
          </Typography.Paragraph>
          <a
            className={'btn-link'}
            href={'https://ptwired.zendesk.com/hc/en-us/articles/8411300780947'}
            target={'_blank'}
            rel="noopener noreferrer"
          >
            Help Center
          </a>
        </Space>
      </div>
    )}
  </Space>
);

const getEngagementCount = (data, status) =>
  data && data.filter((dt) => dt.Status === status).length;

const getEngagementStatus = (name) => {
  switch (name) {
    case 'READY_FOR_BILLING':
      return 'GREEN';
    case 'HIGH_ENGAGEMENT_PATIENTS':
      return 'BLUE';
    case 'LOW_ENGAGEMENT_PATIENTS':
      return 'YELLOW';
    case 'NO_ENGAGEMENT_PATIENTS':
      return 'RED';
    default:
      break;
  }
};

const RTMDashboard = (props) => {
  const {
    dispatch,
    visibleProfile,
    visibleProfile: {
      EmailAddress,
      GroupId,
      GroupInfo: { Name },
      Sub
    },
    admins,
    loadingAnalytics,
    setLoadingAnalytics
  } = props;
  const defaultProvider = {
    EmailAddress,
    FirstName: visibleProfile.FirstName,
    LastName: visibleProfile.LastName,
    Role: visibleProfile.Role,
    GroupId,
    Sub
  };

  const [statisticData, setStatisticData] = useState([]);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState([]);
  const [openFilterPatient, setOpenFilterPatient] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState([defaultProvider]);
  const [selectedView, setSelectedView] = useState(null);
  const [view, setView] = useState('CARD');
  const [searchTerm, setSearchTerm] = useState('');
  const [openPatientDetail, setOpenPatientDetail] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [rtm, setRTM] = useRecoilState(states.rtm);
  const [filters, setFilters] = useRecoilState(states.filters);

  const group = useRecoilValue(states.groups);

  useEffect(() => {
    fetchProviders();

    setSelectedView(rtm.dashboard.status);
    setSearchTerm(rtm.dashboard.search);
    setOpenSort(!!rtm.dashboard.sortFilters.length);
  }, []);

  useEffect(() => {
    if (rtm.analytics) {
      setStatisticData([
        {
          icon: <FileDoneOutlined className="statistic-icon" />,
          title: 'Ready for Billing',
          value: getEngagementCount(rtm.analytics, 'GREEN'),
          cardClassName: 'card-ready-to-bill',
          name: 'READY_FOR_BILLING',
          description: `These patients have met billing requirements for one or more RTM Codes. Click on the patient's card and select the 'Billing' tab for more information on the bill dates, codes, and supporting documentation.`
        },
        {
          icon: <ArrowUpOutlined className="statistic-icon" />,
          title: 'On Track for Billing',
          value: getEngagementCount(rtm.analytics, 'BLUE'),
          cardClassName: 'card-high-engagement',
          name: 'HIGH_ENGAGEMENT_PATIENTS',
          description: `Based on these patients' current activity levels thus far in the billing period, they will  be eligible for billing code 98977 and the end of the billing period.`
        },
        {
          icon: <ArrowDownOutlined className="statistic-icon" />,
          title: 'Not On Track for Billing',
          value: getEngagementCount(rtm.analytics, 'YELLOW'),
          cardClassName: 'card-low-engagement',
          name: 'LOW_ENGAGEMENT_PATIENTS',
          description: `Based on these patients' current activity levels thus far in the billing period, they will NOT be eligible for billing code 98977 and the end of the billing period.`
        },
        {
          icon: <UsergroupDeleteOutlined className="statistic-icon" />,
          title: 'No Activity Reported',
          value: getEngagementCount(rtm.analytics, 'RED'),
          cardClassName: 'card-no-engagement',
          name: 'NO_ENGAGEMENT_PATIENTS',
          description: `These patients have not used the app at all thus far in the current billing cycle.`
        }
      ]);
    }
  }, [rtm.analytics, openPatientDetail, selectedPatient]);

  useEffect(() => {
    if (filters && Array.isArray(filters.RTM)) {
      const { RTM } = filters;
      const providers = group.providers?.filter((p) => RTM.includes(p.Sub));

      setSelectedProviders(providers);
    }
  }, [filters, group]);

  const fetchProviders = async () => {
    try {
      await dispatch(getAdmins(GroupId));
    } catch (error) {
      notification.warning({
        message: 'Sorry',
        description: 'We seem to be having issues with fetching providers.'
      });
    }
  };

  const handleOpenFilterPatient = () => {
    setOpenFilterPatient(!openFilterPatient);
  };

  const handleSetSelectedView = (name) => {
    const view = selectedView === name ? null : name;

    setSelectedView(view);
    setRTM((prevState) => ({
      ...prevState,
      dashboard: {
        ...prevState.dashboard,
        status: view
      }
    }));
  };

  const handleInputSearch = (e) => {
    const value = e.target.value;

    setSearchTerm(value);
    setRTM((prevState) => ({
      ...prevState,
      dashboard: {
        ...prevState.dashboard,
        search: value
      }
    }));
  };

  const renderProviderOptions = () => {
    return getActiveProviders(admins)
      .filter(
        (item) =>
          !selectedProviders.some(
            (prvdr) => prvdr.EmailAddress === item.EmailAddress
          )
      )
      .sort(
        (a, b) =>
          (a.EmailAddress > b.EmailAddress) - (a.EmailAddress < b.EmailAddress)
      )
      .map((item, i) => (
        <Select.Option key={i} value={item.EmailAddress}>
          {`${item.FirstName} ${item.LastName} `}
          <i style={{ fontSize: 12, color: '#999999' }}>
            ({item.EmailAddress})
          </i>
        </Select.Option>
      ));
  };

  const renderProviderItems = () => {
    return selectedProviders
      .sort(
        (a, b) =>
          (a.EmailAddress > b.EmailAddress) - (a.EmailAddress < b.EmailAddress)
      )
      .map((item, i) => (
        <div className="provider-tag" key={i}>
          <Typography.Text>
            {`${item.FirstName} ${item.LastName} `}
            <i style={{ fontSize: 12, color: '#999999' }}>
              ({item.EmailAddress})
            </i>
            <span
              className="provider-tag-remove-btn"
              onClick={() => handleRemoveProvider(item.EmailAddress)}
            >
              <CloseOutlined />
            </span>
          </Typography.Text>
        </div>
      ));
  };

  const handleSelectProviders = (EmailAddress) => {
    const activeAdmins = getActiveProviders(admins);

    const selectedProvider = activeAdmins.filter(
      (admin) => admin.EmailAddress === EmailAddress
    );

    let allProviders = selectedProviders.concat(selectedProvider);

    setSelectedProviders([...allProviders]);
  };

  const handleSelectAllProviders = () => {
    const activeAdmins = getActiveProviders(admins);

    setSelectedProviders([...activeAdmins]);
  };

  const handleRemoveAllProviders = () => {
    setSelectedProviders([]);
  };

  const handleRemoveProvider = (EmailAddress) => {
    let updatedProviders = selectedProviders.filter(
      (admin) => admin.EmailAddress !== EmailAddress
    );

    setSelectedProviders([...updatedProviders]);
  };

  const handleSaveFilter = async () => {
    const activeAdmins = getActiveProviders(admins);
    const selectedList = selectedProviders.map((prov) => prov.Sub);

    const sortedFilter = [...selectedList].sort();
    const sortedAdmins = [...activeAdmins.map((p) => p.Sub)].sort();

    const queryFilters = [];
    if (!_.isEqual(sortedFilter, sortedAdmins)) {
      queryFilters.push(...selectedList);
    }

    if (queryFilters.length > 50) {
      notification.warning({
        message: 'Too Many Filters',
        description: 'Please select a maximum of 50 providers.'
      });

      return;
    }

    try {
      const payload = {
        ...filters,
        RTM: selectedList
      };

      if (!filters) {
        payload.Sub = Sub;
        payload.GroupId = GroupId;
      }

      services.filters.addFilters(payload).then((res) => {
        if (res.status === 200) {
          setFilters((prevState) => ({
            ...prevState,
            ...res.data
          }));
        }
      });
    } catch (error) {
      console.log('[rtm provider filters]', error);
    }

    let total = 0;
    let initialFetched = 0;

    try {
      setLoadingAnalytics(true);

      await services.rtm.getAnalyticsList(GroupId, queryFilters).then((res) => {
        if (res.status === 200) {
          const totalData = res.data.total;
          const initFetch = res.data.items.length;

          setRTM((prevState) => ({
            ...prevState,
            analytics: _.uniqBy([...res.data.items], 'Sub'),
            dashboard: {
              ...prevState.dashboard,
              page: 1
            },
            hasRemaining: totalData > initFetch
          }));

          total = totalData;
          initialFetched = initFetch;
        }
      });
    } catch (error) {
      notification.error({
        message: 'Fetch Failed',
        description: 'An error occurred while fetching RTM patients'
      });
    } finally {
      setLoadingAnalytics(false);
      setOpenFilterPatient(false);
    }

    if (total > initialFetched) {
      const failedQueries = [];
      const limit = 5000;
      const counter = Math.ceil(total / limit);

      for (let i = 1; i <= counter; i++) {
        const skip = i * limit;
        const done = i === counter || skip > total;

        const rtmState = getRecoil(states.rtm);
        const cancelProcess = rtmState.stopFetching;

        let msgType = done ? 'success' : 'loading';
        let msgDuration = done ? 3 : 0;
        let msgContent = done
          ? ' Fetching RTM patients completed.'
          : ' Fetching remaining RTM patients ...';

        if (cancelProcess) {
          msgType = 'warning';
          msgDuration = 3;
          msgContent = ' Fetching RTM patients cancelled.';
        }

        message.open({
          key: 'fetching-rtm-patients',
          type: msgType,
          duration: msgDuration,
          content: msgContent
        });

        if (skip > total || cancelProcess) {
          setRTM((prevState) => ({
            ...prevState,
            hasRemaining: false,
            stopFetching: false
          }));

          break;
        }

        const query = services.rtm.getAnalyticsList(
          GroupId,
          queryFilters,
          limit,
          skip
        );

        try {
          await query.then((res) => {
            if (res.status === 200) {
              setRTM((prevState) => ({
                ...prevState,
                analytics: sortAnalytics(
                  _.uniqBy([...prevState.analytics, ...res.data.items], 'Sub')
                )
              }));
            }
          });
        } catch (error) {
          failedQueries.push(query);
          continue;
        }
      }

      if (failedQueries.length) {
        await getRemainingAnalytics(failedQueries);
      }
    }
  };

  const getRemainingAnalytics = async (queries, retry = 3) => {
    const failed = [];
    for (let i = 0; i < queries.length; i++) {
      const query = queries[i];

      try {
        await query.then((res) => {
          if (res.status === 200) {
            setRTM((prevState) => ({
              ...prevState,
              analytics: sortAnalytics(
                _.uniqBy([...prevState.analytics, ...res.data.items], 'Sub')
              )
            }));
          }
        });
      } catch (error) {
        failed.push(query);
      }
    }

    if (failed.length && retry > 0) {
      await getRemainingAnalytics(failed, retry - 1);
    }
  };

  const sortAnalytics = (dataArr) =>
    [...dataArr].sort((a, b) => {
      const aFname = a.FirstName?.trim().toLowerCase() || '';
      const aLname = a.LastName?.trim().toLowerCase() || '';

      const bFname = b.FirstName?.trim().toLowerCase() || '';
      const bLname = b.LastName?.trim().toLowerCase() || '';

      if (aFname + aLname > bFname + bLname) {
        return 1;
      }

      return -1;
    });

  const handleSearch = (patientsList) => {
    if (!searchTerm) {
      return patientsList;
    }

    return patientsList?.filter((item) => {
      const input = searchTerm.toLowerCase();
      const fname = item.FirstName.toLowerCase();
      const lname = item.LastName.toLowerCase();
      const email = item.EmailAddress?.toLowerCase() || '';
      const onInitials =
        input.split(' ').includes(fname) || input.split(' ').includes(lname);

      return (
        fname.includes(input) ||
        lname.includes(input) ||
        email.includes(input) ||
        `${fname} ${lname}`.includes(input) ||
        onInitials
      );
    });
  };

  const getFilteredList = (patientsList) => {
    const engagementStatus = getEngagementStatus(selectedView);
    const filteredList = selectedView
      ? patientsList.filter((p) => p.Status === engagementStatus)
      : patientsList.filter((p) => p);
    const sortedList = getSortedList(filteredList);

    return handleSearch(sortedList);
  };

  const getSortItems = (sortItems) => {
    setSelectedSort(sortItems);
  };

  const getSortedList = (patientsList) => {
    if (!selectedSort.length) {
      return patientsList;
    }

    const predicate = (i) =>
      selectedSort[i] ? getSortFunction(selectedSort[i]) : () => null;

    return patientsList.sort(
      firstBy(predicate(0))
        .thenBy(predicate(1))
        .thenBy(predicate(2))
        .thenBy(predicate(3))
    );
  };

  const getActiveProviders = (admins) => {
    if (Array.isArray(admins)) {
      return admins
        .filter(({ active }) => active === true || active === 'true')
        .map((item) => ({
          EmailAddress: item.EmailAddress,
          FirstName: item.FirstName,
          LastName: item.LastName,
          Role: item.Role,
          GroupId: item.GroupId,
          Sub: item.Sub
        }));
    }

    return [];
  };

  const handleOpenPatientDetail = ({ patient }) => {
    setSelectedPatient(patient);
    setOpenPatientDetail(() => !openPatientDetail);
  };

  const renderMobileFilteredProviders = () => {
    if (!!selectedProviders.length) {
      return (
        <Typography.Text
          style={{ fontSize: 14, marginTop: -16 }}
          className="mb-2 hide-dt show-lg-tablet"
        >
          Displaying patients of{' '}
          {selectedProviders.length > 1 ? 'providers' : 'provider'}
          {' - '}
          <Typography.Link>
            {selectedProviders[0].FirstName} {selectedProviders[0].LastName}
          </Typography.Link>{' '}
          {selectedProviders.length > 1 && (
            <Fragment>
              and{' '}
              <Typography.Link
                underline
                onClick={() => handleOpenFilterPatient()}
              >
                {selectedProviders.length - 1} more
              </Typography.Link>
            </Fragment>
          )}
        </Typography.Text>
      );
    } else {
      return <div />;
    }
  };

  return (
    <div className="ptw-main-body">
      <Typography.Title level={2}>
        RTM Dashboard{' '}
        <Popover
          content={popoverContent()}
          trigger="hover"
          placement="right"
          arrowPointAtCenter
        >
          <InfoCircleFilled className="popover-icon" />
        </Popover>
      </Typography.Title>
      {loadingAnalytics ? (
        <LoadingPage
          type="list"
          content={
            <Space direction="vertical" align="center">
              <div>Loading RTM dashboard, please wait...</div>
              <div>For large patient lists, this may take several minutes.</div>
            </Space>
          }
        />
      ) : (
        <React.Fragment>
          <PatientDetailDrawer
            openPatientDetail={openPatientDetail}
            handleOpenPatientDetail={handleOpenPatientDetail}
            selectedPatient={selectedPatient}
            setSelectedPatient={setSelectedPatient}
            groupName={Name}
            provider={EmailAddress}
            source="dashboard"
            selectedProviders={selectedProviders}
            setLoadingAnalytics={(loading) => setLoadingAnalytics(loading)}
          />

          <FilterPatientsModal
            openFilterPatient={openFilterPatient}
            handleOpenFilterPatient={handleOpenFilterPatient}
            handleSelectProviders={handleSelectProviders}
            handleSelectAllProviders={handleSelectAllProviders}
            handleRemoveProvider={handleRemoveProvider}
            handleRemoveAllProviders={handleRemoveAllProviders}
            handleSaveFilter={handleSaveFilter}
            admins={admins}
            selectedProviders={selectedProviders}
            renderProviderOptions={renderProviderOptions}
            renderProviderItems={renderProviderItems}
          />

          <Row gutter={[16, 16]}>
            {statisticData &&
              statisticData.map((item, i) => (
                <Col key={i} xl={6} lg={12} md={12} sm={24} xs={24}>
                  <Card
                    onClick={() => handleSetSelectedView(item.name)}
                    className={`statistic-card ${
                      item.name === selectedView && 'selected-card'
                    } ${item.cardClassName}`}
                  >
                    <Row gutter={[12, 0]} justify="center" align="middle">
                      <Col lg={6} md={6} sm={24} xs={24}>
                        <Row justify="center" align="middle">
                          {item.icon}
                        </Row>
                      </Col>
                      <Col lg={18} md={18} sm={24} xs={24}>
                        <Row justify="space-between" align="middle">
                          <Space size={0} direction="vertical">
                            <Space direction="horizontal">
                              <Typography.Text className="statistic-title">
                                {item.title}
                              </Typography.Text>
                              <Popover
                                className="statistic-popover"
                                content={popoverContent({
                                  title: item.title,
                                  description: item.description
                                })}
                                trigger="hover"
                                placement="right"
                                arrowPointAtCenter
                              >
                                <InfoCircleFilled
                                  style={{
                                    color: '#fff'
                                  }}
                                  className="popover-icon"
                                />
                              </Popover>
                            </Space>
                            <Typography.Text className="statistic-value">
                              {item.value}
                            </Typography.Text>
                          </Space>
                        </Row>
                      </Col>
                      <div className="statistic-collapse-icon">
                        {selectedView === item.name ? (
                          <ChevronDown />
                        ) : (
                          <ChevronForward />
                        )}
                      </div>
                    </Row>
                  </Card>
                </Col>
              ))}
          </Row>

          <Divider />

          <div className="tab-header" style={{ marginBottom: '2em' }}>
            {!!selectedProviders.length ? (
              <Typography.Text
                style={{ fontSize: 16 }}
                className="hide-lg-tablet"
              >
                Displaying patients of{' '}
                {selectedProviders.length > 1 ? 'providers' : 'provider'}
                {' - '}
                <Typography.Link>
                  {selectedProviders[0].FirstName}{' '}
                  {selectedProviders[0].LastName}
                </Typography.Link>{' '}
                {selectedProviders.length > 1 && (
                  <Fragment>
                    and{' '}
                    <Typography.Link
                      underline
                      onClick={() => handleOpenFilterPatient()}
                    >
                      {selectedProviders.length - 1} more
                    </Typography.Link>
                  </Fragment>
                )}
              </Typography.Text>
            ) : (
              <div />
            )}
            <div className="tab-header-controls">
              <Input
                size="middle"
                placeholder="Search RTM patient name or email"
                prefix={<SearchOutlined />}
                value={searchTerm}
                onChange={handleInputSearch}
              />
              <Button
                className={`btn-default ${openSort && 'active'}`}
                icon={
                  <SwapVerticalOutline
                    style={{
                      height: 14,
                      position: 'relative',
                      top: 2
                    }}
                  />
                }
                onClick={() => setOpenSort(!openSort)}
              >
                Sort
              </Button>

              {rtm.hasRemaining ? (
                <Popconfirm
                  title={
                    <Typography.Text>
                      RTM patients are still being fetched.
                      <br />
                      Filtering by providers will cancel the ongoing process.
                      <br />
                      Do you want to proceed?
                    </Typography.Text>
                  }
                  okText="Proceed"
                  onConfirm={() => {
                    setRTM((prevState) => ({
                      ...prevState,
                      stopFetching: true
                    }));

                    handleOpenFilterPatient();
                  }}
                >
                  <Button className="btn-default" icon={<FilterOutlined />}>
                    Filter Providers
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  className="btn-default"
                  icon={<FilterOutlined />}
                  onClick={handleOpenFilterPatient}
                >
                  Filter Providers
                </Button>
              )}

              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item key="CARD" onClick={() => setView('CARD')}>
                      <AppstoreOutlined /> Card
                    </Menu.Item>

                    <Menu.Item key="LIST" onClick={() => setView('LIST')}>
                      <BarsOutlined /> List
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className="btn-default" icon={<PicCenterOutlined />}>
                  View as <CaretDownFilled />
                </Button>
              </Dropdown>
            </div>
          </div>
          {renderMobileFilteredProviders()}

          {openSort && (
            <SortPatientsContainer
              setOpenSort={setOpenSort}
              getSortItems={getSortItems}
            />
          )}

          {searchTerm && (
            <Typography.Text>
              Search results for:{' '}
              <Typography.Text strong>{searchTerm}</Typography.Text>
            </Typography.Text>
          )}

          <PatientsTable
            view={view}
            searchTerm={searchTerm}
            selectedView={selectedView}
            patients={getFilteredList(rtm.analytics)}
            visibleProfile={visibleProfile}
            openDrawer={(patient) =>
              handleOpenPatientDetail({
                patient
              })
            }
          />
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  patients: state.patients.analyticsData,
  admins: state.users.admins,
  loadingAnalyticsData: state.patients.loadingAnalyticsData
});

export default connect(mapStateToProps)(RTMDashboard);
