import React from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Dropdown, Menu, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import states from '../../../states';
import truncateText from '../../../utils/truncateText.utils';
import { getDiagnosisName } from '../../../utils/careplan';

const DiagnosisSelect = ({ loading, value, onChange }) => {
  const careplan = useRecoilValue(states.careplan);

  if (!careplan.programs.length || loading) {
    return (
      <Select
        loading={loading}
        disabled={loading}
        className="nps-reports-select"
        placeholder="Select Program"
      />
    );
  }

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          {careplan.programs.map((item, i) => (
            <Menu.SubMenu key={i} title={item.Program}>
              {item &&
                item.Diagnosis &&
                Array.isArray(item.Diagnosis) &&
                item.Diagnosis.map(subItem => (
                  <Menu.Item
                    key={subItem.Id}
                    onClick={() => onChange(subItem.Id)}
                  >
                    {subItem.Diagnosis}
                  </Menu.Item>
                ))}
            </Menu.SubMenu>
          ))}

          <Menu.Item
            key={careplan.programs.length}
            onClick={() => onChange('other')}
          >
            Other
          </Menu.Item>
        </Menu>
      }
    >
      <Button
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: 220,
          paddingLeft: 11,
          paddingRight: 11,
          borderRadius: 4
        }}
      >
        <div style={{ color: value ? '#000' : '#C0C0C0' }}>
          {value ? truncateText(getDiagnosisName(value), 21) : 'Select Program'}
        </div>

        <DownOutlined
          style={{ paddingTop: 4, fontSize: 12, color: '#C0C0C0' }}
        />
      </Button>
    </Dropdown>
  );
};

export default DiagnosisSelect;
