import { createAction } from 'redux-actions';
import { API_REQUEST } from '../../membership/middleware';

export const defaultUser = {
  active: true
};

export const GET_ADMINS = 'GET_ADMINS';
export const getAdmins = groupId => ({
  [API_REQUEST]: createAction(GET_ADMINS)({
    resource: `groups/${groupId}/users/group-admin?groupId=${groupId}`,
    method: 'GET'
  })
});

export const PT_NEW_USER_EMAIL = 'PT_NEW_USER_EMAIL';
export const newUserEmail = (EmailAddress, GroupId, groupName) => ({
  [API_REQUEST]: createAction(PT_NEW_USER_EMAIL)({
    resource: `groups/${groupName}/email`,
    method: 'POST',
    body: { EmailAddress: EmailAddress.toLowerCase(), GroupId: GroupId }
  })
});

export const SEARCH_USER = 'SEARCH_USER';
export const searchUser = (groupName, searchTerm) => ({
  [API_REQUEST]: createAction(SEARCH_USER)({
    resource: `groups/${groupName}/users?search=${searchTerm}&role=pt`,
    method: 'GET'
  })
});

export const GET_USERS = 'GET_USERS';
export const getUsers = groupName => ({
  [API_REQUEST]: createAction(GET_USERS)({
    resource: `groups/${groupName}/users?role=pt`,
    method: 'GET'
  })
});

export const GET_USER = 'GET_USER';
export const getUser = (groupId, emailAddress) => ({
  [API_REQUEST]: createAction(GET_USER)({
    resource: `groups/${groupId}/users?search=${encodeURIComponent(
      emailAddress
    )}&role=pt`,
    method: 'GET'
  })
});

export const POST_USER = 'POST_USER';
export const postUser = (groupId, user) => {
  return {
    [API_REQUEST]: createAction(POST_USER)({
      resource: `groups/${groupId}/users`,
      method: 'POST',
      body: { ...user, groupId: groupId, GroupId: groupId }
    })
  };
};

export const UPDATE_USER = 'UPDATE_USER';
export const updateUser = (groupName, user, sendEmail) => ({
  [API_REQUEST]: createAction(UPDATE_USER)({
    resource: `groups/${groupName}/therapists`,
    method: 'PUT',
    body: { ...user, sendEmail: sendEmail }
  })
});

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const updateUserProfile = (sub, preferences) => ({
  [API_REQUEST]: createAction(UPDATE_USER_PROFILE)({
    resource: `preferences`,
    method: 'PUT',
    body: { sub, preferences }
  })
});

export const UPDATE_CORNER_PHOTO = 'UPDATE_CORNER_PHOTO';
export const updateCornerPhoto = createAction(
  UPDATE_CORNER_PHOTO,
  values => values
);

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const updateCurrentUser = createAction(UPDATE_CURRENT_USER, values => ({
  values
}));

export const SET_USER_FORM_TYPE = 'SET_USER_FORM_TYPE';
export const setUserFormType = createAction(SET_USER_FORM_TYPE, formType => ({
  formType
}));

export const IS_LOADING = 'USERS_IS_LOADING';
export const isLoading = createAction(IS_LOADING);

export const DONE_LOADING = 'USERS_DONE_LOADING';
export const doneLoading = createAction(DONE_LOADING);

export const IS_SEARCHING = 'USERS_IS_SEARCHING';
export const isSearching = createAction(IS_SEARCHING);

export const DONE_SEARCHING = 'USERS_DONE_SEARCHING';

export const doneSearching = createAction(DONE_SEARCHING);

export const OPEN_MODAL = 'OPEN_MODAL';
export const openModal = createAction(OPEN_MODAL)();

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const closeModal = createAction(CLOSE_MODAL)();
