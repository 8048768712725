import { createAction } from 'redux-actions';
import { API_REQUEST } from '../../membership/middleware';

export const GET_VIDEO_ROOM = 'GET_VIDEO_ROOM';
export const getVideoRoom = (patient, therapist, role) => ({
  [API_REQUEST]: createAction(GET_VIDEO_ROOM)({
    resource: `twilio?patient=${patient}&therapist=${therapist}&role=${role}`,
    method: 'GET'
  })
});

export const NOTIFICATION_VIDEO_ROOM = 'NOTIFICATION_VIDEO_ROOM';
export const notificationVideoRoom = (contact, groupId, ptFname, ptLname) => ({
  [API_REQUEST]: createAction(NOTIFICATION_VIDEO_ROOM)({
    resource: `twilio`,
    method: 'POST',
    body: {
      EmailAddress: contact,
      GroupId: groupId,
      PTFirstName: ptFname,
      PTLastName: ptLname
    }
  })
});
