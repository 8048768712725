/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import { Row, Col, Drawer, notification } from 'antd';
import _, { debounce } from 'lodash';

import {
  calculateHalfChecked,
  filterByOnlyClinicExercises,
  filterExercises,
  mapTagsToFilters,
  exercisesListWithSelectedExercises,
  filterByOnlyMyExercises,
} from '../../utils/exercises.utils';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

import services from '../../services';
import states from '../../states';
import setNotification from '../../utils/setNotification.utils';

import ExerciseFilter from './ExerciseFilter';
import ExerciseAction from './ExerciseAction';
import ExerciseFilterTags from './ExerciseFilterTags';
import ExerciseListDisplay from './ExerciseListDisplay';
import ExerciseDetailsModal from '../ExerciseDetailsModal';
import AddTemplateModal from '../../pages/EditPrescription/PrescriptionExercises/AddTemplateModal';
import SelectionPanel from '../SelectionPanel';
import ExerciseFormModal from '../ExerciseFormModal';

const ExerciseList = ({
  visibleProfile,
  isEditing,
  selectedExercises,
  handleSelectExercise,
  handleAddTemplateModal,
  handleRemoveExerciseItem,
  handleRemoveAllExerciseItem,
  onDragEnd,
  addTemplateToSelectionPanel,
  fromTemplates = false,
}) => {
  const [filters, setFilters] = useRecoilState(states.filters);
  const [exercises, setExercises] = useRecoilState(states.exercises);
  const [user, setUser] = useRecoilState(states.user);

  const [currentView, setCurrentView] = useState('card');
  const [loading, setLoading] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [favoritesSelected, setFavoritesSelected] = useState(false);
  const [clinicExercisesSelected, setClinicExercisesSelected] = useState(false);
  const [myExercisesSelected, setMyExercisesSelected] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState({
    checked: [],
    halfChecked: [],
  });
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [tags, setTags] = useState([]);
  const [exerciseFormModal, setExerciseFormModal] = useState(false);
  const [displaySelectionPanel, setDisplaySelectionPanel] = useState(false);

  const setModals = useSetRecoilState(states.modal);
  const favorites = useRecoilValue(states.favorites);

  let exercisesList = [];

  useEffect(() => {
    setDisplayFilter(filters?.ShowExerciseTray ?? true);
    initData();

    if (!visibleProfile?.FavoriteId) {
      assignFavoriteId();
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const initData = async () => {
    try {
      setLoading(true);

      const { Sub } = visibleProfile;
      const [exercises, parameter] = await Promise.all([
        services.exercises.getCustomExercises(Sub),
        services.exercises.getCustomParameter(Sub),
      ]);

      const controller = new AbortController();
      const options = {
        abortSignal: controller.signal,
      };

      const { GroupId } = visibleProfile;
      const response = await services.exercises.searchExercises(
        GroupId,
        null,
        null,
        options
      );

      setExercises(prevState => ({
        ...prevState,
        custom: {
          exercises: exercises.data,
          parameter: parameter.data,
        },
        unmapped: response.data.exercises,
        list: [...exercises.data, ...response.data.exercises].map(ex => {
          const exData = toCamelCaseObjKeys(ex);
          const params = parameter.data.find(p => p.ExerciseId === exData.id);

          if (!params) {
            return exData;
          }

          return {
            ...exData,
            exerciseName: params.ExerciseName,
            instructions: params.Instructions || '',
            sets: params.Sets || '',
            reps: params.Reps || '',
            hold: params.Hold || '',
          };
        }),
      }));
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'An error occurred while fetching exercises.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDisplayFilter = async displayFilter => {
    setDisplayFilter(displayFilter);

    const { Sub, GroupId } = visibleProfile;
    const payload = {
      ...filters,
      ShowExerciseTray: displayFilter,
    };

    if (!filters) {
      payload.Sub = Sub;
      payload.GroupId = GroupId;
    }

    await services.filters.addFilters(payload).then(res => {
      if (res.status === 200) {
        setFilters(prevState => ({
          ...prevState,
          ...res.data,
        }));
      }
    });
  };

  const handleSelectionPanel = () => {
    setDisplaySelectionPanel(prevState => !prevState);
  };

  const assignFavoriteId = async () => {
    try {
      const { Sub } = visibleProfile;
      const response = await services.exercises.addFavoriteId(Sub);

      if (response.status === 200) {
        setUser({
          ...user,
          details: {
            emailAddress: visibleProfile.EmailAddress,
            sub: Sub,
            role: visibleProfile.Role,
            firstName: visibleProfile.FirstName,
            lastName: visibleProfile.LastName,
            active: visibleProfile.Active,
            clinic: visibleProfile.Clinic,
            billingRole: visibleProfile.BillingRole,
            favoriteId: response.data.id,
            preferences: {
              ...toCamelCaseObjKeys(visibleProfile.Preferences),
            },
          },
        });
      }
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while assigning favorite ID.'
      );
    }
  };

  const searchExercises = async (term, tags) => {
    try {
      setLoading(true);

      const searchInput = term || null;
      const filterTags = mapTagsToFilters(tags);

      const controller = new AbortController();
      const options = {
        abortSignal: controller.signal,
      };

      const { GroupId } = visibleProfile;
      const response = await services.exercises.searchExercises(
        GroupId,
        searchInput,
        filterTags,
        options
      );

      const dataArr = [...response.data.exercises];

      if (searchInput && !dataArr.length) {
        const input = searchInput.trim().toLowerCase();
        const exState = getRecoil(states.exercises);
        const customParams = exState.custom.parameter.filter(param =>
          param.ExerciseName.trim().toLowerCase().includes(input)
        );

        if (customParams.length) {
          await Promise.all(
            customParams.map(param =>
              services.exercises.getExerciseDetails(param.ExerciseId)
            )
          ).then(res => {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i]) {
                dataArr.push(res.data[i]);
              }
            }
          });
        }
      }

      setExercises(prevState => {
        const customs = [...prevState.custom.exercises].filter(ex => {
          if (!searchInput && !filterTags.length) {
            return true;
          }

          const input = searchInput?.trim().toLowerCase();
          const name = ex.ExerciseName?.trim().toLowerCase();
          const inSearch = name.includes(input);

          const tags = ex?.Tag?.split(',').map(t => t?.trim());
          const qryTag = filterTags.map(f => f?.toLowerCase());
          const inTags = tags?.some(t => qryTag.includes(t?.toLowerCase()));

          return inSearch || inTags;
        });

        const results = [
          ...customs.map(ex => toCamelCaseObjKeys(ex)),
          ...dataArr.map(ex => toCamelCaseObjKeys(ex)),
        ].map(item => {
          const exercise = {
            ...item,
          };

          delete exercise.cPTCode;
          return exercise;
        });

        return {
          ...prevState,
          unmapped: results,
          list: results.map(item => {
            const parameters = [...prevState.custom.parameter];
            const exData = toCamelCaseObjKeys(item);
            const params = parameters.find(p => p.ExerciseId === exData.id);

            if (!params) {
              return exData;
            }

            return {
              ...exData,
              exerciseName: params.ExerciseName,
              instructions: params.Instructions || '',
              sets: params.Sets || '',
              reps: params.Reps || '',
              hold: params.Hold || '',
            };
          }),
        };
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'An error occurred while fetching exercises.',
      });
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useRef(
    debounce(async (value, tags) => {
      await searchExercises(value, tags);
    }, 500)
  ).current;

  const handleSearch = value => {
    setSearchTerm(value);
    debouncedSearch(value, tags);
  };

  const handleExerciseDetailsModal = exerciseDetails => {
    setModals(prevState => ({
      ...prevState,
      exerciseDetails: !prevState.exerciseDetails,
    }));

    setExercises(prevState => ({
      ...prevState,
      details: exerciseDetails,
    }));
  };

  const inputTags = values => {
    setTags(values);
    debouncedSearch(searchTerm, values);
  };

  const removeTag = tag => {
    const keysCopy = checkedKeys.checked.slice();
    const halfChecked = calculateHalfChecked(keysCopy);

    _.remove(tags, item => item.key === tag.key);
    _.pull(keysCopy, tag.key);

    setTags(tags);
    setCheckedKeys({
      checked: keysCopy,
      halfChecked: halfChecked,
    });

    debouncedSearch(searchTerm, tags);
  };

  const removeAllTags = () => {
    setTags([]);
    setCheckedKeys({
      checked: [],
      halfChecked: [],
    });

    debouncedSearch(searchTerm, []);
  };

  exercisesList = filterExercises(
    exercises.list,
    searchTerm,
    favorites.list,
    favoritesSelected,
    tags
  );

  exercisesList = exercisesListWithSelectedExercises(
    exercisesList,
    visibleProfile.GroupId,
    selectedExercises,
    exercises.custom.exercises
  );

  exercisesList = filterByOnlyClinicExercises(
    clinicExercisesSelected,
    exercisesList
  );

  exercisesList = filterByOnlyMyExercises(
    myExercisesSelected,
    exercisesList,
    exercises.custom.exercises
  );

  return (
    <React.Fragment>
      <ExerciseFormModal
        isOpen={exerciseFormModal}
        onCancel={() => setExerciseFormModal(false)}
        selectedExercises={selectedExercises}
        fromTemplates={fromTemplates}
      />
      <AddTemplateModal
        addTemplateToSelectionPanel={addTemplateToSelectionPanel}
      />
      <ExerciseDetailsModal
        handleExerciseDetailsModal={handleExerciseDetailsModal}
      />
      <SelectionPanelDrawer
        isOpen={displaySelectionPanel}
        handleClose={handleSelectionPanel}
        selectedExercises={selectedExercises}
        handleRemoveExerciseItem={handleRemoveExerciseItem}
        handleOpenExerciseModal={() => setExerciseFormModal(true)}
        handleAddTemplateModal={handleAddTemplateModal}
        handleRemoveAllExerciseItem={handleRemoveAllExerciseItem}
        onDragEnd={onDragEnd}
      />
      <Row gutter={[24, 0]} className="mb-4">
        {displayFilter && (
          <Col xl={4} lg={5} md={7} sm={24} xs={24}>
            {/* filter drawer */}
            <ExerciseFilter
              type="dt"
              setFavoritesSelected={setFavoritesSelected}
              setClinicExercisesSelected={setClinicExercisesSelected}
              setMyExercisesSelected={setMyExercisesSelected}
              checkedKeys={checkedKeys}
              setCheckedKeys={setCheckedKeys}
              expandedKeys={expandedKeys}
              setExpandedKeys={setExpandedKeys}
              tags={tags}
              setTags={inputTags}
              removeTag={removeTag}
            />
          </Col>
        )}
        <Col
          xl={displayFilter ? 20 : 24}
          lg={displayFilter ? 19 : 24}
          md={displayFilter ? 17 : 24}
          sm={24}
          xs={24}
        >
          <Row gutter={[16, 16]}>
            <Col
              lg={isEditing ? 17 : 24}
              md={isEditing ? 13 : 24}
              sm={24}
              xs={24}
            >
              {/* search field, filter and view toggles */}
              <ExerciseAction
                isEditing={isEditing}
                handleSearch={handleSearch}
                searchTerm={searchTerm}
                displayFilter={displayFilter}
                handleDisplayFilter={handleDisplayFilter}
                currentView={currentView}
                setCurrentView={setCurrentView}
                handleSelectionPanel={handleSelectionPanel}
              />
              {/* filter tags */}
              <ExerciseFilterTags
                tags={tags}
                removeTag={removeTag}
                removeAllTags={removeAllTags}
              />
              {displayFilter && (
                <ExerciseFilter
                  type="mb"
                  setFavoritesSelected={setFavoritesSelected}
                  setClinicExercisesSelected={setClinicExercisesSelected}
                  setMyExercisesSelected={setMyExercisesSelected}
                  checkedKeys={checkedKeys}
                  setCheckedKeys={setCheckedKeys}
                  expandedKeys={expandedKeys}
                  setExpandedKeys={setExpandedKeys}
                  tags={tags}
                  setTags={inputTags}
                  removeTag={removeTag}
                />
              )}
              {/* exercises list display */}
              <ExerciseListDisplay
                loading={loading}
                currentView={currentView}
                favorites={favorites}
                favoritesSelected={favoritesSelected}
                exercisesList={exercisesList}
                handleSelectExercise={handleSelectExercise}
                handleExerciseDetailsModal={handleExerciseDetailsModal}
              />
            </Col>
            {isEditing && (
              <Col lg={7} md={11} sm={0} xs={0} className="hide-sm-tablet">
                {/* prescription exercises list */}
                <SelectionPanel
                  selectedExercises={selectedExercises}
                  handleOpenExerciseModal={() => setExerciseFormModal(true)}
                  handleAddTemplateModal={handleAddTemplateModal}
                  handleRemoveAllExerciseItem={handleRemoveAllExerciseItem}
                  handleRemoveExerciseItem={handleRemoveExerciseItem}
                  onDragEnd={onDragEnd}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const SelectionPanelDrawer = ({
  isOpen,
  handleClose,
  selectedExercises,
  handleOpenExerciseModal,
  handleAddTemplateModal,
  handleRemoveAllExerciseItem,
  handleRemoveExerciseItem,
  onDragEnd,
}) => {
  return (
    <Drawer
      destroyOnClose
      placement="right"
      visible={isOpen}
      bodyStyle={{
        padding: 0,
      }}
      width="100%"
      className="hide-dt show-lg-tablet"
      onClose={handleClose}
    >
      <SelectionPanel
        selectedExercises={selectedExercises}
        handleOpenExerciseModal={handleOpenExerciseModal}
        handleAddTemplateModal={handleAddTemplateModal}
        handleRemoveAllExerciseItem={handleRemoveAllExerciseItem}
        handleRemoveExerciseItem={handleRemoveExerciseItem}
        onDragEnd={onDragEnd}
      />
    </Drawer>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
  formType: state.groupExercises.formType,
});

export default connect(mapStateToProps)(ExerciseList);
