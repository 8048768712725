import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { editCustomizations, getCustomizations } from '../actions/groups';
import { Alert, Collapse, Typography } from 'antd';
import Loading from '../../../components/Loading';
// Forms
import IntroMessageForm from './IntroMessageForm';
import IntroVideoForm from './IntroVideoForm';
import CustomizableButtonsForm from './CustomizableButtonsForm';
import CustomHomeContentForm from './CustomHomeContentForm';
import RewardsForm from './RewardsForm';
import setNotification from '../../../utils/setNotification.utils';

//Events for backend
const EDIT_BUTTONS = 'EDIT_BUTTONS',
  EDIT_INTRO_MESSAGE = 'EDIT_INTRO_MESSAGE',
  EDIT_CUSTOM_CONTENT = 'EDIT_CUSTOM_CONTENT',
  EDIT_REWARDS = 'EDIT_REWARDS',
  EDIT_VIDEO = 'EDIT_VIDEO';

function AppCustomization(props) {
  const { groupId, groupName, dispatch } = props;

  const [pageLoading, setPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // states
  const [introText, setIntroText] = useState('');
  const [introVideoUrl, setIntroVideoUrl] = useState('');
  const [buttons, setButtons] = useState([]);
  const [customContent, setCustomContent] = useState('');
  const [rewardItem, setRewardItem] = useState({
    MetricType: '',
    MetricTarget: '',
    Color: '',
    CompletionTop: '',
    CompletionBottom: '',
    ContentTop: '',
    ContentBottom: '',
    FacebookLink: '',
    GoogleLink: '',
    ImageLocation: ''
  });

  useEffect(() => {
    fetchCustomizationData();
  }, []);

  //get saved information
  const fetchCustomizationData = async () => {
    setPageLoading(true);

    if (groupId) {
      try {
        const res = await dispatch(getCustomizations(groupId));
        const Item = res.action.payload.Item;
        if (Item) {
          const buttonList = Object.keys(Item)
            .map(key => Item[key])
            .filter(item => typeof item === 'object' && !Array.isArray(item));

          Item.IntroText && setIntroText(Item.IntroText);
          Item.IntroVideoUrl && setIntroVideoUrl(Item.IntroVideoUrl);
          buttonList.length > 0 && setButtons(buttonList);
          Item.Rewards && setRewardItem(Item.Rewards[0]);
          Item.CustomContent && setCustomContent(Item.CustomContent);
        }

        setPageLoading(false);
      } catch (err) {
        console.log('Err', err);
        setPageLoading(false);
        setNotification(
          'error',
          'Error!',
          'An error occurred while fetching costumization data.'
        );
      }
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
        setErrorMessage(null);
      }, 5000);
    }
  }, [error]);

  // handle form submit functions
  const handleSubmitCustomization = async (event, values) => {
    let requestBody;

    if (event === EDIT_REWARDS) {
      requestBody = { ...values, groupId, groupName, enrolled: true };
    } else {
      requestBody = { ...values, groupId, customizationEvent: event };
    }

    setIsLoading(true);
    try {
      const response = await dispatch(editCustomizations(event, requestBody));

      if (response) {
        setIsLoading(false);
        setNotification('success', 'Success!', 'Changes has been saved.');
      }
    } catch (err) {
      console.log(err.message);
      setIsLoading(false);
      setNotification(
        'error',
        'Error!',
        'An error occurred while saving data.'
      );
    }
  };

  return (
    <div>
      {error && <Alert message={errorMessage} type="error" />}
      {pageLoading ? (
        <Loading isLoading={pageLoading}>
          <Typography.Text>Loading...</Typography.Text>
        </Loading>
      ) : (
        <Collapse accordion defaultActiveKey={['1']}>
          <Collapse.Panel header="Set Home Page Announcement" key="1">
            <CustomHomeContentForm
              customContent={customContent}
              setCustomContent={setCustomContent}
              event={EDIT_CUSTOM_CONTENT}
              handleSubmitCustomization={handleSubmitCustomization}
              isLoading={isLoading}
            />
          </Collapse.Panel>
          <Collapse.Panel header="Set Intro Pop-up Message" key="2">
            <IntroMessageForm
              introText={introText}
              setIntroText={setIntroText}
              event={EDIT_INTRO_MESSAGE}
              handleSubmitCustomization={handleSubmitCustomization}
              isLoading={isLoading}
            />
          </Collapse.Panel>
          <Collapse.Panel header="Set Intro Video" key="3">
            <IntroVideoForm
              introVideoUrl={introVideoUrl}
              setIntroVideoUrl={setIntroVideoUrl}
              event={EDIT_VIDEO}
              handleSubmitCustomization={handleSubmitCustomization}
              isLoading={isLoading}
            />
          </Collapse.Panel>
          <Collapse.Panel header="Set Customizable Buttons" key="4">
            {Array.isArray(buttons) && (
              <CustomizableButtonsForm
                buttons={buttons}
                event={EDIT_BUTTONS}
                handleSubmitCustomization={handleSubmitCustomization}
                isLoading={isLoading}
              />
            )}
          </Collapse.Panel>
          <Collapse.Panel header="Create Rewards" key="5">
            <RewardsForm
              rewardItem={rewardItem}
              setRewardItem={setRewardItem}
              event={EDIT_REWARDS}
              handleSubmitCustomization={handleSubmitCustomization}
              isLoading={isLoading}
            />
          </Collapse.Panel>
        </Collapse>
      )}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    groupName: state.groups.currentGroup.name,
    groupId: state.groups.currentGroup.id
  };
}

export default connect(mapStateToProps)(AppCustomization);
