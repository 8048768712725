import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { submit, isDirty } from 'redux-form';
import { v1 as uuid } from 'uuid';

import {
  selectPrescriptionExercise,
  deselectPrescriptionExercise,
  clearPrescriptionExercises,
  searchExercises,
  resetSearchResults,
} from './actions/prescription';
import {
  selectedExercisesSelector,
  cookedSearchResultsSelector,
  formSaveEnabled,
} from './selectors';
import { currentUserGroupSelector } from '../exercise-library/selectors';
import CustomExerciseForm from './CustomExerciseForm';
import { ignoredKeys, keyValueMap } from './Filters';
import _ from 'lodash';
import filterExercises, {
  clearSelected,
  filterByOnlyClinicExercises,
  parseFavorites,
} from './lib/filterExercises';
import {
  getFavorites,
  postFavorites,
  putFavorites,
  deleteFavorites,
} from './actions/patients';
import setNotification from '../../utils/setNotification.utils';
import PTModal from '../groups/group-admin/components/PTModal';
import ExerciseDetailModal from './Modals/ExerciseDetailModal';
import TemplateModal from './ExerciseTemplates/TemplateModal';
import SelectionPanel from '../../components/SelectionPanel';
import ExerciseList from '../../components/ExerciseList';
import { getRecoil, setRecoil } from 'recoil-nexus';
import states from '../../states';

export const randomId = () => Math.floor(Math.random() * 0x10000).toString(16);

class PatientExercises extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templateModalOpen: false,
      pending: null,
      searchTermPresent: false,
      searchTerm: 'NONE',
      currentFilter: null,
      showGroup: true,
      showPtw: true,
      tags: [],
      checkedKeys: { checked: [], halfChecked: [] },
      window: window.innerWidth,
      expandedKeys: [],
      _isMounted: false,
      showExerciseDetailModal: false,
      modalExercise: null,
      favorites: [],
      favoritesCount: 0,
      favoriteId: null,
      favoritesSelected: false,
      filteredExercises: [],
      clinicExercises: [],
      clinicExercisesSelected: false,
      showCustomExerciseModal: false,
    };

    this.handleExerciseCardClick = this.handleExerciseCardClick.bind(this);
    this.handleExerciseTitleClick = this.handleExerciseTitleClick.bind(this);
    this.handleAddCustomExercise = this.handleAddCustomExercise.bind(this);
    this.handleAddCustomExerciseFormClose =
      this.handleAddCustomExerciseFormClose.bind(this);
    this.dispatchQuery = this.dispatchQuery.bind(this);
    this.handleRemoveCard = this.handleRemoveCard.bind(this);
    this.handleClearAllCards = this.handleClearAllCards.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onClear = this.onClear.bind(this);
    this.openTemplateModal = this.openTemplateModal.bind(this);
    this.handleShowCustomExerciseModal =
      this.handleShowCustomExerciseModal.bind(this);
  }

  componentDidMount() {
    const { dispatch, searchResults } = this.props;

    dispatch(resetSearchResults(), this.dispatchQuery());
    this.handleShowCustomExerciseModal(false);

    const clinicExercises = searchResults.filter(
      result => result.isGroupExercise === true
    );

    this.setState({ clinicExercises });
  }

  openTemplateModal() {
    const { templateModalOpen } = this.state;

    const newState = templateModalOpen ? false : true;
    this.setState({ templateModalOpen: newState });
  }

  dispatchQuery() {
    const { searchTerm, tags } = this.state;
    const {
      dispatch,
      visibleProfile: { GroupId },
    } = this.props;

    if (this.state.pending) {
      clearTimeout(this.state.pending);
    }

    const mappedTags = this.mapTagsToFilters(tags);

    this.setState({
      searchTermPresent: !!searchTerm,
      pending: setTimeout(() => {
        dispatch(searchExercises(GroupId, searchTerm, mappedTags, GroupId));
      }, 300),
    });
  }

  handleShowCustomExerciseModal(val) {
    this.setState({
      showCustomExerciseModal: val,
    });
  }

  mapTagsToFilters = tags => {
    return tags.map(item => {
      if (item.value) {
        return item.value;
      } else {
        return item.title;
      }
    });
  };

  onUpdate(event) {
    const value = event.target.value;
    const filter = this.state.tags;

    this.setState({ searchTerm: value }, () => {
      if ((!value || value.length < 2) && filter === null) {
        return;
      }
      this.dispatchQuery();
    });
  }

  onClear() {
    const { dispatch } = this.props;
    dispatch(
      resetSearchResults(),
      this.setState({ searchTerm: null, searchTermPresent: false }),
      this.dispatchQuery()
    );
  }

  mapExerciseToSelected(exercise) {
    const newObj = {};
    newObj.instructions = exercise.instructions;
    newObj.exerciseName = exercise.exerciseName;
    newObj.reps = exercise.reps;
    newObj.sets = exercise.sets;
    newObj.groupId = exercise.groupId;
    newObj.completionDates = [];
    newObj.id = exercise.id;
    newObj.video = exercise.video;
    newObj.tag = exercise.tag;
    newObj.hold = exercise.hold;

    return newObj;
  }

  handleAddCustomExercise(values) {
    const { dispatch } = this.props;
    dispatch(
      selectPrescriptionExercise({ ...values, custom: true, id: uuid() })
    );
    this.handleShowCustomExerciseModal(false);
  }

  handleAddCustomExerciseFormClose() {
    const { addCustomExerciseFormDirty } = this.props;
    const confirmMessage =
      'You currently have unsaved changes. Are you sure you want to discard your changes?';

    if (addCustomExerciseFormDirty && !window.confirm(confirmMessage)) {
      return;
    }
    this.handleShowCustomExerciseModal(false);
  }

  handleRemoveCard(index) {
    const { dispatch, selectedExercises } = this.props;
    const { favorites } = this.state;
    const exercise = selectedExercises[index];

    if (exercise && exercise.star) {
      const { id } = exercise;
      for (let i = 0; i < favorites.length; i++) {
        if (favorites[i].id === id) {
          favorites[i].isSelected = false;
        }
      }
      this.setState({ favorites: favorites });
    }

    return () => {
      dispatch(deselectPrescriptionExercise(index));
    };
  }

  handleClearAllCards() {
    const { dispatch } = this.props;
    const { filteredExercises, favorites } = this.state;

    this.setState({
      filteredExercises: clearSelected(filteredExercises),
      favorites: clearSelected(favorites),
    });

    dispatch(clearPrescriptionExercises());
  }

  toggleMyExercises = e => {
    e.preventDefault();
    const { showGroup } = this.state;

    this.setState({ showGroup: !showGroup });
  };

  togglePtwExercises = e => {
    e.preventDefault();
    const { showPtw } = this.state;

    this.setState({ showPtw: !showPtw });
  };

  clickFilter = (selectedKeys, e) => {
    if (e.checkedNodes.length === 0) {
      return this.setState({
        tags: [],
        checkedKeys: { checked: [], halfChecked: [] },
      });
    }

    const nodes = e.checkedNodes;
    this.addTags(nodes, e);
    this.checkKeys(nodes, e);
  };

  removeTag = tag => {
    const { tags, checkedKeys } = this.state;

    const keysCopy = checkedKeys.checked.slice();

    _.remove(tags, item => {
      return item.key === tag.key;
    });

    _.pull(keysCopy, tag.key);

    const halfChecked = this.calculateHalfChecked(keysCopy);

    this.setState(
      {
        tags: tags,
        checkedKeys: { checked: keysCopy, halfChecked: halfChecked },
      },
      () => {
        this.dispatchQuery();
      }
    );
  };

  checkKeys = (nodes, e) => {
    const checkedKeys = this.state.checkedKeys.checked;

    if (e.checked) {
      let newCheckedKeys = _.concat([], checkedKeys);

      nodes.map(node => {
        return newCheckedKeys.push(node.key);
      });
      const halfChecked = this.calculateHalfChecked(newCheckedKeys);
      newCheckedKeys = _.uniq(newCheckedKeys);
      this.setState({
        checkedKeys: { checked: newCheckedKeys, halfChecked: halfChecked },
      });
      return;
    }

    const item = e.node.props.eventKey;
    const removed = _.without(checkedKeys, item);

    const halfChecked = this.calculateHalfChecked(removed);

    this.setState({
      checkedKeys: { checked: removed, halfChecked: halfChecked },
    });
  };

  createTag = node => {
    const value = keyValueMap[node.key];
    return { title: node.title, key: node.key, value: value ? value : null };
  };

  addTags = (nodes, e) => {
    const { tags } = this.state;

    if (e.checked) {
      let newTags = _.concat([], tags);
      nodes.map(node => {
        const isFound = ignoredKeys.find(key => {
          return node.key === key;
        });
        if (isFound) {
          return node;
        }
        return newTags.push(this.createTag(node));
      });

      newTags = _.uniqBy(newTags, 'key');

      this.setState({ tags: newTags }, () => {
        this.dispatchQuery();
      });
    } else {
      const item = e.node.props.eventKey;

      const filteredTags = _.filter(tags, object => {
        return object.key !== item;
      });

      this.setState({ tags: filteredTags }, () => {
        this.dispatchQuery();
      });
    }
  };

  getParents = key => {
    const parents = [];

    for (let i = key.length; i > 3; i--) {
      if (i % 2 === 0) continue;
      parents.push(key.slice(0, i - 2));
    }

    return parents;
  };

  expandKeys = nodes => {
    let keys = [];

    nodes.map(node => {
      keys.push(node.key);
      const parents = this.getParents(node.key);
      keys = _.concat(parents, keys);
      return node;
    });

    this.setState({ expandedKeys: _.uniq(keys) });
  };

  clickLeaf = (selectedKeys, e) => {
    const selected = e.selected;

    if (selected) {
      e.checked = true;
      this.addTags(e.selectedNodes, e);
      this.expandKeys(e.selectedNodes, e);
      this.checkKeys(e.selectedNodes, e);
    } else {
      e.checked = false;
      // const {title, eventKey} = e.node;

      e.node.key = e.node.eventKey;
      const uncheckedNode = this.createTag(e.node);
      uncheckedNode.key = e.node.props.eventKey;
      this.removeTag(uncheckedNode);
    }
  };

  onExpand = (index, item) => {
    const key = item.node.props.eventKey;
    const parents = this.getParents(key);
    let keys = _.concat(parents, key);

    if (item.expanded) {
      this.setState({ expandedKeys: _.uniq(keys) });
    } else {
      this.setState({ expandedKeys: index });
    }
  };

  clearTags = () => {
    this.setState(
      { tags: [], checkedKeys: { checked: [], halfChecked: [] } },
      () => {
        this.dispatchQuery();
      }
    );
  };

  calculateHalfChecked = keys => {
    let halfChecked = [];
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const parents = this.getParents(key);
      halfChecked = halfChecked.concat(parents);
    }
    return halfChecked;
  };

  handleClickFavorite = id => {
    const { favorites } = this.state;

    for (let i = 0; i < favorites.length; i++) {
      const favorite = favorites[i];

      if (favorite.id === id) {
        favorites[i].isSelected = !favorites[i].isSelected;
      }
    }
    this.setState({ favorites: favorites });
  };

  handleExerciseCardClick(exercise) {
    const { dispatch, selectedExercises } = this.props;
    const { star, id, isSelected } = exercise;

    if (star) {
      this.handleClickFavorite(id);
    }

    if (isSelected) {
      const index = selectedExercises.findIndex(
        item => item.exerciseName === exercise.exerciseName
      );
      dispatch(deselectPrescriptionExercise(index));
    } else {
      dispatch(selectPrescriptionExercise(exercise));
    }
  }

  handleExerciseTitleClick(exercise) {
    this.setState({ showExerciseDetailModal: true, modalExercise: exercise });
  }

  closeExerciseDetailModal = () => {
    this.setState({ showExerciseDetailModal: false, modalExercise: null });
  };

  checkSelected = favorites => {
    if (!favorites || favorites.length === 0) {
      return [];
    }

    const { selectedExercises } = this.props;
    const updated = [...favorites];

    updated.forEach(f => (f.isSelected = false));

    for (let i = 0; i < selectedExercises.length; i++) {
      const selected = selectedExercises[i];

      for (let j = 0; j < favorites.length; j++) {
        const favorite = favorites[j];

        if (selected.id === favorite.id) {
          updated[j].isSelected = true;
        }
      }
    }
    return updated;
  };

  addFavorite = ex => {
    const { dispatch } = this.props;
    const { favoriteId } = this.state;
    const { id, groupId } = ex;

    dispatch(putFavorites(favoriteId, [{ Id: id, GroupId: groupId }])).then(
      response => {
        const { exercises } = response.value;
        const updated = this.checkSelected(parseFavorites(exercises));
        this.setState({ favorites: updated });
      }
    );
    setNotification(
      'success',
      'Favorite Added!',
      'Exercise has been added to favorites.'
    );
    this.closeExerciseDetailModal();
  };

  deleteFavorite = ex => {
    const { dispatch } = this.props;
    const { favoriteId } = this.state;
    const { id } = ex;

    dispatch(deleteFavorites(favoriteId, id)).then(response => {
      const { exercises } = response.value;

      this.setState({
        favorites: this.checkSelected(parseFavorites(exercises)),
      });
      setNotification(
        'success',
        'Favorite Removed!',
        'Exercise has been removed to favorites.'
      );
      this.closeExerciseDetailModal();
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { searchResults, selectedExercises } = this.props;

    const {
      tags,
      favorites,
      favoritesSelected,
      searchTerm,
      clinicExercisesSelected,
    } = this.state;
    const {
      searchResults: prevSearchResults,
      selectedExercises: prevSelectedExercises,
    } = prevProps;
    const {
      tags: prevTags,
      favorites: prevFavorites,
      favoritesSelected: prevFavoritesSelected,
      searchTerm: prevSearchTerm,
      clinicExercisesSelected: prevClinicExercisesSelected,
    } = prevState;

    if (prevFavorites !== favorites) {
      this.setState({ favoritesCount: favorites.length });
    }

    /**
     * Re-render exercises on state change
     */
    if (
      prevSearchResults !== searchResults ||
      prevTags !== tags ||
      prevFavorites !== favorites ||
      prevFavoritesSelected !== favoritesSelected ||
      prevSearchTerm !== searchTerm ||
      prevSelectedExercises !== selectedExercises ||
      prevClinicExercisesSelected !== clinicExercisesSelected
    ) {
      let filteredExercises = filterExercises(
        searchResults,
        searchTerm,
        favorites,
        favoritesSelected,
        tags
      );
      filteredExercises = filterByOnlyClinicExercises(
        clinicExercisesSelected,
        filteredExercises
      );
      this.setState({ filteredExercises: filteredExercises });
    }
  }

  render() {
    const {
      dispatch,
      selectedExercises,
      fromTemplate,
      setTemplateData,
      addCustomExerciseFormSaveEnabled,
    } = this.props;
    const {
      templateModalOpen,
      showExerciseDetailModal,
      modalExercise,
      favorites,
      favoritesCount,
      showCustomExerciseModal,
    } = this.state;

    return (
      <Fragment>
        {templateModalOpen && (
          <TemplateModal
            closeFunction={this.openTemplateModal}
            setTemplateData={setTemplateData}
          />
        )}
        {showExerciseDetailModal && (
          <ExerciseDetailModal
            currentExercise={modalExercise}
            closeModal={this.closeExerciseDetailModal}
            addFavorite={this.addFavorite}
            deleteFavorite={this.deleteFavorite}
            favorites={favorites}
            favoritesCount={favoritesCount}
          />
        )}
        {showCustomExerciseModal && (
          <PTModal
            header="Write-In Exercise"
            showModal={showCustomExerciseModal}
            close={this.handleAddCustomExerciseFormClose}
            save={() => dispatch(submit('prescriptionCustomExercise'))}
            saveEnabled={addCustomExerciseFormSaveEnabled}
            width={500}
          >
            <CustomExerciseForm onSubmit={this.handleAddCustomExercise} />
          </PTModal>
        )}
        <Row gutter={[16, 16]}>
          <Col md={fromTemplate ? 19 : 24} lg={fromTemplate ? 19 : 24}>
            <ExerciseList
              selectedExercises={[]}
              handleSelectExercise={
                fromTemplate && this.handleExerciseCardClick
              }
            />
          </Col>
          {fromTemplate && (
            <Col md={5} lg={5} className="sticky-panel">
              <SelectionPanel
                selectedExercises={selectedExercises}
                handleAddTemplateModal={this.openTemplateModal}
                handleRemoveExerciseItem={this.handleRemoveCard}
                handleRemoveAllExerciseItem={this.handleClearAllCards}
              />
            </Col>
          )}
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  searchResults: cookedSearchResultsSelector(state),
  selectedExercises: selectedExercisesSelector(state),
  removedExercises: state.patients.removedExercises,
  exerciseDetailModalOpen: state.patients.exerciseDetailModalOpen,
  addCustomExerciseModalOpen: state.patients.addCustomExerciseModalOpen,
  currentExercise: state.patients.currentModalExerciseDetail,
  group: currentUserGroupSelector(state),
  addCustomExerciseFormSaveEnabled: formSaveEnabled(
    'prescriptionCustomExercise'
  )(state),
  addCustomExerciseFormDirty: isDirty('prescriptionCustomExercise')(state),
  isSearching: state.patients.isSearching,
  currentUserGroup: currentUserGroupSelector(state),
  doneSearching: state.patients.doneSearching,
  visibleProfile: state.visibleProfile,
});

export default connect(mapStateToProps)(PatientExercises);
