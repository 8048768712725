import React from 'react';
import { Row, Col, Card, Typography, Divider, Button } from 'antd';
import { connect } from 'react-redux';
import defaultLogo from '../img/default-logo.png';
import { logoff } from './DispatchLogoutItem';
import { push } from 'react-router-redux';
import { requestDeauth } from '../features/membership/actions';
import { actions as idleActions } from '../features/idle-monitor';
import { bindActionCreators } from 'redux';

const ForbiddenPage = props => {
  const { design, dispatch } = props;

  const config = bindActionCreators(
    {
      visit: push,
      requestDeauth,
      stopIdleTimer: idleActions.stop
    },
    dispatch
  );

  return (
    <div className="container">
      <Row gutter={0}>
        <Col lg={6} md={6} xs={1} />
        <Col lg={12} md={12} xs={22}>
          <Card bordered={false} className="ptw-card">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '1em'
              }}
            >
              {design.brandType === 'logo' ? (
                <img
                  src={design.logoUrl}
                  alt="logo"
                  style={{
                    height: '6em',
                    width: 'auto'
                  }}
                />
              ) : (
                <img
                  src={defaultLogo}
                  alt="logo"
                  style={{
                    height: '6em',
                    width: 'auto'
                  }}
                />
              )}
            </div>

            <Divider />
            <Typography.Title type="secondary" className="text-403 text-center">
              403
            </Typography.Title>
            <Typography.Title level={2} className="text-center">
              Access Denied
            </Typography.Title>
            <Typography.Paragraph className="text-center">
              You are attempting to access with an account from a group that has
              been deactivated.
            </Typography.Paragraph>
            <Typography.Paragraph className="text-center">
              Please contact support if you believe this to be a mistake.
            </Typography.Paragraph>
            <Typography.Paragraph
              className="text-center"
              style={{ marginTop: 36 }}
            >
              <Button
                onClick={() => logoff(config)}
                className="ptw-btn btn-primary"
                type="primary"
                size="large"
                htmlType="submit"
              >
                Go to Login Page
              </Button>
            </Typography.Paragraph>
          </Card>
        </Col>
        <Col lg={6} md={6} xs={1} />
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    design: state.publicPage.design
  };
};

export default connect(mapStateToProps)(ForbiddenPage);
