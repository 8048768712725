import { Alert } from 'antd';
import { useEffect, useState } from 'react';

const NetworkConnectionPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const online = 'online';
    const noConn = 'offline';

    const handleShowPopup = () => {
      setShowPopup(true);
    };

    const handleHidePopup = () => {
      setShowPopup(false);
    };

    window.addEventListener(online, handleHidePopup);
    window.addEventListener(noConn, handleShowPopup);

    return () => {
      window.removeEventListener(online, handleHidePopup);
      window.removeEventListener(noConn, handleShowPopup);
    };
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  if (!showPopup) {
    return null;
  }

  return (
    <div
      style={{
        position: 'relative'
      }}
    >
      <Alert
        showIcon
        closable
        type="warning"
        message="No internet connection"
        description="Any changes will not be saved until internet connectivity is restored."
        onClose={handleClosePopup}
        style={{
          position: 'absolute',
          right: '50%',
          transform: 'translateX(50%)'
        }}
      />
    </div>
  );
};

export default NetworkConnectionPopup;
