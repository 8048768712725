import {
    required,
    maxLength,
    compoundValidator,
    alphaNumericAndSpecial,
} from '../validators/generic';

const maxLength500 = maxLength(500);

const brandText = compoundValidator([alphaNumericAndSpecial, maxLength500, required], 'Invalid text');

const validLogoTypes = ['image/jpeg', 'image/png', 'image/jpg'];

export const logo = file => {
    const errors = {}
    if (file.size > 5000000) errors.size = 'Logo too big'
    if (!validLogoTypes.includes(file.type))  errors.type = 'Invalid file type';
    return errors
}

export const generic = values => {
    const errors = {};

    if(values.brandType === 'text'){
        const brandTextValidation = brandText(values.brandText);
        if(brandTextValidation){
            errors.brandText = brandTextValidation;
        }
    }
    
    return errors;
};