import React from 'react';
import { Input, Form, Typography } from 'antd';

const ParamInput = ({
  input,
  label,
  type,
  placeholder,
  componentClass,
  children,
  meta: { error, warning, touched },
  meta,
  ...props
}) => {
  // eslint-disable-next-line no-mixed-operators
  const validationState =
    (touched && error && 'error') || (warning && 'warning') || null;

  return (
    <Form.Item
      label={label}
      validateStatus={validationState}
      help={error && <Typography.Text type="danger">{error}</Typography.Text>}
    >
      <Input
        {...input}
        type={type}
        placeholder={placeholder}
        size="large"
        componentClass={componentClass}
        {...props}
      >
        {children}
      </Input>
    </Form.Item>
  );
};

export default ParamInput;
