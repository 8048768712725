import { orderBy } from 'lodash';
import { capitalize } from 'voca';
import { getRecoil } from 'recoil-nexus';
import states from '../states';
import isEmail from 'validator/lib/isEmail';

export const generateDateQuery = (qryStart, qryEnd) => {
  const now = Date.now();
  const month = 1000 * 60 * 60 * 24 * 30;

  const isoStart = new Date(qryStart || now - month).toISOString();
  const isoStartDate = isoStart.substring(0, isoStart.indexOf('T'));

  const isoEnd = new Date(qryEnd || now).toISOString();
  const isoEndDate = isoEnd.substring(0, isoEnd.indexOf('T'));

  return {
    start: isoStartDate + ' 00:00:00',
    end: isoEndDate + ' 23:59:59'
  };
};

export const formatReport = (type, report, providers) => {
  const groups = getRecoil(states.groups);
  const provider = providers.find((p) => p.Sub === report.provider);

  if (type === 'RTM') {
    return {
      provider: {
        ...provider
      },
      report: {
        ...report
      }
    };
  }

  return {
    provider: {
      ...provider
    },
    report: {
      ...report,
      result:
        report.result.length === 1 && report.result[0].clinic === null
          ? [
              {
                clinic: report.result[0].clinic,
                clinicName: 'All Locations',
                count: report.result[0].count
              }
            ]
          : report.result.map((clinic) => {
              const clinicInfo = groups.clinics.find(
                (c) => c.Id === clinic.clinic
              );
              return {
                ...clinic,
                clinicName: clinicInfo ? clinicInfo.ClinicName : clinic.clinic
              };
            })
    }
  };
};

export const generateUsageReport = (reports) => {
  const groups = getRecoil(states.groups);

  let providerList = [];
  reports.forEach((report) =>
    providerList.push(formatReport('USAGE', report, groups.providers))
  );

  return {
    labels:
      providerList.map(
        ({ provider }) =>
          `${capitalize(provider.FirstName)} ${capitalize(provider.LastName)}`
      ) || [],
    datasets:
      providerList[0]?.report.result.map((clinic, i) => ({
        label: clinic.clinicName,
        data: providerList.map((provider) => provider.report.result[i].count),
        fill: false,
        axis: 'y'
      })) || []
  };
};

export const generateRtmBillers = (reports) => {
  const groups = getRecoil(states.groups);
  const sorted = orderBy(reports, (item) => item.result, ['desc']);

  let providerList = [];
  sorted.forEach((report) =>
    providerList.push(formatReport('RTM', report, groups.providers))
  );

  const labels =
    providerList
      .filter((p) => Object.keys(p.provider).length !== 0)
      .map(
        ({ provider }) =>
          `${capitalize(provider.FirstName)} ${capitalize(provider.LastName)}`
      ) || [];
  const datasets =
    providerList.length > 0
      ? providerList[0].report.result
          .map((item) => ({
            label: item.code,
            data: providerList.map((provider) => {
              let getCode = provider.report.result.find(
                (res) => res.code === item.code
              );

              if (getCode) {
                return getCode.count;
              } else {
                return null;
              }
            }),
            fill: false,
            axis: 'y'
          }))
          .sort((a, b) => b.data - a.data)
      : [];

  return {
    labels,
    datasets
  };
};

export const generateRtmBilled = (label, reports) => {
  const labels = [label];
  const datasets =
    Array.isArray(reports) &&
    reports
      .map((item) => ({
        label: item.Code,
        data: labels.map(() => item.TotalCount),
        fill: false,
        axis: 'y'
      }))
      .sort((b, a) => a.data[0] - b.data[0]);

  return {
    labels,
    datasets
  };
};

export const formatRtmProviders = (rtmProviders) => {
  if (!rtmProviders) {
    return [];
  }

  const groups = getRecoil(states.groups);
  const groupProviders = groups.providers;

  let providers = [];
  if (rtmProviders.startsWith('[') && rtmProviders.endsWith(']')) {
    let parsedArr = [];
    try {
      parsedArr = JSON.parse(rtmProviders);
    } catch (error) {
      parsedArr = [];
    }

    providers = [...groupProviders].filter((p) => {
      return parsedArr.includes(p.Sub) || parsedArr.includes(p.EmailAddress);
    });
  } else {
    providers = [...groupProviders].filter((p) => {
      if (isEmail(rtmProviders)) {
        return p.EmailAddress === rtmProviders;
      } else {
        return p.Sub === rtmProviders;
      }
    });
  }

  return providers;
};

export const getEngagementStatus = (name) => {
  switch (name) {
    case 'GREEN':
      return 'Ready for Billing';
    case 'BLUE':
      return 'High Engagement';
    case 'YELLOW':
      return 'Low Engagement';
    case 'RED':
      return 'No Engagement';
    default:
      return 'Inactive';
  }
};

export const sortProvidersByStatus = (providerList) => {
  const activeProviders = providerList.filter((p) => p.Active);
  const inactiveProviders = providerList.filter((p) => !p.Active);

  return [...activeProviders, ...inactiveProviders];
};

export const filterActiveProviders = (providerList) => {
  if (Array.isArray(providerList) && providerList.length === 0) {
    return [];
  }

  return providerList
    .filter((p) => !!p.Active)
    .map((p) => p.Sub)
    .slice(0, 50);
};
