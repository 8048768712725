import { Modal, notification, Select, Space, Typography } from 'antd';
import { useState } from 'react';
import { connect } from 'react-redux';
import { capitalize } from 'voca';

import {
  getMessageBoardProviders,
  setMessageBoardProviders
} from '../patients/actions/patients';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

const AssignPatientsModal = ({
  dispatch,
  getProviders,
  setProviders,
  providers,
  open,
  onClose,
  boards,
  profile
}) => {
  const [value, setValue] = useState(null);
  const [showHelper, setShowHelper] = useState(false);
  const [loading, setLoading] = useState(false);

  const options = providers.filter((p) => {
    if (p.Active === true || p.Active === 'true') {
      if (p.Sub !== profile.Sub) {
        return true;
      }
    }

    return false;
  });

  const handleSelect = (val) => {
    setValue(val);
    setShowHelper(false);
  };

  const handleSubmit = async () => {
    if (!value) {
      setShowHelper(true);
      return;
    }

    try {
      setLoading(true);

      await Promise.all(
        boards.map((b) =>
          dispatch(getProviders(b.Owner)).then((res) => ({
            owner: b.Owner,
            groupId: b.GroupId,
            fname: b.FirstName,
            lname: b.LastName,
            providers: res.value.providers.map((p) => ({
              ...toCamelCaseObjKeys(p)
            }))
          }))
        )
      ).then(async (params) => {
        await Promise.all(
          params.map((data) => {
            const newProvider = providers.find((p) => p.Sub === value);
            const therapists = [
              ...data.providers.filter((p) => p.sub !== profile.Sub),
              {
                sub: value,
                firstName: newProvider.FirstName,
                lastName: newProvider.LastName
              }
            ];

            return dispatch(
              setProviders({
                Action: 'MANAGE_BOARDS',
                therapists,
                boardData: {
                  to: data.owner,
                  firstName: data.fname,
                  lastName: data.lname,
                  GroupId: data.groupId
                }
              })
            );
          })
        );
      });

      notification.success({
        message: 'Assign Success',
        description:
          'Patients have been successfully assigned to the new provider.'
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Assign Failed',
        description: 'An error occurred while trying to assign the patients.'
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      okText="Assign"
      confirmLoading={loading}
      onOk={handleSubmit}
    >
      <Space
        size="large"
        direction="vertical"
        style={{
          width: '100%'
        }}
      >
        <Space direction="vertical" size={0}>
          <Typography.Text strong>
            Provider's profile has been deactived.
          </Typography.Text>

          <Typography.Text>
            Do you want to assign the patients to another provider?
          </Typography.Text>
        </Space>

        <Space
          direction="vertical"
          size={0}
          style={{
            width: '100%'
          }}
        >
          <Select
            showSearch
            size="large"
            placeholder="Select Provider"
            status={showHelper ? 'error' : ''}
            value={value}
            onChange={handleSelect}
            style={{
              width: '100%',
              fontSize: 15
            }}
          >
            {options.map((item, i) => (
              <Select.Option key={i} value={item.Sub}>
                {capitalize(item.FirstName)} {capitalize(item.LastName)} (
                {item.EmailAddress})
              </Select.Option>
            ))}
          </Select>

          {showHelper ? (
            <Typography.Text
              type="danger"
              style={{
                fontSize: 12
              }}
            >
              Please select a provider.
            </Typography.Text>
          ) : (
            <div
              style={{
                height: 22
              }}
            />
          )}
        </Space>
      </Space>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  providers: state.users.admins
});

const mapDispatchToProps = () => ({
  getProviders: (owner) => getMessageBoardProviders(owner),
  setProviders: (param) => setMessageBoardProviders(param)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignPatientsModal);
