import { IDLESTATUS_AWAY, IDLESTATUS_INACTIVE, IDLESTATUS_EXPIRED } from './constants'
import { push } from 'react-router-redux'
import { requestDeauth } from '../membership/actions'
import { success } from 'react-notification-system-redux'
import { actions as idleActions } from './index'
import config from '../../config'

const logoff = dispatch => {
    //todo: refactor to single action spawning multiple
    dispatch(idleActions.stop())
    dispatch(push('/'));
    dispatch(requestDeauth());
    dispatch(success({
        message: 'Automatically Signed Out Due to Inactivity',
        title: 'Successfully Signed Out',
        position: 'tr'
    }));
};

export const idleStatusDelay = idleStatus => () => {
    switch (idleStatus) {
        case IDLESTATUS_AWAY:
            return 1000 * 60 * 60;
        case IDLESTATUS_INACTIVE:
            return 1000 * 60 * 60 *12;
        case IDLESTATUS_EXPIRED:
            return 1000 * 60 * 60 *12;
        default:
    }
}

export const activeStatusAction = (dispatch, getState) => {
};

export const idleStatusAction = idleStatus => (dispatch, getState) => {
    const cfg = config;
    switch (idleStatus) {
        case IDLESTATUS_AWAY:
            break;
        case IDLESTATUS_INACTIVE:
            console.log('Inactive')
            break;
        case IDLESTATUS_EXPIRED:
            console.log('Expired')
            if (getState().visibleProfile.Role !== 'p') {
                console.log('IDLESTATUS: EXPIRED')
                logoff(dispatch);
            }
            break;
        default:
            if (cfg.env === 'development') {
                console.log(idleStatus);
            }
    }
}