import 'isomorphic-fetch';
import config from '../../config';
import Auth from '@aws-amplify/auth';

export const API_REQUEST = 'API_REQUEST';

export default (store) => (next) => async (action) => {
  //check for API_REQUEST action attribute

  const apiRequest = action[API_REQUEST];
  if (apiRequest) {
    let token;
    try {
      const session = await Auth.currentSession();
      const {
        idToken: { jwtToken },
      } = session;
      token = jwtToken;
    } catch (err) {
      if (err === 'No current user') {
        const sso_token = store.getState().currentUser.token;
        if (sso_token) {
          token = sso_token;
        } else {
          token = null;
        }
      }
    }

    const authAction = {
      type: apiRequest.type,
      payload: new Promise((resolve, reject) => {
        const url = `${config.root}/${apiRequest.payload.resource}${
          apiRequest.payload.query ? '?' + apiRequest.payload.query : ''
        }`;

        return fetch(url, {
          method: apiRequest.payload.method,
          body: apiRequest.payload.file
            ? apiRequest.payload.body
            : JSON.stringify(apiRequest.payload.body),
          headers: apiRequest.payload.file
            ? {
                Authorization: token,
                Accept: 'image/*',
              }
            : {
                ...apiRequest.payload.headers,
                Authorization: token,
                'Content-Type': 'application/json',
              },
        }).then((response) => {
          if (response.status === 401) {
            console.log('Invalid Permissions');
          }

          if (response.status >= 200 && response.status < 300) {
            //no parsing when status is No Content
            if (response.status !== 204) {
              return response
                .json()
                .then((json) => resolve(json))
                .catch(() => resolve(response));
            } else {
              return resolve(response);
            }
          } else {
            return reject(response);
          }
        });
      }),
    };
    return next(authAction);
  } else {
    return next(action);
  }
};
