import React, { Fragment } from 'react';
import { Form, Input, Button, Typography } from 'antd';

const IntroMessageForm = props => {
  const {
    introText,
    setIntroText,
    event,
    handleSubmitCustomization,
    isLoading,
  } = props;

  const popContent = (
    <Fragment>
      <Typography.Text strong style={{ color: '#fff' }}>
        Intro Pop-up Message
      </Typography.Text>
      <Typography.Paragraph style={{ color: '#fff' }}>
        When the patient first logs into the app, they will see this message
        underneath your logo. After this initial login, they will not see this
        message again.
      </Typography.Paragraph>

      <a
        href={'https://ptwired.zendesk.com/hc/en-us/articles/4411528633235'}
        target={'_blank'}
      >
        Learn More
      </a>
    </Fragment>
  );

  return (
    <Form
      name="basic"
      labelAlign="right"
      labelWrap={true}
      labelCol={{ xl: 4, lg: 6, md: 8, sm: 24 }}
      wrapperCol={{ xl: 20, lg: 18, md: 16, sm: 24 }}
      initialValues={introText}
      onFinish={() => {
        handleSubmitCustomization(event, { customizationTextValue: introText });
      }}
      autoComplete={false}
    >
      <Form.Item
        label="Intro Pop-up Message"
        name="introText"
        tooltip={popContent}
        rules={[
          { required: true, message: 'Intro Pop-up Message is required.' },
        ]}
      >
        <Input.TextArea
          defaultValue={introText}
          onChange={e => setIntroText(e.target.value)}
          value={introText}
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          xl: { offset: 4 },
          lg: { offset: 6 },
          md: { offset: 8 },
          sm: { offset: 0 },
        }}
      >
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save Changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default IntroMessageForm;
