import React, { useEffect, useState } from 'react';
import { Row, Col, Pagination, notification } from 'antd';
import ExerciseTemplateCard from './ExerciseTemplateCard';
import services from '../../../services';

const ExerciseTemplateGrid = ({ templates, handleCardClick }) => {
  const [pagination, setPagination] = useState({
    patientsPerPageSize: 24,
    patientsTotalPage: 0,
    patientsCurrentPage: 1,
    minIndex: 0,
    maxIndex: 0
  });

  useEffect(() => {
    setPagination({
      ...pagination,
      minIndex: 0,
      maxIndex: pagination.patientsPerPageSize,
      patientsCurrentPage: pagination.patientsCurrentPage
    });
  }, [templates.length]);

  const handleSelectItem = async (item, index) => {
    try {
      notification.info({
        message: 'Loading...',
        description: 'Fetching template data. Please wait.'
      });

      const { GroupId, Id } = item;
      const response = await services.templates.getItem(GroupId, Id);

      if (response.status === 200) {
        notification.destroy();
        handleCardClick(response.data, index);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while fetching template data.'
      });
    }
  };

  const handlePagination = (page) => {
    setPagination({
      ...pagination,
      patientsCurrentPage: page,
      minIndex: (page - 1) * pagination.patientsPerPageSize,
      maxIndex: page * pagination.patientsPerPageSize
    });
  };

  return (
    templates && (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {Array.isArray(templates) &&
            templates
              .slice(pagination.minIndex, pagination.maxIndex)
              .map((item, i) => {
                return (
                  <Col key={i} xl={6} lg={12} md={12} sm={24} xs={24}>
                    <ExerciseTemplateCard
                      template={item}
                      handleCardClick={() => handleSelectItem(item, i)}
                    />
                  </Col>
                );
              })}
        </Row>
        <div className="pagination-container align-right">
          <Pagination
            responsive
            pageSize={pagination.patientsPerPageSize}
            current={pagination.patientsCurrentPage}
            total={templates.length}
            onChange={handlePagination}
            showSizeChanger={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            style={{ bottom: '0px' }}
          />
        </div>
      </React.Fragment>
    )
  );
};

export default ExerciseTemplateGrid;
