import apiFetch from '../utils/fetch.utils';

export const getTokens = groupId => {
  try {
    const uri = `/push-notification/tokens/?groupId=${groupId}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    throw error;
  }
};

export const deleteTokens = list => {
  try {
    const uri = `/push-notification/tokens/?list=${list}`;
    const method = 'DELETE';

    return apiFetch(uri, method);
  } catch (error) {
    throw error;
  }
};
