import React, { Component } from 'react'
import {Well,Panel, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {requestDeauth} from "../membership/actions";

class DischargePage extends Component {

    constructor(props){
        super(props);

        let {publicPage:{phone}} = props;

        let pre;
        let mid;
        let end;

        if(phone){
            // eslint-disable-next-line
            phone = phone.replace(/[\-]+/g, '');
            pre = phone.slice(0,3);
            mid = phone.slice(3,6);
            end = phone.slice(6,10);
        }

        this.state = {
            phone: phone ? "("+pre+")"+mid+"-"+end : "(919)323-8627"
        }
    }

    render(){
        const {publicPage} = this.props;
        const {phone} = this.state;

        return(<div>
            <Well bsSize="large">
                <Panel style={{textAlign:"center"}}>
                    <Panel.Heading>
                        <img src={publicPage.design.logoUrl} width={250} alt=""/>
                        <br/>
                        <br/>
                        <Panel.Title componentClass="h3">Your Exercise Program Prescription Has Expired!</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <p>Please contact us at {phone} to receive a new exercise prescription.</p>
                        <br/>
                        <p>If you think this is a mistake, please email us at <a href="mailto:admin@ptwired.com">admin@ptwired.com</a></p>
                    </Panel.Body>
                </Panel>
                <Button variant="primary" size="lg" block onClick={()=>{this.props.dispatch(requestDeauth())}}>
                    Log Out
                </Button>
            </Well>
        </div>)
    }

}


const mapStateToProps = (state) =>{

    return{
        ...state,
        visibleProfile: state.visibleProfile,
        publicPage: state.publicPage,
    }
}

export default connect(mapStateToProps)(DischargePage);