import React from 'react';
import { Form, Select, Modal, Button, Typography } from 'antd';
import v from 'voca';
import TextTranslation from '../../../I18n/TranslateText';

const SelectPrintRxModal = ({
  isOpen,
  currLocale,
  profile,
  rxPrintDetails,
  handleCancel,
  handleSelectPrintRx,
  handlePrintRxModal,
  handlePrintPreview,
}) => {
  return (
    <Modal
      title={
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          <TextTranslation
            currLocale={currLocale}
            currText={'Select Prescription'}
          />
        </Typography.Title>
      }
      open={isOpen}
      maskClosable={false}
      onCancel={handleCancel}
      footer={[
        <Button
          type="primary"
          disabled={!rxPrintDetails}
          onClick={handlePrintPreview}
        >
          <TextTranslation currLocale={currLocale} currText={'Print Preview'} />
        </Button>,
        <Button type="default" onClick={handlePrintRxModal}>
          <TextTranslation currLocale={currLocale} currText={'Close'} />
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item
          label={
            <TextTranslation
              currLocale={currLocale}
              currText={'Select Prescription to Print'}
            />
          }
        >
          <Select
            showSearch
            placeholder={
              <TextTranslation
                currLocale={currLocale}
                currText={'Select Prescription'}
              />
            }
            onChange={handleSelectPrintRx}
          >
            {Array.isArray(profile.Prescriptions) &&
              profile.Prescriptions.map((item, i) => {
                const { firstName, lastName } = profile;
                const patientName = `${v.capitalize(firstName)} ${v.capitalize(
                  lastName
                )}`;
                const prescriptionName = `${patientName}'s Home Exercise Program`;

                return (
                  <Select.Option key={i} value={item.Id}>
                    <span
                      style={{
                        textTransform: !item.Name ? 'capitalize' : 'unset',
                      }}
                    >
                      {item.Name || prescriptionName}
                    </span>
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SelectPrintRxModal;
