import { atom } from 'recoil';

const defaultState = {
  programs: []
};

const CAREPLAN_STATE = atom({
  key: 'CAREPLAN_STATE',
  default: defaultState
});

export default CAREPLAN_STATE;
