import { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  notification,
  Space,
  Table,
  Typography,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Loading from '../../../components/Loading';
import services from '../../../services';

const tableHeader = (title, index) => ({
  title,
  dataIndex: index,
  key: index,
  ellipsis: true,
});

const GroupNotifications = ({ group }) => {
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const list = filtered.length ? filtered : tokens;

  useEffect(() => {
    if (group) {
      if (group.id) {
        getGroupTokens();
      }
    }
  }, [group]);

  const getGroupTokens = async () => {
    try {
      setLoading(true);
      const response = await services.notification.getTokens(group.id);

      if (response.message === 'Success') {
        const { data } = response.data;
        setTokens(data);
      }
    } catch (error) {
      notification.error({
        message: 'Fetch Failed',
        description: 'An error occurred while fetching tokens.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setShowConfirm(false);
    setSelectedKeys([]);
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      const response = await services.notification.deleteTokens(
        encodeURIComponent(JSON.stringify(selectedItems.map(item => item.Id)))
      );

      if (response.message === 'Success') {
        setFiltered([]);
        getGroupTokens();
        handleCancel();

        notification.success({
          message: 'Delete Success',
          description: 'Token(s) successfully deleted.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Delete Failed',
        description: 'An error occurred while deleting token(s).',
      });
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div>
      {loading ? (
        <Loading isLoading={loading}>
          <Typography.Text>Loading...</Typography.Text>
        </Loading>
      ) : (
        <>
          <Space
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '1em',
            }}
          >
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search by user email"
              onChange={e =>
                setFiltered(
                  tokens.filter(item => item.UserEmail.includes(e.target.value))
                )
              }
            />

            <Space className="hide-mb">
              <Button onClick={() => setSelectedKeys([])}>CANCEL</Button>

              <Button
                danger
                type="primary"
                disabled={!selectedKeys.length}
                onClick={() => setShowConfirm(true)}
              >
                DELETE
              </Button>
            </Space>
          </Space>

          <Space className="hide-dt flex-mb mb-2">
            <Button onClick={() => setSelectedKeys([])}>CANCEL</Button>

            <Button
              danger
              type="primary"
              disabled={!selectedKeys.length}
              onClick={() => setShowConfirm(true)}
            >
              DELETE
            </Button>
          </Space>

          <Table
            scroll={{ y: 520, x: 'max-content' }}
            size="small"
            pagination={false}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedKeys,
              onChange: (keys, rows) => {
                setSelectedKeys(keys);
                setSelectedItems(rows);
              },
            }}
            columns={[
              tableHeader('Id', 'Id'),
              tableHeader('Token', 'Token'),
              tableHeader('Device Type', 'DeviceType'),
              tableHeader('User Email', 'UserEmail'),
            ]}
            dataSource={list.map((item, i) => ({
              ...item,
              key: i,
            }))}
          />
        </>
      )}

      <Modal
        visible={showConfirm}
        okText="DELETE"
        onCancel={deleting ? null : handleCancel}
        footer={[
          <Fragment key="cancel">
            <Button disabled={deleting} onClick={handleCancel}>
              CANCEL
            </Button>
          </Fragment>,
          <Fragment key="delete">
            <Button
              danger
              type="primary"
              disabled={deleting}
              loading={deleting}
              onClick={handleDelete}
            >
              DELETE
            </Button>
          </Fragment>,
        ]}
      >
        <Typography.Text>
          {'Are you sure you want to delete the token(s)?'}
          <br />
          {"This is a permanently delete. You can't undo this action."}
        </Typography.Text>
      </Modal>
    </div>
  );
};

export default GroupNotifications;
