import apiFetch from '../utils/fetch.utils';

export const getByOwner = async (owner) => {
  try {
    const uri = `educational-pdf?type=owner&id=${owner}`;
    const method = 'GET';

    return await apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addPdf = async (payload) => {
  try {
    const uri = 'educational-pdf';
    const method = 'POST';

    return await apiFetch(uri, method, {
      body: {
        type: 'owner',
        action: 'create',
        content: {
          ...payload
        }
      }
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editPdf = async (payload) => {
  try {
    const uri = 'educational-pdf';
    const method = 'POST';

    return await apiFetch(uri, method, {
      body: {
        type: 'owner',
        action: 'update',
        content: {
          ...payload
        }
      }
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deletePdf = async (id, owner) => {
  try {
    const uri = 'educational-pdf';
    const method = 'POST';

    return await apiFetch(uri, method, {
      body: {
        type: 'owner',
        action: 'delete',
        Id: id,
        Owner: owner
      }
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getByPatient = async (sub) => {
  try {
    const uri = `educational-pdf?type=patient&id=${sub}`;
    const method = 'GET';

    return await apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const assignPdf = async (content, notification) => {
  try {
    const uri = 'educational-pdf';
    const method = 'POST';

    return await apiFetch(uri, method, {
      body: {
        type: 'patient',
        content,
        notification
      }
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};
