import React, { Component } from 'react';
import { PanelGroup, Panel, Modal, Button } from 'react-bootstrap';
import v from 'voca';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { exersiceProperties } from '../helper';
import {
  getEmbeddedUrl,
  getVimeoUrlId,
  getYoutubeUrlId
} from '../../patients/videoUrlHelpers';
import './styles.css';
import '../../../styles/patient-mobile.css';
import {
  completeExercise,
  sendExerciseCompleteEvent,
  updateRxList
} from '../actions/patient';
import { decodedCurrentUserSelector } from '../../exercise-library/selectors';
import achievementsImages from './AchievementsImages';
import achievementPlaceholder from '../../../img/achievements/lock-achievement.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import ReviewWidget from '../Widgets/ReviewWidget';
import Notifications from '../Notifications';
import { requestProfile } from '../../membership/actions';
import ProgressBar from '../header/ProgressBar';
import { countExercises, isChecked, simpleDate } from './exerciseHelpers';
import VideoModal from '../../video/VideoModal';
import { Space, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import TextTranslation from '../../../I18n/TranslateText';

class ExercisesList extends Component {
  constructor(props) {
    super(props);

    const groupName = v.titleCase(props.decodedCurrentUser.groupName);

    this.state = {
      exercises: [],
      showNotification: false,
      groupName: groupName,
      showReviewWidget: false,
      reviewWidgetCompute: false,
      active: null,
      achievementModalOpen: false,
      currentAchievement: {
        image_name: achievementPlaceholder,
        title: '',
        message: ''
      },
      modalsQueue: [],
      exerciseCounter: 0,
      numberOfExercises: 0,
      loading: false,
      questionsModalVisible: (bool) => {
        if (bool === undefined) {
          return !!(
            this.state.exerciseCounter === this.state.numberOfExercises
          );
        } else return bool;
      }
    };
    this.openAchievementModal = this.openAchievementModal.bind(this);
    this.closeAchievementModal = this.closeAchievementModal.bind(this);
    this.closeQuestionsModal = this.closeQuestionsModal.bind(this);
    this.closeReviewWidget = this.closeReviewWidget.bind(this);
  }

  componentDidMount() {
    const {
      currentRx: { Prescription }
    } = this.props;

    if (this.props.location.search === '?25=true') {
      this.setState({ showReviewWidget: true });
    }

    this.setState({
      exercises: Prescription.exercises.map((ex) => ({
        ...ex,
        completionDates: Array.isArray(ex.completionDates)
          ? ex.completionDates
          : []
      }))
    });

    this.setState({
      exerciseCounter: countExercises(Prescription.exercises),
      numberOfExercises: Prescription.exercises.length
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { reviewWidgetCompute } = this.state;

    if (reviewWidgetCompute) {
      return;
    }

    const { clinic, currentRx } = this.props;

    const { Prescription } = currentRx;
    const completedExercisesValid = Prescription.completedExercises === 25;

    if (clinic && clinic.IsPracticePromotions && completedExercisesValid) {
      if (clinic.FacebookUrl && clinic.GoogleUrl) {
        this.setState({ showReviewWidget: true, reviewWidgetCompute: true });
      } else {
        this.setState({ reviewWidgetCompute: true });
      }
    }
  }

  openAchievementModal(achievement) {
    this.setState({
      achievementModalOpen: true,
      currentAchievement: achievement
    });
  }

  closeAchievementModal() {
    const { modalsQueue } = this.state;
    this.setState({
      achievementModalOpen: false,
      currentAchievement: achievementPlaceholder
    });
    if (modalsQueue.length > 0)
      setTimeout(() => this.openAchievementModal(modalsQueue.pop()), 750);
  }

  achievementModal() {
    const { image_name, title, message } = this.state.currentAchievement;

    return (
      <Modal
        show={this.state.achievementModalOpen}
        onHide={this.closeAchievementModal}
        bsSize="large"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="achievement-modal-body">
          <img src={image_name} alt="Achievement" />
          <h4>{title}</h4>
          <p>{message}</p>
        </Modal.Body>
      </Modal>
    );
  }

  closeQuestionsModal() {
    this.setState({ questionsModalVisible: false });
  }

  closeReviewWidget() {
    this.setState({ showReviewWidget: false });
  }

  closeNotification = () => {
    this.setState({ showNotification: false });
  };

  handleCompleteExercise = async (exercise, isCompletedToday) => {
    const { exercises, exerciseCounter, numberOfExercises } = this.state;
    const { GroupId } = this.props.visibleProfile;

    if (isChecked(exercise) && isCompletedToday) return;

    this.setState({ loading: true });

    const {
      decodedCurrentUser: { sub },
      dispatch
    } = this.props;

    const { Id } = this.props.currentRx;
    const query = Id ? '&prescription=' + Id : '';

    try {
      await dispatch(completeExercise(GroupId, sub, exercise.id, query)).then(
        async (res) => {
          await dispatch(updateRxList(Id, { ...res.value }));

          this.setState({ exerciseCounter: exerciseCounter + 1 }, () => {
            if (exerciseCounter === numberOfExercises) {
              this.setState({ showNotification: true });
            }
          });
          // const modalsQueue = Object.keys(achievements).map(
          //   k => achievementsImages[k][achievements[k]]
          // );
          // if (modalsQueue.length > 0)
          //   this.openAchievementModal(modalsQueue.pop());
          // this.setState({
          //   modalsQueue,
          // });
        }
      );

      this.exerciseCompleteEvent(this.props.visibleProfile, exercise.id);

      let index;

      for (let i = 0; i < exercises.length; i++) {
        const ex = exercises[i];

        if (ex.id === exercise.id) {
          index = i;
        }
      }

      if (!exercises[index]) {
        return;
      }

      try {
        exercises[index].completionDates.push(simpleDate());
      } catch (err) {
        exercises[index].completionDates = [];
        exercises[index].completionDates.push(simpleDate());
      }

      this.setState({ exercises: exercises });
    } catch (error) {
      console.log('error', error);
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  exerciseCompleteEvent = async (profile, exerciseId) => {
    await this.props.dispatch(
      sendExerciseCompleteEvent(
        profile.GroupInfo.Name,
        profile.Sub,
        profile.GroupId,
        profile.EmailAddress,
        exerciseId
      )
    );
  };

  render() {
    const { clinic, locale } = this.props;
    const {
      showReviewWidget,
      groupName,
      showNotification,
      exercises,
      exerciseCounter,
      numberOfExercises,
      loading
    } = this.state;

    const buttonColor = (completed) => {
      if (completed) {
        return 'success';
      }
      return 'primary';
    };

    return (
      <div className="mobile-body container-fluid">
        <Modal show={loading} size={100}>
          <Modal.Body style={{ textAlign: 'center' }}>
            <Spin size="large" />
            <Typography.Paragraph>
              <TextTranslation
                currLocale={locale}
                currText={'Completing exercise'}
              />
              ...
            </Typography.Paragraph>
          </Modal.Body>
        </Modal>
        {showNotification && <Notifications onClose={this.closeNotification} />}
        {showReviewWidget && (
          <ReviewWidget
            onHide={this.closeReviewWidget}
            google={clinic.GoogleUrl}
            facebook={clinic.FacebookUrl}
            groupName={groupName}
          />
        )}
        <div className="row">
          <div className="col-xs-12">
            <ProgressBar
              totalExercises={numberOfExercises}
              completed={exerciseCounter}
            />
          </div>

          {exercises.length === 0 ? (
            <Space align="center">
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            </Space>
          ) : (
            <div className="col-xs-12">
              <PanelGroup accordion id="accordion-2">
                {/*eslint-disable-next-line*/}
                {exercises.map((item, i) => {
                  const ex = {
                    ...item,
                    imageUrl:
                      item.imgBase64 ||
                      item.image ||
                      `https://d2p805pqn3eul9.cloudfront.net/${item.groupId}/${item.id}.jpg`
                  };

                  const date1 = moment().format('l');
                  const date2 = moment().format('L');
                  const date3 = moment().format('YYYY-MM-DD');

                  const completed = isChecked(ex);
                  const isCompletedToday =
                    !!ex.completionDates.find((date) => {
                      return date === date1 || date === date2 || date === date3;
                    }) && completed;

                  if (!ex.deleted)
                    return (
                      <div
                        key={'exercise-' + i}
                        className={'complete-exercise-button-div'}
                      >
                        <Button
                          bsStyle={buttonColor(isCompletedToday)}
                          className="completeExerciseButton"
                          aria-hidden="true"
                          onClick={() =>
                            this.handleCompleteExercise(ex, isCompletedToday)
                          }
                          block
                        >
                          {buttonColor(isCompletedToday) === 'success' && (
                            <span>
                              <TextTranslation
                                currLocale={locale}
                                currText={'Exercise Completed!'}
                              />
                            </span>
                          )}
                          {buttonColor(isCompletedToday) === 'primary' && (
                            <span>
                              <TextTranslation
                                currLocale={locale}
                                currText={'Click To Complete'}
                              />
                            </span>
                          )}
                        </Button>

                        <Panel key={ex.id} eventKey={i}>
                          <Panel.Heading
                            style={
                              ex.imageUrl
                                ? {
                                    backgroundImage: `url(${ex.imageUrl})`,
                                    backgroundPositionY: 'center',
                                    backgroundPositionX: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat'
                                  }
                                : null
                            }
                          >
                            <Panel.Title toggle>
                              <h2 className="u-position-relative shadowed">
                                <TextTranslation
                                  currLocale={locale}
                                  currText={v.capitalize(ex.exerciseName)}
                                />
                              </h2>
                              <div className="row exercise-property">
                                {exersiceProperties.map((property, i) => (
                                  <div key={i}>
                                    {ex[property] && (
                                      <div
                                        className="col-xs-2"
                                        style={i === 2 ? { width: '40%' } : {}}
                                      >
                                        <h4 className=" u-position-relative shadowed">
                                          {property === 'frequency' ? (
                                            <span>
                                              {ex[property]}x{' '}
                                              <TextTranslation
                                                currLocale={locale}
                                                currText={`per ${v.capitalize(
                                                  ex['perdiem']
                                                )}`}
                                              />
                                            </span>
                                          ) : (
                                            <span>
                                              {ex[property]}{' '}
                                              <TextTranslation
                                                currLocale={locale}
                                                currText={property}
                                              />
                                            </span>
                                          )}
                                        </h4>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </Panel.Title>

                            {(ex.video || ex.instructions) && (
                              <Panel.Toggle componentClass="a">
                                <div className={'mobile-video-icon'}>
                                  <row>
                                    <FontAwesomeIcon icon={faPlayCircle} />
                                  </row>
                                </div>
                              </Panel.Toggle>
                            )}
                          </Panel.Heading>

                          <Panel.Body collapsible>
                            {ex.video &&
                              (getVimeoUrlId(ex.video) ||
                                getYoutubeUrlId(ex.video) ||
                                ex.video.includes('player')) && (
                                <div className="video-container">
                                  <iframe
                                    title={ex.exerciseName}
                                    width="560"
                                    height="315"
                                    src={
                                      ex.video.includes('player')
                                        ? ex.video
                                        : getEmbeddedUrl(ex.video)
                                    }
                                    frameBorder="0"
                                    allowFullScreen
                                  />
                                </div>
                              )}
                            {ex.instructions && (
                              <p>
                                <TextTranslation
                                  currLocale={locale}
                                  currText={ex.instructions}
                                />
                              </p>
                            )}
                          </Panel.Body>
                        </Panel>
                      </div>
                    );
                })}
              </PanelGroup>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLocation: state.routerReducer.location.pathname,
  clinic: state.clinics.currentClinic,
  patients: state.patients.patients,
  visibleProfile: state.visibleProfile,
  locale: state.mobile.locale,
  decodedCurrentUser: decodedCurrentUserSelector(state)
});

export default withRouter(connect(mapStateToProps)(ExercisesList));
