export const LOCALE = 'LOCALE';
export const AFRIKAANS = 'af';
export const ALBANIAN = 'sq';
export const AMHARIC = 'am';
export const ARABIC = 'ar';
export const ARMENIAN = 'hy';
export const AZERBAIJANI = 'az';
export const BENGALI = 'bn';
export const BOSNIAN = 'bs';
export const BULGARIAN = 'bg';
export const CATALAN = 'ca';
export const CHINESE = 'zh';
export const CHINESE_TRADITIONAL = 'zh-TW';
export const CROATIAN = 'hr';
export const CZECK = 'cs';
export const DANISH = 'da';
export const DARI = 'fa-AF';
export const DUTCH = 'nl';
export const ENGLISH = 'en';
export const ESTONIAN = 'et';
export const FARSI = 'fa';
export const TAGALOG = 'tl';
export const FINNISH = 'fi';
export const FRENCH = 'fr';
export const FRENCH_CANADA = 'fr-CA';
export const GEORGIAN = 'ka';
export const GERMAN = 'de';
export const GREEK = 'el';
export const GUJATI = 'gu';
export const HAITIAN_CREOLE = 'ht';
export const HAUSA = 'ha';
export const HEBREW = 'he';
export const HINDI = 'hi';
export const HUNGARIAN = 'hu';
export const ICELANDIC = 'is';
export const INDONESIAN = 'id';
export const IRISH = 'ga';
export const ITALIAN = 'it';
export const JAPANESE = 'ja';
export const KANNADA = 'kn';
export const KAZAKH = 'kk';
export const KOREAN = 'ko';
export const LATVIAN = 'lv';
export const LITHUANIAN = 'lt';
export const MACADONIAN = 'mk';
export const MALAY = 'ms';
export const MALAYALAM = 'ml';
export const MALTESE = 'mt';
export const MARATHI = 'mr';
export const MONGOLIAN = 'mn';
export const NORWEGIAN = 'no';
export const PASHTO = 'ps';
export const POLISH = 'pl';
export const PORTUGUESE = 'pt';
export const PUNJABI = 'pa';
export const ROMANIAN = 'ro';
export const RUSSIAN = 'ru';
export const SERBIAN = 'sr';
export const SINHALA = 'si';
export const SLOVAK = 'sk';
export const SLOVENIAN = 'sl';
export const SOMALI = 's';
export const SPANISH = 'es';
export const SWAHILI = 'sw';
export const SWEDISH = 'sv';
export const TAMIL = 'ta';
export const TELUGU = 'te';
export const THAI = 'th';
export const TURKISH = 'tr';
export const UKRAINIAN = 'uk';
export const URDU = 'ur';
export const UZBEK = 'uz';
export const VIETNAMESE = 'vi';
export const WELSH = 'cy';
