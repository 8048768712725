import React, { Fragment, useState, useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Breadcrumb, Row, Space } from 'antd';
import DropdownNavigation from '../../components/DropdownNavigation';
import DispatchLinkItem from '../../components/DispatchLinkItem';
import Loading from '../../components/Loading';
import AddProgramModal from './AddProgramModal';
import Program from './Program';
import ProgramDetails from './ProgramDetails';
import VideoDrawer from './VideoDrawer';
import {
  getAllPrograms,
  getProgram,
  updateProgram,
  addVideo,
  addProgram,
  changeActiveProgram,
} from './actions';
import setNotification from '../../utils/setNotification.utils';

export const ACTIONS = {
  CHANGE_SCREEN: 'CHANGE_SCREEN',
  TOGGLE_DRAWER: 'TOGGLE_DRAWER',
};

const initialState = {
  screen: 'program',
  activeVideo: {
    Id: null,
    Title: null,
    Sequence: null,
    Drip: null,
    Description: null,
    NotificationTitle: null,
    NotificationDescription: null,
    Location: null,
  },
  showDrawer: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.CHANGE_SCREEN: {
      if (action.payload.screen === 'program') {
        return {
          ...state,
          screen: action.payload.screen,
          activeProgram: '',
        };
      }
      if (action.payload.screen === 'details') {
        return {
          ...state,
          screen: action.payload.screen,
        };
      }
      return { ...state, screen: action.payload.screen };
    }
    case ACTIONS.TOGGLE_DRAWER: {
      if (action.payload) {
        return {
          ...state,
          showDrawer: !state.showDrawer,
          activeVideo: action.payload,
        };
      }
      return {
        ...state,
        showDrawer: !state.showDrawer,
        activeVideo: {
          Id: null,
          Title: null,
          Sequence: null,
          Drip: null,
          Description: null,
          NotificationTitle: null,
          NotificationDescription: null,
          Location: null,
        },
      };
    }
    default:
      return state;
  }
};

function CarePlan(props) {
  const { carePlan, dispatch } = props;
  const [state, dispatchState] = useReducer(reducer, initialState);
  const [showAddProgramModal, setshowAddProgramModal] = useState(false);

  useEffect(() => {
    handleFetchAllPrograms();
  }, []);

  const handleChangeScreen = ({ activeProgram, screen }) => {
    if (activeProgram) {
      handleChangeActiveProgram(activeProgram);
    }
    dispatchState({ type: ACTIONS.CHANGE_SCREEN, payload: { screen } });
  };

  const handleChangeActiveProgram = activeProgram => {
    dispatch(changeActiveProgram(activeProgram));
  };

  const handleToggleDrawer = payload => {
    if (payload) {
      dispatchState({
        type: ACTIONS.TOGGLE_DRAWER,
        payload: payload,
      });
    } else {
      dispatchState({
        type: ACTIONS.TOGGLE_DRAWER,
      });
    }
  };

  const handleToggleAddProgramModal = () => {
    setshowAddProgramModal(!showAddProgramModal);
  };

  const handleFetchAllPrograms = async () => {
    try {
      await dispatch(getAllPrograms());
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateProgram = async ({
    id,
    programName,
    diagnosis,
    patientTerm,
    videos,
  }) => {
    try {
      await dispatch(
        updateProgram({
          id: id,
          programName: programName,
          diagnosis: diagnosis,
          patientTerm: patientTerm,
          videos: videos,
        })
      );

      await dispatch(getAllPrograms());

      handleChangeActiveProgram({
        Id: id,
        ProgramName: programName,
        Diagnosis: diagnosis,
        PatientTerm: patientTerm,
        Videos: videos,
      });

      setNotification(
        'success',
        'Favorite Added!',
        'Program was updated successfully!'
      );
    } catch (error) {
      console.log(error);
      setNotification(
        'error',
        'Error!',
        'Unable to update program. Please try again later!'
      );
    }
  };

  const handleAddVideo = async ({
    programId,
    drip,
    title,
    description,
    notificationTitle,
    notificationDescription,
    url,
  }) => {
    try {
      await dispatch(
        addVideo({
          programId: programId,
          drip: drip,
          title: title,
          description: description,
          notificationTitle: notificationTitle,
          notificationDescription: notificationDescription,
          url: url,
        })
      );

      await dispatch(getAllPrograms());
      await dispatch(getProgram({ id: programId }));

      handleToggleDrawer();

      setNotification('success', 'Success!', 'Video was added successfully.');
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'Unable to add video. Please try again later!'
      );
      console.log(error);
    }
  };

  const handleAddProgram = async ({ programName, diagnosis, patientTerm }) => {
    try {
      await dispatch(
        addProgram({
          programName: programName,
          diagnosis: diagnosis,
          patientTerm: patientTerm,
        })
      );

      await dispatch(getAllPrograms());

      handleToggleAddProgramModal();

      setNotification('success', 'Success!', 'Program was added successfully.');
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'Unable to add program. Please try again later.'
      );
      console.log(error);
    }
  };

  return (
    <Fragment>
      <DropdownNavigation />
      {showAddProgramModal && (
        <AddProgramModal
          carePlan={carePlan}
          showAddProgramModal={showAddProgramModal}
          setshowAddProgramModal={setshowAddProgramModal}
          dispatchState={dispatchState}
          handleAddProgram={handleAddProgram}
        />
      )}
      {state.showDrawer && (
        <VideoDrawer
          state={state}
          carePlan={carePlan}
          dispatchState={dispatchState}
          handleAddVideo={handleAddVideo}
          handleUpdateProgram={handleUpdateProgram}
          handleToggleDrawer={handleToggleDrawer}
        />
      )}
      <div className="ptw-main-body">
        <Row className="mb-3" align="middle" justify="space-between">
          <Breadcrumb>
            <DispatchLinkItem title="Admin&nbsp;" url="/administrator" />
            <Breadcrumb.Item
              active={state.screen === 'program' ? true : false}
              onClick={() => handleChangeScreen({ screen: 'program' })}
            >
              Care Plan Program
            </Breadcrumb.Item>
            {state.screen === 'details' && (
              <Breadcrumb.Item
                active={state.screen === 'details' ? true : false}
                onClick={() => handleChangeScreen({ screen: 'details' })}
              >
                {carePlan &&
                  carePlan.activeProgram &&
                  carePlan.activeProgram.ProgramName}
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
          <div className="hide-mb">
            {state.screen === 'program' && (
              <Button
                type="primary"
                className="btn-primary ptw-btn"
                size="large"
                onClick={() => setshowAddProgramModal(true)}
              >
                <PlusOutlined /> Add Care Plan Program
              </Button>
            )}
            {state.screen === 'details' && (
              <Button
                type="primary"
                className="btn-primary ptw-btn"
                size="large"
                onClick={() => handleToggleDrawer()}
              >
                <PlusOutlined /> Add Video Sequence
              </Button>
            )}
          </div>
        </Row>
        <Space className="hide-dt show-mb mb-3" direction="vertical">
          {state.screen === 'program' && (
            <Button
              type="primary"
              className="btn-primary ptw-btn"
              size="large"
              onClick={() => setshowAddProgramModal(true)}
            >
              <PlusOutlined /> Add Care Plan Program
            </Button>
          )}
          {state.screen === 'details' && (
            <Button
              type="primary"
              className="btn-primary ptw-btn"
              size="large"
              onClick={() => handleToggleDrawer()}
            >
              <PlusOutlined /> Add Video Sequence
            </Button>
          )}
        </Space>
        {/* <div className="tab-header">
          <Breadcrumb className="care-breadcrumb">
            <DispatchLinkItem title="Admin&nbsp;" url="/administrator" />
            <Breadcrumb.Item
              active={state.screen === 'program' ? true : false}
              onClick={() => handleChangeScreen({ screen: 'program' })}
            >
              Care Plan Program
            </Breadcrumb.Item>
            {state.screen === 'details' && (
              <Breadcrumb.Item
                active={state.screen === 'details' ? true : false}
                onClick={() => handleChangeScreen({ screen: 'details' })}
              >
                {carePlan &&
                  carePlan.activeProgram &&
                  carePlan.activeProgram.ProgramName}
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
          <div className="tab-header-controls">
            {state.screen === 'program' && (
              <Button
                type="primary"
                className="btn-primary ptw-btn"
                size="large"
                onClick={() => setshowAddProgramModal(true)}
              >
                <PlusOutlined /> Add Care Plan Program
              </Button>
            )}
            {state.screen === 'details' && (
              <Button
                type="primary"
                className="btn-primary ptw-btn"
                size="large"
                onClick={() => handleToggleDrawer()}
              >
                <PlusOutlined /> Add Video Sequence
              </Button>
            )}
          </div>
        </div> */}
        {carePlan.isLoading && <Loading isLoading={true} />}
        {state.screen === 'program' &&
          carePlan &&
          carePlan.data &&
          !carePlan.isLoading && (
            <Program
              data={carePlan.data}
              state={state}
              handleChangeScreen={handleChangeScreen}
            />
          )}
        {state.screen === 'details' && !carePlan.isLoading && (
          <ProgramDetails
            carePlan={carePlan}
            dispatch={dispatch}
            dispatchState={dispatchState}
            handleUpdateProgram={handleUpdateProgram}
            handleToggleDrawer={handleToggleDrawer}
          />
        )}
      </div>
    </Fragment>
  );
}

const mapStateToProps = state => ({
  carePlan: state.carePlan,
});

export default connect(mapStateToProps)(CarePlan);
