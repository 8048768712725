import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Divider, List, Modal, Typography, Button, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import states from '../../../states';
import services from '../../../services';
import setNotification from '../../../utils/setNotification.utils';

const EmailDistroModal = ({ loadingProviders, open, onClose }) => {
  const [nps, setNps] = useRecoilState(states.nps);
  const groups = useRecoilValue(states.groups);

  const [loadingDistroList, setLoadingDistroList] = useState(false);
  const [savingDistroList, setSavingDistroList] = useState(false);

  useEffect(() => {
    if (open) {
      getDistroList();
    }
  }, [open]);

  const getDistroList = async () => {
    try {
      setLoadingDistroList(true);

      const response = await services.nps.getDistroList();
      const { data } = response.data;

      setNps(prevState => ({
        ...prevState,
        distroList: data
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDistroList(false);
    }
  };

  const handleSelect = value => {
    const provider = groups.providers.find(p => p.Sub === value);
    if (provider) {
      setNps(prevState => ({
        ...prevState,
        distroList: [
          ...nps.distroList,
          {
            EmailAddress: provider.EmailAddress,
            Sub: provider.Sub,
            FirstName: provider.FirstName,
            LastName: provider.LastName
          }
        ]
      }));
    }
  };

  const handleRemove = value => {
    setNps(prevState => ({
      ...prevState,
      distroList: nps.distroList.filter(item => item.Sub !== value.Sub)
    }));
  };

  const handleSubmit = async () => {
    const notif = {
      type: 'success',
      message: 'Success!',
      description: 'Emails successfully added.'
    };

    try {
      setSavingDistroList(true);

      await services.nps.updateDistroList({
        groupId: groups.user.id,
        providers: nps.distroList
      });
    } catch (error) {
      console.log(error);

      notif.type = 'error';
      notif.message = 'Error!';
      notif.description = 'An error occurred while updating email distro list.';
    } finally {
      setSavingDistroList(false);
      onClose();
      setNotification(notif.type, notif.message, notif.description);
    }
  };

  return (
    <Modal
      closable
      title="NPS Detractor Emails"
      okText="Save"
      visible={open}
      onOk={handleSubmit}
      onCancel={onClose}
      okButtonProps={{ loading: savingDistroList }}
      bodyStyle={{ height: 550, overflow: 'auto' }}
    >
      <Select
        style={{ width: '100%' }}
        className="nps-reports-select"
        showSearch
        placeholder="Select Provider"
        optionFilterProp="children"
        loading={loadingProviders}
        disabled={loadingProviders}
        onChange={handleSelect}
        value={null}
        filterOption={(input, option) => {
          if (option && option.label) {
            const label = String(option.label).toLowerCase();
            if (label.includes(String(input).toLowerCase())) {
              return option;
            }
          }
        }}
      >
        {[
          ...groups.providers.filter(item => item.Active),
          ...groups.providers.filter(item => !item.Active)
        ]
          .filter(item => !nps.distroList.some(i => i.Sub === item.Sub))
          .map((item, i) => {
            const value = item.Sub;
            const label = `${item.FirstName} ${item.LastName}  (${item.EmailAddress})`;

            return (
              <Select.Option key={i} value={value}>
                {!item.Active ? (
                  <em style={{ color: 'grey' }}>{label}</em>
                ) : (
                  label
                )}
              </Select.Option>
            );
          })}
      </Select>

      <Divider />

      <List
        bordered
        size="small"
        loading={loadingDistroList}
        dataSource={nps.distroList}
        renderItem={item => {
          const profile = groups.providers.find(p => p.Sub === item.Sub);
          const inactive = !profile?.Active;

          return (
            <List.Item
              style={{ paddingRight: 0 }}
              actions={[
                <Button
                  type="text"
                  shape="circle"
                  onClick={() => handleRemove(item)}
                >
                  <CloseOutlined />
                </Button>
              ]}
            >
              <Typography.Text
                italic={inactive}
                style={{ color: inactive && 'grey' }}
              >
                {item.FirstName} {item.LastName}{' '}
                <label style={{ color: inactive && 'grey' }}>
                  ({item.EmailAddress})
                </label>
              </Typography.Text>
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};

export default EmailDistroModal;
