import apiFetch from '../utils/fetch.utils';

export const sendPdf = async payload => {
  try {
    const uri = 'documents';
    const method = 'POST';

    return apiFetch(uri, method, {
      body: payload
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};
