import { Button, DatePicker, notification, Space } from 'antd';
import { useState } from 'react';

import config from '../../../../../config';
import services from '../../../../../services';

const ActionButtons = ({ profile, cognito, openSearch, getProfile }) => {
  const [updating, setUpdating] = useState(false);
  const [computing, setComputing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [period, setPeriod] = useState(null);
  const [hasPeriod, setHasPeriod] = useState(true);
  const [migrating, setMigrating] = useState(false);

  const handleUpdateProfile = async action => {
    try {
      const { GroupId, Role, EmailAddress } = profile;
      setUpdating(true);

      await services.cognito.updateProfile(action, {
        GroupId,
        Role,
        EmailAddress
      });

      await getProfile(EmailAddress);

      notification.success({
        message: 'Update Success',
        description: 'User profile updated successfully',
        duration: 3
      });
    } catch (error) {
      notification.error({
        message: 'Update Failed',
        description: 'An error occurred while updating data',
        duration: 3
      });
    } finally {
      setUpdating(false);
    }
  };

  const handleRunAnalytics = async () => {
    try {
      setComputing(true);

      const { Sub, GroupId } = profile;
      await services.rtm.runAnalytics(Sub, GroupId);

      notification.success({
        message: 'Analytics Success',
        description: 'RTM analytics done successfully',
        duration: 3
      });
    } catch (error) {
      notification.error({
        message: 'Analytics Failed',
        description: 'An error occurred while running RTM analytics',
        duration: 3
      });
    } finally {
      setComputing(false);
    }
  };

  const handlePickDate = value => {
    if (value) {
      const isoDate = new Date(value).toISOString();
      const start = Date.parse(isoDate.substring(0, isoDate.indexOf('T')));
      const month = 1000 * 60 * 60 * 24 * 30;
      const hours = 23 * 60 * 60000;
      const minutes = 59 * 60000;
      const seconds = 59 * 1000;

      setPeriod({
        start,
        end: start + month + hours + minutes + seconds
      });
      setHasPeriod(true);
    } else {
      setPeriod(null);
    }
  };

  const handleSaveNewPeriod = async () => {
    if (period) {
      try {
        setSaving(true);

        const { Sub, GroupId } = profile;
        await services.rtm.updateBillingPeriod({
          sub: Sub,
          groupId: GroupId,
          start: period.start,
          end: period.end
        });

        notification.success({
          message: 'Update Success',
          description: 'Billing period updated successfully',
          duration: 3
        });
      } catch (error) {
        notification.error({
          message: 'Save Failed',
          description: 'An error occurred while saving new billing period',
          duration: 3
        });
      } finally {
        setSaving(false);
      }
    } else {
      setHasPeriod(false);
    }
  };

  const handleMigrateBoards = async () => {
    try {
      setMigrating(true);

      const { EmailAddress } = profile;
      await services.admin.migrateBoards(EmailAddress);

      notification.success({
        message: 'Migration Success',
        description: 'Messages successfully migrated',
        duration: 3
      });
    } catch (error) {
      notification.error({
        message: 'Migration Failed',
        description: 'An error occurred while migrating messages',
        duration: 3
      });
    } finally {
      setMigrating(false);
    }
  };

  return (
    <Space size="large">
      {!config.root.includes('ofpw8yar0c') &&
        (profile.Role === 'pt' || profile.Role === 'ga') && (
          <Button
            type="primary"
            loading={migrating}
            onClick={handleMigrateBoards}
          >
            MIGRATE MESSAGES
          </Button>
        )}

      {profile && cognito && !openSearch && profile.Role === 'p' && (
        <Button
          type="primary"
          loading={updating}
          onClick={() => handleUpdateProfile('index patient')}
        >
          INDEX PATIENT
        </Button>
      )}

      {profile && !cognito && (
        <Button
          type="primary"
          loading={updating}
          onClick={() => handleUpdateProfile('add to cognito')}
        >
          ADD TO COGNITO
        </Button>
      )}

      {profile && profile.Role === 'p' && (
        <Space size="large">
          <Button
            type="primary"
            loading={computing}
            onClick={handleRunAnalytics}
          >
            RUN ANALYTICS
          </Button>

          <Space>
            <DatePicker
              style={{ width: '14.1em', borderColor: !hasPeriod && '#FF0000' }}
              placeholder="PICK BILLING START"
              format="MMMM DD, YYYY"
              onChange={handlePickDate}
            />

            <Button
              type="primary"
              loading={saving}
              onClick={handleSaveNewPeriod}
            >
              UPDATE PERIOD
            </Button>
          </Space>
        </Space>
      )}
    </Space>
  );
};

export default ActionButtons;
