import { atom } from 'recoil';

const defaultState = {
  list: [],
};

const FAVORITES_STATE = atom({
  key: 'FAVORITES_STATE',
  default: defaultState,
});

export default FAVORITES_STATE;
