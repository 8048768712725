import { createAction } from 'redux-actions'

export const UPDATE_CURRENT_EXERCISE = 'UPDATE_CURRENT_LIBRARY_EXERCISE'
export const updateCurrentExercise = createAction(UPDATE_CURRENT_EXERCISE, values => ({values}))

export const SET_EXERCISE_FORM_TYPE = 'SET_LIBRARY_EXERCISE_FORM_TYPE'
export const setExerciseFormType = createAction(SET_EXERCISE_FORM_TYPE, formType => ({formType}))

export const IS_LOADING = 'EXERCISES_LIBRARY_IS_LOADING'
export const isLoading = createAction(IS_LOADING)

export const DONE_LOADING = 'EXERCISES_LIBRARY_DONE_LOADING'
export const doneLoading = createAction(DONE_LOADING)

export const IS_SEARCHING = 'EXERCISES_LIBRARY_IS_SEARCHING'
export const isSearching = createAction(IS_SEARCHING)

export const DONE_SEARCHING = 'EXERCISES_LIBRARY_IS_SEARCHING'
export const doneSearching = createAction(DONE_SEARCHING)