import React, { Fragment } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Checkbox, Col, Divider, Form, Row, Space, Typography } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updateFormParamsError } from '../actions/groupExercises';
import {
  anyInvalidTouchedField,
  showParamsError,
} from '../../exercise-library/selectors';
import {
  exerciseNameValidator,
  paramValidator,
  instructionalVideoURLValidator,
  instructionsValidator,
  tagsValidator,
  requiredValidator,
} from '../validators/groupExercises';
import {
  popDescription,
  popImage,
  popVideo,
  popTag,
  popParameters,
  popName,
} from './pops';
import { ImageSelector } from '../../../components/file-uploader';
import Input from '../Input';
import ParamInput from './ExerciseParamInput';
import ProfileImage from '../../patients/Modals/ProfileImage';

class ExerciseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cropping: false, img: null };
    const { sets, reps, hold } = props.initialValues;

    props.dispatch(
      updateFormParamsError(this.validateParams({ sets, reps, hold }))
    );
  }

  handleParamOnChange = paramName => (e, newVal) => {
    const { dispatch, exerciseParams } = this.props;
    dispatch(
      updateFormParamsError(
        this.validateParams({
          ...exerciseParams,
          [paramName]: newVal,
        })
      )
    );
  };

  validateParams = ({ sets, reps, hold }) => !!(sets || reps || hold);

  hideDeleteModal = () => {
    this.setState({ deleteModal: false });
  };

  showDeleteModal = () => {
    this.setState({ deleteModal: true });
  };

  componentWillUnmount() {
    const { handleDismount } = this.props;
    handleDismount();
  }

  render() {
    const {
      handleSubmit,
      showParamsError,
      anyInvalidTouchedField,
      onFileSelected,
      onFileRemoved,
      initialValues,
      imageValidator,
      validImage,
      validParams,
      visibleProfile,
      file,
      img,
      cropping,
      handleImageCrop,
      hideCrop,
      onExit,
    } = this.props;

    const paramsError = showParamsError && !validParams;

    return (
      <React.Fragment>
        {cropping && (
          <ProfileImage
            show={cropping}
            onHide={hideCrop}
            closeButton={hideCrop}
            image={file}
            onSubmit={handleImageCrop}
            onExit={onExit}
            isExerciseImage={true}
          />
        )}
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[16, 0]}>
            {anyInvalidTouchedField ||
              (paramsError && (
                <div className="alert alert-danger" role="alert">
                  <i className="fa fa-exclamation-triangle fa-lg" />
                  Highlighted fields are invalid.
                </div>
              ))}
            <Col span={24}>
              <Field
                name="ExerciseName"
                label="Exercise Name"
                placeholder="Enter exercise name"
                tooltip={popName}
                component={Input}
                type="text"
                validate={[requiredValidator, exerciseNameValidator]}
              />
            </Col>
            <Col span={24}>
              <Form.Item
                style={{ marginBottom: -40 }}
                label="Parameters"
                tooltip={popParameters}
              />
            </Col>
            <Col span={8}>
              <Field
                name="Sets"
                label="Sets"
                placeholder="Enter sets"
                component={ParamInput}
                type="text"
                validate={[paramValidator]}
                onChange={this.handleParamOnChange('sets')}
              />
            </Col>
            <Col span={8}>
              <Field
                name="Reps"
                label="Reps"
                placeholder="Enter reps"
                component={ParamInput}
                type="text"
                validate={[paramValidator]}
                onChange={this.handleParamOnChange('reps')}
              />
            </Col>
            <Col span={8}>
              <Field
                name="Hold"
                label="Hold"
                placeholder="Enter hold"
                component={ParamInput}
                type="text"
                validate={[paramValidator]}
                onChange={this.handleParamOnChange('hold')}
              />
            </Col>
            <Col span={24}>
              <Field
                name="Tag"
                label="Tags"
                placeholder="Enter tags"
                tooltip={popTag}
                component={Input}
                type="text"
                validate={[tagsValidator]}
                hideErrorMessage
              />
            </Col>
            <Col span={24}>
              <Field
                name="Instructions"
                label="Instructions"
                placeholder="Enter instructions"
                tooltip={popDescription}
                component={Input}
                type="textarea"
                componentClass="textarea"
                validate={[instructionsValidator]}
                hideErrorMessage
              />
            </Col>
            <Col span={24}>
              <Field
                name="Video"
                label="Exercise Video URL"
                placeholder="Enter exercise video URL"
                tooltip={popVideo}
                component={Input}
                type="text"
                validate={[instructionalVideoURLValidator]}
                hideErrorMessage
              />
            </Col>
            <Col span={12}>
              <Form.Item
                label="Exercise Image"
                tooltip={popImage}
                help={
                  !validImage && (
                    <Typography.Paragraph type="danger">
                      Selected image is either not in JPG, JPEG, or PNG format
                      or has a size of more than 2.5MB.
                    </Typography.Paragraph>
                  )
                }
              >
                <div
                  className={!validImage ? 'has-error' : ''}
                  style={{ height: '160px', width: '260px' }}
                >
                  <ImageSelector
                    isUser={false}
                    defaultImageUrl={
                      initialValues.id
                        ? `https://d2p805pqn3eul9.cloudfront.net/${initialValues.GroupId}/${initialValues.Id}.jpg`
                        : ''
                    }
                    ref={selector => {
                      this.selector = selector;
                    }}
                    src={
                      initialValues.Id && img === null
                        ? `https://d2p805pqn3eul9.cloudfront.net/${initialValues.GroupId}/${initialValues.Id}.jpg`
                        : img
                    }
                    imageValidator={imageValidator}
                    onFileSelected={onFileSelected}
                    onFileRemoved={onFileRemoved}
                    height={160}
                    width={260}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <div style={{ height: 100 }} />
              <Space direction="vertical" align="left" size={12}>
                <Checkbox name="MyExercises">Save to My Exercises</Checkbox>
                {visibleProfile.Role === 'sa' && (
                  <Checkbox name="ClinicExercise">
                    Save to Clinic Exercise Library
                  </Checkbox>
                )}
              </Space>
            </Col>
            {visibleProfile.Role === 'sa' && (
              <Fragment>
                <Divider />
                <Col span={24}>
                  <Field
                    label="Foundational Exercise"
                    name="Foundational"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    component={Input}
                    type="switch"
                  />
                </Col>
              </Fragment>
            )}
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    group: state.visibleProfile.GroupId,
    showParamsError: showParamsError('exercise')(state),
    anyInvalidTouchedField: anyInvalidTouchedField('exercise')(state),
    exerciseParams: formValueSelector('exercise')(
      state,
      'sets',
      'reps',
      'hold'
    ),
    validParams: state.form.exercise.validParams,
    visibleProfile: state.visibleProfile,
  };
}

export default reduxForm({
  form: 'exercise',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(connect(mapStateToProps)(withRouter(ExerciseForm)));
