import apiFetch from '../utils/fetch.utils';
import config from '../config';

export const getProfile = async input => {
  try {
    const uri = `admin/users?search=${encodeURIComponent(input.trim())}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateProfile = async payload => {
  try {
    const uri = 'admin/users';
    const method = 'PUT';

    return apiFetch(uri, method, { body: payload });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const migrateProfile = async emailAddress => {
  try {
    const uri = 'admin/migrate-profile';
    const method = 'POST';

    return apiFetch(uri, method, {
      body: {
        emailAddress,
      },
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const migrateBoards = async therapist => {
  try {
    const uri = 'admin/migrate-boards';
    const method = 'POST';

    return apiFetch(uri, method, {
      body: {
        therapist,
      },
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const sendAnnouncement = async payload => {
  try {
    const uri = 'admin/announcements';
    const method = 'POST';

    return apiFetch(uri, method, {
      body: {
        ...payload,
      },
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAnnouncement = async () => {
  try {
    const uri = 'announcements';
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
