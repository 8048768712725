import React from 'react';
import { connect } from 'react-redux';
import PushNotificationsPage from './PushNotificationsPage';
import DropdownNavigation from '../../components/DropdownNavigation';

const PushNotifications = (props) => {
  return (
    <React.Fragment>
      <DropdownNavigation />
      <div className="ptw-main-body">
        {' '}
        <PushNotificationsPage {...props} />
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    groupId: state.visibleProfile.GroupId,
    emailAddress: state.visibleProfile.EmailAddress,
  };
}

export default connect(mapStateToProps)(PushNotifications);
