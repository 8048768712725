import React, { Component } from 'react';
import { Tree } from 'antd';
const { TreeNode } = Tree;

export const keyValueMap = {
  '0-1-0-0-0': 'Cervical Spine Flexors',
  '0-1-0-0-1': 'Cervical Spine Extensors',
  '0-1-0-0-2': 'Cervical Spine Rotators',
  '0-1-1-0-0': 'Ankle Dorsiflexors',
  '0-1-1-0-1': 'Ankle Plantarflexors',
  '0-1-1-0-2': 'Ankle Invertors',
  '0-1-1-0-3': 'Ankle Evertors',
  '0-1-1-1-0': 'Foot Intrinsics',
  '0-1-1-1-1': 'Toe Extensors',
  '0-1-1-1-2': 'Toe Flexors',
  '0-1-1-2-0': 'Hip Flexors',
  '0-1-1-2-1': 'Hip Extensors',
  '0-1-1-2-2': 'Hip Internal Rotators',
  '0-1-1-2-3': 'Hip External Rotators',
  '0-1-1-2-4': 'Hip Abductors',
  '0-1-1-2-5': 'Hip Adductors',
  '0-1-1-3-0': 'Knee Extensors',
  '0-1-1-3-1': 'Knee Flexors',
  '0-1-1-3-2': 'Patella',
  '0-1-3-1-4': 'Hand Thumb',
  '0-1-3-3-3': 'Shoulder Flexors',
  '0-1-3-3-4': 'Shoulder Extensors',
  '0-1-3-3-5': 'Shoulder Abductors',
  '0-1-3-3-6': 'Shoulder Internal Rotators',
  '0-1-3-3-7': 'Shoulder External Rotators',
  '0-1-3-4-0': 'Wrist Extensors',
  '0-1-3-4-1': 'Wrist Flexors',
  '0-1-3-4-2': 'Wrist Pronators',
  '0-1-3-4-3': 'Wrist Supinators',
  '0-1-3-4-4': 'Wrist Radial Deviation',
  '0-1-3-4-5': 'Wrist Ulnar Deviation',
};

export const ignoredKeys = ['0-0', '0-1', '0-2', '0-3', '0-4', '0-5', '0-6'];

export class Filters extends Component {
  render() {
    const { onSelect, checkedKeys, onClick, expandedKeys, onExpand } =
      this.props;
    return (
      <div className="filters-panel">
        <div className="filters-container sticky-panel">
          <Tree
            className="hide-file-icon"
            checkedKeys={checkedKeys}
            onCheck={onSelect}
            checkable
            checkStrictly
            onSelect={onClick}
            expandedKeys={expandedKeys}
            onExpand={onExpand}
          >
            <TreeNode title="Position" key="0-3" disableCheckbox>
              <TreeNode title="Dynamic" key="0-3-0" />
              <TreeNode title="Kneeling" key="0-3-1" />
              <TreeNode title="Prone" key="0-3-2" />
              <TreeNode title="Quadruped" key="0-3-3" />
              <TreeNode title="Side Lying" key="0-3-4" />
              <TreeNode title="Sitting" key="0-3-5" />
              <TreeNode title="Standing" key="0-3-6" />
              <TreeNode title="Supine" key="0-3-7" />
            </TreeNode>
            <TreeNode title="Body Parts" key="0-1" disableCheckbox>
              <TreeNode title="Head and Neck" key="0-1-0">
                <TreeNode title="Cervical Spine" key="0-1-0-0">
                  <TreeNode
                    title="Flexors"
                    value="Cervical Spine Flexors"
                    key="0-1-0-0-0"
                  />
                  <TreeNode
                    title="Extensors"
                    value="Cervical Spine Extensors"
                    key="0-1-0-0-1"
                  />
                  <TreeNode
                    title="Rotators"
                    value="Cervical Spine Rotators"
                    key="0-1-0-0-2"
                  />
                </TreeNode>
                <TreeNode title="Face" key="0-1-0-1" />
                <TreeNode title="Jaw" key="0-1-0-2" />
                <TreeNode title="Vestibular" key="0-1-0-3" />
              </TreeNode>
              <TreeNode title="Lower Extremity" key="0-1-1">
                <TreeNode title="Ankle" key="0-1-1-0">
                  <TreeNode
                    title="Dorsiflexors"
                    value="Ankle Dorsiflexors"
                    key="0-1-1-0-0"
                  />
                  <TreeNode
                    title="Plantarflexors"
                    value="Ankle Plantarflexors"
                    key="0-1-1-0-1"
                  />
                  <TreeNode
                    title="Invertors"
                    value="Ankle Invertors"
                    key="0-1-1-0-2"
                  />
                  <TreeNode
                    title="Evertors"
                    value="Ankle Evertors"
                    key="0-1-1-0-3"
                  />
                </TreeNode>
                <TreeNode title="Foot" key="0-1-1-1">
                  <TreeNode
                    title="Intrinsics"
                    value="Foot Intrinsics"
                    key="0-1-1-1-0"
                  />
                  <TreeNode
                    title="Extensors"
                    value="Toe Extensors"
                    key="0-1-1-1-1"
                  />
                  <TreeNode
                    title="Flexors"
                    value="Toe Flexors"
                    key="0-1-1-1-2"
                  />
                </TreeNode>
                <TreeNode title="Hip" key="0-1-1-2">
                  <TreeNode
                    title="Flexors"
                    value="Hip Flexors"
                    key="0-1-1-2-0"
                  />
                  <TreeNode
                    title="Extensors"
                    value="Hip Extensors"
                    key="0-1-1-2-1"
                  />
                  <TreeNode
                    title="Internal Rotators"
                    value="Hip Internal Rotators"
                    key="0-1-1-2-2"
                  />
                  <TreeNode
                    title="External Rotators"
                    value="Hip External Rotators"
                    key="0-1-1-2-3"
                  />
                  <TreeNode
                    title="Abductors"
                    value="Hip Abductors"
                    key="0-1-1-2-4"
                  />
                  <TreeNode
                    title="Adductors"
                    value="Hip Adductors"
                    key="0-1-1-2-5"
                  />
                </TreeNode>
                <TreeNode title="Knee" key="0-1-1-3">
                  <TreeNode
                    title="Extensors"
                    value="Knee Extensors"
                    key="0-1-1-3-0"
                  />
                  <TreeNode
                    title="Flexors"
                    value="Knee Flexors"
                    key="0-1-1-3-1"
                  />
                  <TreeNode title="Patella" key="0-1-1-3-2" />
                </TreeNode>
              </TreeNode>
              <TreeNode title="Trunk" key="0-1-2">
                <TreeNode title="Abdominals" key="0-1-2-0" />
                <TreeNode title="Chest" key="0-1-2-1" />
                <TreeNode title="Costovertebral" key="0-1-2-2" />
                <TreeNode title="Lumbar Spine" key="0-1-2-3" />
                <TreeNode title="Pelvis" key="0-1-2-4" />
                <TreeNode title="Thoracic Spine" key="0-1-2-5" />
              </TreeNode>
              <TreeNode title="Upper Extremity" key="0-1-3">
                <TreeNode title="Elbow" key="0-1-3-0">
                  <TreeNode title="Elbow Flexors" key="0-1-3-0-0" />
                  <TreeNode title="Elbow Extensors" key="0-1-3-0-1" />
                </TreeNode>
                <TreeNode title="Hand" key="0-1-3-1">
                  <TreeNode title="Finger Flexors" key="0-1-3-1-0" />
                  <TreeNode title="Finger Extensors" key="0-1-3-1-1" />
                  <TreeNode title="Finger Adductors" key="0-1-3-1-2" />
                  <TreeNode title="Finger Abductors" key="0-1-3-1-3" />
                  <TreeNode title="Thumb" value="Hand Thumb" key="0-1-3-1-4" />
                </TreeNode>
                <TreeNode title="Scapulothoracic" key="0-1-3-2"></TreeNode>
                <TreeNode title="Shoulder" key="0-1-3-3">
                  <TreeNode title="Lower Trap" key="0-1-3-3-0" />
                  <TreeNode title="Middle Trap" key="0-1-3-3-1" />
                  <TreeNode title="Serratus Anterior" key="0-1-3-3-2" />
                  <TreeNode
                    title="Flexors"
                    value="Shoulder Flexors"
                    key="0-1-3-3-3"
                  />
                  <TreeNode
                    title="Extensors"
                    value="Shoulder Extensors"
                    key="0-1-3-3-4"
                  />
                  <TreeNode
                    title="Abductors"
                    value="Shoulder Abductors"
                    key="0-1-3-3-5"
                  />
                  <TreeNode
                    title="Internal Rotators"
                    value="Shoulder Internal Rotators"
                    key="0-1-3-3-6"
                  />
                  <TreeNode
                    title="External Rotators"
                    value="Shoulder External Rotators"
                    key="0-1-3-3-7"
                  />
                </TreeNode>
                <TreeNode title="Wrist" key="0-1-3-4">
                  <TreeNode
                    title="Extensors"
                    value="Wrist Extensors"
                    key="0-1-3-4-0"
                  />
                  <TreeNode
                    title="Flexors"
                    value="Wrist Flexors"
                    key="0-1-3-4-1"
                  />
                  <TreeNode
                    title="Pronators"
                    value="Wrist Pronators"
                    key="0-1-3-4-2"
                  />
                  <TreeNode
                    title="Supinators"
                    value="Wrist Supinators"
                    key="0-1-3-4-3"
                  />
                  <TreeNode
                    title="Radial Deviation"
                    value="Wrist Radial Deviation"
                    key="0-1-3-4-4"
                  />
                  <TreeNode
                    title="Ulnar Deviation"
                    value="Wrist Ulnar Deviation"
                    key="0-1-3-4-5"
                  />
                </TreeNode>
              </TreeNode>
            </TreeNode>
            <TreeNode title="Movement/Direction" key="0-2" disableCheckbox>
              <TreeNode title="Flexion" key="0-2-0" />
              <TreeNode title="Extension" key="0-2-1" />
              <TreeNode title="Abduction" key="0-2-2" />
              <TreeNode title="Adduction" key="0-2-3" />
              <TreeNode title="External Rotation" key="0-2-4" />
              <TreeNode title="Internal Rotation" key="0-2-5" />
              <TreeNode title="Circumduction" key="0-2-6" />
              <TreeNode title="Rotation" key="0-2-7" />
            </TreeNode>
            <TreeNode title="Type" key="0-0" disableCheckbox>
              <TreeNode title="Aquatic" key="0-0-0" />
              <TreeNode title="Range of Motion" key="0-0-1">
                <TreeNode title="AAROM" key="0-0-1-1" />
                <TreeNode title="AROM" key="0-0-1-2" />
                <TreeNode title="PROM" key="0-0-1-3" />
              </TreeNode>
              <TreeNode title="Mobilization" key="0-0-2">
                <TreeNode title="Self Mobilization" key="0-0-2-0" />
                <TreeNode title="Static Hold Stretching" key="0-0-2-1" />
                <TreeNode title="Dynamic Stretching" key="0-0-2-2" />
              </TreeNode>
              <TreeNode title="Strengthening" key="0-0-3">
                <TreeNode title="Isometrics" key="0-0-3-0" />
                <TreeNode title="Concentrics" key="0-0-3-1" />
                <TreeNode title="Eccentrics" key="0-0-3-2" />
              </TreeNode>
              <TreeNode title="Functional" key="0-0-4">
                <TreeNode title="Agility" key="0-0-4-0" />
                <TreeNode title="Plyometrics" key="0-0-4-1" />
                <TreeNode title="Gait Training" key="0-0-4-2" />
              </TreeNode>
              <TreeNode title="Proprioception" key="0-0-5">
                <TreeNode title="Balance" key="0-0-5-0" />
                <TreeNode title="Vestibular" key="0-0-5-1" />
              </TreeNode>
              <TreeNode title="Pediatric" key="0-0-6" />
            </TreeNode>
            <TreeNode title="Equipment" key="0-4" disableCheckbox>
              <TreeNode title="Ankle Weight" key="0-4-0" />
              <TreeNode title="Barbell" key="0-4-1" />
              <TreeNode title="BOSU Ball" key="0-4-2" />
              <TreeNode title="Cane" key="0-4-3" />
              <TreeNode title="Dowel" key="0-4-4" />
              <TreeNode title="Dumbbell" key="0-4-5" />
              <TreeNode title="Exercise Band" key="0-4-6" />
              <TreeNode title="Exercise Ball" key="0-4-7" />
              <TreeNode title="Foam Pad" key="0-4-8" />
              <TreeNode title="Foam Roller" key="0-4-9" />
              <TreeNode title="Kettlebell" key="0-4-10" />
              <TreeNode title="Pulley" key="0-4-11" />
              <TreeNode title="Step" key="0-4-12" />
              <TreeNode title="Strap" key="0-4-13" />
              <TreeNode title="TRX" key="0-4-14" />
              <TreeNode title="Wobble Board" key="0-4-15" />
              <TreeNode title="Yoga Block" key="0-4-16" />
            </TreeNode>
          </Tree>
        </div>
      </div>
    );
  }
}
