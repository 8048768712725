import React, { Fragment, useState } from 'react';
import { Breadcrumb, Form, Input, Button } from 'antd';
import { ImageOutline } from 'react-ionicons';
import { useSetRecoilState } from 'recoil';

import states from '../../states';
import { sendAnnouncement } from '../../services/admin';

import setNotification from '../../utils/setNotification.utils';
import DropdownNavigation from '../../components/DropdownNavigation';
import DispatchLinkItem from '../../components/DispatchLinkItem';
import { LinkOutlined } from '@ant-design/icons';

const ReleaseAnnouncement = () => {
  const setAnnouncement = useSetRecoilState(states.admin);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = async values => {
    try {
      setLoading(true);
      const response = await sendAnnouncement(values);

      if (response.status === 200) {
        setNotification(
          'success',
          'Success!',
          'Message has been sent successfully.'
        );

        setAnnouncement({
          Title: values.title,
          Message: values.message,
          ReleaseNotes: values.releaseNotes,
          Version: values.version,
          Image: values.image,
        });

        form.resetFields();
      }
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while sending message.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <DropdownNavigation />
      <div className="ptw-main-body">
        <div className="tab-header">
          <Breadcrumb>
            <DispatchLinkItem title="Admin&nbsp;" url="/administrator" />
            <Breadcrumb.Item active>Release Announcement</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Form
          form={form}
          labelWrap
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 18 }}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Title is required.' }]}
          >
            <Input size="large" placeholder="Enter title" />
          </Form.Item>
          <Form.Item
            label="Message"
            name="message"
            rules={[{ required: true, message: 'Message is required.' }]}
          >
            <Input.TextArea
              size="large"
              autoSize={{ minRows: 4, maxRows: 12 }}
              placeholder="Compose a message"
            />
          </Form.Item>
          <Form.Item
            label="Release Notes Link"
            name="releaseNotes"
            rules={[
              { required: true, message: 'Release notes link is required.' },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter release notes link"
              suffix={<LinkOutlined style={{ marginBottom: -5 }} />}
            />
          </Form.Item>
          <Form.Item label="Image URL" name="image">
            <Input
              size="large"
              placeholder="Enter image URL"
              suffix={<ImageOutline style={{ marginBottom: -5 }} />}
            />
          </Form.Item>
          <Form.Item
            label="Version"
            name="version"
            validateTrigger="onBlur"
            rules={[{ required: true, message: 'Version is required.' }]}
          >
            <Input size="large" placeholder="Enter web app version" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 3, span: 18 }}>
            <Button
              size="large"
              className="ptw-btn"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Fragment>
  );
};

export default ReleaseAnnouncement;
