import { createAction } from 'redux-actions';
import { API_REQUEST } from '../membership/middleware';

export const POST_PUSH_NOTIFICATION = 'POST_PUSH_NOTIFICATION';
export const postPushNotification = payload => {
  return {
    [API_REQUEST]: createAction(POST_PUSH_NOTIFICATION)({
      resource: 'push-notification',
      method: 'POST',
      body: payload
    })
  };
};

export const GET_PUSH_HISTORY = 'GET_PUSH_HISTORY';
export const getPushHistory = groupId => {
  return {
    [API_REQUEST]: createAction(GET_PUSH_HISTORY)({
      resource: 'push-history',
      method: 'GET',
      query: `groupId=${groupId}`
    })
  };
};

export const UPDATE_PUSH_HISTORY = 'UPDATE_PUSH_HISTORY';
export const updatePushHistory = payload => {
  return {
    [API_REQUEST]: createAction(UPDATE_PUSH_HISTORY)({
      resource: 'push-history',
      method: 'POST',
      body: payload
    })
  };
};

export const SEND_GROUP_NOTIFICATION = 'SEND_GROUP_NOTIFICATION';
export const sendGroupNotification = payload => ({
  [API_REQUEST]: createAction(SEND_GROUP_NOTIFICATION)({
    resource: 'push-notification',
    method: 'POST',
    body: payload
  })
});
