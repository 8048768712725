import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ImageComponent from './ImageComponent';
import './styles.css';
import { calculateAchievements } from './exerciseHelpers';
import TextTranslation from '../../../I18n/TranslateText';

const achievements = {
  exercises: [1, 10, 25, 50],
  routines: [1, 5, 15, 30],
};

class AchievementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      earnedAchievements: { exercises: [], routines: [null] },
    };
  }

  componentDidMount() {
    const {
      visibleProfile: { Prescriptions },
      rxList,
    } = this.props;

    const exercisesList = [];
    for (let i = 0; i < Prescriptions.length; i++) {
      const item = Prescriptions[i];
      const exercises = !item.Prescription
        ? item.exercises
        : item.Prescription.exercises;

      exercisesList.push(...exercises);
    }

    const earnedAchievements = calculateAchievements(rxList);
    this.setState({ earnedAchievements });
  }

  render() {
    const { locale, rxList } = this.props;
    const { earnedAchievements } = this.state;

    return (
      <div className="mobile-body container-fluid">
        <div className="container achievement-container">
          <div id="content">
            {Object.keys(achievements).map((k, i) => {
              return (
                <Row key={'achievements-' + i}>
                  <Row key={i}>
                    <div className="col-xs-12 header-section">
                      <TextTranslation currLocale={locale} currText={k} />
                    </div>
                  </Row>
                  <Row>
                    {achievements[k].map((score, index) => {
                      return (
                        <Col xs={6} sm={3} key={index}>
                          <ImageComponent
                            locale={locale}
                            achievements={earnedAchievements}
                            category={k}
                            valueToCheck={score}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Row>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentLocation: state.routerReducer.location.pathname,
  patients: state.patients.patients,
  visibleProfile: state.visibleProfile,
  locale: state.mobile.locale,
  rxList: state.mobile.rxList,
});

export default withRouter(connect(mapStateToProps)(AchievementList));
