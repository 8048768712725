import React from 'react';
import { Space, Button } from 'antd';
import {
  CheckCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

import states from '../../../states';
import { useRecoilValue } from 'recoil';

const Stepper = ({ currentStep, setCurrentStep, handleAssignPrescription }) => {
  const prescription = useRecoilValue(states.prescription);
  const { form } = prescription;

  return (
    <Space direction="horizontal" className="stepper-container pull-right">
      {currentStep !== 1 && (
        <Button
          size="large"
          icon={<LeftOutlined />}
          className="ptw-btn"
          onClick={() => setCurrentStep(prevStep => prevStep - 1)}
        >
          Back
        </Button>
      )}
      {currentStep !== 2 && (
        <Button
          size="large"
          type="primary"
          className="ptw-btn btn-primary"
          disabled={
            form &&
            form.prescription &&
            Array.isArray(form.prescription.exercises) &&
            form.prescription.exercises.length === 0
          }
          onClick={() => setCurrentStep(prevStep => prevStep + 1)}
        >
          Next <RightOutlined />
        </Button>
      )}
      {currentStep === 2 && (
        <React.Fragment>
          {/* <Button
            size="large"
            type="primary"
            className="ptw-btn btn-primary"
            onClick={() => setCurrentStep(prevStep => prevStep + 1)}
          >
            Save to Draft
          </Button> */}
          <Button
            size="large"
            type="primary"
            className="ptw-btn btn-primary"
            onClick={handleAssignPrescription}
            disabled={
              !form.name ||
              !form.prescription.frequency ||
              !form.prescription.perdiem
            }
          >
            Assign <CheckCircleOutlined />
          </Button>
        </React.Fragment>
      )}
    </Space>
  );
};

export default Stepper;
