import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";


class ReactiveModal extends Component {
    render(){

        return (
            <div className="reactive-modal">
            <Modal.Dialog >
                <Modal.Header>
                    <Modal.Title>We see that you are logged in as a provider on a mobile device.</Modal.Title>
                </Modal.Header>
                <Modal.Body>The provider portal is optimized for tablets and computers. We highly recommend you access the software on those devices, as some provider features are not available on a mobile device.</Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.closeWindow} bsStyle="primary">OK</Button>
                </Modal.Footer>
            </Modal.Dialog>
            </div>
        )

    }
}

export default ReactiveModal;