import React, { Component } from 'react';
import V from 'voca'
import {Thumbnail, Button, Col, Row, Well} from 'react-bootstrap';
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
} from 'react-share';

class Article extends Component{
    componentDidMount(){
        window.scroll(0,0)
    }
    render(){
        const {item, goBack} = this.props;
        const twitterIcon = <TwitterIcon size={32} round={true} />
        const facebookIcon = <FacebookIcon size={32} round={true} />
        return (
             <Well className={'article-container'}>
                 <Row>
                     <Col xsOffset={8} xs={4}>
                         <Button onClick={goBack} >Back</Button>
                     </Col>
                 </Row>
                <Row>
                    {item && item.content && item.content.src &&
                    <Col xs={12}>
                        <Thumbnail src={V.replaceAll(item.content.src, '150', '300')} alt="242x400" responsive={true}/>
                    </Col>
                    }
                </Row>
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12}><h3 style={{paddingTop:"10px"}}>{item.title}</h3></Col>
                            </Row>
                        <Row>
                            <Col xs={1}><FacebookShareButton children={facebookIcon} url={item.link}/></Col>
                            <Col xs={1}><TwitterShareButton  style={{paddingLeft:'10px'}} children={twitterIcon}url={item.link}/></Col>
                            <Col xsOffset={1} xs={9} style={{lineHeight:3}}>{item.pubDate.slice(0,17)}</Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <p className={"article-p"}>
                                    {Array.isArray(item.content.text)  && item.content.text.map((text,i)=>{
                                        return(<p key={'article'+i} style={{whiteSpace:'inherit'}}>
                                            {item.bold ? <b>text.text</b> : text.text}
                                        </p>)
                                    })}
                                </p>
                            </Col>
                        </Row>
                        </Col>
                </Row>
            </Well>
        );
    }
}

export default Article;