import * as admin from './admin';
import * as careplan from './careplan';
import * as cognito from './cognito';
import * as documents from './documents';
import * as educationalPdf from './educationalPdf';
import * as email from './email';
import * as exercises from './exercises';
import * as filters from './filters';
import * as groups from './groups';
import * as links from './links';
import * as message from './message';
import * as notification from './notification';
import * as nps from './nps';
import * as patients from './patients';
import * as preferences from './preferences';
import * as prescription from './prescription';
import * as providers from './providers';
import * as reports from './reports';
import * as rtm from './rtm';
import * as templates from './templates';
import * as user from './user';

const services = {
  admin,
  careplan,
  cognito,
  documents,
  educationalPdf,
  email,
  exercises,
  filters,
  groups,
  links,
  message,
  notification,
  nps,
  patients,
  preferences,
  prescription,
  providers,
  reports,
  rtm,
  templates,
  user
};

export default services;
