import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";


class PopupBlockerModal extends Component {
    render(){

        return(
            <div className="reactive-modal">
                <Modal.Dialog >
                    <Modal.Header>
                        <Modal.Title>Popups May be blocked</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>It is possible that your popups may be preventing the print screen from showing. Please
                    un-block popups to view the printing screen.</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.props.closeWindow} bsStyle="primary">OK</Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
        )
    }
}

export default PopupBlockerModal;