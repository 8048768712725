import React from 'react'


const ProgressBar = ({ totalExercises, completed }) => {
  const styles = {
    progressbar: {
      width: `${(completed / totalExercises) * 100}%`
    }
  }

  return (<div className="row">
    <div className="col-xs-10 col-sm-11 plan-progress-bar">
      <div className="progress">

        <div className="progress-bar progress-bar-ptw"
          role="progressbar"
          aria-valuenow="40"
          aria-valuemin="0"
          aria-valuemax="100"
          style={styles.progressbar} />
      </div>
    </div>
    <div className="col-xs-2 col-sm-1 plan-progress-details">
      <span className="pull-right">{`${completed}/${totalExercises}`}</span>
    </div>
  </div>)
}


export default ProgressBar
