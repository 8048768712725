import { getRecoil } from 'recoil-nexus';

import apiFetch from '../utils/fetch.utils';
import states from '../states';

export const getLocations = async (id = '') => {
  try {
    const groupsState = getRecoil(states.groups);
    const groupId = id || groupsState.user?.id;

    const method = 'GET';
    const uri = `groups/${groupId}/clinics?id=${groupId}`;

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCustoms = async (id, field) => {
  try {
    const method = 'GET';
    const uri = `groups/customs?groupId=${id}&field=${field}`;

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getGroupDetails = async (groupName) => {
  try {
    const method = 'GET';
    const uri = `groups/${groupName}`;

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getClinics = async (groupId) => {
  try {
    const method = 'GET';
    const uri = `groups/${groupId}/clinics?id=${groupId}`;

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getProviders = async (id = '') => {
  try {
    const groupsState = getRecoil(states.groups);
    const groupId = id || groupsState.user?.id;

    const method = 'GET';
    const uri = `groups/${groupId}/users/group-admin?groupId=${groupId}`;

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateCustoms = async (payload) => {
  try {
    const method = 'POST';
    const uri = 'groups/customs';

    return apiFetch(uri, method, {
      body: payload
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateReviewSender = async (payload) => {
  try {
    const uri = 'review-sender';
    const method = 'PUT';

    return apiFetch(uri, method, {
      body: payload
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getNonCaseIdRequired = async (groupId) => {
  try {
    const method = 'GET';
    const uri = `groups/${groupId}/clinics?nonCaseIdRequred=${true}`;

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
