import {
    GET_GROUPPATIENTS,
    OPEN_PATIENT_FORM_MODAL,
    CLOSE_PATIENT_FORM_MODAL,
    SELECT_GROUP_PATIENT
} from '../actions'
import {
    RESET_GRID,
    DONE_LOADING,
    IS_LOADING
} from '../actions/gridState'
import { FULFILLED, PENDING, REJECTED } from 'redux-promise-middleware'
import mapModel from '../../mapper';

const defaultState = {
    formModalOpen: false,
    groupPatients: [],
    isFetching: false,
    isSearching: false,
    isSubmitting: false,
    isLoadingGrid: true,
    showAddGroupPatient: false,//toggle on first so that default toggles off
    showEditGroupPatient: false,
    selectedGroupPatient: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case RESET_GRID:
            return {
                ...defaultState
            }
        case DONE_LOADING:
            return {
                ...state,
                isLoadingGrid: false
            }
        case IS_LOADING:
            return {
                ...state,
                isLoadingGrid: true
            }
        case '@@router/LOCATION_CHANGE':
            //are we navigating away from /groups/{anything}
            //if so cleanup state after prompting 'are you sure' dialog
            //todo: reevaluate the need for this
            if (!/\/groups\/\w+/g.test(action.payload.pathname)) {
                return {
                    ...state,
                    showEditGroupPatient: false,
                    showAddGroupPatient: false,
                    selectedGroupPatient: {}
                }
            } else {
                return {
                    ...state
                }
            }

        case SELECT_GROUP_PATIENT:
            return {
                ...state,
                selectedGroupPatient: action.payload
            }

        //group patients
        case `${GET_GROUPPATIENTS}_${PENDING}`:
            return {
                ...state,
                isFetching: true
            }
        case `${GET_GROUPPATIENTS}_${FULFILLED}`:
            return {
                ...state,
                isFetching: false,
                groupPatients: action.payload.profiles.map(u =>  mapModel('user', u, false)),
                isSearching: false
                //todo: this should probably return a root node of 'users' instead of 'groups' for correctness
            }
        case `${GET_GROUPPATIENTS}_${REJECTED}`:
        return {
            ...state,
            isFetching: false
        }

        case OPEN_PATIENT_FORM_MODAL:
            return {
            ...state,
            formModalOpen: true
            }

        case CLOSE_PATIENT_FORM_MODAL:
            return {
            ...state,
            formModalOpen: false
            }
        default:
            return state;
    }
}
