import React, { Component, createRef } from 'react';
import {
  Panel,
  ListGroup,
  ListGroupItem,
  Button,
  Row,
  Col
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import {
  faSignOutAlt,
  faComment,
  faVideo,
  faPrint,
  faGlobe
} from '@fortawesome/free-solid-svg-icons';
import 'isomorphic-fetch';
import { setLocale } from '../actions/patient';
import { requestDeauth } from '../../../features/membership/actions';
import PatientPrintPreview from '../../patients/Modals/PatientPrintPreview';
import SelectPrintRxModal from './SelectPrintRxModal';
import SelectLanguageModal from './SelectLanguageModal';
import TextTranslation from '../../../I18n/TranslateText';

class MobileHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openSelectPrintRx: false,
      openPrintPreview: false,
      openLanguageModal: false,
      rxPrintDetails: null
    };

    this.goToBlog = this.goToBlog.bind(this);
    this.signOut = this.signOut.bind(this);
    this.handleSelectPrintRx = this.handleSelectPrintRx.bind(this);
    this.handleSelectRxModal = this.handleSelectRxModal.bind(this);
    this.handlePrintPreview = this.handlePrintPreview.bind(this);
    this.handleLanguageModal = this.handleLanguageModal.bind(this);
    this.formatRxItem = this.formatRxItem.bind(this);

    this.printRef = createRef(null);
  }

  componentDidMount() {
    const { profile } = this.props;

    if (profile.Prescriptions && !profile.Prescription) {
      if (profile.Prescriptions.length === 1) {
        this.setState({
          rxPrintDetails: this.formatRxItem(profile.Prescriptions[0])
        });
      }
    } else if (!profile.Prescriptions && profile.Prescription) {
      this.setState({
        rxPrintDetails: this.formatRxItem(profile.Prescription)
      });
    }
  }

  formatRxItem = (rxItem) => {
    return !rxItem.hasOwnProperty('Prescription')
      ? {
          Prescription: { ...rxItem }
        }
      : rxItem;
  };

  signOut() {
    this.props.dispatch(requestDeauth());
  }

  goToBlog() {
    console.log('Navigate to blog');
    this.props.history.push('/blog');
  }

  handleLanguageModal() {
    this.setState({
      openLanguageModal: !this.state.openLanguageModal
    });
  }

  handleSelectPrintRx(rxId) {
    const { profile } = this.props;
    const { Prescriptions } = profile;

    const rxDetails = rxId
      ? Prescriptions.find((rx) => rx.Id === rxId)
      : this.formatRxItem(Prescriptions[rxId]);

    this.setState({
      rxPrintDetails: rxDetails
    });
  }

  handleSelectRxModal() {
    this.setState({
      rxPrintDetails: null,
      openSelectPrintRx: !this.state.openSelectPrintRx
    });
  }

  handlePrintPreview() {
    this.setState({
      openPrintPreview: !this.state.openPrintPreview
    });
  }

  render() {
    const { profile, siteDesign, locale, dispatch } = this.props;
    const {
      rxPrintDetails,
      openSelectPrintRx,
      openPrintPreview,
      openLanguageModal
    } = this.state;

    const printProps = {
      currentPatient: {
        ...profile,
        GroupInfo: {
          ...profile.GroupInfo,
          PrintEmail: profile.GroupInfo?.PrintEmail || 'provider'
        }
      },
      siteDesign
    };

    console.log(profile);

    return (
      <div className="mobile-body container-fluid">
        {rxPrintDetails && locale && (
          <PatientPrintPreview
            openModal={openPrintPreview}
            handleCloseModal={this.handlePrintPreview}
            forwardedRef={this.printRef}
            printProps={printProps}
            prescription={rxPrintDetails.Prescription}
            currLocale={locale}
          />
        )}

        <SelectPrintRxModal
          isOpen={openSelectPrintRx}
          currLocale={locale}
          profile={profile}
          rxPrintDetails={rxPrintDetails}
          handleCancel={this.handleSelectRxModal}
          handleSelectPrintRx={this.handleSelectPrintRx}
          handlePrintRxModal={this.handleSelectRxModal}
          handlePrintPreview={this.handlePrintPreview}
        />

        <SelectLanguageModal
          locale={locale}
          openLanguageModal={openLanguageModal}
          handleLanguageModal={this.handleLanguageModal}
          handleSetLocale={(val) => dispatch(setLocale(val))}
        />

        <Panel onClick={() => {}}>
          <Panel.Body>
            <div className="logout-panel-div">
              <ListGroup>
                <ListGroupItem>
                  <Row>
                    <Col xs={12}>
                      <Button
                        bsStyle="primary"
                        block
                        onClick={this.handleLanguageModal}
                      >
                        <TextTranslation
                          currLocale={locale}
                          currText={'Languages'}
                        />{' '}
                        <FontAwesomeIcon icon={faGlobe} />
                      </Button>
                      <br />
                    </Col>
                  </Row>

                  {(profile.GroupInfo.EnableTelehealth === undefined ||
                    profile.GroupInfo.EnableTelehealth === true) && (
                    <Row>
                      <Col xs={12}>
                        <Button
                          bsStyle="primary"
                          block
                          onClick={() => {
                            this.props.history.push('/exercises?video=true');
                          }}
                        >
                          <TextTranslation
                            currLocale={locale}
                            currText={'Video Call'}
                          />{' '}
                          <FontAwesomeIcon icon={faVideo} />
                        </Button>
                        <br />
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col xs={12}>
                      <div>
                        {profile.Prescriptions.length > 1 ? (
                          <Button
                            bsStyle="primary"
                            block
                            onClick={this.handleSelectRxModal}
                          >
                            <TextTranslation
                              currLocale={locale}
                              currText={'Print Prescription'}
                            />{' '}
                            <FontAwesomeIcon icon={faPrint} />
                          </Button>
                        ) : (
                          <Button
                            bsStyle="primary"
                            block
                            onClick={this.handlePrintPreview}
                          >
                            <TextTranslation
                              currLocale={locale}
                              currText={'Print Prescription'}
                            />{' '}
                            <FontAwesomeIcon icon={faPrint} />
                          </Button>
                        )}

                        <br />
                      </div>
                    </Col>
                  </Row>
                  {/*<Button bsStyle="primary" block onClick={()=>{this.props.history.push('/progress')}}>*/}
                  {/*Progress <FontAwesomeIcon icon={faChartPie} />*/}
                  {/*</Button>*/}
                  <Row>
                    <Col xs={12}>
                      <Button
                        bsStyle="primary"
                        block
                        onClick={() => {
                          this.props.history.push('/comment');
                        }}
                      >
                        <TextTranslation
                          currLocale={locale}
                          currText={'Feedback'}
                        />{' '}
                        <FontAwesomeIcon icon={faComment} />
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <Button bsStyle="primary" block onClick={this.signOut}>
                    <TextTranslation currLocale={locale} currText={'Logout'} />{' '}
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </Button>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.visibleProfile,
    siteDesign: state.publicPage.design,
    appointmentRequest: state.clinics.currentClinic.AppointmentRequest,
    locale: state.mobile.locale
  };
};

export default connect(mapStateToProps)(MobileHome);
