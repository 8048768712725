import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Space,
  Typography
} from 'antd';
import { useState } from 'react';

import ActionButtons from './ActionButtons';
import UserForm from './UserForm';
import apiFetch from '../../../../utils/fetch.utils';
import config from '../../../../config';
import services from '../../../../services';

const Utilities = ({ group }) => {
  const [input, setInput] = useState('');
  const [fetching, setFetching] = useState(false);
  const [profile, setProfile] = useState(null);
  const [cognito, setCognito] = useState(null);
  const [openSearch, setOpenSearch] = useState(null);
  const [clinics, setClinics] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [migrating, setMigrating] = useState(false);

  const isPatient = profile?.Role === 'p';
  const isProvider = profile?.Role === 'pt' || profile?.Role === 'ga';
  const showActionButtons =
    isPatient ||
    (isPatient && cognito && !openSearch) ||
    (profile && !cognito) ||
    isProvider;

  const handleGetProfile = async input => {
    try {
      setFetching(true);
      setProfile(null);
      setCognito(null);
      setOpenSearch(null);

      const response = await Promise.all([
        services.admin.getProfile(input),
        services.cognito.getProfile(input)
      ]);

      if (response[1].data) {
        const { data } = response[1].data;
        const { UserAttributes } = data;

        const role = UserAttributes.find(a => a.Name === 'custom:role');
        if (role && role.Value === 'p') {
          const { Username } = data;
          const groupId = UserAttributes.find(a => a.Name === 'custom:groupId');
          const osResponse = await services.patients.searchBySub(
            Username,
            groupId.Value
          );

          if (osResponse.data[0]) {
            setOpenSearch(osResponse.data[0]['_source']);
          }
        }
      }

      if (response[0]['data']) {
        const { data } = response[0];
        setProfile(data);

        await apiFetch(
          `groups/${group.name}/clinics?id=${group.id}`,
          'GET'
        ).then(res => setClinics(res.data));
      } else {
        setProfile('No Record in DynamoDB');
      }

      if (response[1]['data']) {
        const { data } = response[1]['data'];
        setCognito(data);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Search Failed',
        description: 'An error occurred while fetching data',
        duration: 3
      });
    } finally {
      setFetching(false);
    }
  };

  const handleDeleteProfile = async () => {
    try {
      setDeleting(true);

      await services.cognito.updateProfile('delete profile', {
        CognitoId: cognito ? cognito.Username : null,
        Indexed: !!openSearch
      });

      notification.success({
        message: 'Delete Success',
        description: 'User profile successfully deleted',
        duration: 3
      });

      setCognito(null);
      setOpenSearch(null);
    } catch (error) {
      notification.error({
        message: 'Delete Failed',
        description: 'An error occurred while deleting data',
        duration: 3
      });
    } finally {
      setDeleting(false);
    }
  };

  const handleMigrateProfile = async () => {
    try {
      setMigrating(true);

      await services.admin.migrateProfile(input);
      handleGetProfile(input);

      notification.success({
        message: 'Migration Success',
        description: 'User profile successfully migrated',
        duration: 3
      });
    } catch (error) {
      notification.error({
        message: 'Migration Failed',
        description: 'An error occurred while migrating user profile',
        duration: 3
      });
    } finally {
      setMigrating(false);
    }
  };

  return (
    <div className="su-utilities">
      <Form layout="inline" onFinish={() => handleGetProfile(input)}>
        <Form.Item name="email" style={{ width: '26em' }}>
          <Input
            placeholder="email@domain.com"
            value={input}
            onChange={e => setInput(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" loading={fetching}>
            SEARCH
          </Button>
        </Form.Item>
      </Form>

      <Divider />

      {!fetching && (
        <>
          {profile && (
            <>
              {profile === 'No Record in DynamoDB' ? (
                <Space direction="vertical" size="large">
                  {(cognito || openSearch) && (
                    <Space size="large">
                      {!config.root.includes('ofpw8yar0c') && (
                        <Button
                          type="primary"
                          loading={migrating}
                          onClick={handleMigrateProfile}
                        >
                          MIGRATE PROFILE
                        </Button>
                      )}

                      <Button
                        danger
                        type="primary"
                        disabled={deleting}
                        onClick={handleDeleteProfile}
                      >
                        DELETE PROFILE
                      </Button>
                    </Space>
                  )}

                  <Typography.Title level={3}>
                    Error 404 - No Record Found
                  </Typography.Title>
                </Space>
              ) : (
                <div className="box">
                  {showActionButtons && (
                    <>
                      <ActionButtons
                        cognito={cognito}
                        profile={profile}
                        openSearch={openSearch}
                        getProfile={handleGetProfile}
                      />

                      <div className="space" />
                    </>
                  )}

                  <UserForm
                    profile={profile}
                    group={group}
                    clinics={clinics}
                    getProfile={handleGetProfile}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Utilities;
