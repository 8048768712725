import { atom } from 'recoil';

const defaultState = {
  list: [],
  details: null,
  loading: false,
  redirect: ''
};

const PATIENT_STATE = atom({
  key: 'PATIENT_STATE',
  default: defaultState
});

export default PATIENT_STATE;
