import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Row } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { stats } from './data';

import ExpandCard from './ExpandCard';
import PreviewCard from './PreviewCard';
import StatCard from '../../components/StatCard';
import states from '../../states';
import services from '../../services';
import * as reportsUtils from '../../utils/reports.utils';

const UsageReports = ({ visibleProfile }) => {
  const [loading, setLoading] = useState(false);
  const [statsData, setStatsData] = useState(stats);
  const [expandedView, setExpandedView] = useState(null);
  const [reports, setReports] = useRecoilState(states.reports);
  const groups = useRecoilValue(states.groups);

  const ptuEnabled = !!visibleProfile?.GroupInfo?.EnablePTU;

  useEffect(() => {
    getInitData();
  }, []);

  useEffect(() => {
    if (groups.clinics) {
      setStatsData((prevState) => {
        const newStats = [...prevState];
        const clinicsStat = newStats.find((stat) => stat.title === 'Locations');
        clinicsStat.value = groups.clinics.length;
        return newStats;
      });
    }

    if (groups.providers) {
      setStatsData((prevState) => {
        const newStats = [...prevState];
        const providersStat = newStats.find(
          (stat) => stat.title === 'Providers'
        );
        providersStat.value = groups.providers.filter((p) => !!p.Active).length;
        return newStats;
      });
    }

    if (reports.usage.totalPatients) {
      setStatsData((prevState) => {
        const newStats = [...prevState];
        const patientsStat = newStats.find(
          (stat) => stat.title === 'Patients Added'
        );
        patientsStat.value = reports.usage.totalPatients;
        return newStats;
      });
    }

    if (reports.usage.totalHeps) {
      setStatsData((prevState) => {
        const newStats = [...prevState];
        const hepStat = newStats.find((stat) => stat.title === 'HEPs Assigned');
        hepStat.value = reports.usage.totalHeps;
        return newStats;
      });
    }

    if (ptuEnabled) {
      if (reports.usage.totalCareplans) {
        setStatsData((prevState) => {
          const newStats = [...prevState];
          const careplanStat = newStats.find(
            (stat) => stat.title === 'Care Plans Assigned'
          );
          careplanStat.value = reports.usage.totalCareplans;
          return newStats;
        });
      }
    } else {
      setStatsData((prevState) =>
        prevState.filter((stat) => stat.title !== 'Care Plans Assigned')
      );
    }
  }, [
    reports.usage.totalPatients,
    reports.usage.totalHeps,
    reports.usage.totalCareplans,
    groups.clinics,
    groups.providers,
    ptuEnabled
  ]);

  const getInitData = async () => {
    try {
      setLoading(true);

      //empty array should return all providers
      const providers = groups.providers;
      const responses = await fetchReports(providers);

      const [
        patients,
        heps,
        careplans,
        patientsCount,
        hepsCount,
        careplansCount
      ] = responses.map((res, i) => {
        if (res.status === 'rejected') {
          return i > 2 ? 0 : [];
        }

        return res.value.data;
      });

      setReports((prevReports) => ({
        ...prevReports,
        usage: {
          patients: reportsUtils.generateUsageReport(patients, providers),
          heps: reportsUtils.generateUsageReport(heps, providers),
          careplans: reportsUtils.generateUsageReport(careplans, providers),
          totalPatients: patientsCount || 0,
          totalHeps: hepsCount || 0,
          totalCareplans: careplansCount || 0
        }
      }));
    } catch (error) {
      console.log('getInitData', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchReports = async (providers) => {
    try {
      const last30days = reportsUtils.generateDateQuery();
      const allTime = reportsUtils.generateDateQuery(0, Date.now());
      const providersList = providers
        .filter((p) => !!p.Active)
        .map((p) => p.Sub);

      return await Promise.allSettled([
        services.reports.getUsageReports(
          'PATIENTS',
          last30days,
          providersList.slice(0, 50),
          []
        ),
        services.reports.getUsageReports(
          'HEP',
          last30days,
          providersList.slice(0, 50),
          []
        ),
        services.reports.getUsageReports(
          'CAREPLAN',
          last30days,
          providersList.slice(0, 50),
          []
        ),
        services.reports.getUsageReports(
          'TOTAL_PATIENTS',
          allTime,
          providersList,
          []
        ),
        services.reports.getUsageReports(
          'TOTAL_HEP',
          allTime,
          providersList,
          []
        ),
        services.reports.getUsageReports(
          'TOTAL_CAREPLAN',
          allTime,
          providersList,
          []
        )
      ]);
    } catch (error) {
      throw error;
    }
  };

  const handleExpand = (type) => {
    setExpandedView(type);
    window.scrollTo(0, 0);
  };

  return (
    <div className="ptw-main-body">
      <div style={{ marginTop: 12, marginBottom: 12 }}>
        <Row
          gutter={[16, 16]}
          justify="space-between"
          style={{ marginBottom: 20 }}
        >
          {statsData.map((stat) => {
            if (stat.title === 'Care Plans Assigned' && !ptuEnabled) {
              return null;
            }

            const flex = !ptuEnabled ? statsData.length - 1 : statsData.length;

            return (
              <Fragment>
                <Col className="hide-lg-tablet" flex={1 / flex}>
                  <StatCard
                    loading={loading}
                    title={stat.title}
                    value={stat.value}
                    icon={stat.icon}
                  />
                </Col>
                <Col className="hide-dt show-lg-tablet" md={12} sm={24} xs={24}>
                  <StatCard
                    loading={loading}
                    title={stat.title}
                    value={stat.value}
                    icon={stat.icon}
                  />
                </Col>
              </Fragment>
            );
          })}
        </Row>
        {expandedView ? (
          <ExpandCard
            type={expandedView}
            handleClose={() => {
              handleExpand(null);
            }}
          />
        ) : (
          <Row gutter={[16, 16]}>
            <Col lg={ptuEnabled ? 8 : 12} md={24} sm={24} xs={24}>
              <PreviewCard
                type="PATIENTS"
                loading={loading}
                handleExpand={() => {
                  handleExpand('PATIENTS');
                }}
              />
            </Col>
            <Col lg={ptuEnabled ? 8 : 12} md={24} sm={24} xs={24}>
              <PreviewCard
                type="HEPS"
                loading={loading}
                handleExpand={() => {
                  handleExpand('HEPS');
                }}
              />
            </Col>
            {ptuEnabled && (
              <Col lg={ptuEnabled ? 8 : 12} md={24} sm={24} xs={24}>
                <PreviewCard
                  type="CAREPLANS"
                  loading={loading}
                  handleExpand={() => {
                    handleExpand('CAREPLANS');
                  }}
                />
              </Col>
            )}
          </Row>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  visibleProfile: state.visibleProfile
});

export default connect(mapStateToProps)(withRouter(UsageReports));
