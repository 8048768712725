import { gql } from '@apollo/client';

export const GET_NEW_MESSAGE = gql`
  subscription OnNewMessageSubscription($to: String!) {
    onNewMessage(to: $to) {
      to
      message
      from
      date
      MessageBoardId
    }
  }
`;

export const GET_NEW_ANNOUNCEMENT = gql`
  subscription OnSendAnnouncementSubscription($DateSent: Float) {
    onSendAnnouncement(DateSent: $DateSent) {
      Title
      Message
      ReleaseNotes
      Image
      Version
    }
  }
`;
