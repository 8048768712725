import {camelCase} from "voca";

/**
 * Accepts an array of objects and returns an array of objects with the keys camel cased
 * */
export default function(objArray){

    const parsedObjects = [];
    if (Array.isArray(objArray)) {

        for(let i =0; i < objArray.length; i++){
            const obj = objArray[i];
            const keys = Object.keys(obj);

            const tempObj = {};
            keys.map(k=>{
                const key = camelCase(k);
                return tempObj[key] = obj[k];
            })

            parsedObjects.push(tempObj);
        }
        return parsedObjects
    }
}